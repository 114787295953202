/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {MarkerData} from '../../../../types/components/ApiTypes';

interface Props {
    marker: MarkerData,
}

const IconMarker = (props: Props) => {
    if (props.marker.icon) {
        return (
            <div
                onClick={() => {
                    console.log('hello');
                }}
            >
                {props.marker.icon}
            </div>
        );
    }

    return null;
};

export default IconMarker;
