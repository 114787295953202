/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo, useState} from 'react';
import DisplayGridHeaderRow from '../../../common/displayTable/DisplayGridHeaderRow';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import DisplayGridSortableTableHeader from '../../../common/displayTable/DisplayGridSortableTableHeader';
import Constants from '../../../../helper/Constants';
import BinsenseStore from '../../../../stores/BinsenseStore';
import StorageListTableRow from './StorageListTableRow';
import PaginationTable from '../../../common/displayTable/PaginationTable';
import {Site} from '../../../../types/components/ApiTypes';
import getMasterSerialsFromSite from '../../../../helper/functions/getMasterSerialsFromSite';
import getAutomationMasterSerialsFromSite from '../../../../helper/functions/getAutomationMasterSerialsFromSite';
import getAllSerialsFromSite from '../../../../helper/functions/getAllSerialsFromSite';
import useWindowSize from '../../../../helper/hooks/useWindowSize';

interface Props {
    rows: any[]
    site: Site,
}

const StorageListTable = (props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const masterSerials = useMemo(() => getMasterSerialsFromSite(props.site), [ props.site ]);
    const automationMasterSerials = useMemo(() => getAutomationMasterSerialsFromSite(props.site), [ props.site ]);
    const siteSerials = useMemo(() => getAllSerialsFromSite(props.site), [ props.site ]);

    const renderGridHeader = (id: string, label: string, xs: number, key = '') => (
        <DisplayGridSortableTableHeader
            id={id}
            label={label}
            xs={xs}
            data={props.rows}
            setData={BinsenseStore.setStorageListData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderRow = (row: any) => (
        <StorageListTableRow
            bin={row}
            site={props.site}
            masterSerials={masterSerials}
            automationMasterSerials={automationMasterSerials}
            key={`${ row.id }_storage_table_row`}
            siteSerials={siteSerials}
        />
    );

    const xs = () => largeScreen ? 9 / 7 : 9.75 / 8;

    return (
        <div style={{width: '100%'}}>
            <PaginationTable
                rows={props.rows}
                renderRow={renderRow}
            >
                <DisplayGridHeaderRow sx={{marginTop: '0px'}}>
                    {renderGridHeader('sites_name_header', Constants.STRUCTURE,  xs(), 'name')}
                    {renderGridHeader('sites_fan_status_header', Constants.FAN_STATUS, xs(), 'fanStatus')}
                    {renderGridHeader('sites_highest_temp_header', Constants.HIGHEST_TEMP, xs(), 'maxTemp')}
                    {renderGridHeader('sites_grainType', Constants.GRAIN_TYPE, xs(), 'grainType')}
                    {renderGridHeader('sites_avg_temp_header', Constants.AVG_TEMP, xs(), 'avgTemp')}
                    {renderGridHeader('sites_avg_moisture_header', Constants.AVG_MOISTURE, xs(), 'avgMoisture')}
                    {renderGridHeader('sites_fill_level_header', Constants.FILL_LEVEL, xs(), 'fillLevel')}
                    {renderGridHeader('sites_last_reported_header', Constants.LAST_CALL_IN, xs(), 'lastReported')}
                    <DisplayGridLabel
                        id='sites_actions_header'
                        label="Actions"
                        xs={xs()}
                        header
                    />
                </DisplayGridHeaderRow>
            </PaginationTable>
        </div>
    );
};

export default StorageListTable;
