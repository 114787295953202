/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import Grid from '@mui/material/Grid';
import {InputFieldProps, SelectOptions} from '../../../types/components/ApiTypes';
import SelectFieldInput from './SelectFieldInput';

interface Props {
    id: string,
    label: string,
    field: InputFieldProps,
    options: SelectOptions[],
    textFieldProps?: object,
    helperText?: string,
    fullWidth?: boolean,
}

function GridSelectFieldInput(props: Props) {
    return (
        <Grid
            item
            xs={props.fullWidth ? 12 : 6}
        >
            <SelectFieldInput
                id={props.id}
                label={props.label}
                field={props.field}
                options={props.options}
                textFieldProps={props.textFieldProps}
                helperText={props.helperText}
            />
        </Grid>
    );
}

export default GridSelectFieldInput;
