import { Customer } from '../../../../types/components/ApiTypes';
import { observer } from 'mobx-react-lite';
import {Button, Grid, styled, Typography} from '@mui/material';
import getTestID from '../../../../helper/functions/getTestID';
import CustomerNotificationsTable from '../../../customer/preferences/CustomerNotificationsTable';
import { useEffect, useState } from 'react';
import getNotificationSettings from '../../../../controllers/apiCalls/get/getNotificationSettings';
import { useSnackbar } from 'notistack';
import { Add } from '@mui/icons-material';
import Constants from '../../../../helper/Constants';
import AddContactDetailModal from '../../../customer/preferences/AddContactDetailModal';
import { clearDealerCustomerSettings } from '../../../../helper/functions/clearPreviousUserData';

interface Props {
    user: Customer
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '12px',
});

const EditCustomerNotifications = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ addDetailModalOpen, setAddDetailModalOpen ] = useState(false);

    useEffect(() => {
        clearDealerCustomerSettings();
        if (props.user.id > 0) {
            getNotificationSettings(enqueueSnackbar, props.user.id);
        }
    }, [ props.user.id ]);

    return (
        <Grid
            item
            xs={12}
        >
            <Row>
                <Typography color="primary">
                    Phone/Email Notifications
                </Typography>
                <Button
                    {...getTestID('new_contact_detail_button')}
                    startIcon={<Add/>}
                    onClick={() => setAddDetailModalOpen(true)}
                    variant="contained"
                >
                    {Constants.CONTACT_DETAIL}
                </Button>
            </Row>
            <CustomerNotificationsTable/>
            {addDetailModalOpen && (
                <AddContactDetailModal
                    id="add_contact_detail_modal"
                    open
                    close={() => setAddDetailModalOpen(false)}
                />
            )}
        </Grid>
    );
});

export default EditCustomerNotifications;