/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import postValidSerial from '../../controllers/apiCalls/post/postValidSerial';
import Constants from '../Constants';

const getHardwareType = (index: number, device: string, deviceType: string) => {
    if (device.toLowerCase() === Constants.LIVE.toLowerCase()) {
        return deviceType.toLowerCase() === Constants.REMOTE.toLowerCase() || index !== 0 ? 'REMOTE' : 'MASTER';
    }
    else if (device.toLowerCase() === Constants.PLUS.toLowerCase()) {
        return deviceType.toLowerCase() === Constants.AUTOMATION_HUB.toLowerCase() ? 'AUTOMATION' : 'AUTOMATION_REMOTE';
    }
    return device.toUpperCase();
};

const getSerialValidation = async (
    enqueueSnackbar: any,
    serials: string[],
    device: string,
    deviceType: string,
    siteId?: number
) => {
    const isValid = [];
    for (let i = 0; i < serials.length; i++) {
        const body = {
            serial: serials[i],
            hardwareType: getHardwareType(i, device, deviceType),
            siteId,
            useForMonitoring: true,
        };
        const verification = await postValidSerial(enqueueSnackbar, body);
        isValid.push(verification);
    }
    return isValid;
};

export default getSerialValidation;
