import callDeleteAPI from '../../callDeleteAPI';
import updateData from '../../../helper/functions/updateData';

const deleteDealerMyCustomer = (enqueueSnackbar: any, id: number) => {
    callDeleteAPI(`deleteMyCustomer/${ id }`,
        (response) => {
            if (response === 'OK') {
                updateData(enqueueSnackbar);
            }
        },
        enqueueSnackbar,
        'Dealer my customer deleted successfully'
    );
};

export default deleteDealerMyCustomer;