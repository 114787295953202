/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {IGTResponse} from '../types/components/ApiTypes';
import {EnqueueSnackbar} from '../types/SnackbarTypes';

const displaySuccessMessage = <T = any>(receivedServerData: IGTResponse<T>,
                             successMessage: string,
                             enqueueSnackbar: EnqueueSnackbar) => {
    let message = 'Content saved';
    if (receivedServerData.displayMessage && receivedServerData.message &&
        receivedServerData.messageTitle) {
        message = receivedServerData.message;
    } else if (successMessage !== '') {
        message = successMessage;
    }
    enqueueSnackbar(message, {variant: 'success'});
};

export default displaySuccessMessage;
