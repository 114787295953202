/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo, useState} from 'react';
import NotesTable from '../notes/NotesTable';
import Constants from '../../../helper/Constants';
import NewNoteModal from '../notes/NewNoteModal';
import {Button, styled} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';
import {Add, Download} from '@mui/icons-material';
import BinsenseStore from '../../../stores/BinsenseStore';
import exportTableToCSV from '../../../helper/functions/exportTableToCSV';
import {observer} from 'mobx-react-lite';
import WebStore from '../../../stores/WebStore';
import getSelectedSiteIndex from '../../../helper/functions/getSelectedSiteIndex';
import DashboardSkeleton from '../../common/DashboardSkeleton';

const Row = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'right',
    paddingBottom: '12px',
    paddingRight: '20px',
});

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const NotesPage = observer(() => {
    const site = useMemo(() => BinsenseStore.userData.sites[getSelectedSiteIndex()],
        [ WebStore.selectedSiteId, BinsenseStore.userData ]);
    const rows = useMemo(() => BinsenseStore.notesTableData, [ BinsenseStore.notesTableData ]);

    const columns = [
        {
            field: 'note',
            headerName: Constants.NOTE,
        },
        {
            field: 'structure',
            headerName: Constants.STRUCTURE,
        },
        {
            field: 'date',
            headerName: Constants.DATE,
        },
    ];

    const [ newNoteModalOpen, setNewNoteModalOpen ] = useState(false);

    return (
        <Root>
            {!BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
                <Row>
                    <Button
                        {...getTestID('new_note_button')}
                        startIcon={<Add/>}
                        onClick={() => setNewNoteModalOpen(true)}
                        variant='contained'
                    >
                        {Constants.NEW_NOTE}
                    </Button>
                    <Button
                        {...getTestID('export_button')}
                        startIcon={<Download/>}
                        onClick={() => exportTableToCSV(columns, rows,
                            `user_${ BinsenseStore.userData.id }_notes`)}
                        variant='contained'
                        style={{ marginLeft: '8px' }}
                    >
                        {Constants.EXPORT}
                    </Button>
                </Row>
            ) : (
                <Row>
                    <DashboardSkeleton
                        height={40}
                        width='120px'
                        style={{ marginRight: '8px' }}
                    />
                    <DashboardSkeleton
                        height={40}
                        width='100px'
                    />
                </Row>
            )}
            <NotesTable
                rows={rows}
                site={site}
            />
            {newNoteModalOpen && (
                <NewNoteModal
                    open
                    close={() => setNewNoteModalOpen(false)}
                    site={site}
                />
            )}
        </Root>
    );
});

export default NotesPage;
