/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {DesktopAccessDisabled, DesktopWindows} from '@mui/icons-material';
import BinsenseStore from '../../../../stores/BinsenseStore';
import HoverTextButton from '../../../common/buttons/HoverTextButton';
import Constants from '../../../../helper/Constants';

interface Props {
    numMarkers: number,
}

const MapAutoPlayer = observer((props: Props) => {
    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;
        if (BinsenseStore.playThroughMap) {
             intervalId = setInterval(() => {
                const currentMarkerIndex = BinsenseStore.currentMarkerIndex;
                const newIndex = currentMarkerIndex === props.numMarkers - 1
                    ? 0 : currentMarkerIndex + 1;
                BinsenseStore.setCurrentMarkerIndex(newIndex);
            }, 10000);

            return () => {
                if (intervalId) {
                    clearInterval(intervalId);
                }
            };
        } else if (intervalId) {
            clearInterval(intervalId);
        }

    }, [ BinsenseStore.playThroughMap ]);

    const onClick = () => {
        BinsenseStore.togglePlayThroughMap();
    };

    return (
        <HoverTextButton
            id="display_button"
            icon={BinsenseStore.playThroughMap ? <DesktopAccessDisabled/> : <DesktopWindows/>}
            hoverText={Constants.DISPLAY}
            onClick={onClick}
        />
    );
});

export default MapAutoPlayer;
