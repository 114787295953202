/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import ConfirmationModal from './ConfirmationModal';

interface Props {
    id: string,
    open: boolean,
    close: () => void,
    delete: () => void,
    description: string,
    secondaryDescription?: string
}

const DeleteModal = (props: Props) => (
    <ConfirmationModal
        id={props.id}
        open={props.open}
        close={props.close}
        submit={props.delete}
        title="Warning! Please read:"
        message={`Are you sure you want to delete ${ props.description }? ${ props.secondaryDescription
            ? props.secondaryDescription : '' }`}
    />
);

export default DeleteModal;
