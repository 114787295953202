import moment from 'moment-timezone';
// import GrainData from '../pojos/GrainData';

interface Point {
    x: number,
    y: number
}

export function strFormat(format: string, ...values: any[]): string {
    //create local ref of arguments of outer function, to pass into regex replace function (avoiding scope issues)
    //use regex to replace {n} with args[n] and replace double single quotes with single quotes (for consistency with Java)
    return format.replace(/{('')}/g, '\'').replace(/{(\d+)}/g, function (match: string, n: number) {
        //if args[n] is undefined, just return what was there: "{n}"
        return values[n] != null ? values[n] : match;
    });
}

export function degreesToRadians(degrees: number): number {
    return degrees * Math.PI / 180;
}

export function NumToFixedNum(num: number | undefined, decimal: number) {
    return num == null ? undefined : parseFloat(parseFloat(`${  num }`).toFixed(decimal));
}

export function radiansToDegrees(radians: number): number {
    return radians * 180 / Math.PI;
}

export function degreesToSlope(deg: number): number {
    return Math.tan(degreesToRadians(deg));
}

export function slopeToRadians(slope: number): number {
    return Math.atan(slope);
}

export function slopeToDegrees(slope: number): number {
    return slopeToRadians(slope) * 180 / Math.PI;
}

export function cToF(celsius: number, isIncremental: boolean = false) {
    const mod = isIncremental ? 0 : 32;
    return parseFloat((celsius * 9 / 5 + mod).toFixed(1));
}

export function fToC(fahrenheit: number, isIncremental: boolean = false) {
    const mod = isIncremental ? 0 : 32;
    return parseFloat(((fahrenheit - mod) * 5 / 9).toFixed(1));
}

export function displayTemp(useFahrenheit: boolean, celsiusTemp?: number, isIncremental?: boolean): string {
    if (celsiusTemp != null) {
        const safeTemp = useFahrenheit ? cToF(celsiusTemp, isIncremental) : celsiusTemp;
        return `${ NumToFixedNum(safeTemp, 1) }`;
    }
    return '';
}

export function tempConversion(useFahrenheit: boolean, celsiusTemp: number, isIncremental?: boolean) {
    return useFahrenheit ? cToF(celsiusTemp, isIncremental) : celsiusTemp;
}

export function meterToFeet(meter: number | undefined) {
    return meter ? meter * 3.28084 : undefined;
}

export function feetToMeter(feet: number | undefined) {
    return feet ? feet / 3.28084 : undefined;
}

// export function bushelsToTonnes(bushels: number, grain?: GrainData): number {
//     if (grain != null && grain.bushelsPerTonne > 0) {
//         return NumToFixedNum(bushels / grain.bushelsPerTonne, 2) || 0;
//     }
//     return 0;
// }
//
// export function tonnesToBushels(tonnes: number, grain?: GrainData): number {
//     if (grain != null && grain.bushelsPerTonne > 0) {
//         return NumToFixedNum(tonnes * grain.bushelsPerTonne, 1) || 0;
//     }
//     return 0;
// }

export function validateForDecimal(e: React.FormEvent<HTMLInputElement>, decimal: number) {
    const targetInput = (e.target as HTMLInputElement);
    const t = targetInput.value;
    targetInput.value = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'),
        decimal + 1)) : t;
}

export function getFromNowMoment(tz: string, utcTime: string, lang: string) {
    moment.updateLocale(lang, { relativeTime: { m: '%d minute',
h: '%d hour' } });
    return moment.utc(utcTime).tz(tz);
}

export function getFromNowTimeString(tz: string, utcTime: string, lang: string) {
    return getFromNowMoment(tz, utcTime, lang).fromNow();
}

export function getBoundingOffsets(width: number, length: number, angle: number) {
    let xOffset = 0, yOffset = 0;
    if (width === length || angle === 90 || angle === 270) {
        xOffset = length;
        yOffset = width;
    }
    else if (angle === 0 || angle === 180) {
        xOffset = width;
        yOffset = length;
    }
    else if (angle > 0 && angle < 90) {
        xOffset = Math.sin(degreesToRadians(angle)) * length;
        yOffset = Math.cos(degreesToRadians(angle)) * length;
    }
    else if (angle > 90 && angle < 180) {
        xOffset = Math.sin(degreesToRadians(180 - angle)) * length;
        yOffset = Math.cos(degreesToRadians(180 - angle)) * length;
    }
    else if (angle > 180 && angle < 270) {
        xOffset = Math.sin(degreesToRadians(angle - 180)) * length;
        yOffset = Math.cos(degreesToRadians(angle - 180)) * length;
    }
    else if (angle > 270 && angle < 360) {
        xOffset = Math.sin(degreesToRadians(360 - angle)) * length;
        yOffset = Math.cos(degreesToRadians(360 - angle)) * length;
    }

    return {
        xOffset,
yOffset,
    };
}

export function eventIsTouchy(ev: React.PointerEvent | React.MouseEvent | React.TouchEvent): ev is React.TouchEvent {
    return 'targetTouches' in ev;
}

export function eventIsMousey(ev: React.PointerEvent | React.MouseEvent | React.TouchEvent): ev is React.MouseEvent {
    return 'button' in ev;
}

export function eventIsPointy(ev: React.PointerEvent | React.MouseEvent | React.TouchEvent): ev is React.PointerEvent {
    return 'pointerId' in ev;
}

export function getEventCoords(ev: React.PointerEvent | React.MouseEvent | React.TouchEvent): Point {
    if (eventIsTouchy(ev)) {
        return {
            x: ev.targetTouches[0].clientX,
y: ev.targetTouches[0].clientY,
        };
    } else {
        return {
            x: ev.clientX,
y: ev.clientY,
        };
    }
}

export function urlToSearchParams(urlString: string): URLSearchParams {
    const urlChunks = urlString.split('?');
    if (urlChunks.length > 1 && urlChunks[1].length > 0) {
        return new URLSearchParams(urlChunks[1]);
    }
    return new URLSearchParams();
}

export function searchParamsToParamObject(searchParams: URLSearchParams) {
    const unsafeSite = searchParams.get('site');
    const unsafeStruct = searchParams.get('struct');

    return {
        siteID: unsafeSite == null ? 0 : parseInt(unsafeSite),
        structID: unsafeStruct == null ? 0 : parseInt(unsafeStruct),
    };
}

export function getSearchParams(): URLSearchParams {
    return urlToSearchParams(window.location.href);
}

export function getParamObject() {
    return searchParamsToParamObject(getSearchParams());
}

export function comparisonOfStructuresInAscendingOrder(clonedData: any[]) {
    const collator = new Intl.Collator('en',
    {numeric: true,
        sensitivity: 'base' });

    let sortIndexAvailable = false;
    clonedData.forEach(obj => {
        if (obj.sortOrder > 0) {
            sortIndexAvailable = true;
        }
    });

    if (sortIndexAvailable) {
        clonedData = clonedData.sort((obj1: any, obj2: any) => {
            const sortOrderModifier = 1;
            const comparison = collator.compare(obj1.sortOrder , obj2.sortOrder);
            return comparison * sortOrderModifier;
        });
    } else {
        clonedData = clonedData.sort((obj1: any, obj2: any) => {
            const sortOrderModifier = 1;
            const comparison = collator.compare(obj1.name ? obj1.name : obj1.structure, obj2.name ? obj2.name : obj2.structure);
            return comparison * sortOrderModifier;
        });
    }
    return clonedData;
}