import callAPI from '../../callAPI';

const getRequestPasswordReset = (enqueueSnackbar: any, email: String) => {
    callAPI(`password/forgot/${ email }`,
        (response) => {
            if (response === 'OK') {
                enqueueSnackbar('Successfully requested password reset.', {variant: 'success'});
            }
        },
        enqueueSnackbar
    );

    return '';
};

export default getRequestPasswordReset;