/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Grid} from '@mui/material';
import ContentPanel from '../../common/ContentPanel';
import AccountInformationPaper from '../preferences/AccountInformationPaper';
import SystemPreferencesPaper from '../preferences/SystemPreferencesPaper';
import AccountPasswordPaper from '../preferences/AccountPasswordPaper';
import DealerInformationPaper from '../preferences/DealerInformationPaper';
import AccountAddressPaper from '../preferences/AccountAddressPaper';
import CustomerNotificationsTable from '../preferences/CustomerNotificationsPanel';
import {observer} from 'mobx-react-lite';
import ManageSitePaper from '../preferences/ManageSitePaper';
import {useMemo} from 'react';
import BinsenseStore from '../../../stores/BinsenseStore';

const Preferences = observer(() => {
    const userData = useMemo(() => BinsenseStore.userData, [ BinsenseStore.userData ]);

    const renderGridItem = (paper: object) => (
        <Grid
            item
            xs={6}
        >
            {paper}
        </Grid>
    );

    return (
        <ContentPanel>
            <Grid
                container
                spacing={2}
            >
                <CustomerNotificationsTable/>
                {renderGridItem(
                    <AccountInformationPaper
                        userId={userData.id}
                        account={userData.account}
                    />
                )}
                {renderGridItem(
                    <AccountAddressPaper
                        userId={userData.id}
                        account={userData.account}
                    />
                )}
                {renderGridItem(<DealerInformationPaper/>)}
                {renderGridItem(<SystemPreferencesPaper preferences={userData.settings.preferences}/>)}
                {renderGridItem(<AccountPasswordPaper/>)}
                {renderGridItem(<ManageSitePaper sites={userData.sites}/>)}
            </Grid>
        </ContentPanel>
    );
});

export default Preferences;
