import callDeleteAPI from '../../callDeleteAPI';
import updateData from '../../../helper/functions/updateData';

const deleteAssociationCable = (enqueueSnackbar: any, id: number) => {
    callDeleteAPI(`assocation/cable/${ id }`,
        (response) => {
            if (response === 'OK') {
                updateData(enqueueSnackbar);
            }
        },
        enqueueSnackbar,
        'Cable association deleted successfully'
    );
};

export default deleteAssociationCable;