/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import Constants from '../../../../helper/Constants';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import DisplayGridActions from '../../../common/displayTable/DisplayGridActions';
import {Delete, Edit} from '@mui/icons-material';

interface Props {
    row: any,
    onEditClick: (id: number, note: string, structureId: number) => void,
    onDeleteClick: (id: number, note: string) => void,
}

const CustomerServiceReportsTableRow = observer((props: Props) => {
    const renderGridLabel = (id: string, label: string, xs: number) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={xs}
        />
    );

    return (
        <DisplayGridContent key={`${ props.row.id }_record`}>
            {renderGridLabel(`${ props.row.id }_record`, props.row.notes, 8)}
            {renderGridLabel(`${ props.row.id }_date`, new Date(props.row.serviceDate).toLocaleDateString(), 2)}
            <DisplayGridActions
                buttons={[
                    {
                        id: `${ props.row.id }_record_edit_button`,
                        onClick: () => props.onEditClick(props.row.id, props.row.notes, props.row.userId),
                        icon: <Edit/>,
                        hoverText: Constants.EDIT,
                    },
                    {
                        id: `${ props.row.id }_record_delete_button`,
                        onClick: () => props.onDeleteClick(props.row.id, props.row.note),
                        icon: <Delete/>,
                        hoverText: Constants.DELETE,
                    },
                ]}
                xs={2}
                key={`${ props.row.id }_user_actions_cell`}
                id={props.row.id}
            />
        </DisplayGridContent>
    );
});

export default CustomerServiceReportsTableRow;
