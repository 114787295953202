/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import { useState } from 'react';
import SubmitButton from '../../common/buttons/SubmitButton';
import TitlePaper from '../../common/TitlePaper';
import Constants from '../../../helper/Constants';
import isPasswordValid from '../../../helper/validator/isPasswordValid';
import PasswordField from '../../common/input/PasswordField';
import postUpdatePassword from '../../../controllers/apiCalls/post/postUpdatePassword';
import {useSnackbar} from 'notistack';
import {NewPassword} from '../../../types/components/ApiTypes';

const AccountPasswordPaper = () => {
    const {enqueueSnackbar} = useSnackbar();

    const [ oldPassword, setOldPassword ] = useState('');
    const [ oldPasswordError, setOldPasswordError ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ newPasswordError, setNewPasswordError ] = useState('');
    const [ newPasswordConfirm, setNewPasswordConfirm ] = useState('');
    const [ newPasswordConfirmError, setNewPasswordConfirmError ] = useState('');

    const isValid = () => {
        let valid = true;

        const oldPassError = isPasswordValid(oldPassword);
        setOldPasswordError(oldPassError);
        const passwordError = isPasswordValid(newPassword);
        setNewPasswordError(passwordError);
        const newRetypePasswordError = isPasswordValid(newPasswordConfirm);
        setNewPasswordConfirmError(newRetypePasswordError);

        if (newPassword !== '' && newPasswordConfirm !== '' && newPassword !== newPasswordConfirm) {
            setNewPasswordError('Password fields do not match');
            setNewPasswordConfirmError('Password fields do not match');
            valid = false;
        }

        if (newPassword !== '' && oldPassword !== '' && newPassword === oldPassword) {
            setNewPasswordError('You used this password recently. Please choose a different one');
            valid = false;
        }

        if (newPasswordConfirm !== '' && oldPassword !== '' && newPasswordConfirm === oldPassword) {
            setNewPasswordConfirmError('You used this password recently. Please choose a different one');
            valid = false;
        }

        if (oldPassError !== '' || newPasswordError !== '' || newRetypePasswordError !== '') {
            valid = false;
        }

        return valid;
    };

    const submit = () => {
        if (isValid()) {
            const body: NewPassword = {
                oldPassword,
                newPassword,
                newPasswordConfirm,
            };
            postUpdatePassword(enqueueSnackbar, body);
        }
    };

    return (
        <TitlePaper title={Constants.CHANGE_PASSWORD}>
            <PasswordField
                id="old_password_input"
                label="Old Password"
                field={{
                    value: oldPassword,
                    setValue: setOldPassword,
                    error: oldPasswordError,
                    setError: setOldPasswordError,
                }}
            />
            <PasswordField
                id="new_password_input"
                label="New Password"
                field={{
                    value: newPassword,
                    setValue: setNewPassword,
                    error: newPasswordError,
                    setError: setNewPasswordError,
                }}
            />
            <PasswordField
                id="retype_new_password_input"
                label="Retype New Password"
                field={{
                    value: newPasswordConfirm,
                    setValue: setNewPasswordConfirm,
                    error: newPasswordConfirmError,
                    setError: setNewPasswordConfirmError,
                }}
            />

            <SubmitButton
                id="change_password_submit"
                text={Constants.CHANGE_PASSWORD}
                onClick={submit}
            />
        </TitlePaper>
    );
};

export default AccountPasswordPaper;
