/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

// @ts-ignore
import uuid from 'react-uuid';
import {observer} from 'mobx-react-lite';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import Constants from '../../../../helper/Constants';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DisplayGridActions from '../../../common/displayTable/DisplayGridActions';
import {Delete, Edit, Visibility} from '@mui/icons-material';
import BinSetupModal from '../../../dealer/createSite/binSetupModal/BinSetupModal';
// eslint-disable-next-line
import {BuildingData, GrainBagData, GrainBinData, MarkerData, MarkerTypes, Site, StructIdSerials} from '../../../../types/components/ApiTypes';
import putBin from '../../../../controllers/apiCalls/put/putBin';
import {useSnackbar} from 'notistack';
import getTimeSince from '../../../../helper/functions/getTimeSince';
import getTempUnit from '../../../../helper/functions/getTempUnit';
import deleteStructure from '../../../../controllers/apiCalls/delete/deleteStructure';
import goToViewDevicePage from '../../../../helper/functions/goToViewDevicePage';
import getIndex from '../../../../helper/functions/getIndex';
import getGrainBinData from '../../../../helper/functions/getGrainBinData';
import getNewBin from '../../../../helper/functions/getNewBin';
import { displayTemp } from '../../../../helper/functions/functions';
import { useEffect, useMemo, useState } from 'react';
import useWindowSize from '../../../../helper/hooks/useWindowSize';
import getHardwareFieldsFromStructureData from '../../../../helper/functions/getHardwareFieldsFromStructureData';
import DeleteStructureModal from '../../../common/modal/DeleteStructureModal';
import BagSetupModal from '../../../dealer/createSite/newSiteWizard/BagSetupModal';
import getGrainBagData from '../../../../helper/functions/getGrainBagData';
import getNewBag from '../../../../helper/functions/getNewBag';
import putGrainBag from '../../../../controllers/apiCalls/put/putGrainBag';
import getNewBuilding from '../../../../helper/functions/getNewBuilding';
import getBuildingData from '../../../../helper/functions/getBuildingData';
import BuildingSetupModal from '../../../dealer/createSite/newSiteWizard/BuildingSetupModal';
import putBuilding from '../../../../controllers/apiCalls/put/putBuilding';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import checkAddToSubscription from '../../../../helper/functions/checkAddToSubscription';
import getMarkerData from '../../../../helper/functions/getMarkerData';
import getNewUser from '../../../../helper/functions/getNewUser';
import WebStore from '../../../../stores/WebStore';

interface Props {
    bin: any,
    site: Site,
    masterSerials: string[],
    automationMasterSerials: string[],
    siteSerials: StructIdSerials[],
}

const StorageListTableRow = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);
    const [ addToSubscriptionMessage, setAddToSubscriptionMessage ] = useState('');
    const [ showBinAddToSubscriptionModel, setShowBinAddToSubscriptionModel ] = useState(false);
    const [ showBuildingAddToSubscriptionModel, setShowBuildingAddToSubscriptionModel ] = useState(false);
    const [ showBagAddToSubscriptionModel, setShowBagAddToSubscriptionModel ] = useState(false);

    const disableAutomation = useMemo(() => props.site.automationHubs && props.site.automationHubs.length === 0,
        [ props.site ]);

    const tempUnit = useMemo(getTempUnit, [ BinsenseStore.userData, BinsenseStore.dealerUserData ]);
    const user = useMemo(() => {
        const index = getIndex(WebStore.pageIds[0], BinsenseStore.dealerUserData.customers);
        return index !== -1 ? BinsenseStore.dealerUserData.customers[index] : getNewUser();
    }, [ BinsenseStore.dealerUserData.customers, WebStore.pageIds[0] ]);

    const grainBinData = useMemo(() => {
        const index = getIndex(props.bin.id, props.site.bins);
        const bin = index !== -1 ? props.site.bins[index] : getNewBin();
        return getGrainBinData(bin);
    }, [ props.bin, props.site ]);
    const grainBagData = useMemo(() => {
        const index = getIndex(props.bin.id, props.site.grainBags);
        const bag = index !== -1 ? props.site.grainBags[index] : getNewBag();
        return getGrainBagData(bag);
    }, [ props.bin, props.site ]);
    const buildingData = useMemo(() => {
        const index = getIndex(props.bin.id, props.site.buildings);
        const building = index !== -1 ? props.site.buildings[index] : getNewBuilding();
        return getBuildingData(building);
    }, [ props.bin, props.site ]);

    const [ editedGrainBinData, setEditedGrainBinData ] = useState<GrainBinData | null>(null);
    const [ editedBuildingData, setEditedBuildingData ] = useState<BuildingData | null>(null);
    const [ editedBagData, setEditedBagData ] = useState<GrainBagData | null>(null);
    const [ editBinOpen, setEditBinOpen ] = useState(false);
    const [ editBagOpen, setEditBagOpen ] = useState(false);
    const [ editBuildingOpen, setEditBuildingOpen ] = useState(false);
    const [ deleteOpen, setDeleteOpen ] = useState(false);

    const onDelete = () => {
        deleteStructure(enqueueSnackbar, props.bin.id);
    };

    const setEditOpen = (value: boolean) => {
        if (props.bin.type === Constants.BIN) {
            setEditBinOpen(value);
        } else if (props.bin.type === Constants.BAG) {
            setEditBagOpen(value);
        } else if (props.bin.type === Constants.BUILDING) {
            setEditBuildingOpen(value);
        }
    };

    const onEditBinSubmit = () => {
        if (editedGrainBinData) {
            const binBody = {
                name: editedGrainBinData.name,
                binType: editedGrainBinData.type.toString().toLowerCase(),
                peakHeight: editedGrainBinData.peakHeight,
                eaveHeight: editedGrainBinData.eaveHeight,
                capacity: editedGrainBinData.bushelCapacity,
                diameter: editedGrainBinData.diameter,
                settings: {
                    hardwareType: editedGrainBinData.device,
                },
                hardware: getHardwareFieldsFromStructureData(editedGrainBinData),
            };
            putBin(enqueueSnackbar, props.bin.id, binBody);
        }
    };

    const onEditBagSubmit = () => {
        if (editedBagData) {
            const bagBody = {
                id: props.bin.id,
                name: editedBagData.name,
                rotation: editedBagData.rotation,
                length: editedBagData.length,
                width: editedBagData.width,
                capacity: editedBagData.quantity,
                settings: {
                    hardwareType: editedBagData.device,
                },
                hardware: getHardwareFieldsFromStructureData(editedBagData),
            };
            putGrainBag(enqueueSnackbar, props.bin.id, bagBody);
        }
    };

    const onEditBuildingSubmit = () => {
        if (editedBuildingData) {
            const buildingBody = {
                id: props.bin.id,
                name: editedBuildingData.name,
                rotation: editedBuildingData.rotation,
                length: editedBuildingData.length,
                width: editedBuildingData.width,
                height: editedBuildingData.height,
                settings: {
                    hardwareType: editedBuildingData.device,
                },
                hardware: getHardwareFieldsFromStructureData(editedBuildingData),
            };
            putBuilding(enqueueSnackbar, props.bin.id, buildingBody);
        }
    };

    useEffect(() => {
        if (editedGrainBinData) {
            // skip increase sub popup if device type is now remote or device type is unchanged
            if (editedGrainBinData.deviceType.toLowerCase() === Constants.REMOTE.toLowerCase() ||
                editedGrainBinData.deviceType.toLowerCase() === grainBinData.deviceType.toLowerCase()) {
                onEditBinSubmit();
            } else {
                const markerData: MarkerData[] = getMarkerData(props.site);
                const newMarker: MarkerData = {
                    // these properties are only added for completeness as the MarkerData object required.
                    // We only need the display.grainBinData property for the checkAddToSubscription() function
                    id: -1,
                    longitude: 0,
                    latitude: 0,
                    display: {
                        type: MarkerTypes.bin,
                        grainBinData: editedGrainBinData,
                    },
                };
                markerData.push(newMarker);

                if (props.site.live) {
                    const message: string = checkAddToSubscription(markerData, user.account.userName
                        ? user.account.userName : undefined, props.site.id, false);
                    if (message.length > 0) {
                        setAddToSubscriptionMessage(message);
                        setShowBinAddToSubscriptionModel(true);
                    }
                    else {
                        onEditBinSubmit();
                    }
                }
                else {
                    onEditBinSubmit();
                }
            }
        }
    }, [ editedGrainBinData ]);

    useEffect(() => {
        if (editedBuildingData) {
            if (editedBuildingData.deviceType.toLowerCase() === Constants.REMOTE.toLowerCase() ||
                editedBuildingData.deviceType.toLowerCase() === buildingData.deviceType.toLowerCase()) {
                onEditBuildingSubmit();
            } else {
                const markerData: MarkerData[] = getMarkerData(props.site);
                const newMarker: MarkerData = {
                    id: -1,
                    longitude: 0,
                    latitude: 0,
                    display: {
                        type: MarkerTypes.building,
                        buildingData: editedBuildingData,
                    },
                };
                markerData.push(newMarker);

                if (props.site.live) {
                    const message: string = checkAddToSubscription(markerData, user.account.userName
                        ? user.account.userName : undefined, props.site.id, false);
                    if (message.length > 0) {
                        setAddToSubscriptionMessage(message);
                        setShowBuildingAddToSubscriptionModel(true);
                    }
                    else {
                        onEditBuildingSubmit();
                    }
                }
                else {
                    onEditBuildingSubmit();
                }
            }
        }
    }, [ editedBuildingData ]);

    useEffect(() => {
        if (editedBagData) {
            if (editedBagData.deviceType.toLowerCase() === Constants.REMOTE.toLowerCase() ||
                editedBagData.deviceType.toLowerCase() === grainBagData.deviceType.toLowerCase()) {
                onEditBagSubmit();
            } else {
                const markerData: MarkerData[] = getMarkerData(props.site);
                const newMarker: MarkerData = {
                    id: -1,
                    longitude: 0,
                    latitude: 0,
                    display: {
                        type: MarkerTypes.grainBag,
                        grainBagData: editedBagData,
                    },
                };
                markerData.push(newMarker);

                if (props.site.live) {
                    const message: string = checkAddToSubscription(markerData, user.account.userName
                        ? user.account.userName : undefined, props.site.id, false);
                    if (message.length > 0) {
                        setAddToSubscriptionMessage(message);
                        setShowBagAddToSubscriptionModel(true);
                    }
                    else {
                        onEditBagSubmit();
                    }
                }
                else {
                    onEditBagSubmit();
                }
            }
        }
    }, [ editedBagData ]);

    const renderGridLabel = (id: string, label: string, xs: number, lengthLimit?: number) => (
        <DisplayGridLabel
            id={`${ props.bin.name }_${ id }`}
            label={label}
            xs={xs}
            lengthLimit={lengthLimit}
        />
    );

    const actionButtons = [
            {
                id: `${ props.bin.name }_view_device_button`,
                onClick: () => goToViewDevicePage(props.bin.id),
                icon: <Visibility/>,
                hoverText: Constants.VIEW_DEVICE,
            },
            {
                id: `${ props.bin.name }_edit_button`,
                onClick: () => setEditOpen(true),
                icon: <Edit/>,
                hoverText: Constants.EDIT,
            },
            {
                id: `${ props.bin.name }_delete_button`,
                onClick: () => setDeleteOpen(true),
                icon: <Delete/>,
                hoverText: Constants.DELETE,
            },
        ];

    const xs = () => largeScreen ? 9 / 7 : 9.75 / 8;

    return (
        <DisplayGridContent
            key={uuid()}
            topMargin='0px'
        >
            {renderGridLabel('name_site', props.bin.name, xs(), largeScreen ? 9 : 6)}
            {renderGridLabel('fan_status_site', props.bin.fanStatus, xs())}
            {renderGridLabel('highest_temp_site',
                props.bin.maxTemp !== Constants.NA ? `${ displayTemp( tempUnit === 'F', props.bin.maxTemp) }
                ${ props.bin.maxTemp !== Constants.NA ? tempUnit : '' }` : props.bin.maxTemp, xs())}
            {renderGridLabel('grain_type', props.bin.grainType, xs())}
            {renderGridLabel('avg_temp_site',
                props.bin.avgTemp !== Constants.NA ? `${ displayTemp( tempUnit === 'F', props.bin.avgTemp) } 
                ${ props.bin.avgTemp !== Constants.NA ? tempUnit : '' }` : props.bin.avgTemp, xs())}
            {renderGridLabel('avg_moisture_site',
                `${ props.bin.avgMoisture }${ props.bin.avgMoisture !== Constants.NA ? '%' : '' }`, xs())}
            {renderGridLabel('fill_level_site',
                `${ props.bin.fillLevel }${ props.bin.fillLevel !== Constants.NA ? '%' : '' }`, xs())}
            {renderGridLabel('last_reported_site',
                props.bin.lastReported !== Constants.NA ? getTimeSince(props.bin.lastReported) : Constants.NA, xs())}
            <DisplayGridActions
                buttons={actionButtons}
                xs={xs()}
                key={`${ props.bin.id }_storage_list_actions_cell`}
                id={props.bin.name}
            />
            {editBinOpen && (
                <BinSetupModal
                    open
                    close={() => setEditOpen(false)}
                    onSubmit={setEditedGrainBinData}
                    grainBinData={grainBinData}
                    masterSerials={props.masterSerials}
                    automationMasterSerials={props.automationMasterSerials}
                    structureId={props.bin.id}
                    disableAutomation={disableAutomation}
                    siteSerials={props.siteSerials}
                />
            )}
            {editBagOpen && (
                <BagSetupModal
                    open
                    close={() => setEditOpen(false)}
                    onSubmit={setEditedBagData}
                    grainBagData={grainBagData}
                    masterSerials={props.masterSerials}
                    automationMasterSerials={props.automationMasterSerials}
                    structureId={props.bin.id}
                    disableAutomation={disableAutomation}
                    siteSerials={props.siteSerials}
                />
            )}
            {editBuildingOpen && (
                <BuildingSetupModal
                    open
                    close={() => setEditOpen(false)}
                    onSubmit={setEditedBuildingData}
                    buildingData={buildingData}
                    masterSerials={props.masterSerials}
                    automationMasterSerials={props.automationMasterSerials}
                    structureId={props.bin.id}
                    disableAutomation={disableAutomation}
                    siteSerials={props.siteSerials}
                />
            )}
            {deleteOpen && (
                <DeleteStructureModal
                    id={`${ props.bin.id }_delete_confirmation`}
                    open
                    close={() => setDeleteOpen(false)}
                    submit={onDelete}
                    structName={props.bin.name}
                />
            )}
            {showBinAddToSubscriptionModel && (
                <ConfirmationModal
                    id='add_bin_to_subscription_confirmation'
                    open
                    close={() => setShowBinAddToSubscriptionModel(false)}
                    submit={onEditBinSubmit}
                    title={Constants.INCREASE_SUBSCRIPTION}
                    message={addToSubscriptionMessage}
                />
            )}
            {showBuildingAddToSubscriptionModel && (
                <ConfirmationModal
                    id='add_building_to_subscription_confirmation'
                    open
                    close={() => setShowBuildingAddToSubscriptionModel(false)}
                    submit={onEditBuildingSubmit}
                    title={Constants.INCREASE_SUBSCRIPTION}
                    message={addToSubscriptionMessage}
                />
            )}
            {showBagAddToSubscriptionModel && (
                <ConfirmationModal
                    id='add_bag_to_subscription_confirmation'
                    open
                    close={() => setShowBagAddToSubscriptionModel(false)}
                    submit={onEditBagSubmit}
                    title={Constants.INCREASE_SUBSCRIPTION}
                    message={addToSubscriptionMessage}
                />
            )}
        </DisplayGridContent>
    );
});

export default StorageListTableRow;
