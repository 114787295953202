/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import Constants from '../../../../helper/Constants';
import useWindowSize from '../../../../helper/hooks/useWindowSize';
import {useMemo} from 'react';
import KeyValueTypography from '../../../common/typography/KeyValueTypography';
import {observer} from 'mobx-react-lite';
import getTempUnit from '../../../../helper/functions/getTempUnit';
import BinsenseStore from '../../../../stores/BinsenseStore';
import TrendIcon from '../../../common/TrendIcon';
import { displayTemp } from '../../../../helper/functions/functions';
import { Site } from '../../../../types/components/ApiTypes';
import getAutomationRemotesCount from '../../../../helper/functions/getAutomationRemotesCount';

const Row = styled('div')({
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const TitleRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

interface Props {
    site: Site,
}

const StorageGridAutomationHubBody = observer((props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);

    const dataFontSize = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[4] ? 14 : 13, [ windowWidth ]);
    const tempUnit = useMemo(getTempUnit, [ BinsenseStore.userData.settings.preferences ]);

    const getTrendIcon = (trend: string) => (
        <TrendIcon value={trend}/>
    );

    const getTypographyPair = (id: string, label: string, value: string, isItalic?: boolean) => (
        <KeyValueTypography
            id={`automation_hub_${ id }`}
            label={label}
            value={value}
            fontSize={dataFontSize}
            flipColors
            isItalic={isItalic}
        />
    );

    return (
        <div style={{ width: '100%' }}>
            <Row style={{ marginTop: '8px' }}>
                {props.site.ambientTemp
                    ? getTypographyPair('ambient_temp', 'Ambient Temp:',
                    `${ displayTemp( tempUnit === 'F', props.site.ambientTemp) } ${ tempUnit }`)
                    : getTypographyPair('ambient_temp', 'Ambient Temp:', 'No Reading', true)
                }
                {props.site.humidity
                    ? getTypographyPair('humidity', 'Humidity:',
                        String(props.site.humidity).concat('%'))
                    : getTypographyPair('humidity', 'Humidity:', 'No Reading', true)
                }
            </Row>
            <Row>
                {props.site.tempTrend
                    ? (
                        <TitleRow>
                            <Typography
                                fontSize={dataFontSize}
                                paddingRight="8px"
                            >
                                Temp. Trend:
                            </Typography>
                            {getTrendIcon(props.site.tempTrend)}
                        </TitleRow>
                    )
                    : (
                        <TitleRow>
                            {getTypographyPair('tempTrend', 'Temp. Trend:', 'No Reading', true)}
                        </TitleRow>
                    )
                }
                {props.site.humidityTrend
                    ? (
                        <TitleRow>
                            <Typography
                                fontSize={dataFontSize}
                                paddingRight="8px"
                            >
                                Humidity Trend:
                            </Typography>
                            {getTrendIcon(props.site.humidityTrend)}
                        </TitleRow>
                    )
                    : (
                        <TitleRow>
                            {getTypographyPair('humidityTrend', 'Humidity Trend:', 'No Reading', true)}
                        </TitleRow>
                    )
                }
            </Row>
            <Row>
                {getTypographyPair('remotes', 'Remotes:', String(getAutomationRemotesCount(props.site)))}
            </Row>
        </div>
    );
});

export default StorageGridAutomationHubBody;
