/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {
    AddLocation,
    People,
    ShoppingBag,
    TableChart
} from '@mui/icons-material';
import Constants from '../../../helper/Constants';

const GENERAL_DEALER_DRAWER = [
    {
        id: 'my_customers',
        title: Constants.MY_CUSTOMERS,
        icon: <ShoppingBag/>,
    },
    {
        id: 'my_users',
        title: Constants.MY_USERS,
        icon: <People/>,
    },
    {
        id: 'create_site',
        title: Constants.CREATE_SITE,
        icon: <AddLocation/>,
    },
    {
        id: 'reports',
        title: Constants.REPORTS,
        icon: <TableChart/>,
    },
];

export default GENERAL_DEALER_DRAWER;
