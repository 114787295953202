/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import getTestID from '../../../../../helper/functions/getTestID';

interface Props {
    id: string,
    value: string,
    label: string,
    label2?: string,
}

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const DeviceConditionItem = (props: Props) => (
    <Root>
        <Typography
            {...getTestID(`${ props.id }_value`)}
            fontWeight="bold"
            fontSize={20}
        >
            {props.value}
        </Typography>
        {props.label2 &&
            <Typography
                {...getTestID(`${ props.id }_label2`)}
                fontWeight="bold"
                fontSize={10}
            >
                {props.label2}
            </Typography>
        }
        <Typography
            {...getTestID(`${ props.id }_label`)}
            fontSize={10}
        >
            {props.label}
        </Typography>
    </Root>
);

export default DeviceConditionItem;
