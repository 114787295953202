/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import {MenuOpen} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import getTestID from '../../helper/functions/getTestID';
import BinsenseLogo from '../common/BinsenseLogo';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    margin: '16px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '6px',
    paddingTop: '6px',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '30px',
});

interface Props {
    open: boolean,
    setOpen: () => void,
}

const DrawerHeader = (props: Props) => (
    <Root
        onClick={props.setOpen}
    >
        <BinsenseLogo
            style={{
                height: '32px',
                width: 'auto',
            }}
            justIcon={!props.open}
        />
        <IconButton
            {...getTestID('open_close_drawer_button')}
            sx={{
                padding: 0,
            }}
        >
            <MenuOpen {...getTestID('hide_sidebar')}/>
        </IconButton>
    </Root>
);

export default DrawerHeader;
