/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {MarkerData} from '../../types/components/ApiTypes';
import getHardwareFieldsFromStructureData from './getHardwareFieldsFromStructureData';

const getPutBinBodyFromMarker = (marker: MarkerData) => {
    const data = marker.display?.grainBinData;
    return data ? {
        id: marker.id,
        binType: data.type.toString().toLowerCase(),
        capacity: data.bushelCapacity != null ? data.bushelCapacity : null,
        diameter: data.diameter,
        eaveHeight: data.eaveHeight != null ? data.eaveHeight : null,
        latitude: marker.latitude,
        longitude: marker.longitude,
        latLongOffset: false,
        name: marker.tooltip ? marker.tooltip.name : '',
        peakHeight: data.peakHeight != null ? data.peakHeight : null,
        hardware: getHardwareFieldsFromStructureData(data),
        settings: {hardwareType: data.device},
    } : {};
};

export default getPutBinBodyFromMarker;
