/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {BuildingData, GrainBagData, GrainBinData, GrainPileData} from '../../types/components/ApiTypes';
import getRemoteUnitsFromStructureData from './getRemoteUnitsFromStructureData';
import getUnitFields from './getUnitFields';
import getSoloUnitsFromStructureData from './getSoloUnitsFromStructureData';
import Constants from '../Constants';

const getHardwareFieldsFromStructureData = (data: GrainBinData | GrainBagData | GrainPileData | BuildingData) => ({
    masterUnits: data.deviceType === Constants.MASTER.toLowerCase() ? [ getUnitFields(data.sn[0], data.powerSource,
        data.device.toLowerCase() === Constants.PLUS.toLowerCase()) ] : [],
    remoteUnits: getRemoteUnitsFromStructureData(data),
    soloUnits: getSoloUnitsFromStructureData(data),
});

export default getHardwareFieldsFromStructureData;
