import {observer} from 'mobx-react-lite';
import {AlertHistoryData} from '../../../../types/components/ApiTypes';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import Constants from '../../../../helper/Constants';
import Icon from '@mdi/react';
import { mdiAlphaIBox, mdiAlphaSBox } from '@mdi/js';
import Colors from '../../../../helper/Colors';
import moment from 'moment';
import HoverTextButton from '../../../common/buttons/HoverTextButton';
import { Typography } from '@mui/material';
import getSmsResponse from '../../../../controllers/apiCalls/get/getSmsResponse';
import { useSnackbar } from 'notistack';

interface Props {
    row: AlertHistoryData,
    refreshAlertHistory: () => void,
}

const AlertHistoryTableRow = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const renderGridItem = (id: string, label: string, xs: number, lengthLimit?: number, children?: object) => (
        <DisplayGridLabel
            id={`${ id }`}
            label={label}
            xs={xs}
            lengthLimit={lengthLimit}
            children={children}
        />
    );

    const formatType = (type: string) => {
        switch (type.toLowerCase()) {
            case 'roi':
                return Constants.WEEKLY_RISE;
            case 'threshold_low':
                return Constants.LOW_THRESHOLD;
            case 'threshold_high':
                return Constants.HIGH_THRESHOLD;
            case 'threshold_sys':
                return Constants.SYSTEM_THRESHOLD;
            case 'voltage':
                return Constants.VOLTAGE;
        }

        return Constants.OTHER;

    };

    const replyI = () => {
        getSmsResponse(enqueueSnackbar, props.row.textId, Constants.REPLY_I, props.refreshAlertHistory);
    };

    const replyS = () => {
        getSmsResponse(enqueueSnackbar, props.row.textId, Constants.REPLY_S, props.refreshAlertHistory);
    };

    return (
        <DisplayGridContent
            topMargin='0px'
        >
            {renderGridItem(`${ props.row.id }_alert_time`, props.row.alertTime != null
                ? moment(new Date(props.row.alertTime)).format('MMMM Do, YYYY h:mm:ss A')
                : Constants.NA, 3)}
            {renderGridItem(`${ props.row.id }_message`, props.row.message, 4)}
            {renderGridItem(`${ props.row.id }_type`, formatType(props.row.type), 1)}
            {renderGridItem(`${ props.row.id }_reply`, props.row.reply, 2, undefined,
                !!!props.row.reply && props.row.type.toLowerCase() !== 'voltage'
                ?   (
                    <>
                        <HoverTextButton
                            id={`${ props.row.id }_reply_i`}
                            onClick={replyI}
                            icon={
                                <Icon
                                    path={mdiAlphaIBox}
                                    size={1}
                                    color={Colors.accent}
                                />
                            }
                            hoverText='Reply I'
                        />
                        <HoverTextButton
                            id={`${ props.row.id }_reply_s`}
                            onClick={replyS}
                            icon={
                                <Icon
                                    path={mdiAlphaSBox}
                                    size={1}
                                    color={Colors.accent}
                                />
                            }
                            hoverText='Reply S'
                        />
                    </>
                    )
                : props.row.type.toLowerCase() === 'voltage'
                ? <Typography fontWeight="bold">{Constants.NA}</Typography>
                :  <></>
            )}
            {renderGridItem(`${ props.row.id }_reply_time`, props.row.replyTime != null
                ? moment(new Date(props.row.replyTime)).format('MMMM Do, YYYY h:mm:ss A')
                : Constants.NA, 2)}
        </DisplayGridContent>
    );
});

export default AlertHistoryTableRow;
