/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {MarkerData, SiteLocationData} from '../../types/components/ApiTypes';
import CreateSiteMap from '../dealer/createSite/CreateSiteMap';
import LocationMarker from '../dealer/createSite/newSiteWizard/LocationMarker';
import {observer} from 'mobx-react-lite';
import BinsenseStore from '../../stores/BinsenseStore';
import getSelectedSiteIndex from '../../helper/functions/getSelectedSiteIndex';
import SetSiteLocationFormHeader from './SetSiteLocationFormHeader';
import Constants from '../../helper/Constants';
import getAllUserSiteName from '../../helper/functions/getAllUserSiteNames';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
});

const MapArea = styled('div')({
    width: '100%',
});

interface Props {
    site: SiteLocationData | null,
    setSite: Dispatch<SetStateAction<SiteLocationData | null>>,
    siteLocationInvalid: boolean,
    noLocationDetected?: boolean,
    mapHeight: number,
    showDelete?: boolean,
    close?: () => void,
    userId?: number,
}

const SetSiteLocationForm = observer((props: Props) => {
    const initialName = () => {
        if (props.noLocationDetected) {
            const index = getSelectedSiteIndex();
            return BinsenseStore.userData.sites[index].name;
        }
        return props.site ? props.site.name : '';
    };

    const [ name, setName ] = useState(initialName());
    const [ nameError, setNameError ] = useState('');
    const [ viewState, setViewState ] = useState(props.site ? {
        longitude: props.site.longitude !== null && props.site.longitude !== 0
            ? props.site.longitude : Constants.DEFAULT_LONGITUDE,
        latitude: props.site.latitude !== null && props.site.latitude !== 0
            ? props.site.latitude : Constants.DEFAULT_LATITUDE,
        zoom: props.site.zoom !== null && props.site.zoom !== 0 ? props.site.zoom : Constants.DEFAULT_ZOOM,
    } : {
        longitude: Constants.DEFAULT_LONGITUDE,
        latitude: Constants.DEFAULT_LATITUDE,
        zoom: Constants.DEFAULT_ZOOM,
    });
    const [ markerData, setMarkerData ] = useState<MarkerData[]>(props.site ? [ {
        id: props.site.id,
        longitude: props.site.longitude,
        latitude: props.site.latitude,
        draggable: true,
        icon: (<LocationMarker/>),
    } ] : []);

    useEffect(() => {
        if (name.trim() !== '' && markerData.length > 0) {
            props.setSite({
                name,
                id: markerData[0].id,
                latitude: markerData[0].latitude,
                longitude: markerData[0].longitude,
                zoom: viewState.zoom,
            });
        } else if (name.trim() === '') {
            props.setSite(null);
        }
    }, [ name, markerData, viewState ]);

    useEffect(() => {
        if (props.siteLocationInvalid && name.trim() === '') {
            setNameError(Constants.ENTER_SITE_NAME);
        } else if (props.siteLocationInvalid && getAllUserSiteName(props.userId).includes(name.toLowerCase())) {
            setNameError(Constants.SITE_NAME_IN_USE);
        } else {
            setNameError('');
        }
    }, [ props.siteLocationInvalid, name ]);

    return (
        <Root>
            <SetSiteLocationFormHeader
                name={{
                    value: name,
                    setValue: setName,
                    error: nameError,
                    setError: setNameError,
                }}
                markerData={markerData}
                setMarkerData={setMarkerData}
                viewState={viewState}
                setViewState={setViewState}
                id={props.site !== null ? props.site.id : undefined}
                showDelete={props.showDelete}
                close={props.close}
            />
            <MapArea>
                <CreateSiteMap
                    viewState={viewState}
                    setViewState={setViewState}
                    markerData={markerData}
                    setMarkerData={setMarkerData}
                    showSearch
                    toggleOnBottom
                    height={props.mapHeight}
                />
            </MapArea>
        </Root>
    );
});

export default SetSiteLocationForm;
