import callDeleteAPI from '../../callDeleteAPI';
import updateData from '../../../helper/functions/updateData';

const deleteDealerUser = (enqueueSnackbar: any, id: number) => {
    callDeleteAPI(`dealerUser/${ id }`,
        (response) => {
            if (response === 'OK') {
                updateData(enqueueSnackbar);
            }
        },
        enqueueSnackbar,
        'Dealer user deleted successfully'
    );
};

export default deleteDealerUser;