import { cloneDeep } from 'lodash';
import WebStore from '../../../stores/WebStore';
import callPostAPI from '../../callPostAPI';

const postWaiveCustomerBalance = (enqueueSnackbar: any, userId: number) => {
    callPostAPI(`payments/waiveCustomerBalance/${ userId }`, {},
        (response) => {
            if (response) {
                const customer = cloneDeep(WebStore.selectedCustomer);
                customer.balance.amount = 0;
                WebStore.setSelectedCustomer(customer, true);
            }
        },
        enqueueSnackbar,
        'Balance waived successfully.'
    );
};

export default postWaiveCustomerBalance;