/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {RemoteUnit, Site} from '../../types/components/ApiTypes';

const getCount = (remotes: RemoteUnit[]) => {
    let count = 0;
    remotes.forEach(remote => {
        if (remote.automation) {
            count++;
        }
    });
    return count;
};

const getAutomationRemotesCount = (site: Site) => {
    let remotesCount = 0;
    site.bins.forEach(s => {
        if (s.hardware?.remoteUnits) {
            remotesCount += getCount(s.hardware.remoteUnits);
        }
    });
    site.grainBags.forEach(s => {
        if (s.hardware?.remoteUnits) {
            remotesCount += getCount(s.hardware.remoteUnits);
        }
    });
    site.buildings.forEach(s => {
        if (s.hardware?.remoteUnits) {
            remotesCount += getCount(s.hardware.remoteUnits);
        }
    });
    site.automationHubs.forEach(s => {
        if (s.hardware?.remoteUnits) {
            remotesCount += getCount(s.hardware.remoteUnits);
        }
    });
    return remotesCount;
};

export default getAutomationRemotesCount;
