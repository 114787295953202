/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import { Site} from '../../types/components/ApiTypes';
import Constants from '../Constants';
import getSiteReportData from './getSiteReportData';

const siteHasAllMasterCalledIn = (site: Site) => {
    const reportData = getSiteReportData(site);
    let masterCount = 0;
    const masterCheckIns = [];

    reportData.forEach((bin) => {
        bin.devices.forEach((device) => {
            if (device.deviceType.toLowerCase() === Constants.MASTER.toLowerCase()) {
                masterCount++;
                if (device.checkInTime) {
                    masterCheckIns.push(device.checkInTime);
                }
            }
        });
    });

    // automation hub
    if (site.automationHubs.length > 0) {
        site.automationHubs.forEach((hub) => {
            hub.hardware.masterUnits.forEach((master) => {
                masterCount++;
                if (master.checkInTime) {
                    masterCheckIns.push(master.checkInTime);
                }
            });
        });
    }

    return (masterCount !== 0 && masterCheckIns.length === masterCount) ? true : false;

};

export default siteHasAllMasterCalledIn;