/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Button, styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import { Dispatch, SetStateAction } from 'react';
import { BinGraphData } from '../../../../../types/components/ApiTypes';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingTop: '8px',
    paddingBottom: '8px',
});

interface Props {
    data: BinGraphData[],
    label: string,
    start: any,
    setStart: Dispatch<SetStateAction<any>>,
    end: any,
    setEnd: Dispatch<SetStateAction<any>>,
    sensorId?: string,
    structId: number,
    showModal: boolean,
    setShowModal: Dispatch<SetStateAction<boolean>>,
}

const ChartMLTagButton = observer((props: Props) => (
    <div>
        <Root>
            <Row>
                <Button
                    variant='contained'
                    onClick={() => props.setShowModal(true)}
                >{props.label}</Button>
            </Row>
        </Root>
    </div>

));

export default ChartMLTagButton;
