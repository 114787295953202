import callDeleteAPI from '../../callDeleteAPI';
import updateData from '../../../helper/functions/updateData';
import WebStore from '../../../stores/WebStore';
import Constants from '../../../helper/Constants';

const deleteStructure = (enqueueSnackbar: any, id: number) => {
    callDeleteAPI(`structure/${ id }`,
        (response) => {
            if (response === 'OK') {
                updateData(enqueueSnackbar);
                if (WebStore.currentPage === Constants.VIEW_DEVICE) {
                    WebStore.goToPreviousPage();}
            }
        },
        enqueueSnackbar,
        'Structure deleted successfully'
    );
};

export default deleteStructure;