import callPutAPI from '../../callPutAPI';
import updateData from '../../../helper/functions/updateData';

const putBinSettings = (enqueueSnackbar: any, id: number, body: any, message?: string) => {
    callPutAPI(`binSettings/${ id }`,
        body,
        (response) => {
            if (response === 'OK') {
                updateData(enqueueSnackbar);
            }
        },
        enqueueSnackbar,
        message ? message : 'Bin settings updated successfully'
    );
};

export default putBinSettings;