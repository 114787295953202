/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {useState} from 'react';
import Constants from '../../../../helper/Constants';
import {DistanceUnit, GrainPileData, GrainPileShape, StructIdSerials} from '../../../../types/components/ApiTypes';
import PileDetailsForm from './PileDetailsForm';
import StructureSetupModal from '../binSetupModal/StructureSetupModal';
import getAllStructNames from '../../../../helper/functions/getAllStructNames';

interface Props {
    open: boolean,
    close: () => void,
    onSubmit: (grainPileData: GrainPileData) => void,
    grainPileData?: GrainPileData,
    masterSerials: string[],
    automationMasterSerials: string[],
    structureId?: number,
    disableAutomation: boolean,
    markerStructNames?: string[],
    markerSerials?: StructIdSerials[],
    siteSerials?: StructIdSerials[],
    siteId?: number,
}

const PileSetupModal = (props: Props) => {
    const [ name, setName ] = useState(props.grainPileData?.name
        ? props.grainPileData.name : '');
    const [ nameError, setNameError ] = useState('');
    const [ shape, setShape ] = useState(props.grainPileData?.shape
        ? props.grainPileData.shape : GrainPileShape.circle);
    const [ shapeError, setShapeError ] = useState('');
    const [ diameter, setDiameter ] = useState(props.grainPileData?.diameter
        ? props.grainPileData.diameter.toString() : '');
    const [ diameterError, setDiameterError ] = useState('');
    const [ quantity, setQuantity ] = useState(props.grainPileData?.quantity
        ? props.grainPileData.quantity.toString() : '');
    const [ quantityError, setQuantityError ] = useState('');
    const [ powerSource, setPowerSource ] = useState(props.grainPileData?.powerSource
        ? props.grainPileData.powerSource : '');
    const [ powerSourceError, setPowerSourceError ] = useState('');
    const structNames = getAllStructNames(props.markerStructNames, props.siteId);
    const nameOnPageLoad = props.grainPileData?.name ? props.grainPileData.name : '';

    const submit = (device: string, deviceType: string, sn: string[], associatedMaster: string) => {
        props.onSubmit({
            name,
            diameter: Number(diameter),
            unit: DistanceUnit.feet,
            shape,
            quantity: Number(quantity),
            device,
            deviceType: deviceType.toLowerCase(),
            sn,
            associatedMaster,
            powerSource,
        });
    };

    const isPileDetailsValid = () => name !== '' && diameter !== '' && quantity !== '' && powerSource !== '' &&
        !((nameOnPageLoad === '' || nameOnPageLoad.trim().toLowerCase() !== name.trim().toLowerCase()) &&
        structNames && structNames.some(item => item.trim().toLowerCase() === name.trim().toLowerCase()));

    const resetErrors = () => {
        if (name !== '') {
            setNameError('');
        }
        if (diameter !== '') {
            setDiameterError('');
        }
        if (quantity !== '') {
            setQuantityError('');
        }
        if (powerSource !== '') {
            setPowerSourceError('');
        }
    };

    const setPileErrors = () => {
        resetErrors();
        if (name === '') {
            setNameError('Please enter a name for the pile');
        }
        if ((nameOnPageLoad === '' || nameOnPageLoad.trim().toLowerCase() !== name.trim().toLowerCase()) &&
            structNames && structNames.some(item => item.trim().toLowerCase() === name.trim().toLowerCase())) {
            setNameError('This name already exists');
        }
        if (diameter === '') {
            setDiameterError('Please enter a diameter for the pile');
        }
        if (quantity === '') {
            setQuantityError('Please enter a quantity for the pile');
        }
        if (powerSource === '') {
            setPowerSourceError('Please enter a power source for this pile.');
        }
    };

    return (
        <StructureSetupModal
            id='pile_setup'
            modalTitle={Constants.PILE_SETUP}
            firstStepTitle={Constants.PILE_DETAILS}
            isFirstStepValid={isPileDetailsValid}
            setFirstStepErrors={setPileErrors}
            resetFirstStepErrors={resetErrors}
            onSubmit={submit}
            close={props.close}
            data={props.grainPileData}
            masterSerials={props.masterSerials}
            automationMasterSerials={props.automationMasterSerials}
            structureId={props.structureId}
            disableAutomation={props.disableAutomation}
            markerSerials={props.markerSerials}
            siteSerials={props.siteSerials}
        >
            <PileDetailsForm
                name={{
                    value: name,
                    setValue: setName,
                    error: nameError,
                    setError: setNameError,
                }}
                shape={{
                    value: shape,
                    setValue: setShape,
                    error: shapeError,
                    setError: setShapeError,
                }}
                diameter={{
                    value: diameter,
                    setValue: setDiameter,
                    error: diameterError,
                    setError: setDiameterError,
                }}
                quantity={{
                    value: quantity,
                    setValue: setQuantity,
                    error: quantityError,
                    setError: setQuantityError,
                }}
                powerSource={{
                    value: powerSource,
                    setValue: setPowerSource,
                    error: powerSourceError,
                    setError: setPowerSourceError,
                }}
            />
        </StructureSetupModal>
    );
};

export default PileSetupModal;
