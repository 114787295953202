/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {MarkerData} from '../../types/components/ApiTypes';
import Constants from '../Constants';

const getMasterSerialsFromMarkers = (markers: MarkerData[]) => {
    const masterMarkers = markers.filter(marker =>
        marker.display?.grainBinData?.deviceType &&
            marker.display.grainBinData.deviceType === Constants.MASTER.toLowerCase() ||
        marker.display?.grainBagData?.deviceType &&
            marker.display.grainBagData.deviceType === Constants.MASTER.toLowerCase() ||
        marker.display?.buildingData?.deviceType &&
            marker.display.buildingData.deviceType === Constants.MASTER.toLowerCase() ||
        marker.display?.grainPileData?.deviceType &&
            marker.display.grainPileData.deviceType === Constants.MASTER.toLowerCase());
    return masterMarkers.map(marker => {
        if (marker.display?.grainBinData) return marker.display.grainBinData.sn[0];
        if (marker.display?.grainBagData) return marker.display.grainBagData.sn[0];
        if (marker.display?.buildingData) return marker.display.buildingData.sn[0];
        if (marker.display?.grainPileData) return marker.display.grainPileData.sn[0];
        return '';
    });
};

export default getMasterSerialsFromMarkers;
