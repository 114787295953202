import BinsenseStore from '../../stores/BinsenseStore';
import WebStore from '../../stores/WebStore';
import { MarkerData } from '../../types/components/ApiTypes';
import { getAutomationCount, getAutomationCountByMarkerData } from './getAutomationCount';
import { getLiveCount, getLiveCountByMarkerData } from './getLiveCount';
import { getSoloCount, getSoloCountByMarkerData } from './getSoloCount';

/**
 * checkAddToSubscription
 * If this is being called from a dealer page, we need to call getUserStructures and getBalanceByUsername on the page this is
 * being called from.
 *
 * @param markerData the data on the site a device is being added to.
 * @param user if user object was passed, this function is being called by a dealer
 * @param selectedSite passed from the dealer side when we can't retrieve the current site from webstore
 * @param newSite passed as true when this function is being called from the create site wizard
 * @returns increase subscription message
 */
const checkAddToSubscription = (markerData: MarkerData[], user?: string, selectedSite?: number, newSite?: boolean) => {
    // Get the user's current number of subscribed devices
    const currentLiveCount: number = user != undefined
        ? WebStore.selectedCustomer.balance.subscribedMasters > 0
            ? WebStore.selectedCustomer.balance.subscribedMasters
            : getLiveCount(false, user, selectedSite, newSite)
        : BinsenseStore.userData.balance.subscribedMasters > 0
            ? BinsenseStore.userData.balance.subscribedMasters
            : getLiveCount(undefined, undefined, undefined, newSite);
    const currentAutomationCount: number = user != undefined
        ? WebStore.selectedCustomer.balance.subscribedAutomation > 0
            ? WebStore.selectedCustomer.balance.subscribedAutomation
            : getAutomationCount(undefined, user, selectedSite, newSite)
        : BinsenseStore.userData.balance.subscribedAutomation > 0
            ? BinsenseStore.userData.balance.subscribedAutomation
            : getAutomationCount(undefined, undefined, undefined, newSite);
    const currentSoloCount: number = user != undefined
        ? WebStore.selectedCustomer.balance.subscribedSolos > 0
            ? WebStore.selectedCustomer.balance.subscribedSolos
            : getSoloCount(undefined, user, selectedSite, newSite)
        : BinsenseStore.userData.balance.subscribedSolos > 0
            ? BinsenseStore.userData.balance.subscribedSolos
            : getSoloCount(undefined, undefined, undefined, newSite);

    // Get the user's new number of subscribed devices
    const newLiveCount: number = getLiveCountByMarkerData(markerData, user, selectedSite, newSite);
    const newSoloCount: number = getSoloCountByMarkerData(markerData, user, selectedSite, newSite);
    const newAutomationCount: number = getAutomationCountByMarkerData(markerData, user, selectedSite, newSite);

    // Get the difference between the user's new and current counts
    const liveCountChange: number = newLiveCount - currentLiveCount;
    const soloCountChange: number = newSoloCount - currentSoloCount;
    const automationCountChange: number = newAutomationCount - currentAutomationCount;

    let message: string = '';

    // if user is increasing their subscription, prepare message
    if (liveCountChange > 0 || soloCountChange > 0 || automationCountChange > 0) {
        message = message.concat('Saving this site will add ');

        if (liveCountChange > 0) {
            message = message
                .concat(liveCountChange.toString())
                .concat(' master unit(s)');

            if (soloCountChange > 0 && automationCountChange > 0) {
                message = message.concat(', ');
            } else if (soloCountChange > 0 || automationCountChange > 0) {
                message = message.concat(' and ');
            }
        }
        if (soloCountChange > 0) {
            message = message
                .concat(soloCountChange.toString())
                .concat(' solo unit(s)');

            if (automationCountChange > 0) {
                message = message.concat(' and ');
            }
        }
        if (automationCountChange > 0) {
            message = message
                .concat(automationCountChange.toString())
                .concat(' automation unit(s)');
        }
        message = message.concat(' to your subscription plan.');
    }

    return message;
};

export default checkAddToSubscription;