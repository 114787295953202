/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {AutomationHubs, Bin, Building, GrainBag, Pile, RemoteUnit, Site} from '../../types/components/ApiTypes';
import Constants from '../Constants';
import { comparisonOfStructuresInAscendingOrder } from './functions';

const getPowerSource = (unit: any) => {
    if (unit.status.hasOwnProperty('acPower')) {
        return unit.status.acPower ? 'Plugged In' : 'Battery Powered';
    }
    return Constants.NA;
};

const getAssociation = (bin: Bin | GrainBag | Building | Pile | AutomationHubs,
                        units: any[], associations: any[], device: string, structType: string) =>
    units.map((unit, index) => associations.push({
        associationId: `${ bin.id }_${ unit.serial }_${ index }`,
        id: bin.id,
        structure: bin.name,
        serialNumber: unit.serial,
        masterSerial: unit.hasOwnProperty('masterSerial') ? (unit as RemoteUnit).masterSerial : undefined,
        status: unit.status?.reportingStatus ? unit.status.reportingStatus : Constants.NA,
        firmwareVersion: unit.firmwareVersion,
        powerSource: getPowerSource(unit),
        fanControl: bin.hardware.fans.length > 0 ? 'Yes' : 'No',
        device,
        structId: bin.id,
        sortOrder: bin.sortOrder,
        type: structType,
}));

const getBinAssociations = (struct: Bin | GrainBag | Building | Pile, associations: any[], structType: string) => {
    getAssociation(struct, struct.hardware?.masterUnits ? struct.hardware.masterUnits : [], associations, 'Master', structType);
    getAssociation(struct, struct.hardware?.remoteUnits ? struct.hardware.remoteUnits : [], associations, 'Remote', structType);
    getAssociation(struct, struct.hardware?.soloUnits ? struct.hardware.soloUnits : [], associations, 'Solo', structType);
};

const getAutomationHubAssociations = (hub: AutomationHubs, associations: any[], structType: string) => {
    getAssociation(hub, hub.hardware?.masterUnits ? hub.hardware.masterUnits : [], associations, 'Master', structType);
    getAssociation(hub, hub.hardware?.remoteUnits ? hub.hardware.remoteUnits : [], associations, 'Remote', structType);
};

const getAssociationsData = (site: Site) => {
    const associations: any[] = [];
    site.bins.forEach(bin => getBinAssociations(bin, associations, Constants.BIN));
    site.grainBags.forEach(bag => getBinAssociations(bag, associations, Constants.BAG));
    site.buildings.forEach(building => getBinAssociations(building, associations, Constants.BUILDING));
    // site.piles.forEach(pile => getBinAssociations(pile, associations));
    site.automationHubs.forEach(hub => getAutomationHubAssociations(hub, associations, Constants.AUTOMATION_HUB));
    const clonedData = JSON.parse(JSON.stringify(associations));
    return comparisonOfStructuresInAscendingOrder(clonedData);
};

export default getAssociationsData;
