/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import ContentPanel from '../../common/ContentPanel';
import {observer} from 'mobx-react-lite';
import AddDevicesPaper from '../../dealer/createSite/newSiteWizard/AddDevicesPaper';
import React, {useEffect, useMemo, useState} from 'react';
import getSelectedSiteIndex from '../../../helper/functions/getSelectedSiteIndex';
import BinsenseStore from '../../../stores/BinsenseStore';
import WebStore from '../../../stores/WebStore';
import {MarkerData} from '../../../types/components/ApiTypes';
import getMarkerData from '../../../helper/functions/getMarkerData';
import Constants from '../../../helper/Constants';
import SubmitButton from '../../common/buttons/SubmitButton';
import saveMarkerData from '../../../helper/functions/saveMarkerData';
import {useSnackbar} from 'notistack';
import getNewSite from '../../../helper/functions/getNewSite';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import {styled} from '@mui/material';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import changeDrawerAfterConfirmation from '../../../helper/functions/changeDrawerAfterConfirmation';
import checkAddToSubscription from '../../../helper/functions/checkAddToSubscription';

const SkeletonRoot = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '16px',
});

const AddNew = observer(() => {
    const { enqueueSnackbar } = useSnackbar();
    const [ addToSubscriptionMessage, setAddToSubscriptionMessage ] = React.useState('');
    const [ showAddToSubscriptionModel, setShowAddToSubscriptionModel ] = React.useState(false);

    const selectedSite = useMemo(() => {
        const index = getSelectedSiteIndex();
        return index > -1 ? BinsenseStore.userData.sites[index] : getNewSite();
    }, [ WebStore.selectedSiteId, BinsenseStore.userData ]);

    const site = useMemo(() => ({
        id: selectedSite.id,
        name: selectedSite.name,
        latitude: selectedSite.latitude,
        longitude: selectedSite.longitude,
        zoom: 17,
    }), [ selectedSite ]);

    const [ markerData, setMarkerData ] = useState<MarkerData[]>([]);

    const onMarkerUpdate = () => {
        if (!WebStore.editingMap) {
            WebStore.setEditingMap(true);
        }
    };

    useEffect(() => {
        setMarkerData(getMarkerData(selectedSite));
    }, [ selectedSite ]);

    const confirmationSubmit = () => {
        WebStore.setEditingMap(false);
        changeDrawerAfterConfirmation();
    };

    const save = () => {
        saveMarkerData(enqueueSnackbar, selectedSite, markerData);
        WebStore.setEditingMap(false);
    };

    const confirmAddToSubscription = () => {
        let message: string = '';
        if (selectedSite.live) {
            message = checkAddToSubscription(markerData);
        }

        if (message.length > 0) {
            setAddToSubscriptionMessage(message);
            setShowAddToSubscriptionModel(true);
        } else {
            save();
        }
    };

    return (
        <ContentPanel>
            <AddDevicesPaper
                site={site}
                markerData={markerData}
                setMarkerData={setMarkerData}
                fullWidth
                onMarkerUpdate={onMarkerUpdate}
            />
            <div style={{ paddingRight: '20px' }}>
                {!BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
                    <SubmitButton
                        id='save_changes_button'
                        text={Constants.SAVE_CHANGES}
                        onClick={confirmAddToSubscription}
                    />
                ) : (
                    <SkeletonRoot>
                        <DashboardSkeleton
                            height={40}
                            width='100px'
                        />
                    </SkeletonRoot>
                )}
            </div>

            {WebStore.isLoseChangesConfirmationOpen && (
                <ConfirmationModal
                    id="exit_add_new_confirmation"
                    open
                    close={() => WebStore.setIsLoseChangesConfirmationOpen(false)}
                    title="Warning"
                    submit={confirmationSubmit}
                    message="Are you sure you want to exit? All unsaved changes will be lost."
                />
            )}

            {showAddToSubscriptionModel && (
                <ConfirmationModal
                    id='add_to_subscription_confirmation'
                    open
                    close={() => setShowAddToSubscriptionModel(false)}
                    submit={save}
                    title={Constants.INCREASE_SUBSCRIPTION}
                    message={addToSubscriptionMessage}
                />
            )}

        </ContentPanel>
    );
});

export default AddNew;
