/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo} from 'react';
import {styled, Tooltip, Typography} from '@mui/material';
import getMapTooltipStyling from '../../helper/functions/getMapTooltipStyling';
import getTestID from '../../helper/functions/getTestID';
import WebStore from '../../stores/WebStore';
import Constants from '../../helper/Constants';

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    alignSelf: 'center',
    maxWidth: '140px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px',
});

interface Props {
    device: any,
    children: any,
    fontSize?: number,
}

const RemoteTooltip = (props: Props) => {
    const tooltipStyle = useMemo(getMapTooltipStyling, [ WebStore.lightMode ]);

    const renderContent = () => (
        <Column>
            <Row>
                <Typography fontSize={props.fontSize}>
                    {/* props.device may be based on different objects, which may store serial number in 'serial' or 'serialNumber' property */}
                    <b>{ Constants.REMOTE.concat(': ')}</b> { props.device.serial ?? props.device.serialNumber }
                </Typography>
            </Row>
            <Row>
                <Typography fontSize={props.fontSize}>
                    {`(${ Constants.MASTER }: ${ props.device.masterSerial })`}
                </Typography>
            </Row>
        </Column>
    );

    return (
        <Tooltip
            {...getTestID(`${ props.device.serial ?? props.device.serialNumber }_remote_tooltip`)}
            title={renderContent()}
            componentsProps={{
                tooltip: {
                    style: tooltipStyle,
                },
            }}
            disableHoverListener={!props.device.serial && !props.device.serialNumber}
            style={{ cursor: props.device.serial || props.device.serialNumber ? 'pointer' : undefined }}
        >
            { props.children }
        </Tooltip>
    );
};

export default RemoteTooltip;
