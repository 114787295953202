/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';
import {SelectOptions} from '../../../types/components/ApiTypes';

const StyledTypography = styled(Typography)(({theme}) => ({
    // @ts-ignore
    color: theme.palette.primary.toggleButtonLabel,
    fontSize: '12px',
    paddingBottom: '8px',
}));

interface Props {
    title?: string,
    value: string,
    setValue: (newValue: any) => void,
    options: SelectOptions[],
    size?: 'small' | 'medium' | 'large',
    disabled?: boolean,
}

const ToggleButtonInput = (props: Props) => {
    const handleChange = (event: any, newValue: string | null) => {
        if (newValue !== null) {
            props.setValue(newValue);
        }
    };

    return (
        <div>
            {props.title && (
                <StyledTypography>
                    {props.title}
                </StyledTypography>
            )}
            <ToggleButtonGroup
                color="primary"
                value={props.value}
                exclusive
                onChange={handleChange}
                size={props.size}
            >
                {props.options.map(option => (
                    <ToggleButton
                        key={option.id}
                        {...getTestID(option.id)}
                        value={option.value !== undefined ? option.value : option.title}
                        disabled={props.disabled}
                    >
                        {option.title}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </div>
    );
};

export default ToggleButtonInput;
