import {styled, Typography} from '@mui/material';
import BaseModal from './BaseModal';
import getTestID from '../../../helper/functions/getTestID';
import {Warning} from '@mui/icons-material';
import Constants from '../../../helper/Constants';

const Container = styled('div')({
    width: '450px',
    padding: '16px',
});

interface Props {
    id: string,
    open: boolean,
    close: () => void,
    submit: () => void,
    email: string,
}

function DeleteCustomerModal(props: Props) {
    const submit = () => {
        props.submit();
        props.close();
    };

    return (
        <BaseModal
            id={props.id}
            open={props.open}
            close={props.close}
            submit={submit}
            title={Constants.DELETE_CUSTOMER}
            titleObject={<Warning color="error"/>}
            buttonColor="error"
            submitButtonText={Constants.DELETE}
        >
            <Container>
                <Typography {...getTestID('popup_message')}>
                    {`Are you sure you want to delete the customer ${ props.email }? This will remove the
                    customer and all of their historical data forever.`}
                </Typography>
            </Container>
        </BaseModal>
    );
}

export default DeleteCustomerModal;
