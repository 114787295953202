/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Bin, Building, GrainBag, Pile} from '../../types/components/ApiTypes';

const getSerialNumbers = (struct: Bin | GrainBag | Building | Pile) => [
        ...struct.hardware.masterUnits.map(unit => unit.serial),
        ...struct.hardware.remoteUnits.map(unit => unit.serial),
        ...struct.hardware.soloUnits.map(unit => unit.serial),
];

export default getSerialNumbers;
