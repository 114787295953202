/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import Cookies from 'js-cookie';

const getToken = () => {
    if (process.env.REACT_APP_SIM === 'sim') {
        // This cookie value changes on the Intragrain server end because it only stays valid for a certain amount of time.
        // See test procedures on the Binsense confluence page for more info on how and when to update it
        Cookies.set('IGTAccessToken',
            // eslint-disable-next-line max-len
            '3bb100f6a429dba8740a4aaf98a2abd3b284a97e409a0baaec41fa8ef18c7da4ddbb6f409ba19a03320c5086324c0d12c39af8912e9795babd6af61ba96a052c1654709755380');
    }

    const cookie = Cookies.get('IGTAccessToken');
    return cookie ? cookie.toString() : '';
};

export default getToken;
