import callAPI from '../../callAPI';

const getTransferToCustomer = (enqueueSnackbar: any, siteId: number, setTransfer: any) => {
    callAPI(`transferOwnership/${ siteId }`,
        (response) => {
            if (response === 'OK') {
                setTransfer(true);
                enqueueSnackbar('Successfully transferred to customer', {variant: 'success'});
            }
        },
        enqueueSnackbar
    );
};

export default getTransferToCustomer;