/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Cable} from '../../types/components/ApiTypes';
import getMaxNumberOfSensors from './getMaxNumberOfSensors';
import getBinViewAvg from './getBinViewAvg';
import getTempUnit from './getTempUnit';
import getBinViewHighThreshold from './getBinViewHighThreshold';
import { displayTemp } from './functions';
import getSensorColor from './getSensorColor';

const getBinViewTableRows = (cables: Cable[], highTemperatureThreshold: number, lowTemperatureThreshold: number) => {
    const numberOfRows = getMaxNumberOfSensors(cables);
    const rows: any[] = [];
    let avgValue: number;
    for (let i = numberOfRows - 1; i >= 0; i--) {
        avgValue = Number(getBinViewAvg(i, cables, true));
        rows.push({
            id: i,
            row: i + 1,
            // eslint-disable-next-line
            avgTemp: ` ${ displayTemp( getTempUnit() === 'F', avgValue) } ${ getTempUnit() }`,
            avgMoisture: `${ getBinViewAvg(i, cables) }%`,
            tempThresholdHigh: getBinViewHighThreshold(i, cables, true),
            moistureThresholdHigh: false,
            sensorColor: getSensorColor(avgValue, false, highTemperatureThreshold, lowTemperatureThreshold),
        });
    }
    return rows;
};

export default getBinViewTableRows;
