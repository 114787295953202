/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Grid} from '@mui/material';
import StorageGridItem from './StorageGridItem';
import StorageGridAutomationHub from './StorageGridAutomationHub';
// import { AutomationHubs } from '../../../../types/components/ApiTypes';
import BinsenseStore from '../../../../stores/BinsenseStore';
import {observer} from 'mobx-react-lite';
import StorageGridItemSkeleton from './StorageGridItemSkeleton';
import { Site } from '../../../../types/components/ApiTypes';

interface Props {
    site: Site,
    structures: any[],
    hubs: any[],
}

const SKELETON_IDS = [ 0,1,2,3,4,5 ];

const StorageGrid = observer((props: Props) => (
    <Grid
        container
        spacing={2}
        alignItems="flex-start"
    >
        {!BinsenseStore.awaitingApi && !BinsenseStore.awaitingUserData ? (
            props.hubs != null ? props.hubs.map(hub => (
                <StorageGridAutomationHub
                    site={props.site}
                    key={`${ hub.id }_hub_grid_item`}
                    hub={hub}
                />
            )) : <></>
        ) : (
            SKELETON_IDS.map(id => (
                <StorageGridItemSkeleton key={`${ id }_storage_skeleton`}/>
            ))
        )}

        {!BinsenseStore.awaitingApi && !BinsenseStore.awaitingUserData ? (
            props.structures.map(bin => (
                <StorageGridItem
                    bin={bin}
                    key={`${ bin.id }_grid_item`}
                />
            ))
        ) : (
            SKELETON_IDS.map(id => (
                <StorageGridItemSkeleton key={`${ id }_storage_skeleton`}/>
            ))
        )}
    </Grid>
));

export default StorageGrid;
