/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import TitlePaper from '../../common/TitlePaper';
import Constants from '../../../helper/Constants';
import {useMemo, useState} from 'react';
import SelectFieldInput from '../../common/input/SelectFieldInput';
import SetSiteLocationModal from '../../common/SetSiteLocationModal';
import SubmitButton from '../../common/buttons/SubmitButton';
import {styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {Site} from '../../../types/components/ApiTypes';
import getIndex from '../../../helper/functions/getIndex';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

interface Props {
    sites: Site[],
    userId?: number,
}

const ManageSitePaper = observer((props: Props) => {
    const [ site, setSite ] = useState('');
    const [ siteError, setSiteError ] = useState('');

    const [ editId, setEditId ] = useState<number | null>(null);

    const selectedSite = useMemo(() => {
        if (editId) {
            const index = getIndex(Number(editId), props.sites);
            return index !== -1 ? props.sites[index] : null;
        }
        return null;
    }, [ editId, props.sites ]);

    const options = useMemo(() => props.sites.map(s => ({
        title: s.name,
        value: s.id.toString(),
        id: `${ s.name }_option`,
    })), [ props.sites ]);

    return (
        <TitlePaper title={Constants.MANAGE_SITE}>
            <Root>
                <SelectFieldInput
                    id="manage_site_select"
                    label={Constants.SITE}
                    field={{
                        value: site,
                        setValue: setSite,
                        error: siteError,
                        setError: setSiteError,
                    }}
                    options={options}
                />
                <SubmitButton
                    id="manage_site_button"
                    text={Constants.MANAGE_SITE}
                    onClick={() => setEditId(Number(site))}
                    disabled={site === ''}
                />
            </Root>
            {selectedSite !== null && (
                <SetSiteLocationModal
                    site={selectedSite}
                    open
                    close={() => {
                        setEditId(null);
                        setSite('');
                    }}
                    showDelete
                    userId={props.userId}
                />
            )}
        </TitlePaper>
    );
});

export default ManageSitePaper;