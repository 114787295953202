/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import DisplayGridHeaderRow from '../../common/displayTable/DisplayGridHeaderRow';
import DisplayGridSortableTableHeader from '../../common/displayTable/DisplayGridSortableTableHeader';
import Constants from '../../../helper/Constants';
import BinsenseStore from '../../../stores/BinsenseStore';
import AlertsTableRow from './AlertsTableRow';
import PaginationTable from '../../common/displayTable/PaginationTable';

interface Props {
    dealer?: boolean
}

const AlertsTable = observer((props: Props) => {
    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const rows = useMemo(() => BinsenseStore.alertsData, [ BinsenseStore.alertsData ]);

    const renderSortableHeader = (id: string, label: string, xs: number, key: string) => (
        <DisplayGridSortableTableHeader
            id={id}
            label={label}
            xs={xs}
            data={rows}
            setData={BinsenseStore.setAlertsData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderRow = (row: any) => (
        <AlertsTableRow
            data={row}
            dealer={props.dealer}
            key={`${ row.id }_alerts_table_row`}
        />
    );

    return (
        <div style={{ width: '100%' }}>
            <PaginationTable
                rows={rows}
                renderRow={renderRow}
            >
                <DisplayGridHeaderRow>
                    {props.dealer && renderSortableHeader('customer', Constants.CUSTOMER, 1.5, 'customer')}
                    {props.dealer && renderSortableHeader('site', Constants.SITE, 1.5, 'site')}
                    {renderSortableHeader('message', Constants.MESSAGE, props.dealer ? 4 : 6, 'bin')}
                    {renderSortableHeader('created_date', Constants.CREATED_AT, 2, 'date')}
                    {renderSortableHeader('status', Constants.STATUS, props.dealer ? 1.5 : 2, 'status')}
                    {renderSortableHeader('grain_type', Constants.GRAIN_TYPE, props.dealer ? 1.5 : 2, 'grainType')}
                </DisplayGridHeaderRow>
            </PaginationTable>
        </div>
    );
});

export default AlertsTable;
