import {useState, useEffect} from 'react';
import TitlePaper from '../../../../common/TitlePaper';
import {styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import Constants from '../../../../../helper/Constants';
import { Bin } from '../../../../../types/components/ApiTypes';
import StyledSwitch from '../../../../common/input/StyledSwitch';
import putBinSettings from '../../../../../controllers/apiCalls/put/putBinSettings';
import { useSnackbar } from 'notistack';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
});

interface Props {
    bin: Bin,
}

const AlertSettingsPaper = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ voltageAlerts, setVoltageAlerts ] = useState(props.bin.settings.voltageAlerts);
    const [ submitDisabled, setSubmitDisabled ] = useState(true);

    const submit = () => {
        // handle submit
        const settings = {
            voltageAlerts,
            lowTemperatureThreshold: props.bin.settings.lowTemperatureThreshold,
        };

        putBinSettings(enqueueSnackbar, props.bin.id, settings);
    };

    useEffect(() => {
        setVoltageAlerts(props.bin.settings.voltageAlerts);
        setSubmitDisabled(voltageAlerts === props.bin.settings.voltageAlerts);
    }, [ props.bin.settings.voltageAlerts ]);

    useEffect(() => {
        setSubmitDisabled(voltageAlerts === props.bin.settings.voltageAlerts);
    }, [ voltageAlerts ]);

    return (
        <div
            style={{
                width: '100%',
                paddingTop: '32px',
            }}
        >
            <TitlePaper
                rightTitle={(
                    <Row>
                        <Typography color="primary">
                            {Constants.ALERT_SETTINGS}
                        </Typography>
                    </Row>
                )}
            >
                <StyledSwitch
                    id="toggle_voltage_alerts"
                    description={Constants.VOLTAGE_ALERTS}
                    value={voltageAlerts}
                    setValue={setVoltageAlerts}
                />
                <SubmitButton
                    id="alert_settings_save_changes_button"
                    text={Constants.SAVE_CHANGES}
                    onClick={submit}
                    disabled={submitDisabled}
                />
            </TitlePaper>
        </div>
    );
});

export default AlertSettingsPaper;
