/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import Constants from '../Constants';

const getUnitFields = (sn: string, powerSource: string, isAutomation: boolean) => ({
    serial: sn,
        status: {
            acPower: powerSource === Constants.PLUGGED_IN,
        },
    automation: isAutomation,
});

export default getUnitFields;
