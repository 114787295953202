/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Grid} from '@mui/material';
import DisplayGridItem from '../../../../common/displayTable/DisplayGridItem';
import {observer} from 'mobx-react-lite';
import DeviceBinDetailsPaper from './DeviceBinDetailsPaper';
import AutomationSettingsPaper from './automationSettings/AutomationSettingsPaper';
import TemperatureSettingsPaper from './TemperatureSettingsPaper';
import AlertSettingsPaper from './AlertSettingsPaper';

interface Props {
    bin: any,
    disableAutomationSettings: boolean,
}

const ViewDeviceDisplayPage = observer((props: Props) => (
    <Grid
        container
        spacing={2}
        alignItems="flex-start"
    >
        <DisplayGridItem
            xs={6}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <DeviceBinDetailsPaper bin={props.bin}/>
                <TemperatureSettingsPaper bin={props.bin}/>
            </div>
        </DisplayGridItem>
        <DisplayGridItem
                xs={6}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <AutomationSettingsPaper
                    bin={props.bin}
                    disabled={props.disableAutomationSettings}
                />
                { (props.bin.hardware.masterUnits.length > 0 ||
                    props.bin.hardware.remoteUnits.length > 0 ||
                    props.bin.hardware.soloUnits.length > 0) &&
                    <AlertSettingsPaper bin={props.bin} />
                }
            </div>
        </DisplayGridItem>
    </Grid>
    ));

export default ViewDeviceDisplayPage;
