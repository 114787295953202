/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import {Dispatch} from 'react';
import moment from 'moment';
import {observer} from 'mobx-react-lite';
import DateTimeSelector from './DateTimeSelector';
import BinsenseStore from '../../stores/BinsenseStore';
import DashboardSkeleton from './DashboardSkeleton';

interface Props {
    start: any,
    setStart: Dispatch<any>,
    end: any,
    setEnd: Dispatch<any>,
    small?: boolean,
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
});

const DateRangePicker = observer((props: Props) => !BinsenseStore.awaitingUserData ? (
    <Row>
        <DateTimeSelector
            id="start_date"
            label="Start Date"
            value={props.start}
            setValue={props.setStart}
            marginRight
            maxDate={props.end ? props.end : moment(new Date())}
            small={props.small}
        />
        <DateTimeSelector
            id="end_date"
            label="End Date"
            value={props.end}
            setValue={props.setEnd}
            minDate={props.start}
            maxDate={moment(new Date())}
            small={props.small}
        />
    </Row>
) : (
    <Row>
        <DashboardSkeleton
            height={40}
            style={{ marginRight: '4px' }}
        />
        <DashboardSkeleton height={40}/>
    </Row>
));

export default DateRangePicker;
