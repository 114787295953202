/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import WebStore from '../../stores/WebStore';
import Constants from '../../helper/Constants';

const goToViewDevicePage = (id: number) => {
    WebStore.setViewDeviceId(id, true);
    // WebStore.setViewDeviceType(binType, true);
    WebStore.goToNewPage(Constants.VIEW_DEVICE);
};

export default goToViewDevicePage;
