/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Bin, Building, GrainBag} from '../../../../types/components/ApiTypes';
import GridItemDeviceTooltip from './GridItemDeviceTooltip';
import getTimeSince from '../../../../helper/functions/getTimeSince';
import getHardwareType from '../../../../helper/functions/getHardwareType';
import getDevices from '../../../../helper/functions/getDevices';
import PowerSymbol from '../../../common/PowerSymbol';
import Constants from '../../../../helper/Constants';
import RemoteTooltip from '../../../common/RemoteTooltip';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

interface Props {
    bin: Bin | GrainBag | Building,
}

const StorageGridItemHeaderRight = observer((props: Props) => {
    const devices = useMemo(() => getDevices(props.bin), [ props.bin ]);
    const hardwareType = useMemo(() => getHardwareType(props.bin), [ props.bin ]);

    const renderDeviceTypeRow = () => (
        <Typography fontSize={11}>
            <b>{hardwareType.toLowerCase() === Constants.DIRECT.toLowerCase() ||
                hardwareType.toLowerCase() === Constants.UNMONITORED.toLowerCase()
                ? hardwareType.concat(': ') : ''}</b>
            <b>{hardwareType.toLowerCase() !== Constants.DIRECT.toLowerCase() &&
                hardwareType.toLowerCase() !== Constants.UNMONITORED.toLowerCase() &&
                devices.length === 1
                ? devices[0].type.concat(': ') : ''}</b>
            {devices.length === 1 ? devices[0].serial : `${ devices.length } devices`}
        </Typography>
    );

    return (
        <Root>
            <div style={{ paddingRight: '4px' }}>
                {devices.length === 1 && devices[0].type.toLowerCase() === Constants.REMOTE.toLowerCase() ? (
                    <RemoteTooltip
                        device={devices[0]}
                        fontSize={11}
                    >
                        {renderDeviceTypeRow()}
                    </RemoteTooltip>
                ) : renderDeviceTypeRow()}

                {devices.length > 0 && (
                    <Typography fontSize={11}>
                        {getTimeSince(props.bin.status.lastReading)}
                    </Typography>
                )}
            </div>
            {devices.length === 1 ? (
                <PowerSymbol
                    acPower={devices[0].acPower}
                    batteryLevel={devices[0].batteryLevel}
                />
            ) : (
                <GridItemDeviceTooltip
                    devices={devices}
                    id={props.bin.id}
                />
            )}
        </Root>
    );
});

export default StorageGridItemHeaderRight;
