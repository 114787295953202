/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import {Dispatch, SetStateAction, useState} from 'react';
import WebStore from '../../../../stores/WebStore';
import {styled} from '@mui/material';
import {Customer, MarkerData, Site} from '../../../../types/components/ApiTypes';
import EditMap from './EditMap';
import MapAutoPlayer from './MapAutoPlayer';
// import MapLegend from '../../../dealer/createSite/siteToolbar/MapLegend';
import CreateSiteToolbar from '../../../dealer/createSite/CreateSiteToolbar';
import saveMarkerData from '../../../../helper/functions/saveMarkerData';
import {useSnackbar} from 'notistack';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import Constants from '../../../../helper/Constants';
import checkAddToSubscription from '../../../../helper/functions/checkAddToSubscription';
import { getBalanceByUsername } from '../../../../controllers/apiCalls/get/getBalance';
import getUserStructures from '../../../../controllers/apiCalls/get/getUserStructures';

interface Props {
    markerData: MarkerData[],
    setMarkerData: Dispatch<SetStateAction<MarkerData[]>>,
    viewState: any,
    site: Site,
    user?: Customer,
    deletedStructureArray?: any[],
    setDeletedStructureArray: Dispatch<SetStateAction<number[]>>,
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const StorageMapHeader = observer((props: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ showAddToSubscriptionModel, setShowAddToSubscriptionModel ] = useState(false);
    const [ addToSubscriptionMessage, setAddToSubscriptionMessage ] = useState('');

    // const [ features, setFeatures ] = useState({});
    //
    // const onFeaturesUpdate = useCallback(e => {
    //     setFeatures(currFeatures => {
    //         const newFeatures = {...currFeatures};
    //         for (const f of e.features) {
    //             newFeatures[f.id] = f;
    //         }
    //         return newFeatures;
    //     });
    // }, []);
    //
    // const onFeaturesDelete = useCallback(e => {
    //     setFeatures(currFeatures => {
    //         const newFeatures = {...currFeatures};
    //         for (const f of e.features) {
    //             delete newFeatures[f.id];
    //         }
    //         return newFeatures;
    //     });
    // }, []);

    const save = () => {
        saveMarkerData(enqueueSnackbar, props.site, props.markerData, props.deletedStructureArray);
        props.setDeletedStructureArray([]);
        WebStore.setEditingMap(!WebStore.editingMap);
    };

    const onEditClicked = () => {
        if (WebStore.editingMap) {
            if (props.site.live) {
                const message: string = checkAddToSubscription(props.markerData, props.user
                    ? props.user.account.userName
                    : undefined, props.site.id);
                if (message.length > 0) {
                    setAddToSubscriptionMessage(message);
                    setShowAddToSubscriptionModel(true);
                } else {
                    save();
                }
            } else {
                save();
            }
        } else {
            if (WebStore.selectedView === Constants.DEALER && props.user) {
                getUserStructures(undefined, props.user.account.userName);
                getBalanceByUsername(undefined, props.user.account.userName);
            }
            WebStore.setEditingMap(!WebStore.editingMap);
        }
    };

    return (
        <div>
            <Row>
                <EditMap
                    onClick={onEditClicked}
                    siteId={props.site.id}
                />
                {!WebStore.editingMap && (
                    <MapAutoPlayer numMarkers={props.markerData.length}/>
                )}
                {/*{!WebStore.editingStorageMap && (*/}
                {/*    <MapLegend compressItems/>*/}
                {/*)}*/}
            </Row>
            {WebStore.editingMap && (
                <CreateSiteToolbar
                    viewState={props.viewState}
                    markerData={props.markerData}
                    setMarkerData={props.setMarkerData}
                    siteId={props.site.id}
                />
            )}

            {showAddToSubscriptionModel && (
                <ConfirmationModal
                    id='add_to_subscription_confirmation'
                    open
                    close={() => setShowAddToSubscriptionModel(false)}
                    submit={save}
                    title={Constants.INCREASE_SUBSCRIPTION}
                    message={addToSubscriptionMessage}
                />
            )}
        </div>
    );
});

export default StorageMapHeader;
