/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {
    Battery0Bar,
    Battery1Bar,
    Battery2Bar,
    Battery3Bar,
    Battery4Bar,
    Battery5Bar,
    Battery6Bar,
    BatteryFull
} from '@mui/icons-material';

interface Props {
    batteryLevel: number,
}

const BatterySymbol = (props: Props) => {
    if (props.batteryLevel > 90) {
        return <BatteryFull/>;
    }
    if (props.batteryLevel > 75) {
        return <Battery6Bar/>;
    }
    if (props.batteryLevel > 60) {
        return <Battery5Bar/>;
    }
    if (props.batteryLevel > 45) {
        return <Battery4Bar/>;
    }
    if (props.batteryLevel > 30) {
        return <Battery3Bar/>;
    }
    if (props.batteryLevel > 15) {
        return <Battery2Bar/>;
    }
    if (props.batteryLevel > 5) {
        return <Battery1Bar/>;
    }
    return <Battery0Bar/>;
};

export default BatterySymbol;
