/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import React, {Dispatch, SetStateAction} from 'react';
import {styled, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import {observer} from 'mobx-react-lite';
import {WarningAmber} from '@mui/icons-material';
import getTestID from '../../../helper/functions/getTestID';
import Constants from '../../../helper/Constants';
import logout from '../../../helper/functions/logout';
import SubmitButton from '../../common/buttons/SubmitButton';
import WebStore from '../../../stores/WebStore';
import BaseModal from '../../common/modal/BaseModal';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '16px',
});

const ButtonRow = styled('div')({
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

export interface Props {
    setModalOpen: Dispatch<SetStateAction<boolean>>
}

const OverduePaymentModal = observer((props: Props) => {
    const onPayNow = () => {
        props.setModalOpen(false);
        WebStore.setSelectedDrawer(Constants.BILLING_AND_PAYMENT, true);
    };

    return (
        <BaseModal
            id="overdue_payment_modal"
            open
            close={onPayNow}
            title={Constants.OVERDUE_PAYMENT}
            hideButtons
        >
            <Root>
                <Row>
                    <WarningAmber
                        color="primary"
                        fontSize="large"
                    />
                    <Typography
                        sx={{
                            paddingLeft: '16px',
                            fontSize: '20px',
                            fontWeight: 800,
                        }}
                    >
                        Warning! Please read
                    </Typography>
                </Row>

                <Typography {...getTestID('overdue_message')}>
                    {Constants.OVERDUE_WARNING}
                </Typography>

                <ButtonRow>
                    <Button
                        {...getTestID('logout_dashboard')}
                        color="error"
                        sx={{marginRight: '16px'}}
                        onClick={logout}
                    >
                        Log Out
                    </Button>
                    <SubmitButton
                        id={'make_payment_button'}
                        text={Constants.PAY_NOW}
                        onClick={onPayNow}
                    />
                </ButtonRow>
            </Root>
        </BaseModal>
    );
});

export default OverduePaymentModal;
