import callPostAPI from '../../callPostAPI';
import updateData from '../../../helper/functions/updateData';

const postMachineLeariningTraining = (enqueueSnackbar: any, body: any) => {
    callPostAPI('tagMLCandidate',
        body,
        (response) => {
            console.log(response);
            updateData(enqueueSnackbar);
        },
        enqueueSnackbar,
        'Machine Learning Candidate logged'
    );
};

export default postMachineLeariningTraining;