/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useLayoutEffect, useRef, useState, useEffect} from 'react';
import Constants from '../../../../../../helper/Constants';
import {observer} from 'mobx-react-lite';
import SubmitButton from '../../../../../common/buttons/SubmitButton';
import putAutomationSettings from '../../../../../../controllers/apiCalls/put/putAutomationSettings';
import {useSnackbar} from 'notistack';
import NaturalAirDryingForm from './NaturalAirDryingForm';
import AerationForm from './AerationForm';
import {IconButton, Box, ButtonGroup, Paper} from '@mui/material';
import AutomationSettingsPaperHeader from './AutomationSettingsPaperHeader';
import { AutomationOperationModes, AutomationSettings, Bin } from '../../../../../../types/components/ApiTypes';
import BinsenseStore from '../../../../../../stores/BinsenseStore';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { displayTemp, fToC, tempConversion } from '../../../../../../helper/functions/functions';
import getTempUnit from '../../../../../../helper/functions/getTempUnit';
import getBinFanStatus from '../../../../../../helper/functions/getBinFanStatus';
import getGrain from '../../../../../../helper/functions/getGrain';

interface Props {
    bin: Bin,
    disabled: boolean,
}

const AutomationSettingsPaper = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ type, setType ] = useState<AutomationOperationModes>(props.bin.automationSettings?.operationMode != null
        ? props.bin.automationSettings.operationMode : AutomationOperationModes.aeration);
    const automationRunning = props.bin.automationSettings?.running != null
        ? props.bin.automationSettings.running : false;
    const grain = getGrain(props.bin.settings.productId);

    //natural air drying fields
    const [ avgGrainMoisture, setAvgGrainMoisture ] = useState(props.bin.status.avgMoisture
        ? props.bin.status.avgMoisture.toString() : 'No average moisture');
    const [ avgGrainMoistureError, setAvgGrainMoistureError ] = useState('');
    const [ targetMoisture, setTargetMoisture ] = useState(props.bin.automationSettings?.targetMoisture != null
        ? props.bin.automationSettings.targetMoisture.toString()
        : BinsenseStore.dataModel.automationDefaults.targetMoisture);
    const [ targetMoistureError, setTargetMoistureError ] = useState('');
    const [ temperatureLow, setTemperatureLow ] = useState(
        props.bin.automationSettings?.targetTemperatureRangeLow != null
        ? displayTemp(getTempUnit() === 'F', props.bin.automationSettings?.targetTemperatureRangeLow)
        : displayTemp(getTempUnit() === 'F',
        Number(BinsenseStore.dataModel.automationDefaults.minimumTemperature)));
    const [ temperatureLowError, setTemperatureLowError ] = useState('');
    const [ temperatureHigh, setTemperatureHigh ] = useState(
        props.bin.automationSettings?.targetTemperatureRangeHigh != null
        ? displayTemp(getTempUnit() === 'F', props.bin.automationSettings?.targetTemperatureRangeHigh)
        : displayTemp(getTempUnit() === 'F',
        Number(BinsenseStore.dataModel.automationDefaults.maximumTemperature)));
    const [ temperatureHighError, setTemperatureHighError ] = useState('');
    const [ plenumRise, setPlenumRise ] = useState(props.bin.automationSettings?.plenumRise != null
        ? displayTemp(getTempUnit() === 'F', props.bin.automationSettings?.plenumRise, true)
        : displayTemp(getTempUnit() === 'F',
            Number(BinsenseStore.dataModel.automationDefaults.plenumRise), true));
    const [ plenumRiseError, setPlenumRiseError ] = useState('');
    const [ emcLow, setEmcLow ] = useState(props.bin.automationSettings?.targetEmcRangeLow != null
        ? props.bin.automationSettings?.targetEmcRangeLow.toString()
        : grain.minimumEmc);
    const [ emcLowError, setEmcLowError ] = useState('');
    const [ emcHigh, setEmcHigh ] = useState(props.bin.automationSettings?.targetEmcRangeHigh != null
        ? props.bin.automationSettings?.targetEmcRangeHigh.toString()
        : grain.maximumEmc);
    const [ emcHighError, setEmcHighError ] = useState('');

    // common temperature constants converts
    const degrees0 = tempConversion(getTempUnit() === 'F', 0);
    const degrees0Incremental = tempConversion(getTempUnit() === 'F', 0, true);
    const degrees40 = tempConversion(getTempUnit() === 'F', 40);
    const degrees10Incremental = tempConversion(getTempUnit() === 'F', 10, true);
    const degreesMinus20 = tempConversion(getTempUnit() === 'F', -20);
    const degrees30 = tempConversion(getTempUnit() === 'F', 30);
    const degrees60 = tempConversion(getTempUnit() === 'F', 60);

    //aeration fields
    const [ maxGrainTemp, setMaxGrainTemp ] = useState(props.bin.status?.maxTemperature != null
        ? displayTemp(getTempUnit() === 'F', props.bin.status?.maxTemperature) : 'No max temperature');
    const [ maxGrainTempError, setMaxGrainTempError ] = useState('');
    const [ targetTemp, setTargetTemp ] = useState(props.bin.automationSettings?.targetTemperature != null
        ? displayTemp(getTempUnit() === 'F', props.bin.automationSettings?.targetTemperature)
        : displayTemp(getTempUnit() === 'F', Number(BinsenseStore.dataModel.automationDefaults.targetTemperature)));
    const [ targetTempError, setTargetTempError ] = useState('');
    const [ minAllowedTemp, setMinAllowedTemp ] =
        useState(props.bin.automationSettings?.minimumAllowedTemperature != null
        ? displayTemp(getTempUnit() === 'F', props.bin.automationSettings.minimumAllowedTemperature)
        : displayTemp(getTempUnit() === 'F',
        Number(BinsenseStore.dataModel.automationDefaults.minimumAllowedTemperature)));
    const [ minAllowedTempError, setMinAllowedTempError ] = useState('');
    const [ aerationEmcLow, setAerationEmcLow ] = useState(props.bin.automationSettings?.targetEmcRangeLow != null
        ? props.bin.automationSettings.targetEmcRangeLow.toString()
        : grain.minimumEmc);
    const [ aerationEmcLowError, setAerationEmcLowError ] = useState('');
    const [ aerationEmcHigh, setAerationEmcHigh ] = useState(props.bin.automationSettings?.targetEmcRangeHigh != null
        ? props.bin.automationSettings.targetEmcRangeHigh.toString()
        : grain.maximumEmc);
    const [ aerationEmcHighError, setAerationEmcHighError ] = useState('');
    const [ aerationPlenumRise, setAerationPlenumRise ] = useState(props.bin.automationSettings?.plenumRise != null
        ? displayTemp(getTempUnit() === 'F', props.bin.automationSettings.plenumRise, true)
        : displayTemp(getTempUnit() === 'F', Number(BinsenseStore.dataModel.automationDefaults.plenumRise), true));
    const [ aerationPlenumRiseError, setAerationPlenumRiseError ] = useState('');

    const [ automationSettingsChanged, setAutomationSettingsChanged ] = useState(false);

    const isValid = () => {
        if (type == AutomationOperationModes.natural_air_drying) {
            return Number(temperatureLow) >= degrees0 && Number(temperatureLow) < Number(temperatureHigh) &&
                Number(temperatureHigh) <= degrees40 && Number(emcLow) < Number(emcHigh) &&
                Number(plenumRise) >= degrees0Incremental && Number(plenumRise) <= degrees10Incremental;
        } else {
            //default to aeration (Temperature Control)
            return Number(minAllowedTemp) >= degreesMinus20 && Number(minAllowedTemp) <= degrees30 &&
                Number(targetTemp) >= Number(minAllowedTemp) && Number(targetTemp) <= degrees60 &&
                Number(aerationEmcLow) < Number(aerationEmcHigh) &&
                Number(aerationPlenumRise) >= degrees0Incremental && Number(aerationPlenumRise) <= degrees10Incremental;
        }
    };

    const firstUpdate = useRef(true);

    useEffect(() => {
        setAerationEmcLow(props.bin.automationSettings?.targetEmcRangeLow != null
            ? props.bin.automationSettings.targetEmcRangeLow.toString()
        : grain.minimumEmc);
        setAerationEmcHigh(props.bin.automationSettings?.targetEmcRangeHigh != null
            ? props.bin.automationSettings.targetEmcRangeHigh.toString()
        : grain.maximumEmc);

        setEmcLow(props.bin.automationSettings?.targetEmcRangeLow != null
            ? props.bin.automationSettings?.targetEmcRangeLow.toString()
            : grain.minimumEmc);
        setEmcHigh(props.bin.automationSettings?.targetEmcRangeHigh != null
            ? props.bin.automationSettings?.targetEmcRangeHigh.toString()
            : grain.maximumEmc);
    }, [ props.bin ]);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        setAutomationSettingsChanged(true);
    });

    const resetAutomationValuesToDefault = () => {
        setAerationEmcLow(grain.minimumEmc);
        setAerationEmcHigh(grain.maximumEmc);
        setEmcLow(grain.minimumEmc);
        setEmcHigh(grain.maximumEmc);
        setMinAllowedTemp(displayTemp(getTempUnit() === 'F',
            Number(BinsenseStore.dataModel.automationDefaults.minimumAllowedTemperature)));
        setTemperatureLow(displayTemp(getTempUnit() === 'F',
            Number(BinsenseStore.dataModel.automationDefaults.minimumTemperature)));
        setTemperatureHigh(displayTemp(getTempUnit() === 'F',
            Number(BinsenseStore.dataModel.automationDefaults.maximumTemperature)));
        setAerationPlenumRise(displayTemp(getTempUnit() === 'F',
            Number(BinsenseStore.dataModel.automationDefaults.plenumRise), true));
        setPlenumRise(displayTemp(getTempUnit() === 'F',
            Number(BinsenseStore.dataModel.automationDefaults.plenumRise), true));
    };

    const setErrors = () => {
        if (Number(targetTemp) < Number(minAllowedTemp) || Number(targetTemp) > degrees60) {
            setTargetTempError
                (`Target temp. must be greater than the minimum allowed temp. and less than or equal to ${ degrees60.toString() }`);
        }
        if (Number(minAllowedTemp) < degreesMinus20 || Number(minAllowedTemp) > degrees30) {
            setMinAllowedTempError
                (`Temp. must be greater than ${ degreesMinus20.toString() } and less than or equal to ${ degrees30.toString() }`);
        }
        if ( Number(temperatureLow) < degrees0 || Number(temperatureLow) >= Number(temperatureHigh)) {
            setTemperatureLowError(`Min Temp. must be greater than ${ degrees0 } and less than Max Temp.`);
        }
        if ( Number(temperatureHigh) < degrees0 || Number(temperatureHigh) > degrees40) {
            setTemperatureHighError(`Max Temp. must be greater than Min Temp. and less than or equal to ${ degrees40.toString() }`);
        }

        if (type == AutomationOperationModes.natural_air_drying) {
            if ( Number(emcLow) >= Number(emcHigh)) {
                setEmcLowError('Min EMC must be less than Max EMC');
            }
        } else {
            if ( Number(aerationEmcLow) >= Number(aerationEmcHigh)) {
                setAerationEmcLowError('Min EMC must be less than Max EMC');
            }
        }

        if (Number(aerationPlenumRise) < degrees0Incremental || Number(aerationPlenumRise) > degrees10Incremental) {
            setAerationPlenumRiseError
                (`Plenum rise must be great than or equal to ${ degrees0Incremental.toString() } and less than or equal to 
                ${ degrees10Incremental.toString() }`);
        }
    };

    const submit = (toggleRunning: boolean) => {
        if (targetTempError !== '') {
            setTargetTempError('');
        }
        if (minAllowedTempError !== '') {
            setMinAllowedTempError('');
        }
        if (temperatureLowError !== '') {
            setTemperatureLowError('');
        }
        if (temperatureHighError !== '') {
            setTemperatureHighError('');
        }
        if (aerationEmcLowError !== '') {
            setAerationEmcLowError('');
        }
        if (emcLowError !== '') {
            setEmcLowError('');
        }
        if (isValid()) {
            let settings: AutomationSettings | null | undefined;
            if (toggleRunning) {
                settings = {
                    structId: Number(props.bin.id),
                    operationMode: props.bin.automationSettings?.operationMode != null
                        ? props.bin.automationSettings.operationMode : AutomationOperationModes.manual,
                    targetMoisture: Number(props.bin.automationSettings?.targetMoisture),
                    targetEmcRangeLow: Number(props.bin.automationSettings?.targetEmcRangeLow),
                    targetEmcRangeHigh: Number(props.bin.automationSettings?.targetEmcRangeHigh),
                    targetTemperature: Number(props.bin.automationSettings?.targetTemperature),
                    targetTemperatureRangeLow: Number(props.bin.automationSettings?.targetTemperatureRangeLow),
                    targetTemperatureRangeHigh: Number(props.bin.automationSettings?.targetTemperatureRangeHigh),
                    plenumRise: Number(props.bin.automationSettings?.plenumRise),
                    plenumOffset: Number(props.bin.automationSettings?.plenumOffset),
                    running: !automationRunning,
                    minimumAllowedTemperature: Number(props.bin.automationSettings?.minimumAllowedTemperature),
                };
            } else {
                settings = {
                    structId: Number(props.bin.id),
                    operationMode: type,
                    targetMoisture: Number(targetMoisture),
                    targetEmcRangeLow: Number(type == AutomationOperationModes.aeration ? aerationEmcLow : emcLow),
                    targetEmcRangeHigh: Number(type == AutomationOperationModes.aeration ? aerationEmcHigh : emcHigh),
                    targetTemperature: getTempUnit() === 'F'
                        ? fToC(Number(targetTemp))
                        : Number(targetTemp),
                    targetTemperatureRangeLow: getTempUnit() === 'F'
                        ? fToC(Number(temperatureLow))
                        : Number(temperatureLow),
                    targetTemperatureRangeHigh: getTempUnit() === 'F'
                        ? fToC(Number(temperatureHigh))
                        : Number(temperatureHigh),
                    plenumRise: getTempUnit() === 'F'
                        ? fToC(Number(type == AutomationOperationModes.aeration ? aerationPlenumRise : plenumRise), true)
                        : Number(type == AutomationOperationModes.aeration ? aerationPlenumRise : plenumRise),
                    plenumOffset: Number(0),
                    running: automationRunning,
                    minimumAllowedTemperature: getTempUnit() === 'F'
                        ? fToC(Number(minAllowedTemp))
                        : Number(minAllowedTemp),
                };
            }
            putAutomationSettings(enqueueSnackbar, props.bin.id, settings);
        } else {
            setErrors();
        }
    };

    const renderContent = () => {
        switch (type) {
            case AutomationOperationModes.aeration:
                return (
                    <AerationForm
                        maxGrainTemp={{
                            value: maxGrainTemp,
                            setValue: setMaxGrainTemp,
                            error: maxGrainTempError,
                            setError: setMaxGrainTempError,
                        }}
                        targetTemperature={{
                            value: targetTemp,
                            setValue: setTargetTemp,
                            error: targetTempError,
                            setError: setTargetTempError,
                        }}
                        minTemperature={{
                            value: minAllowedTemp,
                            setValue: setMinAllowedTemp,
                            error: minAllowedTempError,
                            setError: setMinAllowedTempError,
                        }}
                        emcLow={{
                            value: aerationEmcLow,
                            setValue: setAerationEmcLow,
                            error: aerationEmcLowError,
                            setError: setAerationEmcLowError,
                        }}
                        emcHigh={{
                            value: aerationEmcHigh,
                            setValue: setAerationEmcHigh,
                            error: aerationEmcHighError,
                            setError: setAerationEmcHighError,
                        }}
                        plenumRise={{
                            value: aerationPlenumRise,
                            setValue: setAerationPlenumRise,
                            error: aerationPlenumRiseError,
                            setError: setAerationPlenumRiseError,
                        }}
                        fanStatus={getBinFanStatus(props.bin.hardware.fans)}
                        disabled={props.disabled}
                    />
                );
            case AutomationOperationModes.natural_air_drying:
                return (
                    <NaturalAirDryingForm
                        averageGrainMoisture={{
                            value: avgGrainMoisture,
                            setValue: setAvgGrainMoisture,
                            error: avgGrainMoistureError,
                            setError: setAvgGrainMoistureError,
                        }}
                        targetMoisture={{
                            value: targetMoisture,
                            setValue: setTargetMoisture,
                            error: targetMoistureError,
                            setError: setTargetMoistureError,
                        }}
                        lowTemperature={{
                            value: temperatureLow,
                            setValue: setTemperatureLow,
                            error: temperatureLowError,
                            setError: setTemperatureLowError,
                        }}
                        highTemperature={{
                            value: temperatureHigh,
                            setValue: setTemperatureHigh,
                            error: temperatureHighError,
                            setError: setTemperatureHighError,
                        }}
                        plenumRise={{
                            value: plenumRise,
                            setValue: setPlenumRise,
                            error: plenumRiseError,
                            setError: setPlenumRiseError,
                        }}
                        emcLow={{
                            value: emcLow,
                            setValue: setEmcLow,
                            error: emcLowError,
                            setError: setEmcLowError,
                        }}
                        emcHigh={{
                            value: emcHigh,
                            setValue: setEmcHigh,
                            error: emcHighError,
                            setError: setEmcHighError,
                        }}
                        fanStatus={getBinFanStatus(props.bin.hardware.fans)}
                        disabled={props.disabled}
                    />
                );
            default:
                return (<div/>);
        }
    };

    return (
        <div style={{ width: '100%' }} >
            <Paper>
                <AutomationSettingsPaperHeader
                    type={type}
                    setType={setType}
                />
                {renderContent()}
                <ButtonGroup
                    sx={{display:'flex',
                    flexDirection:'row-reverse'}}
                >
                    <Box>
                        <SubmitButton
                            id="automation_settings_toggle_running_button"
                            text={automationRunning ? Constants.STOP : Constants.START}
                            onClick={() => submit(true)}
                            color={automationRunning ? 'error' : 'success'}
                            disabled={props.disabled}
                        />
                    </Box>
                    <Box sx={{mr:1}}>
                        <SubmitButton
                            id="automation_settings_save_changes_button"
                            text={Constants.SAVE_CHANGES}
                            onClick={() => submit(false)}
                            color="primary"
                            disabled={!automationSettingsChanged || props.disabled}
                        />
                    </Box>
                    {type == AutomationOperationModes.aeration &&
                        <IconButton
                            onClick={resetAutomationValuesToDefault}
                            disabled={props.disabled}
                        >
                            <SettingsBackupRestoreIcon />
                        </IconButton>
                    }
                    {type == AutomationOperationModes.natural_air_drying &&
                        <IconButton
                            onClick={resetAutomationValuesToDefault}
                            disabled={props.disabled}
                        >
                            <SettingsBackupRestoreIcon />
                        </IconButton>
                    }
                </ButtonGroup>
            </Paper>
        </div>
    );
});

export default AutomationSettingsPaper;
