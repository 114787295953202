/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Typography} from '@mui/material';
import Colors from '../../../../../helper/Colors';
import {observer} from 'mobx-react-lite';
import {useMemo} from 'react';
import Constants from '../../../../../helper/Constants';

interface Props {
    value: string,
    isTempHigh: boolean,
    isMoistureHigh: boolean,
    sensorColor: string;
}

const DetailedSensorTableCell = observer((props: Props) => {
    const values = useMemo(() => props.value.charAt(0) !== 'N' ? props.value.split(':') : [ Constants.NA ], [ props.value ]);

    const getColor = (isHigh: boolean, index: number) => {
        if (props.value[index] !== Constants.NA) {
            return isHigh ? Colors.tableLabelRed : Colors.tableLabelGreen;
        }
        return undefined;
    };

    const getTempColor = (sensorColor: string, index: number) => {
        if (props.value[index] !== Constants.NA) {
            return sensorColor;
        }
        return undefined;
    };

    return values.length === 2 ? (
        <div>
            <Typography color={getTempColor(props.sensorColor, 0)}>
                {values[0]}
            </Typography>
            <Typography color={getColor(props.isMoistureHigh, 1)}>
                {values[1]}
            </Typography>
        </div>
    ) : <Typography>{Constants.NA}</Typography>;
});

export default DetailedSensorTableCell;
