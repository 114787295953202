/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import handleError from './handleError';
import loginStore from '../stores/LoginStore';
import BinsenseStore from '../stores/BinsenseStore';
import getBaseURL from './getBaseURL';
import urlStore from '../stores/urlStore';
import {EnqueueSnackbar} from '../types/SnackbarTypes';
import showStatusFailMessage from './showStatusFailMessage';
import displaySuccessMessage from './displaySuccessMessage';

const callPutAPI = (uri: string,
                    body: object,
                    onSuccess: (response: any) => void,
                    enqueueSnackbar: EnqueueSnackbar,
                    successMessage: string,
                    onFail?: () => void) => {
    BinsenseStore.setAwaitingApi(true);

    fetch(
        `${ getBaseURL() }${ urlStore.baseDirectory }${ uri }`,
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-TOKEN': loginStore.token,
            },
            body: JSON.stringify(body),
        }
    )
        .then((response) => {
            handleError(response, enqueueSnackbar);
            return response.json();
        })
        .then((receivedServerData) => {
            setTimeout(() => {
                BinsenseStore.setAwaitingApi(false);
            }, 300);

            if (receivedServerData.status && receivedServerData.status === 'fail') {
                showStatusFailMessage(receivedServerData, enqueueSnackbar);
                if (onFail) {
                    onFail();
                }
                console.log('failed');
            } else {
                displaySuccessMessage(receivedServerData, successMessage, enqueueSnackbar);

                setTimeout(() => {
                    onSuccess(receivedServerData);
                }, 50);
            }
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error.message);
            BinsenseStore.setAwaitingApi(false);
        });
};

export default callPutAPI;
