/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import getTestID from '../../../helper/functions/getTestID';
import DisplayGridItem from './DisplayGridItem';
import TypographyWithLengthLimit from '../typography/TypographyWithLengthLimit';

interface Props {
    id: string,
    label: string | number,
    children?: object,
    header?: boolean,
    xs: number,
    textAlign?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined,
    fontSize?: number,
    lengthLimit?: number,
    dropDown?: object,
    icon?: object,
}

const DisplayGridLabel = (props: Props) => (
    <DisplayGridItem
        xs={props.xs}
        header={props.header}
    >
        {props.icon}
        {props.dropDown != undefined
            ? props.dropDown
            :   (
                <TypographyWithLengthLimit
                    {...getTestID(props.id)}
                    lengthLimit={props.lengthLimit}
                    fontWeight="bold"
                    fontSize={props.fontSize}
                    textAlign={props.textAlign ? props.textAlign : 'left'}
                >
                    {props.label}
                </TypographyWithLengthLimit>
                )
        }
        <>
            {props.children}
        </>
    </DisplayGridItem>
);

export default DisplayGridLabel;
