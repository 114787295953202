/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {MarkerData} from '../../types/components/ApiTypes';
import getHardwareFieldsFromStructureData from './getHardwareFieldsFromStructureData';

const getPutBuildingBodyFromMarker = (marker: MarkerData) => {
    const data = marker.display?.buildingData;
    return data ? {
        id: marker.id,
        name: data.name,
        rotation: data.rotation,
        latitude: marker.latitude,
        longitude: marker.longitude,
        latLongOffset: false,
        length: data.length,
        width: data.width,
        height: data.height,
        hardware: getHardwareFieldsFromStructureData(data),
        settings: {hardwareType: data.device},
    } : {};
};

export default getPutBuildingBodyFromMarker;
