/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import { EnqueueSnackbar } from '../../types/SnackbarTypes';
// import postValidEmail from '../../controllers/apiCalls/post/postValidEmail';
// import callAPI from '../../controllers/callAPI';
import postValidEmail from '../../controllers/apiCalls/post/postValidEmail';

const isEmailExists = async (enqueueSnackbar: EnqueueSnackbar, newEmail: string) => {
    if (newEmail && newEmail !== '') {
        const body = {
            email: newEmail,
        };
        const verification = await postValidEmail(enqueueSnackbar, body);
        if (verification !== 'OK') {
            return verification;
        }
        return '';
    } else {
        return 'Email is empty';
    }
};

export default isEmailExists;