/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {InputFieldProps, SelectOptions} from '../../../types/components/ApiTypes';
import getTestID from '../../../helper/functions/getTestID';

interface Props {
    id: string,
    label: string,
    field: InputFieldProps,
    options: SelectOptions[],
    textFieldProps?: object,
    helperText?: string,
    noBottomMargin?: boolean,
    small?: boolean,
    width?: string,
}

const SelectFieldInput = (props: Props) => {
    const handleChange = (event: any) => {
        if (props.field.setValue) {
            props.field.setValue(event.target.value);
        }
    };

    return (
        <FormControl
            sx={{
                width: props.width ? props.width : '100%',
                marginBottom: props.noBottomMargin ? '0px' : '10px',
            }}
        >
            <InputLabel>{props.label}</InputLabel>
            <Select
                {...getTestID(props.id)}
                label={props.label}
                value={props.field.value}
                onChange={handleChange}
                MenuProps={{
                    sx: {maxHeight: '400px'},
                }}
                {...props.textFieldProps}
                size={props.small ? 'small' : undefined}
            >
                {props.options.map(option => (
                    <MenuItem
                        {...getTestID(option.id)}
                        key={option.id}
                        value={option.value !== undefined ? option.value : option.title}
                    >
                        {option.title}
                    </MenuItem>
                ))}
            </Select>
            {(props.helperText || props.field.error) && (
                <FormHelperText
                    {...getTestID(`${ props.id }_helper_text`)}
                    error={props.field.error !== ''}
                >
                    {props.field.error ? props.field.error : props.helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default SelectFieldInput;
