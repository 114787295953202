/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {MarkerData} from '../../types/components/ApiTypes';
import getHardwareFieldsFromStructureData from './getHardwareFieldsFromStructureData';

const getPutGrainBagBodyFromMarker = (marker: MarkerData) => {
    const data = marker.display?.grainBagData;
    return data ? {
        id: marker.id,
        name: data.name,
        rotation: data.rotation,
        latitude: marker.latitude,
        longitude: marker.longitude,
        latLongOffset: false,
        length: data.length,
        hardware: getHardwareFieldsFromStructureData(data),
        settings: {hardwareType: data.device},
        width: data.width,
    } : {};
};

export default getPutGrainBagBodyFromMarker;
