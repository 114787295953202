import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';

const getServiceRecords = (enqueueSnackbar: any, id: number) => {
    callAPI(`serviceRecords/${ id }`,
        (response) => {
            BinsenseStore.setServiceRecordsData(response);
        },
        enqueueSnackbar
    );
};

export default getServiceRecords;