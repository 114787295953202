/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import Grid from '@mui/material/Grid';

interface Props {
    children: object | object[],
    topMargin?: string,
}

const DisplayGridContent = (props: Props) => (
    <Grid
        container
        spacing={2}
        sx={{
            marginLeft: '0px',
            width: 'auto',
            marginTop: props.topMargin ? props.topMargin : '-16px',
        }}
    >
        {props.children}
    </Grid>
);

export default DisplayGridContent;
