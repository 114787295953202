/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Delete} from '@mui/icons-material';
import Constants from '../../../../helper/Constants';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import DisplayGridActions from '../../../common/displayTable/DisplayGridActions';
import {observer} from 'mobx-react-lite';
import DisplayGridCheckBox from '../../../common/displayTable/DisplayGridCheckBox';
import {Dispatch, SetStateAction} from 'react';

interface Props {
    row: any,
    setDeleteId: Dispatch<SetStateAction<number | null>>,
    changeSelected: (id: number) => void,
}

const CableAssociationsTableRow = observer((props: Props) => {
    const renderGridLabel = (id: string, label: string, xs: number) => (
        <DisplayGridLabel
            id={`${ props.row.serial }_${ id }_cell`}
            label={label}
            xs={xs}
        />
    );

    return (
        <DisplayGridContent key={`${ props.row.serial }_cable_associations`}>
            <DisplayGridCheckBox
                xs={2}
                id={`${ props.row.serial }_selected`}
                value={props.row.selected}
                onChange={() => props.changeSelected(props.row.smartId)}
            />
            {renderGridLabel('smartId', props.row.smartId, 2)}
            {renderGridLabel('serial', props.row.serial, 2.5)}
            {renderGridLabel('type', props.row.type, 2.5)}
            <DisplayGridActions
                buttons={[
                    {
                        id: `${ props.row.serial }_cable_associations_delete_button`,
                        onClick: () => props.setDeleteId(props.row.id),
                        icon: <Delete/>,
                        hoverText: Constants.DELETE,
                    },
                ]}
                xs={3}
                key={`${ props.row.serial }_cable_associations_actions_cell`}
                id={props.row.serial}
            />
        </DisplayGridContent>
    );
});

export default CableAssociationsTableRow;
