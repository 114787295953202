/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useMemo, useState} from 'react';
import {Paper, styled} from '@mui/material';
import CarouselNavigation from './CarouselNavigation';
import CarouselVideoPlayer from './CarouselVideoPlayer';
import CarouselTextDisplay from './CarouselTextDisplay';
import CarouselImageDisplay from './CaourselImageDisplay';
import BinsenseStore from '../../../stores/BinsenseStore';
import {observer} from 'mobx-react-lite';
import DashboardSkeleton from '../DashboardSkeleton';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const Carousel = observer(() => {
    const [ curIndex, setCurIndex ] = useState(0);

    const data = useMemo(() => BinsenseStore.carouselData, [ BinsenseStore.carouselData ]);
    const [ reset, setReset ] = useState(true);
    const [ userClick, setUserClick ] = useState(false);

    const onNext = () => {
        setCurIndex(prevState => {
            if (prevState === data.length - 1) {
                return 0;
            }
            return prevState + 1;
        });
    };

    const onPrev = () => {
        setCurIndex(prevState => {
            if (prevState === 0) {
                return data.length - 1;
            }
            return prevState - 1;
        });
    };

    const currentDisplay = useMemo(() => data[curIndex], [ data, curIndex ]);

    useEffect(() => {
        if (!userClick && reset) {
            onNext();
        }
    },[ userClick, reset ]);

    setTimeout(() => setReset(!reset), (2500));

    return !BinsenseStore.awaitingUserData ? (
        <Paper
            onClick={() => setUserClick(true)}
            sx={{
                height: '100%',
                padding: !BinsenseStore.awaitingUserData ? '0px' : undefined,
                position: 'relative',
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <CarouselNavigation
                    data={data}
                    curIndex={curIndex}
                    setCurIndex={setCurIndex}
                    onPrevClick={onPrev}
                    onNextClick={onNext}
                />
                {currentDisplay.type === 'YOUTUBE' && (
                    <CarouselVideoPlayer src={currentDisplay.url}/>
                )}
                {currentDisplay.type === 'IMAGE' && (
                    <CarouselImageDisplay src={currentDisplay.src}/>
                )}
                {(currentDisplay.title || currentDisplay.subtext) && (
                    <CarouselTextDisplay
                        title={currentDisplay.title}
                        subtext={currentDisplay.subtext}
                        url={currentDisplay.url}
                        src={currentDisplay.src}
                    />
                )}
            </div>
        </Paper>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={180}/>
        </SkeletonRoot>
    );
});

export default Carousel;
