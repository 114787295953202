/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import Constants from '../../../helper/Constants';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../common/displayTable/DisplayGridContent';
import DisplayGridActions from '../../common/displayTable/DisplayGridActions';
import {Delete, Edit} from '@mui/icons-material';
import {Dispatch, SetStateAction, useMemo} from 'react';
import {MyUsersTableData} from '../../../types/components/ApiTypes';
import useWindowSize from '../../../helper/hooks/useWindowSize';

interface Props {
    row: MyUsersTableData,
    setEdit: Dispatch<SetStateAction<number | null>>,
    setDeleteId: Dispatch<SetStateAction<number | null>>,
    setDeleteMessage: Dispatch<SetStateAction<string>>,
}

const MyUsersTableRow = (props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const renderLabel = (id: string, label: string) => (
        <DisplayGridLabel
            id={`${ id }_my_user`}
            label={label ? label : Constants.NA}
            xs={largeScreen ? 3.5 : 10 / 3}
            lengthLimit={largeScreen ? 26 : 18}
        />
    );

    return (
        <DisplayGridContent key={`${ props.row.id }_user`}>
            {renderLabel(`${ props.row.id }_email`, props.row.email)}
            {renderLabel(`${ props.row.id }_name`, props.row.name)}
            {renderLabel(`${ props.row.id }_dealer`, props.row.dealer)}
            <DisplayGridActions
                buttons={[
                    {
                        id: `${ props.row.email }_my_user_edit_button`,
                        onClick: () => props.setEdit(props.row.id),
                        icon: <Edit/>,
                        hoverText: Constants.EDIT,
                    },
                    {
                        id: `${ props.row.email }_my_user_delete_button`,
                        onClick: () => {
                            props.setDeleteId(props.row.id);
                            props.setDeleteMessage(props.row.name);
                        },
                        icon: <Delete/>,
                        hoverText: Constants.DELETE,
                    },
                ]}
                xs={largeScreen ? 1.5 : 2}
                key={`${ props.row.id }_user_actions_cell`}
                id={props.row.email}
            />
        </DisplayGridContent>
    );
};

export default MyUsersTableRow;
