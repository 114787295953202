/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Autocomplete, Grid, TextField} from '@mui/material';
import TitlePaper from '../../common/TitlePaper';
import {useEffect, useMemo, useState} from 'react';
import GridTextFieldInput from '../../common/input/GridTextFieldInput';
import Constants from '../../../helper/Constants';
import SubmitButton from '../../common/buttons/SubmitButton';
import BinsenseStore from '../../../stores/BinsenseStore';
import getTestID from '../../../helper/functions/getTestID';
import HoverTextButton from '../../common/buttons/HoverTextButton';
import {Delete} from '@mui/icons-material';
import {Customer} from '../../../types/components/ApiTypes';
import WebStore from '../../../stores/WebStore';
import deleteDealerMyCustomer from '../../../controllers/apiCalls/delete/deleteDealerMyCustomer';
import { useSnackbar } from 'notistack';
import getIndex from '../../../helper/functions/getIndex';
import putUserSettings from '../../../controllers/apiCalls/put/putUserSettings';
import DeleteCustomerModal from '../../common/modal/DeleteCustomerModal';

interface Props {
    user: Customer,
}

const EditCustomerAccountPaper = (props: Props) => {
    const [ newUsername, setNewUsername ] = useState(props.user.account.userName);
    const [ newUsernameError, setNewUsernameError ] = useState('');
    const [ dealer, setDealer ] = useState<string | null>('');
    const [ dealerId, setDealerId ] = useState(props.user.dealerId ? props.user.dealerId : -1);
    const [ dealerInput, setDealerInput ] = useState('');
    const [ dealerError, setDealerError ] = useState('');
    const {enqueueSnackbar} = useSnackbar();

    const [ deleteUserOpen, setDeleteUserOpen ] = useState(false);

    const getDealerGroupName = (dealerGroupId: number | undefined, dealerList: any) => {
        if (dealerGroupId !== undefined) {
            const index = getIndex(dealerGroupId, dealerList);
            return index !== -1 ? dealerList[index].name : '';
        }
        return '';
    };

    const dealersOptions = useMemo(() => {
        // get dealer groups (active dealers and Intragrain dealer)
        let dealerList = BinsenseStore.dealerUserData.dealerGroups
        .filter(iDealer => iDealer.active === true || iDealer.name === 'Intragrain');

        // if the signed in dealer user does not belong to 'Intragrain' dealer group (dealerId = 1),
        // the dealerList will only include one dealer group name that the selected user belongs to
        if (BinsenseStore.dealerUserData.dealerId && BinsenseStore.dealerUserData.dealerId !== 1) {
            const index = getIndex(props.user.dealerId!, dealerList);
            dealerList = index !== -1 ? [ dealerList[index] ] : [];
        }
    return dealerList;
    }, [ BinsenseStore.dealerUserData.dealerGroups ]);

    useEffect(() => {
        setDealer(getDealerGroupName(props.user.dealerId, dealersOptions));
        setDealerInput(getDealerGroupName(props.user.dealerId, dealersOptions));
    }, [ props.user, dealersOptions ]);

    const dealerChange = (newValue: string | null) => {
        setDealer(newValue);
        const index = dealersOptions.findIndex(d => d.name === newValue);
        if (index > -1) {
            setDealerId(dealersOptions[index].id);
        }
    };

    const deleteMycustomer = () => {
        deleteDealerMyCustomer(enqueueSnackbar, Number(props.user.id));
        WebStore.goToPreviousPage();
    };

    const isValid = () => {
        let valid = true;

        if (dealer === '') {
            valid = false;
            setDealerError('Field is required.');
        }

        if (newUsername === '') {
            valid = false;
            setNewUsernameError('Field is required.');
        }

        return valid;
    };

    const onSubmit = () => {
        if (isValid()) {
            const settings = {
                dealerId: dealerId !== props.user.dealerId ? dealerId : null,
                customer: {
                    account: {
                        userName: newUsername !== props.user.account.userName ? newUsername : null,
                    },
                },
            };
            putUserSettings(enqueueSnackbar, props.user.id, settings);
        }
    };

    return (
        <TitlePaper
            title="Edit Customer Account"
            rightTitle={(
                <HoverTextButton
                    id={`${ props.user.id }_delete_user_button`}
                    icon={<Delete/>}
                    hoverText={Constants.DELETE_USER}
                    onClick={() => setDeleteUserOpen(true)}
                />
            )}
        >
            <Grid
                container
                spacing={2}
            >
                <GridTextFieldInput
                    id="change_username_input"
                    label="Change Username"
                    field={{
                        value: newUsername,
                        setValue: setNewUsername,
                        error: newUsernameError,
                        setError: setNewUsernameError,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 50,
                        },
                    }}
                    fullWidth
                />
                <Grid
                    item
                    xs={12}
                >
                    <Autocomplete
                        value={dealer}
                        onChange={(event, newValue) => dealerChange(newValue)}
                        inputValue={dealerInput}
                        onInputChange={(event, newInputValue) => {
                            setDealerInput(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                {...getTestID('user_dealer_input')}
                                fullWidth
                                label={Constants.USERS_DEALER}
                                helperText={dealerError}
                                error={dealerError !== ''}
                            />
                        )}
                        options={dealersOptions.length > 0 ? dealersOptions.map(d => d.name) : [ Constants.NA ]}
                    />
                </Grid>
            </Grid>
            <SubmitButton
                id="save_changes_button"
                text={Constants.SAVE_CHANGES}
                onClick={onSubmit}
            />
            {deleteUserOpen && (
                <DeleteCustomerModal
                    id={`${ props.user.id }_delete_user_modal`}
                    open
                    close={() => setDeleteUserOpen(false)}
                    submit={deleteMycustomer}
                    email={props.user.account.accountEmail ?? props.user.account.userName}
                />
            )}
        </TitlePaper>
    );
};

export default EditCustomerAccountPaper;
