/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import {useEffect, useMemo, useState} from 'react';
import Constants from '../../../../helper/Constants';
import DisplayGridHeaderRow from '../../../common/displayTable/DisplayGridHeaderRow';
import DisplayGridSortableTableHeader from '../../../common/displayTable/DisplayGridSortableTableHeader';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import CommandsTableRow from './CommandsTableRow';
import {Site} from '../../../../types/components/ApiTypes';
import getCommandsData from '../../../../helper/functions/getCommandsData';
import PaginationTable from '../../../common/displayTable/PaginationTable';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import {styled} from '@mui/material';
import useWindowSize from '../../../../helper/hooks/useWindowSize';

const SkeletonRoot = styled('div')({
    width: '100%',
    padding: '20px',
    paddingTop: '0px',
    paddingBottom: '60px',
});

interface Props {
    site: Site,
}

const CommandsTable = observer((props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const rows = useMemo(() => BinsenseStore.commandsData, [ BinsenseStore.commandsData ]);

    useEffect(() => {
        if (!BinsenseStore.awaitingApi) {
            BinsenseStore.setCommandsData(getCommandsData(props.site));
        }
    }, [ props.site, BinsenseStore.awaitingApi ]);

    const renderHeader = (id: string, label: string, key: string, xs: number) => (
        <DisplayGridSortableTableHeader
            id={`commands_${ id }_header`}
            label={label}
            xs={xs}
            data={rows}
            setData={BinsenseStore.setCommandsData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderRow = (row: any) => (
        <CommandsTableRow
            row={row}
            key={`${ row.id }_command`}
            siteId={props.site.id}
        />
    );

    return !BinsenseStore.awaitingUserData ? (
        <div style={{ width: '100%' }}>
            <PaginationTable
                rows={rows}
                renderRow={renderRow}
            >
                <DisplayGridHeaderRow>
                    {renderHeader('bin', Constants.BIN, 'bin', largeScreen ? 2.75 : 2)}
                    {renderHeader('device', Constants.DEVICE, 'device', largeScreen ? 1.5 : 2)}
                    {renderHeader('serial', Constants.SERIAL, 'serialNumber', largeScreen ? 1.75 : 2)}
                    {renderHeader('number_of_cables', Constants.NUMBER_OF_CABLES, 'numberOfCables', 1.5)}
                    {renderHeader('command', Constants.COMMAND, 'command', 3)}
                    <DisplayGridLabel
                        id='actions'
                        label={Constants.ACTIONS}
                        xs={1.5}
                        header
                    />
                </DisplayGridHeaderRow>
            </PaginationTable>
        </div>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={300}/>
        </SkeletonRoot>
    );
});

export default CommandsTable;
