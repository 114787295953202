import callForBlobAPI from '../../callForBlobAPI';
import downloadFile from '../../../helper/functions/downloadFile';

const getSitePrintMap = (enqueueSnackbar: any, siteId: number, userId: number) => {
    callForBlobAPI(`site/printmap/${ siteId }/${ userId }`,
        'application/pdf',
        (response: Blob) => {
            downloadFile(response, `${ siteId }_site_map.pdf`);
        },
        enqueueSnackbar
    );
};

export default getSitePrintMap;