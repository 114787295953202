/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Divider, Paper, styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {useMemo} from 'react';
import {Bin, Building, GrainBag} from '../../../../../types/components/ApiTypes';
import getTempUnit from '../../../../../helper/functions/getTempUnit';
import BinsenseStore from '../../../../../stores/BinsenseStore';
import getTestID from '../../../../../helper/functions/getTestID';
import TrendIcon from '../../../../common/TrendIcon';
import DashboardSkeleton from '../../../../common/DashboardSkeleton';
import { displayTemp } from '../../../../../helper/functions/functions';

interface Props {
    bin: Bin | GrainBag | Building,
}

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '100%',
});

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
});

const MaxPairDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const HEADER_FONT_SIZE = 14;
const CONTENT_FONT_SIZE = 20;

const DeviceMaxTempHumidityPaper = observer((props: Props) => {
    const tempUnit = useMemo(getTempUnit, [ BinsenseStore.userData.settings.preferences, BinsenseStore.awaitingUserData ]);

    const getMaxItems = (value: string, label: string, trend: string, id: string) => (
        <MaxPairDiv>
            <Row>
                <Typography
                    {...getTestID(`view_device_max_${ id }`)}
                    fontWeight="bold"
                    fontSize={CONTENT_FONT_SIZE}
                    paddingRight='4px'
                >
                    {value}
                </Typography>
                <TrendIcon value={trend}/>
            </Row>
            <Typography
                {...getTestID(`weather_condition_station_${ id }_label`)}
                fontSize={HEADER_FONT_SIZE - 4}
            >
                {label}
            </Typography>
        </MaxPairDiv>
    );

    return !BinsenseStore.awaitingUserData ? (
        <Paper
            style={{
                height: '100%',
                width: '100%',
            }}
        >
            <Column>
                <Typography
                    style={{ marginBottom: '16px' }}
                    fontSize={HEADER_FONT_SIZE}
                >
                    Max
                </Typography>
                <Row>
                    {getMaxItems(`${ displayTemp(tempUnit === 'F', props.bin.status?.maxTemperature) } ${ tempUnit }`,
                        'Temperature', props.bin.status?.temperatureTrend, 'temp')}
                    <Divider
                        orientation="vertical"
                        flexItem
                    />
                    {getMaxItems(props.bin.status?.maxMoisture ? `${ props.bin.status?.maxMoisture }${ '%' }` : '--', 'Moisture',
                        props.bin.status?.moistureTrend, 'moisture')}
                </Row>
            </Column>
        </Paper>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={160}/>
        </SkeletonRoot>
    );
});

export default DeviceMaxTempHumidityPaper;
