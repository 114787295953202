/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import Paper from '@mui/material/Paper';
import {Modal, styled, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import {observer} from 'mobx-react-lite';
import Colors from '../../../helper/Colors';
import Welcome from '../../../images/welcome.png';
import getTestID from '../../../helper/functions/getTestID';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '212px',
    justifyContent: 'space-between',
});

interface Props {
    open: boolean
    onClick: () => void,
}

const WelcomeTile = observer((props: Props) => (
    <Modal
        open={props.open}
    >
        <Paper
            elevation={6}
            sx={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                outline: 'none',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                padding: '32px',
                margin: 0,
                backgroundColor: Colors.primaryYellow,
            }}
        >
            <Row>
                <Column>
                    <div>
                        <Typography
                            sx={{
                                color: 'black',
                                fontSize: '24px',
                                fontWeight: 600,
                                paddingBottom: '16px',
                            }}
                        >
                            Welcome To Bin-Sense!
                        </Typography>
                        <Typography
                            sx={{
                                color: 'black',
                                paddingBottom: '32px',
                            }}
                        >
                            Click the button below to begin adding your first site.
                        </Typography>
                    </div>

                    <div>
                        <Button
                            {...getTestID('start_quote_button')}
                            variant="contained"
                            onClick={props.onClick}
                        >
                            Add First Site
                        </Button>
                    </div>
                </Column>
                <img
                    src={Welcome}
                    alt="welcome image"
                />
            </Row>
        </Paper>
    </Modal>
));

export default WelcomeTile;
