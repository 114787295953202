/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import DisplayGridItem from './DisplayGridItem';
import {ActionButton} from '../../../types/components/ApiTypes';
import HoverTextButton from '../buttons/HoverTextButton';

interface Props {
    buttons: ActionButton[],
    xs: number,
    id: any,
    header?: boolean,
    shrinkIcon?: boolean,
}

const DisplayGridActions = (props: Props) => (
    <DisplayGridItem
        xs={props.xs}
        header={props.header}
    >
        {props.buttons.map(button => (
            <HoverTextButton
                id={button.id}
                icon={button.icon}
                hoverText={button.hoverText ? button.hoverText : ''}
                onClick={button.onClick}
                color="secondary"
                key={`${ props.id }_${ button.hoverText }_tooltip`}
                disabled={button.disabled}
            />
        ))}
    </DisplayGridItem>
    );

export default DisplayGridActions;
