import getBaseURL from '../../getBaseURL';
import urlStore from '../../../stores/urlStore';
import loginStore from '../../../stores/LoginStore';
import handleError from '../../handleError';
import showStatusFailMessage from '../../showStatusFailMessage';

const postValidEmail = (enqueueSnackbar: any, body: any) => fetch(
        `${ getBaseURL() }${ urlStore.baseDirectory }validEmail`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-TOKEN': loginStore.token,
            },
            body: JSON.stringify(body),
        }
    )
        .then((response) => {
            handleError(response, enqueueSnackbar);
            return response.json();
        })
        .then((receivedServerData) => {
            if (receivedServerData.status && receivedServerData.status === 'fail') {
                showStatusFailMessage(receivedServerData, enqueueSnackbar);
                console.log('failed');
            } else {
                return receivedServerData;
            }
        })
        .catch((error) => {
            console.error(error.message);
        });

export default postValidEmail;