/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

// @ts-ignore
import uuid from 'react-uuid';
import DisplayGridItem from '../../../common/displayTable/DisplayGridItem';
import {observer} from 'mobx-react-lite';
import Constants from '../../../../helper/Constants';
import {useMemo} from 'react';
import useWindowSize from '../../../../helper/hooks/useWindowSize';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import {styled} from '@mui/material';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const StorageGridItemSkeleton = observer(() => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);

    return (
        <DisplayGridItem
            xs={windowWidth > Constants.WINDOW_WIDTHS[2] ? 4 : 6}
            key={uuid()}
            minWidth="375px"
        >
            <SkeletonRoot>
                <DashboardSkeleton
                    height={700}
                />
            </SkeletonRoot>
        </DisplayGridItem>
    );
});

export default StorageGridItemSkeleton;
