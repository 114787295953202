/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react-lite';
import WebStore from '../../stores/WebStore';
import getTestID from '../../helper/functions/getTestID';
import SitesPopup from './SitesPopup';
import BinsenseStore from '../../stores/BinsenseStore';
import {useMemo} from 'react';
import getSelectedSiteIndex from '../../helper/functions/getSelectedSiteIndex';
import Constants from '../../helper/Constants';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const Banner = observer(() => {
    const selectedSite = useMemo(() => {
        const index = getSelectedSiteIndex();
        return index >= 0 ? BinsenseStore.userData.sites[index].name : 'No Site Selected';
    }, [ WebStore.selectedSiteId, BinsenseStore.userData ]);

    const disableSitePopup = useMemo(() => BinsenseStore.awaitingApi || WebStore.editingMap ||
        (WebStore.selectedDrawer === Constants.DEVICE_SETTINGS && (WebStore.pageQueue.length > 0 &&
            WebStore.currentPage !== Constants.VIEW_DEVICE)),
        [ BinsenseStore.awaitingApi, WebStore.editingMap,
            WebStore.selectedDrawer, WebStore.currentPage,WebStore.pageQueue ]);

    return (
        <Root>
            <SitesPopup
                disabled={disableSitePopup}
            />
            <Typography
                {...getTestID(`${ WebStore.selectedDrawer }_page_${ selectedSite }`)}
                style={{
                    fontSize: '20px',
                    opacity: 0.5,
                }}
            >
                {selectedSite}
            </Typography>
        </Root>
    );
});

export default Banner;
