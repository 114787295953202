import callPutAPI from '../../callPutAPI';
import updateData from '../../../helper/functions/updateData';

const putBin = (enqueueSnackbar: any, id: number, body: any) => {
    callPutAPI(`bin/${ id }`,
        body,
        (response) => {
            if (response === 'OK') {
                updateData(enqueueSnackbar);
            }
        },
        enqueueSnackbar,
        'Bin updated successfully'
    );
};

export default putBin;