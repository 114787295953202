import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';

const getServerCommands = (enqueueSnackbar: any, id: number, limit: number, pageIndex: number) => {
    callAPI(`servercommands/${ id }?limit=${ limit }&page_index=${ pageIndex }`,
        (response) => {
            BinsenseStore.setCommandHistoryData(response.serverCommands.map((row: any, index: number) => ({
                ...row,
                id: index + pageIndex,
            })));
            BinsenseStore.setCommandCount(response.count);
        },
        enqueueSnackbar
    );
};

export default getServerCommands;