/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import BaseModal from './BaseModal';
import getTestID from '../../../helper/functions/getTestID';
import {Warning} from '@mui/icons-material';
import Constants from '../../../helper/Constants';

const Container = styled('div')({
    width: '450px',
    padding: '16px',
});

interface Props {
    id: string,
    open: boolean,
    close: () => void,
    submit: () => void,
    structName: string,
}

function DeleteAssociationModal(props: Props) {
    const submit = () => {
        props.submit();
        props.close();
    };

    return (
        <BaseModal
            id={props.id}
            open={props.open}
            close={props.close}
            submit={submit}
            title="End Association"
            titleObject={<Warning color="error"/>}
            buttonColor="error"
            submitButtonText={Constants.END}
        >
            <Container>
                <Typography {...getTestID('popup_message')}>
                    {`Are you sure you want to end the association ${ props.structName }?`}
                </Typography>
            </Container>
        </BaseModal>
    );
}

export default DeleteAssociationModal;
