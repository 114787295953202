import callPostAPI from '../../callPostAPI';
import {NewPassword} from '../../../types/components/ApiTypes';

const postUpdatePassword = (enqueueSnackbar: any, body: NewPassword) => {
    callPostAPI('updatePassword',
        body,
        (response) => {
            console.log(response);
        },
        enqueueSnackbar,
        'Password updated successfully'
    );
};

export default postUpdatePassword;