import callPutAPI from '../../callPutAPI';
import updateData from '../../../helper/functions/updateData';

const putUpdateSortOrder = (enqueueSnackbar: any, body: any, siteId: number) => {
    callPutAPI(`updateSortOrder/${ siteId }`,
        body,
        (response) => {
            if (response === 'OK') {
                updateData(enqueueSnackbar);
            }
        },
        enqueueSnackbar,
        'Bin Order updated successfully'
    );
};

export default putUpdateSortOrder;