import React, {SVGAttributes} from 'react';
import {strFormat} from '../../helper/functions/functions';
import AutomationImage from '../siteLegend/AU-YY.svg';

interface SVGProps extends React.SVGProps<SVGSVGElement> {
    id: string,
    diameter: number,
    fullness?: number,
    color?: string,
    onOverlayStart?: (xOffset: number) => void,
    onMouseLeave?: () => void,
    x: number,
    y: number,
    href?: string,
    onlink?: (event: React.MouseEvent) => void
}

interface LocalSVGProps extends SVGProps {
    type: 'bin' | 'boiler' | 'automation',
}

const RoundStructSVG = (props: LocalSVGProps) => {
    const {
        id, className, diameter, fullness, stroke, type, onOverlayStart,
        onMouseLeave, x: centerX, y: centerY, href, onlink, ...rest
    } = props;

    const outlineStroke = 3;
    const halfStroke = 0.5 * outlineStroke;

    const radius = 0.5 * diameter;
    const x = centerX - radius;
    const y = centerY - radius;

    const shapeDiameter = diameter - outlineStroke;
    const shapeRadius = 0.5 * shapeDiameter;

    const safeFullness = fullness == null ? 0 : fullness * diameter * 0.01;
    const lessFullness = diameter - safeFullness;

    const binColor = props.color == null ? 'black' : props.color;

    const transformFormat = `translate(${ radius }, ${ radius }) rotate({0}) translate(-${ radius }, -${ radius })`;

    function onMouseEnter() {
        if (onOverlayStart != null) {
            onOverlayStart(radius);
        }
    }

    function renderRibs() {
        const ribs: JSX.Element[] = [];
        for (let i = 0; i < 180; i += 22.5) {
            ribs.push(
                <use
                    xlinkHref={`#${ id }_roofRibTemplate`}
                    className="roofRib"
                    transform={strFormat(transformFormat, i)}
                    key={`roofRib${ i }_${ props.id }`}
                />
            );
        }
        return (
            <g
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >{ribs}</g>
        );
    }

    const drawShape = (shapeProps: SVGAttributes<SVGCircleElement>) => (
        <circle
            cx={shapeRadius + halfStroke}
            cy={shapeRadius + halfStroke}
            r={shapeRadius}
            {...shapeProps}
        />
    );

    const inner = (
        <svg
            id={id}
            className={`structure ${ binColor } ${ type } ${ className }`}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={diameter}
            height={diameter}
            viewBox={`0 0 ${ diameter } ${ diameter }`}
            x={x}
            y={y}
            {...rest}
        >
            {
                type === 'bin' ? (
                    <defs>
                        <line
                            id={`${ id }_roofRibTemplate`}
                            x1={outlineStroke}
                            y1={halfStroke + shapeRadius}
                            x2={shapeDiameter}
                            y2={halfStroke + shapeRadius}
                            strokeWidth={halfStroke}
                        />
                    </defs>
                ) : null
            }
            <mask id={`${ id }_clipFillMask`}>
                {drawShape({
                    strokeWidth: outlineStroke,
                    stroke: 'white',
                    fill: 'white',
                })}
                <rect
                    x={0}
                    y={0}
                    width={diameter}
                    height={lessFullness}
                    fill="black"
                />
            </mask>
            {drawShape({className: 'shape'})}
            {type === 'bin' ? renderRibs() : null}
            {drawShape({
                className: `structure ${ binColor } outline`,
                strokeWidth: outlineStroke,
            })}
            <g mask={`url(#${ id }_clipFillMask)`}>
                <rect
                    x={0}
                    y={lessFullness}
                    width={diameter}
                    height={safeFullness}
                    className= {`structure ${ binColor } fullnessShape`}
                    strokeWidth={outlineStroke * 2}
                />
                {drawShape({
                    className: `structure ${ binColor } outline fullnessShape`,
                    strokeWidth: outlineStroke,
                })}
            </g>
            {drawShape({
                className: 'cover',
                onMouseEnter,
                onMouseLeave,
            })}
            {type === 'automation' && (
                <image
                    href={AutomationImage}
                    x={0}
                    y={0}
                    width={diameter}
                    height={diameter}
                />
            )}
        </svg>
    );
    return href == null ? inner : (
        <a
            onClick={onlink}
            href={href}
        >{inner}</a>
    );
};

const BinSVG = (props: SVGProps) => {
    const localProps = {
        type: 'bin',
        ...props,
    };
    // @ts-ignore
    return <RoundStructSVG {...localProps} />;
};

const BoilerSVG = (props: SVGProps) => {
    const localProps = {
        type: 'boiler',
        ...props,
    };
    // @ts-ignore
    return <RoundStructSVG {...localProps} />;
};

const AutomationSVG = (props: SVGProps) => {
    const localProps = {
        type: 'automation',
        ...props,
    };
    // @ts-ignore
    return <RoundStructSVG {...localProps} />;
};

export {BinSVG, BoilerSVG, AutomationSVG};
