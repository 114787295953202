/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

interface Props {
    children: object | object[],
}

const AccordionTableRowDetails = (props: Props) => (
    <div
        style={{
            paddingLeft: '22.39px',
            width: '100%',
        }}
    >
        {props.children}
    </div>
);

export default AccordionTableRowDetails;
