/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Fragment} from 'react';
import {observer} from 'mobx-react-lite';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';
import {IconButton, styled, Typography} from '@mui/material';
import {Add, Remove, ZoomOutMap} from '@mui/icons-material';
import getTempUnit from '../../../helper/functions/getTempUnit';
import BinsenseStore from '../../../stores/BinsenseStore';
import { displayTemp } from '../../../helper/functions/functions';
import { WeatherDataForLiveAndSolo } from '../../../types/components/ApiTypes';

interface Props {
    children: object
    displayAmbientTemp?: boolean,
    structId?: number,
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

const SvgZoomPanComponent = observer((props: Props) => {

    const getAmbientTemprature = () => {
        let ambientTemperature = null;
        if (props.structId) {
            const weatherData: WeatherDataForLiveAndSolo[] = BinsenseStore.weatherDataForLiveAndSolo;
            for (let i = 0; i < weatherData.length; i++) {
                if (weatherData[i].structId === props.structId) {
                    ambientTemperature = weatherData[i].ambientTemperature;
                    break;
                }
            }
        }
        return ambientTemperature;
    };

    const ambientTemperature = getAmbientTemprature();
    const tempUnit = getTempUnit();

    return (
        <TransformWrapper>
            {({ zoomIn, zoomOut, resetTransform }) => (
                <Fragment>
                    <div>
                        <Row>
                            <IconButton
                                onClick={() => zoomIn()}
                            >
                                <Add fontSize="small" />
                            </IconButton>

                            <IconButton
                                onClick={() => zoomOut()}
                            >
                                <Remove fontSize="small" />
                            </IconButton>

                            <IconButton
                                onClick={() => resetTransform()}
                            >
                                <ZoomOutMap fontSize="small" />
                            </IconButton>
                        </Row>
                        {props.displayAmbientTemp && ambientTemperature !== null && <Row>
                            <Typography fontSize={12}>
                                {displayTemp( tempUnit === 'F', ambientTemperature).concat('°').concat(tempUnit)}
                            </Typography>
                        </Row>}
                        <TransformComponent
                            wrapperStyle={{ width: '100%' }}
                            contentStyle={{
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {props.children}
                        </TransformComponent>
                    </div>
                </Fragment>
            )}
        </TransformWrapper>
        );
    });

export default SvgZoomPanComponent;
