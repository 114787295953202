import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';
import WebStore from '../../../stores/WebStore';

const getBinGraphData = (enqueueSnackbar: any, id: number, start: number, end: number) => {
    callAPI(`binGraphData/${ id }/${ start }/${ end }/${ WebStore.utcOffset }`,
        (response) => {
            BinsenseStore.setBinGraphData(response);
        },
        enqueueSnackbar
    );
};

export default getBinGraphData;