export default {
    'dealerReportsData': [
        {
            id: 0,
            user: 'johnsmith',
            name: 'John Smith',
            structure: 'Bin 1',
            device: 'Solo-AB23234',
            maxTemp: '90 C',
            lastCallIn: '2022-07-13 - 29 days ago',
        },
        {
            id: 1,
            user: 'danthorson_old',
            name: 'Dan Thorson',
            structure: 'Bin 2',
            device: 'Master-4B354E',
            maxTemp: '85 C',
            lastCallIn: '2022-08-14 - 25 days ago',
        },
        {
            id: 2,
            user: 'lfahlman',
            name: 'Landon Fahlman',
            structure: 'Bin 16',
            device: 'Remote-456G56',
            maxTemp: '65 C',
            lastCallIn: '2022-07-15 - 45 days ago',
        },
        {
            id: 3,
            user: 'ipeters',
            name: 'Isaiah Peters',
            structure: 'Bin 15',
            device: 'Remote-4G3434',
            maxTemp: '60 C',
            lastCallIn: '2022-07-14 - 44 days ago',
        },
        {
            id: 4,
            user: 'afish',
            name: 'Alex Fish',
            structure: 'Bin 18',
            device: 'Master-45G343',
            maxTemp: '45 C',
            lastCallIn: '2022-07-03 - 54 days ago',
        },
        {
            id: 5,
            user: 'hjackson',
            name: 'Halle Jackson',
            structure: 'Bin 25',
            device: 'Remote-334534',
            maxTemp: '42 C',
            lastCallIn: '2022-06-24 - 64 days ago',
        },
    ],
};
