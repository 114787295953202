/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {KeyboardDoubleArrowDown, KeyboardDoubleArrowUp} from '@mui/icons-material';

interface Props {
    label: string,
    sortColumn: string,
    sortAscending: boolean,
}

const SortableTableHeaderIcon = (props: Props) => {
    if (props.label === props.sortColumn) {
        return props.sortAscending ? <KeyboardDoubleArrowUp/> : <KeyboardDoubleArrowDown/>;
    }
    return null;
};

export default SortableTableHeaderIcon;