/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import TitlePaper from '../../common/TitlePaper';
import Constants from '../../../helper/Constants';
import BillingInformationForm from '../../common/personalDetails/BillingInformationForm';
import {useState, useEffect, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import BillingInformationPaperButtonRow from './BillingInformationPaperButtonRow';
import BinsenseStore from '../../../stores/BinsenseStore';
import WebStore from '../../../stores/WebStore';

const BillingInformationPaper = observer(() => {
    const balance = useMemo(() => WebStore.selectedView.toLowerCase() === Constants.DEALER.toLowerCase()
            ? WebStore.selectedCustomer.balance : BinsenseStore.userData.balance,
            [ WebStore.selectedCustomer.balance, BinsenseStore.userData.balance ]);

    const [ currentOwingBalance, setCurrentOwingBalance ] = useState('');
    const [ accountRenewalDate, setAccountRenewalDate ] = useState('');
    const [ currentPlan, setCurrentPlan ] = useState('');
    const [ totalLiveDevices, setTotalLiveDevices ] = useState('');
    const [ totalSoloDevices, setTotalSoloDevices ] = useState('');
    const [ totalAutomationDevices, setTotalAutomationDevices ] = useState('');
    const [ dueDate, setDueDate ] = useState('');

    useEffect(() => {
        setCurrentOwingBalance('$'.concat(balance != null && balance.amount != null
            ? balance.amount.toString() : '--'));

        setAccountRenewalDate(balance != null &&
            balance.renewalDate != null
            ? new Date(balance.renewalDate).toDateString()
            : '--');

        setCurrentPlan('$'.concat(balance != null &&
            balance.currentPlanAmount != null
            ? balance.currentPlanAmount.toString()
            : '--').concat('/year'));

        setTotalLiveDevices(balance != null &&
            balance.subscribedMasters != null
            ? balance.subscribedMasters.toString()
            : '--');

        setTotalSoloDevices(balance != null &&
            balance.subscribedSolos != null
            ? balance.subscribedSolos.toString()
            : '--');

        setTotalAutomationDevices(balance.subscribedAutomation != null
            ? balance.subscribedAutomation.toString()
            : '--');

        setDueDate(balance != null &&
            balance.dueDay != null
            ? new Date(balance.dueDay).toDateString()
            : '--');
    }, []);

    const payNow = () => {
        if (WebStore.selectedView.toLowerCase() === Constants.DEALER.toLowerCase()) {
            WebStore.setCurrentPage(Constants.PAY_NOW, false);
        }
        else
        {
            WebStore.setSelectedDrawer(Constants.PAY_NOW, false);
        }
    };

    const manageBilling = () => {
        if (WebStore.selectedView.toLowerCase() === Constants.DEALER.toLowerCase()) {
            WebStore.setCurrentPage(Constants.MANAGE_BILLING, false);
        }
        else
        {
            WebStore.setSelectedDrawer(Constants.MANAGE_BILLING, false);
        }
    };

    return (
        <TitlePaper title={Constants.BILLING_INFORMATION}>
            <BillingInformationForm
                currentOwingBalance={{
                    value: currentOwingBalance,
                    setValue: setCurrentOwingBalance,
                }}
                accountRenewalDate={{
                    value: accountRenewalDate,
                    setValue: setAccountRenewalDate,
                }}
                totalLiveDevices={{
                    value: totalLiveDevices,
                    setValue: setTotalLiveDevices,
                }}
                totalSoloDevices={{
                    value: totalSoloDevices,
                    setValue: setTotalSoloDevices,
                }}
                totalAutomationDevices={{
                    value: totalAutomationDevices,
                    setValue: setTotalAutomationDevices,
                }}
                dueDate={{
                    value: dueDate,
                    setValue: setDueDate,
                }}
                currentPlan={{
                    value: currentPlan,
                    setValue: setCurrentPlan,
                }}
                disableAll
            />
            <BillingInformationPaperButtonRow
                onPayNow={payNow}
                onManageBilling={manageBilling}
                disablePayNow={(Object.keys(balance).length <= 0 || balance.amount <= 0)}
                disableManageBilling={(Object.keys(balance).length <= 0)}
                hideManageBilling={WebStore.selectedView.toLowerCase() === Constants.DEALER.toLowerCase() &&
                    BinsenseStore.dealerUserData.dealerId !== 1}
            />
        </TitlePaper>
    );
});

export default BillingInformationPaper;
