/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Checkbox, Paper, styled, Typography} from '@mui/material';
import KeyValueTypography from '../../../common/typography/KeyValueTypography';
import Constants from '../../../../helper/Constants';
import getTestID from '../../../../helper/functions/getTestID';
import {observer} from 'mobx-react-lite';
import {Dispatch, SetStateAction, useState} from 'react';
import SearchUserFilter from '../../myCustomers/SearchUserFilter';

interface Props {
    existingUserSelected: boolean,
    setExistingUserSelected: (value: boolean) => void,
    user: {
        value: string | null,
        setValue: Dispatch<SetStateAction<string | null>>,
    },
    error: string,
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '32px',
    width: '100%',
    alignItems: 'center',
});

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
});

const SelectUserPaper = observer((props: Props) => {
    const [ existingUserInput, setExistingUserInput ] = useState('');

    return (
        <Paper
            style={{
                width: '95%',
            }}
        >
            <Root>
                <KeyValueTypography
                    label="STEP 1:"
                    value={Constants.SELECT_USER}
                />
                <Row
                    style={{ paddingBottom: '16px' }}
                >
                    <Checkbox
                        {...getTestID(`existing_user_${ props.existingUserSelected }`)}
                        checked={props.existingUserSelected}
                        onClick={() => props.setExistingUserSelected(true)}
                    />
                    <Typography
                        color='primary'
                        style={{ paddingLeft: '16px' }}
                    >
                        Existing User
                    </Typography>
                </Row>
                <SearchUserFilter
                    id="create_site_select_user"
                    user={props.user}
                    userInput={{
                        value: existingUserInput,
                        setValue: setExistingUserInput,
                    }}
                />
                {props.error !== '' && (
                    <Typography color="error">{props.error}</Typography>
                )}
                <Row>
                    <Checkbox
                        {...getTestID(`new_user_${ props.existingUserSelected }`)}
                        checked={!props.existingUserSelected}
                        onClick={() => props.setExistingUserSelected(false)}
                    />
                    <Typography
                        color='primary'
                        style={{
                            paddingLeft: '16px',
                        }}
                    >
                        New User
                    </Typography>
                </Row>
            </Root>
        </Paper>
    );
});

export default SelectUserPaper;
