/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {ArrowLeft, ArrowRight} from '@mui/icons-material';
import CarouselIconButton from './CarouselIconButton';

interface Props {
    onPrevClick: () => void,
    onNextClick: () => void,
}

const CarouselNextPrevButtons = (props: Props) => (
    <div>
        <CarouselIconButton onClick={props.onPrevClick}>
            <ArrowLeft/>
        </CarouselIconButton>
        <CarouselIconButton onClick={props.onNextClick}>
            <ArrowRight/>
        </CarouselIconButton>
    </div>
);

export default CarouselNextPrevButtons;
