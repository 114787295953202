/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {LinearProgress, styled} from '@mui/material';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react-lite';
import WebStore from '../../stores/WebStore';
import getTestID from '../../helper/functions/getTestID';
import BannerButtons from './BannerButtons';
import Constants from '../../helper/Constants';
import BinsenseStore from '../../stores/BinsenseStore';
import Breadcrumbs from './Breadcrumbs';
import {useMemo} from 'react';
import SitesPopupWithLabel from './SitesPopupWithLabel';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    margin: '16px',
    justifyContent: 'space-between',
    minWidth: '800px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

interface Props {
    disableButtons?: boolean,
}

const Banner = observer((props: Props) => {
    const displaySitesIcon = useMemo(() => WebStore.selectedView === Constants.CUSTOMER &&
            !Constants.HIDE_SELECTED_SITE_DRAWERS.includes(WebStore.selectedDrawer),
        [ WebStore.selectedView, WebStore.selectedDrawer ]);

    return (
        <div>
            {BinsenseStore.awaitingApi || BinsenseStore.awaitingUserData && (
                <LinearProgress color="primary"/>
            )}
            <Root>
                <div>
                    <Row>
                        <Typography
                            sx={{
                                fontSize: '20px',
                                paddingLeft: '16px',
                            }}
                            {...getTestID(`${ WebStore.selectedDrawer }_page`)}
                        >
                            {WebStore.selectedDrawer}
                        </Typography>
                        {displaySitesIcon && (
                            <SitesPopupWithLabel/>
                        )}
                    </Row>
                    <Breadcrumbs/>
                </div>
                <BannerButtons disableButtons={props.disableButtons}/>
            </Root>
        </div>
    );
});

export default Banner;
