/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Bin, Building, GrainBag, Pile, Site, UserData} from '../../types/components/ApiTypes';
import Constants from '../Constants';
import getGrainType from './getGrainType';
import getSiteTotalBushels from './getSiteTotalBushels';

const getBinStatusField = (bin: Bin, key: string) => {
    if (bin.status[key]) {
        return bin.status[key];
    }
    return key === 'volume' ? 0 : Constants.NA;
};

const getStructureInventory = (site: Site, key: string) => site[key]
    .map((structure: Bin | Building | GrainBag | Pile) => ({
        id: structure.id,
        site: structure.name,
        bushels: key === 'bins' ? getBinStatusField(<Bin> structure, 'volume') : 0,
        minTemp: key === 'bins' ? getBinStatusField(<Bin> structure, 'minTemperature') : Constants.NA,
        avgTemp: key === 'bins' ? getBinStatusField(<Bin> structure, 'avgTemperature') : Constants.NA,
        maxTemp: key === 'bins' ? getBinStatusField(<Bin> structure, 'maxTemperature') : Constants.NA,
        minMoisture: key === 'bins' ? getBinStatusField(<Bin> structure, 'minMoisture') : Constants.NA,
        maxMoisture: key === 'bins' ? getBinStatusField(<Bin> structure, 'maxMoisture') : Constants.NA,
        commodity: getGrainType(structure.settings.productId),
        sortOrder: structure.sortOrder,
        percentFull: structure.status.percentFull,
    }));

const getSiteInventory = (site: Site) => {
    const bins = getStructureInventory(site, 'bins');
    const buildings = getStructureInventory(site, 'buildings');
    const grainBags = getStructureInventory(site, 'grainBags');
    // const piles = getStructureInventory(site, 'piles');

    let allStructures = [ ...bins, ...buildings, ...grainBags ];
    let sortIndexAvailable = false;
    allStructures.forEach(obj => {
        if (obj.sortOrder > 0) {
            sortIndexAvailable = true;
        }
    });

    if (sortIndexAvailable) {
        allStructures = allStructures.sort((a, b) => a.sortOrder - b.sortOrder);
    }

    return ({
        id: site.id,
        site: site.name,
        bushels: getSiteTotalBushels(site),
        structures: allStructures,
        // structures: [ ...bins, ...buildings, ...grainBags, ...piles ],
    });
};

const getInventory = (data: UserData) => data.sites.map(site => getSiteInventory(site));

export default getInventory;
