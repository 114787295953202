/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import AccordionComponent from '../../common/AccordionComponent';
import MyCustomersTableRowDetails from './MyCustomersTableRowDetails';
import {observer} from 'mobx-react-lite';
import AccordionTableRowDetails from '../../common/displayTable/AccordionTableRowDetails';
import MyCustomersTableRowSummary from './MyCustomersTableRowSummary';
import WebStore from '../../../stores/WebStore';

interface Props {
    data: any,
}

const MyCustomersTableRow = observer((props: Props) => {
    const renderDetails = () => (
        <AccordionTableRowDetails>
            {props.data.sites.map((site: any) => (
                <MyCustomersTableRowDetails
                    data={props.data}
                    site={site}
                    key={`${ props.data.id }_${ site.id }_details`}
                />
            ))}
        </AccordionTableRowDetails>
    );

    return (
        <AccordionComponent
            id={props.data.id}
            summary={<MyCustomersTableRowSummary data={props.data}/>}
            details={renderDetails()}
            expanded={WebStore.myCustomersSearch === props.data.user}
        />
    );
});

export default MyCustomersTableRow;
