/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Site} from '../../types/components/ApiTypes';

const getSiteTotalBushels = (site: Site) => {
    let bushels = 0;
    site.bins.forEach(bin => {
        if (bin.status?.volume) {
            bushels += bin.status.volume;
        }
    });
    site.grainBags.forEach(bag => {
        if (bag.status?.volume) {
            bushels += bag.status.volume;
        }
    });
    site.buildings.forEach(building => {
        if (building.status?.volume) {
            bushels += building.status.volume;
        }
    });
    return Number(bushels.toFixed(2));
};

export default getSiteTotalBushels;
