import React from 'react';
import ReactDOM from 'react-dom';
import {LicenseInfo} from '@mui/x-data-grid-pro';
import './index.css';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LocalizationProvider from '@mui/lab/LocalizationProvider/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import './styles/index.scss';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

LicenseInfo.setLicenseKey(
    '9fc41b91d3579162ed53ab63d7b4819aT1JERVI6MzEzNDAsRVhQSVJZPTE2NjY1MzYzNzcwMDAsS0VZVkVSU0lPTj0x'
);

ReactDOM.render(
    <LocalizationProvider
        dateAdapter={DateAdapter}
        locale='en'
    >
        <App/>
    </LocalizationProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
