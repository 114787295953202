/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useMemo, useState} from 'react';
import Constants from '../../../../helper/Constants';
import {Button, styled} from '@mui/material';
import getTestID from '../../../../helper/functions/getTestID';
import {Add, Download} from '@mui/icons-material';
import BinsenseStore from '../../../../stores/BinsenseStore';
import exportTableToCSV from '../../../../helper/functions/exportTableToCSV';
import {observer} from 'mobx-react-lite';
import {Customer} from '../../../../types/components/ApiTypes';
import getServiceRecords from '../../../../controllers/apiCalls/get/getServiceRecords';
import {useSnackbar} from 'notistack';
import CustomerServiceRecordsTable from './CustomerServiceRecordsTable';
import NewRecordModal from './NewRecordModal';
import DashboardSkeleton from '../../../common/DashboardSkeleton';

const Row = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'right',
    paddingBottom: '12px',
    paddingRight: '20px',
});

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

interface Props {
    user: Customer,
}

const CustomerServiceRecordsPage = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ newRecordModalOpen, setNewRecordModalOpen ] = useState(false);

    const rows = useMemo(() => BinsenseStore.serviceRecordsData, [ BinsenseStore.serviceRecordsData ]);
    const columns = [
        {
            field: 'notes',
            headerName: Constants.SERVICE_RECORD,
        },
        {
            field: 'serviceDate',
            headerName: Constants.DATE,
        },
    ];

    useEffect(() => {
        if (props.user.id !== -1) {
            getServiceRecords(enqueueSnackbar, props.user.id);
        }
    }, [ props.user ]);

    return (
        <Root>
            {!BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
                <Row>
                    <Button
                        {...getTestID('new_record_button')}
                        startIcon={<Add/>}
                        onClick={() => setNewRecordModalOpen(true)}
                        variant='contained'
                    >
                        {Constants.NEW_RECORD}
                    </Button>
                    <Button
                        {...getTestID('export_button')}
                        startIcon={<Download/>}
                        onClick={() => exportTableToCSV(columns, rows,
                            `user_${ props.user.id }_records`)}
                        variant='contained'
                        style={{ marginLeft: '8px' }}
                    >
                        {Constants.EXPORT}
                    </Button>
                </Row>
            ) : (
                <Row>
                    <DashboardSkeleton
                        height={40}
                        width='135px'
                        style={{ marginRight: '8px' }}
                    />
                    <DashboardSkeleton
                        height={40}
                        width='100px'
                    />
                </Row>
            )}
            <CustomerServiceRecordsTable
                displayedData={rows}
                user={props.user}
            />
            {newRecordModalOpen && (
                <NewRecordModal
                    open
                    close={() => setNewRecordModalOpen(false)}
                    userId={props.user.id}
                />
            )}
        </Root>
    );
});

export default CustomerServiceRecordsPage;
