import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';
import updateSite from '../../../helper/functions/updateSite';

const getSiteStructures = (enqueueSnackbar: any, siteId: number, update: boolean = false) => {
    callAPI(`siteStructures/${ siteId }`,
        (response) => {
            const structures = {
                id: siteId,
                ...response.structNames ? response : {
                    ...response,
                    structNames: [
                        ...response.bins.map((bin: { name: any; }) => bin.name),
                        ...response.buildings.map((building: { name: any; }) => building.name),
                        ...response.grainBags.map((bag: { name: any; }) => bag.name),
                        // ...response.piles.map((pile: { name: any; }) => pile.name),
                        ...response.automationHubs.map((hub: { name: any; }) => hub.name),
                    ],
                },
            };
            if (update) {
                updateSite(structures);
            } else {
                BinsenseStore.setSiteStructures(structures);
            }
        },
        enqueueSnackbar,
        true
    );
};

export default getSiteStructures;