/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import NewNoteModal from './NewNoteModal';
import DisplayGridHeaderRow from '../../common/displayTable/DisplayGridHeaderRow';
import DisplayGridSortableTableHeader from '../../common/displayTable/DisplayGridSortableTableHeader';
import Constants from '../../../helper/Constants';
import BinsenseStore from '../../../stores/BinsenseStore';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import getNotesTableData from '../../../helper/functions/getNotesTableData';
import NotesTableRow from './NotesTableRow';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import deleteBinNote from '../../../controllers/apiCalls/delete/deleteBinNote';
import {useSnackbar} from 'notistack';
import {Site} from '../../../types/components/ApiTypes';
import PaginationTable from '../../common/displayTable/PaginationTable';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import {styled} from '@mui/material';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

interface Props {
    rows: any[],
    site: Site,
}

const NotesTable = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => BinsenseStore.setNotesTableData(getNotesTableData(BinsenseStore.binNotesData)),
        [ BinsenseStore.binNotesData ]);

    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const [ edit, setEdit ] = useState<{ id: number | null, note: string, structureId: number | undefined }>({
        id: null,
        note: '',
        structureId: undefined,
    });
    const [ deleteId, setDeleteId ] = useState<number | null>(null);

    const onEditClick = (id: number, note: string, structureId: number) => {
        setEdit({
            id,
            note,
            structureId,
        });
    };

    const onCloseEdit = () => {
        setEdit({
            id: null,
            note: '',
            structureId: undefined,
        });
    };

    const onDelete = () => {
        deleteBinNote(enqueueSnackbar, Number(deleteId), props.site.id);
    };

    const renderSortableHeader = (key: string, label: string, xs: number) => (
        <DisplayGridSortableTableHeader
            id={`${ key }_header`}
            label={label}
            xs={xs}
            data={BinsenseStore.notesTableData}
            setData={BinsenseStore.setNotesTableData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderRow = (row: any) => (
        <NotesTableRow
            row={row}
            onEditClick={onEditClick}
            onDeleteClick={() => setDeleteId(row.id)}
            key={`${ row.id }_note`}
        />
    );

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <PaginationTable
            rows={props.rows}
            renderRow={renderRow}
        >
            <DisplayGridHeaderRow>
                {renderSortableHeader('date', Constants.DATE, 2)}
                {renderSortableHeader('structure', Constants.STRUCTURE, 3)}
                {renderSortableHeader('note', Constants.NOTE, 5)}
                <DisplayGridLabel
                    id='actions_header'
                    label={Constants.ACTIONS}
                    xs={2}
                    header
                />
            </DisplayGridHeaderRow>
            {edit.id !== null && (
                <NewNoteModal
                    open
                    close={onCloseEdit}
                    editingId={edit.id}
                    note={edit.note}
                    structureId={edit.structureId}
                    site={props.site}
                />
            )}
            {deleteId !== null && (
                <ConfirmationModal
                    id="delete_note_confirmation"
                    open
                    close={() => setDeleteId(null)}
                    submit={onDelete}
                    title="Delete Note"
                    message="Are you sure you want to delete this note?"
                />
            )}
        </PaginationTable>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={600}/>
        </SkeletonRoot>
    );
});

export default NotesTable;
