/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import getTestID from '../../../helper/functions/getTestID';
import TrendIcon from '../../common/TrendIcon';

interface Props {
    id: string,
    difference: string,
}

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '16px',
    marginBottom: '16px',
});

const DashboardStatsDifferenceRow = observer((props: Props) => {
    const trendValue = useMemo(() => {
        if (props.difference.charAt(0) === '+') {
            return 'RISING';
        }
        if (props.difference.charAt(0) === '-') {
            return 'FALLING';
        }
        return 'STEADY';
    }, [ props.difference ]);

    return (
        <Root>
            <TrendIcon
                value={trendValue}
                size={'large'}
            />
            <Typography
                {...getTestID(`${ props.id }_difference`)}
                paddingLeft="8px"
                fontSize={18}
            >
                {props.difference}
            </Typography>
        </Root>
    );
});

export default DashboardStatsDifferenceRow;
