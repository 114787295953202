/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Button, styled} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';
import {Add} from '@mui/icons-material';
import {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import BinsenseStore from '../../../stores/BinsenseStore';
import DashboardSkeleton from '../DashboardSkeleton';

interface Props {
    id: string,
    label: string,
    onClick: () => void,
    icon?: object,
    noIcon?: boolean,
    disabled?: boolean,
}

const Root = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'right',
    paddingBottom: '12px',
    paddingRight: '20px',
});

const TableButton = observer((props: Props) => {
    const icon = useMemo(() => {
        if (props.noIcon) {
            return undefined;
        }
        else if (props.icon) {
            return props.icon;
        }
        return <Add/>;
    }, [ props.icon, props.noIcon ]);

    return (
        <Root>
            {!BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
                <Button
                    {...getTestID(props.id)}
                    startIcon={icon}
                    onClick={props.onClick}
                    variant='contained'
                    disabled={props.disabled}
                >
                    {props.label}
                </Button>
            ) : (
                <DashboardSkeleton
                    height={40}
                    width='100px'
                />
            )}
        </Root>
    );
});

export default TableButton;
