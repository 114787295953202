/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {StructIdSerials, MasterUnit, RemoteUnit, Site, SoloUnit} from '../../types/components/ApiTypes';

const addMastersToList = (masters: MasterUnit[], serials: string[]) => {
    masters.forEach(master => {
        if (!serials.includes(master.serial)) {
            serials.push(master.serial);
        }
    });
};

const addRemotesToList = (remotes: RemoteUnit[], serials: string[]) => {
    remotes.forEach(remote => {
        if (!serials.includes(remote.serial)) {
            serials.push(remote.serial);
        }
    });
};

const addSoloToList = (soloUnits: SoloUnit[], serials: string[]) => {
    soloUnits.forEach(solo => {
        if (!serials.includes(solo.serial)) {
            serials.push(solo.serial);
        }
    });
};

const getAllSerialsFromSite = (site: Site) => {
        const structIdSerials: StructIdSerials[] = [];
        let serials: string[] = [];
        site.bins.forEach(bin => {
            if (bin.hardware?.masterUnits) {
                addMastersToList(bin.hardware.masterUnits, serials);
            }
            if (bin.hardware?.remoteUnits) {
                addRemotesToList(bin.hardware.remoteUnits, serials);
            }
            if (bin.hardware?.soloUnits) {
                addSoloToList(bin.hardware.soloUnits, serials);
            }
            structIdSerials.push({
                id: bin.id,
                sn: serials});
            serials = [];
        });
        site.grainBags.forEach(bag => {
            if (bag.hardware?.masterUnits) {
                addMastersToList(bag.hardware.masterUnits, serials);
            }
            if (bag.hardware?.remoteUnits) {
                addRemotesToList(bag.hardware.remoteUnits, serials);
            }
            if (bag.hardware?.soloUnits) {
                addSoloToList(bag.hardware.soloUnits, serials);
            }
            structIdSerials.push({
                id: bag.id,
                sn: serials});
            serials = [];
        });
        site.buildings.forEach(building => {
            if (building.hardware?.masterUnits) {
                addMastersToList(building.hardware.masterUnits, serials);
            }
            if (building.hardware?.remoteUnits) {
                addRemotesToList(building.hardware.remoteUnits, serials);
            }
            if (building.hardware?.soloUnits) {
                addSoloToList(building.hardware.soloUnits, serials);
            }
            structIdSerials.push({
                id: building.id,
                sn: serials});
            serials = [];
        });
        // site.piles.forEach(pile => {
        //     if (pile.hardware?.masterUnits) {
        //         addMastersToList(pile.hardware.masterUnits, serials);
        //     }
        //     if (pile.hardware?.remoteUnits) {
        //         addRemotesToList(pile.hardware.remoteUnits, serials);
        //     }
        //     if (pile.hardware?.soloUnits) {
        //         addSoloToList(pile.hardware.soloUnits, serials);
        //     }
        //     structIdSerials.push({
        //         id: pile.id,
        //         sn: serials});
        //     serials = [];
        // });
        site.automationHubs.forEach(hub => {
            if (hub.hardware?.masterUnits) {
                addMastersToList(hub.hardware.masterUnits, serials);
            }
            if (hub.hardware?.remoteUnits) {
                addRemotesToList(hub.hardware.remoteUnits, serials);
            }
            if (hub.hardware?.soloUnits) {
                addSoloToList(hub.hardware.soloUnits, serials);
            }
            structIdSerials.push({
                id: hub.id,
                sn: serials});
            serials = [];
        });

        return structIdSerials;
};

export default getAllSerialsFromSite;
