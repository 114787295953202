/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Tab, Tabs} from '@mui/material';
import Constants from '../../../../helper/Constants';
import getTestID from '../../../../helper/functions/getTestID';

interface Props {
    tab: string,
    onTabChange: (event: any, newValue: string) => void,
}

const StructureSetupOptions = (props: Props) => (
    <Tabs
        value={props.tab}
        onChange={props.onTabChange}
    >
        <Tab
            {...getTestID('bushel_capacity_tab')}
            label={Constants.BUSHEL_CAPACITY}
            value={Constants.BUSHEL_CAPACITY}
        />
        <Tab
            {...getTestID('dimensions_tab')}
            label={Constants.DIMENSIONS}
            value={Constants.DIMENSIONS}
        />
    </Tabs>
);

export default StructureSetupOptions;