/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import Constants from '../../../../helper/Constants';
import {styled} from '@mui/material';
import TextFieldInput from '../../../common/input/TextFieldInput';
import {InputFieldProps} from '../../../../types/components/ApiTypes';
import BagRotationField from './BagRotationField';
import TooltipStore from '../../../../stores/TooltipStore';
import TooltipMessages from '../../../../helper/TooltipMessages';
import SelectFieldInput from '../../../common/input/SelectFieldInput';
import KeyValueTypography from '../../../common/typography/KeyValueTypography';
import InfoTooltip from '../../../common/InfoTooltip';
import {observer} from 'mobx-react-lite';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '16px',
});

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '8px',
    width: '100%',
});

interface Props {
    name: InputFieldProps,
    length: InputFieldProps,
    width: InputFieldProps,
    rotation: InputFieldProps,
    quantity: InputFieldProps,
    powerSource: InputFieldProps,
    building?: boolean,
}

const BagDetailsForm = observer((props: Props) => (
    <Root>
        <Row>
            <KeyValueTypography
                label="STEP 1:"
                value={!props.building ? Constants.BAG_DETAILS : Constants.BUILDING_DETAILS}
            />
            <InfoTooltip
                id="power_source_tooltip_button"
                open={TooltipStore.beginTooltips && TooltipStore.showPowerSourceMessages}
                message={TooltipMessages.POWER}
                onClick={() => TooltipStore.setShowPowerSourceMessages(false, true)}
            />
        </Row>
        <TextFieldInput
            id="pile_name_input"
            label={Constants.NAME}
            field={props.name}
        />
        <TextFieldInput
            id='bag_length_input'
            label={Constants.LENGTH}
            field={props.length}
            textFieldProps={{
                inputProps: {
                    type: 'number',
                    min: '0',
                },
            }}
        />
        <TextFieldInput
            id='bag_width_input'
            label={Constants.WIDTH}
            field={props.width}
            textFieldProps={{
                inputProps: {
                    type: 'number',
                    min: '0',
                },
            }}
        />
        <BagRotationField
            rotation={props.rotation}
        />
        <TextFieldInput
            id="bag_quantity_input"
            label={!props.building ? Constants.QUANTITY : Constants.HEIGHT}
            field={props.quantity}
            textFieldProps={{
                inputProps: {
                    type: 'number',
                    min: '0',
                },
            }}
        />
        <SelectFieldInput
            id="bin_details_power_source_input"
            label={Constants.POWER_SOURCE}
            field={props.powerSource}
            options={[
                {
                    id: 'battery_powered',
                    title: Constants.BATTERY_POWERED,
                },
                {
                    id: 'plugged_in',
                    title: Constants.PLUGGED_IN,
                },
            ]}
        />
    </Root>
));

export default BagDetailsForm;
