/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import AccordionComponent from '../../../common/AccordionComponent';
import SiteReportTableRowSummary from './SiteReportTableRowSummary';
import SiteReportTableRowDetails from './SiteReportTableRowDetails';
import {CustomerReportData} from '../../../../types/components/ApiTypes';

interface Props {
    row: CustomerReportData,
}

const SiteReportTableRow = observer((props: Props) => (
    <AccordionComponent
        id={props.row.structure}
        summary={<SiteReportTableRowSummary row={props.row}/>}
        details={<SiteReportTableRowDetails row={props.row}/>}
        expanded={props.row.devices.length > 1}
        disabled={props.row.devices.length <= 1}
    />
));

export default SiteReportTableRow;
