/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useMemo, useState} from 'react';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DisplayGridSortableTableHeader from '../../../common/displayTable/DisplayGridSortableTableHeader';
import Constants from '../../../../helper/Constants';
import {observer} from 'mobx-react-lite';
import SiteReportTableRow from './SiteReportTableRow';
import AccordionTableHeader from '../../../common/displayTable/AccordionTableHeader';
import getSiteReportData from '../../../../helper/functions/getSiteReportData';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import {Site} from '../../../../types/components/ApiTypes';
import PaginationAccordionTable from '../../../common/displayTable/PaginationAccordionTable';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import {styled} from '@mui/material';

interface Props {
    site: Site,
    displayAll?: boolean,
}

const SkeletonRoot = styled('div')({
    width: '100%',
    padding: '20px',
});

const SiteReportTable = observer((props: Props) => {
    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const rows = useMemo(() => BinsenseStore.customerReportsData, [ BinsenseStore.customerReportsData ]);

    useEffect(() => BinsenseStore.setCustomerReportsData(getSiteReportData(props.site)), [ props.site ]);

    const renderGridItem = (label: string | number, id: string, key?: string) => {
        if (key) {
            return (
                <DisplayGridSortableTableHeader
                    id={id}
                    label={label}
                    xs={1.2}
                    data={rows}
                    setData={BinsenseStore.setCustomerReportsData}
                    sortColumn={sortColumn}
                    setSortColumn={setSortColumn}
                    sortAscending={sortAscending}
                    setSortAscending={setSortAscending}
                    dataKey={key}
                    fontSize={12}
                />
            );
        }
        return (
            <DisplayGridLabel
                id={id}
                label={String(label)}
                xs={1.2}
                header
                fontSize={12}
            />
        );
    };

    const renderRow = (row: any) => (
        <SiteReportTableRow
            row={row}
            key={`${ row.structure }_row`}
        />
    );

    return !BinsenseStore.awaitingApi && !BinsenseStore.awaitingUserData ? (
        <PaginationAccordionTable
            rows={rows}
            renderRow={renderRow}
            displayAll={props.displayAll}
        >
            <AccordionTableHeader>
                {renderGridItem(Constants.STRUCTURE, 'structure_header', 'structure')}
                {renderGridItem(Constants.SERIAL, 'serial_header')}
                {renderGridItem(Constants.INSTALL_DATE, 'install_date_header')}
                {renderGridItem(Constants.VOLTAGE, 'voltage_header')}
                {renderGridItem(Constants.LAST_VOLTAGE, 'last_voltage_header')}
                {renderGridItem(Constants.LAST_CABLE, 'last_cable_header')}
                {renderGridItem(Constants.TEMP_CABLES, 'temp_cables_header', 'tempCables')}
                {renderGridItem(Constants.MOIST_CABLES, 'moist_cables_header', 'moistureCables')}
                {renderGridItem(Constants.DEVICE, 'device_header')}
                {renderGridItem(Constants.STATUS, 'status_header')}
            </AccordionTableHeader>
        </PaginationAccordionTable>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={400}/>
        </SkeletonRoot>
    );
});

export default SiteReportTable;
