/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Building, DistanceUnit} from '../../types/components/ApiTypes';
import getDeviceType from './getDeviceType';
import getDevice from './getDevice';
import getAssociatedMaster from './getAssociatedMaster';
import getSerialNumbers from './getSerialNumbers';
import getPowerSource from './getPowerSource';
import Constants from '../Constants';

const getBuildingData = (building: Building) => ({
        associatedMaster: getAssociatedMaster(building.settings?.hardwareType ? building.settings.hardwareType : '',
            building.hardware.remoteUnits),
        device: getDevice(building),
        deviceType: getDeviceType(building),
        length: building.length,
        name: building.name ? building.name : Constants.NA,
        powerSource: getPowerSource(building),
        rotation: building.rotation ? building.rotation : 0,
        sn: getSerialNumbers(building),
        unit: DistanceUnit.feet,
        width: building.width,
        height: building.height,
});

export default getBuildingData;
