import callPostAPI from '../../callPostAPI';
import {Site} from '../../../types/components/ApiTypes';
import updateData from '../../../helper/functions/updateData';
import WebStore from '../../../stores/WebStore';

const postSiteDealer = (enqueueSnackbar: any, userId: number, body: Site) => {
    callPostAPI(`site/${ userId }`,
        body,
        (response) => {
            console.log(response);
            updateData(enqueueSnackbar, userId);
            WebStore.setPageIds([ userId, response, -1 ], true);
            WebStore.setCreateSiteNewUserId(-1);
        },
        enqueueSnackbar,
        'Site added successfully'
    );
};

export default postSiteDealer;