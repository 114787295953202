import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';

const getLostDeviceReport = (enqueueSnackbar: any) => {
    callAPI('lostDeviceReport',
        (response) => {
            const data = response.map((device: any) => ({
                id: device.deviceSerial,
                user: device.userName,
                name: device.name,
                structure: device.structureName,
                device: `${ device.deviceType === 'AUTOMATION_HUB' ? 'Automation Hub' : device.deviceType.charAt(0) +
                    device.deviceType.slice(1).toLowerCase() } - ${ device.deviceSerialHex }`,
                lastCallIn: device.lastUpdateTime,
                siteId: device.siteId,
                structureId: device.structureId,
            }));
            BinsenseStore.setLostDevicesData(data);
        },
        enqueueSnackbar
    );
};

export default getLostDeviceReport;