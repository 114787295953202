/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import BaseModal from '../../../../common/modal/BaseModal';
import {Button, styled} from '@mui/material';
import getTestID from '../../../../../helper/functions/getTestID';
import {Download} from '@mui/icons-material';
import Constants from '../../../../../helper/Constants';
import StyledDataGridPro from '../../../../common/StyledDataGridPro';
import exportTableToCSV from '../../../../../helper/functions/exportTableToCSV';
import {Bin, Building, Cable, GrainBag} from '../../../../../types/components/ApiTypes';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import getSensorTableRowData from '../../../../../helper/functions/getSensorTableRowData';
import {GridCellParams} from '@mui/x-data-grid-pro';
import DetailedSensorTableCell from './DetailedSensorTableCell';
import replaceSpaces from '../../../../../helper/functions/replaceSpaces';

interface Props {
    bin: Bin | GrainBag | Building,
    open: boolean,
    close: () => void,
}

const Container = styled('div')({
    width: '100%',
    height: '500px',
    marginBottom: '8px',
});

const Root = styled('div')({
    width: '1100px',
    padding: '16px',
});

const TableViewModal = (props: Props) => {
    const cables = props.bin.hardware.cables;
    const highTemperatureThreshold = props.bin.settings.highTemperatureThreshold;
    const lowTemperatureThreshold = props.bin.settings.lowTemperatureThreshold;

    const rows = getSensorTableRowData(cables, highTemperatureThreshold, lowTemperatureThreshold);

    const getColumn = (cable: Cable) => ({
        field: `${ cable.smartId }_column`,
        headerName: `Cable ${ cable.smartId }`,
        renderCell: (params: GridCellParams) => (
            <DetailedSensorTableCell
                value={params.value}
                isTempHigh={params.row[`temp${ cable.smartId }High`]}
                isMoistureHigh={params.row[`moist${ cable.smartId }High`]}
                sensorColor={params.row[`sensor${ cable.smartId }Color`]}
            />
        ),
    });

    const columns = [
        {
            field: 'row',
            headerName: Constants.ROW,
        },
        ...cables.map(cable => getColumn(cable)),
    ];

    return (
        <BaseModal
            id="new_user"
            open
            close={props.close}
            title={`${ props.bin.name } - ${ Constants.TABLE_VIEW }`}
            titleObject={(
                <Button
                    {...getTestID('export_button')}
                    startIcon={<Download/>}
                    onClick={() => exportTableToCSV(columns, rows, `${ replaceSpaces(props.bin.name) }_sensor_table_expanded`)}
                >
                    {Constants.EXPORT}
                </Button>
            )}
            disableSubmitOnAwait
            hideButtons
        >
            <Root>
                <Container>
                    <StyledDataGridPro
                        rows={rows}
                        columns={columns}
                        density='standard'
                    />
                </Container>
                <SubmitButton
                    id="close_button"
                    text={Constants.CLOSE}
                    onClick={props.close}
                />
            </Root>
        </BaseModal>
    );
};

export default TableViewModal;
