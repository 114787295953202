import callPutAPI from '../../callPutAPI';
import updateData from '../../../helper/functions/updateData';

const putBuilding = (enqueueSnackbar: any, id: number, body: any) => {
    callPutAPI(`structure/building/${ id }`,
        body,
        (response) => {
            if (response === 'OK') {
                updateData(enqueueSnackbar);
            }
        },
        enqueueSnackbar,
        'Building updated successfully'
    );
};

export default putBuilding;