/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import LoginPaper from './LoginPaper';
import BinsenseLogo from '../common/BinsenseLogo';
import {useSnackbar} from 'notistack';
import {useEffect} from 'react';
import getLoginConfig from '../../controllers/apiCalls/get/getLoginConfig';

const Root = styled('div')({
    display: 'flex',
    height: '100vh',
    width: '100%',
    flexDirection: 'row',
});

const LeftColumn = styled('div')(({theme}) => ({
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // @ts-ignore
    backgroundColor: theme.palette.primary.drawerUserBackground,
}));

const RightColumn = styled('div')(({theme}) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // @ts-ignore
    backgroundColor: theme.palette.primary.background,
}));

const LoginScreen = () => {
    const {enqueueSnackbar} = useSnackbar();
    useEffect(() => {
        getLoginConfig(enqueueSnackbar);
    }, []);

    return (
        <Root>
            <LeftColumn>
                <BinsenseLogo
                    style={{
                        width: '60%',
                        height: 'auto',
                        minWidth: '170px',
                    }}
                />
            </LeftColumn>
            <RightColumn>
                <LoginPaper/>
            </RightColumn>
        </Root>
    );
};

export default LoginScreen;
