/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import Constants from '../../../../helper/Constants';
import {styled} from '@mui/material';
import TextFieldInput from '../../../common/input/TextFieldInput';
import {InputFieldProps} from '../../../../types/components/ApiTypes';
import {GrainBagSVG} from '../../../../images/sitemap/RectangleStructSVG';

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
});

const TextFieldArea = styled('div')({
    width: '70%',
    marginRight: '4px',
});

const RotationExampleArea = styled('div')({
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

interface Props {
    rotation: InputFieldProps,
}

const BagRotationField = (props: Props) => (
    <Root>
        <TextFieldArea>
            <TextFieldInput
                id="bag_rotation_input"
                label={Constants.ROTATION}
                field={props.rotation}
                textFieldProps={{
                    inputProps: {
                        type: 'number',
                    },
                }}
            />
        </TextFieldArea>
        <RotationExampleArea>
            <GrainBagSVG
                id="bag_rotation_example"
                x={0}
                y={0}
                width={24}
                length={54}
                rotation={Number(props.rotation.value)}
            />
        </RotationExampleArea>
    </Root>
);

export default BagRotationField;
