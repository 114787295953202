/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {MarkerData, MarkerTypes} from '../../types/components/ApiTypes';

const disableAutomationOption = (markers: MarkerData[]) => {
    let returnVal: boolean = true;
    markers.filter(marker => {
        if (marker.display?.type === MarkerTypes.automation) {
            returnVal = false;
        }
    });
    return returnVal;
};

export default disableAutomationOption;
