/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {MarkerData, StructIdSerials} from '../../types/components/ApiTypes';

const getSerialsFromMarkers = (markers: MarkerData[]) => {
    let serials: string[] = [];
    const markerSerials: StructIdSerials[] = [];
    markers.map(marker => {
        if (marker.draggable) {
            marker.display?.grainBinData?.sn?.forEach(serial => {
                serials.push(serial);
            });
            marker.display?.grainBagData?.sn?.forEach(serial => {
                serials.push(serial);
            });
            marker.display?.grainPileData?.sn?.forEach(serial => {
                serials.push(serial);
            });
            marker.display?.buildingData?.sn?.forEach(serial => {
                serials.push(serial);
            });
            markerSerials.push({
                id: marker.id,
                sn: serials});
            serials = [];
        }
    });
    return markerSerials;
};

export default getSerialsFromMarkers;
