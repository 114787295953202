/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Bin, BinType, DistanceUnit} from '../../types/components/ApiTypes';
import getDevice from './getDevice';
import getDeviceType from './getDeviceType';
import getAssociatedMaster from './getAssociatedMaster';
import getPowerSource from './getPowerSource';
import getSerialNumbers from './getSerialNumbers';
import Constants from '../Constants';

const getGrainBinData = (bin: Bin) => ({
        associatedMaster: getAssociatedMaster(bin.settings?.hardwareType ? bin.settings.hardwareType : '',
            bin.hardware.remoteUnits),
        name: bin.name ? bin.name : Constants.NA,
        type: bin.binType === 'flat' ? BinType.flat : BinType.hopper,
        peakHeight: bin.peakHeight ? bin.peakHeight : null,
        eaveHeight: bin.eaveHeight ? bin.eaveHeight : null,
        bushelCapacity: bin.capacity ? bin.capacity : null,
        device: getDevice(bin),
        deviceType: getDeviceType(bin),
        sn: getSerialNumbers(bin),
        diameter: bin.diameter,
        unit: DistanceUnit.feet,
        powerSource: getPowerSource(bin),
        sortOrder: bin.sortOrder,
});

export default getGrainBinData;
