/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Dispatch, SetStateAction, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../common/displayTable/DisplayGridContent';
import Constants from '../../../helper/Constants';
import DisplayGridActions from '../../common/displayTable/DisplayGridActions';
import {Delete, Edit, Visibility} from '@mui/icons-material';
import DisplayGridCheckBox from '../../common/displayTable/DisplayGridCheckBox';
import BinsenseStore from '../../../stores/BinsenseStore';
import {ActionButton, DeviceSettingsStructure} from '../../../types/components/ApiTypes';
import getTempUnit from '../../../helper/functions/getTempUnit';
import goToViewDevicePage from '../../../helper/functions/goToViewDevicePage';
import getIndex from '../../../helper/functions/getIndex';
import { displayTemp } from '../../../helper/functions/functions';
import DeleteStructureModal from '../../common/modal/DeleteStructureModal';
import deleteStructure from '../../../controllers/apiCalls/delete/deleteStructure';
import {useSnackbar} from 'notistack';

interface Props {
    setEditingId: Dispatch<SetStateAction<number | null>>,
    setModalOpen: Dispatch<SetStateAction<boolean>>,
    tableData: DeviceSettingsStructure[],
    row: DeviceSettingsStructure,
    showDevice: boolean,
}

const DeviceSettingsTableRow = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ deleteOpen, setDeleteOpen ] = useState(false);

    const xs = 12 / 6;

    const tempUnit = useMemo(getTempUnit, [ BinsenseStore.userData.settings.preferences ]);

    const enableAutoLevelToggle = props.row.hardwareType.toLowerCase() === Constants.LIVE.toLowerCase() ||
        props.row.hardwareType.toLowerCase() === Constants.PLUS.toLowerCase();

    const onEditClick = (id: number) => {
        props.setEditingId(id);
        props.setModalOpen(true);
    };

    const renderGridLabel = (id: string, label: string | number, lengthLimit?: number) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={xs}
            lengthLimit={lengthLimit}
        />
    );

    const renderTempAlertsCell = (
        name: string, alerts: string, highThreshold: number | null) =>
        alerts === 'thresholds' ? (
            renderGridLabel(`${ name }_alerts`, highThreshold
                ? ` ${ displayTemp(getTempUnit() === 'F', highThreshold) } ${ tempUnit }`
                : Constants.THRESHOLDS)
        ) : (
            <DisplayGridLabel
                id={`${ name }_alerts`}
                label={Constants.THRESHOLDS}
                xs={xs}
            />
        );

    const changeSelected = (id: number) => {
        const index = getIndex(id, props.tableData);
        const clone = JSON.parse(JSON.stringify(props.tableData));
        clone[index].selected = !clone[index].selected;
        BinsenseStore.setDeviceTableData(clone);
    };

    const onDelete = () => {
        deleteStructure(enqueueSnackbar, props.row.id);
    };

    const getButtons = () => {
        const buttons: ActionButton[] = [];
        if ( props.showDevice ) {
            buttons.push(
                {
                    id: `${ props.row.name }_view_device_button`,
                    onClick: () => goToViewDevicePage(props.row.id),
                    icon: <Visibility/>,
                    hoverText: Constants.VIEW_DEVICE,
                }
            );
        }
        buttons.push(
            {
                id: `${ props.row.name }_edit_button`,
                onClick: () => onEditClick(props.row.id),
                icon: <Edit/>,
                hoverText: Constants.EDIT,
            }
        );
        buttons.push(
            {
                id: `${ props.row.name }_delete_button`,
                onClick: () => setDeleteOpen(true),
                icon: <Delete/>,
                hoverText: Constants.DELETE,
            }
        );
        return buttons;
    };

    return (
        <DisplayGridContent>
            <DisplayGridCheckBox
                xs={xs}
                id={`${ props.row.name }_selected`}
                value={props.row.selected}
                onChange={() => changeSelected(props.row.id)}
            />
            {renderGridLabel(`${ props.row.name }_structure`, props.row.name, 12)}
            {renderTempAlertsCell(props.row.name, props.row.alerts, props.row.highTempThreshold)}
            {renderGridLabel(`${ props.row.name }_weekly_temp_rise`,
                ` ${ displayTemp(getTempUnit() === 'F', props.row.weeklyTempRise, true) } ${ tempUnit }`)}
            {renderGridLabel(`${ props.row.name }_auto_level`,
                enableAutoLevelToggle ? (props.row.autoLevel ? Constants.ON : Constants.MANUAL)
                : Constants.NA, 12)}
            <DisplayGridActions
                buttons={getButtons()}
                xs={xs}
                id={props.row.id}
            />
            {deleteOpen && (
                <DeleteStructureModal
                    id={`${ props.row.id }_delete_confirmation_modal`}
                    open
                    close={() => setDeleteOpen(false)}
                    submit={onDelete}
                    structName={props.row.name}
                />
            )}
        </DisplayGridContent>
    );
});

export default DeviceSettingsTableRow;
