import Constants from '../../../helper/Constants';
import callAPI from '../../callAPI';

const getSmsResponse = (enqueueSnackbar: any, textId: number, reply: string, refresh: () => void) => {
    callAPI(`smsResponse/${ textId }/${ reply }`,
        (response) => {
            if (response.toLowerCase() === Constants.SUCCESS.toLowerCase()) {
                enqueueSnackbar('Successfully replied to alert', {variant: 'success'});
                refresh();
            }
        },
        enqueueSnackbar
    );
};

export default getSmsResponse;