/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Fragment} from 'react';
import {Chip, styled, Typography} from '@mui/material';
import {SiteTooltipData} from '../../../../../types/components/ApiTypes';
import getTestID from '../../../../../helper/functions/getTestID';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    alignSelf: 'stretch',
    width: '275px',
});

interface Props {
    siteTooltip: SiteTooltipData,
}

const SiteMarkerTooltip = (props: Props) => (
    <Fragment>
        <Column>
            <Row style={{ marginBottom: '8px' }}>
                <Typography
                    {...getTestID('map_popup_name')}
                    fontSize="16px"
                    fontWeight="bold"
                >
                    {props.siteTooltip.name}
                </Typography>
                <Chip
                    {...getTestID('map_popup_status')}
                    label={props.siteTooltip.displayData.status}
                    color={props.siteTooltip.displayData.status === 'Online' ? 'success' : 'error'}
                    size='small'
                />
            </Row>
            <Row>
                <Typography fontSize="12px">Customer Name: </Typography>
                <Typography
                    {...getTestID('map_popup_customer_name')}
                    fontSize="12px"
                    color="primary"
                >
                    {props.siteTooltip.customer.lastName.toUpperCase()}, {props.siteTooltip.customer.firstName}
                </Typography>
            </Row>
            <Row>
                <Typography fontSize="12px">Email: </Typography>
                <Typography
                    {...getTestID('map_popup_customer_email')}
                    fontSize="12px"
                    color="primary"
                >
                    {props.siteTooltip.customer.email}
                </Typography>
            </Row>
            <Row>
                <Typography fontSize="12px">Type: </Typography>
                <Typography
                    {...getTestID('map_popup_device_type')}
                    fontSize="12px"
                    color="primary"
                >
                    {props.siteTooltip.displayData.deviceType}
                </Typography>
            </Row>
        </Column>
    </Fragment>
);

export default SiteMarkerTooltip;
