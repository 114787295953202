/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import {useEffect, useMemo, useState} from 'react';
import BinsenseStore from '../../../stores/BinsenseStore';
import DisplayGridSortableTableHeader from '../../common/displayTable/DisplayGridSortableTableHeader';
import Constants from '../../../helper/Constants';
import InventoryTableRow from './InventoryTableRow';
import getInventory from '../../../helper/functions/getInventory';
import AccordionTableHeader from '../../common/displayTable/AccordionTableHeader';
import PaginationAccordionTable from '../../common/displayTable/PaginationAccordionTable';
import {styled, Typography} from '@mui/material';
import TooltipStore from '../../../stores/TooltipStore';
import TooltipMessages from '../../../helper/TooltipMessages';
import getIndex from '../../../helper/functions/getIndex';
import WebStore from '../../../stores/WebStore';
import { InventoryTableSite } from '../../../types/components/ApiTypes';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import DisplayGridItem from '../../common/displayTable/DisplayGridItem';
import getTestID from '../../../helper/functions/getTestID';
import InfoTooltip from '../../common/InfoTooltip';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const InventoryTable = observer(() => {
    useEffect(() => BinsenseStore.setInventoryTableData(getInventory(BinsenseStore.userData)),
        [ BinsenseStore.userData ]);

    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);
    const siteIndex = useMemo(() => getIndex(WebStore.selectedSiteId, BinsenseStore.inventoryTableData),
        [ BinsenseStore.inventoryTableData, WebStore.selectedSiteId ]);

    const rows = useMemo(() => {
        const selectedSiteRows: InventoryTableSite[] = [] as InventoryTableSite[];
        if (siteIndex >= 0) {
            selectedSiteRows.push(BinsenseStore.inventoryTableData[siteIndex]);
        }
        return selectedSiteRows;
    }, [ BinsenseStore.inventoryTableData, siteIndex ]);

    const getHeader = (id: string, label: string, key: string) => (
        <DisplayGridSortableTableHeader
            id={id}
            label={label}
            xs={2}
            data={rows}
            setData={BinsenseStore.setInventoryTableData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderGridItem = (label: string | number, id: string, xs: number = 8.0 / 5.0) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={xs}
            header
        />
    );

    const renderRow = (row: any) => (
        <InventoryTableRow
            data={row}
            key={`${ row.id }_inventory_row`}
        />
    );

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <PaginationAccordionTable
            rows={rows}
            renderRow={renderRow}
        >
            <AccordionTableHeader>
                {getHeader('site', Constants.SITE, 'site')}
                {renderGridItem('Commodity', 'commodity_header', 2)}
                <DisplayGridItem
                    xs={8.0 / 5.0}
                    header
                >
                    <Row>
                        <Typography
                            {...getTestID('bushels_header')}
                            fontWeight="bold"
                        >
                            Level
                        </Typography>
                        <InfoTooltip
                            id="inventory_tooltip_button"
                            open={TooltipStore.beginTooltips && TooltipStore.showInventoryMessages}
                            message={TooltipMessages.INVENTORY}
                            onClick={() => TooltipStore.setShowInventoryMessages(false, true)}
                        />
                    </Row>
                </DisplayGridItem>
                {renderGridItem('Min Temp.', 'min_temp_header')}
                {renderGridItem('Max Temp.', 'max_temp_header')}
                {renderGridItem('Min Moisture', 'min_moisture_header')}
                {renderGridItem('Max Moisture', 'max_moisture_header')}
            </AccordionTableHeader>
        </PaginationAccordionTable>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={600}/>
        </SkeletonRoot>
    );
});

export default InventoryTable;
