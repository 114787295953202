/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Button, styled} from '@mui/material';
import getTestID from '../../helper/functions/getTestID';
import signIn from '../../helper/functions/signIn';
import {useSnackbar} from 'notistack';
import {Dispatch, SetStateAction} from 'react';

interface Props {
    tab: string,
    username: string,
    password: string,
    setForgotPasswordModalOpen: Dispatch<SetStateAction<boolean>>
    setForgotUsernameModalOpen: Dispatch<SetStateAction<boolean>>
}

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    paddingTop: '8px',
});

const LoginPaperButtons = (props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    return (
        <Root>
            <Button
                {...getTestID('sign_in_button')}
                color="primary"
                variant="contained"
                onClick={() => signIn(props.username, props.password, props.tab, enqueueSnackbar)}
                disabled={props.username === '' || props.password === ''}
                style={{
                    width: '100%',
                    height: '50px',
                }}
            >
                Sign in
            </Button>
            <Row>
                <Button
                    {...getTestID('forgot_username_button')}
                    color="primary"
                    onClick={() => props.setForgotUsernameModalOpen(true)}
                >
                    Forgot username?
                </Button>
                <Button
                    {...getTestID('forgot_password_button')}
                    color="primary"
                    onClick={() => props.setForgotPasswordModalOpen(true)}
                >
                    Forgot password?
                </Button>
            </Row>

        </Root>
    );
};

export default LoginPaperButtons;
