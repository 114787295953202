/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {MobileDateTimePicker} from '@mui/x-date-pickers/MobileDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import getTestID from '../../helper/functions/getTestID';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import moment from 'moment';

interface Props {
    label: string,
    value: any,
    setValue: any,
    id: string,
    marginRight?: boolean,
    minDate?: any,
    maxDate?: any,
    small?: boolean,
}

const DateTimeSelector = observer((props:Props) => {
    const [ newTime, setNewTime ] = useState<any>(moment((props.value)));

    const setValue = () => {
        props.setValue(newTime);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
                label={props.label}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        {...getTestID(`${ props.id }_input`)}
                        fullWidth
                        style={{ marginRight: props.marginRight ? '4px' : '0px' }}
                        size={props.small ? 'small' : undefined}
                    />
                )}
                value={newTime}
                onChange={(newValue) => setNewTime(newValue)}
                onAccept={setValue}
                minDateTime={props.minDate}
                maxDateTime={props.maxDate}
            />
        </LocalizationProvider>
    );
});

export default DateTimeSelector;
