/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useState} from 'react';
import {styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import BaseModal from '../../../../common/modal/BaseModal';
import Constants from '../../../../../helper/Constants';
import TextFieldInput from '../../../../common/input/TextFieldInput';
import {Bin, Building, GrainBag} from '../../../../../types/components/ApiTypes';
import putBinSettings from '../../../../../controllers/apiCalls/put/putBinSettings';
import {useSnackbar} from 'notistack';

const Container = styled('div')({
    width: '500px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
    bin: Bin | GrainBag | Building,
    fanId: number | string,
}

const FanStatusModal = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ fanRunTime, setFanRunTime ] = useState('24');
    const [ fanRunTimeError, setFanRunTimeError ] = useState('');

    const isValid = () => true;

    const submit = () => {
        if (isValid()) {
            let settings: {};
            if (props.fanId === 'All') {
                settings = {
                    fanControls: props.bin.hardware.fans.map(fan => ({
                        fanId: fan.id,
                        request: 'TURN_ON',
                        hours: Number(fanRunTime),
                    })),
                };
            }
            else {
                settings = {
                    fanControls: {
                        fanId: props.fanId,
                        request: 'TURN_ON',
                        hours: Number(fanRunTime),
                    },
                };
            }
            putBinSettings(enqueueSnackbar, props.bin.id, settings);
            props.close();
        }
    };

    return (
        <BaseModal
            id="fan_status"
            open={props.open}
            close={props.close}
            submit={submit}
            title="Turn Fan On"
            disableSubmitOnAwait
        >
            <Container>
                <Typography paddingBottom="16px">
                    How long do you want the fan(s) to run?
                </Typography>
                <TextFieldInput
                    id="fan_run_hours_input"
                    label={Constants.HOURS}
                    field={{
                        value: fanRunTime,
                        setValue: setFanRunTime,
                        error: fanRunTimeError,
                        setError: setFanRunTimeError,
                    }}
                    textFieldProps={{
                        inputProps: {
                            type: 'number',
                            min: 1,
                            max: 96,
                        },
                    }}
                />
            </Container>
        </BaseModal>
    );
});

export default FanStatusModal;
