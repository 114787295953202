/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import {CSSProperties, useEffect, useMemo, useState} from 'react';
import getPaginationData from '../../../helper/functions/getPaginationData';
import Pagination from '../pagination/Pagination';
import getRowsPerPage from '../../../helper/functions/getRowsPerPage';
import DisplayGridPaper from './DisplayGridPaper';
import WebStore from '../../../stores/WebStore';

interface Props {
    rows: any[],
    renderRow: (row: any) => object,
    style?: CSSProperties,
    displayAll?: boolean,
    children: object,
}

const PaginationAccordionTable = observer((props: Props) => {
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(props.rows.length >= 10 || props.displayAll ? 10 : props.rows.length);

    useEffect(() => {
        setRowsPerPage(getRowsPerPage(rowsPerPage, props.rows.length));
        setPage(0);
    }, [ props.rows ]);

    const displayedRows = useMemo(() => getPaginationData(props.rows, page, rowsPerPage),
        [ props.rows, page, rowsPerPage ]);

    const backgroundColor = useMemo(() => WebStore.lightMode ? 'rgba(247, 247, 247, 1)' : 'rgba(30, 30, 30, 1)',
        [ WebStore.lightMode ]);

    return (
        <div
            style={{
                ...props.style,
                paddingBottom: '8px',
            }}
        >
            <DisplayGridPaper
                sx={{ backgroundColor }}
            >
                {props.children}
                {displayedRows.map(row => props.renderRow(row))}
            </DisplayGridPaper>
            {!props.displayAll && (
                <Pagination
                    count={props.rows.length}
                    pageField={{
                        value: page,
                        setValue: setPage,
                    }}
                    rowsPerPageField={{
                        value: rowsPerPage,
                        setValue: setRowsPerPage,
                    }}
                />
            )}
        </div>
    );
});

export default PaginationAccordionTable;
