/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

const getNewUser = () => ({
    account: {
        accountEmail: '',
        address: {
            address: '',
            city: '',
            country: '',
            postalCode: '',
            province: '',
            userName: '',
        },
        companyName: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        userName: '',
    },
    id: -1,
    sites: [],
    retailerHasAccess: true,
    temperatureUnits: '',
    timeZone: '',
});

export default getNewUser;
