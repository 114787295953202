/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import Colors from '../../../helper/Colors';

const TextArea = styled('div')(({}) => ({
    // @ts-ignore
    backgroundColor: Colors.primaryDark,
    padding: '10px',
    borderBottomRightRadius: Colors.paperBorderRadius,
    borderBottomLeftRadius: Colors.paperBorderRadius,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    cursor: 'pointer',
}));

interface Props {
    title: string,
    subtext: string,
    url: string,
    src: string,
}

const CarouselTextDisplay = (props: Props) => (
    <div
        onClick={() => window.open(props.url, '_blank')}
        style={{
            display: 'flex',
            justifyContent: 'center',
        }}
    >
        <img
            src={props.src}
            alt="carousel image"
            style={{
                width: '60%',
                height: '60%',
                cursor: 'pointer',
                paddingTop: '15px',
            }}
        />
        <TextArea>
            <Typography color={'#000000'}>{props.title}</Typography>
            <Typography color={'#000000'}>{props.subtext}</Typography>
        </TextArea>
    </div>
);

export default CarouselTextDisplay;
