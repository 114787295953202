/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import Paper from '@mui/material/Paper';
import {styled, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import {observer} from 'mobx-react-lite';
import Welcome from '../../../images/welcome.png';
import getTestID from '../../../helper/functions/getTestID';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '212px',
    justifyContent: 'space-between',
});

const ButtonRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});

const WelcomeTile = observer(() => (
    <Paper
        elevation={6}
        sx={{
            backgroundColor: '#bfd1ea',
            marginBottom: '0px',
            height: '100%',
        }}
    >
        <Row>
            <Column>
                <div>
                    <Typography
                        sx={{
                            color: 'black',
                            fontSize: '24px',
                            fontWeight: 600,
                            paddingBottom: '16px',
                        }}
                    >
                        Welcome to the Bin-Sense Web Dashboard!
                    </Typography>
                    <Typography
                        sx={{
                            color: 'black',
                            paddingBottom: '32px',
                        }}
                    >
                        We value your feedback and would love to hear from you.
                        If you find a bug or have a feature request please
                        click the button below, or in the top right corner.
                    </Typography>
                </div>

                <ButtonRow>
                    <Button
                        {...getTestID('report_bugorfeature_button')}
                        variant="contained"
                        sx={{
                            color: 'black',
                            backgroundColor: 'primary',
                            marginRight: '16px',
                        }}
                        onClick={() =>
                            window.open('https://www.binsense.com/submit-feature-request-or-report-a-bug/', '_blank')
                        }
                    >
                        Submit a Bug Report or Feature Request
                    </Button>
                </ButtonRow>
            </Column>
            <img
                src={Welcome}
                alt="welcome image"
            />
        </Row>
    </Paper>
));

export default WelcomeTile;
