/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import TitlePaper from '../../../common/TitlePaper';
import {Grid} from '@mui/material';
import Constants from '../../../../helper/Constants';
import GridTextFieldInput from '../../../common/input/GridTextFieldInput';
import {InputFieldProps} from '../../../../types/components/ApiTypes';
import GridSelectFieldInput from '../../../common/input/GridSelectFieldInput';
import SubmitButton from '../../../common/buttons/SubmitButton';
import {useMemo} from 'react';
import BinsenseStore from '../../../../stores/BinsenseStore';

interface Props {
    firstName: InputFieldProps,
    lastName: InputFieldProps,
    address: InputFieldProps,
    postalCode: InputFieldProps,
    city: InputFieldProps,
    province: InputFieldProps,
    country: InputFieldProps,
    phoneNumber?: InputFieldProps,
    email?: InputFieldProps,
    submit: () => void,
}

const EditCustomerInformationPaper = (props: Props) => {
    const countryOptions = useMemo(() => BinsenseStore.dataModel.countries.map(c => ({
        id: c.id.toString(),
        title: c.name,
        value: c.abbreviation,
    })), [ BinsenseStore.dataModel ]);

    const provinceOptions = useMemo(() => {
        const countries = BinsenseStore.dataModel.countries;
        const index = countries.findIndex(c => c.abbreviation === props.country.value);
        return index > -1 ? countries[index].regions.map(prov => ({
            id: prov.id.toString(),
            title: prov.name,
            value: prov.abbreviation,
        })) : [];
    }, [ BinsenseStore.dataModel, props.country.value ]);

    const updateCountry = (country: string) => {
        if (props.province.setValue && props.country.setValue) {
            props.province.setValue('');
            props.country.setValue(country);
        }
    };

    return (
        <TitlePaper
            title={Constants.ACCOUNT_INFORMATION}
        >
            <Grid
                container
                spacing={2}
            >
                <GridTextFieldInput
                    id="edit_customer_account_first_name_input"
                    label={Constants.FIRST_NAME}
                    field={props.firstName}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 100,
                        },
                    }}
                />
                <GridTextFieldInput
                    id="edit_customer_account_last_name_input"
                    label={Constants.LAST_NAME}
                    field={props.lastName}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 100,
                        },
                    }}
                />
                <GridTextFieldInput
                    id="edit_customer_account_address_input"
                    label={Constants.ADDRESS}
                    field={props.address}
                    fullWidth
                />
                <GridTextFieldInput
                    id="edit_customer_account_postal_code_input"
                    label={Constants.POSTAL_CODE_OR_ZIP_CODE}
                    field={props.postalCode}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 100,
                        },
                    }}
                />
                <GridSelectFieldInput
                    id="edit_customer_account_country_input"
                    label={Constants.COUNTRY}
                    field={{
                        ...props.country,
                        setValue: updateCountry,
                    }}
                    options={countryOptions}
                />
                <GridSelectFieldInput
                    id="edit_customer_account_province_input"
                    label={Constants.PROVINCE_OR_STATE}
                    field={props.province}
                    options={provinceOptions}
                />
                <GridTextFieldInput
                    id="edit_customer_account_city_input"
                    label={Constants.TOWN_OR_CITY}
                    field={props.city}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 100,
                        },
                    }}
                />
                {props.phoneNumber && (
                    <GridTextFieldInput
                        id="edit_customer_account_phone_number_input"
                        label={Constants.MOBILE_NUMBER}
                        field={props.phoneNumber}
                        textFieldProps={{
                            inputProps: {
                                maxLength: 100,
                            },
                        }}
                    />
                )}
                {props.email && (
                    <GridTextFieldInput
                        id="edit_customer_account_email_input"
                        label={Constants.EMAIL}
                        field={props.email}
                        textFieldProps={{
                            inputProps: {
                                maxLength: 100,
                            },
                        }}
                    />
                )}
            </Grid>
            <SubmitButton
                id="save_changes_button"
                text={Constants.SAVE_CHANGES}
                onClick={props.submit}
            />
        </TitlePaper>
    );
};

export default EditCustomerInformationPaper;
