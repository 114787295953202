/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default function isPostalCode(str: string, minLength: number, type: string) {
    const expression = /^\d{5}-\d{4}$|^\d{5}$|^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$|^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/;
    if (str === '' || str.length < minLength || !expression.test(str)) {
        return `Please enter a valid ${ type }`;
    }

    return '';
}
