/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Dispatch, SetStateAction, useMemo, useState} from 'react';
import {Tooltip} from '@mui/material';
import {observer} from 'mobx-react-lite';
import cloneDeep from 'lodash/cloneDeep';
import {
    BuildingData,
    DistancePerPixel,
    GrainBagData,
    GrainBinData,
    GrainPileData,
    MarkerData,
    MarkerTypes,
    StructIdSerials
} from '../../../../types/components/ApiTypes';
import IconMarker from './IconMarker';
import DisplayMarker from './DisplayMarker';
import BinSetupModal from '../../../dealer/createSite/binSetupModal/BinSetupModal';
import PileSetupModal from '../../../dealer/createSite/newSiteWizard/PileSetupModal';
import BagSetupModal from '../../../dealer/createSite/newSiteWizard/BagSetupModal';
import WebStore from '../../../../stores/WebStore';
import DetailedBinTooltip from './tooltips/DetailedBinTooltip';
import BasicMarkerTooltip from './tooltips/BasicMarkerTooltip';
import BinsenseStore from '../../../../stores/BinsenseStore';
import getTestID from '../../../../helper/functions/getTestID';
import getMapTooltipStyling from '../../../../helper/functions/getMapTooltipStyling';
import DetailedAutomationHubTooltip from './tooltips/DetailedAutomationHubTooltip';
import BuildingSetupModal from '../../../dealer/createSite/newSiteWizard/BuildingSetupModal';
import getStructNamesFromMarkers from '../../../../helper/functions/getStructNamesFromMarkers';
import DeleteStructureModal from '../../modal/DeleteStructureModal';
import Constants from '../../../../helper/Constants';

interface Props {
    marker: MarkerData,
    masterSerials: string[],
    automationMasterSerials: string[],
    distancePerPixel?: DistancePerPixel,
    onMarkerEdit?: (newMarker: MarkerData) => void,
    onMarkerDelete?: (id: number) => void,
    index: number,
    siteId?: number,
    disableAutomation: boolean,
    markerData: MarkerData[],
    markerSerials: StructIdSerials[],
    siteSerials?: StructIdSerials[],
    deletedStructureArray?: any[],
    setDeletedStructureArray?: Dispatch<SetStateAction<number[]>>,
}

const TooltipMarker = observer((props: Props) => {
    const [ addBinModalOpen, setAddBinModalOpen ] = useState(false);
    const [ addPileModalOpen, setAddPileModalOpen ] = useState(false);
    const [ addBagModalOpen, setAddBagModalOpen ] = useState(false);
    const [ addBuildingModalOpen, setAddBuildingModalOpen ] = useState(false);
    const [ tooltipOpen, setTooltipOpen ] = useState(false);
    const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);

    const markerStructNames = useMemo(() => getStructNamesFromMarkers(props.markerData), [ props.markerData ]);

    const onEditClicked = () => {
        if (props.marker.display?.type === MarkerTypes.bin) {
            setAddBinModalOpen(true);
        } else if (props.marker.display?.type === MarkerTypes.grainPile) {
            setAddPileModalOpen(true);
        } else if (props.marker.display?.type === MarkerTypes.grainBag) {
            setAddBagModalOpen(true);
        } else if (props.marker.display?.type === MarkerTypes.building) {
            setAddBuildingModalOpen(true);
        }
    };

    const addBinMarker = (binData: GrainBinData) => {
        if (props.onMarkerEdit) {
            const newMarker = cloneDeep(props.marker);

            if (newMarker.tooltip?.name) {
                newMarker.tooltip.name = binData.name;
            }
            if (newMarker.display?.grainBinData) {
                if (newMarker.display.grainBinData) {
                    // To edit and add remotes to the newly created master structure
                    if (newMarker.display.grainBinData.deviceType.toLowerCase() === Constants.MASTER.toLowerCase() &&
                        binData.deviceType.toLowerCase() === Constants.REMOTE.toLowerCase() &&
                        newMarker.display.grainBinData.sn.length > 0 &&
                        newMarker.display.grainBinData.sn[0] === binData.associatedMaster &&
                        binData.sn.length > 0) {
                            binData.sn.forEach(sn => {
                                newMarker.display?.grainBinData?.sn.push(sn);
                            });
                    } else {
                        newMarker.display.grainBinData = binData;
                    }
                } else {
                    newMarker.display.grainBinData = binData;
                }
            }

            props.onMarkerEdit(newMarker);
        }
    };

    const addPileMarker = (pileData: GrainPileData) => {
        if (props.onMarkerEdit) {
            const newMarker = cloneDeep(props.marker);

            if (newMarker.tooltip?.name) {
                newMarker.tooltip.name = pileData.name;
            }
            if (newMarker.display?.grainPileData) {
                newMarker.display.grainPileData = pileData;
            }

            props.onMarkerEdit(newMarker);
        }
    };

    const addBagMarker = (bagData: GrainBagData) => {
        if (props.onMarkerEdit) {
            const newMarker = cloneDeep(props.marker);

            if (newMarker.tooltip?.name) {
                newMarker.tooltip.name = bagData.name;
            }
            if (newMarker.display?.grainBagData) {
                newMarker.display.grainBagData = bagData;
            }

            props.onMarkerEdit(newMarker);
        }
    };

    const addBuildingMarker = (buildingData: BuildingData) => {
        if (props.onMarkerEdit) {
            const newMarker = cloneDeep(props.marker);

            if (newMarker.tooltip?.name) {
                newMarker.tooltip.name = buildingData.name;
            }
            if (newMarker.display?.buildingData) {
                newMarker.display.buildingData = buildingData;
            }

            props.onMarkerEdit(newMarker);
        }
    };

    const deleteStruct = () => {
        if (props.onMarkerDelete) {
            props.onMarkerDelete(props.marker.id);
            if (props.deletedStructureArray && props.setDeletedStructureArray) {
                const array = props.deletedStructureArray;
                array.push(props.marker.id);
                props.setDeletedStructureArray(array);
            }
        }
    };

    const onTooltipOpen = () => {
        if (!BinsenseStore.playThroughMap) {
            setTooltipOpen(true);
        }
    };

    const onTooltipClose = () => {
        if (!BinsenseStore.playThroughMap) {
            setTooltipOpen(false);
        }
    };

    const tooltipStyle = useMemo(() => {
        if ((props.marker.tooltip?.displayData || props.marker.tooltip?.automationData) &&
            !props.marker.tooltip?.editable) {
            return getMapTooltipStyling();
        }
        return {};
    }, [ props.marker.tooltip, WebStore.lightMode ]);

    const isTooltipOpen = useMemo(() => (BinsenseStore.playThroughMap &&
            props.index === BinsenseStore.currentMarkerIndex) ? true : tooltipOpen,
        [ BinsenseStore.playThroughMap, BinsenseStore.currentMarkerIndex, props.index, tooltipOpen ]);

    const tooltipBody = useMemo(() => {
        if (props.marker.tooltip?.editable) {
            return (
                <BasicMarkerTooltip
                    marker={props.marker}
                    onEditClicked={onEditClicked}
                    onDeleteClicked={() => setDeleteModalOpen(true)}
                />
            );
        }
        if (props.marker.tooltip?.automationData) {
            return <DetailedAutomationHubTooltip marker={props.marker}/>;
        }
        if (props.marker.tooltip?.displayData) {
            return <DetailedBinTooltip marker={props.marker}/>;
        }
        return <div/>;
    }, [ props.marker ]);

    if (props.marker.tooltip) {
        return (
            <div>
                <Tooltip
                    {...getTestID(`map_${ props.marker.tooltip.name }`)}
                    title={tooltipBody}
                    componentsProps={{
                        tooltip: {
                            style: tooltipStyle,
                        },
                    }}
                    open={isTooltipOpen}
                    onOpen={onTooltipOpen}
                    onClose={onTooltipClose}
                >
                    <div>
                        {props.marker.icon && (
                            <IconMarker
                                marker={props.marker}
                            />
                        )}
                        {props.marker.display && (
                            <DisplayMarker
                                marker={props.marker}
                                distancePerPixel={props.distancePerPixel}
                            />
                        )}
                    </div>
                </Tooltip>
                {(addBinModalOpen && props.marker.display?.grainBinData) && (
                    <BinSetupModal
                        open
                        close={() => setAddBinModalOpen(false)}
                        onSubmit={addBinMarker}
                        grainBinData={props.marker.display.grainBinData}
                        masterSerials={props.masterSerials}
                        automationMasterSerials={props.automationMasterSerials}
                        structureId={props.marker.id}
                        disableAutomation={props.disableAutomation}
                        markerStructNames={markerStructNames}
                        markerSerials={props.markerSerials}
                        siteSerials={props.siteSerials}
                    />
                )}
                {(addPileModalOpen && props.marker.display?.grainPileData) && (
                    <PileSetupModal
                        open
                        close={() => setAddPileModalOpen(false)}
                        onSubmit={addPileMarker}
                        grainPileData={props.marker.display.grainPileData}
                        masterSerials={props.masterSerials}
                        automationMasterSerials={props.automationMasterSerials}
                        structureId={props.marker.id}
                        disableAutomation={props.disableAutomation}
                        markerStructNames={markerStructNames}
                        markerSerials={props.markerSerials}
                        siteSerials={props.siteSerials}
                    />
                )}
                {(addBagModalOpen && props.marker.display?.grainBagData) && (
                    <BagSetupModal
                        open
                        close={() => setAddBagModalOpen(false)}
                        onSubmit={addBagMarker}
                        grainBagData={props.marker.display.grainBagData}
                        masterSerials={props.masterSerials}
                        automationMasterSerials={props.automationMasterSerials}
                        structureId={props.marker.id}
                        disableAutomation={props.disableAutomation}
                        markerStructNames={markerStructNames}
                        markerSerials={props.markerSerials}
                        siteSerials={props.siteSerials}
                    />
                )}
                {(addBuildingModalOpen && props.marker.display?.buildingData) && (
                    <BuildingSetupModal
                        open
                        close={() => setAddBuildingModalOpen(false)}
                        onSubmit={addBuildingMarker}
                        buildingData={props.marker.display.buildingData}
                        masterSerials={props.masterSerials}
                        automationMasterSerials={props.automationMasterSerials}
                        structureId={props.marker.id}
                        disableAutomation={props.disableAutomation}
                        markerStructNames={markerStructNames}
                        markerSerials={props.markerSerials}
                        siteSerials={props.siteSerials}
                    />
                )}
                {deleteModalOpen && (
                    <DeleteStructureModal
                        id="delete_structure_confirmation_modal"
                        open
                        close={() => setDeleteModalOpen(false)}
                        submit={deleteStruct}
                        structName={props.marker.tooltip.name}
                    />
                )}
            </div>
        );
    }

    return null;
});

export default TooltipMarker;
