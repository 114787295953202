/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Fragment} from 'react';
import {IconButton, styled, Typography} from '@mui/material';
import {MarkerData} from '../../../../../types/components/ApiTypes';
import {Delete, Edit} from '@mui/icons-material';
import Constants from '../../../../../helper/Constants';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});

interface Props {
    marker: MarkerData,
    onEditClicked?: () => void,
    onDeleteClicked?: () => void,
}

const BasicMarkerTooltip = (props: Props) => {
    if (props.marker.tooltip) {
        return (
            <Fragment>
                <Row>
                    <Typography
                        sx={{color: 'white'}}
                        style={{marginRight: '8px'}}
                    >
                        {props.marker.tooltip.name}
                    </Typography>
                    {/* eslint-disable-next-line */}
                    {(props.marker.tooltip.editable && props.marker.display?.type.toLowerCase() !== Constants.AUTOMATION.toLowerCase()) && (
                        <IconButton onClick={props.onEditClicked}>
                            <Edit sx={{color: 'white'}}/>
                        </IconButton>
                    )}
                    {props.onDeleteClicked && (
                        <IconButton onClick={props.onDeleteClicked}>
                            <Delete sx={{color: 'white'}}/>
                        </IconButton>
                    )}
                </Row>
            </Fragment>
        );
    }
    return null;
};

export default BasicMarkerTooltip;
