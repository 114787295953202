/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import Constants from '../../../../helper/Constants';

interface Props {
    row: any,
}

const ReportsDifferenceTable = observer((props: Props) => {
    const renderGridLabel = (id: string, label: string) => (
        <DisplayGridLabel
            id={`${ props.row.id }_${ id }`}
            label={label}
            xs={12.0 / 7.0}
        />
    );

    return (
        <DisplayGridContent
            topMargin='0px'
        >
            {renderGridLabel('row', props.row.level)}
            {props.row.startTemp == null ? renderGridLabel('start_temp', Constants.NA)
                : renderGridLabel('start_temp', props.row.startTemp)}
            {props.row.endTemp == null ? renderGridLabel('end_temp', Constants.NA)
                : renderGridLabel('end_temp', props.row.endTemp)}
            {props.row.tempChange  == null ? renderGridLabel('temp_change', Constants.NA)
                : renderGridLabel('temp_change', props.row.tempChange)}
            {props.row.startMoisture == null
                ? renderGridLabel('start_moisture', Constants.NA)
                : renderGridLabel('start_moisture', props.row.startMoisture)}
            {props.row.endMoisture == null
                ? renderGridLabel('end_moisture', Constants.NA)
                : renderGridLabel('end_moisture', props.row.endMoisture)}
            {props.row.moistureChange == null
                ? renderGridLabel('moist_change', Constants.NA)
                : renderGridLabel('moist_change', props.row.moistureChange)}
        </DisplayGridContent>
    );
});

export default ReportsDifferenceTable;
