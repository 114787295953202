/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {useEffect, useMemo, useState} from 'react';
import {styled} from '@mui/material';
import useWindowSize from '../../helper/hooks/useWindowSize';
import WebStore from '../../stores/WebStore';
import {observer} from 'mobx-react-lite';

const Root = styled('div')({
    flex: 1,
    display: 'flex',
    minWidth: '800px',
});

const ScrollArea = styled('div')({
    width: '100%',
    overflow: 'auto',
});

const Content = styled('div')({
    padding: '16px',
});

interface Props {
    children: object,
}

const ContentPanel = observer((props: Props) => {
    const windowSize = useWindowSize();

    const [ reset, setReset ] = useState(false);

    useEffect(() => {
        setReset(true);
        setTimeout(() => setReset(false), 0);
    }, [ WebStore.currentPage ]);

    const maxHeight = useMemo(() => (windowSize[1] - 108.5),
        [ windowSize ]);

    return (
        <Root>
            {!reset ? (
                <ScrollArea style={{height: `${ maxHeight }px`}}>
                    <Content>
                        {props.children}
                    </Content>
                </ScrollArea>
            ) : (
                <div style={{width: '100%'}}>
                    <Content>
                        {props.children}
                    </Content>
                </div>
            )}
        </Root>
    );
});

export default ContentPanel;
