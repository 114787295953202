/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {RemoteUnit} from '../../types/components/ApiTypes';
import Constants from '../Constants';

const getAssociatedMaster = (type: string, units: RemoteUnit[]) => {
        if (type === Constants.LIVE.toLowerCase() && units.length > 0) {
                return units[0].masterSerial ? units[0].masterSerial : '';
        }
        return '';
};

export default getAssociatedMaster;
