import callAPI from '../../callAPI';
import LoginStore from '../../../stores/LoginStore';

const getLoginConfig = (enqueueSnackbar: any) => {
    callAPI('config',
        (response) => {
            LoginStore.setLoginConfig(response);
        },
        enqueueSnackbar
    );
};

export default getLoginConfig;