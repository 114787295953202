/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import Constants from '../../../../../helper/Constants';
import {useMemo, useState} from 'react';
import TitlePaper from '../../../../common/TitlePaper';
import GridTextFieldInput from '../../../../common/input/GridTextFieldInput';
import GridSelectFieldInput from '../../../../common/input/GridSelectFieldInput';
import {Grid} from '@mui/material';
import BinsenseStore from '../../../../../stores/BinsenseStore';
import {Grain} from '../../../../../types/components/ApiTypes';
import {observer} from 'mobx-react-lite';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import putBin from '../../../../../controllers/apiCalls/put/putBin';
import {useSnackbar} from 'notistack';
import ConfirmationModal from '../../../../common/modal/ConfirmationModal';
import getSelectedSiteIndex from '../../../../../helper/functions/getSelectedSiteIndex';
import WebStore from '../../../../../stores/WebStore';

interface Props {
    bin: any,
}

const DeviceBinDetailsPaper = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ name, setName ] = useState(props.bin.name ? props.bin.name : '');
    const [ nameError, setNameError ] = useState('');
    const [ productType, setProductType ] = useState(props.bin.settings.productId
        ? props.bin.settings.productId.toString() : '');
    const [ productTypeError, setProductTypeError ] = useState('');
    const [ changeGrainWarningOpen, setChangeGrainWarningOpen ] = useState(false);

    // move [Empty] from the last item to the first item of the product option list
    let productList = useMemo(() => BinsenseStore.dataModel.grains, [ BinsenseStore.dataModel.grains ]);
    productList = [ productList[productList.length - 1], ...productList.slice(0, -1) ];

    const productOptions = useMemo(() => productList.map((grain: Grain) => ({
        title: grain.name,
        value: grain.id.toString(),
        id: `${ grain.id }_option`,
    })), [ productList ]);

    const structNames = useMemo(() => {
        const names = WebStore.selectedView === Constants.CUSTOMER
            ? BinsenseStore.userData.sites[getSelectedSiteIndex()].structNames
            : BinsenseStore.siteStructures.structNames;
        return names ? names : [];
    }, [ WebStore.selectedSiteId, BinsenseStore.userData, BinsenseStore.siteStructures ]);

    const nameOnPageLoad = props.bin.name !== undefined ? props.bin.name : '';

    const setBinErrors = () => {
        if (name.trim() === '') {
            setNameError('Please enter a name for this bin.');
            return false;
        }
        if ((nameOnPageLoad === '' || nameOnPageLoad.trim().toLowerCase() !== name.trim().toLowerCase()) &&
            structNames && structNames.some(item => item.trim().toLowerCase() === name.trim().toLowerCase())) {
            setNameError('This name already exists');
            return false;
        }
        return true;
    };

    const submit = () => {
        const body = {
            name,
            settings: {
                productId: Number(productType),
                lowTemperatureThreshold: props.bin.settings?.lowTemperatureThreshold,
                setFirstStepErrors: {setBinErrors},

            },
        };
        if (setBinErrors()) {
        putBin(enqueueSnackbar, props.bin.id, body);
        }
    };

    const onSaveClick = () => {
        if (props.bin.settings.productId && Number(productType) !== props.bin.settings.productId &&
            props.bin.automationSettings != null && props.bin.automationSettings.running) {
            setChangeGrainWarningOpen(true);
        }
        else {
            if (name.trim() === '') {
                setNameError('Please enter a valid name');
            }
            else {
                setNameError('');
                submit();
            }
        }
    };

    return (
        <div style={{ width: '100%' }} >
            <TitlePaper
                title="Bin Details"
            >
                <Grid
                    container
                    spacing={2}
                >
                    <GridTextFieldInput
                        id="edit_bin_name_input"
                        label={Constants.NAME}
                        field={{
                            value: name,
                            setValue: setName,
                            error: nameError,
                            setError: setNameError,
                        }}
                        fullWidth
                    />
                    <GridSelectFieldInput
                        id="edit_commodity_input"
                        label={Constants.COMMODITY}
                        field={{
                            value: productType,
                            setValue: setProductType,
                            error: productTypeError,
                            setError: setProductTypeError,
                        }}
                        options={productOptions}
                        fullWidth
                    />
                </Grid>
                <SubmitButton
                    id="bin_details_save_changes_button"
                    text={Constants.SAVE_CHANGES}
                    onClick={onSaveClick}
                />
                {changeGrainWarningOpen && (
                    <ConfirmationModal
                        id='change_grain_type_confirmation'
                        open
                        close={() => setChangeGrainWarningOpen(false)}
                        submit={submit}
                        title='Change Grain Type Warning'
                        message="Changing your grain type will adjust your automation settings. Please make sure your
                        automation settings are still applicable."
                    />
                )}
            </TitlePaper>
        </div>
    );
});

export default DeviceBinDetailsPaper;
