/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper, Skeleton, styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {Bin, Building, GrainBag} from '../../../../types/components/ApiTypes';
import StorageGridItemHeader from './StorageGridItemHeader';
import Constants from '../../../../helper/Constants';
import ToggleButtonInput from '../../../common/input/ToggleButtonInput';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import DeviceBinViewBody from '../viewDevicePage/display/DeviceBinViewBody';
import WebStore from '../../../../stores/WebStore';

interface Props {
    bin: Bin | GrainBag | Building,
    binView?: {
        value: string,
        setValue: Dispatch<SetStateAction<string>>,
    }
    includeReset?: boolean,
    maxWidth?: boolean,
    maxHeight?: boolean,
    children: object | object [],
    showSensorButtons?: boolean,
    displayAmbientTemp?: boolean,
}

const ToggleDiv = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '8px',
});

const BinImagePanel = observer((props: Props) => {
    const [ binView, setBinView ] = useState(Constants.SIDE_VIEW);
    const [ reset, setReset ] = useState(false);

    useEffect(() => {
        if (props.includeReset) {
            setReset(true);
            setTimeout(() => setReset(false), 50);
        }
    }, [ WebStore.viewDeviceId, WebStore.selectedSiteId ]);

    return (
        <Paper
            style={{
                width: props.maxWidth ? '100%' : undefined,
                height: props.maxHeight ? '100%' : undefined,
            }}
        >
            <StorageGridItemHeader
                bin={props.bin}
            />
            <ToggleDiv>
                <ToggleButtonInput
                    value={props.binView ? props.binView.value : binView}
                    setValue={props.binView ? props.binView.setValue : setBinView}
                    options={[
                        {
                            id: 'side_view_option',
                            title: Constants.SIDE_VIEW,
                        },
                        // {
                        //     id: 'top_view_option',
                        //     title: Constants.TOP_VIEW,
                        // },
                        {
                            id: 'table_view_option',
                            title: Constants.TABLE_VIEW,
                        },
                    ]}
                    size="small"
                />
            </ToggleDiv>
            {!reset ? (
                <DeviceBinViewBody
                    view={props.binView ? props.binView.value : binView}
                    bin={props.bin}
                    showSensorButtons={props.showSensorButtons}
                    displayAmbientTemp={props.displayAmbientTemp}
                />
            ) : (
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    sx={{
                        width: '100%',
                        height: '600px',
                    }}
                />
            )}

            {props.children}
        </Paper>
    );
});

export default BinImagePanel;
