/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import BinsenseStore from '../../stores/BinsenseStore';
import CountryData from '../temp/CountryData';

const getCountryList = () => {
    const countries = BinsenseStore.dataModel.countries;
    if (countries.length > 0) {
        return countries.map(country => ({
            title: country.name,
            id: `${ country.id }_option`,
            value: country.id.toString(),
        }));
    }
    return CountryData.countries;
};

export default getCountryList;
