/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

// @ts-ignore
import uuid from 'react-uuid';
import MapboxMap, {GeolocateControl, MapRef, Marker, NavigationControl, ScaleControl} from 'react-map-gl';
import {Dispatch, SetStateAction, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import WebStore from '../../../stores/WebStore';
import Constants from '../../../helper/Constants';
import {DistancePerPixel, MarkerData, MarkerTypes, StructIdSerials} from '../../../types/components/ApiTypes';
import MapViewToggle from './MapViewToggle';
import CustomMarker from './markers/CustomMarker';
import DrawControl from './drawPolygons/DrawControl';
import mapboxgl, { LngLatBounds } from 'mapbox-gl';
// import Colors from '../../../helper/Colors';
import getTestID from '../../../helper/functions/getTestID';
import getMasterSerialsFromMarkers from '../../../helper/functions/getMasterSerialsFromMarkers';
import disableAutomationOption from '../../../helper/functions/disableAutomationOption';
import getAutomationMasterSerialsFromMarkers from '../../../helper/functions/getAutomationMasterSerialsFromMarkers';
import getSerialsFromMarkers from '../../../helper/functions/getSerialsFromMarkers';
import getSelectedSiteIndex from '../../../helper/functions/getSelectedSiteIndex';
import getSite from '../../../helper/functions/getSite';
import getAllSerialsFromSite from '../../../helper/functions/getAllSerialsFromSite';
import GeocoderControl from './geocoder/GeocoderControl';
import UIStateStore from '../../../stores/UIStateStore';
import React from 'react';
import SatelliteViewPopup from './SatelliteViewPopup';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

interface Props {
    height: number,
    initialView: {
        longitude: number,
        latitude: number,
        zoom: number,
    },
    markers: MarkerData[],
    onMarkerDragEnd?: (lngLat: { lng: number, lat: number }, index: number) => void,
    onMarkerEdit?: (newMarker: MarkerData, index: number) => void,
    onMarkerDelete?: (id: number) => void,
    onMove: (e: any) => void,
    showSearch?: boolean,
    toggleOnBottom?: boolean,
    distancePerPixel?: DistancePerPixel,
    features?: any,
    onFeaturesDelete?: any,
    onFeaturesUpdate?: any,
    onMarkerClick?: (id: number) => void,
    siteId?: number,
    onSetBounds?: (bounds: LngLatBounds) => void
    isMyCustomersMap? : boolean,
    deletedStructureArray?: any[],
    setDeletedStructureArray?: Dispatch<SetStateAction<number[]>>,
}

const DisplayMap = observer((props: Props) => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updateBounds = props.onSetBounds ? props.onSetBounds : ((bounds: LngLatBounds) => {});
    const masterSerials = useMemo(() => getMasterSerialsFromMarkers(props.markers), [ props.markers ]);
    const automationMasterSerials =
        useMemo(() => getAutomationMasterSerialsFromMarkers(props.markers), [ props.markers ]);

    const disableAutomation = useMemo(() => disableAutomationOption(props.markers), [ props.markers ]);
    const markerSerials = useMemo(() => getSerialsFromMarkers(props.markers), [ props.markers ]);
    const siteSerials = useMemo(() => {
        let allSerials: StructIdSerials[] = [];
        const index = getSelectedSiteIndex();
        if (index >= 0 ) {
            allSerials = getAllSerialsFromSite(getSite(index));
        }
        return allSerials;
    }, [ props.siteId ]);

    const markers = useMemo(() => props.markers.map(((marker, index) => {
        if (((marker.minZoom !== undefined && marker.maxZoom !== undefined) &&
                (props.initialView.zoom >= marker.minZoom && props.initialView.zoom <= marker.maxZoom)) ||
            ((marker.minZoom !== undefined && marker.maxZoom === undefined) &&
                (props.initialView.zoom >= marker.minZoom)) ||
            ((marker.minZoom === undefined && marker.maxZoom !== undefined) &&
                (props.initialView.zoom <= marker.maxZoom)) ||
            ((marker.minZoom === undefined && marker.maxZoom === undefined))
        ) {
            return (
                <Marker
                    key={uuid()}
                    longitude={marker.longitude}
                    latitude={marker.latitude}
                    draggable={marker.draggable}
                    onDragEnd={(e) => props.onMarkerDragEnd && props.onMarkerDragEnd(e.lngLat, index)}
                    // @ts-ignore
                    // eslint-disable-next-line
                    onClick= {props.onMarkerClick ? marker.display?.type === MarkerTypes.automation ? undefined : () => props.onMarkerClick(marker.id) : undefined}
                >
                    {(marker.icon || marker.display || marker.siteTooltip || marker.tooltip) && (
                        <CustomMarker
                            onMarkerDelete={props.onMarkerDelete}
                            onMarkerEdit={newMarker => props.onMarkerEdit ? props.onMarkerEdit(newMarker, index) : null}
                            marker={marker}
                            distancePerPixel={props.distancePerPixel}
                            masterSerials={masterSerials}
                            automationMasterSerials={automationMasterSerials}
                            index={index}
                            siteId={props.siteId}
                            disableAutomation={disableAutomation}
                            markerData={props.markers}
                            markerSerials={markerSerials}
                            siteSerials={siteSerials}
                            deletedStructureArray={props.deletedStructureArray}
                            setDeletedStructureArray={props.setDeletedStructureArray}
                        />
                    )}
                </Marker>
            );
        }
        return null;
    })), [ props.markers, props.initialView.zoom ]);

    const mapStyle = useMemo(() => {
        if (UIStateStore.mapView === Constants.SATELLITE) {
            return 'mapbox://styles/mapbox/satellite-streets-v11';
        }
        return WebStore.lightMode ? 'mapbox://styles/mapbox/light-v10' : 'mapbox://styles/mapbox/dark-v10';
    }, [ WebStore.lightMode, UIStateStore.mapView ]);

    const mapRef = React.useRef() as React.MutableRefObject<MapRef>;
    const onMapLoad = React.useCallback(() => {
        try {
        updateBounds(mapRef.current.getBounds());
        mapRef.current.on('move', () => {
            try {
          updateBounds(mapRef.current.getBounds());
            } catch (e) {}
        });
        } catch (e) {}
      }, []);

    return (
        <div>
            {props.toggleOnBottom === undefined && (
                <MapViewToggle
                    view={UIStateStore.mapView}
                    setView={UIStateStore.setMapView}
                />
            )}
            <MapboxMap
                ref={mapRef}
                {...getTestID('map_canvas')}
                {...props.initialView}
                //reuseMaps
                style={{
                    width: '100%',
                    height: props.height,
                }}
                mapStyle={mapStyle}
                mapboxAccessToken={Constants.MAPBOX_ACCESS_TOKEN}
                onMove={props.onMove}
                onLoad={onMapLoad}
            >
                {markers}
                <GeolocateControl/>
                <NavigationControl/>
                <ScaleControl/>
                {props.showSearch && (
                    <GeocoderControl
                        mapboxAccessToken={Constants.MAPBOX_ACCESS_TOKEN}
                        position="top-left"
                    />
                )}
                {(props.features !== undefined && props.onFeaturesUpdate && props.onFeaturesDelete) && (
                    <DrawControl
                        position="top-left"
                        // @ts-ignore
                        displayControlsDefault={false}
                        controls={{
                            polygon: true,
                            trash: true,
                        }}
                        onCreate={props.onFeaturesUpdate}
                        onUpdate={props.onFeaturesUpdate}
                        onDelete={props.onFeaturesDelete}
                    />
                )}
                {/*<Source*/}
                {/*    id='polygonjson'*/}
                {/*    type='geojson'*/}
                {/*    //@ts-ignore*/}
                {/*    data={Data.polygonData}*/}
                {/*/>*/}
                {/*<Layer*/}
                {/*    id='anything'*/}
                {/*    type="fill"*/}
                {/*    source='polygonjson'*/}
                {/*    paint={{*/}
                {/*        'fill-color': Colors.primaryYellow,*/}
                {/*        'fill-opacity': 0.4,*/}
                {/*    }}*/}
                {/*/>*/}
            </MapboxMap>
            {props.toggleOnBottom && (
                <MapViewToggle
                    view={UIStateStore.mapView}
                    setView={UIStateStore.setMapView}
                />
            )}

            {UIStateStore.mapView === Constants.SATELLITE &&
                UIStateStore.showSatelliteViewMessage && WebStore.selectedView === Constants.CUSTOMER &&
                <SatelliteViewPopup />}
        </div>
    );
});

export default DisplayMap;
