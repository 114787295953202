/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {useEffect, useState} from 'react';
import Constants from '../../../../helper/Constants';
import BaseModal from '../../../common/modal/BaseModal';
import BaseWizard from '../../../common/wizard/BaseWizard';
import {styled} from '@mui/material';
import SelectDeviceStep from './SelectDeviceStep';
import EnterSerialStep from './EnterSerialStep';
import {
    GrainBagData,
    GrainBinData,
    GrainPileData,
    BuildingData,
    StructIdSerials
} from '../../../../types/components/ApiTypes';
import SelectDeviceTypeStep from './SelectDeviceTypeStep';
import getSerialValidation from '../../../../helper/functions/getSerialValidation';
import {useSnackbar} from 'notistack';

const Container = styled('div')({
    width: '750px',
    padding: '32px',
});

interface Props {
    id: string,
    modalTitle: string,
    firstStepTitle: string,
    isFirstStepValid: () => boolean,
    setFirstStepErrors: () => void,
    resetFirstStepErrors: () => void,
    onSubmit: (
        device: string,
        deviceType: string,
        sn: string[],
        associatedMaster: string,
    ) => void,
    close: () => void,
    data?: GrainBinData | GrainPileData | GrainBagData | BuildingData,
    masterSerials: string[],
    automationMasterSerials: string[],
    structureId?: number,
    disableAutomation: boolean,
    children: object,
    markerSerials?: StructIdSerials[],
    siteSerials?: StructIdSerials[],
}

const StructureSetupModal = (props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const steps = [
        props.firstStepTitle,
        Constants.SELECT_DEVICE,
        Constants.SELECT_DEVICE_TYPE,
        Constants.ENTER_SERIAL,
    ];

    const [ activeStep, setActiveStep ] = useState(0);

    // step 2 state
    const originalDevice = props.data?.device ? props.data.device : '';
    const [ selectedDevice, setSelectedDevice ] = useState(props.data?.device ? props.data.device : '');
    const [ selectedDeviceError, setSelectedDeviceError ] = useState('');

    // step 3 state
    const [ deviceType, setDeviceType ] = useState(props.data?.deviceType ? props.data.deviceType : '');
    const [ deviceTypeError, setDeviceTypeError ] = useState('');

    // step 4 state
    const [ serial, setSerial ] = useState(props.data?.sn ? props.data.sn : [ '' ]);
    const [ serialError, setSerialError ] = useState(false);
    const [ associatedMaster, setAssociatedMaster ] = useState(props.data?.associatedMaster
        ? props.data.associatedMaster : '');
    const [ associatedMasterError, setAssociatedMasterError ] = useState('');

    let submitting = false;

    const submit = () => {
        if (!submitting) {
            submitting = true;
            props.onSubmit(
                selectedDevice,
                selectedDevice.toLowerCase() === Constants.LIVE.toLowerCase() ||
                    selectedDevice.toLowerCase() === Constants.SOLO.toLowerCase()
                    ? deviceType.toLowerCase() : '',
                serial,
                (selectedDevice.toLowerCase() === Constants.LIVE.toLowerCase() &&
                deviceType.toLowerCase() === Constants.MASTER.toLowerCase()) ? serial[0] : associatedMaster
            );
            props.close();
        }
    };

    const isSelectedDeviceValid = () => activeStep !== 1 || selectedDevice !== '';

    const isDeviceTypeValid = () => activeStep !== 2 || deviceType !== '';

    const isValid = () => props.isFirstStepValid() && isSelectedDeviceValid() && isDeviceTypeValid();

    const isSerialValid = (s: any) => {
        let retValue = false;
        if (s !== undefined) {
            retValue = !props.structureId ? s.isAvailable : props.structureId === s.structId;
            if (s.isAvailable && !retValue) {
                if (serial.includes(s.serial)) {
                    retValue = true;
                }
            }
        }
        return retValue;
    };

    useEffect(() => {
        if (serial && serial.length > 0) {
            const newSerials = serial.filter(s => s !== '');
            const unique = Array.from(new Set(newSerials));
            let isMappedInMarker = false;
            props.markerSerials?.forEach( markerSerial => {
                if (!isMappedInMarker && markerSerial.id !== props.structureId) {
                    markerSerial.sn.forEach( sn => {
                        isMappedInMarker = unique.includes(sn);
                    });
                }
            });
            let isMappedInSite = false;
            unique.forEach(sn => {
                if (!isMappedInSite && props.siteSerials) {
                    props.siteSerials.forEach(siteSerial => {
                        if (!isMappedInSite && siteSerial.id !== props.structureId) {
                            isMappedInSite = siteSerial.sn.includes(sn);
                        }
                    });
                }
            });
            setSerialError(unique.length !== newSerials.length || isMappedInMarker || isMappedInSite);
        }
    }, [ serial ]);

    const handleNext = () => {
        if (isValid()) {
            if (activeStep === 0) {
                props.resetFirstStepErrors();
            }
            if (activeStep !== steps.length - 1 &&
                (activeStep !== 1 || selectedDevice.toLowerCase() === Constants.LIVE.toLowerCase())) {
                setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
            } else if (activeStep === 1 && selectedDevice.toLowerCase() !== Constants.DIRECT.toLowerCase() &&
                selectedDevice.toLowerCase() !== Constants.NOT_MONITORED.toLowerCase()) {
                setDeviceType(selectedDevice);
                setActiveStep((prevActiveStep: number) => prevActiveStep + 2);
            } else if (activeStep === 1 &&
                    (selectedDevice.toLowerCase() === Constants.NOT_MONITORED.toLowerCase() ||
                        selectedDevice.toLowerCase() === Constants.DIRECT.toLowerCase())) {
                submit();
            } else {
                if (!serialError) {
                    getSerialValidation(enqueueSnackbar, serial, selectedDevice, deviceType).then(r => {
                        const valid = !r.map(s => isSerialValid(s)).includes(false);
                        if (valid) {
                            submit();
                        } else {
                            setSerialError(true);
                        }
                    });
                }
            }
        }
        else if (!props.isFirstStepValid()) {
            props.setFirstStepErrors();
        }
        else if (!isSelectedDeviceValid()) {
            setSelectedDeviceError('Please select a device');
        }
        else if (!isDeviceTypeValid()) {
            setDeviceTypeError('Please select a device type');
        }
    };

    useEffect(() => {
        if (selectedDevice.toLowerCase() !== Constants.LIVE.toLowerCase() &&
            selectedDevice.toLowerCase() !== Constants.SOLO.toLowerCase()) {
            setDeviceType('');
        }
        if (selectedDevice === Constants.NOT_MONITORED) {
            handleNext();
        }
    }, [ selectedDevice ]);

    const handleBack = () => {
        if (activeStep === steps.length - 1 && selectedDevice !== Constants.LIVE.toLowerCase()) {
            setActiveStep((prevActiveStep: number) => prevActiveStep - 2);
        }
        else {
            setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
        }
    };

    const renderPaper = () => {
        switch (steps[activeStep]) {
            case props.firstStepTitle:
                return (
                    props.children
                );
            case Constants.SELECT_DEVICE:
                return (
                    <SelectDeviceStep
                        selectedDevice={{
                            value: selectedDevice,
                            setValue: setSelectedDevice,
                            error: selectedDeviceError,
                            setError: setSelectedDeviceError,
                        }}
                        disableAutomation={props.disableAutomation}
                        originalDevice={originalDevice}
                        structureId={props.structureId}
                    />
                );
            case Constants.SELECT_DEVICE_TYPE:
                return (
                    <SelectDeviceTypeStep
                        deviceType={{
                            value: deviceType,
                            setValue: setDeviceType,
                            error: deviceTypeError,
                            setError: setDeviceTypeError,
                        }}
                        currentDeviceType={props.data?.deviceType ? props.data.deviceType : ''}
                        disableRemote={props.masterSerials.length === 0}
                    />
                );
            case Constants.ENTER_SERIAL:
                return (
                    <EnterSerialStep
                        deviceType={deviceType === '' ? undefined : deviceType}
                        serial={{
                            value: serial,
                            setValue: setSerial,
                        }}
                        serialError={serialError}
                        associatedMaster={{
                            value: associatedMaster,
                            setValue: setAssociatedMaster,
                            error: associatedMasterError,
                            setError: setAssociatedMasterError,
                        }}
                        automationRemote={selectedDevice.toLowerCase() === Constants.PLUS.toLowerCase()}
                        masterSerials=
                            {selectedDevice.toLowerCase() === Constants.PLUS.toLowerCase()
                                ? props.automationMasterSerials : props.masterSerials}
                    />
                );
            default:
                return (
                    <div/>
                );
        }
    };

    return (
        <BaseModal
            id={`${ props.id }_modal`}
            open
            close={props.close}
            title={props.modalTitle}
            hideButtons
        >
            <Container>
                <BaseWizard
                    id={`${ props.id }_wizard`}
                    close={props.close}
                    submit={submit}
                    steps={steps}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    handleNext={handleNext}
                    handleBack={handleBack}
                >
                    {renderPaper()}
                </BaseWizard>
            </Container>
        </BaseModal>
    );
};

export default StructureSetupModal;
