/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import BinsenseStore from '../../stores/BinsenseStore';
import WebStore from '../../stores/WebStore';
import Constants from '../Constants';

const getTempUnit = () => {
    if (BinsenseStore.awaitingUserData) {
        return 'C';
    }
    if (WebStore.selectedView === Constants.DEALER) {
        return BinsenseStore.dealerUserData.settings.preferences.temperatureUnits === Constants.FAHRENHEIT ? 'F' : 'C';
    }
    return BinsenseStore.userData.settings.preferences.temperatureUnits === Constants.FAHRENHEIT ? 'F' : 'C';
};

export default getTempUnit;
