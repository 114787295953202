/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {MasterUnit, Site} from '../../types/components/ApiTypes';
import WebStore from '../../stores/WebStore';
import Constants from '../Constants';
import BinsenseStore from '../../stores/BinsenseStore';

const addMastersToList = (masters: MasterUnit[], masterList: string[]) => {
    masters.forEach(master => {
        if (!masterList.includes(master.serial)) {
            masterList.push(master.serial);
        }
    });
};

const getMasterSerialsFromSite = (site: Site) => {
    if (WebStore.selectedView === Constants.DEALER) {
        const masters: string[] = [];
        site.bins.forEach(bin => {
            if (bin.hardware?.masterUnits) {
                addMastersToList(bin.hardware.masterUnits, masters);
            }
        });
        site.grainBags.forEach(bag => {
            if (bag.hardware?.masterUnits) {
                addMastersToList(bag.hardware.masterUnits, masters);
            }
        });
        site.buildings.forEach(building => {
            if (building.hardware?.masterUnits) {
                addMastersToList(building.hardware.masterUnits, masters);
            }
        });
        // site.piles.forEach(pile => {
        //     if (pile.hardware?.masterUnits) {
        //         addMastersToList(pile.hardware.masterUnits, masters);
        //     }
        // });
        return masters;
    }
    return BinsenseStore.userData.masterUnits.filter(unit => unit.siteId === site.id && !unit.automation)
        .map(unit => unit.serial);
};

export default getMasterSerialsFromSite;
