/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Button, styled} from '@mui/material';
import Constants from '../../helper/Constants';
import {Dispatch, SetStateAction, useState} from 'react';
import {InputFieldProps, MarkerData} from '../../types/components/ApiTypes';
import getTestID from '../../helper/functions/getTestID';
import TextFieldInput from './input/TextFieldInput';
import LocationMarker from '../dealer/createSite/newSiteWizard/LocationMarker';
import {observer} from 'mobx-react-lite';
import getNewId from '../../helper/functions/getNewId';
import BinsenseStore from '../../stores/BinsenseStore';
import WebStore from '../../stores/WebStore';
import ConfirmationModal from './modal/ConfirmationModal';
import getIndex from '../../helper/functions/getIndex';
import deleteSite from '../../controllers/apiCalls/delete/deleteSite';
import {useSnackbar} from 'notistack';
import InfoTooltip from './InfoTooltip';
import TooltipStore from '../../stores/TooltipStore';
import TooltipMessages from '../../helper/TooltipMessages';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
});

const InputArea = styled('div')({
    width: '25%',
    marginRight: '8px',
});

interface Props {
    name: InputFieldProps,
    markerData: MarkerData[],
    setMarkerData: Dispatch<SetStateAction<MarkerData[]>>,
    viewState: any,
    setViewState: Dispatch<SetStateAction<any>>,
    id?: number,
    noLocationDetected?: boolean,
    showDelete?: boolean,
    close?: () => void,
}

const SetSiteLocationForm = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ deleteOpen, setDeleteOpen ] = useState(false);

    const getId = () => {
        if (props.id) {
            return props.id;
        }
        return props.noLocationDetected ? WebStore.selectedSiteId : getNewId(BinsenseStore.userData.sites);
    };

    const onAddSite = () => {
        props.setMarkerData([ {
            id: getId(),
            longitude: props.viewState.longitude,
            latitude: props.viewState.latitude,
            draggable: true,
            icon: (<LocationMarker/>),
        } ]);
    };

    const onDelete = () => {
        if (props.id) {
            const clone = JSON.parse(JSON.stringify(BinsenseStore.userData));
            const index = getIndex(props.id, clone.sites);
            clone.sites.splice(index, 1);
            if (props.id === WebStore.selectedSiteId) {
                WebStore.setSelectedSiteId(clone.sites.length > 0 ? clone.sites[0].id : -1, true);
            }
            deleteSite(enqueueSnackbar, props.id);
            if (props.close) {
                props.close();
            }
        }
    };

    return (
        <Root>
            <InputArea>
                <TextFieldInput
                    id="set_site_name_input"
                    label={Constants.SITE_NAME}
                    field={props.name}
                    size='small'
                />
            </InputArea>
            <Button
                {...getTestID('add_site_button')}
                onClick={onAddSite}
                color="primary"
                variant="contained"
                disabled={props.name.value.trim() === ''}
                style={{ marginBottom: '8px' }}
            >
                {props.markerData.length === 0 ? Constants.SET_SITE_LOCATION : Constants.RESET_SITE_LOCATION}
            </Button>
            {props.showDelete && (
                <Button
                    {...getTestID('delete_site_button')}
                    onClick={() => setDeleteOpen(true)}
                    color="primary"
                    variant="contained"
                    style={{
                        marginBottom: '8px',
                        marginLeft: '8px',
                    }}
                >
                    {Constants.DELETE_SITE}
                </Button>
            )}
            <div style={{ marginBottom: '8px' }}>
                <InfoTooltip
                    id="site_location_tooltip_button"
                    open={TooltipStore.beginTooltips && TooltipStore.showSiteLocationMessages}
                    message={TooltipMessages.SITE_LOCATION}
                    onClick={() => TooltipStore.setShowSiteLocationMessages(false, true)}
                />
            </div>
            {deleteOpen && (
                <ConfirmationModal
                    id='delete_site_confirmation'
                    open
                    close={() => setDeleteOpen(false)}
                    submit={onDelete}
                    title={Constants.DELETE_SITE}
                    message="Are you sure you want to delete this site?"
                />
            )}
        </Root>
    );
});

export default SetSiteLocationForm;
