/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {StorageListBin} from '../../types/components/ApiTypes';
import filterByName from './filterByName';

const filterEmptyStructures = (bins: StorageListBin[], key: string, includeEmpty: boolean) => {
    if (includeEmpty) {
        return filterByName(bins, key);
    }

    const clone = JSON.parse(JSON.stringify(bins));
    const filteredBins: StorageListBin[] = [];
    clone.forEach((bin: StorageListBin) => {
        if (bin.fillLevel !== undefined && bin.fillLevel > 0) {
            filteredBins.push(bin);
        }
        else if (bin.status != undefined && bin.status.percentFull > 0) {
            filteredBins.push(bin);
        }
    });
    return filterByName(filteredBins, key);
};

export default filterEmptyStructures;
