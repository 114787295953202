/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {styled, Tooltip, Typography} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

interface Props {
    id: string,
    value: string | number,
    icon: object,
    tooltip: string,
    onClick?: () => void,
}

const SystemStatusItem = (props: Props) => (
    <Tooltip
        title={props.tooltip}
        onClick={props.onClick}
        style={{ cursor: props.onClick !== undefined ? 'pointer' : undefined}}
        arrow
    >
        <Root>
            {props.icon}
            <Typography
                {...getTestID(`system_status_${ props.id }`)}
                fontSize={18}
                fontWeight="bold"
            >
                {props.value}
            </Typography>
        </Root>
    </Tooltip>
);

export default SystemStatusItem;
