/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {AddBox, AddLocation, CreditCard, Settings, Tune} from '@mui/icons-material';
import Constants from '../../../helper/Constants';

const rotate = {
    'WebkitTransform': 'rotate(90deg)',
    'MozTransform': 'rotate(90deg)',
    'MsTransform': 'rotate(90deg)',
    'OTransform': 'rotate(90deg)',
    transform: 'rotate(90deg)',
};

const ManagementDrawer = [
    // {
    //     id: 'users',
    //     title: Constants.USERS,
    //     icon: <People/>,
    // },
    {
        id: 'create_site',
        title: Constants.CREATE_SITE,
        icon: <AddLocation/>,
    },
    {
        id: 'add_new',
        title: Constants.ADD_NEW,
        icon: <AddBox/>,
    },
    {
        id: 'device_settings',
        title: Constants.DEVICE_SETTINGS,
        icon: <Settings/>,
    },
    {
        id: 'preferences',
        title: Constants.PREFERENCES,
        icon: <Tune sx={rotate}/>,
    },
    {
        id: 'billing_and_payment',
        title: Constants.BILLING_AND_PAYMENT,
        icon: <CreditCard/>,
    },
];

export default ManagementDrawer;
