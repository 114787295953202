/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Site, SiteLocationData} from '../../types/components/ApiTypes';

const saveSiteLocationDataToSite = (data: Site, siteLocation: SiteLocationData) => {
    data.name = siteLocation.name;
    data.latitude = siteLocation.latitude;
    data.longitude = siteLocation.longitude;
    data.id = siteLocation.id;
};

export default saveSiteLocationDataToSite;
