/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo, useState} from 'react';
import {Grid, styled, Typography} from '@mui/material';
import Constants from '../../../helper/Constants';
import BaseModal from '../../common/modal/BaseModal';
import GridTextFieldInput from '../../common/input/GridTextFieldInput';
import PersonalDetailsForm from '../../common/personalDetails/PersonalDetailsForm';
import BinsenseStore from '../../../stores/BinsenseStore';
import getIndex from '../../../helper/functions/getIndex';
import putDealerUser from '../../../controllers/apiCalls/put/putDealerUser';
import {useSnackbar} from 'notistack';
import postDealerUser from '../../../controllers/apiCalls/post/postDealerUser';
import GridSelectFieldInput from '../../common/input/GridSelectFieldInput';
import isEmailExists from '../../../helper/functions/isEmailExists';
import isPasswordValid from '../../../helper/validator/isPasswordValid';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '800px',
    padding: '16px',
    alignItems: 'center',
});

interface Props {
    open: boolean,
    close: () => void,
    id?: any,
}

const AddUserModal = (props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const user = useMemo(() => {
        if (props.id) {
            const index = getIndex(props.id, BinsenseStore.dealerUserData.dealerUsers);
            return BinsenseStore.dealerUserData.dealerUsers[index];
        }
        return null;
    }, [ BinsenseStore.dealerUserData, props.id ]);

    const dealerOptions = useMemo(() => BinsenseStore.dealerUserData.dealerGroups
    .filter(dealer => dealer.active === true || dealer.id === Constants.CALIAN_DEALER_ID)
    .map(dealer => ({
        id: `${ dealer.id }_option`,
        title: dealer.name,
        value: dealer.id.toString(),
    })), [ BinsenseStore.dealerUserData.dealerGroups ]);

    const [ firstName, setFirstName ] = useState(user ? user.firstName : '');
    const [ firstNameError, setFirstNameError ] = useState('');
    const [ lastName, setLastName ] = useState(user ? user.lastName : '');
    const [ lastNameError, setLastNameError ] = useState('');
    const [ dealer, setDealer ] = useState(user ? user.dealerId.toString() : '');
    const [ dealerError, setDealerError ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');
    const [ passwordConfirmation, setPasswordConfirmation ] = useState('');
    const [ passwordConfirmationError, setPasswordConfirmationError ] = useState('');
    const [ passwordNoMatchError, setPasswordNoMatchError ] = useState('');
    const [ username, setUsername ] = useState(user ? user.username : '');
    const [ usernameError, setUsernameError ] = useState('');
    const usernameOnLoad = user ? user.username : '';

    const isValid = () => firstName.trim() !== '' && lastName.trim() !== '' && username.trim() !== '' && dealer !== '' && password !== '' &&
        password === passwordConfirmation && isPasswordValid(password) === '';

    const setErrors = () => {
        if (firstName.trim() === '') {
            setFirstNameError('Please enter a first name');
        }
        if (lastName.trim() === '') {
            setLastNameError('Please enter a last name');
        }
        if (username.trim() === '') {
            setUsernameError('Please enter a valid username');
        }
        if (dealer === '') {
            setDealerError('Please select a dealer');
        }
        const passwordValidMsg = isPasswordValid(password);
        if (passwordValidMsg !== '') {
            setPasswordError(passwordValidMsg);
        }
        if (password !== passwordConfirmation) {
            setPasswordNoMatchError('Password fields do not match');
        }
    };

    const resetErrors = () => {
        if (firstName.trim() !== '') {
            setFirstNameError('');
        }
        if (lastName.trim() !== '') {
            setLastNameError('');
        }
        if (username.trim() !== '') {
            setUsernameError('');
        }
        if (dealer !== '') {
            setDealerError('');
        }
        if (isPasswordValid(password) === '') {
            setPasswordError('');
        }
        if (password === passwordConfirmation) {
            setPasswordNoMatchError('');
        }
    };

    const validateUsernameAndCreateUser = async (body : any) => {
        if (usernameOnLoad.toLowerCase() !== username.toLowerCase()) {
            await isEmailExists(enqueueSnackbar, username.trim()).then(r => {
                if (r !== '') {
                    setUsernameError(r);
                } else {
                    if (user) {
                        putDealerUser(enqueueSnackbar, user.id, body);
                    } else {
                        postDealerUser(enqueueSnackbar, body);
                    }
                    props.close();
                }
            });
        } else if (user) {
            putDealerUser(enqueueSnackbar, user.id, body);
            props.close();
        }
    };

    const submit = () => {
        setFirstName(firstName.trim());
        setLastName(lastName.trim());
        setUsername(username.trim());
        resetErrors();
        if (isValid()) {
            const body = {
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                username : usernameOnLoad.toLowerCase() !== username.toLowerCase() ? username : undefined,
                dealerId: Number(dealer),
                password,
                passwordVerify: passwordConfirmation,
            };
            validateUsernameAndCreateUser(body);
        }
        else {
            setErrors();
        }
    };

    return (
        <BaseModal
            id='add_user_modal'
            open
            close={props.close}
            submit={submit}
            title={props.id ? Constants.EDIT_USER : Constants.ADD_USER}
        >
            <Container>
                <PersonalDetailsForm
                    firstName={{
                        value: firstName,
                        setValue: setFirstName,
                        error: firstNameError,
                        setError: setFirstNameError,
                    }}
                    lastName={{
                        value: lastName,
                        setValue: setLastName,
                        error: lastNameError,
                        setError: setLastNameError,
                    }}
                    email={{
                        value: username,
                        setValue: setUsername,
                        error: usernameError,
                        setError: setUsernameError,
                    }}
                    isUsername={true}
                />
                <Grid
                    container
                    spacing={2}
                    style={{ paddingTop: '16px' }}
                >
                    <GridSelectFieldInput
                        id="dealer_input"
                        label={Constants.DEALER}
                        field={{
                            value: dealer,
                            setValue: setDealer,
                            error: dealerError,
                            setError: setDealerError,
                        }}
                        options={dealerOptions}
                        fullWidth
                    />
                    <GridTextFieldInput
                        id="password_input"
                        label={Constants.PASSWORD}
                        field={{
                            value: password,
                            setValue: setPassword,
                            error: passwordError,
                            setError: setPasswordError,
                        }}
                        type="password"
                    />
                    <GridTextFieldInput
                        id="password_confirmation_input"
                        label={Constants.PASSWORD_CONFIRMATION}
                        field={{
                            value: passwordConfirmation,
                            setValue: setPasswordConfirmation,
                            error: passwordConfirmationError,
                            setError: setPasswordConfirmationError,
                        }}
                        type="password"
                    />
                </Grid>
                {passwordNoMatchError !== '' && (
                    <Typography color="error">{passwordNoMatchError}</Typography>
                )}
            </Container>
        </BaseModal>
    );
};

export default AddUserModal;
