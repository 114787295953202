/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import BinsenseStore from '../../stores/BinsenseStore';
import getNewSite from './getNewSite';

const getSite = (index: number) => index >= 0 ? BinsenseStore.userData.sites[index] : getNewSite();

export default getSite;
