/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../common/displayTable/DisplayGridContent';
import DisplayGridChip from '../../common/displayTable/DisplayGridChip';
import DisplayGridItem from '../../common/displayTable/DisplayGridItem';
import {Typography} from '@mui/material';

interface Props {
    data: any,
    dealer?: boolean,
}

const AlertsTable = observer((props: Props) => {
    const renderGridLabel = (id: string, label: string, xs: number) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={xs}
        />
    );

    return (
        <DisplayGridContent key={`${ props.data.id }_note`}>
            {props.dealer && renderGridLabel(String(props.data.id), props.data.customer, 1.5)}
            {props.dealer && renderGridLabel(String(props.data.id), props.data.site, 1.5)}
            <DisplayGridItem
                xs={props.dealer ? 4 : 6}
            >
                <div style={{ width: '100%' }}>
                    <Typography fontWeight="bold">
                        {props.data.bin}
                    </Typography>
                    <Typography>
                        {props.data.message}
                    </Typography>
                </div>
            </DisplayGridItem>
            {renderGridLabel(String(props.data.id), String(props.data.date), 2)}
            <DisplayGridChip
                status={props.data.status}
                xs={props.dealer ? 1.5 : 2}
            />
            {renderGridLabel(String(props.data.id), props.data.grainType, props.dealer ? 1.5 : 2)}
        </DisplayGridContent>
    );
});

export default AlertsTable;
