/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import {useState} from 'react';
import DisplayGridHeaderRow from '../../../common/displayTable/DisplayGridHeaderRow';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DisplayGridSortableTableHeader from '../../../common/displayTable/DisplayGridSortableTableHeader';
import Constants from '../../../../helper/Constants';
import ReportsDifferenceTableRow from './ReportsDifferenceTableRow';
import PaginationTable from '../../../common/displayTable/PaginationTable';
import {styled} from '@mui/material';
import TooltipStore from '../../../../stores/TooltipStore';
import TooltipMessages from '../../../../helper/TooltipMessages';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import DisplayGridItem from '../../../common/displayTable/DisplayGridItem';
import InfoTooltip from '../../../common/InfoTooltip';
import SortableTableHeaderButton from '../../../common/displayTable/SortableTableHeaderButton';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

interface Props {
    data: any[],
}

const ReportsDifferenceTable = observer((props: Props) => {
    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const renderHeader = (id: string, label: string, key: string) => (
        <DisplayGridSortableTableHeader
            id={`${ id }_header`}
            label={label}
            xs={12.0 / 7.0}
            data={props.data}
            setData={BinsenseStore.setDifferencesData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderRow = (row: any) => (
        <ReportsDifferenceTableRow
            key={`${ row.id }_row`}
            row={row}
        />
    );

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <PaginationTable
            rows={props.data}
            renderRow={renderRow}
            pageRowCount={100}
        >
            <DisplayGridHeaderRow sx={{marginTop: '0px'}}>
                <DisplayGridItem
                    xs={12.0 / 7.0}
                    header
                >
                    <Row>
                        <SortableTableHeaderButton
                            id="row_header"
                            label={Constants.ROW}
                            data={props.data}
                            setData={BinsenseStore.setDifferencesData}
                            sortColumn={sortColumn}
                            setSortColumn={setSortColumn}
                            sortAscending={sortAscending}
                            setSortAscending={setSortAscending}
                            dataKey="level"
                        />
                        <InfoTooltip
                            id="differences_tooltip_button"
                            open={TooltipStore.beginTooltips && TooltipStore.showDifferencesMessages}
                            message={TooltipMessages.DIFFERENCES}
                            onClick={() => TooltipStore.setShowDifferencesMessages(false, true)}
                        />
                    </Row>
                </DisplayGridItem>
                {renderHeader('start_temp', Constants.START_TEMP, 'startTemp')}
                {renderHeader('end_temp', Constants.END_TEMP, 'endTemp')}
                {renderHeader('temp_change', Constants.TEMP_CHANGE, 'tempChange')}
                {renderHeader('start_moisture', Constants.START_MOIST, 'startMoisture')}
                {renderHeader('end_moisture', Constants.END_MOIST, 'endMoisture')}
                {renderHeader('moist_change', Constants.MOIST_CHANGE, 'moistureChange')}
            </DisplayGridHeaderRow>
        </PaginationTable>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={600}/>
        </SkeletonRoot>
    );
});

export default ReportsDifferenceTable;
