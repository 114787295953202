/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, TablePagination} from '@mui/material';
import {ChangeEvent, Dispatch, SetStateAction, useMemo} from 'react';

interface Props {
    count: number,
    pageField: {
        value: number,
        setValue: Dispatch<SetStateAction<number>>
    },
    rowsPerPageField: {
        value: number,
        setValue: Dispatch<SetStateAction<number>>
    }
}

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
});

const Pagination = (props: Props) => {
    const rowsPerPageOptions = useMemo(() => {
        const options = [];
        if (props.count > 10) {
            options.push({
                id: 'tag_10',
                value: 10,
                label: '10',
            });
        }
        if (props.count > 20) {
            options.push({
                id: 'tag_20',
                value: 20,
                label: '20',
            });
        }
        if (props.count > 30) {
            options.push({
                id: 'tag_30',
                value: 30,
                label: '30',
            });
        }
        options.push({
            id: 'tag_all',
            value: props.count,
            label: 'All',
        });
        return options;
    }, [ props.count ]);

    const handleChangePage = (
        event: any,
        newPage: number
    ) => {
        props.pageField.setValue(newPage);
    };

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        props.rowsPerPageField.setValue(parseInt(event.target.value, 10));
        props.pageField.setValue(0);
    };

    return (
        <Root>
            <TablePagination
                component="div"
                count={props.count}
                page={props.pageField.value}
                onPageChange={handleChangePage}
                rowsPerPage={props.rowsPerPageField.value}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                showFirstButton
                showLastButton
            />
        </Root>
    );
};

export default Pagination;