/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */
/* eslint-disable */

//eslint-disable is placed to remove error: Expected indentation of 28 space characters but found 24 react/jsx-indent

import {Accordion, AccordionDetails, AccordionSummary, Link, styled, Typography} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';
import getTestID from "../../helper/functions/getTestID";

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const StyledTypography = styled(Typography)(() => ({
    paddingBottom: '8px',
}));

interface Props {
    hideButtons?: boolean,
    onAccept?: () => void,
    onDecline?: () => void,
    paperSx?: object,
}

function TermsOfService(props: Props) {
    return (
        <Root>
            {!props.hideButtons && (
                <StyledTypography>
                    WELCOME FROM CALIAN AGRICULTURE
                </StyledTypography>
            )}
            <Typography>
                Thank you for using our Bin-Sense® or Fuel Lock™ products (“Products”) and our related services (“Services”). These Services are provided for your use by us, Calian Agriculture Ltd. (“Calian Agriculture”), but it is likely that you purchased our Products with our Services from someone else. This might be a retailer, distributor, or partner of Calian Agriculture. If you have any questions about your purchase, please contact them first because they are in a better position to help you with most concerns.
            </Typography>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore {...getTestID('ARTICLE1')}/>}
                    {...getTestID('article_1_accordion')}
                >
                    <Typography>ARTICLE 1 - TERMS OF SERVICE</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        By using our Products and Services, you are agreeing to these terms and conditions described herein (the Terms of Service”) so please read them carefully. Our Products and Services are constantly evolving and, as a result, we may amend or modify the Terms of Service from time to time at Calian Agriculture’s discretion and without notice.. Your continued use of the Products and/or Services constitutes your acceptance of any amended or modified Terms of Service.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore {...getTestID('ARTICLE2')}/>}
                    {...getTestID('article_2_accordion')}
                >
                    <Typography>ARTICLE 2 - USING OUR SERVICES</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        Calian Agriculture grants you, and you accept, a non-exclusive, non-transferable, non-sublicensable, personal, revocable, limited right and license to access and use the Services. The rights granted in this license are limited to the purposes of operating and performing our Services.
                    </StyledTypography>
                    <StyledTypography>
                        Our Products and Services allow you to upload, submit, store, send or receive content and you retain ownership of any intellectual property rights that you may have on any such content. What belongs to you remains yours. When you transfer content or data through our Products or Services, you agree that Calian Agriculture may, and you are giving Calian Agriculture, and those we work with, a worldwide, right and royalty-free license to collect, use, host, store, disclose, reproduce, modify, and create derivative works from such content and data derived from your use of our Products and Services for industry analysis, benchmarking, analytics, marketing, Product and/or Service improvements, and other commercial and business purposes. All such data disclosed will be in aggregate and anonymous form only and will not identify you or any specific user of our Products or Services, all in compliance with our Privacy Policy (referenced below).
                    </StyledTypography>
                    <StyledTypography>
                        Some of our Services may allow you to remove your content and we cannot guarantee that we will retain any of this information. If you are concerned about how we keep and store your content, please review our Privacy Policy.
                    </StyledTypography>
                    <StyledTypography>
                        The Privacy Policy is incorporated into these Terms of Service by reference.
                    </StyledTypography>
                    <StyledTypography>
                        If you are using our Services on behalf of a corporation or other non-human entity, your agreement to these Terms of Service is also binding on that entity.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore {...getTestID('ARTICLE3')}/>}
                    {...getTestID('article_3_accordion')}
                >
                    <Typography>ARTICLE 3 - THINGS YOU CANNOT DO</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        You are not allowed to hack, decompile, change, reconstruct, or change our Services. This includes our website, any software we develop, and any hardware we produce. By using our Services, you are agreeing to leave all our stuff alone, and only use it in the way that we intended you to use it. You are also not allowed to create any software or product that interacts with our Services, or does basically the same thing our Services do. Do not use any of our Services in any illegal way or in ways that might be interpreted as offensive, or immoral. We also do not want you using any of our trademarks, logos, or the things that we create in a way that someone else might think that you are Calian Agriculture. Finally, while you can use our Services for your own personal or commercial purposes, you cannot sell our Services or otherwise profit independently from our Services.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore {...getTestID('ARTICLE4')}/>}
                    {...getTestID('article_4_accordion')}
                >
                    <Typography>ARTICLE 4 - YOUR ACCOUNT</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        To use our Services, you need an account with a unique username and password. Make sure you keep your password safe as we are not responsible for stolen usernames or passwords. If you suspect that someone has stolen your account or otherwise has gained unlawful access to the Services using your account information, you agree to tell us as soon as reasonably possible.
                    </StyledTypography>
                    <StyledTypography>
                        Our agreement to provide Services is with you and any non-human entity that you are using the services on behalf of. By using the Services and maintaining an account, you agree not to let anyone who is not bound by this agreement to use that account.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore {...getTestID('ARTICLE5')}/>}
                    {...getTestID('article_5_accordion')}
                >
                    <Typography>ARTICLE 5 - SUBSCRIPTION PLAN FEES</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        The Services are licensed to you under a specific subscription plan for the selected initial subscription term (“Subscription Plan”). As part of the Subscription Plan, you agree to pay the fees as quoted to you by a retailer or distributor when you first purchased one of our Products and Services. The Subscription Plan fees are to be paid no later than the 30th day in the month that you sign up for the Services. The Subscription Plan does not include any amounts associated with the purchase of the Products that are required to use our Services. We may change the Subscription Plan that we charge you, but will provide you with reasonable notice prior to doing so. Unless you tell us otherwise in writing, we will renew your Subscription Plan to the Services for additional terms at the end of the initial term, and your obligation to pay any Subscription Plan fees will continue indefinitely, so long as the Services are being provided and you can access them.
                    </StyledTypography>
                    <StyledTypography>
                        You may pay by cheque, direct bank transfer, or other payment methods we may accept. At any time, we may stop accepting payment from any method previously accepted, or start accepting new methods of payments.
                    </StyledTypography>
                    <StyledTypography>
                        If you do not pay the Subscription Plan fees as due, we have the right to stop providing you with the Services.
                    </StyledTypography>
                    <StyledTypography>
                        You must have the authority to use any billing methods you provide We use third-party payment processors to process and/or collect the Subscription Plan fees associated with your account. The processing of payments will be subject to the terms, conditions and privacy policies of the third-party payment processor. This is something that you agree to as part of these Terms of Service. Any disputes that you may have regarding the processing of any payment must be resolved directly with the third-party payment processor.
                    </StyledTypography>
                    <StyledTypography>
                        We will provide you with either a digital billing summary that you can review, save, and print as you wish, or a hard copy in the mail. If there are any discrepancies, or you think that we billed you in error, you need to contact us within 90 days of the date of billing. After 90 days, we will not give you a refund. If you do contact us within 90 days, and we have made a mistake, then we will correct the account.
                    </StyledTypography>
                    <StyledTypography>
                        We send all invoices and expect all payments within Canada to be made in Canadian currency. All other countries are considered to be in United States currency and paid to reflect USD exchange rates. Any exchange rate charges are your responsibility.
                    </StyledTypography>
                    <StyledTypography>
                        All the prices that we quote or display from time to time do not include taxes. You are responsible for any taxes that you are required to pay, or that we need to collect from you.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore {...getTestID('ARTICLE6')}/>}
                    {...getTestID('article_6_accordion')}
                >
                    <Typography>ARTICLE 6 - MODIFYING OR TERMINATING OUR SERVICES</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        We are constantly updating and modifying and improving our Services to give you a better experience. We may add or remove certain functionalities related to our Services. We may suspend or stop a service altogether.
                    </StyledTypography>
                    <StyledTypography>
                        You can stop using our Services at any time. Calian Agriculture may also stop providing Services to you at any time, or add or create new limits on the Services that we provide to you.
                    </StyledTypography>
                    <StyledTypography>
                        We understand your content is important to you. If we suspend or otherwise cancel a Service, we will give you reasonable notice, where practicable, so you have a chance to retrieve and store that content elsewhere. If you cancel your Subscription or you let your Subscription expire, we recommend that you immediately back up your content. You will only be able to access and back up your content for 90 days after your cancellation or the expiry of your Subscription. Any content that you leave behind may be deleted after 90 days.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore {...getTestID('ARTICLE7')}/>}
                    {...getTestID('article_7_accordion')}
                >
                    <Typography>ARTICLE 7 - INDEMNIFICATION</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        You will indemnify, release and hold harmless Calian Agriculture, its parent and affiliates, and all officers, directors, and employees, from and against any and all third party claims, actions, liabilities, losses, damages, judgments, costs and expenses, arising out of (i) any use of the Services by you that is not in accordance with this Agreement; (ii) any violation of any laws, regulations or the rights of any third party by you or by any person that you allow to use the Services.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore {...getTestID('ARTICLE8')}/>}
                    {...getTestID('article_8_accordion')}
                >
                    <Typography>ARTICLE 8 - OUR WARRANTIES AND DISCLAIMERS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        We provide our Services using a commercially reasonable level of skill and care and it is our hope these Services will make your operations better. However, there are certain things that we do not promise about our Services.
                    </StyledTypography>
                    <StyledTypography>
                        OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OF SERVICE OR ADDITIONAL TERMS, NEITHER CALIAN AGRICULTURE NOR ITS SUPPLIERS OR DISTRIBUTORS MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE, WE DO NOT MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE SERVICES, THE SPECIFIC FUNCTIONS OF THE SERVICES, OR THEIR RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS. WE PROVIDE THE SERVICES ‘AS IS.’
                    </StyledTypography>
                    <StyledTypography>
                        SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.
                    </StyledTypography>
                    <StyledTypography>
                        WE DO NOT GUARANTEE THE ACCURACY OR TIMELINESS OF INFORMATION AND/OR DATA AVAILABLE OR ACCESSIBLE THROUGH USE OF THE SERVICES NOR THAT THE SERVICES MAY BE PROVIDED WITHOUT INTERRUPTION OR ERROR-FREE. WE DO NOT WARRANT THAT WE WILL BE ABLE TO CORRECT ALL REPORTED DEFECTS IN THE SERVICES. WE DO NOT GUARANTEE THAT THE SERVICES AND ACCESS TO YOUR ACCOUNT WILL ALWAYS FUNCTION WITHOUT DISRUPTIONS OR DELAY. SINCE THE SERVICES MAY OR WILL BE FACILITATED AND/OR TRANSMITTED THROUGH PUBLIC INTERNET LINES, COMMUNICATIONS NETWORKS, AND PUBLIC AND/OR PRIVATE SWITCHED TELEPHONY NETWORK(S) (AS THE CASE MAY BE), YOU UNDERSTAND THAT THERE MAY BE SERVICE DISRUPTIONS OR OUTAGES.
                    </StyledTypography>
                    <StyledTypography>
                        THERE IS NO WARRANTY ON ANY SOFTWARE PRODUCTS LICENSED TO YOU AS PART OF THE PROVIDED SERVICES.
                    </StyledTypography>
                    <StyledTypography>
                        WE ARE NOT RESPONSIBLE FOR ANY LOSS OF BUSINESS, USE OR DATA, INTERRUPTION OF BUSINESS, LOST PROFITS OR GOODWILL, OR OTHER KIND OF INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH LOSS, AND NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OR OF ANY LIMITED REMEDY. THIS EXCLUSION INCLUDES ANY LIABILITY THAT MAY ARISE OUT OF THIRD-PARTY CLAIMS AGAINST USER OR CALIAN AGRICULTURE.
                    </StyledTypography>
                    <StyledTypography>
                        Calian Agriculture does provide a limited warranty for the physical and tangible Products that you purchased from one of our authorized distributors or dealers for a period of 12 months from the date of purchase. This warranty is limited only to those manufacturing defects that were caused, or allowed to occur because of us. If the Product you purchased was damaged in transport, by a natural disaster, through some means that was not caused by us, by you, by an improper installation, by the environment in which the Product was used, by misuse, by use for a purpose for which the equipment was not designed, by improper maintenance, or by abuse or improper handling, then we are not responsible.
                    </StyledTypography>
                    <StyledTypography>
                        If you have a valid warranty claim, please notify us immediately. If we do not receive notification that you have a warranty claim before the warranty period expires, we will not be obligated to provide any warranty services to you. When we determine that you have a valid warranty claim, we will determine the best way to fix the problem, we might repair the Product, or, just give you a new one but, that decision is ours. Any repaired or replaced Products have a warranty period of the original term or 90 days, whichever is longer.
                    </StyledTypography>
                    <StyledTypography>
                        If, for some reason, you do have a claim against us, our liability is limited to the amount that we charged and collected from you in the 12 months prior to the date on which the claim arose, regardless of why or how the claim arose, including negligence and gross negligence.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore {...getTestID('ARTICLE9')}/>}
                    {...getTestID('article_9_accordion')}
                >
                    <Typography>ARTICLE 9 - APPLICABLE LAW</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        You agree that these Terms of Service, and any contract that we enter into shall be governed by the laws of Saskatchewan. You agree to submit to the exclusive jurisdiction of the courts of competent jurisdiction in the Province of Saskatchewan in respect to any action relating to this Agreement.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore {...getTestID('ARTICLE10')}/>}
                    {...getTestID('article_10_accordion')}
                >
                    <Typography>ARTICLE 10 - ADDITIONAL TERMS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <StyledTypography>
                        From time to time, applicable laws might require us to do certain things and we are going to comply with those requirements. If the applicable laws change requiring an update to the Services, then we are going to make those changes.
                    </StyledTypography>
                    <StyledTypography>
                        To the extent possible, we will protect your private information and content. However, if required by law, we will do what is required of us.
                    </StyledTypography>
                    <StyledTypography>
                        If you do something that you should not have under this Agreement, and we choose not to do anything to stop you, that does not mean that we are waiving any right to act in the future. We just are not doing anything about it right now.
                    </StyledTypography>
                </AccordionDetails>
            </Accordion>

            <StyledTypography>
                ACKNOWLEDGMENT: BY CLICKING “Next” YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, UNDERSTAND IT, AND HAVE THE OPPORTUNITY TO SPEAK WITH A LAWYER THAT DOES NOT REPRESENT CALIAN AGRICULTURE PRIOR TO AGREEING TO IT. IN CONSIDERATION OF US PROVIDING THE SERVICES TO YOU, YOU AGREE TO THESE TERMS OF SERVICE. IF YOU DO NOT AGREE, CLICK ‘Cancel’ AND YOU WILL NOT BE ABLE TO ACCESS THE SERVICES.
            </StyledTypography>

        </Root>
    );
}

export default TermsOfService;