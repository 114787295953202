import { Button, Typography } from '@mui/material';
import TitlePaper from '../../common/TitlePaper';
import Constants from '../../../helper/Constants';
import getTestID from '../../../helper/functions/getTestID';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import getExtendUserDueDate from '../../../controllers/apiCalls/get/getExtendUserDueDate';

interface Props {
    userId: number
}

const ExtendDueDatePaper = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const extendUserDueDate = () => {
        getExtendUserDueDate(enqueueSnackbar, props.userId);
    };

    return (
        <TitlePaper title={Constants.EXTEND_DUE_DATE}>
            <Typography>
                {Constants.EXTEND_DUE_DATE_MESSAGE}
            </Typography>
            <Button
                {...getTestID('extend_due_date_button')}
                color='primary'
                variant='contained'
                onClick={extendUserDueDate}
                style={{
                    marginRight: '8px',
                    marginTop: '8px',
                }}
            >
                {Constants.EXTEND}
            </Button>
        </TitlePaper>
    );
});

export default ExtendDueDatePaper;