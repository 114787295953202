/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import WebStore from '../../stores/WebStore';
import Constants from '../Constants';
import getUserModel from '../../controllers/apiCalls/get/getUserModel';
import getDealerUserModel from '../../controllers/apiCalls/get/getDealerUserModel';

const updateData = (enqueueSnackbar: any, userId?: number) => {
    if (WebStore.selectedView === Constants.CUSTOMER) {
        getUserModel(enqueueSnackbar);
    }
    else {
        getDealerUserModel(enqueueSnackbar, userId);
    }
};

export default updateData;
