/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import Constants from '../../../helper/Constants';
import ToggleButtonInput from '../../common/input/ToggleButtonInput';

const RightAlign = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

interface Props {
    view: string,
    setView: (value: string) => void,
}

const MapViewToggle = (props: Props) => (
    <RightAlign>
        <ToggleButtonInput
            title=""
            value={props.view}
            setValue={props.setView}
            options={[
                {
                    id: 'map_option',
                    title: Constants.MAP,
                },
                {
                    id: 'satellite_option',
                    title: Constants.SATELLITE,
                },
            ]}
            size="small"
        />
    </RightAlign>
);

export default MapViewToggle;
