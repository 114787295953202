/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Bin, Building, GrainBag, Pile} from '../../types/components/ApiTypes';
import Constants from '../Constants';

const getDeviceType = (struct: Bin | GrainBag | Building | Pile) => {
        if (struct.settings?.hardwareType && (struct.settings.hardwareType === Constants.LIVE.toLowerCase() ||
            struct.settings.hardwareType === Constants.UNMONITORED)) {
                if (struct.hardware.masterUnits.length > 0) {
                        return Constants.MASTER.toLowerCase();
                } else {
                        return Constants.REMOTE.toLowerCase();
                }
        }
        return struct.settings?.hardwareType ? struct.settings.hardwareType : '';
};

export default getDeviceType;
