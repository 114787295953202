/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Delete} from '@mui/icons-material';
import Constants from '../../../../helper/Constants';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import DisplayGridActions from '../../../common/displayTable/DisplayGridActions';
import DisplayGridCheckBox from '../../../common/displayTable/DisplayGridCheckBox';
import {Dispatch, SetStateAction} from 'react';

interface Props {
    row: any,
    changeSelected: (id: number) => void,
    setDeleteId: Dispatch<SetStateAction<number | null>>
}

const FanAssociationsTableRow = (props: Props) => (
    <DisplayGridContent key={`${ props.row.id }_fan_associations`}>
        <DisplayGridCheckBox
            xs={2}
            id={`${ props.row.id }_selected`}
            value={props.row.selected}
            onChange={() => props.changeSelected(props.row.id)}
        />
        <DisplayGridLabel
            id={String(props.row.id)}
            label={props.row.fanLocalId}
            xs={5}
        />
        <DisplayGridActions
            buttons={[
                {
                    id: `${ props.row.id }_fan_associations_delete_button`,
                    onClick: () => props.setDeleteId(props.row.id),
                    icon: <Delete/>,
                    hoverText: Constants.DELETE,
                },
            ]}
            xs={5}
            key={`${ props.row.fanId }_fan_associations_actions_cell`}
            id={props.row.fanId}
        />
    </DisplayGridContent>
);

export default FanAssociationsTableRow;
