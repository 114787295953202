// /*
//  * Copyright © 2021 Calian Ltd.  All rights reserved.
//  */

import React, { useEffect, useState } from 'react';
import {Button, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import { Customer } from '../../../types/components/ApiTypes';
import TitlePaper from '../../common/TitlePaper';
import Constants from '../../../helper/Constants';
import getTestID from '../../../helper/functions/getTestID';
import BinsenseStore from '../../../stores/BinsenseStore';
import { useSnackbar } from 'notistack';
import postWaiveCustomerBalance from '../../../controllers/apiCalls/post/postWaiveCustomerBalance';
import WebStore from '../../../stores/WebStore';

interface Props {
    user: Customer
}

const MyCustomersWaiveBalancePanel = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ balance, setBalance ] = useState(0);

    useEffect(() => {
        setBalance(WebStore.selectedCustomer.balance.amount);
    }, [ WebStore.selectedCustomer.balance.amount ]);

    const waiveBalance = () => {
        postWaiveCustomerBalance(enqueueSnackbar, props.user.id);
    };

    return (
        <TitlePaper title={Constants.WAIVE_SUBSCRIPTION_OWING_BALANCE}>
            <Typography>
                This button will take the owing balance of ${ balance } and decrease it to $0.
                If a customer account is locked out, they will then be able to have access again.
            </Typography>
            <Button
                {...getTestID('waive_balance_button')}
                color="primary"
                variant="contained"
                onClick={waiveBalance}
                disabled={BinsenseStore.awaitingApi || balance <= 0}
                style={{
                    marginRight: '8px',
                    marginTop: '8px',
                }}
            >
                Waive
            </Button>
        </TitlePaper>
    );
});

export default MyCustomersWaiveBalancePanel;