import { cloneDeep } from 'lodash';
import WebStore from '../../../stores/WebStore';
import { Customer } from '../../../types/components/ApiTypes';
import callAPI from '../../callAPI';

const getCustomerBillingAddress = (enqueueSnackbar: any, customer: Customer) => {
    callAPI(`billingAddress/${ customer.id }`,
        (response) => {
            const customerData = cloneDeep(WebStore.selectedCustomer);
            customerData.id = customer.id;
            customerData.account = customer.account;
            customerData.account.billingAddress = response;

            WebStore.setSelectedCustomer(customerData, true);
        },
        enqueueSnackbar
    );
};

export default getCustomerBillingAddress;