/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import DisplayGridHeaderRow from '../../common/displayTable/DisplayGridHeaderRow';
import DisplayGridSortableTableHeader from '../../common/displayTable/DisplayGridSortableTableHeader';
import Constants from '../../../helper/Constants';
import BinsenseStore from '../../../stores/BinsenseStore';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import {useEffect, useMemo, useState} from 'react';
import AddUserModal from './AddUserModal';
import getMyUsersData from '../../../helper/functions/getMyUsersData';
import MyUsersTableRow from './MyUsersTableRow';
import {observer} from 'mobx-react-lite';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import {styled} from '@mui/material';
import { useSnackbar } from 'notistack';
import deleteDealerUser from '../../../controllers/apiCalls/delete/deleteDealerUser';
import useWindowSize from '../../../helper/hooks/useWindowSize';
import PaginationTable from '../../common/displayTable/PaginationTable';
import DeleteUserModal from '../../common/modal/DeleteUserModal';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const MyUsersTable = observer(() => {
    const {enqueueSnackbar} = useSnackbar();

    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);
    const [ editId, setEditId ] = useState<number | null>(null);
    const [ deleteId, setDeleteId ] = useState<number | null>(null);
    const [ deleteUserMessage, setDeleteUserMessage ] = useState('');

    const rows = useMemo(() => BinsenseStore.myUsersData, [ BinsenseStore.myUsersData ]);

    useEffect(() => BinsenseStore.setMyUsersData(getMyUsersData(BinsenseStore.dealerUserData.dealerUsers)),
        [ BinsenseStore.dealerUserData ]);

    const renderSortableHeader = (id: string, label: string, key: string) => (
        <DisplayGridSortableTableHeader
            id={`${ id }_header`}
            label={label}
            xs={largeScreen ? 3.5 : 10 / 3}
            data={rows}
            setData={BinsenseStore.setMyUsersData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const deleteRow = () => {
        deleteDealerUser(enqueueSnackbar, Number(deleteId));
    };

    const renderRow = (row: any) => (
        <MyUsersTableRow
            row={row}
            setEdit={setEditId}
            setDeleteId={setDeleteId}
            setDeleteMessage={setDeleteUserMessage}
            key={`${ row.id }_user`}
        />
    );

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <div>
            <PaginationTable
                rows={rows}
                renderRow={renderRow}
            >
                <DisplayGridHeaderRow>
                    {renderSortableHeader('user', 'Username/Email', 'email')}
                    {renderSortableHeader('name', Constants.NAME, 'name')}
                    {renderSortableHeader('dealer', Constants.DEALER, 'dealer')}
                    <DisplayGridLabel
                        id='actions_header'
                        label={Constants.ACTIONS}
                        xs={largeScreen ? 1.5 : 2}
                        header
                    />
                </DisplayGridHeaderRow>
            </PaginationTable>
            {editId !== null && (
                <AddUserModal
                    open
                    close={() => setEditId(null)}
                    id={editId}
                />
            )}
            {deleteId !== null && (
                <DeleteUserModal
                    id={`${ deleteId }_delete_confirmation`}
                    open
                    close={() => setDeleteId(null)}
                    submit={deleteRow}
                    name={deleteUserMessage}
                />
            )}
        </div>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={600}/>
        </SkeletonRoot>
    );
});

export default MyUsersTable;
