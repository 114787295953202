/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Tooltip} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {observer} from 'mobx-react-lite';
import getTestID from '../../../helper/functions/getTestID';

interface Props {
    id: string
    icon: object,
    hoverText: string,
    onClick: any,
    disabled?: boolean,
    color?: any,
}

const HoverTextButton = observer((props: Props) => (
    <Tooltip
        title={props.hoverText}
        followCursor
    >
        <div>
            <IconButton
                {...getTestID(props.id)}
                onClick={props.onClick}
                disabled={props.disabled}
                color={props.color}
            >
                {props.icon}
            </IconButton>
        </div>
    </Tooltip>
));

export default HoverTextButton;
