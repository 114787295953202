/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import Constants from '../Constants';

const getSensorColor = (temperature: number, sensorMuted: boolean, highTemperatureThreshold: number, lowTemperatureThreshold: number) => {
    let color = Constants.SENSOR_COLOR_GREY;

    if (!sensorMuted) {

        switch (true) {
            case highTemperatureThreshold != null && temperature >= highTemperatureThreshold:
            case lowTemperatureThreshold != null && temperature <= lowTemperatureThreshold:
                color = Constants.SENSOR_COLOR_RED;
                break;
            case highTemperatureThreshold != null && temperature > highTemperatureThreshold - Constants.SENSOR_CAUTION_THRESHOLD:
            case lowTemperatureThreshold != null && temperature < lowTemperatureThreshold + Constants.SENSOR_CAUTION_THRESHOLD:
                color = Constants.SENSOR_COLOR_YELLOW;
                break;
            default:
                color = Constants.SENSOR_COLOR_GREEN;
        }
    }

    return color;
};

export default getSensorColor;
