/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import BinsenseStore from '../../stores/BinsenseStore';
import ProvinceData from '../temp/ProvinceData';

const getProvinceList = (country: string) => {
    if (country) {
        const countryIndex = BinsenseStore.dataModel.countries.findIndex(c => c.id === Number(country));
        const provinces = countryIndex > -1 ? BinsenseStore.dataModel.countries[countryIndex].regions : undefined;

        if (provinces) {
            return provinces.map(province => ({
                title: province.name,
                id: `${ province.id }_option`,
                value: province.id.toString(),
            }));
        }

        if (country === '14') {
            return ProvinceData.australia;
        }

        if (country === '40') {
            return ProvinceData.canada;
        }

        if (country === '144') {
            return ProvinceData.mexico;
        }

        if (country === '236') {
            return ProvinceData.usa;
        }
    }
    return [];
};

export default getProvinceList;
