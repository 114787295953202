/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {BuildingSVG, GrainBagSVG, GrainPileSVG} from '../../../../images/sitemap/RectangleStructSVG';
import {AutomationSVG, BinSVG} from '../../../../images/sitemap/RoundStructSVG';
import {DistancePerPixel, DistanceUnit, MarkerData, MarkerTypes} from '../../../../types/components/ApiTypes';

const MINIMUM_SIZE = 4;

interface Props {
    marker: MarkerData,
    distancePerPixel?: DistancePerPixel,
}

const DisplayMarker = (props: Props) => {
    const getDistPerPixel = (unit: DistanceUnit) => {
        if (props.distancePerPixel) {
            if (unit === DistanceUnit.feet) {
                return props.distancePerPixel.ftPerPx;
            }
            if (unit === DistanceUnit.meters) {
                return props.distancePerPixel.mPerPx;
            }
        }

        return 1;
    };

    if (props.marker.display && props.distancePerPixel) {
        if (props.marker.display.type === MarkerTypes.bin &&
            props.marker.display.grainBinData) {
            const diameterInPixel = props.marker.display.grainBinData.diameter /
                getDistPerPixel(props.marker.display.grainBinData.unit);
            const fullness = parseFloat(props.marker.tooltip?.displayData?.level || '0%') || 0;
            const binColor = props.marker.heatThresholdColor || 'black';
            if (diameterInPixel > MINIMUM_SIZE) {
                return (
                    <BinSVG
                        id={`${ props.marker.id }_bin`}
                        diameter={diameterInPixel}
                        x={10}
                        y={10}
                        fullness={fullness}
                        color={binColor}
                    />
                );
            }
        }
        if (props.marker.display.type === MarkerTypes.automation) {
            const diameterInPixel = 30 / getDistPerPixel(DistanceUnit.feet);
            if (diameterInPixel > MINIMUM_SIZE) {
                return (
                    <AutomationSVG
                        id={`${ props.marker.id }_automation_device`}
                        diameter={diameterInPixel}
                        x={10}
                        y={10}
                    />
                );
            }
        }

        if (props.marker.display.type === MarkerTypes.grainPile &&
            props.marker.display.grainPileData) {
            const diameterInPixel = props.marker.display.grainPileData.diameter /
                getDistPerPixel(props.marker.display.grainPileData.unit);
            if (diameterInPixel > MINIMUM_SIZE) {
                return (
                    <GrainPileSVG
                        id="gp"
                        x={0}
                        y={0}
                        width={diameterInPixel}
                        length={diameterInPixel}
                        shape={props.marker.display.grainPileData.shape}
                    />
                );
            }
        }

        if (props.marker.display.type === MarkerTypes.grainBag &&
            props.marker.display.grainBagData) {
            const distPerPx = getDistPerPixel(props.marker.display.grainBagData.unit);
            const widthInPixel = props.marker.display.grainBagData.width / distPerPx;
            const lengthInPixel = props.marker.display.grainBagData.length / distPerPx;
            if (lengthInPixel > MINIMUM_SIZE && widthInPixel > MINIMUM_SIZE) {
                return (
                    <GrainBagSVG
                        id="gb"
                        x={0}
                        y={0}
                        width={widthInPixel}
                        length={lengthInPixel}
                        rotation={props.marker.display.grainBagData.rotation}
                    />
                );
            }
        }

        if (props.marker.display.type === MarkerTypes.building &&
            props.marker.display.buildingData) {
            const distPerPx = getDistPerPixel(props.marker.display.buildingData.unit);
            const widthInPixel = props.marker.display.buildingData.width / distPerPx;
            const lengthInPixel = props.marker.display.buildingData.length / distPerPx;
            if (lengthInPixel > MINIMUM_SIZE && widthInPixel > MINIMUM_SIZE) {
                return (
                    <BuildingSVG
                        id="building"
                        x={0}
                        y={0}
                        width={widthInPixel}
                        length={lengthInPixel}
                        rotation={props.marker.display.buildingData.rotation}
                    />
                );
            }
        }
    }

    return null;
};

export default DisplayMarker;
