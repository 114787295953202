/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useState} from 'react';
import {styled, Typography} from '@mui/material';
import Divider from '@mui/material/Divider';
import DrawerButton from './DrawerButton';
import DrawerHeader from './DrawerHeader';
import DrawerUserInformation from './DrawerUserInformation';
import DrawerText from './DrawerText';

const Root = styled('div')(({theme}) => ({
    // @ts-ignore
    backgroundColor: theme.palette.primary.drawerBackground,
    // @ts-ignore
    borderRightColor: theme.palette.primary.drawerUserBackground,
    borderRightStyle: 'solid',
    borderRightWidth: '2px',
    height: '100vh',
    minWidth: '270px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

const Column = styled('div')({
    flexDirection: 'column',
});

interface Props {
    disableButtons?: boolean,
    generalList: {
        id: string,
        title: string,
        icon: object,
        disabled?: boolean,
    }[],
    managementList: {
        id: string,
        title: string,
        icon: object,
        disabled?: boolean,
    }[]
}

function Drawer(props: Props) {
    const [ drawerOpen, setDrawerOpen ] = useState(true);

    return (
        <Root
            style={!drawerOpen ? {minWidth: '105px'} : {}}
        >
            <Column>
                <DrawerHeader
                    setOpen={() => setDrawerOpen(!drawerOpen)}
                    open={drawerOpen}
                />
                {drawerOpen && (
                    <DrawerUserInformation/>
                )}
                {!drawerOpen && (
                    <Divider/>
                )}

                <DrawerText
                    text="GENERAL"
                    drawerOpen={drawerOpen}
                />
                {props.generalList.map(drawerItem => (
                    <DrawerButton
                        id={drawerItem.id}
                        key={drawerItem.id}
                        icon={drawerItem.icon}
                        text={drawerItem.title}
                        drawerOpen={drawerOpen}
                        disabled={props.disableButtons || drawerItem.disabled}
                    />
                ))}

                <DrawerText
                    text="MANAGEMENT"
                    drawerOpen={drawerOpen}
                />
                {props.managementList.map(drawerItem => (
                    <DrawerButton
                        id={drawerItem.id}
                        key={drawerItem.id}
                        icon={drawerItem.icon}
                        text={drawerItem.title}
                        drawerOpen={drawerOpen}
                        disabled={props.disableButtons}
                    />
                ))}
            </Column>
            <Typography
                sx={{marginLeft: '16px'}}
            >
                v{process.env.REACT_APP_VERSION}
            </Typography>
        </Root>
    );
}

export default Drawer;
