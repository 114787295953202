/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {MarkerData} from '../../types/components/ApiTypes';

const getStructNamesFromMarkers = (markers: MarkerData[]) => {
    const names: string[] = [];
    markers.map(marker => {
            if (marker.display?.grainBinData) names.push(marker.display.grainBinData.name);
            if (marker.display?.grainBagData) names.push(marker.display.grainBagData.name);
            if (marker.display?.grainPileData) names.push(marker.display.grainPileData.name);
            if (marker.display?.buildingData) names.push(marker.display.buildingData.name);
        });
    return names;
};

export default getStructNamesFromMarkers;
