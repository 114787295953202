import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';
import getTempUnit from '../../../helper/functions/getTempUnit';
import { displayTemp } from '../../../helper/functions/functions';

const getReportsDifferenceData = (enqueueSnackbar: any, id: number, startDate: number, endDate: number) => {
    callAPI(`reports/difference/${ id }/${ startDate }/${ endDate }`,
        (response) => {
            BinsenseStore.setDifferencesData(response.map((dif: any, index: number) => ({
                id: index,
                endMoisture: dif.endMoisture == null ? null : `${ dif.endMoisture }%`,
                endTemp: dif.endTemp == null ? null
                   : `${ displayTemp( getTempUnit() === 'F', dif.endTemp) } ${ getTempUnit() }`,
                level: dif.level.toString(),
                moistureChange: dif.moistureChange == null ? null : `${ dif.moistureChange }%`,
                startMoisture: dif.startMoisture == null ? null : `${ dif.startMoisture }%`,
                startTemp: dif.startTemp == null ? null
                    : `${ displayTemp( getTempUnit() === 'F', dif.startTemp) } ${ getTempUnit() }`,
                tempChange: dif.tempChange == null ? null
                    : `${ displayTemp( getTempUnit() === 'F', dif.tempChange) } ${ getTempUnit() }`,
            })));
        },
        enqueueSnackbar
    );
};

export default getReportsDifferenceData;