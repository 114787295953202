/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useState} from 'react';
import {Paper, styled, Tab, Tabs} from '@mui/material';
import Constants from '../../helper/Constants';
import LoginPaperText from './LoginPaperText';
import LoginPaperTextFields from './LoginPaperTextFields';
import LoginPaperButtons from './LoginPaperButtons';
import signIn from '../../helper/functions/signIn';
import LoginPaperRegister from './LoginPaperRegister';
import RegistrationModal from './RegistrationModal';
import {useSnackbar} from 'notistack';
import getTestID from '../../helper/functions/getTestID';
import ForgotPasswordModal from './ForgotPasswordModal';
import ForgotUsernameModal from './ForgotUsernameModal';
import WebStore from '../../stores/WebStore';
import LoginStore from '../../stores/LoginStore';
import NewUserPasswordPaper from './NewUserPasswordPaper';
import ForgotPasswordResetPaper from './ForgotPasswordResetPaper';

const Root =  styled('div')({
    width: '100%',
    height: '100%',
    paddingLeft: '10%',
    paddingRight: '10%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const LoginPaper = () => {
    const {enqueueSnackbar} = useSnackbar();
    const [ tab, setTab ] = useState(Constants.CUSTOMER);
    const [ username, setUsername ] = useState('');
    const [ usernameError, setUsernameError ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');

    const [ registrationModalOpen, setRegistrationModalOpen ] = useState(false);
    const [ forgotUsernameModalOpen, setForgotUsernameModalOpen ] = useState(false);
    const [ forgotPasswordModalOpen, setForgotPasswordModalOpen ] = useState(false);
    const [ newUserPasswordPaperOpen, setNewUserPasswordPaperOpen ] = useState(false);
    const [ forgotPasswordResetPaperOpen, setforgotPasswordResetPaperOpen ] = useState(false);

    useEffect(() => {
        const url = new URL(window.location.href);
        const userUsername = url.searchParams.get('un');
        const passwordReset = url.searchParams.get('passwordReset');
        const siteId = url.searchParams.get('si');
        const token = url.searchParams.get('token');

        if (userUsername !== null && userUsername !== '' &&
                siteId !== null && siteId !== '' &&
                token !== null && token !== '') {
            setNewUserPasswordPaperOpen(true);
        }
        else if (userUsername !== null && userUsername !== '' &&
                    token !== null && token !== '' &&
                    passwordReset !== null && passwordReset === '1') {
            setforgotPasswordResetPaperOpen(true);
        }
        else if (LoginStore.token !== '' && WebStore.selectedView !== '') {
            LoginStore.setLoggedIn(true);
        }
    }, []);

    const handleChange = (event: any, newValue: string) => {
        setTab(newValue);
    };

    const onEnterPress = (e: any) => {
        if (e.key === 'Enter' && username !== '' && password !== '') {
            signIn(username, password, tab, enqueueSnackbar);
        }
    };

    return (
        <Paper
            sx={{
                width: '75%',
                ...((newUserPasswordPaperOpen || forgotPasswordResetPaperOpen) && {
                    minHeight: '350px',
                }),
                ...((!newUserPasswordPaperOpen && !forgotPasswordResetPaperOpen) && {
                    minHeight: '650px',
                }),

                minWidth: '490px',
            }}
        >
            <Root onKeyDown={onEnterPress}>
                {newUserPasswordPaperOpen ? (<NewUserPasswordPaper/>)
                : forgotPasswordResetPaperOpen ? (<ForgotPasswordResetPaper/>)
                : (
                    <>
                        <Tabs
                            value={tab}
                            onChange={handleChange}
                        >
                            <Tab
                                {...getTestID('customer_button')}
                                label={Constants.CUSTOMER}
                                value={Constants.CUSTOMER}
                                style={{
                                    width: '50%',
                                }}
                            />
                            <Tab
                                {...getTestID('dealer_button')}
                                label={Constants.DEALER}
                                value={Constants.DEALER}
                                style={{
                                    width: '50%',
                                }}
                            />
                        </Tabs>
                        <LoginPaperText tab={tab} />
                        <LoginPaperTextFields
                            username={{
                                value: username,
                                setValue: setUsername,
                                error: usernameError,
                                setError: setUsernameError,
                            }}
                            password={{
                                value: password,
                                setValue: setPassword,
                                error: passwordError,
                                setError: setPasswordError,
                            }}
                        />
                        <LoginPaperButtons
                            tab={tab}
                            username={username}
                            password={password}
                            setForgotUsernameModalOpen={setForgotUsernameModalOpen}
                            setForgotPasswordModalOpen={setForgotPasswordModalOpen}
                        />
                        <LoginPaperRegister
                            setModalOpen={setRegistrationModalOpen}
                        />
                    </>
                )}

            </Root>
            {registrationModalOpen && (
                <RegistrationModal
                    open
                    close={() => setRegistrationModalOpen(false)}
                    tab={tab}
                />
            )}
            {forgotUsernameModalOpen && (
                <ForgotUsernameModal
                    open
                    close={() => setForgotUsernameModalOpen(false)}
                />
            )}
            {forgotPasswordModalOpen && (
                <ForgotPasswordModal
                    open
                    close={() => setForgotPasswordModalOpen(false)}
                />
            )}
        </Paper>
    );
};

export default LoginPaper;
