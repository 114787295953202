/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Fragment, useMemo} from 'react';
import {Divider, styled, Tooltip, Typography} from '@mui/material';
import getTestID from '../../../../helper/functions/getTestID';
import {MoreVert} from '@mui/icons-material';
import getMapTooltipStyling from '../../../../helper/functions/getMapTooltipStyling';
import WebStore from '../../../../stores/WebStore';
import PowerSymbol from '../../../common/PowerSymbol';
import Constants from '../../../../helper/Constants';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px',
});

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    alignSelf: 'center',
    maxWidth: '180px',
});

interface Props {
    devices: any[],
    id: number | string,
}

const GridItemDeviceTooltip = (props: Props) => {
    const tooltipStyle = useMemo(getMapTooltipStyling,[ WebStore.lightMode ]);

    const renderContent = () => (
        <Fragment>
            <Column>
                {props.devices.map((device: any, index: number) => (
                    <div
                        style={{ width: '100%' }}
                        key={`${ device.serial }_tooltip_item`}
                    >
                        {index !== 0 && (
                            <Divider/>
                        )}
                        <Row
                            style={{
                                paddingTop: index !== 0 ? '8px' : '0px',
                                paddingBottom: index !== props.devices.length - 1 ? '8px' : '0px',
                            }}
                        >
                            <Column>
                                <Row>
                                    <Typography fontSize={12}>
                                        <b>{props.devices.length > 1 ? device.type.concat(': ') : ''}</b>
                                        {device.serial}
                                    </Typography>
                                </Row>
                                { device.type === Constants.REMOTE && (
                                    <Row>
                                        <Typography fontSize={12}>
                                            { `(${ Constants.MASTER }:  ${ device.masterSerial })`}
                                        </Typography>
                                    </Row>
                                )}
                            </Column>
                            <Column>
                                <PowerSymbol
                                    acPower={device.acPower}
                                    batteryLevel={device.batteryLevel}
                                />
                            </Column>
                        </Row>
                    </div>
                ))}
            </Column>
        </Fragment>
    );

    return (
        <Tooltip
            {...getTestID(`${ props.id }_device_tooltip`)}
            title={renderContent()}
            componentsProps={{
                tooltip: {
                    style: tooltipStyle,
                },
            }}
            disableHoverListener={props.devices.length === 0}
            style={{ cursor: props.devices.length > 0 ? 'pointer' : undefined }}
        >
            <MoreVert/>
        </Tooltip>
    );
};

export default GridItemDeviceTooltip;
