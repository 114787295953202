/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Dispatch, SetStateAction} from 'react';
import Constants from '../../../../../../helper/Constants';
import {observer} from 'mobx-react-lite';
import ToggleButtonInput from '../../../../../common/input/ToggleButtonInput';
import {styled, Typography} from '@mui/material';
import { AutomationOperationModes } from '../../../../../../types/components/ApiTypes';

interface Props {
    type: AutomationOperationModes,
    setType: Dispatch<SetStateAction<AutomationOperationModes>>
}

const AutomationSettingsPaperHeader = observer((props: Props) => {
    const Root = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: '16px',
    });

    return (
        <Root>
            <Typography
                color="primary"
                paddingBottom='16px'
            >
                {Constants.AUTOMATION_SETTINGS}
            </Typography>
            <ToggleButtonInput
                value={props.type}
                setValue={props.setType}
                options={[
                    {
                        id: 'temp_control_option',
                        title: Constants.TEMP_CONTROL,
                        value: AutomationOperationModes.aeration,
                    },
                    {
                        id: 'moist_control_option',
                        title: Constants.MOIST_CONTROL,
                        value: AutomationOperationModes.natural_air_drying,
                    },
                ]}
            />
        </Root>
    );
});

export default AutomationSettingsPaperHeader;
