/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {TableRow} from '../../types/components/ApiTypes';

const getRow = (row: TableRow) => {
    let rowStr = '';
    for (let i = 0; i < row.values.length; i++) {
        rowStr += `"column_${ i }_status":"${ row.values[i].status }","column_${ i }_value":"${ row.values[i].value }",`;
    }
    return JSON.parse(`{${ rowStr.slice(0, rowStr.length - 1) }}`);
};

const getCommunicationTableRowData = (rows: TableRow[]) => rows.map(row => ({
    id: row.header.toLowerCase().replace(/ /g, '_'),
    bin: row.header,
    ...getRow(row),
}));

export default getCommunicationTableRowData;
