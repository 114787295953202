import callDeleteAPI from '../../callDeleteAPI';
import updateData from '../../../helper/functions/updateData';

const deleteAssociationFan = (enqueueSnackbar: any, id: number) => {
    callDeleteAPI(`association/fan/${ id }`,
        (response) => {
            if (response === 'OK') {
                updateData(enqueueSnackbar);
            }
        },
        enqueueSnackbar,
        'Fan association deleted successfully'
    );
};

export default deleteAssociationFan;