/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

// @ts-ignore
import uuid from 'react-uuid';
import Constants from '../../../helper/Constants';
import DisplayGridContent from '../../common/displayTable/DisplayGridContent';
import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import DisplayGridActions from '../../common/displayTable/DisplayGridActions';
import {Delete, Edit} from '@mui/icons-material';
import {NotificationEmail} from '../../../types/components/ApiTypes';
import EditEmailModal from './EditEmailModal';
import deleteNotificationEmail from '../../../controllers/apiCalls/delete/deleteNotificationEmail';
import {useSnackbar} from 'notistack';
import {Email} from '@mui/icons-material';
import Colors from '../../../helper/Colors';

interface Props {
    data: NotificationEmail,
}

const CustomerEmailNotificationsRow = observer((props: Props) => {
    const { enqueueSnackbar } = useSnackbar();

    const [ editOpen, setEditOpen ] = useState(false);
    const [ deleteOpen, setDeleteOpen ] = useState(false);

    const renderGridItem = (label: string, id: string) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={6}
            icon={
                <Email
                    sx={{
                        color: Colors.accent,
                        margin: 1,
                    }}
                />
            }
        />
    );

    const deleteEmail = () => {
        deleteNotificationEmail(enqueueSnackbar, props.data.id);
    };

    return (
        <DisplayGridContent key={uuid()}>
            {renderGridItem(props.data.email, `${ props.data.id }_email`)}
            <DisplayGridActions
                buttons={[
                    {
                        id: `${ props.data.id }_edit_button`,
                        onClick: () => setEditOpen(true),
                        icon: <Edit/>,
                        hoverText: Constants.EDIT,
                    },
                    {
                        id: `${ props.data.id }_delete_button`,
                        onClick: () => setDeleteOpen(true),
                        icon: <Delete/>,
                        hoverText: Constants.DELETE,
                    },
                ]}
                xs={6}
                id={`${ props.data.id }_actions`}
            />
            {editOpen && (
                <EditEmailModal
                    id="edit_email_modal"
                    open
                    close={() => setEditOpen(false)}
                    editId={props.data.id}
                />
            )}
            {deleteOpen && (
                <ConfirmationModal
                    id='delete_email_confirmation'
                    open
                    close={() => setDeleteOpen(false)}
                    submit={deleteEmail}
                    title="Delete Email"
                    message="Are you sure you want to delete this email? Notifications will no longer
                    be sent to this email."
                />
            )}
        </DisplayGridContent>
    );
});

export default CustomerEmailNotificationsRow;
