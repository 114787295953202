/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Edit, Save} from '@mui/icons-material';
import Constants from '../../../../helper/Constants';
import HoverTextButton from '../../../common/buttons/HoverTextButton';
import WebStore from '../../../../stores/WebStore';
import {observer} from 'mobx-react-lite';

interface Props {
    onClick: () => void,
    siteId: number,
}

const EditMap = observer((props: Props) => (
    <HoverTextButton
        id="edit_map_button"
        icon={WebStore.editingMap ? <Save/> : <Edit/>}
        hoverText={WebStore.editingMap ? Constants.SAVE_CHANGES : Constants.EDIT}
        onClick={props.onClick}
        disabled={props.siteId === -1}
    />
));

export default EditMap;
