/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {
    Dashboard,
    Inventory,
    LocationOn,
    TableChart
} from '@mui/icons-material';
import CommentIcon from '@mui/icons-material/Comment';
import Constants from '../../../helper/Constants';

const GENERAL_DRAWER = [
    {
        id: 'dashboard',
        title: Constants.DASHBOARD,
        icon: <Dashboard/>,
    },
    {
        id: 'storage',
        title: Constants.STORAGE,
        icon: <LocationOn/>,
    },
    {
        id: 'reports',
        title: Constants.REPORTS,
        icon: <TableChart/>,
    },
    {
        id: 'inventory',
        title: Constants.INVENTORY,
        icon: <Inventory/>,
    },
    {
        id: 'notes',
        title: Constants.NOTES,
        icon: <CommentIcon/>,
    },
];

export default GENERAL_DRAWER;
