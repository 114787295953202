/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import BinsenseStore from '../../../stores/BinsenseStore';
import {Button, styled} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';

const Root = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});

interface Props {
    onPayNow: () => void,
    onManageBilling: () => void,
    disablePayNow?: boolean,
    disableManageBilling?: boolean,
    hideManageBilling?: boolean,
}

const BillingInformationPaperButtonRow = (props: Props) => (
    <Root>
        {!props.hideManageBilling &&
            <Button
                {...getTestID('manage_billing_button')}
                color="primary"
                variant="contained"
                onClick={props.onManageBilling}
                disabled={BinsenseStore.awaitingApi || props.disableManageBilling}
                style={{ marginRight: '8px' }}
            >
                Manage Billing
            </Button>
        }
        <Button
            {...getTestID('pay_now_button')}
            color="primary"
            variant="contained"
            onClick={props.onPayNow}
            disabled={BinsenseStore.awaitingApi || props.disablePayNow}
        >
            Pay Now
        </Button>
    </Root>
);

export default BillingInformationPaperButtonRow;
