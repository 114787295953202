/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import DisplayGridHeaderRow from '../../../common/displayTable/DisplayGridHeaderRow';
import DisplayGridSortableTableHeader from '../../../common/displayTable/DisplayGridSortableTableHeader';
import Constants from '../../../../helper/Constants';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import {useSnackbar} from 'notistack';
import {Customer} from '../../../../types/components/ApiTypes';
import CustomerServiceReportsTableRow from './CustomerServiceReportsTableRow';
import NewRecordModal from './NewRecordModal';
import PaginationTable from '../../../common/displayTable/PaginationTable';
import {styled} from '@mui/material';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import deleteServiceRecord from '../../../../controllers/apiCalls/delete/deleteServiceRecord';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

interface Props {
    displayedData: any,
    user: Customer,
}

const CustomerServiceRecordsTable = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const [ edit, setEdit ] = useState<{ id: number | null, note: string, userId: number }>({
        id: null,
        note: '',
        userId: props.user.id,
    });
    const [ deleteId, setDeleteId ] = useState<number | null>(null);

    const onEditClick = (id: number, note: string, userId: number) => {
        setEdit({
            id,
            note,
            userId,
        });
    };

    const onCloseEdit = () => {
        setEdit({
            id: null,
            note: '',
            userId: props.user.id,
        });
    };

    const onDelete = () => {
        deleteServiceRecord(enqueueSnackbar, Number(deleteId));
    };

    const renderSortableHeader = (key: string, label: string, xs: number) => (
        <DisplayGridSortableTableHeader
            id={`${ key }_header`}
            label={label}
            xs={xs}
            data={BinsenseStore.serviceRecordsData}
            setData={BinsenseStore.setServiceRecordsData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderGridLabel = (id: string, label: string, xs: number, header: boolean = false) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={xs}
            header={header}
        />
    );

    const renderRow = (row: any) => (
        <CustomerServiceReportsTableRow
            row={row}
            onEditClick={() => onEditClick(row.id, row.notes, props.user.id)}
            onDeleteClick={() => setDeleteId(row.id)}
            key={`${ row.id }_record`}
        />
    );

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <PaginationTable
            rows={props.displayedData}
            renderRow={renderRow}
        >
            <DisplayGridHeaderRow>
                {renderSortableHeader('notes', Constants.SERVICE_RECORD, 8)}
                {renderSortableHeader('serviceDate', Constants.DATE, 2)}
                {renderGridLabel('actions_header', Constants.ACTIONS, 2, true)}
            </DisplayGridHeaderRow>
            {edit.id !== null && (
                <NewRecordModal
                    open
                    close={onCloseEdit}
                    editingId={edit.id}
                    note={edit.note}
                    userId={edit.userId}
                />
            )}
            {deleteId !== null && (
                <ConfirmationModal
                    id="delete_record_confirmation"
                    open
                    close={() => setDeleteId(null)}
                    submit={onDelete}
                    title="Delete Record"
                    message="Are you sure you want to delete this record?"
                />
            )}
        </PaginationTable>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={600}/>
        </SkeletonRoot>
    );
});

export default CustomerServiceRecordsTable;
