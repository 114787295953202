/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper, styled} from '@mui/material';
import KeyValueTypography from '../../../common/typography/KeyValueTypography';
import SubmitButton from '../../../common/buttons/SubmitButton';
import {useState} from 'react';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import {Customer, Site} from '../../../../types/components/ApiTypes';
import { useSnackbar } from 'notistack';
import getTransferToCustomer from '../../../../controllers/apiCalls/get/getTransferToCustomer';
import Constants from '../../../../helper/Constants';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});

interface Props {
    user: Customer,
    site: Site,
    disable?: boolean,
}

const SiteReportHeaderPaper = (props: Props) => {
    const [ transferred, setTransferred ] = useState(null);
    const [ confirmationModalOpen, setConfirmationModalOpen ] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const submit = () => {
        getTransferToCustomer(enqueueSnackbar, props.site.id, setTransferred);
    };

    return (
        <Paper>
            <Row>
                <KeyValueTypography
                    label="CUSTOMER EMAIL:"
                    value={props.user.account.accountEmail}
                />
                <SubmitButton
                    id="transfer_to_customer_submit"
                    text={transferred ?? props.site.live ? Constants.RESEND_TRANSFER_EMAIL : Constants.TRANSFER_TO_CUSTOMER}
                    onClick={() => setConfirmationModalOpen(true)}
                    disabled={props.disable}
                />
            </Row>
            {confirmationModalOpen && (
                <ConfirmationModal
                    id="transfer_to_customer_confirmation_modal"
                    open
                    close={() => setConfirmationModalOpen(false)}
                    submit={submit}
                    title={Constants.TRANSFER_TO_CUSTOMER}
                    message={Constants.TRANSFER_TO_CUSTOMER_CONFIRM}
                />
            )}
        </Paper>
    );
};

export default SiteReportHeaderPaper;