import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';
import { Site } from '../../../types/components/ApiTypes';

const getUserStructures = (enqueueSnackbar: any, user: string, onlyLiveSites?: boolean) => {
    const customers = BinsenseStore.dealerUserData.customers;
    const userIndex = customers.findIndex(customer => (customer.account.accountEmail === user || customer.account.userName === user));
    const sites: Site[] = BinsenseStore.dealerUserData.customers[userIndex].sites;
    let firstStruct = true;
    sites.forEach((site: Site) => {
        if (site.live === false && onlyLiveSites) {
            return;
        }
        callAPI(`siteStructures/${ site.id }`,
            (response) => {
                const structures = {
                    id: site.id,
                    ...response.structNames ? response : {
                        ...response,
                        structNames: [
                            ...response.bins.map((bin: { name: any; }) => bin.name),
                            ...response.buildings.map((building: { name: any; }) => building.name),
                            ...response.grainBags.map((bag: { name: any; }) => bag.name),
                            // ...response.piles.map((pile: { name: any; }) => pile.name),
                            ...response.automationHubs.map((hub: { name: any; }) => hub.name),
                        ],
                    },
                };
                BinsenseStore.pushUserStructures(structures, firstStruct);
                firstStruct = false;
            },
            enqueueSnackbar,
            true
        );
    });
};

export default getUserStructures;