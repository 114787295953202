/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Chip} from '@mui/material';
import DisplayGridItem from '../../common/displayTable/DisplayGridItem';
import {useMemo} from 'react';
import getTestID from '../../../helper/functions/getTestID';

interface Props {
    id?: string,
    status: string,
    xs: number,
    size?: 'small' | 'medium',
}

const DisplayGridChip = (props: Props) => {
    const renderColor = useMemo(() => {
        if (props.status === 'Online' || props.status === 'On' || props.status === 'Not Urgent') {
            return 'success';
        }
        if (props.status === 'Moderate') {
            return 'primary';
        }
        return 'error';
    }, [ props.status ]);

    return (
        <DisplayGridItem
            xs={props.xs}
        >
            <Chip
                {...getTestID(props.id)}
                label={props.status}
                color={renderColor}
                size={props.size}
            />
        </DisplayGridItem>
    );
};

export default DisplayGridChip;
