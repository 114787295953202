/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {AutomationHubs, Bin, Building, Cable, GrainBag, MasterUnit, RemoteUnit, Site} from '../../types/components/ApiTypes';
import getHardwareType from './getHardwareType';
import Constants from '../Constants';

const getCableCount = (cables: Cable[], type: string) => {
    let count = 0;
    cables.forEach(cable => {
        if (cable.type === type) count++;
    });
    return count;
};

const getDeviceType = (struct: Bin | GrainBag | Building, key: string) => {
    if (key === 'masterUnits') {
        return Constants.MASTER;
    }
    if (key === 'remoteUnits') {
        return Constants.REMOTE;
    }
    return getHardwareType(struct);
};

const getMasterCheckInTime = (unit: MasterUnit | RemoteUnit) => {
    if (unit.hasOwnProperty('checkInTime')) {
        return (unit as MasterUnit).checkInTime;
    }
    return null;
};

const getSoloUnits = (struct: Bin | GrainBag | Building) => struct.hardware.soloUnits.map(unit => ({
    deviceType: getHardwareType(struct),
    serial: unit.serial,
    firstAssociated: unit.status.installDate,
    voltage: unit.status.voltage ? unit.status.voltage : Constants.NA,
    lastVoltage: unit.status.voltageTime,
    lastCable: unit.status.cableTime,
    device: Constants.SOLO,
    status: struct.hardware.soloUnits.length > 0 ? struct.hardware.soloUnits[0].status.reportingStatus : Constants.NA,
    lowBattery: unit.status.batteryLevel && unit.status.batteryLevel < 25,
}));

const getUnits = (struct: Bin | GrainBag | Building, key: string) => struct.hardware[key]
    .map((unit: MasterUnit | RemoteUnit) => ({
        deviceType: getDeviceType(struct, key),
        serial: unit.serial,
        masterSerial: unit.hasOwnProperty('masterSerial') ? (unit as RemoteUnit).masterSerial : undefined,
        firstAssociated: unit.status.firstAssociated,
        voltage: unit.status.acPower ? -1 : unit.status.voltage,
        lastVoltage: unit.status.voltageTime,
        lastCable: unit.status.cableTime,
        device: Constants.LIVE,
        status: unit.status.reportingStatus,
        checkInTime: getMasterCheckInTime(unit),
        lowBattery: unit.status.batteryLevel && unit.status.batteryLevel < 25,
}));

const getBinDevices = (struct: Bin | GrainBag | Building) => ({
    structure: struct.name,
    tempCables: getCableCount(struct.hardware.cables, 'temperature'),
    moistureCables: getCableCount(struct.hardware.cables, 'moisture'),
    devices: [ ...getUnits(struct, 'masterUnits'), ...getUnits(struct, 'remoteUnits'), ...getSoloUnits(struct) ],
    sortOrder: struct.sortOrder,
});

    const collator = new Intl.Collator('en',
    {numeric: true,
        sensitivity: 'base' });

const getAutomationHubUnits = (masterUnits: MasterUnit[]) => masterUnits
    .map((unit: MasterUnit) => ({
        serial: unit.serial,
        device: Constants.AUTOMATION_HUB,
        deviceType: Constants.AUTOMATION_HUB,
        status: unit.status.reportingStatus,
        firstAssociated: unit.status.firstAssociated,
        voltage: unit.status.acPower ? -1 : unit.status.voltage,
        lastVoltage: unit.status.voltageTime,
        lastCable: unit.status.cableTime,
        checkInTime: getMasterCheckInTime(unit),
        lowBattery: unit.status.batteryLevel && unit.status.batteryLevel < 25,
}));

const getAutomationHubs = (struct: AutomationHubs) => ({
    structure: struct.name,
    tempCables: getCableCount(struct.hardware.cables, 'temperature'),
    moistureCables: getCableCount(struct.hardware.cables, 'moisture'),
    devices: [ ...getAutomationHubUnits(struct.hardware.masterUnits) ],
    sortOrder: struct.sortOrder,
});

const getSiteReportData = (site: Site) => {
    let data = [
        ...site.bins.map(bin => getBinDevices(bin)),
        ...site.grainBags.map(bag => getBinDevices(bag)),
        ...site.buildings.map(building => getBinDevices(building)),
        ...site.automationHubs.map(automationHub => getAutomationHubs(automationHub)),
    ];

    let sortIndexAvailable = false;
    data.forEach(obj => {
        if (obj.sortOrder > 0) {
            sortIndexAvailable = true;
        }
    });

    if (sortIndexAvailable) {
        data = data.sort((obj1: any, obj2: any) => {
            const sortOrderModifier = 1;
            const comparison = collator.compare(obj1.sortOrder, obj2.sortOrder);
            return comparison * sortOrderModifier;
        });
    } else {
        data = data.sort((obj1: any, obj2: any) => {
            const sortOrderModifier = 1;
            const comparison = collator.compare(obj1.structure, obj2.structure);
            return comparison * sortOrderModifier;
        });
    }

    return data;
};

export default getSiteReportData;
