/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Skeleton} from '@mui/material';

const TileSkeleton = (() => (
    <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{
            width: '90%',
            maxWidth: 'none',
            height: '150px',
            borderRadius: '12px',
        }}
    />
));

export default TileSkeleton;
