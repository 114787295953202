import callDeleteAPI from '../../callDeleteAPI';
import updateData from '../../../helper/functions/updateData';
import WebStore from '../../../stores/WebStore';
import Constants from '../../../helper/Constants';

const deleteAssociation = (enqueueSnackbar: any, id: number, serial:string, structure?: string) => {
    let value;
    if (structure === Constants.AUTOMATION_HUB) {
        value = 'Automation Hub deleted successfully';
    } else {
        value = 'Association ended successfully';
    }
    callDeleteAPI(`endAssociation/${ id }/${ serial }`,
    (response) => {
        if (response === 'OK') {
            updateData(enqueueSnackbar);
            if (WebStore.currentPage === Constants.VIEW_DEVICE) {
                WebStore.goToPreviousPage();}
            }
        },
        enqueueSnackbar,
        value
        );
    };

export default deleteAssociation;