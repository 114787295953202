import { ServiceRecord } from '../../../types/components/ApiTypes';
import callPutAPI from '../../callPutAPI';
import getServiceRecords from '../get/getServiceRecords';

const putNote = (enqueueSnackbar: any, record: ServiceRecord, status: string) => {
    callPutAPI('serviceRecord',
        record,
        (response) => {
            if (Number(response) > 0)
                getServiceRecords(enqueueSnackbar, Number(response));
        },
        enqueueSnackbar,
        status
    );
};

export default putNote;