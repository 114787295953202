/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Bin, Building, GrainBag, Pile} from '../../types/components/ApiTypes';
import Constants from '../Constants';
import getTimeSince from './getTimeSince';

const getDevices = (struct: Bin | GrainBag | Building | Pile) => {
    const hardware = struct.hardware;
    const masterUnits = hardware?.masterUnits ? hardware.masterUnits : [];
    const remoteUnits = hardware?.remoteUnits ? hardware.remoteUnits : [];
    const soloUnits = hardware?.soloUnits ? hardware.soloUnits : [];
    const units: any[] = [];
    masterUnits.forEach(unit => units.push({
        serial: unit.serial,
        batteryLevel: unit.status.batteryLevel,
        lastCallIn: getTimeSince(unit.status.cableTime),
        acPower: unit.status.acPower,
        type: Constants.MASTER,
    }));
    remoteUnits.forEach(unit => units.push({
        serial: unit.serial,
        masterSerial: unit.masterSerial,
        batteryLevel: unit.status.batteryLevel,
        lastCallIn: getTimeSince(unit.status.cableTime),
        acPower: unit.status.acPower,
        type: Constants.REMOTE,
    }));
    soloUnits.forEach(unit => units.push({
        serial: unit.serial,
        batteryLevel: unit.status.batteryLevel,
        lastCallIn: getTimeSince(unit.status.lastCallIn),
        acPower: false,
        type: Constants.SOLO,
    }));
    return units;
};

export default getDevices;
