/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled, Paper, Skeleton, Grid} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import getPaymentForm from '../../../controllers/apiCalls/get/getPaymentForm';
import { Customer } from '../../../types/components/ApiTypes';
import ExtendDueDatePaper from './ExtendDueDatePaper';
import BinsenseStore from '../../../stores/BinsenseStore';
import MyCustomersWaiveBalancePanel from '../../dealer/myCustomers/MyCustomersWaiveBalancePanel';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

interface Props {
    user?: Customer | null | undefined,
}

const PayNowPanel = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ loading, setLoading ] = React.useState(true);
    const [ html, setHtml ] = React.useState('');

    React.useEffect(() => {
        //check if it's a back button click event, if yes, force reload
        window.onpageshow = function(event) {
            if (event.persisted) {
                window.location.reload();
            }
        };
    });

    React.useEffect(() => {
        getPaymentForm(enqueueSnackbar, setLoading, setHtml, props.user?.id);
    }, []);

    if (loading) {
        return (
            <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{
                    width:'100%',
                    maxWidth: 'none',
                    height: '250px',
                    borderRadius: '12px',
                }}
            />
        );
    }

    return (
        <>
            <Paper>
                <Root>
                    <div
                        className='content'
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </Root>
            </Paper>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={6}
                >
                    {
                        BinsenseStore.dealerUserData.dealerId === 1 && props.user && // dealerId 1 is Intragrain dealers
                        <ExtendDueDatePaper userId={props.user.id}/>
                    }
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    {
                        BinsenseStore.dealerUserData.dealerId === 1 && props.user && // dealerId 1 is Intragrain dealers
                        <MyCustomersWaiveBalancePanel user={props.user}/>
                    }
                </Grid>
            </Grid>
        </>
    );
});

export default PayNowPanel;
