import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';

const getCarouselItems = (enqueueSnackbar: any) => {
    callAPI('carouselItems',
        (response) => {
            BinsenseStore.setCarouselData(response);
        },
        enqueueSnackbar
    );
};

export default getCarouselItems;