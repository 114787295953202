/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useState} from 'react';
import isPersonalDetailsFormValid from '../../../helper/complexValidators/isPersonalDetailsFormValid';
import PersonalDetailsForm from '../../common/personalDetails/PersonalDetailsForm';
import SubmitButton from '../../common/buttons/SubmitButton';
import TitlePaper from '../../common/TitlePaper';
import Constants from '../../../helper/Constants';
import putUserSettings from '../../../controllers/apiCalls/put/putUserSettings';
import {useSnackbar} from 'notistack';
import {Account} from '../../../types/components/ApiTypes';

interface Props {
    userId: number,
    account: Account,
}

const AccountInformationPaper = (props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ firstName, setFirstName ] = useState(props.account?.firstName ? props.account.firstName : '');
    const [ firstNameError, setFirstNameError ] = useState('');
    const [ lastName, setLastName ] = useState(props.account?.lastName ? props.account.lastName : '');
    const [ lastNameError, setLastNameError ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState(props.account?.phoneNumber ? props.account.phoneNumber : '');
    const [ phoneNumberError, setPhoneNumberError ] = useState('');
    const [ email, setEmail ] = useState(props.account?.accountEmail ? props.account.accountEmail : '');
    const [ emailError, setEmailError ] = useState('');

    useEffect(() => {
        if (props.account?.firstName) {
            setFirstName(props.account.firstName);
        }
        if (props.account?.lastName) {
            setLastName(props.account.lastName);
        }
        if (props.account?.phoneNumber) {
            setPhoneNumber(props.account.phoneNumber);
        }
        if (props.account?.accountEmail) {
            setEmail(props.account.accountEmail);
        }
    }, [ props.account ]);

    const isValid = () => isPersonalDetailsFormValid(
            {
                value: firstName,
                setError: setFirstNameError,
            },
            {
                value: lastName,
                setError: setLastNameError,
            },
            {
                value: phoneNumber,
                setError: setPhoneNumberError,
            }, {
                value: email,
                setError: setEmailError,
            });

    const submit = () => {
        if (isValid()) {
            const settings = {
                customer: {
                    account: {
                        firstName,
                        lastName,
                        accountEmail: email,
                        phoneNumber,
                    },
                },
            };
            putUserSettings(enqueueSnackbar, props.userId, settings);
        }
    };

    return (
        <TitlePaper title={Constants.ACCOUNT_INFORMATION}>
            <PersonalDetailsForm
                firstName={{
                    value: firstName,
                    setValue: setFirstName,
                    error: firstNameError,
                    setError: setFirstNameError,
                }}
                lastName={{
                    value: lastName,
                    setValue: setLastName,
                    error: lastNameError,
                    setError: setLastNameError,
                }}
                phoneNumber={{
                    value: phoneNumber,
                    setValue: setPhoneNumber,
                    error: phoneNumberError,
                    setError: setPhoneNumberError,
                }}
                email={{
                    value: email,
                    setValue: setEmail,
                    error: emailError,
                    setError: setEmailError,
                }}
            />
            <SubmitButton
                id="account_information_submit"
                text={Constants.SAVE_CHANGES}
                onClick={submit}
            />
        </TitlePaper>
    );
};

export default AccountInformationPaper;
