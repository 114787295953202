/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import Constants from '../Constants';

const isNumber = (value: number) => value || value === 0;

const getNumberLabel = (value: number, unit: string = '') => isNumber(value) ? `${ value }${ unit }` : Constants.NA;

export default getNumberLabel;
