/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper, styled, Typography} from '@mui/material';
import {BarChart} from '@mui/icons-material';
import BinsenseStore from '../../../stores/BinsenseStore';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import {observer} from 'mobx-react-lite';
import getTestID from '../../../helper/functions/getTestID';
import DashboardStatsDifferenceRow from './DashboardStatsDifferenceRow';

interface Props {
    label: string,
    difference: string,
    value: string,
}

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const DashboardStatsPaper = observer((props: Props) => {
    const id = props.label.replace(/ /g, '_').toLowerCase();

    return !BinsenseStore.awaitingUserData ? (
        <Paper
            elevation={6}
            sx={{
                marginBottom: '0px',
                width: '100%',
                height: '100%',
            }}
        >
            <Row
                style={{
                    height: '100%',
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Root>
                    <Typography
                        {...getTestID(id)}
                        fontSize={15}
                    >
                        {props.label}
                    </Typography>
                    <DashboardStatsDifferenceRow
                        id={id}
                        difference={props.difference}
                    />
                    <Typography
                        {...getTestID(`${ id }_amount`)}
                        fontWeight="bold"
                        fontSize={25}
                    >
                        {props.value}
                    </Typography>
                </Root>
                <BarChart
                    color="primary"
                    sx={{ fontSize: 50 }}
                />
            </Row>
        </Paper>
    ) : (
        <DashboardSkeleton
            height={180}
            style={{ marginLeft: '20px' }}
        />
    );
});

export default DashboardStatsPaper;
