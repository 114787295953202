import callPutAPI from '../../callPutAPI';
import getBinNotes from '../get/getBinNotes';

const putNote = (enqueueSnackbar: any, id: number, note: any, status: string, siteId: number) => {
    callPutAPI(`note/${ id }`,
        note,
        (response) => {
            if (response === 'OK') {
                getBinNotes(enqueueSnackbar, siteId);
            }
        },
        enqueueSnackbar,
        status
    );
};

export default putNote;