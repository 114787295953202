/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import BaseModal from '../common/modal/BaseModal';
import {useState} from 'react';
import TextFieldInput from '../common/input/TextFieldInput';
import getRequestUsername from '../../controllers/apiCalls/get/getRequestUsername';
import { useSnackbar } from 'notistack';

const Container = styled('div')({
    width: '500px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
}

const ForgotUsernameModal = (props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ email, setEmail ] = useState('');
    const [ emailError, setEmailError ] = useState('');

    const isValid = () => {
        if (email === '') {
            setEmailError('Required field.');
            return false;
        }
        return true;
    };

    const onSubmit = () => {
        if (isValid()) {
            getRequestUsername(enqueueSnackbar, email);
            props.close();
        }
    };

    return (
        <BaseModal
            id='forgot_username_modal'
            open
            close={props.close}
            title='Forgot Username?'
            submit={onSubmit}
            alwaysEnabledSubmit
        >
            <Container>
                <Typography
                    sx={{
                        paddingBottom: '16px',
                    }}
                >
                    Enter the email address you used for your Bin-Sense account.
                </Typography>
                <TextFieldInput
                    id="forgot_username_email_address_input"
                    label="Email Address"
                    field={{
                        value: email,
                        setValue: setEmail,
                        error: emailError,
                        setError: setEmailError,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 60,
                        },
                    }}
                />
            </Container>
        </BaseModal>
    );
};

export default ForgotUsernameModal;
