/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Grid, Paper, styled, Typography} from '@mui/material';
import {Opacity, Thermostat} from '@mui/icons-material';
import DashboardWeatherItem from './DashboardWeatherItem';
import BinsenseStore from '../../../stores/BinsenseStore';
import {useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import WebStore from '../../../stores/WebStore';
import Constants from '../../../helper/Constants';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import getSelectedSiteIndex from '../../../helper/functions/getSelectedSiteIndex';
import getTempUnit from '../../../helper/functions/getTempUnit';
import getTestID from '../../../helper/functions/getTestID';
import {useSnackbar} from 'notistack';
import getWeatherData from '../../../controllers/apiCalls/get/getWeatherData';
import TooltipStore from '../../../stores/TooltipStore';
import TooltipMessages from '../../../helper/TooltipMessages';
import useWindowSize from '../../../helper/hooks/useWindowSize';
import AmbientCondtionChart from './AmbientCondtionChart';
import InfoTooltip from '../../common/InfoTooltip';
import {cToF} from '../../../helper/functions/functions';
import moment from 'moment';
import getSiteGraphData from '../../../controllers/apiCalls/get/getSiteGraphData';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const DashboardWeatherPaper = observer(() => {
    const {enqueueSnackbar} = useSnackbar();

    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const weatherItemFontSize = useMemo(() => windowWidth >= Constants.WINDOW_WIDTHS[4] ? 70 : 60,
        [ windowWidth ]);

    const siteName = useMemo(() => {
        const index = getSelectedSiteIndex();
        return index !== -1 ? BinsenseStore.userData.sites[index].name : '';
    }, [ WebStore.selectedSiteId, BinsenseStore.userData ]);

    const weather = useMemo(() => BinsenseStore.weatherData, [ BinsenseStore.weatherData ]);
    const tempUnit = useMemo(getTempUnit,
        [ BinsenseStore.userData.settings.preferences, BinsenseStore.awaitingUserData ]);

    const endTime = new Date().getTime();
    const startTime = endTime - Constants.MILLISECONDS_IN_A_WEEK;
    const [ start, setStart ] = useState<any>(moment((startTime)));
    const [ end, setEnd ] = useState<any>(moment(endTime));
    const [ timeUnit, setTimeUnit ] = useState(Constants.ONE_WEEK);

    const [ callRefresh, setCallRefresh ] = useState(false);
    useEffect(() => {
        if (WebStore.selectedSiteId >= 0 && !callRefresh) {
            getSiteGraphData(enqueueSnackbar, WebStore.selectedSiteId,
                new Date(start).getTime(), new Date(end).getTime());
            setCallRefresh(true);
            setTimeout(() => setCallRefresh(false), 2000);
        }
    }, [ start, end ]);

    useEffect(() => {
        if (WebStore.selectedSiteId !== -1) {
            getWeatherData(enqueueSnackbar, WebStore.selectedSiteId);
        }
    }, [ WebStore.selectedSiteId ]);

    return !BinsenseStore.awaitingUserData ? (
        <Paper
            elevation={6}
            sx={{
                marginBottom: '0px',
                width: '100%',
                height: '100%',
            }}
        >
            <div style={{ width: '100%' }}>
                <Row>
                    <Typography
                        {...getTestID(`${ siteName }_weather`)}
                        fontSize={20}
                        fontWeight="bold"
                        marginRight='4px'
                    >
                        {siteName} Site Conditions
                    </Typography>
                    <InfoTooltip
                        id="weather_tooltip_button"
                        open={TooltipStore.beginTooltips && TooltipStore.showWeatherMessages}
                        message={TooltipMessages.WEATHER}
                        onClick={() => TooltipStore.setShowWeatherMessages(false, true)}
                    />
                </Row>
                <Grid
                    container
                    spacing={2}
                >
                    <DashboardWeatherItem
                        id='temperature_weather_item'
                        label={Constants.TEMPERATURE}
                        icon={<Thermostat style={{ marginLeft: '8px' }}/>}
                        value={weather.currentTemperature !== null
                            ? (tempUnit === 'F' ? cToF(weather.currentTemperature) : weather.currentTemperature)
                            : '- -'}
                        units={weather.currentTemperature !== null ? tempUnit : ''}
                        xs={6}
                        valueFontSize={weatherItemFontSize}
                        noDivider
                    />
                    <DashboardWeatherItem
                        id='humidity_weather_item'
                        label="Humidity"
                        icon={<Opacity style={{ marginLeft: '8px' }}/>}
                        value={weather.humidity !== null ? weather.humidity : '- -'}
                        units={weather.humidity !== null ? '%' : ''}
                        xs={6}
                        valueFontSize={weatherItemFontSize}
                    />
                </Grid>
                <AmbientCondtionChart
                    timeUnit={timeUnit}
                    setTimeUnit={setTimeUnit}
                    start={start}
                    setStart={setStart}
                    end={end}
                    setEnd={setEnd}
                />
            </div>
        </Paper>
    ) : (
        <DashboardSkeleton
            height={600}
            style={{ marginLeft: '20px' }}
        />
    );
});

export default DashboardWeatherPaper;
