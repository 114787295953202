/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import AreaChart from '../../../../common/charts/AreaChart';
import { BinGraphData } from '../../../../../types/components/ApiTypes';
import { cToF } from '../../../../../helper/functions/functions';
import Colors from '../../../../../helper/Colors';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useEffect } from 'react';
import getTempUnit from '../../../../../helper/functions/getTempUnit';
import BinsenseStore from '../../../../../stores/BinsenseStore';

interface Props {
    data: BinGraphData[],
    id: string,
    legend_show: boolean,
    timeUnit?: string,
    setStart: Dispatch<SetStateAction<moment.Moment>>
    setEnd: Dispatch<SetStateAction<moment.Moment>>
    setTimeUnit: Dispatch<SetStateAction<string>>,
    sensorId?: string,
}

const ViewDeviceTempChart = (props: Props) => {

    const tempUnit = useMemo(getTempUnit,
        [ BinsenseStore.userData.settings.preferences, BinsenseStore.awaitingUserData ]);

    const series = [
        {
            name: 'Max',
            type: 'line',
            data: props.data.map(dataPoint => [
                dataPoint.dt,
                tempUnit === 'F' ? cToF(dataPoint.maxtmp) : dataPoint.maxtmp,
            ]),
        },
        {
            name: 'Avg',
            type: 'line',
            data: props.data.map(dataPoint => [
                dataPoint.dt,
                tempUnit === 'F' ? cToF(dataPoint.avgtmp) : dataPoint.avgtmp,
            ]),
        },
        {
            name: 'Min',
            type: 'line',
            data: props.data.map(dataPoint => [
                dataPoint.dt,
                tempUnit === 'F' ? cToF(dataPoint.mintmp) : dataPoint.mintmp,
            ]),
        },
        {
            name: 'Ambient',
            type: 'line',
            data: props.data.map(dataPoint => [
                dataPoint.dt,
                tempUnit === 'F' ? cToF(dataPoint.amb) : dataPoint.amb,
            ]),
        },
    ];

const SensorSeries = [
    {
        name: 'Sensor',
        type: 'line',
        data: props.data.map(dataPoint => [
            dataPoint.dt,
            tempUnit === 'F' ? cToF(dataPoint.temperature) : dataPoint.temperature,
        ]),
    },
    {
        name: 'Ambient',
        type: 'line',
        data: props.data.map(dataPoint => [
            dataPoint.dt,
            tempUnit === 'F' ? cToF(dataPoint.amb) : dataPoint.amb,
        ]),
    },
];

const yaxis: ApexYAxis[] = [
    {
        seriesName: `Temperature (${ tempUnit })`,
        title: {
            text: `Temperature (${ tempUnit })`,
        },
        labels: {
              minWidth: 80,
              maxWidth: 80,
        },
    },
];

    const gstroke : ApexStroke = {};
    gstroke.width = [ 4,3,3,4 ];
    gstroke.curve = [ 'smooth','smooth','smooth','smooth' ];
    gstroke.dashArray = [ 0,0,0,0 ];

    const popt : ApexPlotOptions = {};

    useEffect(() => {
        setTimeout(() => {
            const chart = ApexCharts.getChartByID('tempchart');
            if (chart) {
                chart?.toggleSeries('Ambient');
                if (props.sensorId === '-1') {
                    chart?.toggleSeries('Avg');
                    chart?.toggleSeries('Min');
                }
            }
        }, 0);
    }, []);

    return (
        <AreaChart
            series={(props.sensorId === '-1') ? series : SensorSeries}
            yaxis={yaxis}
            legendPosition="bottom"
            showLegend={props.legend_show}
            id={props.id}
            type="line"
            showXAxis={true}
            height="100%"
            stroke={gstroke}
            colors={[ Colors.maxTempColor, Colors.avgTempColor, Colors.primary, Colors.ambientTempColor ]}
            plotOptions={popt}
            showMenu={true}
            timeUnit={props.timeUnit}
            setTimeUnit={props.setTimeUnit}
            setStart={props.setStart}
            setEnd={props.setEnd}
            sensorId = {props.sensorId}
        />
    );
};

export default ViewDeviceTempChart;
