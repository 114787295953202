/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Divider, Paper, styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {useEffect, useMemo} from 'react';
import getTempUnit from '../../../../../helper/functions/getTempUnit';
import BinsenseStore from '../../../../../stores/BinsenseStore';
import DeviceConditionItem from './DeviceConditionItem';
import TooltipStore from '../../../../../stores/TooltipStore';
import TooltipMessages from '../../../../../helper/TooltipMessages';
import DashboardSkeleton from '../../../../common/DashboardSkeleton';
import InfoTooltip from '../../../../common/InfoTooltip';
import { displayTemp } from '../../../../../helper/functions/functions';
import getWeatherData from '../../../../../controllers/apiCalls/get/getWeatherData';
import getGrain from '../../../../../helper/functions/getGrain';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '100%',
});

const TitleRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '16px',
});

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
});

const HEADER_FONT_SIZE = 14;

interface Props {
    siteId: number,
    bin: any,
}

const DeviceAmbientConditionStationPaper = observer((props: Props) => {
    const tempUnit = useMemo(getTempUnit, [ BinsenseStore.userData.settings.preferences ]);
    const weather = useMemo(() => BinsenseStore.weatherData, [ BinsenseStore.weatherData ]);

    useEffect(() => {
        if (props.siteId !== -1) {
            getWeatherData(null, props.siteId);
        }
    }, [ props.siteId ]);

    const getWeatherItem = (value: string, label: string, id: string, label2?: string) => (
        <DeviceConditionItem
            id={`weather_condition_${ id }`}
            value={value}
            label={label}
            label2={label2}
        />
    );

    return !BinsenseStore.awaitingUserData ? (
        <Paper
            style={{
                height: '100%',
                width: '100%',
            }}
        >
            <Column>
                <TitleRow>
                    <Typography fontSize={HEADER_FONT_SIZE}>
                        Site Conditions
                    </Typography>
                    <InfoTooltip
                        id="ambient_tooltip_button"
                        open={TooltipStore.beginTooltips && !TooltipStore.showInventoryMessages &&
                        TooltipStore.showAmbientMessages}
                        message={TooltipMessages.AMBIENT}
                        onClick={() => TooltipStore.setShowAmbientMessages(false, true)}
                    />
                </TitleRow>
                <Row>
                    {/* eslint-disable-next-line */}
                    {getWeatherItem(weather.currentTemperature
                        ? ` ${ displayTemp( tempUnit === 'F', weather.currentTemperature) } \u00b0${ tempUnit }`
                        : '- -', 'Temperature', 'temp')}
                    <Divider
                        orientation="vertical"
                        flexItem
                    />
                    {getWeatherItem(weather.humidity ? String(weather.humidity).concat(' %') : '- -', 'Relative Humidity', 'rh')}
                    <Divider
                        orientation="vertical"
                        flexItem
                    />
                    {getWeatherItem(props.bin.status?.emc
                        ? String(props.bin.status.emc).concat(' %')
                        : '- -', 'EMC', 'emc', getGrain(props.bin.settings.productId).name)}
                </Row>
            </Column>
        </Paper>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={160}/>
        </SkeletonRoot>
    );
});

export default DeviceAmbientConditionStationPaper;
