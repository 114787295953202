/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {createTheme, ThemeProvider} from '@mui/material/styles';
import {observer} from 'mobx-react-lite';
import {SnackbarProvider} from 'notistack';
import Colors from './helper/Colors';
import WebStore from './stores/WebStore';
import CssBaseline from '@mui/material/CssBaseline';
import {darkScrollbar} from '@mui/material';
import MainCustomer from './components/customer/main/MainCustomer';
import MainDealer from './components/dealer/main/MainDealer';
import Constants from './helper/Constants';
import LoginStore from './stores/LoginStore';
import LoginScreen from './components/login/LoginScreen';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';

const themeColors = {
    palette: {
        primary: {
            main: Colors.primary,
            background: Colors.background,
            drawerBackground: Colors.background,
            drawerUserBackground: Colors.drawerUserBackgroundLight,
            titleText: Colors.textTitle,
            boldText: Colors.textBold,
            paper: '#FFFFFF',
            modalTitle: Colors.modalTitleLightMode,
            toggleButtonLabel: 'rgba(0,0,0, 0.6)',
        },
        secondary: {
            main: Colors.accent,
        },
    },
    typography: {
        fontFamily: [ 'Public Sans' ].join(','),
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Public Sans',
                    color: Colors.text,
                    fontSize: '16px',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: Colors.paperBorderRadius,
                },
                popper: {
                    color: 'white',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    paddingTop: '25px',
                    paddingRight: '20px',
                    paddingBottom: '25px',
                    paddingLeft: '20px',
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginBottom: '18px',
                    borderRadius: Colors.paperBorderRadius,
                    boxShadow: Colors.boxShadowLight,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: 'Public Sans',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                },
                containedPrimary: {
                    boxShadow: Colors.boxShadowYellow,
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: 'Public Sans',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingBottom: '12px',
                    paddingTop: '12px',
                    '&.Mui-selected': {
                        fontWeight: 800,
                        borderRadius: '10px',
                        '&:hover': {
                            backgroundColor: 'rgba(255,224,0,0.5)',
                        },
                    },
                    '&:hover': {
                        backgroundColor: '#f2f3f5',
                        borderRadius: '10px',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: '10px',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: '10px',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: darkScrollbar({
                    track: Colors.background,
                    thumb: '#d3d5d7',
                    active: '#aeb0b3',
                }),
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 1250,
                md: 1353,
                lg: 1550,
                xl: 1765,
            },
        },
    },
};

const darkThemeColors = {
    palette: {
        primary: {
            main: Colors.primaryDark,
            background: Colors.backgroundDark,
            drawerBackground: Colors.backgroundDark,
            drawerUserBackground: Colors.drawerUserBackgroundDark,
            titleText: Colors.textTitleLight,
            boldText: Colors.textBoldLight,
            paper: Colors.paperDarkMode,
            modalTitle: '#1e1e1e',
            toggleButtonLabel: 'rgba(255, 255, 255, 0.7)',
        },
        secondary: {
            main: '#FFFFFF',
        },
        mode: 'dark',
    },
    typography: {
        fontFamily: [ 'Public Sans' ].join(','),
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    color: Colors.textLight,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: Colors.paperBorderRadius,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    paddingTop: '25px',
                    paddingRight: '20px',
                    paddingBottom: '25px',
                    paddingLeft: '20px',
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginBottom: '18px',
                    borderRadius: Colors.paperBorderRadius,
                    boxShadow: Colors.boxShadowDark,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: 'Public Sans',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                },
                containedPrimary: {
                    boxShadow: Colors.boxShadowYellow,
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: 'Public Sans',
                    borderRadius: '8px',
                    fontWeight: 'bold',
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingBottom: '12px',
                    paddingTop: '12px',
                    '&.Mui-selected': {
                        fontWeight: 800,
                        borderRadius: '10px',
                        '&:hover': {
                            backgroundColor: 'rgba(255,224,0,0.5)',
                        },
                    },
                    '&:hover': {
                        backgroundColor: '#19212a',
                        borderRadius: '10px',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: '10px',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: '10px',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: darkScrollbar({
                    track: Colors.backgroundDark,
                    thumb: '#6b6b6b',
                    active: '#959595',
                }),
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 1250,
                md: 1353,
                lg: 1550,
                xl: 1765,
            },
        },
    },
};

// @ts-ignore
const theme = createTheme(themeColors);
// @ts-ignore
const darkTheme = createTheme(darkThemeColors);

const App = observer(() => (
    <ThemeProvider theme={WebStore.lightMode ? theme : darkTheme}>

        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <ErrorBoundary>
                <CssBaseline/>
                {!LoginStore.loggedIn && <LoginScreen/>}
                {LoginStore.loggedIn && WebStore.selectedView === Constants.CUSTOMER && <MainCustomer/>}
                {LoginStore.loggedIn && WebStore.selectedView === Constants.DEALER && <MainDealer/>}
            </ErrorBoundary>
        </SnackbarProvider>
    </ThemeProvider>
));

declare global {
    interface Window {resetMouseClickedForSensor: () => void;}
}

export default App;
