/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Grid} from '@mui/material';
import ContentPanel from '../../common/ContentPanel';
import {observer} from 'mobx-react-lite';
import BillingInformationPaper from '../billingAndPayment/BillingInformationPaper';
import BillingAddressPaper from '../billingAndPayment/BillingAddressPaper';
import BinsenseStore from '../../../stores/BinsenseStore';
import { useEffect, useState } from 'react';
import UIStateStore from '../../../stores/UIStateStore';
import WebStore from '../../../stores/WebStore';
import { useSnackbar } from 'notistack';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import Constants from '../../../helper/Constants';
import { getBalance } from '../../../controllers/apiCalls/get/getBalance';

const BillingAndPayment = observer(() => {
    const renderGridItem = (paper: object) => (
        <Grid
            item
            xs={6}
        >
            {paper}
        </Grid>
    );

    const {enqueueSnackbar} = useSnackbar();
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        // check if it's a redirect from Stripe or if there is any change in associations that may impact user balance
        // the updateData() function was called right after a new association is added, but that may happened before the balance has been updated.
        // so get the new balance here again
        if (UIStateStore.redirectedToStripe || WebStore.associationsChanged) {
            setTimeout(() => {
                getBalance(enqueueSnackbar, WebStore.selectedView.toLowerCase() === Constants.DEALER.toLowerCase()
                    ? WebStore.selectedCustomer.id : BinsenseStore.userData.id, setLoading, true, false);
            }, 7000);
        }
        else
        {
            setLoading(false);
        }
    }, []);

    return (
        <ContentPanel>
            <Grid
                container
                spacing={2}
            >
                {!BinsenseStore.awaitingApi && !BinsenseStore.awaitingUserData && !loading ? (
                    <>
                        {renderGridItem(<BillingInformationPaper/>)}
                        {renderGridItem(<BillingAddressPaper/>)}
                    </>
                ) : (
                    <>
                        {renderGridItem(<DashboardSkeleton height={350} />)}
                        {renderGridItem(<DashboardSkeleton height={350} />)}
                    </>
                )}
            </Grid>
        </ContentPanel>
    );
});

export default BillingAndPayment;
