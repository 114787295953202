/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import Chart from 'react-apexcharts';
import {useEffect, useMemo, useState} from 'react';
import WebStore from '../../../stores/WebStore';
import {Theme} from '../../../types/components/ApiTypes';
import {Skeleton} from '@mui/material';
import {ApexOptions} from 'apexcharts';
import Colors from '../../../helper/Colors';

interface Props {
    title?: string,
    series: number[],
    labels: string[],
}

const DonutChart = observer((props: Props) => {
    const total = useMemo(() => {
        let sum = 0;
        props.series.forEach(num => sum += num);
        return sum;
    }, [ props.series ]);

    const [ loading, setLoading ] = useState(true);
    const [ options, setOptions ] = useState<ApexOptions>({
        chart: {
            height: '100%',
            background: WebStore.lightMode ? '#fff' : '#1e1e1e',
        },
        labels: props.labels,
        title: {
            text: props.title,
            margin: 20,
            style: {
                fontSize: '20px',
                fontWeight: 'bold',
                fontFamily: 'Public Sans',
                color: WebStore.lightMode ? Colors.text : Colors.textLight,
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '85%',
                    labels: {
                        show: true,
                    },
                },
            },
        },
        tooltip: {
            enabled: true,
            y: {
                formatter (val: any) {
                    const result = (val / total) * 100;
                    return `${ result.toFixed(0) }%`;
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            position: 'bottom',
            fontFamily: 'Public Sans',
        },
        theme: {
            mode: WebStore.lightMode ? Theme.light : Theme.dark,
        },
    });

    useEffect(() => {
        setTimeout(() => setLoading(false), 100);
    }, []);

    useEffect(() => {
        setLoading(true);
        setOptions({
            ...options,
            chart: {
                ...options.chart,
                background: WebStore.lightMode ? '#fff' : '#1e1e1e',
            },
            labels: props.labels,
            title: {
                ...options.title,
                style: {
                    ...options.title?.style,
                    color: WebStore.lightMode ? Colors.text : Colors.textLight,
                },
            },
            theme: {
                ...options.theme,
                mode: WebStore.lightMode ? Theme.light : Theme.dark,
            },
            tooltip: {
                ...options.tooltip,
                y: {
                    formatter (val: any) {
                        const result = (val / total) * 100;
                        return `${ result.toFixed(0) }%`;
                    },
                },
            },
        });
        setTimeout(() => setLoading(false), 100);
    }, [ WebStore.lightMode, props.labels, total ]);

    if (loading) {
        return (
            <div>
                <Skeleton
                    variant="circular"
                    width={100}
                    height={100}
                    sx={{margin: 'auto'}}
                />
            </div>
        );
    }

    return (
        <div id="chart">
            <Chart
                options={options}
                series={props.series}
                type="donut"
                width="100%"
            />
        </div>
    );
});

export default DonutChart;
