/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Button, Grid, MenuItem, Typography, styled} from '@mui/material';
import SiteReportTable from './SiteReportTable';
import DevicesReportedPaper from './DevicesReportedPaper';
import SiteReportingProgressPaper from '../../../dealer/myCustomers/siteReportPage/SiteReportingProgressPaper';
import {observer} from 'mobx-react-lite';
import {
    Bin,
    Building,
    GrainBag,
    MasterUnit,
    RemoteUnit,
    Site,
    SoloUnit
} from '../../../../types/components/ApiTypes';
import {useEffect, useMemo, useRef, useState} from 'react';
import getTestID from '../../../../helper/functions/getTestID';
import EditBinOrderModal from '../../sortOrder/EditBinOrderModal';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import BinsenseStore from '../../../../stores/BinsenseStore';
import Constants from '../../../../helper/Constants';
import {Download} from '@mui/icons-material';
import exportTableToCSV from '../../../../helper/functions/exportTableToCSV';
import getTimeSince from '../../../../helper/functions/getTimeSince';
import getDateString from '../../../../helper/functions/getDateString';
import PopupMenu from '../../../common/PopupMenu';
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import useWindowSize from '../../../../helper/hooks/useWindowSize';
import getSiteCommunicationData from '../../../../controllers/apiCalls/get/getSiteCommunicationData';
import {useSnackbar} from 'notistack';
import CommunicationSummaryTable from '../CommunicationSummaryTable';
import WebStore from '../../../../stores/WebStore';

interface Props {
    site: Site,
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
});

const SiteReportPage = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const windowSize = useWindowSize();
    const windowHeight = useMemo(() => windowSize[1], [ windowSize ]);

    const [ editBinOrderModalOpen, setEditBinOrderModalOpen ] = useState(false);

    const ref = useRef<any>(null);
    const [ exportPDFClicked, setExportPDFClicked ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (props.site.id > 0) {
            getSiteCommunicationData(enqueueSnackbar, props.site.id);
        }
    }, [ props.site ]);

    const devicesAddToTotals = (unit: MasterUnit | RemoteUnit | SoloUnit, totals: number[]) => {
        totals[1] = totals[1] + 1;
        //@ts-ignore
        if (unit.status?.reportingStatus && unit.status.reportingStatus === 'Online') {
            totals[0] = totals[0] + 1;
        }
    };

    const structuresAddToTotals = (struct: Bin | GrainBag | Building, totals: number[]) => {
        struct.hardware.masterUnits.forEach(unit => devicesAddToTotals(unit, totals));
        struct.hardware.remoteUnits.forEach(unit => devicesAddToTotals(unit, totals));
        struct.hardware.soloUnits.forEach(unit => devicesAddToTotals(unit, totals));
    };

    const reportedAndTotal = useMemo(() => {
        const reportedAndTotalArray = [ 0, 0 ];
        props.site.bins.forEach(bin => structuresAddToTotals(bin, reportedAndTotalArray));
        props.site.grainBags.forEach(bag => structuresAddToTotals(bag, reportedAndTotalArray));
        props.site.buildings.forEach(building => structuresAddToTotals(building, reportedAndTotalArray));
        return reportedAndTotalArray;
    }, [ props.site ]);

    const columns = [
        {
            field: 'structure',
            headerName: Constants.STRUCTURE,
        },
        {
            field: 'type',
            headerName: Constants.TYPE,
        },
        {
            field: 'serial',
            headerName: Constants.SERIAL,
        },
        {
            field: 'installDate',
            headerName: Constants.INSTALL_DATE,
        },
        {
            field: 'voltage',
            headerName: Constants.VOLTAGE,
        },
        {
            field: 'lastVoltage',
            headerName: Constants.LAST_VOLTAGE,
        },
        {
            field: 'lastCable',
            headerName: Constants.LAST_CABLE,
        },
        {
            field: 'tempCables',
            headerName: Constants.TEMP_CABLES,
        },
        {
            field: 'moistCables',
            headerName: Constants.MOIST_CABLES,
        },
        {
            field: 'device',
            headerName: Constants.DEVICE,
        },
        {
            field: 'status',
            headerName: Constants.STATUS,
        },
    ];

    const rows = useMemo(() => {
        const rowArray: any[] = [];
        BinsenseStore.customerReportsData.forEach(row => {
            if (row.devices.length === 0) {
                rowArray.push({
                    structure: row.structure,
                    type: '--',
                    serial: '--',
                    installDate: '--',
                    voltage: '--',
                    lastVoltage: '--',
                    lastCable: '--',
                    tempCables: row.tempCables,
                    moistCables: row.moistureCables,
                    device: '--',
                    status: '--',
                });
            } else {
                row.devices.forEach(device => {
                    rowArray.push({
                        structure: row.structure,
                        type: device.deviceType,
                        serial: device.serial,
                        installDate: device.firstAssociated,
                        voltage: device.voltage,
                        lastVoltage: getTimeSince(Number(device.lastVoltage)) === Constants.NA ? '--'
                            : getDateString(new Date(Number(device.lastVoltage)), true),
                        lastCable: getTimeSince(Number(device.lastCable)) === Constants.NA ? '--'
                            : getDateString(new Date(Number(device.lastCable)), true),
                        tempCables: row.tempCables,
                        moistCables: row.moistureCables,
                        device: device.device,
                        status: device.status,
                    });
                });
            }
        });
        return rowArray;
    }, [ BinsenseStore.customerReportsData ]);

    const renderGridItem = (item: object, xs: number) => (
        <Grid
            item
            xs={xs}
        >
            {item}
        </Grid>
    );

    const handlePdfDownload = async () => {
        const element = ref.current;
        const canvas = await html2canvas(element);
        const dataURL = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(dataURL);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(dataURL, 'PNG', 0, 0, pdfWidth, pdfHeight);
        if (WebStore.selectedView === Constants.DEALER) {
            pdf.save('communicationTable.pdf');
        } else {
            pdf.save('siteReport.pdf');
        }
    };

    const handlePdfClick = () => {
        setExportPDFClicked(true);
        setTimeout(() => {
            handlePdfDownload();
            setExportPDFClicked(false);
        }, 1000);
    };

    const handleExportClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleExportClose = () => {
        setAnchorEl(null);
    };

    const disableEditOrderButton = props.site.id > 0 ? false : true;

    const renderButton = () => (
        <Grid
            container
            justifyContent='right'
        >
            {!BinsenseStore.awaitingApi && !BinsenseStore.awaitingUserData ? (
                <Row>
                    <div>
                        <Button
                            {...getTestID('site_report_export_button')}
                            onClick={handleExportClick}
                            variant='contained'
                            startIcon={<Download/>}
                            disabled={rows.length === 0}
                        >
                            {Constants.EXPORT}
                        </Button>
                        <PopupMenu
                            anchorEl={anchorEl}
                            open={open}
                            handleClose={handleExportClose}
                        >
                            <MenuItem
                                {...getTestID('export_to_csv_button')}
                                onClick={() => exportTableToCSV(columns, rows, 'site_report')}
                            >
                                {Constants.EXPORT_TO_CSV}
                            </MenuItem>
                            <MenuItem
                                {...getTestID('export_to_pdf_button')}
                                onClick={handlePdfClick}
                            >
                                {Constants.EXPORT_TO_PDF}
                            </MenuItem>
                        </PopupMenu>
                    </div>
                    <Button
                        {...getTestID('edit_bin_order_button')}
                        onClick={() => setEditBinOrderModalOpen(true)}
                        variant='contained'
                        style={{
                            marginRight: '20px',
                            marginLeft: '8px',
                        }}
                        disabled={disableEditOrderButton}
                    >
                        {Constants.EDIT_ORDER}
                    </Button>
                </Row>
            ) : (
                <DashboardSkeleton
                    height={40}
                    width='300px'
                    style={{
                        marginRight: '20px',
                        marginTop: '16px',
                    }}
                />
            )}
        </Grid>
    );

    return !exportPDFClicked ? (
        <Grid
            container
            spacing={0}
            alignItems="stretch"
        >
            {renderGridItem(
                <DevicesReportedPaper
                    reported={reportedAndTotal[0]}
                    total={reportedAndTotal[1]}
                />, 6)}
            {renderGridItem(<SiteReportingProgressPaper totals={reportedAndTotal}/>, 6)}
            {renderButton()}
            {renderGridItem(<SiteReportTable site={props.site}/>, 12)}
            {editBinOrderModalOpen && (
                <EditBinOrderModal
                    close={() => {
                        setEditBinOrderModalOpen(false);
                    }}
                    site={props.site}
                />
            )}
        </Grid>
    ) : (
        <div
            style={{ width: '100%' }}
        >
            <DashboardSkeleton height={windowHeight}/>
            <div
                ref={ref}
                style={{ paddingTop: '20px' }}
            >
                <Typography
                    color='primary'
                    padding='20px'
                >
                    {Constants.SITE_REPORT}
                </Typography>
                <SiteReportTable
                    site={props.site}
                    displayAll
                />
                { WebStore.selectedView === Constants.DEALER &&
                    <>
                        <Typography
                            color='primary'
                            padding='20px'
                        >
                            {Constants.COMMUNICATION_SUMMARY}
                        </Typography>
                        <CommunicationSummaryTable isCable={true}/>
                    </>
                }
            </div>
        </div>
    );
});

export default SiteReportPage;
