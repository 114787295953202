/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {AddBox, RequestQuote, Tune} from '@mui/icons-material';
import Constants from '../../../helper/Constants';

const rotate = {
    'WebkitTransform': 'rotate(90deg)',
    'MozTransform': 'rotate(90deg)',
    'MsTransform': 'rotate(90deg)',
    'OTransform': 'rotate(90deg)',
    transform: 'rotate(90deg)',
};

const ManagementDealerDrawer = [
    {
        id: 'add_new',
        title: Constants.ADD_NEW,
        icon: <AddBox/>,
    },
    {
        id: 'quotes',
        title: Constants.QUOTES,
        icon: <RequestQuote/>,
    },
    {
        id: 'preferences',
        title: Constants.PREFERENCES,
        icon: <Tune sx={rotate}/>,
    },
];

export default ManagementDealerDrawer;
