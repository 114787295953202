/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useMemo, useState} from 'react';
import {Paper, Skeleton, styled} from '@mui/material';
import {Customer, MarkerData, Site} from '../../../../types/components/ApiTypes';
import getDistancePerPixel from '../../../../helper/functions/getDistancePerPixel';
import CreateSiteMap from '../../../dealer/createSite/CreateSiteMap';
import cloneDeep from 'lodash/cloneDeep';
import {observer} from 'mobx-react-lite';
import WebStore from '../../../../stores/WebStore';
import getMarkerData from '../../../../helper/functions/getMarkerData';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import changeDrawerAfterConfirmation from '../../../../helper/functions/changeDrawerAfterConfirmation';
import StorageMapHeader from './StorageMapHeader';
import goToViewDevicePage from '../../../../helper/functions/goToViewDevicePage';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import useWindowSize from '../../../../helper/hooks/useWindowSize';

interface Props {
    site: Site,
    search: string,
    user?: Customer,
}

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const StorageMap = observer((props: Props) => {
    const windowSize = useWindowSize();
    const height = useMemo(() => windowSize[1] - 325, [ windowSize ]);

    const [ reset, setReset ] = useState(false);
    const [ viewState, setViewState ] = useState({
        longitude: props.site.longitude,
        latitude: props.site.latitude,
        zoom: 17,
    });
    const [ markerData, setMarkerData ] = useState<MarkerData[]>([]);
    const [ deletedStructureArray, setDeletedStructureArray ] = useState<number[]>([]);

    useEffect(() => setMarkerData(getMarkerData(props.site, props.search)), [ props.site, props.search ]);

    useEffect(() => {
        setViewState({
            longitude: props.site.longitude,
            latitude: props.site.latitude,
            zoom: 17,
        });
        setReset(true);
    }, [ props.site, BinsenseStore.awaitingUserData ]);

    useEffect(() => setReset(true), [ height ]);

    // const [ features, setFeatures ] = useState({});
    //
    // const onFeaturesUpdate = useCallback(e => {
    //     setFeatures(currFeatures => {
    //         const newFeatures = {...currFeatures};
    //         for (const f of e.features) {
    //             newFeatures[f.id] = f;
    //         }
    //         return newFeatures;
    //     });
    // }, []);
    //
    // const onFeaturesDelete = useCallback(e => {
    //     setFeatures(currFeatures => {
    //         const newFeatures = {...currFeatures};
    //         for (const f of e.features) {
    //             delete newFeatures[f.id];
    //         }
    //         return newFeatures;
    //     });
    // }, []);

    useEffect(() => {
        setMarkerData(prevState => {
            const newMarkerData = cloneDeep(prevState);
            for (let i = 0; i < newMarkerData.length; i += 1) {
                if (newMarkerData[i].tooltip !== undefined) {
                    // @ts-ignore
                    newMarkerData[i].tooltip.editable = WebStore.editingMap;
                }

                newMarkerData[i].draggable = WebStore.editingMap;
            }
            return newMarkerData;
        });

    }, [ WebStore.editingMap ]);

    const distancePerPixel = useMemo(() => getDistancePerPixel(viewState.latitude, viewState.zoom),
        [ viewState.zoom, viewState.latitude ]);

    const onMarkerClick = (id: number) => {
        if (!WebStore.editingMap) {
            goToViewDevicePage(id);
        }
    };

    const confirmationSubmit = () => {
        WebStore.setEditingMap(false);
        changeDrawerAfterConfirmation();
    };

    const component = useMemo(() => {
        if (reset) {
            setTimeout(() => setReset(false), 50);
            return (
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={height}
                />
            );
        }
        return (
            <CreateSiteMap
                viewState={viewState}
                setViewState={setViewState}
                markerData={markerData}
                setMarkerData={setMarkerData}
                toggleOnBottom
                distancePerPixel={distancePerPixel}
                onMarkerClick={onMarkerClick}
                // features={features}
                // onFeaturesDelete={onFeaturesDelete}
                // onFeaturesUpdate={onFeaturesUpdate}
                siteId={props.site.id}
                deletedStructureArray={deletedStructureArray}
                setDeletedStructureArray={setDeletedStructureArray}
                height={height > 500 ? height : 500}
            />
        );
    }, [ reset, viewState, markerData, distancePerPixel ]);

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <Root>
            <Paper style={{ width: '100%' }}>
                <div style={{ width: '100%'}}>
                    <StorageMapHeader
                        markerData={markerData}
                        setMarkerData={setMarkerData}
                        viewState={viewState}
                        site={props.site}
                        user={props.user}
                        deletedStructureArray={deletedStructureArray}
                        setDeletedStructureArray={setDeletedStructureArray}
                    />

                    {component}

                </div>
                {WebStore.isLoseChangesConfirmationOpen && (
                    <ConfirmationModal
                        id="exit_storage_map_confirmation"
                        open
                        close={() => WebStore.setIsLoseChangesConfirmationOpen(false)}
                        title="Warning"
                        submit={confirmationSubmit}
                        message="Are you sure you want to exit the Storage Map page? All unsaved changes will be lost."
                    />
                )}
            </Paper>
        </Root>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={700}/>
        </SkeletonRoot>
    );
});

export default StorageMap;
