import { useState } from 'react';
import BaseModal from '../../common/modal/BaseModal';
import { Checkbox, Typography, styled } from '@mui/material';
import isNumeric from '../../../helper/validator/isNumeric';
import TextFieldInput from '../../common/input/TextFieldInput';
import Constants from '../../../helper/Constants';
import BinsenseStore from '../../../stores/BinsenseStore';
import postDealerNotification from '../../../controllers/apiCalls/post/postDealerNotification';
import { useSnackbar } from 'notistack';
import SelectFieldInput from '../../common/input/SelectFieldInput';

interface Props {
    open: boolean,
    close: () => void,
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const Container = styled('div')({
    width: '900px',
    padding: '16px',
});

const AddDealerNotificationModal = (props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ phoneNumberError, setPhoneNumberError ] = useState('');
    const [ sendMasterTexts, setSendMasterTexts ] = useState(false);
    const [ sendSystemAlerts, setSendSystemAlerts ] = useState(false);
    const [ sendVoltageAlerts, setSendVoltageAlerts ] = useState(false);
    const [ optedIn, setOptedIn ] = useState(false);
    const [ optedInError, setOptedInError ] = useState(false);

    const countryList = BinsenseStore.dataModel.countries;

    const getCountryOptions = () => {
        if (countryList.length > 0) {
            const options: any[] = [];
            countryList.forEach(country => {
                if (options.length === 0 ||
                        !options.some((item: { value: string; }) => item.value === country.phoneCountryCode)) {
                    options.push({
                        id: country.id.toString(),
                        value: country.phoneCountryCode.toString(),
                        title: country.phoneCountryCode.toString(),
                    });
                }
            });
            return options.sort((a, b) => Number(a.value) - Number(b.value));
        } else {
            return [];
        }
    };

    const countryOptions = getCountryOptions();
    const [ selectedCountryCode, setSelectedCountryCode ] = useState('1');

    const isValid = () => {
        let newPhoneNumberError = '';
        BinsenseStore.dealerNotifications.forEach(d => {
            if (d.phoneNumber === phoneNumber) {
                newPhoneNumberError = Constants.DEALER_NOTIFICATION_DUPLICATE_MESSAGE;
            }
        });

        if (newPhoneNumberError === '') {
            newPhoneNumberError = isNumeric(phoneNumber, 10, '10-digit phone number', 10);
            if (newPhoneNumberError !== '') {
                setPhoneNumberError(newPhoneNumberError);
            }
        } else {
            setPhoneNumberError(newPhoneNumberError);
        }
        if (!optedIn) {
            setOptedInError(true);
        } else {
            setOptedInError(false);
        }
        return newPhoneNumberError === '' && optedIn;
    };

    const submit = () => {
        if (isValid()) {
            const dealerNotification = {
                dealerId: BinsenseStore.dealerUserData.dealerId,
                contactMethod: 'text',
                phoneNumber: selectedCountryCode + phoneNumber,
                sendMasterTexts,
                sendSystemAlerts,
                sendVoltageAlerts,
                optedIn,
            };
            postDealerNotification(enqueueSnackbar, dealerNotification, 'Notification added successfully');
            props.close();
        }
    };

    return (
        <BaseModal
            id='add_user_modal'
            open
            close={props.close}
            submit={submit}
            title='Add Contact'
        >
            <Container>
                <Row
                    style={{
                        width:'35%',
                        paddingTop:0,
                        marginTop:0,
                    }}
                >
                    <div style={{ height:'100px' }} >
                        <SelectFieldInput
                            id='country_code'
                            label=''
                            field={{
                                value: selectedCountryCode,
                                setValue: setSelectedCountryCode,
                            }}
                            options={countryOptions}
                            width='75px'
                        />
                    </div>
                    <div style={{ height:'100px' }}>
                        <TextFieldInput
                            id='phone_number'
                            label='Phone number'
                            field={{
                                value: phoneNumber,
                                setValue: setPhoneNumber,
                                error: phoneNumberError,
                                setError: setPhoneNumberError,
                            }}
                            textFieldProps={{
                                inputProps: {
                                    maxLength: 10,
                                },
                                type: 'tel',
                            }}
                        />
                    </div>
                </Row>
                <Row>
                    Notifications:
                </Row>
                <Row>
                    <Checkbox
                        checked={sendMasterTexts}
                        onClick={() => setSendMasterTexts(!sendMasterTexts)}
                    />
                    Association Alerts
                </Row>
                <Row>
                    <Checkbox
                        checked={sendSystemAlerts}
                        onClick={() => setSendSystemAlerts(!sendSystemAlerts)}
                    />
                    System Temperture Threshold
                </Row>
                <Row>
                    <Checkbox
                        checked={sendVoltageAlerts}
                        onClick={() => setSendVoltageAlerts(!sendVoltageAlerts)}
                    />
                    Voltage Alerts
                </Row>
                <Row style={{paddingTop:'20px'}}>
                    <Checkbox
                        checked={optedIn}
                        onClick={() => setOptedIn(!optedIn)}
                    />
                    <Typography fontSize={13}>
                        {Constants.DEALER_NOTIFICATION_OPT_IN}
                    </Typography>
                </Row>
                <Row>
                    {optedInError &&
                        <Typography
                            fontSize={13}
                            color='red'
                        >
                            {Constants.DEALER_NOTIFICATION_OPT_IN_ERROR}
                        </Typography>
                    }
                </Row>
            </Container>
        </BaseModal>
    );
};

export default AddDealerNotificationModal;
