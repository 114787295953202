import callPutAPI from '../../callPutAPI';

const putForgotPasswordReset = (enqueueSnackbar: any, body: any, message: any, setSuccess?: any) => {
    callPutAPI('password/forgot',
        body,
        (response) => {
            if (response === 'OK') {
                if (setSuccess)
                setSuccess(true);
            }
        },
        enqueueSnackbar,
        message
    );
};

export default putForgotPasswordReset;