/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {TableCSVColumn} from '../../types/components/ApiTypes';
import Constants from '../Constants';
import getDateString from './getDateString';

const determineStringFromRow = (row: any, columns: TableCSVColumn[]) => {
    let rowStr = '';
    columns.forEach(column => {
        let value = row[column.field];
        const headerLower = column.headerName.toLowerCase();
        if ((headerLower.includes('call in') || headerLower.includes('date') || headerLower.includes('time')) &&
            typeof value === 'number') {
            value = getDateString(new Date(value), true);
        }
        if (value === undefined || value === null) {
            value = Constants.NA;
        }
        value = value.toString().replace(/\n/g, ' ');
        return rowStr += `${ value.toString().replace(/,/g, '') },`;
    });
    return rowStr.slice(0, rowStr.length - 1);
};

const generateCSVFileContents = (columns: TableCSVColumn[], data: any[]) => {
    let content = '';
    columns.forEach(column => content = `${ content }${ column.headerName },`);
    content = `${ content.slice(0, -1) }\n`;
    data.forEach(row => content += `${ determineStringFromRow(row, columns) }\n`);
    return content;
};

export default generateCSVFileContents;