/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {ReactNode} from 'react';
import {IconButton} from '@mui/material';

interface Props {
    active?: boolean,
    onClick: () => void,
    children: ReactNode,
}

const CarouselIconButton = (props: Props) => (
    <IconButton
        sx={{
            cursor: 'pointer',
            transition: '200ms',
            padding: 0,
            color: props.active ? '#494949' : '#afafaf',
            '&:hover': {
                color: props.active ? '#494949' : '#1f1f1f',
            },
            '&:active': {
                color: props.active ? '#494949' : '#1f1f1f',
            },
        }}
        onClick={props.onClick}
    >
        {props.children}
    </IconButton>
);

export default CarouselIconButton;
