/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Grid} from '@mui/material';
import ContentPanel from '../../common/ContentPanel';
import AccountInformationPaper from '../../customer/preferences/AccountInformationPaper';
import AccountAddressPaper from '../../customer/preferences/AccountAddressPaper';
import {observer} from 'mobx-react-lite';
import {useMemo} from 'react';
import BinsenseStore from '../../../stores/BinsenseStore';
import SystemPreferencesPaper from '../../customer/preferences/SystemPreferencesPaper';
import AccountPasswordPaper from '../../customer/preferences/AccountPasswordPaper';
import DealerNotifications from '../preferences/DealerNotifications';

const DealerPreferences = observer(() => {
    const dealerUserData = useMemo(() => BinsenseStore.dealerUserData, [ BinsenseStore.dealerUserData ]);

    return (
        <ContentPanel>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={6}
                >
                    <AccountInformationPaper
                        userId={dealerUserData.id}
                        account={dealerUserData.account}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <SystemPreferencesPaper
                        preferences={dealerUserData.settings.preferences}
                        dealer
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <AccountAddressPaper
                        userId={dealerUserData.id}
                        account={dealerUserData.account}
                        dealer
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <AccountPasswordPaper/>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <DealerNotifications/>
                </Grid>
            </Grid>
        </ContentPanel>
    );
});

export default DealerPreferences;
