/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Cable} from '../../types/components/ApiTypes';

const getBinViewHighThreshold = (rowIndex: number, cables: Cable[], temp: boolean = false) => {
    const key = temp ? 'thresholdHigh' : 'CGCHigh';
    for (let i = 0; i < cables.length; i++) {
        if (cables[i].sensorCount > rowIndex && cables[i].sensors[rowIndex][key]) {
            return true;
        }
    }
    return false;
};

export default getBinViewHighThreshold;
