import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';
import getIndex from '../../../helper/functions/getIndex';
import WebStore from '../../../stores/WebStore';

const getDealerUserModel = (enqueueSnackbar: any, userId?: number) => {
    callAPI('dealerUserModel',
        (response) => {
            BinsenseStore.setDealerUserData(response);
            if (userId) {
                const customers = response.customers;
                const userIndex = getIndex(userId, customers);
                WebStore.setPageData([ customers, customers[userIndex].sites, null ], true);
            }
        },
        enqueueSnackbar,
        true
    );
};

export default getDealerUserModel;