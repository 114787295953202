/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Paper, styled, Typography} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';
import {Cancel, CheckCircle, Error} from '@mui/icons-material';
import SystemStatusItem from './SystemStatusItem';
import {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import Constants from '../../../helper/Constants';
import WebStore from '../../../stores/WebStore';
import BinsenseStore from '../../../stores/BinsenseStore';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import getSite from '../../../helper/functions/getSite';
import getSelectedSiteIndex from '../../../helper/functions/getSelectedSiteIndex';
import getBinHeatThresholdColor from '../../../helper/functions/getBinHeatThresholdColor';
import {Bin, Building, GrainBag, MasterUnit, RemoteUnit} from '../../../types/components/ApiTypes';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '32px',
    height: '100%',
});

const ICON_SIZE = 60;

const SystemStatusPaper = observer(() => {
    const site = useMemo(() => getSite(getSelectedSiteIndex()), [ WebStore.selectedSiteId, BinsenseStore.userData ]);

    const addStatusValue = (unit: MasterUnit | RemoteUnit, values: number[]) => {
        if (unit.status.reportingStatus === 'Online') {
            values[0] = values[0] + 1;
        }
        else if (unit.status.reportingStatus === Constants.OFFLINE) {
            values[1] = values[1] + 1;
        }
    };

    const addGrainWarningValue = (bin: Bin, values: number[]) => {
        if (bin !== null) {
            const binColor = getBinHeatThresholdColor(bin);
            if (binColor === 'red' || binColor === 'yellow') {
                values[2] = values[2] + 1;
            }
        }
    };

    const addStatusValuesByKey = (key: string, values: number[]) => {
        site[key].forEach((struct: Bin | GrainBag | Building) => {
            if (struct.hardware) {
                struct.hardware.masterUnits.forEach(unit => addStatusValue(unit, values));
                struct.hardware.remoteUnits.forEach(unit => addStatusValue(unit, values));
            }
            if (key === 'bins') {
                //@ts-ignore
                addGrainWarningValue(struct, values);
            }
        });
    };

    const statusValues = useMemo(() => {
        const values = [ 0, 0, 0 ];
        addStatusValuesByKey('bins', values);
        addStatusValuesByKey('grainBags', values);
        addStatusValuesByKey('buildings', values);
        // addStatusValuesByKey('piles', values);
        return values;
    }, [ BinsenseStore.userData, WebStore.selectedSiteId ]);

    const renderItem = (id: string, value: string | number, tooltip: string, icon: object, onClick?: () => void) => (
        <SystemStatusItem
            id={id}
            value={value}
            icon={icon}
            tooltip={tooltip}
            onClick={onClick}
        />
    );

    const onErrorClick = () => WebStore.setSelectedDrawer(Constants.REPORTS, true);

    const onWarningClick = () => {
        WebStore.setSelectedDrawer(Constants.STORAGE, true);
        WebStore.setStorageMode(Constants.LIST, true);
    };

    return !BinsenseStore.awaitingUserData ? (
        <Paper
            elevation={6}
            sx={{
                marginBottom: '0px',
                width: '100%',
                height: '100%',
            }}
        >
            <Root>
                <Typography
                    {...getTestID('system_status_title')}
                    fontSize={15}
                >
                    System Status
                </Typography>
                <Row>
                    {renderItem('online', statusValues[0], 'Online: No issues',
                        <CheckCircle
                            color='success'
                            style={{ fontSize: ICON_SIZE }}
                        />)}
                    {renderItem('offline', statusValues[1], 'Offline: Click to view \'Reports\' page for details',
                        <Cancel
                            color='error'
                            style={{ fontSize: ICON_SIZE }}
                        />, onErrorClick)}
                    {renderItem('warning', statusValues[2],
                        'Grain Threshold Warning: Click to view \'Storage\' page for details',
                        <Error
                            color='primary'
                            style={{ fontSize: ICON_SIZE }}
                        />, onWarningClick)}
                </Row>
            </Root>
        </Paper>
    ) : (
        <DashboardSkeleton
            height={180}
            style={{ marginLeft: '20px' }}
        />
    );
});

export default SystemStatusPaper;
