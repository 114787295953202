/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {useMemo} from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {observer} from 'mobx-react-lite';
import Colors from '../../helper/Colors';
import WebStore from '../../stores/WebStore';
import getTestID from '../../helper/functions/getTestID';
import Constants from '../../helper/Constants';
import urlStore from '../../stores/urlStore';
import BinsenseStore from '../../stores/BinsenseStore';

const menuItem = {
    marginRight: '8px',
    marginLeft: '8px',
};

interface Props {
    id: string,
    icon: object,
    text: string,
    drawerOpen: boolean,
    expandable?: boolean,
    expandOpen?: boolean,
    setExpandedState?: () => void,
    paddingLeft?: string,
    disabled?: boolean,
}

const DrawerButton = observer((props: Props) => {
    const createSiteConfirmPageChange = props.text !== Constants.CREATE_SITE &&
        WebStore.selectedDrawer === Constants.CREATE_SITE && WebStore.activeStep !== 0;

    const disabled = useMemo(() => props.disabled || (BinsenseStore.userData.sites.length === 0 &&
        WebStore.selectedView === Constants.CUSTOMER && (props.text === Constants.NOTES ||
            props.text === Constants.ADD_NEW)), [ props.disabled, BinsenseStore.userData ]);
    const selected = useMemo(() => props.text === WebStore.selectedDrawer, [ props.text, WebStore.selectedDrawer ]);
    const color = useMemo(() => selected ? {
        color: WebStore.lightMode ? Colors.primary : Colors.primaryDark,
        fontWeight: 800,
    } : {}, [ selected, WebStore.lightMode ]);

    const getQuoteURL = () => {
        const baseURL = urlStore.ipAddress;
        return window.location.protocol
            .concat('//quote')
            .concat(baseURL.substring(baseURL.indexOf('.')));
    };

    const onClick = () => {
        if (props.expandable && props.setExpandedState) {
            props.setExpandedState();
        } else if ((createSiteConfirmPageChange || WebStore.editingMap) &&
            (props.text !== Constants.ADD_NEW || WebStore.selectedDrawer !== Constants.ADD_NEW)) {
            WebStore.setSelectedDrawerAfterConfirmation(props.text);
            WebStore.setIsLoseChangesConfirmationOpen(true);
        } else {
            if (props.text === Constants.STORAGE) {
                WebStore.setStorageMode(Constants.GRID, true);
            }
            if (WebStore.selectedDrawer === Constants.MY_CUSTOMERS && props.text !== Constants.MY_CUSTOMERS) {
                WebStore.setMyCustomersSearch('', true);
            }
            WebStore.setCurrentPage(Constants.MAIN, true);
            WebStore.setPageQueue([], true);
            WebStore.setActiveStep(0);
            WebStore.setSelectedDrawer(props.text, true);
        }
        if (props.text === Constants.QUOTES) {
            window.open(getQuoteURL(), '_blank');
        }
    };

    return (
        <Tooltip
            title={props.text}
            placement="right"
            disableFocusListener={props.drawerOpen}
            disableTouchListener={props.drawerOpen}
            disableHoverListener={props.drawerOpen}
        >
            <MenuItem
                {...getTestID(`${ props.id }_drawer_button`)}
                sx={{
                    ...menuItem,
                    ...color,
                }}
                selected={selected}
                onClick={onClick}
                disabled={disabled}
            >
                <ListItemIcon sx={{...color}}>
                    {props.icon}
                </ListItemIcon>
                {props.drawerOpen && (
                    <ListItemText
                        primary={props.text}
                        primaryTypographyProps={{
                            sx: {...color},
                        }}
                    />
                )}
                {props.expandable && (
                    <ListItemIcon
                        sx={{
                            minWidth: '24px !important',
                            ...color,
                        }}
                        {...getTestID(props.expandOpen
                            ? `${ props.id }_collapse`
                            : `${ props.id }_expand`)}
                    >
                        {props.expandOpen
                            ? <ExpandLess sx={{...color}}/>
                            : <ExpandMore sx={{...color}}/>
                        }
                    </ListItemIcon>
                )}
            </MenuItem>
        </Tooltip>
    );
});

export default DrawerButton;
