/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import { LngLatBounds } from 'react-map-gl';
import {Customer, MarkerData, Site} from '../../types/components/ApiTypes';
import Constants from '../Constants';
//import { getBoundingOffsets } from './functions';

const columns = 10;
const rows = 5;

interface Quadrant {
    rowNumber: number;
    columnNumber: number;
    customerSites: CustomerSite[];
}

interface CustomerSite {
    customer: Customer;
    site: Site;
}

const getMarker = (q:Quadrant) => {
    if (q.customerSites.length == 1)
    {
    return {
        id: q.customerSites[0].site.id,
        longitude: q.customerSites[0].site.longitude,
        latitude: q.customerSites[0].site.latitude,
        siteTooltip: {
            name: q.customerSites[0].site.name,
            customer: {
                id: q.customerSites[0].customer.id,
                firstName: q.customerSites[0].customer.account?.firstName
                ? q.customerSites[0].customer.account.firstName : 'N/A',
                lastName: q.customerSites[0].customer.account?.lastName
                ? q.customerSites[0].customer.account.lastName : 'N/A',
                email: q.customerSites[0].customer.account?.accountEmail
                ? q.customerSites[0].customer.account.accountEmail : 'N/A',
            },
            displayData: {
                deviceType: Constants.LIVE,
                status: 'Online',
            },
        },
    };
    }
// more than one site
    let totalLat = 0;
    let totalLng = 0;
    let countLat = 0;
    let countLng = 0;
    q.customerSites.forEach((cs) => {
        countLat++;
        countLng++;
        totalLat += cs.site.latitude;
        totalLng += cs.site.longitude;
    });

    const lat = totalLat / countLat;
    const lng = totalLng / countLng;
    const markerName = countLat.toString().concat(' sites');
    return {
        secondaryColor: true,
        id: q.customerSites[0].site.id * -1,
        longitude: lng,
        latitude: lat,
        siteTooltip: {
            name: markerName,
            customer: {
                id: 0,
                firstName: '',
                lastName: 'N/A',
                email: 'N/A',
            },
            displayData: {
                deviceType: 'N/A',
                status: 'N/A',
            },
        },
        siteCount: countLat,
    };

}
;

let quadrants:Quadrant[] = [];

const isInBounds = (site:Site, bounds:LngLatBounds) => bounds != undefined &&
    site.latitude > bounds.getSouthWest().lat &&
       site.latitude < bounds.getNorthEast().lat &&
       site.longitude > bounds.getSouthWest().lng &&
       site.longitude < bounds.getNorthEast().lng;

       // return a spot in the 10x5 grid to place this site
const placeInQuadrant = (s:Site, c:Customer, bounds:LngLatBounds) => {
    const lngSpan = bounds.getNorthEast().lng - bounds.getSouthWest().lng;
    const latSpan = bounds.getNorthEast().lat - bounds.getSouthWest().lat;
    const lngQ = lngSpan / columns;
    const latQ = latSpan / rows;
    const lngidx = Math.floor(Math.abs(s.longitude  - bounds.getSouthWest().lng) / lngQ);
    const latidx =  Math.floor(Math.abs(s.latitude - bounds.getSouthWest().lat) / latQ);

    let foundQuadrant = quadrants.find(({rowNumber,columnNumber}) => rowNumber == lngidx && columnNumber == latidx);
    if (foundQuadrant == undefined)
    {
        foundQuadrant = {
            rowNumber:lngidx,
            columnNumber:latidx,
            customerSites:[],
        };
        quadrants.push(foundQuadrant);
    }
    foundQuadrant.customerSites.push({
        site: s,
        customer: c,
    });
};

const getMyCustomersMarkerDataForBounds = (customers: Customer[],
     bounds: LngLatBounds) => {
    quadrants = [];
    const markerData: MarkerData[] = [];
    let totalSites = 0;
    customers.forEach(customer => customer.sites.forEach(site => {
        if (isInBounds(site,bounds)) {
            totalSites++;
        }
    }));

    if (totalSites < 50)
    {
    customers.forEach(customer => customer.sites.forEach(site => {
        if (isInBounds(site,bounds)) {
            markerData.push({
            id: site.id,
            longitude: site.longitude,
            latitude: site.latitude,
            siteTooltip: {
                name: site.name,
                customer: {
                    id: customer.id,
                    firstName: customer.account?.firstName ? customer.account.firstName : 'N/A',
                    lastName: customer.account?.lastName ? customer.account.lastName : 'N/A',
                    email: customer.account?.accountEmail ? customer.account.accountEmail : 'N/A',
                },
                displayData: {
                    deviceType: Constants.LIVE,
                    status: 'Online',
                },
            },
    });
}}
    ));
}
else
{
    // use quadrants
    customers.forEach(customer => customer.sites.forEach(site => {
        if (isInBounds(site,bounds)) {
            placeInQuadrant(site,customer,bounds);
        }
    }));
    // now return markers to represent the quadrants
    quadrants.forEach(q => markerData.push(getMarker(q)));
}
    return markerData;
};

export default getMyCustomersMarkerDataForBounds;
