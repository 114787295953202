import callAPI from '../../callAPI';

const getRequestUsername = (enqueueSnackbar: any, email: String) => {
    callAPI(`username/forgot/${ email }`,
        (response) => {
            if (response === 'OK') {
                enqueueSnackbar('Successfully requested username.', {variant: 'success'});
            } else {
                enqueueSnackbar(response, {variant: 'error'});
            }
        },
        enqueueSnackbar
    );

    return '';
};

export default getRequestUsername;