import { cloneDeep } from 'lodash';
import WebStore from '../../../stores/WebStore';
import { Customer } from '../../../types/components/ApiTypes';
import callAPI from '../../callAPI';

const getCustomerBalance = (enqueueSnackbar: any, customer: Customer) => {
    callAPI(`balance/${ customer.id }`,
        (response) => {
            const customerData = cloneDeep(WebStore.selectedCustomer);
            customerData.id = customer.id;
            customerData.balance = response;

            WebStore.setSelectedCustomer(customerData, true);
        },
        enqueueSnackbar
    );
};

export default getCustomerBalance;