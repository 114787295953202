/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default {
    accent: '#718096',

    background: '#F8FAFC',
    backgroundDark: '#0e151f',

    drawerUserBackgroundLight: '#f2f3f5',
    drawerUserBackgroundDark: '#19212a',

    paperDarkMode: '#2c2c2c',
    primary: '#f3c300', // #073674
    primaryDark: '#f3c300', //#0574B9
    primaryYellow: '#ffe000',

    modalTitleLightMode: '#718096',
    modalTitleDarkMode: '#393d4b',

    tableCellGreen: 'rgba(137,255,137,0.6)',
    tableCellYellow: '#fff186',
    tableCellRed: 'rgba(255,137,137,0.6)',
    tableLabelGreen: '#36e036',
    tableLabelRed: '#e51b1b',
    text: '#4A5668',
    textLight: 'rgba(255,255,255,0.7)',
    textTitle: '#1A202C',
    textTitleLight: '#F8FAFC',
    textBold: '#2C3849',
    textBoldLight: '#6280a3',

    boxShadowLight: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    boxShadowDark: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.12) 0px 12px 24px -4px',
    boxShadowYellow: 'rgba(243, 195, 0, 0.2) 0px 0px 2px 0px, rgba(243, 195, 0, 0.3) 0px 12px 24px -4px',

    paperBorderRadius: '12px',

    avgTempColor: '#00cc00',
    maxTempColor: '#ff9900',
    minTempColor: '#009999',
    ambientTempColor: '#0073e699',

    fanColor: '#666666',
    avgMoistureColor: '#0099ff',
    minMoistureColor: '#0033ff',
    maxMoistureColor: '#00ddff',
    alertColor: '#ff0000',

};
