/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {ListItemIcon, MenuItem} from '@mui/material';
import {Logout, Person} from '@mui/icons-material';
import getTestID from '../../helper/functions/getTestID';
import PopupMenu from '../common/PopupMenu';
import {useState} from 'react';
import HoverTextButton from '../common/buttons/HoverTextButton';
import Constants from '../../helper/Constants';
import logout from '../../helper/functions/logout';

interface Props {
    disableButtons?: boolean,
}

const UserButton = (props: Props) => {
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <HoverTextButton
                id="user_icon_button"
                icon={<Person/>}
                hoverText={Constants.USER}
                onClick={handleClick}
                disabled={props.disableButtons}
            />
            <PopupMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
            >
                <MenuItem
                    {...getTestID('logout_button')}
                    onClick={logout}
                >
                    <ListItemIcon>
                        <Logout fontSize="small"/>
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </PopupMenu>
        </div>
    );
};

export default UserButton;
