/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

//@ts-ignore
import uuid from 'react-uuid';
import DisplayGridItem from '../../../common/displayTable/DisplayGridItem';
import {observer} from 'mobx-react-lite';
import Constants from '../../../../helper/Constants';
import {useMemo} from 'react';
import useWindowSize from '../../../../helper/hooks/useWindowSize';
import AutomationImagePanel from './AutomationImagePanel';
import StorageGridAutomationHubBody from './StorageGridAutomationHubBody';
import { AutomationHubs, Site } from '../../../../types/components/ApiTypes';

interface Props {
    site: Site,
    hub: AutomationHubs,
}

const StorageGridAutomationHub = observer((props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);

    return (
        <DisplayGridItem
            xs={windowWidth > Constants.WINDOW_WIDTHS[2] ? 4 : 6}
            key={uuid()}
            minWidth="375px"
        >
            <AutomationImagePanel
                hub={props.hub}
                maxWidth
            >
                <StorageGridAutomationHubBody
                    site={props.site}
                />
            </AutomationImagePanel>
        </DisplayGridItem>
    );
});

export default StorageGridAutomationHub;
