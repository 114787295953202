/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import ContentPanel from '../../common/ContentPanel';
import AlertsTable from '../alerts/AlertsTable';

interface Props {
    dealer?: boolean
}

const Alerts = (props: Props) => (
    <ContentPanel>
        <AlertsTable dealer={props.dealer}/>
    </ContentPanel>
);

export default Alerts;
