/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {
    AutomationHubs,
    Bin,
    Building,
    DistanceUnit,
    GrainBag,
    MarkerTypes, Pile,
    Site
} from '../../types/components/ApiTypes';
import getBinFanStatus from './getBinFanStatus';
import getTempUnit from './getTempUnit';
import getGrainType from './getGrainType';
import getHardwareType from './getHardwareType';
import getDevices from './getDevices';
import getGrainBinData from './getGrainBinData';
import getNumberLabel from './getNumberLabel';
import filterByName from './filterByName';
import getStructStatus from './getStructStatus';
import getBinHeatThresholdColor from './getBinHeatThresholdColor';
import { displayTemp } from './functions';
import getGrainBagData from './getGrainBagData';
import getBuildingData from './getBuildingData';
import Constants from '../Constants';
import getAutomationRemotesCount from './getAutomationRemotesCount';

const getBinDisplayData = (bin: Bin) => ({
    type: MarkerTypes.bin,
    grainBinData: getGrainBinData(bin),
});

const getBagDisplayData = (bag: GrainBag) => ({
    type: MarkerTypes.grainBag,
    grainBagData: getGrainBagData(bag),
});

const getBuildingDisplayData = (building: Building) => ({
    type: MarkerTypes.building,
    buildingData: getBuildingData(building),
});

// const getPileDisplayData = (pile: Pile) => ({
//     type: MarkerTypes.grainPile,
//     buildingData: getPileData(pile),
// });

const getAutomationDisplayData = (struct: AutomationHubs) => ({
    type: MarkerTypes.automation,
    automationHubData: {
        name: struct.name,
        unit: DistanceUnit.feet,
        serial: struct.hardware.masterUnits.length > 0 ? struct.hardware.masterUnits[0].serial : undefined,
    },
});

const getDisplayData = (struct: any, key: string) => {
    if (key === 'bins') {
        return getBinDisplayData(struct);
    }
    else if (key === 'automationHubs') {
        return getAutomationDisplayData(struct);
    }
    else if (key === 'grainBags') {
        return getBagDisplayData(struct);
    }
    else if (key === 'buildings') {
        return getBuildingDisplayData(struct);
    }
    // else if (key === 'piles') {
    //     return getPileDisplayData(struct);
    // }
};

const getSerial = (struct: Bin | GrainBag | Building | Pile) => {
    const devices = getDevices(struct);
    const masterSerial = devices.length === 1 && devices[0].hasOwnProperty('masterSerial')
                        ? ` (${ Constants.MASTER }: ${ devices[0].masterSerial })` : '';
    return devices.length === 1 ? devices[0].serial.concat(masterSerial) : `${ devices.length } devices`;
};

const getStructureMarkers = (site: Site, key: string, searchKey: string) => {
    if (site[key]) {
        let structs = JSON.parse(JSON.stringify(site[key]));
        if (searchKey !== '') {
            const searchArray = searchKey.split(' ');
            searchArray.forEach(val => structs = filterByName(structs, val));
        }
        return structs.map((struct: any) => ({
            id: struct.id,
            longitude: struct.latLongOffset ? site.longitude + struct.longitude : struct.longitude,
            latitude: struct.latLongOffset ? site.latitude + struct.latitude : struct.latitude,
            draggable: false,
            display: getDisplayData(struct, key),
            tooltip: {
                name: struct.name,
                editable: false,
                displayData: {
                    status: getStructStatus(struct),
                    maxTemp: struct.status?.maxTemperature
                        ? ` ${ displayTemp( getTempUnit() === 'F', struct.status.maxTemperature) } ${ getTempUnit() }`
                        : Constants.NA,
                    tempTrend: struct.status?.temperatureTrend ? struct.status.temperatureTrend : null,
                    maxMoisture: struct.status?.maxMoisture ? getNumberLabel(struct.status.maxMoisture, '%') : Constants.NA,
                    moistureTrend: struct.status?.moistureTrend ? struct.status.moistureTrend : null,
                    // eslint-disable-next-line
                    level: struct.status?.percentFull || struct.status?.percentFull === 0 ? getNumberLabel(struct.status.percentFull, '%') : Constants.NA,
                    fans: struct.hardware?.fans ? getBinFanStatus(struct.hardware.fans) : Constants.NA,
                    type: getHardwareType(struct),
                    serial: getSerial(struct),
                    contents: getGrainType(struct.settings?.productId ? struct.settings.productId : -1),
                },
                automationData: key === 'automationHubs' ? {
                    status: getStructStatus(struct),
                    ambientTemp: site.ambientTemp ? ` ${ displayTemp( getTempUnit() === 'F', site.ambientTemp) } ${ getTempUnit() }`
                        : Constants.NO_READING,
                    tempTrend: site.tempTrend ? site.tempTrend : Constants.NO_READING,
                    humidity: site.humidity ? getNumberLabel(site.humidity, '%') : Constants.NO_READING,
                    humidityTrend: site.humidityTrend ? site.humidityTrend : Constants.NO_READING,
                    serial: getSerial(struct),
                    remotesCount: getAutomationRemotesCount(site),
                } : undefined,
                structure: struct,
            },

            // eslint-disable-next-line
            heatThresholdColor: key === 'bins' ? getBinHeatThresholdColor(struct) : null,
        }));
    }
    return [];
};

const getMarkerData = (site: Site, searchKey: string = '') => {
    const bins = getStructureMarkers(site, 'bins', searchKey);
    const grainBags = getStructureMarkers(site, 'grainBags', searchKey);
    const buildings = getStructureMarkers(site, 'buildings', searchKey);
    // const piles  = getStructureMarkers(site, 'piles', searchKey);
    const automationHubs = getStructureMarkers(site, 'automationHubs', searchKey);
    return [ ...bins, ...grainBags, ...buildings, ...automationHubs ];
    // return [ ...bins, ...grainBags, ...buildings, ...piles, ...automationHubs ];
};

export default getMarkerData;