/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import TitlePaper from '../../../common/TitlePaper';
import Constants from '../../../../helper/Constants';
import {Site} from '../../../../types/components/ApiTypes';
import {styled} from '@mui/material';
import {Typography} from '@mui/material';
import {Check, Close} from '@mui/icons-material';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    padding: '8px',
});

interface Props {
    site: Site
}

const AutomationHubPaper = (props: Props) => {
    const renderRow = (key: string, value: boolean) => (
        <Row>
            <Typography>{key}:</Typography>
            {value ? <Check color="success"/> : <Close color="error"/>}
        </Row>
    );

    //We only ever have 1 automation hub on a site, and a hub can only have one "masterUnit" on it
    const automationHubOnline = props.site.automationHubs[0].hardware.masterUnits[0].status.reportingStatus === Constants.ONLINE;

    return (
        <TitlePaper title={`${ props.site.name } ${ Constants.AUTOMATION_HUB }`}>
            {renderRow('Automation Hub Online', automationHubOnline)}
            {renderRow('Ambient Condition Sensor', props.site.ambientTemp != null && props.site.humidity != null)}
        </TitlePaper>
    );
};

export default AutomationHubPaper;
