/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import Button from '@mui/material/Button';
import getTestID from '../../../helper/functions/getTestID';
import BinsenseStore from '../../../stores/BinsenseStore';
import {observer} from 'mobx-react-lite';
import Constants from '../../../helper/Constants';

const Root = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});

interface Props {
    id: string
    handleBack: () => void,
    activeStep: number
}

const BackButton = observer((props: Props) => (
    <Root>
        <Button
            {...getTestID(props.id)}
            color="secondary"
            disabled={props.activeStep === 0 || BinsenseStore.awaitingApi}
            onClick={props.handleBack}
            sx={{mr: 1}}
            variant="contained"
        >
            {Constants.BACK}
        </Button>
    </Root>
));

export default BackButton;