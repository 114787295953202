/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

const getRowsPerPage = (rowsPerPage: number, rowsLength: number) => {
    if (rowsLength < 30) {
        return rowsLength;
    }
    return rowsPerPage <= 30 || rowsPerPage > rowsLength ? 30 : rowsPerPage;
};

export default getRowsPerPage;
