/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {action, makeObservable, observable} from 'mobx';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Constants from '../helper/Constants';
import getToken from '../helper/functions/getLoginToken';
import {LoginConfig} from '../types/components/ApiTypes';
import Cookies from 'js-cookie';
import urlStore from './urlStore';

class LoginStore {
    loggedIn = false;

    wrongUsernameOrPassword = false;

    wrongPassword = false;

    token = getToken();

    udd = -1;

    loadingData = true;

    email = '';

    hasTemporaryPassword = false;

    hasBiometricAuthentication = false;

    deviceID = '';

    clientSecret = '1234567890ABCD';

    redirectUri = 'http://web.binsense.calianagtest.com';

    authUrl = '';

    loginConfig = {
        loginUrl: '',
        authUrl: '',
        tokenUrl: '',
        clientId: '',
    }

    constructor() {
        makeObservable(this, {
            loggedIn: observable,
            setLoggedIn: action,
            wrongUsernameOrPassword: observable,
            setWrongUsernameOrPassword: action,
            wrongPassword: observable,
            setWrongPassword: action,
            token: observable,
            setToken: action,
            udd: observable,
            setUdd: action,
            loadingData: observable,
            setLoadingData: action,
            email: observable,
            setEmail: action,
            hasTemporaryPassword: observable,
            setTemporaryPassword: action,
            hasBiometricAuthentication: observable,
            setBiometricAuthentication: action,
            deviceID: observable,
            setDeviceId: action,
            clientSecret: observable,
            redirectUri: observable,
            authUrl: observable,
            setAuthUrl: action,
            loginConfig: observable,
            setLoginConfig: action,
        });
        try {
            if (window.globalConfig.redirectUri)
                this.redirectUri = window.globalConfig.redirectUri;
            else
                this.redirectUri = 'http://web.binsense.calianagtest.com';
        } catch (e) {
            this.redirectUri = 'http://web.binsense.calianagtest.com';
        }
        this.initializeFromAsyncStorage();
    }

    setLoggedIn = (isLoggedIn: boolean) => {
        this.loggedIn = isLoggedIn;
    }

    setWrongUsernameOrPassword = (value: boolean) => {
        this.wrongUsernameOrPassword = value;
    }

    setWrongPassword = (value: boolean) => {
        this.wrongPassword = value;
    }

    setToken = (token: string, sync: boolean) => {
        this.token = token;
        const baseURL = urlStore.ipAddress;
        const domainPath = baseURL.substring(baseURL.indexOf('.'));
        Cookies.set('IGTAccessToken', token, {domain:domainPath});
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_TOKEN, token);
        }
    }

    setUdd = (udd: number, sync: boolean) => {
        this.udd = udd;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_UDD, udd.toString());
        }
    }

    setDeviceId = (deviceID: string, sync: boolean) => {
        this.deviceID = deviceID;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_DEVICE_ID, deviceID);
        }
    }

    setAuthUrl = (authUrl: string) => {
        this.authUrl = authUrl;
    }

    setLoadingData = (flag: boolean) => {
        this.loadingData = flag;
    }

    setEmail = (email: string, sync: boolean) => {
        this.email = email;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_EMAIL, email);
        }
    }

    setTemporaryPassword = (flag: boolean) => {
        this.hasTemporaryPassword = flag;
    }

    setLoginConfig = (config: LoginConfig) => {
        this.loginConfig = config;
    }

    setBiometricAuthentication = (flag: boolean, sync: boolean) => {
        this.hasBiometricAuthentication = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_BIOMETRIC_AUTHENTICATION, flag.toString());
        }
    }

    syncWithAsyncStorage = (key: string, value: string) => {
        global.localStorage.setItem(key, JSON.stringify(value));
    }

    initializeFromAsyncStorage = () => {
        const result = global.localStorage.getItem(Constants.STORE_KEY_EMAIL);
        if (result !== null) {
            this.setEmail(JSON.parse(result), false);
        }
        const tokenResult = global.localStorage.getItem(Constants.STORE_KEY_TOKEN);
        if (tokenResult !== null) {
            this.setToken(JSON.parse(tokenResult), false);
        }
        const uddResult = global.localStorage.getItem(Constants.STORE_KEY_UDD);
        if (uddResult !== null) {
            this.setUdd(Number(JSON.parse(uddResult)), false);
        }
        const deviceIDResult = global.localStorage.getItem(Constants.STORE_KEY_DEVICE_ID);
        if (deviceIDResult !== null) {
            this.setDeviceId((JSON.parse(deviceIDResult)), false);
        } else {
            FingerprintJS.load({monitoring: false})
                .then(fp => fp.get())
                .then((fpResult) => this.setDeviceId(fpResult.visitorId, true));
        }
        const biometricResult = global.localStorage.getItem(Constants.STORE_KEY_BIOMETRIC_AUTHENTICATION);
        if (biometricResult !== null) {
            this.setBiometricAuthentication(Boolean(JSON.parse(biometricResult)), false);
        }
    }
}

export default new LoginStore();
