/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import Grid from '@mui/material/Grid';
import Constants from '../../../helper/Constants';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import GridTextFieldInput from '../input/GridTextFieldInput';

interface Props {
    firstName: InputFieldProps,
    lastName: InputFieldProps,
    email: InputFieldProps,
    phoneNumber?: InputFieldProps,
    isUsername?: boolean,
}

function PersonalDetailsForm(props: Props) {
    return (
        <Grid
            container
            spacing={2}
        >
            <GridTextFieldInput
                id="first_name_input"
                label={Constants.FIRST_NAME}
                field={props.firstName}
                textFieldProps={{
                    inputProps: {
                        maxLength: 100,
                    },
                }}
            />
            <GridTextFieldInput
                id="last_name_input"
                label={Constants.LAST_NAME}
                field={props.lastName}
                textFieldProps={{
                    inputProps: {
                        maxLength: 100,
                    },
                }}
            />
            <GridTextFieldInput
                id="email_input"
                label={props.isUsername ? Constants.USERNAME : Constants.EMAIL}
                field={props.email}
                textFieldProps={{
                    inputProps: {
                        maxLength: 60,
                    },
                }}
                fullWidth={!props.phoneNumber}
            />
            {props.phoneNumber && (
                <GridTextFieldInput
                    id="phone_number_input"
                    label={Constants.MOBILE_NUMBER}
                    field={props.phoneNumber}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 10,
                        },
                        type: 'tel',
                    }}
                />
            )}
        </Grid>
    );
}

export default PersonalDetailsForm;
