/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import BaseModal from '../../common/modal/BaseModal';
import {observer} from 'mobx-react-lite';
import {useCallback, useState} from 'react';
import styled from '@emotion/styled';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import update from 'immutability-helper';
import { DragAndDropCard } from './DragAndDropCard';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import putUpdateSortOrder from '../../../controllers/apiCalls/put/putUpdateSortOrder';
import { Site } from '../../../types/components/ApiTypes';
import {isMobile} from 'react-device-detect';
import Constants from '../../../helper/Constants';

interface Props {
    close: () => void,
    site: Site
}

const Container = styled('div')({
    width: '500px',
    padding: '16px',
});

const ScrollArea = styled('div')({
    width: '100%',
    overflow: 'auto',
});

interface Item {
    id: number
    text: string
}

const EditBinOrderModal = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const allStructures : any[] = [ ...props.site.automationHubs, ...props.site.bins, ...props.site.grainBags, ...props.site.buildings ];
    const backend = isMobile ? TouchBackend : HTML5Backend;
    const [ canUpdate, setCanUpdate ] = useState(false);

    let sortIndexAvailable = false;
    allStructures.forEach(obj => {
        if (obj.sortOrder > 0) {
            sortIndexAvailable = true;
        }
    });

    let sortedStructures;
    if (sortIndexAvailable) {
        sortedStructures = allStructures.sort((a, b) => a.sortOrder - b.sortOrder);
    } else {
        sortedStructures = allStructures.sort((a, b) => a.name - b.name);
    }
    const rows : any[] = [];
    sortedStructures.forEach(row => rows.push({
        id: row.id,
        text: row.name,
    }));

    const [ cards, setCards ] = useState([ ...rows ]);

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setCards((prevCards: Item[]) =>
          update(prevCards, {
            $splice: [
              [ dragIndex, 1 ],
              [ hoverIndex, 0, prevCards[dragIndex] as Item ],
            ],
          })
        );
      }, []);

    const renderCard = useCallback(
        (card: { id: number; text: string }, index: number) => (
            <DragAndDropCard
                key={card.id}
                index={index}
                id={card.id}
                text={card.text}
                moveCard={moveCard}
                isMobile={isMobile}
            />
        ),
        []
    );

    const renderCards = () => (
        <div className="BinOrder">
            <DndProvider backend={backend}>
                <div>{cards.map((card, i) => renderCard(card, i))}</div>
            </DndProvider>
        </div>
    );

    const updateSortOrder = () => {
        let index = 0;
        const structures : any[] = [];
        cards.forEach(row => {
            index++;
            structures.push({
                id: row.id,
                sortOrder: index,
            });
        });
        putUpdateSortOrder(enqueueSnackbar, structures, props.site.id);
        props.close();
    };

    const submit = () => {
        setCanUpdate(true);
    };

    return (
        <BaseModal
            id="edit_bin_order_modal"
            open
            close={props.close}
            submit={submit}
            title={Constants.EDIT_ORDER}
            disableSubmitOnAwait
        >
            <Typography
                sx={{
                    paddingLeft: '16px',
                    fontSize: '15px',
                }}
            >
                Drag and drop the bins into your desired order.
            </Typography>
            <ScrollArea style={{height: '600px'}}>
                <Container>
                    {renderCards()}
                </Container>
            </ScrollArea>
            {canUpdate && (
                <ConfirmationModal
                    id={'update_sort_order_confirmation_modal'}
                    open
                    close={() => setCanUpdate(false)}
                    submit={updateSortOrder}
                    title="Update Bin Order"
                    message="Are you sure you want to update the bin order?"
                />
            )}
        </BaseModal>
    );
});

export default EditBinOrderModal;