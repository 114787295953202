/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import getIndex from './getIndex';
import BinsenseStore from '../../stores/BinsenseStore';
import Constants from '../Constants';

const getGrainType = (id: number) => {
    const grainIndex = getIndex(id, BinsenseStore.dataModel.grains);
    return grainIndex >= 0 ? BinsenseStore.dataModel.grains[grainIndex].name : Constants.NA;
};

export default getGrainType;
