/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import { observer } from 'mobx-react-lite';
import { InputFieldProps } from '../../../../../../types/components/ApiTypes';
import Constants from '../../../../../../helper/Constants';
import { Chip, Divider, Grid, styled, Typography } from '@mui/material';
import GridTextFieldInput from '../../../../../common/input/GridTextFieldInput';
import getTestID from '../../../../../../helper/functions/getTestID';
import NaturalAirDryingAdvancedSettings from './NaturalAirDryingAdvancedSettings';
import TooltipStore from '../../../../../../stores/TooltipStore';
import TooltipMessages from '../../../../../../helper/TooltipMessages';
import { useState } from 'react';
import AutomationAdvancedSettings from './AutomationAdvancedSettings';
import InfoTooltip from '../../../../../common/InfoTooltip';

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '12px',
    paddingBottom: '8px',
});

interface Props {
    averageGrainMoisture: InputFieldProps,
    targetMoisture: InputFieldProps,
    emcLow: InputFieldProps,
    emcHigh: InputFieldProps,
    lowTemperature: InputFieldProps,
    highTemperature: InputFieldProps,
    plenumRise: InputFieldProps,
    fanStatus: String | null,
    disabled?: boolean,
}

const NaturalAirDryingForm = observer((props: Props) => {
    const [ advancedOpen, setAdvancedOpen ] = useState(false);

    return (
        <div style={{
            width: '100%',
        }}
        >
            <Grid
                container
                spacing={2}
            >
                <GridTextFieldInput
                    id="average_grain_moisture_input"
                    label="Current Average Grain Moisture (%)"
                    field={props.averageGrainMoisture}
                    textFieldProps={{
                        disabled: true,
                    }}
                    fullWidth
                />
                <GridTextFieldInput
                    id="target_moisture_input"
                    label="Target Moisture (%)"
                    field={props.targetMoisture}
                    textFieldProps={{
                        inputProps: {
                            type: 'number',
                            min: '0',
                            max: props.averageGrainMoisture.value,
                            disabled: props.disabled,
                        },
                        InputProps: {
                            endAdornment: (
                                <InfoTooltip
                                    id="target_moisture_tooltip_button"
                                    open={TooltipStore.beginTooltips && !TooltipStore.showDefaultMessages &&
                                        TooltipStore.showTargetMoistureMessages}
                                    message={TooltipMessages.TARGET_MOISTURE}
                                    onClick={() => TooltipStore.setShowTargetMoistureMessages(false, true)}
                                />
                            ),
                            disabled: props.disabled,
                        },
                        disabled: props.disabled,
                    }}
                    fullWidth
                />
                <GridTextFieldInput
                    id="emc_range_low_threshold_input"
                    label="Minimum EMC (%)"
                    field={props.emcLow}
                    textFieldProps={{
                        inputProps: {
                            type: 'number',
                            min: '0',
                            max: props.emcHigh.value,
                            disabled: props.disabled,
                        },
                        InputProps: {
                            endAdornment: (
                                <InfoTooltip
                                    id="emc_range_tooltip_button"
                                    open={TooltipStore.beginTooltips && !TooltipStore.showTargetMoistureMessages &&
                                        TooltipStore.showEMCRangeMessages}
                                    message={TooltipMessages.EMC_RANGE}
                                    onClick={() => TooltipStore.setShowEMCRangeMessages(false, true)}
                                />
                            ),
                            disabled: props.disabled,
                        },
                        disabled: props.disabled,
                    }}
                />
                <GridTextFieldInput
                    id="emc_range_high_threshold_input"
                    label="Maximum EMC (%)"
                    field={props.emcHigh}
                    textFieldProps={{
                        inputProps: {
                            type: 'number',
                            min: props.emcLow.value,
                            max: '100',
                            disabled: props.disabled,
                        },
                        InputProps: {
                            endAdornment: (
                                <InfoTooltip
                                    id="emc_range_tooltip_button"
                                    message={TooltipMessages.EMC_RANGE}
                                    onClick={() => TooltipStore.setShowEMCRangeMessages(false, true)}
                                />
                            ),
                            disabled: props.disabled,
                        },
                        disabled: props.disabled,
                    }}
                />
            </Grid>
            <Row>
                <Typography style={{ marginRight: '16px' }}>
                    {Constants.FAN_STATUS}:
                </Typography>
                <Chip
                    {...getTestID('aeration_fan_status')}
                    label={props.fanStatus == null ? '' : props.fanStatus}
                    color={props.fanStatus?.toLowerCase() == Constants.FAN_STATUS_RUNNING.toLowerCase() ||
                        // eslint-disable-next-line
                        props.fanStatus?.toLowerCase() == Constants.FAN_DISPLAY_STATUS_RUNNING_MANUAL.toLowerCase() ? 'success'
                        : (props.fanStatus?.toLowerCase() == Constants.FAN_STATUS_OFF.toLowerCase() ? 'secondary'
                            : 'error')}
                    size="small"
                />
            </Row>
            <Divider
                style={{
                    marginBottom: '8px',
                    marginTop: '8px',
                }}
            />
            <AutomationAdvancedSettings
                id='natural_air_drying'
                advancedOpen={advancedOpen}
                setAdvancedOpen={setAdvancedOpen}
                tooltipOpen={TooltipStore.beginTooltips && !TooltipStore.showEMCRangeMessages &&
                    TooltipStore.showAdvancedMessages}
            >
                <NaturalAirDryingAdvancedSettings
                    lowTemperature={props.lowTemperature}
                    highTemperature={props.highTemperature}
                    plenumRise={props.plenumRise}
                    disabled={props.disabled}
                />
            </AutomationAdvancedSettings>
        </div>
    );
});

export default NaturalAirDryingForm;
