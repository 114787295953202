export default {
    'australia': [
        {
            id: '47_option',
            title: 'Australian Capital Territory',
            value: '47',
        },
        {
            id: '48_option',
            title: 'New South Wales',
            value: '48',
        },
        {
            id: '49_option',
            title: 'Northern Territory',
            value: '49',
        },
        {
            id: '50_option',
            title: 'Queensland',
            value: '50',
        },
        {
            id: '51_option',
            title: 'South Australia',
            value: '51',
        },
        {
            id: '52_option',
            title: 'Tasmania',
            value: '52',
        },
        {
            id: '53_option',
            title: 'Victoria',
            value: '53',
        },
        {
            id: '54_option',
            title: 'Western Australia',
            value: '54',
        },
    ],
    'canada': [
        {
            id: '621_option',
            title: 'Alberta',
            value: '621',
        },
        {
            id: '622_option',
            title: 'British Columbia',
            value: 'BC',
        },
        {
            id: '623_option',
            title: 'Manitoba',
            value: '623',
        },
        {
            id: '624_option',
            title: 'New Brunswick',
            value: '624',
        },
        {
            id: '625_option',
            title: 'Newfoundland and Labrador',
            value: '625',
        },
        {
            id: '626_option',
            title: 'Nova Scotia',
            value: '626',
        },
        {
            id: '627_option',
            title: 'Northwest Territories',
            value: '627',
        },
        {
            id: '628_option',
            title: 'Nunavut',
            value: '628',
        },
        {
            id: '629_option',
            title: 'Ontario',
            value: '629',
        },
        {
            id: '630_option',
            title: 'Prince Edward Island',
            value: '630',
        },
        {
            id: '631_option',
            title: 'Quebec',
            value: '631',
        },
        {
            id: '632_option',
            title: 'Saskatchewan',
            value: '632',
        },
        {
            id: '633_option',
            title: 'Yukon',
            value: '633',
        },
    ],
    'mexico': [
        {
            id: '2556_option',
            title: 'Aguascalientes',
            value: '2556',
        },
        {
            id: '2557_option',
            title: 'Baja California',
            value: '2557',
        },
        {
            id: '2558_option',
            title: 'Baja California Sur',
            value: '2558',
        },
        {
            id: '2559_option',
            title: 'Campeche',
            value: '2559',
        },
        {
            id: '2560_option',
            title: 'Chihuahua',
            value: '2560',
        },
        {
            id: '2561_option',
            title: 'Chiapas',
            value: '2561',
        },
        {
            id: '2562_option',
            title: 'Coahuila',
            value: '2562',
        },
        {
            id: '2563_option',
            title: 'Colima',
            value: '2563',
        },
        {
            id: '2564_option',
            title: 'Distrito Federal',
            value: '2564',
        },
        {
            id: '2565_option',
            title: 'Durango',
            value: '2565',
        },
        {
            id: '2566_option',
            title: 'Guerrero',
            value: '2566',
        },
        {
            id: '2567_option',
            title: 'Guanajuato',
            value: '2567',
        },
        {
            id: '2568_option',
            title: 'Hidalgo',
            value: '2568',
        },
        {
            id: '2569_option',
            title: 'Jalisco',
            value: '2569',
        },
        {
            id: '2570_option',
            title: 'Mexico',
            value: '2570',
        },
        {
            id: '2571_option',
            title: 'Michoacan',
            value: '2571',
        },
        {
            id: '2572_option',
            title: 'Morelos',
            value: '2572',
        },
        {
            id: '2573_option',
            title: 'Nayarit',
            value: '2573',
        },
        {
            id: '2574_option',
            title: 'Nuevo Leon',
            value: '2574',
        },
        {
            id: '2575_option',
            title: 'Oaxaca',
            value: '2575',
        },
        {
            id: '2576_option',
            title: 'Puebla',
            value: '2576',
        },
        {
            id: '2577_option',
            title: 'Queretaro',
            value: '2577',
        },
        {
            id: '2578_option',
            title: 'Quintana Roo',
            value: '2578',
        },
        {
            id: '2579_option',
            title: 'Sinaloa',
            value: '2579',
        },
        {
            id: '2580_option',
            title: 'San Luis Potosi',
            value: '2580',
        },
        {
            id: '2581_option',
            title: 'Sonora',
            value: '2581',
        },
        {
            id: '2582_option',
            title: 'Tabasco',
            value: '2582',
        },
        {
            id: '2583_option',
            title: 'Tamaulipas',
            value: '2583',
        },
        {
            id: '2584_option',
            title: 'Tlaxcala',
            value: '2584',
        },
        {
            id: '2585_option',
            title: 'Veracruz',
            value: '2585',
        },
        {
            id: '2586_option',
            title: 'Yucatan',
            value: '2586',
        },
        {
            id: '2587_option',
            title: 'Zacatecas',
            value: '2587',
        },
    ],
    'usa': [
        {
            id: '4820_option',
            title: 'Alaska',
            value: '4820',
        },
        {
            id: '4821_option',
            title: 'Alabama',
            value: '4821',
        },
        {
            id: '4822_option',
            title: 'Arkansas',
            value: '4822',
        },
        {
            id: '4823_option',
            title: 'American Samoa',
            value: '4823',
        },
        {
            id: '4824_option',
            title: 'Arizona',
            value: '4824',
        },
        {
            id: '4825_option',
            title: 'California',
            value: '4825',
        },
        {
            id: '4826_option',
            title: 'Colorado',
            value: '4826',
        },
        {
            id: '4827_option',
            title: 'Connecticut',
            value: '4827',
        },
        {
            id: '4828_option',
            title: 'District of Columbia',
            value: '4828',
        },
        {
            id: '4829_option',
            title: 'Delaware',
            value: '4829',
        },
        {
            id: '4830_option',
            title: 'Florida',
            value: '4830',
        },
        {
            id: '4831_option',
            title: 'Georgia',
            value: '4831',
        },
        {
            id: '4832_option',
            title: 'Guam',
            value: '4832',
        },
        {
            id: '4833_option',
            title: 'Hawaii',
            value: '4833',
        },
        {
            id: '4834_option',
            title: 'Iowa',
            value: '4834',
        },
        {
            id: '4835_option',
            title: 'Idaho',
            value: '4835',
        },
        {
            id: '4836_option',
            title: 'Illinois',
            value: '4836',
        },
        {
            id: '4837_option',
            title: 'Indiana',
            value: '4837',
        },
        {
            id: '4838_option',
            title: 'Kansas',
            value: '4838',
        },
        {
            id: '4839_option',
            title: 'Kentucky',
            value: '4839',
        },
        {
            id: '4840_option',
            title: 'Louisiana',
            value: '4840',
        },
        {
            id: '4841_option',
            title: 'Massachusetts',
            value: '4841',
        },
        {
            id: '4842_option',
            title: 'Maryland',
            value: '4842',
        },
        {
            id: '4843_option',
            title: 'Maine',
            value: '4843',
        },
        {
            id: '4844_option',
            title: 'Michigan',
            value: '4844',
        },
        {
            id: '4845_option',
            title: 'Minnesota',
            value: '4845',
        },
        {
            id: '4846_option',
            title: 'Missouri',
            value: '4846',
        },
        {
            id: '4847_option',
            title: 'Northern Mariana Islands',
            value: '4847',
        },
        {
            id: '4848_option',
            title: 'Mississippi',
            value: '4848',
        },
        {
            id: '4849_option',
            title: 'Montana',
            value: '4849',
        },
        {
            id: '4850_option',
            title: 'North Carolina',
            value: '4850',
        },
        {
            id: '4851_option',
            title: 'North Dakota',
            value: '4851',
        },
        {
            id: '4852_option',
            title: 'Nebraska',
            value: '4852',
        },
        {
            id: '4853_option',
            title: 'New Hampshire',
            value: '4853',
        },
        {
            id: '4854_option',
            title: 'New Jersey',
            value: '4854',
        },
        {
            id: '4855_option',
            title: 'New Mexico',
            value: '4855',
        },
        {
            id: '4856_option',
            title: 'Nevada',
            value: '4856',
        },
        {
            id: '4857_option',
            title: 'New York',
            value: '4857',
        },
        {
            id: '4858_option',
            title: 'Ohio',
            value: '4858',
        },
        {
            id: '4859_option',
            title: 'Oklahoma',
            value: '4859',
        },
        {
            id: '4860_option',
            title: 'Oregon',
            value: '4860',
        },
        {
            id: '4861_option',
            title: 'Pennsylvania',
            value: '4861',
        },
        {
            id: '4862_option',
            title: 'Puerto Rico',
            value: '4862',
        },
        {
            id: '4863_option',
            title: 'Rhode Island',
            value: '4863',
        },
        {
            id: '4864_option',
            title: 'South Carolina',
            value: '4864',
        },
        {
            id: '4865_option',
            title: 'South Dakota',
            value: '4865',
        },
        {
            id: '4866_option',
            title: 'Tennessee',
            value: '4866',
        },
        {
            id: '4867_option',
            title: 'Texas',
            value: '4867',
        },
        {
            id: '4868_option',
            title: 'United States Minor Outlying Islands',
            value: '4868',
        },
        {
            id: '4869_option',
            title: 'Utah',
            value: '4869',
        },
        {
            id: '4870_option',
            title: 'Virginia',
            value: '4870',
        },
        {
            id: '4871_option',
            title: 'Virgin Islands, U.S.',
            value: '4871',
        },
        {
            id: '4872_option',
            title: 'Vermont',
            value: '4872',
        },
        {
            id: '4873_option',
            title: 'Washington',
            value: '4873',
        },
        {
            id: '4874_option',
            title: 'Wisconsin',
            value: '4874',
        },
        {
            id: '4875_option',
            title: 'West Virginia',
            value: '4875',
        },
        {
            id: '4876_option',
            title: 'Wyoming',
            value: '4876',
        },
    ],
};
