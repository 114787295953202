/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Download} from '@mui/icons-material';
import Constants from '../../../../helper/Constants';
import DisplayGridHeaderRow from '../../../common/displayTable/DisplayGridHeaderRow';
import DisplayGridSortableTableHeader from '../../../common/displayTable/DisplayGridSortableTableHeader';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import {useEffect, useMemo, useState} from 'react';
import exportTableToCSV from '../../../../helper/functions/exportTableToCSV';
import {observer} from 'mobx-react-lite';
import AssociationsTableRow from './AssociationsTableRow';
import {Site} from '../../../../types/components/ApiTypes';
import getAssociationsData from '../../../../helper/functions/getAssociationsData';
import PaginationTable from '../../../common/displayTable/PaginationTable';
import TableButton from '../../../common/buttons/TableButton';
import getMasterSerialsFromSite from '../../../../helper/functions/getMasterSerialsFromSite';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import {styled} from '@mui/material';
import getAutomationMasterSerialsFromSite from '../../../../helper/functions/getAutomationMasterSerialsFromSite';
import getAllSerialsFromSite from '../../../../helper/functions/getAllSerialsFromSite';
import useWindowSize from '../../../../helper/hooks/useWindowSize';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

interface Props {
    site: Site,
}

const AssociationsTable = observer((props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const masterSerials = useMemo(() => getMasterSerialsFromSite(props.site), [ props.site ]);
    const automationMasterSerials = useMemo(() => getAutomationMasterSerialsFromSite(props.site), [ props.site ]);
    const siteSerials = useMemo(() => getAllSerialsFromSite(props.site), [ props.site ]);

    const rows = useMemo(() => BinsenseStore.associationsData, [ BinsenseStore.associationsData ]);
    const columns = [
        {
            field: 'structure',
            headerName: Constants.STRUCTURE,
        },
        {
            field: 'serialNumber',
            headerName: Constants.SERIAL,
        },
        {
            field: 'status',
            headerName: Constants.STATUS,
        },
        {
            field: 'channel',
            headerName: Constants.CHANNEL,
        },
        {
            field: 'powerSource',
            headerName: Constants.POWER_SOURCE,
        },
        {
            field: 'fanControl',
            headerName: Constants.FAN_CONTROL,
        },
        {
            field: 'device',
            headerName: Constants.DEVICE,
        },
    ];

    useEffect(() => {
        if (!BinsenseStore.awaitingApi) {
            BinsenseStore.setAssociationsData(getAssociationsData(props.site));
        }
    }, [ props.site, BinsenseStore.awaitingApi ]);

    const renderSortableHeader = (id: string, label: string, key: string, xs: number) => (
        <DisplayGridSortableTableHeader
            id={`${ id }_header`}
            label={label}
            xs={xs}
            data={rows}
            setData={BinsenseStore.setAssociationsData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderRow = (row: any) => (
        <AssociationsTableRow
            row={row}
            site={props.site}
            masterSerials={masterSerials}
            automationMasterSerials={automationMasterSerials}
            key={`${ row.associationId }_associations_table_row`}
            siteSerials={siteSerials}
        />
    );

    return (
        <div style={{ width: '100%' }}>
            <TableButton
                id='associations_export_button'
                label={Constants.EXPORT}
                onClick={() => exportTableToCSV(columns, rows, 'associations_table')}
                icon={<Download/>}
            />
            {!BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
                <PaginationTable
                    rows={rows}
                    renderRow={renderRow}
                >
                    <DisplayGridHeaderRow sx={{marginTop: '0px'}}>
                        {renderSortableHeader('structure', Constants.STRUCTURE, 'structure', largeScreen ? 2.5 : 2)}
                        {renderSortableHeader('serial_number', Constants.SERIAL, 'serialNumber',
                            largeScreen ? 1.5 : 1.25)}
                        {renderSortableHeader('status', Constants.STATUS, 'status', largeScreen ? 1.25 : 1.5)}
                        {renderSortableHeader('power_source', Constants.POWER_SOURCE, 'powerSource',
                            largeScreen ? 1.5 : 1.75)}
                        {renderSortableHeader('fan_control', Constants.FAN_CONTROL, 'fanControl',
                            largeScreen ? 1.5 : 1.25)}
                        {renderSortableHeader('device', Constants.DEVICE, 'device', 1.5)}
                        <DisplayGridLabel
                            id='actions_header'
                            label={Constants.ACTIONS}
                            xs={largeScreen ? 2.25 : 2.75}
                            header
                        />
                    </DisplayGridHeaderRow>
                </PaginationTable>
            ) : (
                <SkeletonRoot>
                    <DashboardSkeleton height={600}/>
                </SkeletonRoot>
            )}
        </div>
    );
});

export default AssociationsTable;
