/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {ValidateFieldProps} from '../../types/components/ApiTypes';
import isPasswordValid from '../validator/isPasswordValid';
import isEmailValid from '../validator/isEmailValid';

export default function isEmailAndPasswordValid(email: ValidateFieldProps,
                                                password: ValidateFieldProps,
                                                confirmPassword: ValidateFieldProps) {
    const newEmailError = isEmailValid(email.value);
    email.setError(newEmailError);
    const newPasswordError = isPasswordValid(password.value);
    password.setError(newPasswordError);
    const newConfirmPasswordError = isPasswordValid(confirmPassword.value);
    confirmPassword.setError(newConfirmPasswordError);

    return newEmailError === '' && newPasswordError === '' && newConfirmPasswordError === '';
}
