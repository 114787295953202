/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default function isNumeric(str: string, minLength: number, type: string, maxLength?: number) {
    const expression = /^[0-9]*$/;
    if (str === '' || str.length < minLength || !expression.test(str) || (maxLength && str.length > maxLength)) {
        return `Please enter a valid ${ type }`;
    }

    return '';
}
