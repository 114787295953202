/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper, styled, Typography} from '@mui/material';
import {Dispatch, SetStateAction, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import Constants from '../../../../helper/Constants';
import Direct from '../../../../images/direct-unit.png';
import Solo from '../../../../images/solo-unit.png';
import Automation from '../../../../images/automation-unit.png';
import Live from '../../../../images/master-unit.png';
import LiveRight from '../../../../images/remote-unit.png';
import Colors from '../../../../helper/Colors';
import WebStore from '../../../../stores/WebStore';
import getTestID from '../../../../helper/functions/getTestID';

interface Props {
    label: string,
    selectedDevice: string,
    setSelectedDevice: Dispatch<SetStateAction<string>>,
    disabled?: boolean,
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

const DeviceTypePanel = observer((props: Props) => {
    const getImage = (label: string) => {
        if (label === Constants.SOLO) {
            return Solo;
        }
        if (label === Constants.DIRECT) {
            return Direct;
        }
        if (label === Constants.PLUS) {
            return Automation;
        }
        if (label === Constants.LIVE || label === Constants.MASTER) {
            return Live;
        }
        return LiveRight;
    };

    const backgroundColor = useMemo(() => {
        if (props.label.toLowerCase() === props.selectedDevice) {
            return WebStore.lightMode ? Colors.drawerUserBackgroundLight : Colors.drawerUserBackgroundDark;
        }
        return undefined;
    }, [ props.selectedDevice, WebStore.lightMode ]);

    return (
        <Paper
            {...getTestID(`bin_setup_${ props.label }`)}
            variant="outlined"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minWidth: '245px',
                cursor: !props.disabled ? 'pointer' : undefined,
            }}
            sx={{ backgroundColor }}
            onClick={!props.disabled ? () => props.setSelectedDevice(props.label.toLowerCase()) : undefined}
        >
            <Typography
                color="primary"
                style={{
                    paddingBottom: '8px',
                    opacity: props.disabled ? 0.4 : undefined,
                }}
            >
                {props.label.toUpperCase()}
            </Typography>
            <Row>
                <img
                    style={{
                        height: '60px',
                        width: 'auto',
                        opacity: props.disabled ? 0.4 : undefined,
                    }}
                    src={getImage(props.label)}
                    alt={`${ props.label } Bin Type`}
                />
                {props.label === Constants.LIVE && (
                    <img
                        style={{
                            height: '60px',
                            width: 'auto',
                            paddingLeft: '10px',
                            opacity: props.disabled ? 0.4 : undefined,
                        }}
                        src={getImage('Live right')}
                        alt={`${ Constants.LIVE } Right Bin Type`}
                    />
                )}
            </Row>
        </Paper>
    );
});

export default DeviceTypePanel;