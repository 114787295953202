/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo, useState} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import DeleteModal from '../../common/modal/DeleteModal';
import NewUserModal from './NewUserModal';
import Constants from '../../../helper/Constants';
import BinsenseStore from '../../../stores/BinsenseStore';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../common/displayTable/DisplayGridContent';
import DisplayGridActions from '../../common/displayTable/DisplayGridActions';
import {Delete, Edit} from '@mui/icons-material';
import DisplayGridChip from '../../common/displayTable/DisplayGridChip';
import getIndex from '../../../helper/functions/getIndex';

interface Props {
    row: any,
}

const UsersTable = (props: Props) => {
    const [ edit, setEdit ] = useState(false);
    const [ remove, setRemove ] = useState(false);

    const rows = useMemo(() => BinsenseStore.customerUsersData, [ BinsenseStore.customerUsersData ]);

    const onDelete = () => {
        const newBody = cloneDeep(rows);
        const index = getIndex(props.row.id, newBody);
        newBody.splice(index, 1);

        console.log('submit delete!');
    };

    const renderGridLabel = (id: string, label: string, header: boolean = false) => (
        <DisplayGridLabel
            id={`${ id }_header`}
            label={label}
            xs={3}
            header={header}
        />
    );

    return (
        <DisplayGridContent key={`${ props.row.id }_user`}>
            {renderGridLabel(String(props.row.id), props.row.email)}
            {renderGridLabel(String(props.row.id), props.row.role)}
            <DisplayGridChip
                status={props.row.status}
                xs={3}
            />
            <DisplayGridActions
                buttons={[
                    {
                        id: `${ props.row.id }_user_edit_button`,
                        onClick: () => setEdit(true),
                        icon: <Edit/>,
                        hoverText: Constants.EDIT,
                    },
                    {
                        id: `${ props.row.id }_user_delete_button`,
                        onClick: () => setRemove(true),
                        icon: <Delete/>,
                        hoverText: Constants.DELETE,
                    },
                ]}
                xs={3}
                key={`${ props.row.id }_user_actions_cell`}
                id={props.row.id}
            />
            {edit && (
                <NewUserModal
                    open
                    close={() => setEdit(false)}
                    editingId={props.row.id}
                />
            )}
            {remove && (
                <DeleteModal
                    id="delete_user_modal"
                    open
                    close={() => setRemove(false)}
                    delete={onDelete}
                    description={props.row.email}
                />
            )}
        </DisplayGridContent>
    );
};

export default UsersTable;
