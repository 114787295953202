/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useMemo, useState} from 'react';
import Constants from '../../../../helper/Constants';
import DisplayGridPaper from '../../../common/displayTable/DisplayGridPaper';
import DisplayGridHeaderRow from '../../../common/displayTable/DisplayGridHeaderRow';
import DisplayGridSortableTableHeader from '../../../common/displayTable/DisplayGridSortableTableHeader';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import {observer} from 'mobx-react-lite';
import DisplayGridCheckBox from '../../../common/displayTable/DisplayGridCheckBox';
import FanAssociationsTableRow from './FanAssociationsTableRow';
import deleteAssociationFan from '../../../../controllers/apiCalls/delete/deleteAssociationFan';
import {useSnackbar} from 'notistack';
import {Bin, Building, GrainBag} from '../../../../types/components/ApiTypes';
import getFanAssociationsData from '../../../../helper/functions/getFanAssociationsData';
import {styled} from '@mui/material';
import DashboardSkeleton from '../../../common/DashboardSkeleton';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

interface Props {
    structure: Bin | GrainBag | Building,
}

const FanAssociationsTable = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ selectAll, setSelectAll ] = useState(false);
    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);
    const [ deleteId, setDeleteId ] = useState<number | null>(null);

    const rows = useMemo(() => BinsenseStore.fanAssociationsData, [ BinsenseStore.fanAssociationsData ]);

    useEffect(() => BinsenseStore.setFanAssociationsData(getFanAssociationsData(props.structure)), [ props.structure ]);

    const renderGridLabel = (id: string, label: string, xs: number, header: boolean = false) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={xs}
            header={header}
        />
    );

    const onDelete = () => {
        deleteAssociationFan(enqueueSnackbar, Number(deleteId));
    };

    const selectAllClicked = () => {
        const clone = JSON.parse(JSON.stringify(rows));
        clone.map((row: any) => {
            row.selected = !selectAll;
            return row;
        });
        BinsenseStore.setFanAssociationsData(clone);
        setSelectAll(!selectAll);
    };

    const changeSelected = (id: number) => {
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].id === id) {
                const clone = JSON.parse(JSON.stringify(rows));
                clone[i].selected = !rows[i].selected;
                BinsenseStore.setFanAssociationsData(clone);
                break;
            }
        }
    };

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <DisplayGridPaper>
            <DisplayGridHeaderRow>
                <DisplayGridCheckBox
                    xs={2}
                    id='select_header'
                    value={selectAll}
                    onChange={selectAllClicked}
                    header
                />
                <DisplayGridSortableTableHeader
                    id="fan_id_header"
                    label={Constants.FAN_ID}
                    xs={5}
                    data={rows}
                    setData={BinsenseStore.setFanAssociationsData}
                    sortColumn={sortColumn}
                    setSortColumn={setSortColumn}
                    sortAscending={sortAscending}
                    setSortAscending={setSortAscending}
                    dataKey="fanId"
                />
                {renderGridLabel('actions_header', Constants.ACTIONS, 5, true)}
            </DisplayGridHeaderRow>
            {rows.map((row: any) => (
                <FanAssociationsTableRow
                    row={row}
                    changeSelected={changeSelected}
                    setDeleteId={setDeleteId}
                    key={`${ row.fanId }_fan_associations_table_row`}
                />
            ))}
            {deleteId !== null && (
                <ConfirmationModal
                    id={`${ deleteId }_delete_confirmation_modal`}
                    open
                    close={() => setDeleteId(null)}
                    submit={onDelete}
                    title="End Fan Association"
                    message="Are you sure you want to end this fan association?"
                />
            )}
        </DisplayGridPaper>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={600}/>
        </SkeletonRoot>
    );
});

export default FanAssociationsTable;
