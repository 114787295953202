import BinsenseStore from '../../stores/BinsenseStore';

const getAllUserSiteName = (userId?: number) => {
    const names: string[] = [];
    if (userId) {
        const user = BinsenseStore.dealerUserData.customers.find(c =>c.id === userId);
        if (user) {
            for (let i = 0; i < user.sites.length; i++) {
                names.push(user.sites[i].name.toLowerCase());
            }
        }
    } else {
        for (let i = 0; i < BinsenseStore.userData.sites.length; i++) {
            names.push(BinsenseStore.userData.sites[i].name.toLowerCase());
        }
    }

    return names;
};

export default getAllUserSiteName;