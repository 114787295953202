/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Dispatch, SetStateAction, useState} from 'react';
import {observer} from 'mobx-react-lite';
import DisplayGridHeaderRow from '../../common/displayTable/DisplayGridHeaderRow';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import DisplayGridSortableTableHeader from '../../common/displayTable/DisplayGridSortableTableHeader';
import Constants from '../../../helper/Constants';
import DisplayGridCheckBox from '../../common/displayTable/DisplayGridCheckBox';
import BinsenseStore from '../../../stores/BinsenseStore';
import {DeviceSettingsStructure} from '../../../types/components/ApiTypes';
import DeviceSettingsTableRow from './DeviceSettingsTableRow';
import PaginationTable from '../../common/displayTable/PaginationTable';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import {styled} from '@mui/material';

interface Props {
    setEditingId: Dispatch<SetStateAction<number | null>>,
    setModalOpen: Dispatch<SetStateAction<boolean>>,
    tableData: DeviceSettingsStructure[],
}

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const DeviceSettingsTable = observer((props: Props) => {
    const xs = 12 / 6;

    const [ selectAll, setSelectAll ] = useState(false);
    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const renderGridHeader = (id: string, label: string | number, key: string) => (
        <DisplayGridSortableTableHeader
            id={id}
            label={label}
            xs={xs}
            data={props.tableData}
            setData={BinsenseStore.setDeviceTableData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const selectAllClicked = () => {
        const clone = JSON.parse(JSON.stringify(props.tableData));
        clone.map((row: any) => {
            row.selected = !selectAll;
            return row;
        });
        BinsenseStore.setDeviceTableData(clone);
        setSelectAll(!selectAll);
    };

    const renderRow = (row: any) => (
        <DeviceSettingsTableRow
            setEditingId={props.setEditingId}
            setModalOpen={props.setModalOpen}
            tableData={props.tableData}
            row={row}
            key={`${ row.id }_row`}
            showDevice={row.showDevice}
        />
    );

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <PaginationTable
            rows={props.tableData}
            renderRow={renderRow}
        >
            <DisplayGridHeaderRow sx={{marginTop: '0px'}}>
                <DisplayGridCheckBox
                    xs={xs}
                    id='select_header'
                    value={selectAll}
                    onChange={selectAllClicked}
                    header
                />
                {renderGridHeader('structure_header', Constants.STRUCTURE, 'name')}
                {renderGridHeader('temp_alerts_thresholds_header', Constants.HIGH_TEMP_ALERTS, 'alerts')}
                {renderGridHeader('weekly_temp_rise_header', Constants.WEEKLY_TEMP_RISE, 'weeklyTempRise')}
                {renderGridHeader('auto_level_header', Constants.AUTO_LEVEL, 'autoLevel')}
                <DisplayGridLabel
                    id='sites_actions_header'
                    label="Actions"
                    xs={xs}
                    header
                />
            </DisplayGridHeaderRow>
        </PaginationTable>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={600}/>
        </SkeletonRoot>
    );
});

export default DeviceSettingsTable;
