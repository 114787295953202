/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import WebStore from '../../stores/WebStore';
import BinsenseStore from '../../stores/BinsenseStore';
import getIndex from './getIndex';

const getSelectedSiteIndex = () => WebStore.selectedSiteId >= 0
    ? getIndex(WebStore.selectedSiteId, BinsenseStore.userData.sites) : -1;

export default getSelectedSiteIndex;
