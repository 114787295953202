import callAPI from '../../callAPI';
import getSiteStructures from './getSiteStructures';

const checkForLatestData = (enqueueSnackbar: any, siteId:number, time: Date) => {
    callAPI(`checkForLatestData/${ siteId }/${ time }`,
        (response) => {
            if (response !== 0) {
                getSiteStructures(enqueueSnackbar, siteId, true);
            }
        },
        enqueueSnackbar
    );
};

export default checkForLatestData;