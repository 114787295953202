/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Bin, Building, GrainBag} from '../../types/components/ApiTypes';

const getCableAssociationsData = (struct: Bin | GrainBag | Building) => struct.hardware.cables.map(cable => ({
    id: cable.id,
    type: cable.type.charAt(0).toUpperCase() + cable.type.slice(1),
    smartId: cable.smartId,
    serial: cable.serial,
    selected: false,
}));

export default getCableAssociationsData;
