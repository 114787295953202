/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import AreaChart from '../../../../common/charts/AreaChart';
import { BinGraphData, XAxisType } from '../../../../../types/components/ApiTypes';
import Colors from '../../../../../helper/Colors';
import { Dispatch, SetStateAction } from 'react';

interface Props {
    data: BinGraphData[],
    id: string
    legend_show: boolean
    height: string | number
    setTimeUnit: Dispatch<SetStateAction<string>>
    setStart: Dispatch<SetStateAction<moment.Moment>>
    setEnd: Dispatch<SetStateAction<moment.Moment>>

}

const ViewDeviceAlertChart = (props: Props) => {
    var alertsFound = false;

    var labels:string[] = [];

    const alertdata: { x: string; y: (number | (() => number))[]; }[] = [];

    var CGCHighStartDate = 0;
    var lowVoltageStart = 0;
    var thresholdHighStart = 0;
    var thresholdSysStart = 0;
    var thresholdLowStart = 0;
    var roiStart = 0;
    var startDate = 0;
    var endDate = 0;
    var lastPointAlert = '';
    var lastPointTime = 0;

    props.data.forEach(point => {
        if (startDate == 0) {
            startDate = point.dt;
        }
        endDate = point.dt;

        if ((point.alert != null && lastPointAlert != '' &&
        lastPointAlert != null && point.alert != lastPointAlert) ||
        (point.alert == null && lastPointAlert != null && lastPointAlert != '')) {
            // end the previous alert
            switch (lastPointAlert) {
                case 'CGCHIGH':
                    alertdata.push({
                        x: 'CGC',
                        y: [ CGCHighStartDate, point.dt ],
                    });
                    CGCHighStartDate = 0;
                    labels.push('CGC');
                    break;
                case 'THRESHLOW':
                    alertdata.push({
                        x: 'Low Temp',
                        y: [ thresholdLowStart, point.dt ],
                    });
                    thresholdLowStart = 0;
                    labels.push('Low');
                    break;
                case 'THRESHHIGH':
                    alertdata.push({
                        x: 'High Temp',
                        y: [ thresholdHighStart, point.dt ],
                    });
                    thresholdHighStart = 0;
                    labels.push('High');
                    break;
                case 'THRESHSYS':
                    alertdata.push({
                        x: 'Sys Temp',
                        y: [ thresholdSysStart, point.dt ],
                    });
                    thresholdSysStart = 0;
                    labels.push('Sys');
                    break;
                case 'RISING':
                    alertdata.push({
                        x: 'Temp Rise',
                        y: [ roiStart, point.dt ],
                    });
                    roiStart = 0;
                    labels.push('Rise');
                    break;
            }
        }

        if (point.voltageAlert == 'LOW' && lowVoltageStart == 0) {
                lowVoltageStart = point.dt;
                alertsFound = true;
        }
        if (point.alert == 'CGCHIGH' && CGCHighStartDate == 0) {
            CGCHighStartDate = point.dt;
            alertsFound = true;
        }
        if (point.alert == 'THRESHLOW' && thresholdLowStart == 0) {
            thresholdLowStart = point.dt;
            alertsFound = true;
        }
        if (point.alert == 'THRESHHIGH' && thresholdHighStart == 0) {
            thresholdHighStart = point.dt;
            alertsFound = true;
        }
        if (point.alert == 'THRESHSYS' && thresholdSysStart == 0) {
            thresholdSysStart = point.dt;
            alertsFound = true;
        }
        if (point.alert == 'RISING' && roiStart == 0) {
            roiStart = point.dt;
            alertsFound = true;
        }
        if (point.voltageAlert == 'NORMAL' && lowVoltageStart != 0)
        {
            // end of a low voltage alert
            alertdata.push({
                x: 'Low Voltage',
                y: [ lowVoltageStart, point.dt ],
            });
            lowVoltageStart = 0;
        }

        lastPointAlert = point.alert;
        lastPointTime = point.dt;
    });

    // if we have open alerts at the end of the array, include them
    if (CGCHighStartDate != 0) {
        alertdata.push({
            x: 'CGC',
            y: [ CGCHighStartDate, lastPointTime ],
        });
    }
    if (lowVoltageStart != 0) {
        alertdata.push({
            x: 'Low Voltage',
            y: [ lowVoltageStart, lastPointTime ],
        });
    }
    if (thresholdHighStart != 0) {
        alertdata.push({
            x: 'High Temp',
            y: [ thresholdHighStart, lastPointTime ],
        });
    }
    if (thresholdLowStart != 0) {
        alertdata.push({
            x: 'Low Temp',
            y: [ thresholdLowStart, lastPointTime ],
        });
    }
    if (thresholdSysStart != 0) {
        alertdata.push({
            x: 'Sys Temp',
            y: [ thresholdSysStart, lastPointTime ],
        });
    }
    if (roiStart != 0) {
        alertdata.push({
            x: 'Temp Rise',
            y: [ roiStart, lastPointTime ],
        });
    }

    const series = [
        {
            name: 'Alerts',
            type: 'rangeBar',
            data: alertdata,
        },
    ];

    const yaxis: ApexYAxis[] = [
        {
            seriesName: 'Alerts',
            title: {
                text: 'Alerts',
            },
            opposite: false,
            labels: {
                  minWidth: 80,
                  maxWidth: 90,
                  style: {
                    fontFamily: 'Public Sans',
                    fontSize: '7pt',
                  },
            },
            show: true,
        },
    ];

    const xaxis: ApexXAxis = {
        type: XAxisType.dateTime,
        tooltip: {
            enabled: false,
        },
        labels: {
            show: true,
        },
        min: startDate,
        max: endDate,
    };

    const gstroke : ApexStroke = {};
    gstroke.width = 3;
    gstroke.curve = 'smooth';
    gstroke.dashArray = 0;

    if (!alertsFound) {
        return null;
    }

    const popt : ApexPlotOptions = {
        bar: {
            horizontal: true,
            barHeight: '80%',
            dataLabels: {
                hideOverflowingLabels: false,
            },
            rangeBarGroupRows: true,
        },

    };

    return (
        <AreaChart
            series={series}
            yaxis={yaxis}
            legendPosition="bottom"
            showLegend={true}
            id={props.id}
            type="rangeBar"
            showXAxis={true}
            height={props.height}
            stroke={gstroke}
            colors={[ Colors.alertColor ]}
            plotOptions={popt}
            xAxis={xaxis}
            showDataLabels={false}
            dataLabelFormatter={(val: any, opt: any) => labels[opt.dataPointIndex]}
            setTimeUnit={props.setTimeUnit}
            setStart={props.setStart}
            setEnd={props.setEnd}
        />
    );
};

export default ViewDeviceAlertChart;
