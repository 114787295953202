/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import AreaChart from '../../../../common/charts/AreaChart';
import { BinGraphData } from '../../../../../types/components/ApiTypes';
import Colors from '../../../../../helper/Colors';
import { Dispatch, SetStateAction } from 'react';

interface Props {
    data: BinGraphData[],
    id: string
    legend_show: boolean
    height: string | number
    setTimeUnit: Dispatch<SetStateAction<string>>
    setStart: Dispatch<SetStateAction<moment.Moment>>
    setEnd: Dispatch<SetStateAction<moment.Moment>>
}

const ViewDeviceGrainChart = (props: Props) => {
    const series = [
        {
            name: 'Bin Level (%)',
            type: 'line',
            data: props.data.map(dataPoint => [ dataPoint.dt, dataPoint.grainLevel ]),
        },
    ];

    const yaxis: ApexYAxis[] = [
        {
            seriesName: 'Bin Level (%)',
            title: {
                text: 'Bin Level (%)',
            },
            opposite: false,
            labels: {
                  minWidth: 80,
                  maxWidth: 80,
            },
            min: 0,
            max: 100,
            tickAmount: 2,
        },
    ];

    const gstroke : ApexStroke = {
        width: 3,
        curve: 'smooth',
        dashArray: 0,
    };

    const popt : ApexPlotOptions = {};

    return (
        <AreaChart
            series={series}
            yaxis={yaxis}
            legendPosition="bottom"
            showLegend={props.legend_show}
            id={props.id}
            type="line"
            showXAxis={true}
            height={props.height}
            stroke={gstroke}
            colors={[ Colors.fanColor ]}
            plotOptions={popt}
            setTimeUnit={props.setTimeUnit}
            setStart={props.setStart}
            setEnd={props.setEnd}

        />
    );
};

export default ViewDeviceGrainChart;
