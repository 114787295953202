import getIndex from './getIndex';
import BinsenseStore from '../../stores/BinsenseStore';
import Constants from '../Constants';

const getGrain = (id: number) => {
    const grainIndex = getIndex(id, BinsenseStore.dataModel.grains);
    return grainIndex >= 0 ? BinsenseStore.dataModel.grains[grainIndex]
        : {
            bushelsPerTonne: 0.00,
            cgc: false,
            id: 0,
            name: Constants.NA,
            minimumEmc: '0',
            maximumEmc: '0',
        };
};

export default getGrain;
