/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {BuildingData, GrainBagData, GrainBinData, GrainPileData} from '../../types/components/ApiTypes';
import Constants from '../Constants';
import getUnitFields from './getUnitFields';

const getRemoteUnitFields = (sn: string, powerSource: string, associatedMaster: string, isAutomation: boolean) => ({
    ...getUnitFields(sn, powerSource, isAutomation),
    masterSerial: associatedMaster,
});

const getRemoteUnitsFromStructureData = (data: GrainBinData | GrainBagData | GrainPileData | BuildingData) => {
    if ((data.deviceType === Constants.REMOTE.toLowerCase() ||
            data.device.toLowerCase() === Constants.PLUS.toLowerCase()) &&
        data.associatedMaster) {
        const remoteSerials = data.sn.filter(s => s !== data.associatedMaster);
        return remoteSerials.map(sn => (getRemoteUnitFields(sn,
            data.powerSource, data.associatedMaster,
            data.device.toLowerCase() === Constants.PLUS.toLowerCase())));
    }
    if (data.deviceType === Constants.MASTER.toLowerCase() && data.sn.length > 1) {
        return data.sn.slice(1).map(sn => (getRemoteUnitFields(sn,
            data.powerSource, data.sn[0],
            data.device.toLowerCase() === Constants.PLUS.toLowerCase())));
    }
    return [];
};

export default getRemoteUnitsFromStructureData;
