/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import BinsenseStore from '../../../stores/BinsenseStore';
import ConfirmationModal from './ConfirmationModal';

const ConfirmModal = observer(() => {
    const onSubmit = () => {
        BinsenseStore.confirm.onConfirm();
    };

    const close = () => {
        BinsenseStore.setConfirm({
            title: '',
            message: '',
            onConfirm: () => {
            },
        });
    };

    return (
        <ConfirmationModal
            id="confirm_modal"
            open
            close={close}
            submit={onSubmit}
            title={BinsenseStore.confirm.title}
            message={BinsenseStore.confirm.message}
        />
    );
});

export default ConfirmModal;
