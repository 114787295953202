/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Power} from '@mui/icons-material';
import BatterySymbol from './BatterySymbol';

interface Props {
    acPower?: boolean,
    batteryLevel: number,
}

const PowerSymbol = (props: Props) => props.acPower ? <Power/> : <BatterySymbol batteryLevel={props.batteryLevel}/>;

export default PowerSymbol;
