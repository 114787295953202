/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import {Dispatch, SetStateAction, useMemo, useState} from 'react';
import {
    AutomationData,
    BinType, BuildingData,
    GrainBagData,
    GrainBinData,
    // GrainPileData,
    MarkerData,
    MarkerDisplayData,
    MarkerTooltipData,
    MarkerTypes,
    StructIdSerials
} from '../../../types/components/ApiTypes';
import BinLight from '../../../images/siteLegend/FB-GG.svg';
import BinDark from '../../../images/siteLegend/FB-YY.svg';
import HopperBinLight from '../../../images/siteLegend/HB-GG.svg';
import HopperBinDark from '../../../images/siteLegend/HB-YY.svg';
import GrainBagLight from '../../../images/siteLegend/GB-GG.svg';
import GrainBagDark from '../../../images/siteLegend/GB-YY.svg';
import GrainPileLight from '../../../images/siteLegend/GP-GG.svg';
import GrainPileDark from '../../../images/siteLegend/GP-YY.svg';
import BuildingLight from '../../../images/siteLegend/LM-GG.svg';
import BuildingDark from '../../../images/siteLegend/LM-YY.svg';
import AutomationLight from '../../../images/siteLegend/AU-GG.svg';
import AutomationDark from '../../../images/siteLegend/AU-YY.svg';
import ToolbarCategory from './siteToolbar/ToolbarCategory';
import BinSetupModal from './binSetupModal/BinSetupModal';
// import PileSetupModal from './newSiteWizard/PileSetupModal';
import BagSetupModal from './newSiteWizard/BagSetupModal';
import WebStore from '../../../stores/WebStore';
import {observer} from 'mobx-react-lite';
import AddAutomationMasterHubModal from './binSetupModal/AddAutomationMasterHubModal';
import ToolbarButton from './siteToolbar/ToolbarButton';
import TooltipStore from '../../../stores/TooltipStore';
import TooltipMessages from '../../../helper/TooltipMessages';
import getNewId from '../../../helper/functions/getNewId';
import getMasterSerialsFromMarkers from '../../../helper/functions/getMasterSerialsFromMarkers';
import disableAutomationOption from '../../../helper/functions/disableAutomationOption';
import InfoTooltip from '../../common/InfoTooltip';
import BuildingSetupModal from './newSiteWizard/BuildingSetupModal';
import getAutomationMasterSerialsFromMarkers from '../../../helper/functions/getAutomationMasterSerialsFromMarkers';
import getStructNamesFromMarkers from '../../../helper/functions/getStructNamesFromMarkers';
import getSerialsFromMarkers from '../../../helper/functions/getSerialsFromMarkers';
import getSelectedSiteIndex from '../../../helper/functions/getSelectedSiteIndex';
import getAllSerialsFromSite from '../../../helper/functions/getAllSerialsFromSite';
import getSite from '../../../helper/functions/getSite';
import Constants from '../../../helper/Constants';

const Root = styled('div')({
    marginBottom: '16px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

interface Props {
    viewState: {
        longitude: number,
        latitude: number,
        zoom: number,
    },
    markerData: MarkerData[],
    setMarkerData: Dispatch<SetStateAction<MarkerData[]>>,
    iconOnClick?: () => void,
    siteId: number,
    onMarkerUpdate?: () => void,
}

const SIZE = 50;

const CreateSiteToolbar = observer((props: Props) => {
    const [ createBinType, setCreateBinType ] = useState(BinType.flat);
    const [ isBinSetupModalOpen, setIsBinSetupModalOpen ] = useState(false);
    // const [ addPileModalOpen, setAddPileModalOpen ] = useState(false);
    const [ addBagModalOpen, setAddBagModalOpen ] = useState(false);
    const [ addBuildingModalOpen, setAddBuildingModalOpen ] = useState(false);
    const [ addAutomationMaster, setAddAutomationMaster ] = useState(false);
    const [ priorStructData, setPriorStructData ] = useState<MarkerData[]> ([]);

    const disableAutomation = useMemo(() =>
        disableAutomationOption(props.markerData), [ props.markerData ]);

    const images = useMemo(() => WebStore.lightMode
        ? [ BinLight, HopperBinLight, GrainPileLight, GrainBagLight, BuildingLight, AutomationLight ]
        : [ BinDark, HopperBinDark, GrainPileDark, GrainBagDark, BuildingDark, AutomationDark ],
        [ WebStore.lightMode ]);

    const masterSerials = useMemo(() => getMasterSerialsFromMarkers(props.markerData), [ props.markerData ]);
    const automationMasterSerials =
        useMemo(() => getAutomationMasterSerialsFromMarkers(props.markerData), [ props.markerData ]);

    const markerStructNames = useMemo(() => getStructNamesFromMarkers(props.markerData), [ props.markerData ]);
    const markerSerials = useMemo(() => getSerialsFromMarkers(props.markerData), [ props.markerData ]);
    const siteSerials = useMemo(() => {
        let serials: StructIdSerials[] = [];
        const index = getSelectedSiteIndex();
        if (index >= 0 ) {
            serials = getAllSerialsFromSite(getSite(index));
        }
        return serials;
    }, [ props.siteId ]);

    const addIconButton = (display: MarkerDisplayData, tooltipInfo?: MarkerTooltipData) => {
        props.setMarkerData(prevState => {
            const newState = cloneDeep(prevState);
            const newPriorStructData = cloneDeep(priorStructData);
            const marker: MarkerData = {
                id: getNewId(props.markerData),
                longitude: props.viewState.longitude,
                latitude: props.viewState.latitude,
                draggable: true,
                display,
            };
            if (tooltipInfo) {
                marker.tooltip = tooltipInfo;
            }
            newState.push(marker);
            newPriorStructData.unshift(marker);
            setPriorStructData(newPriorStructData);
            return newState;
        });
        if (props.onMarkerUpdate) {
            props.onMarkerUpdate();
        }
    };

    const addBinMarker = (grainBinData: GrainBinData) => {
        addIconButton({
                type: MarkerTypes.bin,
                grainBinData,
            },
            {
                name: grainBinData.name,
                editable: true,
            });
    };

    // const addPileMarker = (grainPileData: GrainPileData) => {
    //     addIconButton({
    //         type: MarkerTypes.grainPile,
    //         grainPileData,
    //     }, {
    //         name: grainPileData.name,
    //         editable: true,
    //     });
    // };

    const addBagMarker = (grainBagData: GrainBagData) => {
        addIconButton({
            type: MarkerTypes.grainBag,
            grainBagData,
        }, {
            name: grainBagData.name,
            editable: true,
        });
    };

    const addBuildingMarker = (buildingData: BuildingData) => {
        addIconButton({
            type: MarkerTypes.building,
            buildingData,
        }, {
            name: buildingData.name,
            editable: true,
        });
    };

    const addAutomationMarker = (automationHubData: AutomationData) => {
        addIconButton({
            type: MarkerTypes.automation,
            automationHubData,
        }, {
            name: automationHubData.serial,
            editable: true,
        });
    };

    const getPriorStructData = (priorStructDataList: MarkerData[], currentStructType: MarkerTypes) => {
        if (priorStructDataList.length > 0) {
            let priorStruct: any = null;
            const priorMarker = priorStructDataList.find((marker: MarkerData) => {
                if (marker.display?.type === currentStructType) {
                    if (currentStructType !== MarkerTypes.bin ||
                        currentStructType === MarkerTypes.bin && marker.display?.grainBinData?.type === createBinType) {
                        return marker;
                    }
                }
            });
            if (priorMarker) {
                switch (currentStructType) {
                    case MarkerTypes.bin:
                        priorStruct = cloneDeep(priorMarker.display?.grainBinData);
                        break;
                    case MarkerTypes.grainPile:
                        priorStruct = cloneDeep(priorMarker.display?.grainPileData);
                        break;
                    case MarkerTypes.grainBag:
                        priorStruct = cloneDeep(priorMarker.display?.grainBagData);
                        break;
                    case MarkerTypes.building:
                        priorStruct = cloneDeep(priorMarker.display?.buildingData);
                        break;
                    default:
                        break;
                }
                if (priorStruct) {
                    // Reset unused fields when pre-populating input fields for a new struct
                    priorStruct.name = '';
                    priorStruct.sn = [ '' ];
                    priorStruct.deviceType = '';
                    if (priorStruct.device === Constants.NOT_MONITORED) {
                        priorStruct.device = '';
                    }
                    return priorStruct;
                }
            }
        }
        return undefined;
    };

    return (
        <Root>
            <Row>
                <ToolbarCategory
                    title="Structures"
                    isFirst
                    size={SIZE}
                    buttons={[
                        {
                            id: 'add_devices_flat_bin_button',
                            title: 'Flat Bin',
                            src: images[0],
                            onClick: () => {
                                setCreateBinType(BinType.flat);
                                setIsBinSetupModalOpen(true);
                            },
                        },
                        {
                            id: 'add_devices_hopper_bin_button',
                            title: 'Hopper Bin',
                            src: images[1],
                            onClick: () => {
                                setCreateBinType(BinType.hopper);
                                setIsBinSetupModalOpen(true);
                            },
                        },
                        // {
                        //     id: 'add_devices_grain_pile_button',
                        //     title: 'Grain Pile',
                        //     src: images[2],
                        //     onClick: () => setAddPileModalOpen(true),
                        // },
                        {
                            id: 'add_devices_grain_bag_button',
                            title: 'Grain Bag',
                            src: images[3],
                            onClick: () => setAddBagModalOpen(true),
                        },
                        {
                            id: 'add_devices_building_button',
                            title: 'Building',
                            src: images[4],
                            onClick: () => setAddBuildingModalOpen(true),
                        },
                    ]}
                />
                {disableAutomation && (
                    <Row>
                        <ToolbarButton
                            id='add_devices_automation_master_hub_button'
                            title={'Bin-Sense Plus Automation Hub'}
                            src={images[5]}
                            onClick={() => setAddAutomationMaster(true)}
                            size={SIZE}
                        />
                        <InfoTooltip
                            id="automation_hub_tooltip_button"
                            open={TooltipStore.beginTooltips && TooltipStore.showAutomationHubMessages}
                            message={TooltipMessages.AUTOMATION_HUB}
                            onClick={() => TooltipStore.setShowAutomationHubMessages(false, true)}
                        />
                    </Row>
                )}
                {/*<MapLegend/>*/}
            </Row>
            {isBinSetupModalOpen && (
                <BinSetupModal
                    open
                    close={() => setIsBinSetupModalOpen(false)}
                    createBinType={createBinType}
                    onSubmit={addBinMarker}
                    masterSerials={masterSerials}
                    automationMasterSerials={automationMasterSerials}
                    disableAutomation={disableAutomation}
                    markerStructNames={markerStructNames}
                    markerSerials={markerSerials}
                    siteSerials={siteSerials}
                    grainBinData={getPriorStructData(priorStructData, MarkerTypes.bin)}
                    siteId={props.siteId}
                />
            )}
            {/*{addPileModalOpen && (*/}
            {/*    <PileSetupModal*/}
            {/*        open*/}
            {/*        close={() => setAddPileModalOpen(false)}*/}
            {/*        onSubmit={addPileMarker}*/}
            {/*        masterSerials={masterSerials}*/}
            {/*        markerStructNames={markerStructNames}*/}
            {/*        markerSerials={markerSerials} */}
            {/*        siteSerials={siteSerials} */}
            {/*    />*/}
            {/*)}*/}
            {addBagModalOpen && (
                <BagSetupModal
                    open
                    close={() => setAddBagModalOpen(false)}
                    onSubmit={addBagMarker}
                    masterSerials={masterSerials}
                    automationMasterSerials={automationMasterSerials}
                    disableAutomation={disableAutomation}
                    markerStructNames={markerStructNames}
                    markerSerials={markerSerials}
                    siteSerials={siteSerials}
                    grainBagData={getPriorStructData(priorStructData, MarkerTypes.grainBag)}
                    siteId={props.siteId}
                />
            )}
            {addBuildingModalOpen && (
                <BuildingSetupModal
                    open
                    close={() => setAddBuildingModalOpen(false)}
                    onSubmit={addBuildingMarker}
                    masterSerials={masterSerials}
                    automationMasterSerials={automationMasterSerials}
                    disableAutomation={disableAutomation}
                    markerStructNames={markerStructNames}
                    markerSerials={markerSerials}
                    siteSerials={siteSerials}
                    buildingData={getPriorStructData(priorStructData, MarkerTypes.building)}
                    siteId={props.siteId}
                />
            )}
            {addAutomationMaster && (
                <AddAutomationMasterHubModal
                    open
                    close={() => setAddAutomationMaster(false)}
                    onSubmit={addAutomationMarker}
                />
            )}
        </Root>
    );
});

export default CreateSiteToolbar;
