/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect} from 'react';
import {styled} from '@mui/material';
import Drawer from '../../drawer/Drawer';
import Constants from '../../../helper/Constants';
import Banner from '../../banner/Banner';
import {observer} from 'mobx-react-lite';
import WebStore from '../../../stores/WebStore';
import ConfirmModal from '../../common/modal/ConfirmModal';
import BinsenseStore from '../../../stores/BinsenseStore';
import GeneralDealerDrawer from '../../drawer/lists/GeneralDealerDrawer';
import DealerPreferences from '../contentPanels/DealerPreferences';
import DealerMyUsers from '../contentPanels/DealerMyUsers';
import DealerMyCustomers from '../contentPanels/DealerMyCustomers';
import DealerReports from '../contentPanels/DealerReports';
import CreateSite from '../contentPanels/CreateSite';
import {useSnackbar} from 'notistack';
import Alerts from '../../customer/contentPanels/Alerts';
import ManagementDealerDrawer from '../../drawer/lists/ManagementDealerDrawer';
import getDealerUserModel from '../../../controllers/apiCalls/get/getDealerUserModel';
import getDataModel from '../../../controllers/apiCalls/get/getDataModel';
import DealerAddNew from '../contentPanels/DealerAddNew';
import checkForUpdate from '../../../controllers/apiCalls/get/checkForUpdate';

const Root = styled('div')(({theme}) => ({
    display: 'flex',
    // @ts-ignore
    backgroundColor: theme.palette.primary.background,
    height: '100vh',
    width: '100%',
    flexDirection: 'row',
}));

const Container = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const MainDealer = observer(() => {
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        getDataModel(enqueueSnackbar);
        getDealerUserModel(enqueueSnackbar);
        const interval = setInterval(() => checkForUpdate(enqueueSnackbar, BinsenseStore.dealerUserData.generated),
            1800000);
        return () => clearInterval(interval);
    }, []);

    const renderContentPanel = () => {
        switch (WebStore.selectedDrawer) {
            case Constants.MY_CUSTOMERS:
                return <DealerMyCustomers/>;
            case Constants.MY_USERS:
                return <DealerMyUsers/>;
            case Constants.CREATE_SITE:
                // clear stored site structures before creating a new one
                BinsenseStore.setSiteStructures({
                    id: -1,
                    automationHubs: [],
                    bins: [],
                    grainBags: [],
                    buildings: [],
                    structNames: [],
                    dataGeneratedTime: new Date(),
                });
                return <CreateSite/>;
            case Constants.REPORTS:
                return <DealerReports/>;
            case Constants.ADD_NEW:
                return <DealerAddNew/>;
            case Constants.QUOTES:
                return <div>Quote tool opened in new window</div>;
            case Constants.PREFERENCES:
                return <DealerPreferences/>;
            case Constants.ALERTS:
                return <Alerts dealer/>;
            default:
                return <div/>;
        }
    };

    return (
        <Root>
            <Drawer
                generalList={GeneralDealerDrawer}
                managementList={ManagementDealerDrawer}
            />
            <Container>
                <Banner/>
                {renderContentPanel()}
                {(BinsenseStore.confirm.title !== '' && BinsenseStore.confirm.message !== '') && (
                    <ConfirmModal/>
                )}
            </Container>
        </Root>
    );

});

export default MainDealer;
