/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import CarouselIndicator from './navigation/CarouselIndicator';
import CarouselNextPrevButtons from './navigation/CarouselNextPrevButtons';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    paddingTop: '0px',
    position: 'absolute',
    top: 0,
    width: '100%',
});

interface Props {
    data: any[],
    curIndex: number,
    setCurIndex: (newValue: number) => void,
    onPrevClick: () => void,
    onNextClick: () => void,
}

const CarouselNavigation = (props: Props) => (
    <Row>
        <div>
            {props.data.map((display, index) => (
                <CarouselIndicator
                    key={`carousel_indicator_${ index }`}
                    active={props.curIndex === index}
                    onClick={() => props.setCurIndex(index)}
                />
            ))}
        </div>
        <CarouselNextPrevButtons
            onPrevClick={props.onPrevClick}
            onNextClick={props.onNextClick}
        />
    </Row>
);

export default CarouselNavigation;
