/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import { Site} from '../../types/components/ApiTypes';
import Constants from '../Constants';
import getSiteReportData from './getSiteReportData';

const siteMonitoredOnlyBySolo = (site: Site) => {
    const data = getSiteReportData(site);
    let hasSolo = false;
    let hasRemote = false;

    data.forEach((bin) => {
        bin.devices.forEach((device) => {
            if (device.device.toLowerCase() === Constants.SOLO.toLowerCase()) {
                hasSolo = true;
            } else {
                hasRemote = true;
            }
        });
    });
    return (hasSolo && !hasRemote);

};

export default siteMonitoredOnlyBySolo;