import callAPI from '../../callAPI';
import UIStateStore from '../../../stores/UIStateStore';

const getPaymentForm = (enqueueSnackbar: any, setLoading: any, setHtml: any, userId: any) => {
    callAPI(userId ? `payments/paymentForm/${ userId }` : 'payments/paymentForm',
            (response) => {
                if (response != null && response.toString().length > 0) {
                    window.history.pushState({}, '', '/'); // this allows back arrow to redirect to binsense from stripe
                    setLoading(false);
                    setHtml(response.toString());
                    UIStateStore.setRedirectedToStripe(true);
                }
            },
            enqueueSnackbar
        );
};

export default getPaymentForm;