/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import DisplayGridContent from '../../common/displayTable/DisplayGridContent';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';

interface Props {
    data: any,
}

const InventoryTableRowSummary = (props: Props) => {
    const getLabel = (id: string, label: string, xs: number, lengthLimit?: number) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={xs}
            lengthLimit={lengthLimit}
        />
    );

    return (
        <div style={{ width: '100%' }}>
            <DisplayGridContent key={`${ props.data.id }_customer`}>
                {getLabel(`${ props.data.site }_site_cell`, props.data.site, 2, 10)}
                {getLabel(`${ props.data.site }_commodity_cell`, '--', 2)}
                {getLabel(`${ props.data.site }_bushels_cell`, '--', 8.0 / 5.0)}
                {getLabel(`${ props.data.site }_min_temp_cell`, '--', 8.0 / 5.0)}
                {getLabel(`${ props.data.site }_max_temp_cell`, '--', 8.0 / 5.0)}
                {getLabel(`${ props.data.site }_min_moisture_cell`, '--', 8.0 / 5.0)}
                {getLabel(`${ props.data.site }_max_moisture_cell`, '--', 8.0 / 5.0)}
            </DisplayGridContent>
        </div>
    );
};

export default InventoryTableRowSummary;
