/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import ErrorMessages from '../helper/ErrorMessages';
import {IGTResponse} from '../types/components/ApiTypes';
import {EnqueueSnackbar} from '../types/SnackbarTypes';
import errorSnackbarOptions from '../helper/errorSnackbarOptions';

const showStatusFailMessage = (receivedServerData: IGTResponse<Object>, enqueueSnackbar: EnqueueSnackbar) => {
    if (receivedServerData.displayMessage && receivedServerData.message) {
        enqueueSnackbar(receivedServerData.message, errorSnackbarOptions);
    } else {
        enqueueSnackbar(ErrorMessages.UNEXPECTED, errorSnackbarOptions);
    }
};

export default showStatusFailMessage;
