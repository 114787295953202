/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Chip, styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {PowerSharp} from '@mui/icons-material';
import { AutomationHubs } from '../../../../types/components/ApiTypes';
import getTimeSince from '../../../../helper/functions/getTimeSince';

const Root = styled('div')({
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

interface Props {
    hub: AutomationHubs,
}

const StorageGridAutomationHubHeader = observer((props: Props) => {
    const masterUnits = props.hub.hardware?.masterUnits ? props.hub.hardware.masterUnits : [];
    const status = masterUnits.length > 0 ? masterUnits[0].status.reportingStatus : '';
    return (
        <Root>
            <Row>
                <Typography
                    paddingRight="8px"
                    fontWeight="bold"
                >
                    {props.hub.name}
                </Typography>
                <Chip
                    label={status}
                    color={status === 'Online' ? 'success' : 'error'}
                    size="small"
                    style={{ marginLeft: '8px' }}
                />
            </Row>
            <Row>
                <div style={{ paddingRight: '4px' }}>
                    <Typography fontSize={11}>
                        <b>Automation Hub:</b> {props.hub.hardware.masterUnits.length > 0
                            ? props.hub.hardware.masterUnits[0].serial : ''}
                    </Typography>
                    <Typography fontSize={11}>
                        {getTimeSince(props.hub.status.lastReading)}
                    </Typography>
                </div>

                <PowerSharp/>
            </Row>
        </Root>
    );
});

export default StorageGridAutomationHubHeader;
