/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import DisplayGridItem from './DisplayGridItem';
import {observer} from 'mobx-react-lite';
import {Dispatch, SetStateAction} from 'react';
import SortableTableHeaderButton from './SortableTableHeaderButton';

interface Props {
    id: string,
    label: string | number,
    xs: number,
    data: any[],
    setData: (prevState: any[]) => void,
    sortColumn: string,
    setSortColumn: Dispatch<SetStateAction<string>>,
    sortAscending: boolean,
    setSortAscending: Dispatch<SetStateAction<boolean>>,
    dataKey: string,
    fontSize?: number,
}

const DisplayGridSortableTableHeader = observer((props: Props) => (
    <DisplayGridItem
        xs={props.xs}
        header
    >
        <SortableTableHeaderButton {...props}/>
    </DisplayGridItem>
));

export default DisplayGridSortableTableHeader;
