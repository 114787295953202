/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import callAPI from '../../callAPI';
import { cloneDeep } from 'lodash';

const getDealerListForNewCustomer = (enqueueSnackbar: any, setDealerOptions: any) => {
    callAPI('dealerList',
        (response) => {
            const dealers = cloneDeep(response);

            setDealerOptions(dealers.map((dealer: { name: any; id: { toString: () => any; }; }) => (
                {
                    title: dealer.name,
                    id: `${ dealer.id }_option`,
                    value: dealer.id.toString(),
                }
            )));
        },
        enqueueSnackbar
    );
};

export default getDealerListForNewCustomer;