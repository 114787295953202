/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import DisplayGridPaper from '../../common/displayTable/DisplayGridPaper';
import WebStore from '../../../stores/WebStore';
import Colors from '../../../helper/Colors';
import {styled, Typography} from '@mui/material';
import Constants from '../../../helper/Constants';
import BinsenseStore from '../../../stores/BinsenseStore';
import {useMemo} from 'react';
import CableCommunicationSummaryTableRow from './CableCommunicationSummaryTableRow';
import StatusCommunicationSummaryTableRow from './StatusCommunicationSummaryTableRow';
import useWindowSize from '../../../helper/hooks/useWindowSize';

const TitleRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '12px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    marginLeft: '0px',
    padding: '8px',
    width: '100%',
    height: 100,
});

const HeaderWrapper = styled('div')({
    flex: 1,
    width: 15,
});

const RotatedWrapper = styled('div')({
    width: 80,
});

const BinColumn = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '10%',
});

interface Props {
    isCable: boolean,
}

const CommunicationSummaryTable = observer((props: Props) => {
    const FONT_SIZE = 12;

    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);

    const data = useMemo(() => BinsenseStore.communicationTableData.cableCommunication,
        [ BinsenseStore.communicationTableData ]);

    const headerColor = useMemo(() => {
        if (WebStore.lightMode) {
            return Colors.drawerUserBackgroundLight;
        }
        return '#424242';
    }, [ WebStore.lightMode ]);

    const getHeader = (header: string) => {
        const dateArray = header.split(' ');
        const timeArray = dateArray[3].split(':');
        return (
            <Typography
                fontWeight="bold"
                fontSize={FONT_SIZE}
            >
                { dateArray[1] } { dateArray[2] } { timeArray[0] }:{ timeArray[1] }
            </Typography>
        );
    };

    return (
        <DisplayGridPaper>
            <TitleRow
                style={{ backgroundColor: headerColor }}
            >
                <BinColumn>
                    <Typography
                        fontWeight="bold"
                        fontSize={FONT_SIZE}
                    >
                        {Constants.BIN}
                    </Typography>
                </BinColumn>
                {data.columns.map((column, index) => (
                    <HeaderWrapper key={index}>
                        <RotatedWrapper
                            style={{
                                transform: `translate(-${ !props.isCable && windowWidth > 1600 ? 30 : 40 }%, 0%) rotate(90deg)`,
                            }}
                        >
                            {getHeader(column.header)}
                        </RotatedWrapper>
                    </HeaderWrapper>
                ))}
            </TitleRow>
            {data.rows.map((row, index) => props.isCable ? (
                <CableCommunicationSummaryTableRow
                    key={`${ index }_cable_row`}
                    row={row}
                    index={index}
                />
            ) : (
                <StatusCommunicationSummaryTableRow
                    key={`${ index }_cable_row`}
                    row={row}
                    index={index}
                />
            ))}
        </DisplayGridPaper>
    );
});

export default CommunicationSummaryTable;
