/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {action, makeObservable, observable} from 'mobx';
import Constants from '../helper/Constants';

class TooltipStore {
    beginTooltips = false;

    showWeatherMessages = true;

    showAddDeviceMessages = true;

    showSiteLocationMessages = true;

    showInventoryMessages = true;

    showDifferencesMessages = true;

    showAmbientMessages = true;

    showPlenumConditionsMessages = true;

    showManualMessages = true;

    showTargetTempMessages = true;

    showAdvancedMessages = true;

    showMinTempMessages = true;

    showEMCMoistureMessages = true;

    showPlenumRiseMessages = true;

    showTargetMoistureMessages = true;

    showEMCRangeMessages = true;

    showAutomationHubMessages = true;

    showPowerSourceMessages = true;

    showNotMonitoredMessages = true;

    showDefaultMessages = true;

    showCommunicationSummaryMessages = true;

    dismissedWelcome = false;

    showAssociationAlert = false;

    showSystemTempThreshold = false;

    showVoltageAlert = false;

    constructor() {
        makeObservable(this, {
            beginTooltips: observable,
            setBeginTooltips: action,
            showWeatherMessages: observable,
            setShowWeatherMessages: action,
            showAddDeviceMessages: observable,
            setShowAddDeviceMessages: action,
            showSiteLocationMessages: observable,
            setShowSiteLocationMessages: action,
            showInventoryMessages: observable,
            setShowInventoryMessages: action,
            showDifferencesMessages: observable,
            setShowDifferencesMessages: action,
            showAmbientMessages: observable,
            setShowAmbientMessages: action,
            showPlenumConditionsMessages: observable,
            setShowPlenumConditionsMessages: action,
            showManualMessages: observable,
            setShowManualMessages: action,
            showTargetTempMessages: observable,
            setShowTargetTempMessages: action,
            showAdvancedMessages: observable,
            setShowAdvancedMessages: action,
            showMinTempMessages: observable,
            setShowMinTempMessages: action,
            showEMCMoistureMessages: observable,
            setShowEMCMoistureMessages: action,
            showPlenumRiseMessages: observable,
            setShowPlenumRiseMessages: action,
            showTargetMoistureMessages: observable,
            setShowTargetMoistureMessages: action,
            showEMCRangeMessages: observable,
            setShowEMCRangeMessages: action,
            showAutomationHubMessages: observable,
            setShowAutomationHubMessages: action,
            showPowerSourceMessages: observable,
            setShowPowerSourceMessages: action,
            showNotMonitoredMessages: observable,
            setShowNotMonitoredMessages: action,
            showDefaultMessages: observable,
            setShowDefaultMessages: action,
            showCommunicationSummaryMessages: observable,
            setShowCommunicationSummaryMessages: action,
            setAllMessages: action,
            dismissedWelcome: observable,
            setDismissedWelcome: action,
            showAssociationAlert: observable,
            setShowAssociationAlert: action,
            showSystemTempThreshold: observable,
            setShowSystemTempThreshold: action,
            showVoltageAlert: observable,
            setShowVoltageAlert: action,
        });
        this.initializeFromAsyncStorage();
    }

    setBeginTooltips = (flag: boolean, sync: boolean) => {
        this.beginTooltips = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_BEGIN_TOOLTIPS, flag.toString());
        }
    }

    setDismissedWelcome = (flag: boolean, sync: boolean) => {
        this.dismissedWelcome = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_DISMISSED_WELCOME, flag.toString());
        }
    }

    setShowWeatherMessages = (flag: boolean, sync: boolean) => {
        this.showWeatherMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_WEATHER_MESSAGES, flag.toString());
        }
    }

    setShowAddDeviceMessages = (flag: boolean, sync: boolean) => {
        this.showAddDeviceMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_ADD_DEVICE_MESSAGES, flag.toString());
        }
    }

    setShowSiteLocationMessages = (flag: boolean, sync: boolean) => {
        this.showSiteLocationMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_SITE_LOCATION_MESSAGES, flag.toString());
        }
    }

    setShowInventoryMessages = (flag: boolean, sync: boolean) => {
        this.showInventoryMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_INVENTORY_MESSAGES, flag.toString());
        }
    }

    setShowDifferencesMessages = (flag: boolean, sync: boolean) => {
        this.showDifferencesMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_DIFFERENCES_MESSAGES, flag.toString());
        }
    }

    setShowAmbientMessages = (flag: boolean, sync: boolean) => {
        this.showAmbientMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_AMBIENT_MESSAGES, flag.toString());
        }
    }

    setShowPlenumConditionsMessages = (flag: boolean, sync: boolean) => {
        this.showPlenumConditionsMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_PLENUM_CONDITIONS_MESSAGES, flag.toString());
        }
    }

    setShowManualMessages = (flag: boolean, sync: boolean) => {
        this.showManualMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_MANUAL_MESSAGES, flag.toString());
        }
    }

    setShowTargetTempMessages = (flag: boolean, sync: boolean) => {
        this.showTargetTempMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_TARGET_TEMP_MESSAGES, flag.toString());
        }
    }

    setShowAdvancedMessages = (flag: boolean, sync: boolean) => {
        this.showAdvancedMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_ADVANCED_MESSAGES, flag.toString());
        }
    }

    setShowMinTempMessages = (flag: boolean, sync: boolean) => {
        this.showMinTempMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_MIN_TEMP_MESSAGES, flag.toString());
        }
    }

    setShowEMCMoistureMessages = (flag: boolean, sync: boolean) => {
        this.showEMCMoistureMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_EMC_MOISTURE_MESSAGES, flag.toString());
        }
    }

    setShowPlenumRiseMessages = (flag: boolean, sync: boolean) => {
        this.showPlenumRiseMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_EMC_MOISTURE_MESSAGES, flag.toString());
        }
    }

    setShowTargetMoistureMessages = (flag: boolean, sync: boolean) => {
        this.showTargetMoistureMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_TARGET_MOISTURE_MESSAGES, flag.toString());
        }
    }

    setShowEMCRangeMessages = (flag: boolean, sync: boolean) => {
        this.showEMCRangeMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_EMC_RANGE_MESSAGES, flag.toString());
        }
    }

    setShowAutomationHubMessages = (flag: boolean, sync: boolean) => {
        this.showAutomationHubMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_AUTOMATION_HUB_MESSAGES, flag.toString());
        }
    }

    setShowPowerSourceMessages = (flag: boolean, sync: boolean) => {
        this.showPowerSourceMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_POWER_SOURCE_MESSAGES, flag.toString());
        }
    }

    setShowNotMonitoredMessages = (flag: boolean, sync: boolean) => {
        this.showNotMonitoredMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_NOT_MONITORED_MESSAGES, flag.toString());
        }
    }

    setShowDefaultMessages = (flag: boolean, sync: boolean) => {
        this.showDefaultMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_DEFAULT_MESSAGES, flag.toString());
        }
    }

    setShowCommunicationSummaryMessages = (flag: boolean, sync: boolean) => {
        this.showCommunicationSummaryMessages = flag;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_DEFAULT_MESSAGES, flag.toString());
        }
    }

    setShowAssociationAlert = (flag: boolean) => {
        this.showAssociationAlert = flag;
    }

    setShowSystemTempThreshold = (flag: boolean) => {
        this.showSystemTempThreshold = flag;
    }

    setShowVoltageAlert = (flag: boolean) => {
        this.showVoltageAlert = flag;
    }

    setAllMessages = (flag: boolean) => {
        this.setShowWeatherMessages(flag, true);
        this.setShowAddDeviceMessages(flag, true);
        this.setShowSiteLocationMessages(flag, true);
        this.setShowInventoryMessages(flag, true);
        this.setShowDifferencesMessages(flag, true);
        this.setShowAmbientMessages(flag, true);
        this.setShowPlenumConditionsMessages(flag, true);
        this.setShowManualMessages(flag, true);
        this.setShowTargetTempMessages(flag, true);
        this.setShowAdvancedMessages(flag, true);
        this.setShowMinTempMessages(flag, true);
        this.setShowEMCMoistureMessages(flag, true);
        this.setShowPlenumRiseMessages(flag, true);
        this.setShowTargetMoistureMessages(flag, true);
        this.setShowEMCRangeMessages(flag, true);
        this.setShowAutomationHubMessages(flag, true);
        this.setShowPowerSourceMessages(flag, true);
        this.setShowNotMonitoredMessages(flag, true);
        this.setShowDefaultMessages(flag, true);
        this.setShowCommunicationSummaryMessages(flag, true);
        this.setShowAssociationAlert(flag);
        this.setShowSystemTempThreshold(flag);
        this.setShowVoltageAlert(flag);
    }

    presetTooltipStore = () => {
        this.setBeginTooltips(false, true);
    }

    syncWithAsyncStorage = (key: string, value: string) => {
        global.localStorage.setItem(key, JSON.stringify(value));
    }

    initializeFromAsyncStorage = () => {
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_BEGIN_TOOLTIPS, this.setBeginTooltips);
        this.getBooleanFromAsyncStorage(Constants.STORE_KEY_DISMISSED_WELCOME, this.setDismissedWelcome);
    }

    getBooleanFromAsyncStorage = (key: string, setter: (value: boolean, sync: boolean) => void) => {
        const result = global.localStorage.getItem(key);
        if (result !== null) {
            setter(JSON.parse(result) === 'true', false);
        }
    }
}

export default new TooltipStore();
