import callAPI from '../../callAPI';
import getSiteStructures from './getSiteStructures';

const checkForUpdate = (enqueueSnackbar: any, time: number) => {
    callAPI(`checkForUpdate/${ time }`,
        (response) => {
            response.forEach((siteId: number) => getSiteStructures(enqueueSnackbar, siteId, true));
        },
        enqueueSnackbar
    );
};

export default checkForUpdate;