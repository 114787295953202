import callAPI from '../../callAPI';

const getExtendUserDueDate = (enqueueSnackbar: any, userId: number) => {
    callAPI(`extendUserDueDate/${ userId }`,
        (response) => {
            if (response === 'OK') {
                enqueueSnackbar('Successfully extended customer due date', {variant: 'success'});
            }
        },
        enqueueSnackbar,
        true
    );
};

export default getExtendUserDueDate;