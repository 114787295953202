/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {action, makeObservable, observable} from 'mobx';
import Constants from '../helper/Constants';

class UIStateStore {

    includeEmpty = true

    mapView = Constants.MAP

    showSatelliteViewMessage = true

    redirectedToStripe = false

    constructor() {
        makeObservable(this, {
            includeEmpty : observable,
            setIncludeEmpty: action,
            mapView : observable,
            setMapView: action,
            showSatelliteViewMessage: observable,
            setShowSatelliteViewMessage: action,
            redirectedToStripe: observable,
            setRedirectedToStripe: action,
        });
        this.initializeFromAsyncStorage();
    }

    setIncludeEmpty = (includeEmpty: boolean) => {
        this.includeEmpty = includeEmpty;
        this.syncWithAsyncStorage('includeEmpty', String(this.includeEmpty));
    }

    setMapView = (view: string) => {
        this.mapView = view;
        this.syncWithAsyncStorage(Constants.STORE_KEY_MAP_VIEW, this.mapView);
    }

    setShowSatelliteViewMessage = (showSatelliteViewMessage: boolean) => {
        this.showSatelliteViewMessage = showSatelliteViewMessage;
        // eslint-disable-next-line
        this.syncWithAsyncStorage(Constants.STORE_KEY_SHOW_SATELLITE_VIEW_MESSAGE, String(this.showSatelliteViewMessage));
    }

    setRedirectedToStripe = (redirectedToStripe: boolean) => {
        this.redirectedToStripe = redirectedToStripe;
        this.syncWithAsyncStorage(Constants.STORE_KEY_REDIRECTED_TO_STRIPE, String(this.redirectedToStripe));
    }

    syncWithAsyncStorage = (key: string, value: string ) => {
        global.localStorage.setItem(key, JSON.stringify(value));
    }

    initializeFromAsyncStorage = () => {
        const includeEmptyPref = global.localStorage.getItem('includeEmpty');
        if (includeEmptyPref !== null) {
            this.setIncludeEmpty(JSON.parse(includeEmptyPref) === 'true');
        }
        const mapViewPref = global.localStorage.getItem(Constants.STORE_KEY_MAP_VIEW);
        if (mapViewPref !== null) {
            this.setMapView(JSON.parse(mapViewPref));
        }
        // eslint-disable-next-line
        const showSatelliteViewMessagePref = global.localStorage.getItem(Constants.STORE_KEY_SHOW_SATELLITE_VIEW_MESSAGE);
        if (showSatelliteViewMessagePref !== null) {
            this.setShowSatelliteViewMessage(JSON.parse(showSatelliteViewMessagePref) === 'true');
        }

        const redirectedToStripePref = global.localStorage.getItem(Constants.STORE_KEY_REDIRECTED_TO_STRIPE);
        if (redirectedToStripePref !== null) {
            this.setRedirectedToStripe(JSON.parse(redirectedToStripePref) === 'true');
        }
    }

}

export default new UIStateStore();
