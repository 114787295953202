/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const getNewId = (data: any[]) => {
    let maxId = -1;
    data.forEach(item => {
        if (item.id > maxId) {
            maxId = item.id;
        }
    });
    return maxId + 1;
};

export default getNewId;
