import { observer } from 'mobx-react-lite';
import BinsenseStore from '../../../stores/BinsenseStore';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import { Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import getDealerNotifications from '../../../controllers/apiCalls/get/getDealerNotifications';
import { useSnackbar } from 'notistack';
import PaginationTable from '../../common/displayTable/PaginationTable';
import DisplayGridHeaderRow from '../../common/displayTable/DisplayGridHeaderRow';
import DisplayGridItem from '../../common/displayTable/DisplayGridItem';
import DisplayGridContent from '../../common/displayTable/DisplayGridContent';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import DisplayGridCheckBox from '../../common/displayTable/DisplayGridCheckBox';
import InfoTooltip from '../../common/InfoTooltip';
import TooltipStore from '../../../stores/TooltipStore';
import TooltipMessages from '../../../helper/TooltipMessages';
import DisplayGridActions from '../../common/displayTable/DisplayGridActions';
import { Delete } from '@mui/icons-material';
import Constants from '../../../helper/Constants';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import deleteDealerNotifications from '../../../controllers/apiCalls/delete/deleteDealerNotifications';
import getIndex from '../../../helper/functions/getIndex';
import { DealerNotification } from '../../../types/components/ApiTypes';
import postDealerNotification from '../../../controllers/apiCalls/post/postDealerNotification';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const DealerNotificationTable = observer(() => {
    const {enqueueSnackbar} = useSnackbar();

    const [ rows, setRows ] = useState(BinsenseStore.dealerNotifications);

    useEffect(() => {
        if (BinsenseStore.dealerUserData.dealerId > 0) {
            getDealerNotifications(enqueueSnackbar, BinsenseStore.dealerUserData.dealerId);
        }
    }, [ BinsenseStore.dealerUserData.dealerId ]);

    useEffect(() => {
        setRows(BinsenseStore.dealerNotifications);
    }, [ BinsenseStore.dealerNotifications ]);

    const update = (id: number, alert: string) => {
        const index = getIndex(id, rows);
        if (index >= 0) {
            const d: DealerNotification = JSON.parse(JSON.stringify(rows[index]));
            if (alert === 'sendMasterTexts') {
                d.sendMasterTexts = !d.sendMasterTexts;
            } else if (alert === 'sendSystemAlerts') {
                d.sendSystemAlerts = !d.sendSystemAlerts;
            } else if (alert === 'sendVoltageAlerts') {
                d.sendVoltageAlerts = !d.sendVoltageAlerts;
            }

            postDealerNotification(enqueueSnackbar, d, 'Notification updated successfully');
        }
    };

    const xs = 3;
    const [ deleteId, setDeleteId ] = useState(0);
    const [ deletePhone, setDeletePhone ] = useState('');

    const onDelete = () => {
        if (deleteId > 0) {
            deleteDealerNotifications(enqueueSnackbar, deleteId);
        }
    };

    const setDelete = (id: number, phone: string) => {
        setDeleteId(id);
        setDeletePhone(phone);
    };

    const renderRow = (row: any) => (
        <DisplayGridContent key={`${ row.phoneNumber }_row`}>
            <DisplayGridLabel
                id={`${ row.phoneNumber }_value`}
                label={ row.phoneNumber }
                xs={xs - 1}
            />
            <DisplayGridCheckBox
                xs={xs}
                id={`${ row.sendMasterTexts }_selected`}
                value={row.sendMasterTexts}
                onChange={() => update(row.id, 'sendMasterTexts')}
            />
            <DisplayGridCheckBox
                xs={xs}
                id={`${ row.sendSystemAlerts }_selected`}
                value={row.sendSystemAlerts}
                onChange={() => update(row.id, 'sendSystemAlerts')}
            />
            <DisplayGridCheckBox
                xs={xs}
                id={`${ row.sendVoltageAlerts }_selected`}
                value={row.sendVoltageAlerts}
                onChange={() => update(row.id, 'sendVoltageAlerts')}
            />
            <DisplayGridActions
                xs={xs - 2}
                id={`${ row.id }_action`}
                buttons={[
                    {
                        id: `${ row.id }_delete_button`,
                        onClick: () => setDelete(row.id, row.phoneNumber),
                        icon: <Delete/>,
                        hoverText: Constants.DELETE,
                    },
                ]}
            />
        </DisplayGridContent>
    );

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <div>
            <PaginationTable
                rows={rows}
                renderRow={renderRow}
            >
                <DisplayGridHeaderRow>
                    <DisplayGridLabel
                        id='notification_contact_header'
                        label='Contact'
                        xs={xs - 1}
                        header
                    />
                    <DisplayGridItem
                        xs={xs}
                        header
                    >
                        <Typography fontWeight={'bold'}>Association Alerts</Typography>
                        <InfoTooltip
                            id='association_alerts_tooltip_button'
                            open={TooltipStore.beginTooltips && TooltipStore.showAssociationAlert}
                            message={TooltipMessages.NOTIFICATION_ASSOCIATION_ALERT}
                            onClick={() => TooltipStore.setShowAssociationAlert(false)}
                        />
                    </DisplayGridItem>
                    <DisplayGridItem
                        xs={xs}
                        header
                    >
                        <Typography fontWeight={'bold'}>System Temperture Threshold</Typography>
                        <InfoTooltip
                            id='system_temp_tooltip_button'
                            open={TooltipStore.beginTooltips && TooltipStore.showSystemTempThreshold}
                            message={TooltipMessages.NOTIFICATION_SYSTEM_TEMP}
                            onClick={() => TooltipStore.setShowSystemTempThreshold(false)}
                        />
                    </DisplayGridItem>
                    <DisplayGridItem
                        xs={xs}
                        header
                    >
                        <Typography fontWeight={'bold'}>Voltage Alerts</Typography>
                        <InfoTooltip
                            id='voltage_Alert_tooltip_button'
                            open={TooltipStore.beginTooltips && TooltipStore.showVoltageAlert}
                            message={TooltipMessages.NOTIFICATION_VOLTAGE_TEMP}
                            onClick={() => TooltipStore.setShowVoltageAlert(false)}
                        />
                    </DisplayGridItem>
                    <DisplayGridLabel
                        id='notification_actions_header'
                        label='Actions'
                        xs={xs - 2}
                        header
                    />
                </DisplayGridHeaderRow>
            </PaginationTable>
            {deleteId > 0 && (
                <ConfirmationModal
                    id='delete_notification_confirmation'
                    open
                    close={() => setDeleteId(0)}
                    submit={() => onDelete()}
                    title='Delete Notification'
                    message={`Are you sure you want to delete ${ deletePhone } ?`}
                />
            )}
        </div>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={300}/>
        </SkeletonRoot>
    );
});

export default DealerNotificationTable;
