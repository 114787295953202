/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo, useState} from 'react';
import {MenuItem} from '@mui/material';
import {MoreVert} from '@mui/icons-material';
import getTestID from '../../helper/functions/getTestID';
import {observer} from 'mobx-react-lite';
import PopupMenu from '../common/PopupMenu';
import HoverTextButton from '../common/buttons/HoverTextButton';
import WebStore from '../../stores/WebStore';
import BinsenseStore from '../../stores/BinsenseStore';
import Constants from '../../helper/Constants';
import getStructuresFromSite from '../../helper/functions/getStructuresFromSite';
import getStorageListBins from '../../helper/functions/getStorageListBins';
import {useSnackbar} from 'notistack';
import getBinNotes from '../../controllers/apiCalls/get/getBinNotes';

interface Props {
    disabled?: boolean
}

const SitesPopup = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);
    const sites = useMemo(() => BinsenseStore.userData.sites, [ BinsenseStore.userData.sites ]);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeviceClick = (id: number) => {
        WebStore.setSelectedSiteId(id, true);
        getBinNotes(enqueueSnackbar, id);
        WebStore.setViewDeviceId(-1, true);
        if (WebStore.selectedDrawer === Constants.DEVICE_SETTINGS) {
            const index = sites.findIndex(site => site.id === id);
            BinsenseStore.setDeviceTableData(getStructuresFromSite(sites[index], true));
        }
        else if (WebStore.selectedDrawer === Constants.STORAGE) {
            const index = sites.findIndex(site => site.id === id);
            BinsenseStore.setStorageListData(getStorageListBins(sites[index]));
        }
        handleClose();
    };

    return (
        <div>
            <HoverTextButton
                id='sites_button'
                icon={<MoreVert/>}
                hoverText="Sites"
                onClick={handleClick}
                disabled={props.disabled}
            />
            <PopupMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
            >
                {sites.map(site => (
                    <MenuItem
                        {...getTestID(`${ site.name }_button`)}
                        key={`site_${ site.id }`}
                        onClick={() => handleDeviceClick(site.id)}
                    >
                        {site.name}
                    </MenuItem>
                ))}
            </PopupMenu>
        </div>
    );
});

export default SitesPopup;
