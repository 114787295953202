/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import AccordionComponent from '../../common/AccordionComponent';
import InventoryTableRowSummary from './InventoryTableRowSummary';
import InventoryTableRowDetails from './InventoryTableRowDetails';

interface Props {
    data: any,
}

const InventoryTableRow = (props: Props) => (
    <AccordionComponent
        id={props.data.id}
        summary={<InventoryTableRowSummary data={props.data}/>}
        details={<InventoryTableRowDetails data={props.data}/>}
    />
);

export default InventoryTableRow;
