/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {Paper, Skeleton, styled} from '@mui/material';
import {MarkerData, SiteLocationData} from '../../../../types/components/ApiTypes';
import CreateSiteMap from '../CreateSiteMap';
import CreateSiteToolbar from '../CreateSiteToolbar';
import getDistancePerPixel from '../../../../helper/functions/getDistancePerPixel';
import useWindowSize from '../../../../helper/hooks/useWindowSize';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import {observer} from 'mobx-react-lite';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
});

interface Props {
    site: SiteLocationData,
    markerData: MarkerData[],
    setMarkerData: Dispatch<SetStateAction<MarkerData[]>>,
    fullWidth?: boolean,
    onMarkerUpdate?: () => void,
}

const AddDevicesPaper = observer((props: Props) => {
    const windowSize = useWindowSize();
    const mapHeight = useMemo(() =>
        windowSize[1] - 398,[ windowSize ]);

    const [ reset, setReset ] = useState(false);

    const [ viewState, setViewState ] = useState({
        longitude: props.site.longitude,
        latitude: props.site.latitude,
        zoom: props.site.zoom,
    });

    useEffect(() => {
        setViewState({
            longitude: props.site.longitude,
            latitude: props.site.latitude,
            zoom: 17,
        });
        setReset(true);
    }, [ props.site, BinsenseStore.awaitingUserData ]);

    const [ features, setFeatures ] = useState({});

    const onFeaturesUpdate = useCallback(e => {
        setFeatures(currFeatures => {
            const newFeatures = {...currFeatures};
            for (const f of e.features) {
                newFeatures[f.id] = f;
            }
            return newFeatures;
        });
    }, []);

    const onFeaturesDelete = useCallback(e => {
        setFeatures(currFeatures => {
            const newFeatures = {...currFeatures};
            for (const f of e.features) {
                delete newFeatures[f.id];
            }
            return newFeatures;
        });
    }, []);

    const distancePerPixel = useMemo(() => getDistancePerPixel(viewState.latitude, viewState.zoom),
        [ viewState.zoom, viewState.latitude ]);

    const component = useMemo(() => {
        if (reset) {
            setTimeout(() => setReset(false), 0);
            return (
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={500}
                />
            );
        }
        return (
            <CreateSiteMap
                viewState={viewState}
                setViewState={setViewState}
                markerData={props.markerData}
                setMarkerData={props.setMarkerData}
                toggleOnBottom
                distancePerPixel={distancePerPixel}
                features={features}
                onFeaturesDelete={onFeaturesDelete}
                onFeaturesUpdate={onFeaturesUpdate}
                height={mapHeight}
                onMarkerUpdate={props.onMarkerUpdate}
            />
        );
    }, [ reset, viewState, props.markerData, distancePerPixel ]);

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <Paper
            style={{
                width: props.fullWidth ? undefined : '95%',
            }}
        >
            <Root>
                <div
                    style={{ width: '100%' }}
                >
                    <CreateSiteToolbar
                        viewState={viewState}
                        markerData={props.markerData}
                        setMarkerData={props.setMarkerData}
                        siteId={props.site.id}
                        onMarkerUpdate={props.onMarkerUpdate}
                    />
                    {component}
                </div>
            </Root>
        </Paper>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={710}/>
        </SkeletonRoot>
    );
});

export default AddDevicesPaper;
