/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import AreaChart from '../../../../common/charts/AreaChart';
import { BinGraphData, XAxisType } from '../../../../../types/components/ApiTypes';
import Colors from '../../../../../helper/Colors';
import { Dispatch, SetStateAction } from 'react';

interface Props {
    data: BinGraphData[],
    id: string
    legend_show: boolean
    height: string | number
    setTimeUnit: Dispatch<SetStateAction<string>>
    setStart: Dispatch<SetStateAction<moment.Moment>>
    setEnd: Dispatch<SetStateAction<moment.Moment>>
}

const ViewDeviceFanChart = (props: Props) => {

    const fandata: { x: string; y: (number | (() => number))[]; }[] = [];

    var fanOnDate = 0;
    var startDate = 0;
    var endDate = 0;
    var dataFound = false;
    var labels:string[] = [];

    props.data.forEach(point => {
        if (startDate == 0) {
            startDate = point.dt;
        }
        endDate = point.dt;

        if (point.fanOn == 1) {
            if (fanOnDate == 0) {
                fanOnDate = point.dt;
            }
            dataFound = true;
        }
        if (point.fanOn == 0) {
            if (fanOnDate != 0) {
                fandata.push({
                    x: 'Fan On',
                    y: [ fanOnDate, point.dt ],
                });
                fanOnDate = 0;
                labels.push('        On');

            }
        }
    });

    if (fanOnDate != 0) {
        fandata.push({
            x: 'Fan On',
            y: [ fanOnDate, endDate ],
        });
        labels.push('        On');
    }

    const series = [
        {
            name: 'Fan',
            data: fandata,
        },
    ];
    /* eslint-disable  object-shorthand */
    const yaxis: ApexYAxis[] = [
        {
            seriesName: 'Fan',
            title: {
                text: 'Fan',
            },
            opposite: false,
            labels: {
                  minWidth: 80,
                  maxWidth: 80,
                  show: false,
            },
            show: false,
            tooltip: {
                enabled: false,
            },
        },
    ];
    /* eslint-enable  object-shorthand */

    const gstroke : ApexStroke = {
        width: 3,
        curve: 'smooth',
        dashArray: 0,
    };

    if (!dataFound) {
        return null;
    }

    const popt : ApexPlotOptions = {
        bar: {
            horizontal: true,
            barHeight: '100%',
            dataLabels : {
                position: 'top',
            },
            },
        };
    const xaxis: ApexXAxis = {
        type: XAxisType.dateTime,
        tooltip: {
            enabled: false,
            formatter: () => '',
        },
        labels: {
            show: false,
            showDuplicates: false,
        },
        min: startDate,
        max: endDate,
        axisBorder: {
            show: false,
        },
        range: undefined,
    };

    return (
        <AreaChart
            series={series}
            yaxis={yaxis}
            legendPosition="bottom"
            showLegend={false}
            id={props.id}
            height={props.height}
            type="rangeBar"
            showXAxis={false}
            stroke={gstroke}
            colors={[ Colors.fanColor ]}
            plotOptions={popt}
            xAxis={xaxis}
            tooltipXFormat=' '
            showDataLabels={false}
            dataLabelFormatter={(val, opt) => labels[opt.dataPointIndex]}
            setTimeUnit={props.setTimeUnit}
            setStart={props.setStart}
            setEnd={props.setEnd}
        />
    );
};

export default ViewDeviceFanChart;
