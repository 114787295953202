/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {MarkerData} from '../../types/components/ApiTypes';

const getAutomationMasterSerialsFromMarkers = (markers: MarkerData[]) => {
    const masterMarkers = markers.filter(marker =>
        marker.display?.automationHubData);
    return masterMarkers.map(marker => {
        if (marker.display?.automationHubData) return marker.display.automationHubData.serial;
        return '';
    });
};

export default getAutomationMasterSerialsFromMarkers;
