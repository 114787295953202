import React, {SVGAttributes} from 'react';
import {degreesToRadians} from '../../helper/functions/functions';
import {GrainPileShape} from '../../types/components/ApiTypes';

interface SVGProps extends React.SVGProps<SVGSVGElement> {
    id: string,
    width: number,
    length: number,
    rotation?: number,
    fullness?: number,
    onOverlayStart?: (xOffset: number) => void,
    onMouseLeave?: () => void,
    x: number,
    y: number,
    href?: string,
    onlink?: (event: React.MouseEvent) => void
    shape?: GrainPileShape,
}

interface LocalSVGProps extends SVGProps {
    isGrainBag: boolean,
    isGrainPile: boolean,
}

const RectangleSVG = (props: LocalSVGProps) => {
    const {
        id,
        className,
        width,
        length,
        rotation,
        fullness,
        stroke,
        isGrainBag,
        isGrainPile,
        onOverlayStart,
        onMouseLeave,
        x: centerX,
        y: centerY,
        href,
        onlink,
        shape,
        ...rest
    } = props;

    const safeRotation = rotation == null ? 0 : rotation;
    const radRotation = degreesToRadians(safeRotation);

    const outlineStroke = 4;

    const rectWidth = width - outlineStroke;
    const rectLength = length - outlineStroke;

    let safeCornerRadius = 5;
    if (isGrainBag) {
        safeCornerRadius = rectWidth * 0.15;
    } else if (isGrainPile && (shape === GrainPileShape.circle || shape === undefined)) {
        safeCornerRadius = rectWidth * 0.5;
    }

    let svgWidth: number;
    let svgLength: number;
    if (safeRotation === 0) {
        svgWidth = width;
        svgLength = length;
    } else {
        svgWidth = Math.abs(Math.cos(radRotation) * width) + Math.abs(Math.sin(radRotation) * length);
        svgLength = Math.abs(Math.cos(radRotation) * length) + Math.abs(Math.sin(radRotation) * width);
    }

    const halfSvgWidth = 0.5 * svgWidth;
    const x = centerX - halfSvgWidth;
    const halfSvgLength = 0.5 * svgLength;
    const y = centerY - halfSvgLength;

    const safeFullness = fullness == null ? 0 : fullness * svgLength * 0.01;
    const lessFullness = svgLength - safeFullness;

    const shapeX = (svgWidth - rectWidth) * 0.5;
    const shapeY = (svgLength - rectLength) * 0.5;

    const rotationTransform = `translate(${ halfSvgWidth }, ${ halfSvgLength }) rotate(${ safeRotation }) translate(-${
        halfSvgWidth }, -${ halfSvgLength })`;

    function onMouseEnter() {
        if (onOverlayStart != null) {
            onOverlayStart(halfSvgWidth);
        }
    }

    const drawShape = (shapeProps: SVGAttributes<SVGRectElement>) => (
        <rect
            x={shapeX}
            y={shapeY}
            rx={safeCornerRadius}
            ry={safeCornerRadius}
            width={rectWidth}
            height={rectLength}
            strokeWidth={outlineStroke}
            transform={rotationTransform}
            {...shapeProps}
        />
    );

    const outer = (
        <svg
            id={id}
            className={`structure ${ isGrainBag || isGrainPile ? 'grainbag' : 'building' } ${ className }`}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={svgWidth}
            height={svgLength}
            x={x}
            y={y}
            viewBox={`0 0 ${ svgWidth } ${ svgLength }`}
            {...rest}
        >
            <mask id={`${ id }_clipFillMask`}>
                <g>
                    {drawShape({
                        className: 'fullnessMask',
                        strokeWidth: outlineStroke,
                        stroke: 'white',
                        fill: 'white',
                    })}
                    <rect
                        x={0}
                        y={0}
                        width={svgWidth}
                        height={lessFullness}
                        fill="black"
                    />
                </g>
            </mask>
            {drawShape({className: 'shape'})}
            {drawShape({className: 'outline'})}
            <g mask={`url(#${ id }_clipFillMask)`}>
                <rect
                    className="fullnessShape"
                    x={0}
                    y={lessFullness}
                    width={svgWidth}
                    height={safeFullness}
                    strokeWidth={outlineStroke * 2}
                />
                {drawShape({
                    className: 'fullnessOutline',
                    strokeWidth: outlineStroke,
                })}
            </g>
            {drawShape({
                className: 'cover',
                onMouseEnter,
                onMouseLeave,
            })}
        </svg>
    );
    return href == null ? outer : (
        <a
            onClick={onlink}
            href={href}
        >{outer}</a>
    );
};

const BuildingSVG = (props: SVGProps) => {
    const localProps = {
        isGrainBag: false,
        isGrainPile: false,
        ...props,
    };
    return <RectangleSVG {...localProps} />;
};

const GrainBagSVG = (props: SVGProps) => {
    const localProps = {
        isGrainBag: true,
        isGrainPile: false,
        ...props,
    };
    return <RectangleSVG {...localProps} />;
};

const GrainPileSVG = (props: SVGProps) => {
    const localProps = {
        isGrainBag: false,
        isGrainPile: true,
        ...props,
    };
    return <RectangleSVG {...localProps} />;
};

export {BuildingSVG, GrainBagSVG, GrainPileSVG};
