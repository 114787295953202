/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {useEffect, useMemo, useState} from 'react';
import {Site, SiteLocationData} from '../../types/components/ApiTypes';
import Constants from '../../helper/Constants';
import BaseModal from './modal/BaseModal';
import useWindowSize from '../../helper/hooks/useWindowSize';
import SetSiteLocationForm from './SetSiteLocationForm';
import SubmitButton from './buttons/SubmitButton';
import BinsenseStore from '../../stores/BinsenseStore';
import moveStructures from '../../helper/functions/moveStructures';
import {useSnackbar} from 'notistack';
import getNewSite from '../../helper/functions/getNewSite';
import putSite from '../../controllers/apiCalls/put/putSite';
import WebStore from '../../stores/WebStore';
import getSiteStructures from '../../controllers/apiCalls/get/getSiteStructures';
import getAllUserSiteName from '../../helper/functions/getAllUserSiteNames';

const Container = styled('div')({
    padding: '16px',
});

const ButtonRow = styled('div')({
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

interface Props {
    site?: Site,
    open: boolean,
    close: () => void,
    showDelete?: boolean,
    showMessage?: boolean,
    userId?: number,
}

const SetSiteLocationModal = observer((props: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        // For dealer, the site is not loaded with structures, need to load the structures to update the site.
        if (WebStore.selectedView === Constants.DEALER && props.site) {
            getSiteStructures(enqueueSnackbar, props.site.id);
        }
    }, [ props.site ]);

    const selectedSite = useMemo(() => props.site ? props.site : getNewSite(),
        [ props.site ]);
    const windowSize = useWindowSize();
    const mapWidth = useMemo(() => windowSize[0] * 0.8, [ windowSize ]);
    const mapHeight = useMemo(() => windowSize[1] * 0.8 - 230, [ windowSize ]);

    const [ site, setSite ] = useState<SiteLocationData | null>(props.site ? {
        id: selectedSite.id,
        name: selectedSite.name,
        longitude: selectedSite.longitude,
        latitude: selectedSite.latitude,
        zoom: 17,
    } : null);
    const [ displayMap, setDisplayMap ] = useState(!props.showMessage);

    const containerWidth = useMemo(() => displayMap ? `${ mapWidth }px` : '600px', [ displayMap, mapWidth ] );

    const moveStructuresWithSite = (s: Site) => {
        if (site !== null) {
            const latOffset = site.latitude - s.latitude;
            const longOffset = site.longitude - s.longitude;
            moveStructures(s, latOffset, longOffset);
        }
    };

    const saveSite = () => {
        if (site !== null) {
            let clone = JSON.parse(JSON.stringify(selectedSite));
            if (WebStore.selectedView === Constants.DEALER) {
                clone = {
                    ...selectedSite,
                    ...JSON.parse(JSON.stringify(BinsenseStore.siteStructures)),
                };
            }

            moveStructuresWithSite(clone);

            clone.name = site.name;
            clone.latitude = site.latitude;
            clone.longitude = site.longitude;
            putSite(enqueueSnackbar, clone);
        }
    };

    const isValid = () => site !== null;

    const submit = () => {
        if (!displayMap) {
            setDisplayMap(true);
        }
        else if (isValid()) {
            saveSite();
            props.close();
        }
    };

    const renderContent = () => !displayMap ? (
        <Typography>
            The site {selectedSite.name} is detected to have no set location. Click 'Next'
            to set this site location. Structures on this site may need their locations adjusted after the site
            location is set.
        </Typography>
        ) : (
            <SetSiteLocationForm
                site={site}
                setSite={setSite}
                siteLocationInvalid={false}
                noLocationDetected={!props.site}
                mapHeight={mapHeight}
                showDelete={props.showDelete}
                close={props.close}
            />
        );

    return (
        <BaseModal
            id="set_site_location"
            open={props.open}
            close={props.close}
            title={Constants.SET_SITE_LOCATION}
            hideButtons
            disableSubmitOnAwait
        >
            <Container style={{ width: containerWidth }}>
                {renderContent()}
            </Container>
            <ButtonRow>
                <SubmitButton
                    id="set_site_location_submit_button"
                    text={displayMap ? Constants.SAVE_CHANGES : 'Next'}
                    onClick={submit}
                    disabled={displayMap && (site === null || (site.name.toLowerCase() !== props.site?.name.toLowerCase() &&
                        getAllUserSiteName(props.userId).includes(site.name.toLowerCase())))}
                />
            </ButtonRow>
        </BaseModal>
    );
});

export default SetSiteLocationModal;
