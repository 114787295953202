/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo, useState} from 'react';
import Constants from '../../../helper/Constants';
import SelectUserPaper from '../createSite/newSiteWizard/SelectUserPaper';
import CreateUserPaper from '../createSite/newSiteWizard/CreateUserPaper';
import AddDevicesPaper from '../createSite/newSiteWizard/AddDevicesPaper';
// import SetCableCountPaper from '../createSite/newSiteWizard/SetCableCountPaper';
import NewSiteConfirmationPaper from '../createSite/newSiteWizard/NewSiteConfirmationPaper';
import BaseWizard from '../../common/wizard/BaseWizard';
import SetSiteLocationPaper from '../createSite/newSiteWizard/SetSiteLocationPaper';
import WebStore from '../../../stores/WebStore';
import {observer} from 'mobx-react-lite';
import {MarkerData, SiteLocationData} from '../../../types/components/ApiTypes';
import getNewSite from '../../../helper/functions/getNewSite';
import saveSiteLocationDataToSite from '../../../helper/functions/saveSiteLocationDataToSite';
import postSite from '../../../controllers/apiCalls/post/postSite';
import {useSnackbar} from 'notistack';
import saveMarkerDataToSite from '../../../helper/functions/saveMarkerDataToSite';
import postSiteDealer from '../../../controllers/apiCalls/post/postSiteDealer';
import BinsenseStore from '../../../stores/BinsenseStore';
import isEmailValid from '../../../helper/validator/isEmailValid';
import isPersonalDetailsFormValid from '../../../helper/complexValidators/isPersonalDetailsFormValid';
import isBillingAddressFormValid from '../../../helper/complexValidators/isBillingAddressFormValid';
import {Typography} from '@mui/material';
import postUser from '../../../controllers/apiCalls/post/postUser';
import isEmailExists from '../../../helper/functions/isEmailExists';
import getAllUserSiteName from '../../../helper/functions/getAllUserSiteNames';

interface Props {
    customer?: boolean
}

const steps = [
    Constants.SELECT_USER,
    Constants.CREATE_NEW_USER,
    Constants.SET_SITE_LOCATION,
    Constants.ADD_DEVICES,
    // Constants.SET_CABLE_COUNT,
    Constants.CONFIRMATION,
];

const CreateSiteWizard = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    //step 1 states
    const [ existingUserSelected, setExistingUserSelected ] = useState(true);
    const [ user, setUser ] = useState<string | null>(null);
    const [ userError, setUserError ] = useState('');

    //step 2 states
    const [ firstName, setFirstName ] = useState('');
    const [ firstNameError, setFirstNameError ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ lastNameError, setLastNameError ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ emailError, setEmailError ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ phoneNumberError, setPhoneNumberError ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');
    const [ passwordConfirmation, setPasswordConfirmation ] = useState('');
    const [ passwordConfirmationError, setPasswordConfirmationError ] = useState('');
    const [ country, setCountry ] = useState('');
    const [ countryError, setCountryError ] = useState('');
    const [ province, setProvince ] = useState('');
    const [ provinceError, setProvinceError ] = useState('');
    const [ city, setCity ] = useState('');
    const [ cityError, setCityError ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ addressError, setAddressError ] = useState('');
    const [ postalCode, setPostalCode ] = useState('');
    const [ postalCodeError, setPostalCodeError ] = useState('');
    const [ useForBillingAddress, setUseForBillingAddress ] = useState(true);
    const [ billingCountry, setBillingCountry ] = useState('');
    const [ billingCountryError, setBillingCountryError ] = useState('');
    const [ billingProvince, setBillingProvince ] = useState('');
    const [ billingProvinceError, setBillingProvinceError ] = useState('');
    const [ billingCity, setBillingCity ] = useState('');
    const [ billingCityError, setBillingCityError ] = useState('');
    const [ billingAddress, setBillingAddress ] = useState('');
    const [ billingAddressError, setBillingAddressError ] = useState('');
    const [ billingPostalCode, setBillingPostalCode ] = useState('');
    const [ billingPostalCodeError, setBillingPostalCodeError ] = useState('');

    const [ site, setSite ] = useState<SiteLocationData | null>(null);
    const [ siteLocationInvalid, setSiteLocationInvalid ] = useState(false);
    const [ markerData, setMarkerData ] = useState<MarkerData[]>([]);

    const [ newEmailId, setNewEmailId ] = useState('');

    //step 5 states
    // const [ cable, setCable ] = useState('0');
    // const [ cableError, setCableError ] = useState('');

    const [ error, setError ] = useState('');

    const userId = useMemo(() => {
        if (existingUserSelected) {
            const index = BinsenseStore.dealerUserData.customers.findIndex(c => c.account?.accountEmail &&
                c.account.accountEmail === user);
            return index !== -1 ? BinsenseStore.dealerUserData.customers[index].id : -1;
        }
        return WebStore.createSiteNewUserId;
    }, [ user, WebStore.createSiteNewUserId ]);

    const saveSite = () => {
        if (site !== null) {
            const body = getNewSite();
            saveSiteLocationDataToSite(body, site);
            saveMarkerDataToSite(body, markerData);
            if (props.customer) {
                postSite(enqueueSnackbar, body);
            }
            else if (userId !== -1) {
                postSiteDealer(enqueueSnackbar, userId, body);
            }
        }
    };

    const submit = () => {
        saveSite();
        if (!props.customer) {
            WebStore.setSelectedDrawer(Constants.MY_CUSTOMERS, true);
            WebStore.goToNewPage(Constants.SITE_REPORT);
        }
        else {
            WebStore.setSelectedDrawer(Constants.REPORTS, true);
        }
    };

    const cancel = () => WebStore.setSelectedDrawer(props.customer ? Constants.DASHBOARD : Constants.MY_CUSTOMERS,
        true);

    const isValid = async () => {
        if ((WebStore.activeStep === (props.customer ? 0 : 2) && site === null) ||
            (site &&
                getAllUserSiteName(WebStore.selectedView === Constants.DEALER ? userId : undefined).includes(site.name.toLowerCase()))) {
            setSiteLocationInvalid(true);
            return false;
        }
        if (!props.customer && WebStore.activeStep === 1) {
            let valid = true;

            const emailValid = {
                value: email,
                setError: () => setEmailError(isEmailValid(email)),
            };

            const personalDetailsFormValid = isPersonalDetailsFormValid({
                value: firstName,
                setError: setFirstNameError,
            }, {
                value: lastName,
                setError: setLastNameError,
            }, {
                value: phoneNumber,
                setError: setPhoneNumberError,
            }, {
                value: email,
                setError: setEmailError,
            });

            const addressValid = isBillingAddressFormValid({
                value: postalCode,
                setError: setPostalCodeError,
            }, {
                value: address,
                setError: setAddressError,
            }, {
                value: city,
                setError: setCityError,
            }, {
                value: country,
                setError: setCountryError,
            }, {
                value: province,
                setError: setProvinceError,
            });

            let billingAddressValid = addressValid;

            if (!useForBillingAddress) {
                billingAddressValid = isBillingAddressFormValid({
                    value: billingPostalCode,
                    setError: setBillingPostalCodeError,
                }, {
                    value: billingAddress,
                    setError: setBillingAddressError,
                }, {
                    value: billingCity,
                    setError: setBillingCityError,
                }, {
                    value: billingCountry,
                    setError: setBillingCountry,
                }, {
                    value: billingProvince,
                    setError: setBillingProvinceError,
                });
            }

            if (!emailValid || !personalDetailsFormValid || !addressValid ||
                (!useForBillingAddress && !billingAddressValid)) {
                valid = false;
            }
            if (valid && (email !== newEmailId)) {
                await isEmailExists(enqueueSnackbar, email).then(r => {
                    if ( r !== '' ) {
                        setEmailError(r);
                        valid = false;
                    }
                });
            }
            return valid;
        }
        setSiteLocationInvalid(false);
        return true;
    };

    const createUser = () => {
        const body = {
            firstName,
            lastName,
            email,
            mobileNumber: phoneNumber,
            password,
            verifyPassword: passwordConfirmation,
            countryId: Number(country),
            regionId: Number(province),
            city,
            address,
            postalCode,
            billingAddress: useForBillingAddress ? address : billingAddress,
            billingCity: useForBillingAddress ? city : billingCity,
            billingPostalCode: useForBillingAddress ? postalCode : billingPostalCode,
            billingCountryId: Number(useForBillingAddress ? country : billingCountry),
            billingRegionId: Number(useForBillingAddress ? province : billingProvince),
        };
        setNewEmailId(email);
        postUser(enqueueSnackbar, body);
    };

    const handleNext = () => {
        setError('');
        isValid().then(r => {
        if (r) {
            if (!props.customer && WebStore.activeStep === 0 && existingUserSelected) {
                if (user !== '') {
                    WebStore.setActiveStep(WebStore.activeStep + 2);
                    setUserError('');
                } else {
                    setUserError('Please select a user.');
                }
            }
            else if (WebStore.activeStep < steps.length - (props.customer ? 3 : 1)) {
                if (!props.customer && WebStore.activeStep === 1 && email !== newEmailId) {
                    setError('');
                    createUser();
                }
                WebStore.setActiveStep(WebStore.activeStep + 1);
            }
            else {
                submit();
            }
        }
        });
    };

    const handleBack = () =>
        WebStore.setActiveStep(WebStore.activeStep -
            (!props.customer && WebStore.activeStep === 2 && existingUserSelected ? 2 : 1));

    const renderPaper = () => {
        switch (steps[props.customer ? WebStore.activeStep + 2 : WebStore.activeStep]) {
            case Constants.SELECT_USER:
                return (
                    <SelectUserPaper
                        existingUserSelected={existingUserSelected}
                        setExistingUserSelected={setExistingUserSelected}
                        user={{
                            value: user,
                            setValue: setUser,
                        }}
                        error={userError}
                    />
                );
            case Constants.CREATE_NEW_USER:
                return (
                    <CreateUserPaper
                        isCustomer={!!props.customer}
                        firstName={{
                            value: firstName,
                            setValue: setFirstName,
                            error: firstNameError,
                            setError: setFirstNameError,
                        }}
                        lastName={{
                            value: lastName,
                            setValue: setLastName,
                            error: lastNameError,
                            setError: setLastNameError,
                        }}
                        email={{
                            value: email,
                            setValue: setEmail,
                            error: emailError,
                            setError: setEmailError,
                        }}
                        phoneNumber={{
                            value: phoneNumber,
                            setValue: setPhoneNumber,
                            error: phoneNumberError,
                            setError: setPhoneNumberError,
                        }}
                        password={{
                            value: password,
                            setValue: setPassword,
                            error: passwordError,
                            setError: setPasswordError,
                        }}
                        passwordConfirmation={{
                            value: passwordConfirmation,
                            setValue: setPasswordConfirmation,
                            error: passwordConfirmationError,
                            setError: setPasswordConfirmationError,
                        }}
                        country={{
                            value: country,
                            setValue: setCountry,
                            error: countryError,
                            setError: setCountryError,
                        }}
                        province={{
                            value: province,
                            setValue: setProvince,
                            error: provinceError,
                            setError: setProvinceError,
                        }}
                        city={{
                            value: city,
                            setValue: setCity,
                            error: cityError,
                            setError: setCityError,
                        }}
                        address={{
                            value: address,
                            setValue: setAddress,
                            error: addressError,
                            setError: setAddressError,
                        }}
                        postalCode={{
                            value: postalCode,
                            setValue: setPostalCode,
                            error: postalCodeError,
                            setError: setPostalCodeError,
                        }}
                        useForBillingAddress={useForBillingAddress}
                        setUseForBillingAddress={setUseForBillingAddress}
                        billingPostalCode={{
                            value: billingPostalCode,
                            setValue: setBillingPostalCode,
                            error: billingPostalCodeError,
                            setError: setBillingPostalCodeError,
                        }}
                        billingAddress={{
                            value: billingAddress,
                            setValue: setBillingAddress,
                            error: billingAddressError,
                            setError: setBillingAddressError,
                        }}
                        billingCity={{
                            value: billingCity,
                            setValue: setBillingCity,
                            error: billingCityError,
                            setError: setBillingCityError,
                        }}
                        billingProvince={{
                            value: billingProvince,
                            setValue: setBillingProvince,
                            error: billingProvinceError,
                            setError: setBillingProvinceError,
                        }}
                        billingCountry={{
                            value: billingCountry,
                            setValue: setBillingCountry,
                            error: billingCountryError,
                            setError: setBillingCountryError,
                        }}
                    />
                );
            case Constants.SET_SITE_LOCATION:
                return (
                    <SetSiteLocationPaper
                        site={site}
                        setSite={setSite}
                        siteLocationInvalid={siteLocationInvalid}
                        userId={WebStore.selectedView === Constants.DEALER ? userId : undefined}
                    />
                );
            case Constants.ADD_DEVICES:
                return site !== null ? (
                    <AddDevicesPaper
                        site={site}
                        markerData={markerData}
                        setMarkerData={setMarkerData}
                    />
                ) : <div/>;
            // case Constants.SET_CABLE_COUNT:
            //     return (
            //         <SetCableCountPaper
            //             cables={{
            //                 value: cable,
            //                 setValue: setCable,
            //                 error: cableError,
            //                 setError: setCableError,
            //             }}
            //         />
            //     );
            case Constants.CONFIRMATION:
                return <NewSiteConfirmationPaper markerData={markerData}/>;
            default:
                return <div/>;
        }
    };

    return (
        <BaseWizard
            id='new_site_wizard'
            close={submit}
            submit={submit}
            cancel={cancel}
            handleNext={handleNext}
            handleBack={handleBack}
            steps={props.customer ? steps.slice(2) : steps}
            activeStep={WebStore.activeStep}
            setActiveStep={WebStore.setActiveStep}
        >
            {renderPaper()}
            {error !== '' && (
                <Typography color="error">{error}</Typography>
            )}
        </BaseWizard>
    );
});

export default CreateSiteWizard;
