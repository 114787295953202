import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';

const getWeatherDataForLiveAndSolo = (enqueueSnackbar: any, id: number) => {
    callAPI(`weatherForLiveAndSolo/${ id }`,
        (response) => {
            BinsenseStore.setWeatherDataForLiveAndSolo(response);
        },
        enqueueSnackbar
    );
};

export default getWeatherDataForLiveAndSolo;