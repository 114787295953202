/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper, styled, Typography} from '@mui/material';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '16px',
});

interface Props {
    title?: string,
    rightTitle?: object,
    children: object,
    variant?: 'elevation' | 'outlined' | undefined,
}

const TitlePaper = (props: Props) => (
    <Paper
        variant={props.variant}
    >
        {(props.title || props.rightTitle) && (
            <Row>
                <Typography color="primary">
                    {props.title}
                </Typography>
                {props.rightTitle}
            </Row>
        )}
        {props.children}
    </Paper>
);

export default TitlePaper;
