/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper, styled, Typography} from '@mui/material';
import KeyValueTypography from '../../../common/typography/KeyValueTypography';
import Constants from '../../../../helper/Constants';
import getTestID from '../../../../helper/functions/getTestID';
import WebStore from '../../../../stores/WebStore';
import { MarkerData } from '../../../../types/components/ApiTypes';
import checkAddToSubscription from '../../../../helper/functions/checkAddToSubscription';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
});

interface Props {
    markerData: MarkerData[];
}

const NewSiteConfirmationPaper = (props: Props) => (
    <Paper style={{ width: '95%' }}>
        <Root>
            <KeyValueTypography
                label={`STEP ${ WebStore.selectedView === Constants.DEALER ? 5 : 3 }:`}
                value={Constants.CONFIRMATION}
            />
            <div style={{ width: '100%' }}>
                <Typography
                    {...getTestID('new_site_created')}
                    style={{
                        paddingTop: '32px',
                        paddingBottom: '16px',
                    }}
                >
                    A new site has been created.
                </Typography>
                <Typography
                    {...getTestID('new_site_save')}
                    style={{ paddingBottom: '16px' }}
                >
                    Click "Finish" to save the site and begin installing hardware.
                </Typography>
                <Typography
                    {...getTestID('new_site_redirect')}
                    style={{ paddingBottom: '16px' }}
                >
                    You will be redirected to the Site Reports page where you can view the status of hardware reporting
                </Typography>
                {checkAddToSubscription(props.markerData, undefined, undefined, true).length > 0 &&
                    WebStore.selectedView === Constants.CUSTOMER &&
                    <Typography
                        {...getTestID('new_site_subscription_increase')}
                        style={{ paddingBottom: '16px' }}
                    >
                        {checkAddToSubscription(props.markerData, undefined, undefined, true)}
                    </Typography>
                }
            </div>
        </Root>
    </Paper>
);

export default NewSiteConfirmationPaper;
