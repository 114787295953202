import callPutAPI from '../../callPutAPI';
import updateData from '../../../helper/functions/updateData';

const putGrainBag = (enqueueSnackbar: any, id: number, body: any) => {
    callPutAPI(`structure/grainbag/${ id }`,
        body,
        (response) => {
            if (response === 'OK') {
                updateData(enqueueSnackbar);
            }
        },
        enqueueSnackbar,
        'Grain bag updated successfully'
    );
};

export default putGrainBag;