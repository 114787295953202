/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {
    AutomationHubs,
    Bin,
    Building,
    GrainBag,
    MarkerData,
    MarkerTypes,
    Site
} from '../../types/components/ApiTypes';
import getPutBinBodyFromMarker from './getPutBinBodyFromMarker';
import getPutGrainBagBodyFromMarker from './getPutGrainBagBodyFromMarker';
import deleteStructure from '../../controllers/apiCalls/delete/deleteStructure';
import getPutPileBodyFromMarker from './getPutPileBodyFromMarker';
import getPutAutomationBodyFromMarker from './getPutAutomationBodyFromMarker';
import getIndex from './getIndex';
import putSite from '../../controllers/apiCalls/put/putSite';
import postBins from '../../controllers/apiCalls/post/postBins';
import postGrainBags from '../../controllers/apiCalls/post/postGrainBags';
import postPiles from '../../controllers/apiCalls/post/postPiles';
import postAutomationHubs from '../../controllers/apiCalls/post/postAutomationHubs';
import getPutBuildingBodyFromMarker from './getPutBuildingBodyFromMarker';
import postBuildings from '../../controllers/apiCalls/post/postBuildings';
import WebStore from '../../stores/WebStore';
import { getBalance } from '../../controllers/apiCalls/get/getBalance';
import Constants from '../Constants';
import BinsenseStore from '../../stores/BinsenseStore';

const editBin = (marker: MarkerData, site: any) => {
    const bin = getPutBinBodyFromMarker(marker);
    const index = getIndex(marker.id, site.bins);
    if (index !== -1) {
        site.bins[index] = bin;
    }
};

const editGrainBag = (marker: MarkerData, site: any) => {
    const bag = getPutGrainBagBodyFromMarker(marker);
    const index = getIndex(marker.id, site.grainBags);
    if (index !== -1) {
        site.grainBags[index] = bag;
    }
};

const editBuilding = (marker: MarkerData, site: any) => {
    const building = getPutBuildingBodyFromMarker(marker);
    const index = getIndex(marker.id, site.buildings);
    if (index !== -1) {
        site.buildings[index] = building;
    }
};

// const editPile = (marker: MarkerData, site: any) => {
//     const pile = getPutPileBodyFromMarker(marker);
//     const index = getIndex(marker.id, site.piles);
//     if (index !== -1) {
//         site.piles[index] = pile;
//     }
// };

const editAutomationHub = (marker: MarkerData, site: any) => {
    const hub = getPutAutomationBodyFromMarker(marker);
    const index = getIndex(marker.id, site.automationHubs);
    if (index !== -1) {
        site.automationHubs[index] = hub;
    }
};

const postStructs = (bins: any[], bags: any[], buildings: any[], piles: any[], hubs: any[],
                     id: number, enqueueSnackbar: any) => {
    if (bins.length > 0) {
        postBins(enqueueSnackbar, id, bins);
        WebStore.setAssociationsChanged(true);
    }
    if (bags.length > 0) {
        postGrainBags(enqueueSnackbar, id, bags);
        WebStore.setAssociationsChanged(true);
    }
    if (buildings.length > 0) {
        postBuildings(enqueueSnackbar, id, buildings);
        WebStore.setAssociationsChanged(true);
    }
    if (piles.length > 0) {
        postPiles(enqueueSnackbar, id, piles);
        WebStore.setAssociationsChanged(true);
    }
    if (hubs.length > 0) {
        postAutomationHubs(enqueueSnackbar, id, hubs);
        WebStore.setAssociationsChanged(true);
    }
};

const saveMarkerData = async (enqueueSnackbar: any, site: Site, markerData: MarkerData[], deletedStructureArray?: any[]) => {
    const clone = JSON.parse(JSON.stringify(site));

    const structIds = [
        ...clone.bins.map((bin: Bin) => bin.id),
        ...clone.grainBags.map((bag: GrainBag) => bag.id),
        ...clone.buildings.map((building: Building) => building.id),
        // ...clone.piles.map((pile: Pile) => pile.id),
        ...clone.automationHubs.map((hub: AutomationHubs) => hub.id),
    ];
    const binStructIds = [
        ...clone.bins.map((bin: Bin) => bin.id),
    ];
    const grainBagStructIds = [
        ...clone.grainBags.map((bag: GrainBag) => bag.id),
    ];
    const buildingStructIds = [
        ...clone.buildings.map((building: Building) => building.id),
    ];
    // const pileStructIds = [
    //     ...clone.piles.map((pile: Pile) => pile.id),
    // ];
    const automationHubStructIds = [
        ...clone.automationHubs.map((hub: AutomationHubs) => hub.id),
    ];

    const newBins: any[] = [];
    const newBags: any[] = [];
    const newPiles: any[] = [];
    const newBuildings: any[] = [];
    const newAutomationHubs: any[] = [];

    markerData.map(marker => {
        if (!structIds.includes(marker.id) && marker.display) {
            if (marker.display.type === MarkerTypes.bin) {
                newBins.push(getPutBinBodyFromMarker(marker));
            }
            if (marker.display.type === MarkerTypes.grainBag) {
                newBags.push(getPutGrainBagBodyFromMarker(marker));
            }
            if (marker.display.type === MarkerTypes.building) {
                newBuildings.push(getPutBuildingBodyFromMarker(marker));
            }
            if (marker.display.type === MarkerTypes.grainPile) {
                newPiles.push(getPutPileBodyFromMarker(marker));
            }
            if (marker.display.type == MarkerTypes.automation && marker.display.automationHubData !== null) {
                newAutomationHubs.push(getPutAutomationBodyFromMarker(marker));
            }
        }
        if (structIds.includes(marker.id) && marker.display) {
            if (marker.display.type === MarkerTypes.bin) {
                editBin(marker, clone);
            }
            if (marker.display.type === MarkerTypes.grainBag) {
                editGrainBag(marker, clone);
            }
            if (marker.display.type === MarkerTypes.building) {
                editBuilding(marker, clone);
            }
            // if (marker.display.type === MarkerTypes.grainPile) {
            //     editPile(marker, clone);
            // }
            if (marker.display.type == MarkerTypes.automation) {
                editAutomationHub(marker, clone);
            }
        }
    });

    await postStructs(newBins, newBags, newBuildings, newPiles, newAutomationHubs, site.id, enqueueSnackbar);

    structIds.map(structId => {
        if (deletedStructureArray && deletedStructureArray.includes(structId)) {
            deleteStructure(enqueueSnackbar, structId);
            if (binStructIds.includes(structId)) {
                const index = getIndex(structId, clone.bins);
                if (index >= 0) {
                    clone.bins.splice(index, 1);
                }
            }
            if (grainBagStructIds.includes(structId)) {
                const index = getIndex(structId, clone.grainBags);
                if (index >= 0) {
                    clone.grainBags.splice(index, 1);
                }
            }
            if (buildingStructIds.includes(structId)) {
                const index = getIndex(structId, clone.buildings);
                if (index >= 0) {
                    clone.buildings.splice(index, 1);
                }
            }
            // if (pileStructIds.includes(structId)) {
            //     const index = getIndex(structId, clone.piles);
            //     if (index >= 0) {
            //         clone.piles.splice(index, 1);
            //     }
            // }
            if (automationHubStructIds.includes(structId)) {
                const index = getIndex(structId, clone.automationHubs);
                if (index >= 0) {
                    clone.automationHubs.splice(index, 1);
                }
            }
        }
    });

    putSite(enqueueSnackbar, clone);
    if (WebStore.associationsChanged === true) {
        setTimeout(() => {
            getBalance(enqueueSnackbar, WebStore.selectedView.toLowerCase() === Constants.DEALER.toLowerCase()
                ? WebStore.selectedCustomer.id : BinsenseStore.userData.id, null, true, false);
        }, 7000);
    }
};

export default saveMarkerData;
