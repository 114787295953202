/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useMemo, useState} from 'react';
import {Autocomplete, Grid, TextField} from '@mui/material';
import SubmitButton from '../../common/buttons/SubmitButton';
import TitlePaper from '../../common/TitlePaper';
import Constants from '../../../helper/Constants';
import GridTextFieldInput from '../../common/input/GridTextFieldInput';
import StyledSwitch from '../../common/input/StyledSwitch';
import BinsenseStore from '../../../stores/BinsenseStore';
import getTestID from '../../../helper/functions/getTestID';
import putUserSettings from '../../../controllers/apiCalls/put/putUserSettings';
import {useSnackbar} from 'notistack';

const DealerInformationPaper = () => {
    const {enqueueSnackbar} = useSnackbar();

    const userData = useMemo(() => BinsenseStore.userData, [ BinsenseStore.userData ]);
    const modelData = useMemo(() => BinsenseStore.dataModel, [ BinsenseStore.dataModel ]);
    const dealer = useMemo(() => {
        if (userData.settings.dealerId !== -1) {
            for (let i = 0; i < modelData.dealers.length; i++) {
                if (modelData.dealers[i].id === userData.settings.dealerId) {
                    return modelData.dealers[i];
                }
            }
        }
        return undefined;
    }, [ modelData.dealers, userData.settings.dealerId ]);

    const [ dealerId, setDealerId ] = useState(dealer ? dealer.id : -1);
    const [ userDealer, setUserDealer ] = useState<string | null>(dealer ? dealer.name : '');
    const [ userDealerInput, setUserDealerInput ] = useState('');
    const [ phone, setPhone ] = useState(dealer ? dealer.phone : '');
    const [ email, setEmail ] = useState(dealer ? dealer.email : '');
    const [ allowDealerAccess, setAllowDealerAccess ] = useState(userData.settings.allowDealerAccess);

    useEffect(() => {
        setDealerId(dealer ? dealer.id : -1);
        setUserDealer(dealer ? dealer.name : '');
        setUserDealerInput('');
        setPhone(dealer ? dealer.phone : '');
        setEmail(dealer ? dealer.email : '');
        setAllowDealerAccess(userData.settings.allowDealerAccess);
    }, [ userData, dealer ]);

    const isValid = () => dealerId !== -1;

    const submit = () => {
        if (isValid()) {
            const settings = {
                dealerId,
                allowDealerAccess,
            };
            putUserSettings(enqueueSnackbar, BinsenseStore.userData.id, settings);
        }
    };

    const dealerChange = (newValue: string | null) => {
        setUserDealer(newValue);
        const index = modelData.dealers.findIndex(d => d.name === newValue);
        if (index > -1) {
            setDealerId(modelData.dealers[index].id);
            setPhone(modelData.dealers[index].phone);
            setEmail(modelData.dealers[index].email);
        }
    };

    return (
        <TitlePaper title="Dealer Information">
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Autocomplete
                        value={userDealer}
                        onChange={(event, newValue) => dealerChange(newValue)}
                        inputValue={userDealerInput}
                        onInputChange={(event, newInputValue) => {
                            setUserDealerInput(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                {...getTestID('dealer_input')}
                                fullWidth
                                label={Constants.DEALER}
                            />
                        )}
                        options={modelData.dealers.map(d => d.name)}
                    />
                </Grid>
                <GridTextFieldInput
                    id={'dealer_phone_number'}
                    label={Constants.MOBILE_NUMBER}
                    field={{
                        value: phone,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 25,
                        },
                        disabled: true,
                    }}
                />
                <GridTextFieldInput
                    id='dealer_email'
                    label={Constants.EMAIL}
                    field={{
                        value: email,
                    }}
                    textFieldProps={{
                        disabled: true,
                    }}
                />
            </Grid>
            <StyledSwitch
                id="allow_dealer_to_view_sites_switch"
                description="Allow Dealer to View Storage"
                value={allowDealerAccess}
                setValue={setAllowDealerAccess}
            />
            <SubmitButton
                id="dealer_information_submit"
                text={Constants.SAVE_CHANGES}
                onClick={submit}
            />
        </TitlePaper>
    );
};

export default DealerInformationPaper;
