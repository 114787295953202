/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */
import React from 'react';
import {Button, styled} from '@mui/material';
import Constants from '../../../../../helper/Constants';
import {useMemo, useState} from 'react';
import TableViewModal from './TableViewModal';
import getTestID from '../../../../../helper/functions/getTestID';
import {Download, OpenInFull} from '@mui/icons-material';
import {observer} from 'mobx-react-lite';
import {Bin, Building, GrainBag} from '../../../../../types/components/ApiTypes';
import getBinViewTableRows from '../../../../../helper/functions/getBinViewTableRows';
import exportTableToCSV from '../../../../../helper/functions/exportTableToCSV';
import replaceSpaces from '../../../../../helper/functions/replaceSpaces';
import BinImagePanel from '../../grid/BinImagePanel';
import BinsenseStore from '../../../../../stores/BinsenseStore';
import DashboardSkeleton from '../../../../common/DashboardSkeleton';

interface Props {
    bin: Bin | GrainBag | Building,
    handleToggle(): any,
    displayAmbientTemp?: boolean,
}

const SkeletonRoot = styled('div')({
    width: '100%',
    padding: '20px',
    paddingTop: '10px',
});

const TitleRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const DeviceBinViewPaper = observer((props: Props) => {
    const [ binView, setBinView ] = useState(Constants.SIDE_VIEW);
    const [ tableViewOpen, setTableViewOpen ] = useState(false);

    const tableRows = useMemo(() => getBinViewTableRows(props.bin.hardware.cables,
        props.bin.settings.highTemperatureThreshold, props.bin.settings.lowTemperatureThreshold), [ props.bin ]);

    const columns = [
        {
            field: 'row',
            headerName: Constants.ROW,
        },
        {
            field: 'avgTemp',
            headerName: Constants.AVG_TEMP,
        },
        {
            field: 'avgMoisture',
            headerName: Constants.AVG_MOISTURE,
        },
    ];

    const exportClicked = () => {
        if (binView === Constants.TABLE_VIEW) {
            exportTableToCSV(columns, tableRows, `${ replaceSpaces(props.bin.name) }_sensor_avg_table`);
        }
    };

    React.useEffect(() => {
        props.handleToggle();
      }, [ binView ]);
    return !BinsenseStore.awaitingUserData ? (
        <BinImagePanel
            bin={props.bin}
            binView={{
                value: binView,
                setValue: setBinView,
            }}
            includeReset
            maxWidth
            showSensorButtons
            displayAmbientTemp={props.displayAmbientTemp}
        >
            {binView === Constants.TABLE_VIEW && (
                <TitleRow>
                    <Button
                        {...getTestID('export_button')}
                        onClick={exportClicked}
                        startIcon={<Download/>}
                    >
                        {Constants.EXPORT}
                    </Button>
                    <Button
                        {...getTestID('expand_button')}
                        onClick={() => setTableViewOpen(true)}
                        startIcon={<OpenInFull/>}
                    >
                        {Constants.EXPAND}
                    </Button>
                </TitleRow>
            )}
            {tableViewOpen && (
                <TableViewModal
                    bin={props.bin}
                    open
                    close={() => setTableViewOpen(false)}
                />
            )}
        </BinImagePanel>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={400}/>
        </SkeletonRoot>
    );
});

export default DeviceBinViewPaper;
