/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import {useEffect, useMemo, useState} from 'react';
import Constants from '../../../../helper/Constants';
import DisplayGridHeaderRow from '../../../common/displayTable/DisplayGridHeaderRow';
import BinsenseStore from '../../../../stores/BinsenseStore';
import {styled} from '@mui/material';
import {Typography} from '@mui/material';
import {Download} from '@mui/icons-material';
import exportTableToCSV from '../../../../helper/functions/exportTableToCSV';
import getServerCommands from '../../../../controllers/apiCalls/get/getServerCommands';
import {useSnackbar} from 'notistack';
import ServerCommandHistoryTableRow from './ServerCommandHistoryTableRow';
import {Site} from '../../../../types/components/ApiTypes';
import TableButton from '../../../common/buttons/TableButton';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import DisplayGridPaper from '../../../common/displayTable/DisplayGridPaper';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import Pagination from '../../../common/pagination/Pagination';

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const SkeletonRoot = styled('div')({
    width: '100%',
    padding: '20px',
    paddingTop: '0px',
});

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '20px',
});

interface Props {
    site: Site,
}

const ServerCommandHistoryTable = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(30);

    const rows = useMemo(() => BinsenseStore.commandHistoryData, [ BinsenseStore.commandHistoryData ]);

    useEffect(() => {
        if (props.site.id > -1) {
            getServerCommands(enqueueSnackbar, props.site.id, rowsPerPage, page * rowsPerPage);
        }
    }, [ props.site, page, rowsPerPage ]);

    const count = useMemo(() => BinsenseStore.commandCount, [ BinsenseStore.commandCount ]);

    const columns = [
        {
            field: 'serial',
            headerName: Constants.SERIAL_NUMBER,
        },
        {
            field: 'status',
            headerName: Constants.STATUS,
        },
        {
            field: 'commandText',
            headerName: Constants.DETAILS,
        },
        {
            field: 'queueTime',
            headerName: Constants.QUEUE_TIME,
        },
        {
            field: 'sendTime',
            headerName: Constants.SENT_TIME,
        },
        {
            field: 'replyText',
            headerName: Constants.REPLY,
        },
    ];

    const renderHeader = (id: string, label: string, xs: number) => (
        <DisplayGridLabel
            id={`${ id }_command_history_header`}
            label={label}
            xs={xs}
            header
        />
    );

    return (
        <Root>
            <Row>
                <Typography
                    color="primary"
                    width='250px'
                >
                    Server Command History
                </Typography>
                <TableButton
                    id="export_server_command_history_button"
                    label={Constants.EXPORT}
                    onClick={() => exportTableToCSV(columns, rows, 'server_command_history')}
                    icon={<Download/>}
                />
            </Row>
            {!BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
                <div>
                    <DisplayGridPaper>
                        <DisplayGridHeaderRow sx={{marginTop: '0px'}}>
                            {renderHeader('serial', Constants.SERIAL, 1.5)}
                            {renderHeader('status', Constants.STATUS, 1.5)}
                            {renderHeader('details', Constants.DETAILS, 3)}
                            {renderHeader('queue_time', Constants.QUEUE_TIME, 1.5)}
                            {renderHeader('sent_time', Constants.SENT_TIME, 1.5)}
                            {renderHeader('reply', Constants.REPLY, 3)}
                        </DisplayGridHeaderRow>
                        {rows.map(row => (
                            <ServerCommandHistoryTableRow
                                row={row}
                                key={`${ row.id }_command_history_row`}
                            />
                        ))}
                    </DisplayGridPaper>
                    <Pagination
                        count={count}
                        pageField={{
                            value: page,
                            setValue: setPage,
                        }}
                        rowsPerPageField={{
                            value: rowsPerPage,
                            setValue: setRowsPerPage,
                        }}
                    />
                </div>
            ) : (
                <SkeletonRoot>
                    <DashboardSkeleton height={65 * rowsPerPage}/>
                </SkeletonRoot>
            )}
        </Root>
    );
});

export default ServerCommandHistoryTable;
