/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import {useState} from 'react';
import DisplayGridHeaderRow from '../../../common/displayTable/DisplayGridHeaderRow';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DisplayGridSortableTableHeader from '../../../common/displayTable/DisplayGridSortableTableHeader';
import Constants from '../../../../helper/Constants';
import ReportsLogsTableRow from './ReportsLogsTableRow';
import PaginationTable from '../../../common/displayTable/PaginationTable';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import {styled} from '@mui/material';

interface Props {
    data: any[],
}

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const ReportsLogsTable = observer((props: Props) => {
    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const renderHeader = (id: string, label: string, key: string, xs: number) => (
        <DisplayGridSortableTableHeader
            id={id}
            label={label}
            xs={xs}
            data={BinsenseStore.binLogsData}
            setData={BinsenseStore.setBinLogsData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderRow = (row: any) => (
        <ReportsLogsTableRow
            row={row}
            key={`${ row.id }_log`}
        />
    );

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <PaginationTable
            rows={props.data}
            renderRow={renderRow}
        >
            <DisplayGridHeaderRow sx={{marginTop: '0px'}}>
                {renderHeader('received_data', Constants.DATE, 'logDate', 2)}
                {renderHeader('title', Constants.TITLE, 'type', 3)}
                {renderHeader('description', Constants.DESCRIPTION, 'log', 5)}
                {renderHeader('accepted', Constants.STATUS, 'accepted', 2)}
            </DisplayGridHeaderRow>
        </PaginationTable>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={600}/>
        </SkeletonRoot>
    );
});

export default ReportsLogsTable;
