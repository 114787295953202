/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper, styled} from '@mui/material';
import {useMemo} from 'react';
import BinsenseStore from '../../../stores/BinsenseStore';
import {observer} from 'mobx-react-lite';
import DonutChart from '../../common/charts/DonutChart';
import DashboardSkeleton from '../../common/DashboardSkeleton';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const SiteInventoryPaper = observer(() => {
    const inventory = useMemo(() => BinsenseStore.inventoryTableData.filter(site => site.bushels !== 0),
        [ BinsenseStore.inventoryTableData ]);

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <Paper sx={{height: '100%'}}>
            <DonutChart
                title="Site Inventory"
                series={inventory.map(site => Math.round(site.bushels))}
                labels={inventory.map(site => site.site)}
            />
        </Paper>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={400}/>
        </SkeletonRoot>
    );
});

export default SiteInventoryPaper;
