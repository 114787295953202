/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Tab, Tabs, Button} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {Dispatch, SetStateAction, useState, useMemo} from 'react';
import Constants from '../../../../helper/Constants';
import NewNoteModal from '../../notes/NewNoteModal';
import ViewDeviceBinSelect from './ViewDeviceBinSelect';
import getTestID from '../../../../helper/functions/getTestID';
import {GrainBag, Bin, Site, Building} from '../../../../types/components/ApiTypes';
import BinsenseStore from '../../../../stores/BinsenseStore';
import { Add, Download } from '@mui/icons-material';
import exportTableToCSV from '../../../../helper/functions/exportTableToCSV';
import { useSnackbar } from 'notistack';
import postServerCommand from '../../../../controllers/apiCalls/post/postServerCommand';

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px',
});

const TabRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '55%',
    height: '50px',
});

interface Props {
    bin: Bin | GrainBag | Building,
    site: Site,
    tab: string,
    setTab: Dispatch<SetStateAction<string>>,
}

const Row = styled('div')({
    display: 'flex',
    width: '30%',
    justifyContent: 'right',
    paddingBottom: '12px',
    paddingRight: '20px',
});

const ViewDevicePageHeader = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ newNoteModalOpen, setNewNoteModalOpen ] = useState(false);

    const handleChange = (event: any, newValue: string) => {
        props.setTab(newValue);
    };

    const rowInfo = BinsenseStore.notesTableData.filter((structureId) => (structureId.structureId) === props.bin.id);

    const rows = useMemo(() => rowInfo, [ rowInfo ]);

    const columns = [
        {
            field: 'note',
            headerName: Constants.NOTE,
        },
        {
            field: 'structure',
            headerName: Constants.STRUCTURE,
        },
        {
            field: 'date',
            headerName: Constants.DATE,
        },
    ];

    const masterSN = useMemo(() => {
        let serial = '';
        //Only one master unit will be available for a structure.
        //So, the below FOR loop will run only once.
        props.bin.hardware?.masterUnits.forEach(unit => {
            serial = unit.serial;
        });
        return serial;
    }, [ props.bin ]);

    const getScanCommandId = () => {
        let retValue = -1;
        const index = BinsenseStore.dataModel.serverCommandsGroupMap.findIndex(commandGroup =>
            commandGroup.groupName === Constants.MASTER.toUpperCase());
        if (index > -1) {
            BinsenseStore.dataModel.serverCommandsGroupMap[index].commands.forEach(command => {
                if (command.commandName.toLowerCase() === 'scan') {
                    retValue = command.id;
                }
            });
        }
        return retValue;
    };

    const sendScanCommand = () => {
        const body = {
            commandId: getScanCommandId(),
            deviceType: Constants.MASTER.toLowerCase(),
            arguments: [],
            scheduledTime: new Date().getTime(),
        };
        postServerCommand(enqueueSnackbar, masterSN, body);
    };

    return (
        <Root>
            <ViewDeviceBinSelect
                site={props.site}
                bin={props.bin}
                tab={props.tab}
            />
            <TabRow>
                <Tabs
                    value={props.tab}
                    onChange={handleChange}
                >
                    <Tab
                        {...getTestID('display_tab')}
                        label={Constants.DISPLAY}
                        value={Constants.DISPLAY}
                    />
                    <Tab
                        {...getTestID('notes_tab')}
                        label={Constants.NOTES}
                        value={Constants.NOTES}
                    />
                    <Tab
                        {...getTestID('settings_tab')}
                        label={Constants.SETTINGS}
                        value={Constants.SETTINGS}
                        disabled={BinsenseStore.awaitingUserData || BinsenseStore.awaitingApi}
                    />
                    <Tab
                        {...getTestID('logs_tab')}
                        label={Constants.ACTIVITY}
                        value={Constants.ACTIVITY}
                    />
                </Tabs>
            </TabRow>
            <Row>
                { masterSN !== '' &&
                <Button
                    {...getTestID('find_my_remote_button')}
                    onClick={() => sendScanCommand()}
                    variant='contained'
                    style={{ marginRight: '8px' }}
                >
                    Find My Remotes
                </Button>
                }
                <Button
                    {...getTestID('new_note_button')}
                    startIcon={<Add/>}
                    onClick={() => setNewNoteModalOpen(true)}
                    variant='contained'
                >
                    {Constants.NEW_NOTE}
                </Button>
                { props.tab === Constants.NOTES &&
                    <Button
                        {...getTestID('export_button')}
                        startIcon={<Download/>}
                        onClick={() => exportTableToCSV(columns, rows,
                            `user_${ BinsenseStore.userData.id }_notes`)}
                        variant='contained'
                        style={{ marginLeft: '8px' }}
                    >
                        {Constants.EXPORT}
                    </Button>
                }
            </Row>
            {newNoteModalOpen && (
                <NewNoteModal
                    open
                    close={() => setNewNoteModalOpen(false)}
                    structureId={props.bin.id}
                    site={props.site}
                />
            )}
        </Root>
    );
});

export default ViewDevicePageHeader;
