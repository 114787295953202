import { observer } from 'mobx-react-lite';
import FanOn from '../../images/FanOn.gif';
import FanOff from '../../images/FanOff.gif';

interface Props {
    isRunning: boolean,
    isHopper: boolean,
    imageScale: number,
    binWidth: number,
    binBottom: number,
}

export class FanStyleSheet {
    static getSheet(imageScale:number) {
        return {
            fanImage: {
                height: 50 * imageScale,
                width: 50 * imageScale,
                top: 400 * imageScale,
                left: 120 * imageScale,
                alignSelf: 'center',
            },
            fanImageHopper: {
                height: 50 * imageScale,
                width: 50 * imageScale,
                top: 335 * imageScale,
                left: 100 * imageScale,
                alignSelf: 'center',
            },
        };
    }
};

const FanGif = observer((props: Props) => {
    const fanImageStyle = props.isHopper
        ? FanStyleSheet.getSheet(props.imageScale).fanImageHopper
        : FanStyleSheet.getSheet(props.imageScale).fanImage;

    if (props.isRunning) {
        return (
            <image
                key={'fanOn'}
                style={fanImageStyle}
                href={FanOn}
                x={props.binWidth}
                y={props.binBottom}
            />
            );
    } else {
        return (
            <image
                key={'fanOff'}
                style={fanImageStyle}
                href={FanOff}
                x={props.isHopper ? props.binWidth + 20 : props.binWidth}
                y={props.binBottom}
            />
            );
    }
});

export default FanGif;