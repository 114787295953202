/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {InputFieldProps} from '../../../../../../types/components/ApiTypes';
import {Grid} from '@mui/material';
import getTempUnit from '../../../../../../helper/functions/getTempUnit';
import TooltipStore from '../../../../../../stores/TooltipStore';
import TooltipMessages from '../../../../../../helper/TooltipMessages';
import {observer} from 'mobx-react-lite';
import GridTextFieldInput from '../../../../../common/input/GridTextFieldInput';
import InfoTooltip from '../../../../../common/InfoTooltip';

interface Props {
    lowTemperature: InputFieldProps,
    highTemperature: InputFieldProps,
    plenumRise: InputFieldProps,
    disabled?: boolean,
}

const NaturalAirDryingAdvancedSettings = observer((props: Props) => (
    <Grid
        container
        spacing={2}
    >
        <GridTextFieldInput
            id="temp_range_low_threshold_input"
            label={`Minimum Temperature (${ getTempUnit() })`}
            field={props.lowTemperature}
            textFieldProps={{
                inputProps: {
                    type: 'number',
                    min: '0',
                    max: props.highTemperature.value,
                    disabled: props.disabled,
                },
                InputProps: {
                    endAdornment: (
                        <InfoTooltip
                            id="emc_temp_tooltip_button"
                            open={TooltipStore.beginTooltips && !TooltipStore.showEMCRangeMessages &&
                            !TooltipStore.showAdvancedMessages && TooltipStore.showEMCMoistureMessages}
                            message={TooltipMessages.EMC_MOISTURE}
                            onClick={() => TooltipStore.setShowEMCMoistureMessages(false, true)}
                        />
                    ),
                    disabled: props.disabled,
                },
                disabled: props.disabled,
            }}
        />
        <GridTextFieldInput
            id="temp_range_high_threshold_input"
            label={`Maximum Temperature (${ getTempUnit() })`}
            field={props.highTemperature}
            textFieldProps={{
                inputProps: {
                    type: 'number',
                    max: '40',
                    min: props.lowTemperature.value,
                    disabled: props.disabled,
                },
                InputProps: {
                    endAdornment: (
                        <InfoTooltip
                            id="emc_temp_tooltip_button"
                            message={TooltipMessages.EMC_MOISTURE}
                            onClick={() => TooltipStore.setShowEMCMoistureMessages(false, true)}
                        />
                    ),
                    disabled: props.disabled,
                },
                disabled: props.disabled,
            }}
        />
        <GridTextFieldInput
            id="plenum_rise_input"
            label={`Plenum Rise (${ getTempUnit() })`}
            field={props.plenumRise}
            textFieldProps={{
                inputProps: {
                    type: 'number',
                    min: '0',
                    max: '10',
                    disabled: props.disabled,
                },
                InputProps: {
                    endAdornment: (
                        <InfoTooltip
                            id="plenum_rise_tooltip_button"
                            open={TooltipStore.beginTooltips && !TooltipStore.showEMCMoistureMessages &&
                            TooltipStore.showPlenumRiseMessages}
                            message={TooltipMessages.PLENUM_RISE}
                            onClick={() => TooltipStore.setShowPlenumRiseMessages(false, true)}
                        />
                    ),
                    disabled: props.disabled,
                },
                disabled: props.disabled,
            }}
            fullWidth
        />
    </Grid>
));

export default NaturalAirDryingAdvancedSettings;
