/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import handleError from './handleError';
import loginStore from '../stores/LoginStore';
import BinsenseStore from '../stores/BinsenseStore';
import urlStore from '../stores/urlStore';
import getBaseURL from './getBaseURL';
import {EnqueueSnackbar} from '../types/SnackbarTypes';

const callForBlobAPI = (uri: string,
                        mediaType: string,
                        onSuccess: (response: any) => void,
                        enqueueSnackbar: EnqueueSnackbar,
                        on401Fail?: () => void) => {
    BinsenseStore.setAwaitingApi(true);

    fetch(
        `${ getBaseURL() }${ urlStore.baseDirectory }${ uri }`,
        {
            method: 'GET',
            headers: {
                Accept: mediaType,
                'Content-Type': 'application/json',
                'X-API-TOKEN': loginStore.token,
            },
        }
    )
        .then((response) => {
            handleError(response, enqueueSnackbar, on401Fail);
            return response.blob();
        })
        .then((receivedServerData) => {
            setTimeout(() => {
                BinsenseStore.setAwaitingApi(false);
            }, 300);

            setTimeout(() => {
                onSuccess(receivedServerData);
            }, 50);
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error.message);
            BinsenseStore.setAwaitingApi(false);
        });
};

export default callForBlobAPI;
