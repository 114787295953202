/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Tooltip, Typography} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';
import {CSSProperties} from 'react';

interface Props {
    id: string,
    lengthLimit?: number,
    children: string | number,
    style?: CSSProperties,
    textAlign?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined,
    fontSize?: number,
    fontWeight?: string,
}

const TypographyWithLengthLimit = (props: Props) => (props.lengthLimit &&
    String(props.children).length > props.lengthLimit) ? (
        <Tooltip
        title={props.children}
        followCursor
        >
            <Typography
            {...getTestID(props.id)}
            fontWeight={props.fontWeight}
            fontSize={props.fontSize}
            textAlign={props.textAlign}
            style={{overflowWrap: 'anywhere'}}
            >
                {String(props.children).slice(0, props.lengthLimit)}...
            </Typography>
        </Tooltip>
) : (
    <Typography
        {...getTestID(props.id)}
        fontWeight={props.fontWeight}
        fontSize={props.fontSize}
        textAlign={props.textAlign ? props.textAlign : 'left'}
        style={{overflowWrap: 'anywhere'}}
    >
        {props.children}
    </Typography>
);

export default TypographyWithLengthLimit;
