/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Site} from '../../types/components/ApiTypes';

const moveStructuresByKey = (site: Site, latOffset: number, longOffset: number, key: string) => {
    site[key].forEach((struct: any) => {
        if (!struct.latLongOffset) {
            struct.latitude += latOffset;
            struct.longitude += longOffset;
        }
        else {
            struct.latLongOffset = false;
            struct.latitude += site.latitude;
            struct.longitude += site.longitude;
        }
    });
};

const moveStructures = (site: Site, latOffset: number, longOffset: number) => {
    moveStructuresByKey(site, latOffset, longOffset, 'bins');
    moveStructuresByKey(site, latOffset, longOffset, 'grainBags');
    moveStructuresByKey(site, latOffset, longOffset, 'buildings');
    // moveStructuresByKey(site, latOffset, longOffset, 'piles');
    moveStructuresByKey(site, latOffset, longOffset, 'automationHubs');
};

export default moveStructures;
