/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import Constants from '../../helper/Constants';
import PasswordField from '../common/input/PasswordField';
import {InputFieldProps} from '../../types/components/ApiTypes';
import TextFieldInput from '../common/input/TextFieldInput';
import LoginStore from '../../stores/LoginStore';
import {observer} from 'mobx-react-lite';

interface Props {
    username: InputFieldProps,
    password: InputFieldProps,
}

const Root =  styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const LoginPaperTextFields = observer((props: Props) => (
    <Root>
        <TextFieldInput
            id="username_input"
            label={Constants.USERNAME}
            field={props.username}
        />
        <PasswordField
            id="password_input"
            label={Constants.PASSWORD}
            field={props.password}
        />
        {(LoginStore.wrongUsernameOrPassword || LoginStore.wrongPassword) && (
            <Typography color="error">
                The given username and/or password is incorrect
            </Typography>
        )}
    </Root>
    ));

export default LoginPaperTextFields;