/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {Button, styled, Typography} from '@mui/material';
import getTestID from '../../helper/functions/getTestID';
import { Info } from '@mui/icons-material';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});

const End = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});

interface Props {
    id: string,
    message: string,
    onClick: () => void,
}

const TooltipContents = (props: Props) => (
    <React.Fragment>
        <Row>
            <Info/>
            <div style={{width: '16px'}}/>
            <Typography color="inherit">{props.message}</Typography>
        </Row>
        <End>
            <Button
                {...getTestID(props.id)}
                onClick={props.onClick}
            >
                Ok
            </Button>
        </End>
    </React.Fragment>
);

export default TooltipContents;
