import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';
import {cloneDeep} from 'lodash';
import {ServerCommandsGroup} from '../../../types/components/ApiTypes';

const getDataModel = (enqueueSnackbar: any) => {
    callAPI('dataModel',
        (response) => {
            const dataModel = cloneDeep(response);
            const keys = Object.keys(response.serverCommandsGroupMap);
            const serverCommandsGroups: ServerCommandsGroup[] = [];
            keys.forEach(key => {
                serverCommandsGroups.push({
                    groupName: key,
                    commands: response.serverCommandsGroupMap[key],
                });
            });
            dataModel.serverCommandsGroupMap = serverCommandsGroups;
            dataModel.automationDefaults = response.automationDefaults;

            BinsenseStore.setModelData(dataModel);
        },
        enqueueSnackbar
    );
};

export default getDataModel;