/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import Constants from '../../../helper/Constants';
import {Download} from '@mui/icons-material';
import exportTableToCSV from '../../../helper/functions/exportTableToCSV';
import SelectFieldInput from '../../common/input/SelectFieldInput';
import {styled} from '@mui/material';
import {InputFieldProps, SelectOptions} from '../../../types/components/ApiTypes';
import TableButton from '../../common/buttons/TableButton';
import DateRangePicker from '../../common/DateRangePicker';
import BinsenseStore from '../../../stores/BinsenseStore';
import DashboardSkeleton from '../../common/DashboardSkeleton';

interface Props {
    selectField: InputFieldProps,
    options: SelectOptions[],
    timeField?: {
        start: any,
        setStart: any,
        end: any,
        setEnd: any,
    }
    rows: any[],
    columns: any[],
    noExport?: boolean,
    fileName?: string,
}

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '20px',
});

const DateSelectorDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    minWidth: '625px',
    maxWidth: '625px',
});

const ReportsTableHeader = observer((props: Props) => (
    <Row style={{ justifyContent: 'space-between' }}>
        <DateSelectorDiv>
            <div
                style={{
                    marginRight: '40px',
                    minWidth: '255px',
                }}
            >
                {!BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
                    <SelectFieldInput
                        id="structure_select_field"
                        label={Constants.STRUCTURE}
                        field={props.selectField}
                        options={props.options}
                        small
                        noBottomMargin={props.timeField !== undefined}
                    />
                ) : (
                    <DashboardSkeleton height={40}/>
                )}
            </div>
            {props.timeField && (
                <DateRangePicker
                    start={props.timeField.start}
                    setStart={props.timeField.setStart}
                    end={props.timeField.end}
                    setEnd={props.timeField.setEnd}
                    small
                />
            )}
        </DateSelectorDiv>
        {!props.noExport && (
            <TableButton
                id="export_table_button"
                label={Constants.EXPORT}
                onClick={() => exportTableToCSV(props.columns, props.rows,
                    props.fileName ? props.fileName : 'logs_report')}
                icon={<Download/>}
            />
        )}
    </Row>
    ));

export default ReportsTableHeader;
