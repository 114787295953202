/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import { Grid, Typography, styled } from '@mui/material';
import BaseModal from '../../common/modal/BaseModal';
import { observer } from 'mobx-react-lite';
import GridTextFieldInput from '../../common/input/GridTextFieldInput';
import { useMemo, useState } from 'react';
import Constants from '../../../helper/Constants';
import getIndex from '../../../helper/functions/getIndex';
import putBin from '../../../controllers/apiCalls/put/putBin';
import { useSnackbar } from 'notistack';
import { DeviceSettingsStructure, SiteStructures } from '../../../types/components/ApiTypes';
import putGrainBag from '../../../controllers/apiCalls/put/putGrainBag';
import getTempUnit from '../../../helper/functions/getTempUnit';
import { cToF, displayTemp, fToC } from '../../../helper/functions/functions';
import getSelectedSiteIndex from '../../../helper/functions/getSelectedSiteIndex';
import WebStore from '../../../stores/WebStore';
import BinsenseStore from '../../../stores/BinsenseStore';
import putStructureSettings from '../../../controllers/apiCalls/put/putStructureSettings';
import StyledSwitch from '../../common/input/StyledSwitch';

const Container = styled('div')({
    width: '600px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
    id: number | null,
    devices: DeviceSettingsStructure[],
    siteId?: number,
}

const EditSelectedDevicesModal = observer((props: Props) => {
    const { enqueueSnackbar } = useSnackbar();

    const index = useMemo(() => props.id !== null ? getIndex(props.id, props.devices) : undefined, [ props.devices ]);
    const isDirect = index !== undefined ? props.devices[index].hardwareType.toLowerCase() === Constants.DIRECT.toLowerCase() : false;

    const [ binName, setBinName ] = useState(index !== undefined ? props.devices[index].name : '');
    const [ binNameError, setBinNameError ] = useState('');
    const [ alerts, setAlerts ] = useState(Constants.THRESHOLDS);
    const [ highThreshold, setHighThreshold ] = useState(
        index !== undefined
            ? (props.devices[index].highTempThreshold !== null
                ? displayTemp(getTempUnit() === 'F', props.devices[index].highTempThreshold!) : '') : '');
    const [ highThresholdError, setHighThresholdError ] = useState('');
    const [ lowThreshold, setLowThreshold ] = useState(
        index !== undefined
            ? (props.devices[index].lowTempThreshold !== null
                ? displayTemp(getTempUnit() === 'F', props.devices[index].lowTempThreshold!) : '') : '');
    const [ weeklyTempRise, setWeeklyTempRise ] = useState(
        index !== undefined && !isDirect ? displayTemp(getTempUnit() === 'F', props.devices[index].weeklyTempRise, true) : '');
    const [ weeklyTempRiseError, setWeeklyTempRiseError ] = useState('');
    const maxThreshold = getTempUnit() === 'F' ? cToF(Constants.MAX_TEMP_HIGH_THRESHOLD_IN_CELSIUS)
        : Constants.MAX_TEMP_HIGH_THRESHOLD_IN_CELSIUS;
    const [ autoLevel, setAutoLevel ] = useState(index !== undefined ? props.devices[index].autoLevel : true);
    const enableAutoLevelToggle = index !== undefined
        ? props.devices[index].hardwareType.toLowerCase() === Constants.LIVE.toLowerCase() ||
            props.devices[index].hardwareType.toLowerCase() === Constants.PLUS.toLowerCase()
        : true;

    const showAutoLevelFootnote = index === undefined;

    const disableFields = useMemo(() => index !== undefined ? props.devices[index].grainId === 33
        : props.devices.filter(device => device.selected && device.grainId !== 33).length === 0, [ index ]);

    const isValidThresholds = () => (disableFields || (highThreshold === '' ||
        ((highThreshold !== '' && Number(highThreshold) <= maxThreshold))));
    const isValid = () => binName.trim() !== '' && (disableFields || isValidThresholds());
    const nameOnPageLoad = index !== undefined ? props.devices[index].name : '';

    const setErrors = () => {
        if (binName.trim() === '') {
            setBinNameError('Please enter a valid Name');
        }
        if (!disableFields) {
            if (highThreshold !== '' && Number(highThreshold) > maxThreshold) {
                setHighThresholdError(`Maximum value for High Threshold is ${ maxThreshold } ${ getTempUnit() }`);
            }
        }
    };

    const structNames = useMemo(() => {
        const names = WebStore.selectedView === Constants.CUSTOMER
            ? BinsenseStore.userData.sites[getSelectedSiteIndex()].structNames
            : BinsenseStore.siteStructures.structNames;
        return names ? names : [];
    }, [ WebStore.selectedSiteId, BinsenseStore.userData, BinsenseStore.siteStructures ]);

    const setBinErrors = () => {
        if (binName.trim() === '') {
            setBinNameError('Please enter a name for this bin.');
            return false;
        }

        if ((nameOnPageLoad === '' || nameOnPageLoad.trim().toLowerCase() !== binName.trim().toLowerCase()) &&
            structNames && structNames.some(item => item.trim().toLowerCase() === binName.trim().toLowerCase())) {
            setBinNameError('This name already exists');
            return false;
        }
        return true;
    };

    const resetErrors = () => {
        if (binName.trim() !== '') {
            setBinNameError('');
        }
        if (weeklyTempRise !== '') {
            setWeeklyTempRiseError('');
        }
        if (alerts.toLowerCase() === 'thresholds' && highThreshold !== '') {
            setHighThresholdError('');
        }
    };

    const saveStructure = (id: number, body: any, key: string) => {
        if (key === 'bins') {
            putBin(enqueueSnackbar, id, body);
        }
        if (key === 'grainBags') {
            putGrainBag(enqueueSnackbar, id, body);
        }
    };

    const editDataSingle = () => {
        if (index !== undefined) {
            const body = {
                name: binName,
                settings: {
                    temperatureMonitorType: alerts.toLowerCase(),
                    highTemperatureThreshold: highThreshold !== ''
                        ? getTempUnit() === 'F' ? fToC(Number(highThreshold)) : Number(highThreshold)
                        : undefined,
                    lowTemperatureThreshold: lowThreshold !== ''
                        ? getTempUnit() === 'F' ? fToC(Number(lowThreshold)) : Number(lowThreshold)
                        : undefined,
                    weeklyTemperatureRiseThreshold: !isDirect && weeklyTempRise !== ''
                        ? getTempUnit() === 'F' ? fToC(Number(weeklyTempRise), true) : Number(weeklyTempRise)
                        : undefined,
                    autoExposeCoverSensors: autoLevel,
                    clearLowTemperatureThreshold: lowThreshold === '' ? true : undefined,
                },
            };
            saveStructure(Number(props.id), body, props.devices[index].typeKey);
        }
    };

    const editDataMultiple = () => {
        const bins: any[] = [];
        const grainBags: any[] = [];
        const buildings: any[] = [];
        props.devices.forEach(device => {
            if (device.selected) {
                const body = {
                    id: device.id,
                    settings: {
                        temperatureMonitorType: alerts.toLowerCase(),
                        highTemperatureThreshold: !disableFields && highThreshold !== '' && device.grainId !== 33
                            ? (getTempUnit() === 'F' ? fToC(Number(highThreshold))
                            : Number(highThreshold)) : undefined,
                        lowTemperatureThreshold: !disableFields && lowThreshold !== '' && device.grainId !== 33
                            ? (getTempUnit() === 'F' ? fToC(Number(lowThreshold))
                            : Number(lowThreshold)) : undefined,
                        weeklyTemperatureRiseThreshold: !disableFields && weeklyTempRise !== '' && device.grainId !== 33 &&
                            device.hardwareType.toLowerCase() !== Constants.DIRECT.toLowerCase()
                            ? (getTempUnit() === 'F' ? fToC(Number(weeklyTempRise), true)
                            : Number(weeklyTempRise)) : undefined,
                        autoExposeCoverSensors: device.hardwareType.toLowerCase() === Constants.LIVE.toLowerCase() ||
                            device.hardwareType.toLowerCase() === Constants.PLUS.toLowerCase()
                            ? autoLevel : false,
                    },
                };
                if (device.typeKey === 'bins') {
                    bins.push(body);
                }
                if (device.typeKey === 'grainBags') {
                    grainBags.push(body);
                }
                if (device.typeKey === 'buildings') {
                    buildings.push(body);
                }
            }
        });
        const siteStructures: SiteStructures = {
            id: props.siteId ? props.siteId : WebStore.selectedSiteId,
            automationHubs: [],
            bins: [],
            buildings: [],
            grainBags: [],
            structNames: [],
            dataGeneratedTime: new Date(),
        };
        if (bins.length > 0) {
            siteStructures.bins = bins;
        }
        if (grainBags.length > 0) {
            siteStructures.grainBags = grainBags;
        }
        if (buildings.length > 0) {
            siteStructures.buildings = buildings;
        }
        putStructureSettings(enqueueSnackbar, props.siteId ? props.siteId : WebStore.selectedSiteId, siteStructures);
    };

    const submit = () => {
        resetErrors();
        if (index !== undefined) {
            if (setBinErrors() && isValid()) {
                editDataSingle();
                props.close();
            } else {
                setErrors();
            }
        } else {
            setErrors();
            if (isValidThresholds()) {
                editDataMultiple();
                props.close();
            } else {
                setErrors();
            }
        }
    };

    return (
        <BaseModal
            id="edit_device_modal"
            open={props.open}
            close={props.close}
            submit={submit}
            title={props.id !== null ? 'Edit Device' : 'Edit Devices'}
            disableSubmitOnAwait
            disableSubmit={disableFields && !props.id}
        >
            <Container>
                <Grid
                    container
                    spacing={2}
                    style={{ paddingTop: '16px' }}
                >
                    {props.id !== null && (
                        <GridTextFieldInput
                            id="bin_name_input"
                            label="Bin Name"
                            field={{
                                value: binName,
                                setValue: setBinName,
                                error: binNameError,
                                setError: setBinNameError,
                            }}
                            fullWidth
                        />
                    )}
                    <GridTextFieldInput
                        id="alerts_input"
                        label={Constants.ALERTS}
                        field={{
                            value: alerts,
                            setValue: setAlerts,
                        }}
                        textFieldProps={{
                            inputProps: {
                                type: 'string',
                            },
                            disabled: alerts.toLowerCase() === 'thresholds',
                        }}
                        fullWidth
                    />
                    <GridTextFieldInput
                        id="high_threshold_input"
                        label={`High Threshold (${ getTempUnit() })`}
                        field={{
                            value: highThreshold,
                            setValue: setHighThreshold,
                            error: highThresholdError,
                            setError: setHighThresholdError,
                        }}
                        textFieldProps={{
                            inputProps: {
                                type: 'number',
                            },
                            disabled: alerts.toLowerCase() !== 'thresholds' || disableFields,
                        }}
                        fullWidth
                    />
                    <GridTextFieldInput
                        id="low_threshold_input"
                        label={`Low Threshold (${ getTempUnit() })`}
                        field={{
                            value: lowThreshold,
                            setValue: setLowThreshold,
                        }}
                        textFieldProps={{
                            inputProps: {
                                type: 'number',
                            },
                            disabled: alerts.toLowerCase() !== 'thresholds' || disableFields,
                        }}
                        fullWidth
                    />
                    <GridTextFieldInput
                        id="weekly_temperatre_rise_input"
                        label={`Weekly Temp. Rise (${ getTempUnit() })`}
                        field={{
                            value: weeklyTempRise,
                            setValue: setWeeklyTempRise,
                            error: weeklyTempRiseError,
                            setError: setWeeklyTempRiseError,
                        }}
                        textFieldProps={{
                            inputProps: {
                                type: 'number',
                            },
                            disabled: alerts.toLowerCase() !== 'thresholds' || disableFields || isDirect,
                        }}
                        fullWidth
                    />
                </Grid>

                { enableAutoLevelToggle
                ?   (
                    <Grid
                        container
                        spacing={0}
                        style={{ paddingTop: '16px' }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <StyledSwitch
                            id='auto_level_switch'
                            description='Auto Level'
                            value={autoLevel}
                            setValue={setAutoLevel}
                        />
                        { showAutoLevelFootnote
                        ? (
                            <Typography fontSize="14px">{ Constants.AUTO_LEVEL_FOOTNOTE }</Typography>
                        )
                        : null }
                    </Grid>
                )
                : null }
            </Container>
        </BaseModal>
    );
});

export default EditSelectedDevicesModal;