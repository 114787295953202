/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import WebStore from '../../stores/WebStore';

const changeDrawerAfterConfirmation = () => {
    if (WebStore.selectedDrawerAfterConfirmation !== '') {
        WebStore.setSelectedDrawer(WebStore.selectedDrawerAfterConfirmation, true);
        WebStore.setSelectedDrawerAfterConfirmation('');
    }
};

export default changeDrawerAfterConfirmation;
