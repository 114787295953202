/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {DealerUser} from '../../types/components/ApiTypes';
import BinsenseStore from '../../stores/BinsenseStore';
import getIndex from './getIndex';
import Constants from '../Constants';

const getUserDealer = (user: DealerUser) => {
    const dealers = BinsenseStore.dealerUserData.dealerGroups;
    const index = getIndex(user.dealerId, dealers);
    return index !== -1 ? dealers[index].name : Constants.NA;
};

const getMyUsersData = (users: DealerUser[]) => users.map(user => ({
    id: user.id,
    email: user.username ? user.username : user.emailAddress,
    name: `${ user.lastName }, ${ user.firstName }`,
    dealer: getUserDealer(user),
}));

export default getMyUsersData;
