import updateData from '../../../helper/functions/updateData';
import { AutomationSettings } from '../../../types/components/ApiTypes';
import callPutAPI from '../../callPutAPI';

const putNote = (enqueueSnackbar: any, id: number, settings: AutomationSettings) => {
    callPutAPI(`structure/automationSettings/${ id }`,
        settings,
        (response) => {
            if (response === 'OK') {
                updateData(enqueueSnackbar);
            }
        },
        enqueueSnackbar,
        'Automation settings updated successfully'
    );
};

export default putNote;