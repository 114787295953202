/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import TextField from '@mui/material/TextField';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import getTestID from '../../../helper/functions/getTestID';
import {CSSProperties} from 'react';

interface Props {
    id: string,
    label: string,
    field: InputFieldProps,
    textFieldProps?: object,
    onBlur?: (value: string) => void;
    size?: 'small' | 'medium',
    style?: CSSProperties,
}

function TextFieldInput(props: Props) {
    // @ts-ignore
    const onBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (props.onBlur) {
            props.onBlur(event.target.value);
        }
    };

    return (
        <TextField
            {...getTestID(props.id)}
            fullWidth
            label={props.label}
            value={props.field.value}
            onChange={(event) => props.field.setValue &&
                props.field.setValue(event.target.value)}
            error={props.field.error !== '' && props.field.error !== undefined}
            helperText={props.field.error}
            {...props.textFieldProps}
            onBlur={onBlur}
            size={props.size}
            style={props.style}
        />
    );
}

export default TextFieldInput;
