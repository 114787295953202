/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import ContentPanel from '../../common/ContentPanel';
import {observer} from 'mobx-react-lite';
import DealerReportsPage from '../reports/DealerReportsPage';
import WebStore from '../../../stores/WebStore';
import Constants from '../../../helper/Constants';
import ViewDevicePage from '../../customer/storage/viewDevicePage/ViewDevicePage';
import BinsenseStore from '../../../stores/BinsenseStore';
import {useEffect, useMemo} from 'react';
import getNewSite from '../../../helper/functions/getNewSite';
import getSiteStructures from '../../../controllers/apiCalls/get/getSiteStructures';
import {useSnackbar} from 'notistack';

const DealerReports = observer(() => {
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (WebStore.selectedSiteId !== -1) {
            getSiteStructures(enqueueSnackbar, WebStore.selectedSiteId);
        }
    }, [ WebStore.selectedSiteId ]);

    const site = useMemo(() => ({
        ...getNewSite(),
        ...BinsenseStore.siteStructures,
    }), [ BinsenseStore.siteStructures ]);

    const renderContentPanel = () => {
        switch (WebStore.currentPage) {
            case Constants.MAIN:
                return (<DealerReportsPage/>);
            case Constants.VIEW_DEVICE:
                return (<ViewDevicePage site={site}/>);
            default:
                return (<div/>);
        }
    };

    return (
        <ContentPanel>
            {renderContentPanel()}
        </ContentPanel>
    );
});

export default DealerReports;