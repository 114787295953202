/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import LoginStore from '../../../stores/LoginStore';
import {EnqueueSnackbar} from '../../../types/SnackbarTypes';
import handleError from '../../handleError';

const extractCode = () => {
    const code = LoginStore.authUrl.split('&');
    return code[1].slice(5);
};

const getAPIToken = (enqueueSnackbar: EnqueueSnackbar) => {
    const body = `code=${ extractCode() }&client_id=${ LoginStore.loginConfig.clientId }&client_secret=${
        LoginStore.clientSecret }&redirect_uri=${ LoginStore.redirectUri }`;

    return (
        fetch (
            LoginStore.loginConfig.tokenUrl,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Content-Length': body.length.toString(),
                },
                body,
            }
        )
            .then((response) => {
                handleError(response, enqueueSnackbar);
                return response.json();
            }).then((serverData) => {
                LoginStore.setToken(serverData.access_token, true);
            return {
                success: true,
                code: 200,
            };
            })
    );
};

export default getAPIToken;
