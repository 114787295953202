/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import Constants from '../../../helper/Constants';
import DisplayGridContent from '../../common/displayTable/DisplayGridContent';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import DisplayGridActions from '../../common/displayTable/DisplayGridActions';
import {Visibility} from '@mui/icons-material';
import getTimeSince from '../../../helper/functions/getTimeSince';
import goToViewDevicePage from '../../../helper/functions/goToViewDevicePage';
import WebStore from '../../../stores/WebStore';
import useWindowSize from '../../../helper/hooks/useWindowSize';
import {useMemo} from 'react';

interface Props {
    row: any,
    reportType: string,
}

const DealerReportsTableRow = observer((props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const renderGridLabel = (id: string, label: string, xs: number, lengthLimit?: number) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={xs}
            lengthLimit={lengthLimit}
        />
    );

    const onViewDeviceClick = () => {
        WebStore.setSelectedSiteId(props.row.siteId, true);
        goToViewDevicePage(props.row.structureId);
    };

    return (
        <DisplayGridContent
            topMargin='0px'
        >
            {renderGridLabel(`${ props.row.device }_user`, props.row.user, largeScreen ? 2.5 : 2.25,
                largeScreen ? 17 : 12)}
            {renderGridLabel(`${ props.row.device }_name`, props.row.name, largeScreen ? 2.5 : 2,
                largeScreen ? 17 : 10)}
            {renderGridLabel(`${ props.row.device }_structure`, props.row.structure, 2, largeScreen ? 12 : 10)}
            {renderGridLabel(`${ props.row.device }_device`, props.row.device, 2.5, largeScreen ? 17 : 14)}
            {renderGridLabel(props.reportType === 'Lost Devices' ? `${ props.row.device }_last_call_in`
                : `${ props.row.device }_max_temp`, props.reportType === 'Lost Devices'
                ? getTimeSince(props.row.lastCallIn) : props.row.maxTemp, largeScreen ? 1.5 : 2, largeScreen ? 12 : 10)}
            <DisplayGridActions
                buttons={[
                    {
                        id: `${ props.row.device }_view_device_button`,
                        onClick: onViewDeviceClick,
                        icon: <Visibility/>,
                        hoverText: Constants.VIEW_DEVICE,
                    },
                ]}
                xs={largeScreen ? 1 : 1.25}
                key={`${ props.row.id }_actions_cell`}
                id={props.row.device}
            />
        </DisplayGridContent>
    );
});

export default DealerReportsTableRow;
