/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Fragment} from 'react';
import {Chip, styled, Typography} from '@mui/material';
import {MarkerData} from '../../../../../types/components/ApiTypes';
import getTestID from '../../../../../helper/functions/getTestID';
import Constants from '../../../../../helper/Constants';
import TrendIcon from '../../../TrendIcon';

const AdvancedRoot = styled('div')({
    width: '300px',
});

const TitleRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '16px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    alignSelf: 'stretch',
    width: '100%',
});

interface Props {
    marker: MarkerData,
}

const getTrendIcon = (trend: string) => (
    <TrendIcon value={trend}/>
);

const DetailedAutomationHubTooltip = (props: Props) => {
    if (props.marker.tooltip) {
        return (
            <Fragment>
                <AdvancedRoot>
                    <TitleRow>
                        <Typography
                            {...getTestID('map_popup_name')}
                            fontSize="20px"
                            fontWeight="bold"
                        >
                            {props.marker.tooltip.name}
                        </Typography>
                        <Chip
                            {...getTestID('map_popup_status')}
                            label={props.marker.tooltip.automationData?.status}
                            color={props.marker.tooltip.automationData?.status === 'Online' ? 'success'
                                : 'error'}
                        />
                    </TitleRow>
                    <Row>
                        <Column>
                            <Row>
                                <Typography fontSize="14px">Ambient Temp: </Typography>
                                <Typography
                                    {...getTestID('map_popup_max_temp')}
                                    fontSize="14px"
                                >
                                    {props.marker.tooltip.automationData?.ambientTemp}
                                </Typography>
                            </Row>
                            <Row>
                                <Typography fontSize="14px">Temp Trend: </Typography>
                                {props.marker.tooltip.automationData?.tempTrend === Constants.NO_READING ? (
                                    <Typography
                                    {...getTestID('map_popup_temp_trending_no_reading')}
                                    fontSize="14px"
                                    >
                                        {props.marker.tooltip.automationData?.tempTrend}
                                    </Typography>
                                ) : ( props.marker.tooltip.automationData ? getTrendIcon(props.marker.tooltip.automationData.tempTrend)
                                    : '')}
                            </Row>
                            <Row>
                                <Typography fontSize="14px">Humidity: </Typography>
                                <Typography
                                    {...getTestID('map_popup_humidity')}
                                    fontSize="14px"
                                >
                                    {props.marker.tooltip.automationData?.humidity}
                                </Typography>
                            </Row>
                            <Row>
                                <Typography fontSize="14px">Humidity Trend: </Typography>
                                {props.marker.tooltip.automationData?.humidityTrend === Constants.NO_READING ? (
                                    <Typography
                                    {...getTestID('map_popup_humidity_trending_no_reading')}
                                    fontSize="14px"
                                    >
                                        {props.marker.tooltip.automationData?.humidityTrend}
                                    </Typography>
                                ) : ( props.marker.tooltip.automationData ? getTrendIcon(props.marker.tooltip.automationData.humidityTrend)
                                    : '')}
                            </Row>
                            <Row>
                                <Typography fontSize="14px">Remotes: </Typography>
                                <Typography
                                    {...getTestID('map_popup_remotes_count')}
                                    fontSize="14px"
                                >
                                    {props.marker.tooltip.automationData?.remotesCount}
                                </Typography>
                            </Row>
                            <Row>
                                <Typography fontSize="14px">Automation Hub: </Typography>
                                <Typography
                                    {...getTestID('map_popup_serial')}
                                    fontSize="14px"
                                >
                                    {props.marker.tooltip.automationData?.serial}
                                </Typography>
                            </Row>
                        </Column>
                    </Row>
                </AdvancedRoot>
            </Fragment>
        );
    }
    return null;
};

export default DetailedAutomationHubTooltip;
