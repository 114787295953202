/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import BaseModal from '../common/modal/BaseModal';
import {useState} from 'react';
import Constants from '../../helper/Constants';
import BaseWizard from '../common/wizard/BaseWizard';
import TermsOfService from './TermsOfService';
import isEmailAndPasswordValid from '../../helper/complexValidators/isEmailAndPasswordValid';
import isPersonalDetailsFormValid from '../../helper/complexValidators/isPersonalDetailsFormValid';
import isBillingAddressFormValid from '../../helper/complexValidators/isBillingAddressFormValid';
import CreateNewUserPage from './RegisterNewUserForm';
import postUser from '../../controllers/apiCalls/post/postUser';
import {useSnackbar} from 'notistack';
import isEmailExists from '../../helper/functions/isEmailExists';
import WebStore from '../../stores/WebStore';

const Container = styled('div')({
    width: '1000px',
    padding: '16px',
});

const steps = [
    Constants.TERMS_OF_SERVICE,
    Constants.CREATE_NEW_USER,
];

interface Props {
    open: boolean,
    close: () => void,
    tab: string,
}

const RegistrationModal = (props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ activeStep, setActiveStep ] = useState(0);

    //step 2 - new user
    const [ firstName, setFirstName ] = useState('');
    const [ firstNameError, setFirstNameError ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ lastNameError, setLastNameError ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ emailError, setEmailError ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ phoneNumberError, setPhoneNumberError ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ passwordError, setPasswordError ] = useState('');
    const [ passwordConfirmation, setPasswordConfirmation ] = useState('');
    const [ passwordConfirmationError, setPasswordConfirmationError ] = useState('');
    const [ country, setCountry ] = useState('');
    const [ countryError, setCountryError ] = useState('');
    const [ province, setProvince ] = useState('');
    const [ provinceError, setProvinceError ] = useState('');
    const [ city, setCity ] = useState('');
    const [ cityError, setCityError ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ addressError, setAddressError ] = useState('');
    const [ postalCode, setPostalCode ] = useState('');
    const [ postalCodeError, setPostalCodeError ] = useState('');
    const [ error, setError ] = useState('');
    const [ dealer, setDealer ] = useState('');
    const [ dealerError, setDealerError ] = useState('');

    //step 2 - billing address
    const [ billingCountry, setBillingCountry ] = useState('');
    const [ billingCountryError, setBillingCountryError ] = useState('');
    const [ billingProvince, setBillingProvince ] = useState('');
    const [ billingProvinceError, setBillingProvinceError ] = useState('');
    const [ billingCity, setBillingCity ] = useState('');
    const [ billingCityError, setBillingCityError ] = useState('');
    const [ billingAddress, setBillingAddress ] = useState('');
    const [ billingAddressError, setBillingAddressError ] = useState('');
    const [ billingPostalCode, setBillingPostalCode ] = useState('');
    const [ billingPostalCodeError, setBillingPostalCodeError ] = useState('');
    const [ useForBillingAddress, setUseForBillingAddress ] = useState(true);

    const isValid = () => {
        setError('');
        setDealerError('');
        let valid = true;
        if (password !== passwordConfirmation) {
            setError('Password fields do not match.');
            valid = false;
        }

        const emailAndPasswordValid = isEmailAndPasswordValid({
                value: email,
                setError: setEmailError,
            }, {
                value: password,
                setError: setPasswordError,
            },
            {
                value: passwordConfirmation,
                setError: setPasswordConfirmationError,
            });

        const personalDetailsFormValid = isPersonalDetailsFormValid({
            value: firstName,
            setError: setFirstNameError,
        }, {
            value: lastName,
            setError: setLastNameError,
        }, {
            value: phoneNumber,
            setError: setPhoneNumberError,
        }, {
            value: email,
            setError: setEmailError,
        });

        const addressValid = isBillingAddressFormValid({
            value: postalCode,
            setError: setPostalCodeError,
        }, {
            value: address,
            setError: setAddressError,
        }, {
            value: city,
            setError: setCityError,
        }, {
            value: country,
            setError: setCountryError,
        }, {
            value: province,
            setError: setProvinceError,
        });

        let billingAddressValid = addressValid;

        if (!useForBillingAddress) {
            billingAddressValid = isBillingAddressFormValid({
                value: billingPostalCode,
                setError: setBillingPostalCodeError,
            }, {
                value: billingAddress,
                setError: setBillingAddressError,
            }, {
                value: billingCity,
                setError: setBillingCityError,
            }, {
                value: billingCountry,
                setError: setBillingCountryError,
            }, {
                value: billingProvince,
                setError: setBillingProvinceError,
            });
        }

        if (!emailAndPasswordValid || !personalDetailsFormValid || !addressValid ||
            (!useForBillingAddress && !billingAddressValid)) {
            valid = false;
        }

        if (WebStore.selectedView !== Constants.DEALER && dealer === '') {
            setDealerError('Please select a dealer');
            valid = false;
        }
        return valid;
    };

    const validateEmailAndCreateUser = async (body : any) => {
        await isEmailExists(enqueueSnackbar, email).then(r => {
            if (r !== '') {
                setEmailError(r);
            } else {
                postUser(enqueueSnackbar, body, props.tab);
                props.close();
            }
        });
    };

    const submit = () => {
        setError('');
        if (isValid()) {
            const body = {
                firstName,
                lastName,
                email,
                mobileNumber: phoneNumber,
                password,
                verifyPassword: passwordConfirmation,
                countryId: Number(country),
                regionId: Number(province),
                city,
                address,
                postalCode,
                dealerId: Number(dealer),
                billingAddress: useForBillingAddress ? address : billingAddress,
                billingCity: useForBillingAddress ? city : billingCity,
                billingPostalCode: useForBillingAddress ? postalCode : billingPostalCode,
                billingCountryId: Number(useForBillingAddress ? country : billingCountry),
                billingRegionId: Number(useForBillingAddress ? province : billingProvince),
            };
            validateEmailAndCreateUser(body);
        }
    };

    const handleNext = () => {
        if (activeStep === 1 && isValid()) {
            submit();
        }
        else if (activeStep === 0) {
            setActiveStep(activeStep + 1);
        }
    };

    const renderPaper = () => {
        switch (steps[activeStep]) {
            case Constants.TERMS_OF_SERVICE:
                return <TermsOfService/>;
            case Constants.CREATE_NEW_USER:
                return (
                    <CreateNewUserPage
                        isCustomer={WebStore.selectedView !== Constants.DEALER}
                        firstName={{
                            value: firstName,
                            setValue: setFirstName,
                            error: firstNameError,
                            setError: setFirstNameError,
                        }}
                        lastName={{
                            value: lastName,
                            setValue: setLastName,
                            error: lastNameError,
                            setError: setLastNameError,
                        }}
                        email={{
                            value: email,
                            setValue: setEmail,
                            error: emailError,
                            setError: setEmailError,
                        }}
                        phoneNumber={{
                            value: phoneNumber,
                            setValue: setPhoneNumber,
                            error: phoneNumberError,
                            setError: setPhoneNumberError,
                        }}
                        password={{
                            value: password,
                            setValue: setPassword,
                            error: passwordError,
                            setError: setPasswordError,
                        }}
                        passwordConfirmation={{
                            value: passwordConfirmation,
                            setValue: setPasswordConfirmation,
                            error: passwordConfirmationError,
                            setError: setPasswordConfirmationError,
                        }}
                        country={{
                            value: country,
                            setValue: setCountry,
                            error: countryError,
                            setError: setCountryError,
                        }}
                        province={{
                            value: province,
                            setValue: setProvince,
                            error: provinceError,
                            setError: setProvinceError,
                        }}
                        city={{
                            value: city,
                            setValue: setCity,
                            error: cityError,
                            setError: setCityError,
                        }}
                        address={{
                            value: address,
                            setValue: setAddress,
                            error: addressError,
                            setError: setAddressError,
                        }}
                        postalCode={{
                            value: postalCode,
                            setValue: setPostalCode,
                            error: postalCodeError,
                            setError: setPostalCodeError,
                        }}
                        useForBillingAddress={useForBillingAddress}
                        setUseForBillingAddress={setUseForBillingAddress}
                        billingPostalCode={{
                            value: billingPostalCode,
                            setValue: setBillingPostalCode,
                            error: billingPostalCodeError,
                            setError: setBillingPostalCodeError,
                        }}
                        billingAddress={{
                            value: billingAddress,
                            setValue: setBillingAddress,
                            error: billingAddressError,
                            setError: setBillingAddressError,
                        }}
                        billingCity={{
                            value: billingCity,
                            setValue: setBillingCity,
                            error: billingCityError,
                            setError: setBillingCityError,
                        }}
                        billingProvince={{
                            value: billingProvince,
                            setValue: setBillingProvince,
                            error: billingProvinceError,
                            setError: setBillingProvinceError,
                        }}
                        billingCountry={{
                            value: billingCountry,
                            setValue: setBillingCountry,
                            error: billingCountryError,
                            setError: setBillingCountryError,
                        }}
                        dealer={{
                            value: dealer,
                            setValue: setDealer,
                            error: dealerError,
                            setError: setDealerError,
                        }}
                    />
                );
            default:
                return (
                    <div/>
                );
        }
    };

    return (
        <BaseModal
            id='new_user_modal'
            open
            close={props.close}
            title={Constants.REGISTRATION}
            hideButtons
        >
            <Container>
                <BaseWizard
                    id="registration_wizard"
                    close={props.close}
                    submit={submit}
                    steps={steps}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    handleNext={handleNext}
                >
                    {renderPaper()}
                    {error !== '' && (
                        <Typography color="error">{error}</Typography>
                    )}
                </BaseWizard>
            </Container>
        </BaseModal>
    );
};

export default RegistrationModal;
