/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import Constants from '../../../../helper/Constants';
import useWindowSize from '../../../../helper/hooks/useWindowSize';
import {useMemo} from 'react';
import KeyValueTypography from '../../../common/typography/KeyValueTypography';
import getGrainType from '../../../../helper/functions/getGrainType';
import {observer} from 'mobx-react-lite';
import getBinFanStatus from '../../../../helper/functions/getBinFanStatus';
import {Bin} from '../../../../types/components/ApiTypes';
import getTempUnit from '../../../../helper/functions/getTempUnit';
import BinsenseStore from '../../../../stores/BinsenseStore';
import TrendIcon from '../../../common/TrendIcon';
import getNumberLabel from '../../../../helper/functions/getNumberLabel';
import { displayTemp } from '../../../../helper/functions/functions';

const Row = styled('div')({
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const TitleRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

interface Props {
    bin: Bin,
}

const StorageGridItemBody = observer((props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);

    const dataFontSize = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[4] ? 14 : 13, [ windowWidth ]);
    const tempUnit = useMemo(getTempUnit, [ BinsenseStore.userData.settings.preferences ]);

    const getTrendIcon = (trend: string) => (
        <TrendIcon value={trend}/>
    );

    const getTypographyPair = (id: string, label: string, value: string) => (
        <KeyValueTypography
            id={id}
            label={label}
            value={value}
            fontSize={dataFontSize}
            flipColors
        />
    );

    return (
        <div style={{ width: '100%' }}>
            <Row style={{ marginTop: '8px' }}>
                {getTypographyPair(`${ props.bin.name }_max_moisture`, 'Max Moisture:',
                    props.bin.status?.maxMoisture ? getNumberLabel(props.bin.status.maxMoisture, '%') : Constants.NA)}
                {getTypographyPair(`${ props.bin.name }_max_temp`, 'Max Temp:', props.bin.status?.maxTemperature
                    ? `${ displayTemp( tempUnit === 'F', props.bin.status.maxTemperature) } ${ tempUnit }` : Constants.NA)}
            </Row>
            <Row>
                <TitleRow>
                    <Typography
                        fontSize={dataFontSize}
                        paddingRight="8px"
                    >
                        Moist. Trend:
                    </Typography>
                    {props.bin.status?.moistureTrend ? getTrendIcon(props.bin.status.moistureTrend) : Constants.NA}
                </TitleRow>
                <TitleRow>
                    <Typography
                        fontSize={dataFontSize}
                        paddingRight="8px"
                    >
                        Temp. Trend:
                    </Typography>
                    {props.bin.status?.temperatureTrend ? getTrendIcon(props.bin.status.temperatureTrend) : Constants.NA}
                </TitleRow>
            </Row>
            <Row>
                {getTypographyPair(`${ props.bin.name }_fan_status`, 'Fan(s):',
                    getBinFanStatus(props.bin.hardware?.fans ? props.bin.hardware.fans : []))}
                {getTypographyPair(`${ props.bin.name }_fill_level`, 'Level:',
                // eslint-disable-next-line
                    props.bin.status?.percentFull || props.bin.status?.percentFull === 0 ? getNumberLabel(props.bin.status.percentFull, '%') : Constants.NA)}
            </Row>
            {getTypographyPair(`${ props.bin.name }_commodity`, 'Commodity:',
                props.bin.settings?.productId ? getGrainType(props.bin.settings.productId) : Constants.NA)}
        </div>
    );
});

export default StorageGridItemBody;
