/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Button, styled, Typography} from '@mui/material';
import getTestID from '../../helper/functions/getTestID';
import {Dispatch, SetStateAction} from 'react';

interface Props {
    setModalOpen: Dispatch<SetStateAction<boolean>>
}

const Root =  styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const LoginPaperRegister = (props: Props) => (
    <Root>
        <Typography>
            Don't have an account yet?
        </Typography>
        <Button
            {...getTestID('registration_button')}
            color="primary"
            onClick={() => props.setModalOpen(true)}
        >
            Create New Account
        </Button>
    </Root>
);

export default LoginPaperRegister;