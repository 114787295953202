/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {useState} from 'react';
import Constants from '../../../../helper/Constants';
import {DistanceUnit, GrainBagData, StructIdSerials} from '../../../../types/components/ApiTypes';
import StructureSetupModal from '../binSetupModal/StructureSetupModal';
import BagDetailsForm from './BagDetailsForm';
import getAllStructNames from '../../../../helper/functions/getAllStructNames';

interface Props {
    open: boolean,
    close: () => void,
    onSubmit: (grainBagData: GrainBagData) => void,
    grainBagData?: GrainBagData,
    masterSerials: string[],
    automationMasterSerials: string[],
    structureId?: number,
    disableAutomation: boolean,
    markerStructNames?: string[],
    markerSerials?: StructIdSerials[],
    siteSerials?: StructIdSerials[],
    siteId?: number,
}

const BagSetupModal = (props: Props) => {
    const [ name, setName ] = useState(props.grainBagData?.name
        ? props.grainBagData.name : '');
    const [ nameError, setNameError ] = useState('');
    const [ length, setLength ] = useState(props.grainBagData?.length
        ? props.grainBagData.length.toString() : '');
    const [ lengthError, setLengthError ] = useState('');
    const [ width, setWidth ] = useState(props.grainBagData?.width
        ? props.grainBagData.width.toString() : '');
    const [ widthError, setWidthError ] = useState('');
    const [ rotation, setRotation ] = useState(props.grainBagData?.rotation
        ? props.grainBagData.rotation.toString() : '0');
    const [ rotationError, setRotationError ] = useState('');
    const [ quantity, setQuantity ] = useState(props.grainBagData?.quantity
        ? props.grainBagData.quantity.toString() : '');
    const [ quantityError, setQuantityError ] = useState('');
    const [ powerSource, setPowerSource ] = useState(props.grainBagData?.powerSource
        ? props.grainBagData.powerSource : '');
    const [ powerSourceError, setPowerSourceError ] = useState('');
    const structNames = getAllStructNames(props.markerStructNames, props.siteId);
    const nameOnPageLoad = props.grainBagData?.name ? props.grainBagData.name : '';

    const isValid = () => name.trim() !== '' && length !== '' && width !== '' && rotation !== '' && quantity !== '' &&
        powerSource !== '' &&
        !((nameOnPageLoad === '' || nameOnPageLoad.trim().toLowerCase() !== name.trim().toLowerCase()) &&
            structNames && structNames.some(item => item.trim().toLowerCase() === name.trim().toLowerCase()));

    const resetErrors = () => {
        if (name.trim() !== '') {
            setNameError('');
        }
        if (length !== '') {
            setLengthError('');
        }
        if (width !== '') {
            setWidthError('');
        }
        if (rotation !== '') {
            setRotationError('');
        }
        if (quantity !== '') {
            setQuantityError('');
        }
        if (powerSource !== '') {
            setPowerSourceError('');
        }
    };

    const setErrors = () => {
        resetErrors();
        if (name.trim() === '') {
            setNameError('Please enter a name for this grain bag');
        }
        if ((nameOnPageLoad === '' || nameOnPageLoad.trim().toLowerCase() !== name.trim().toLowerCase()) &&
            structNames && structNames.some(item => item.trim().toLowerCase() === name.trim().toLowerCase())) {
            setNameError('This name already exists');
        }
        if (length === '') {
            setLengthError('Please enter a length for this grain bag');
        }
        if (width === '') {
            setWidthError('Please enter a width for this grain bag');
        }
        if (rotation === '') {
            setRotationError('Please enter a rotation for this grain bag');
        }
        if (quantity === '') {
            setQuantityError('Please enter a quantity for this grain bag');
        }
        if (powerSource === '') {
            setPowerSourceError('Please enter a power source for this grain bag.');
        }
    };

    const submit = (device: string, deviceType: string, sn: string[], associatedMaster: string) => {
        if (isValid()) {
            props.onSubmit({
                name,
                length: Number(length),
                width: Number(width),
                rotation: Number(rotation),
                unit: DistanceUnit.feet,
                quantity: Number(quantity),
                device,
                deviceType: deviceType.toLowerCase(),
                sn,
                associatedMaster,
                powerSource,
            });
        }
        else {
            setErrors();
        }
    };

    return (
        <StructureSetupModal
            id='bag_setup'
            modalTitle={Constants.BAG_SETUP}
            firstStepTitle={Constants.BAG_DETAILS}
            isFirstStepValid={isValid}
            setFirstStepErrors={setErrors}
            resetFirstStepErrors={resetErrors}
            onSubmit={submit}
            close={props.close}
            data={props.grainBagData}
            masterSerials={props.masterSerials}
            automationMasterSerials={props.automationMasterSerials}
            structureId={props.structureId}
            disableAutomation={props.disableAutomation}
            markerSerials={props.markerSerials}
            siteSerials={props.siteSerials}
        >
            <BagDetailsForm
                name={{
                    value: name,
                    setValue: setName,
                    error: nameError,
                    setError: setNameError,
                }}
                length={{
                    value: length,
                    setValue: setLength,
                    error: lengthError,
                    setError: setLengthError,
                }}
                width={{
                    value: width,
                    setValue: setWidth,
                    error: widthError,
                    setError: setWidthError,
                }}
                rotation={{
                    value: rotation,
                    setValue: setRotation,
                    error: rotationError,
                    setError: setRotationError,
                }}
                quantity={{
                    value: quantity,
                    setValue: setQuantity,
                    error: quantityError,
                    setError: setQuantityError,
                }}
                powerSource={{
                    value: powerSource,
                    setValue: setPowerSource,
                    error: powerSourceError,
                    setError: setPowerSourceError,
                }}
            />
        </StructureSetupModal>
    );
};

export default BagSetupModal;
