/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Divider, styled, Typography} from '@mui/material';
import DisplayGridItem from '../../common/displayTable/DisplayGridItem';
import getTestID from '../../../helper/functions/getTestID';

interface Props {
    id: string,
    label: string,
    icon: object,
    value: number | string,
    units: string,
    xs: number,
    valueFontSize: number,
    noDivider?: boolean,
    children?: object | object[],
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});

const DashboardWeatherItem = (props: Props) => {
    const Column = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        paddingLeft: !props.noDivider ? '16px' : undefined,
    });

    return (
        <DisplayGridItem xs={props.xs}>
            {!props.noDivider && (
                <Divider
                    orientation="vertical"
                    flexItem
                    variant="middle"
                />
            )}
            <Column>
                <Row>
                    <Typography
                        {...getTestID(props.id)}
                        fontSize={props.valueFontSize}
                        fontWeight="bold"
                        marginRight='8px'
                        color='primary'
                    >
                        {props.value}
                    </Typography>
                    <Typography
                        {...getTestID(`${ props.id }_unit`)}
                        fontSize={props.valueFontSize - 16}
                        fontWeight="bold"
                        color='primary'
                    >
                        {props.units}
                    </Typography>
                </Row>
                <Row>
                    <Typography
                        {...getTestID(`${ props.id }_label`)}
                        fontWeight="bold"
                    >
                        {props.label}
                    </Typography>
                    {props.icon}
                </Row>
            </Column>
        </DisplayGridItem>
    );
};

export default DashboardWeatherItem;
