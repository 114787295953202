export default {
    siteId: -1,
    cableCommunication: {
        title: '',
        rows: [],
        columns: [],
    },
    deviceCommunication: {
        title: '',
        rows: [],
        columns: [],
    },
};
