/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper, styled} from '@mui/material';
import KeyValueTypography from '../../common/typography/KeyValueTypography';
import Constants from '../../../helper/Constants';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import SelectFieldInput from '../../common/input/SelectFieldInput';
import {Dispatch, SetStateAction, useMemo, useState} from 'react';
import BinsenseStore from '../../../stores/BinsenseStore';
import SearchUserFilter from '../myCustomers/SearchUserFilter';

interface Props {
    user: {
        value: string | null,
        setValue: Dispatch<SetStateAction<string | null>>,
        error: string,
        setError: Dispatch<SetStateAction<string>>,
    },
    site: InputFieldProps,
}

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
});

const SelectFieldDiv = styled('div')({
    marginTop: '16px',
    width: '100%',
});

const SelectUserPaper = (props: Props) => {
    const [ userInput, setUserInput ] = useState(props.user.value ? props.user.value : '');

    const siteOptions = useMemo(() => {
        if (props.user.value !== null) {
            const userIndex = BinsenseStore.dealerUserData.customers
                .findIndex(user => user.account?.accountEmail === props.user.value);
            return userIndex !== -1 ? BinsenseStore.dealerUserData.customers[userIndex].sites.map(site => ({
                id: `${ site.id }_option`,
                title: site.name,
                value: site.id.toString(),
            })) : [];
        }
        return [];
    }, [ BinsenseStore.dealerUserData, props.user.value ]);

    const userChange = (newValue: any) => {
        props.user.setValue(newValue);
        // @ts-ignore
        props.site.setValue('');
    };

    return (
        <Paper
            style={{
                width: '95%',
            }}
        >
            <Root>
                <KeyValueTypography
                    label="STEP 1:"
                    value={Constants.SELECT_SITE}
                />
                <SelectFieldDiv>
                    <SearchUserFilter
                        id="add_new_select_user"
                        user={{
                            value: props.user.value,
                            setValue: userChange,
                        }}
                        userInput={{
                            value: userInput,
                            setValue: setUserInput,
                        }}
                    />
                </SelectFieldDiv>
                <SelectFieldDiv>
                    <SelectFieldInput
                        id="select_site_input"
                        label={Constants.SELECT_SITE}
                        field={props.site}
                        options={siteOptions}
                    />
                </SelectFieldDiv>
            </Root>
        </Paper>
    );
};

export default SelectUserPaper;
