/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import {styled, Typography} from '@mui/material';
import Colors from '../../../helper/Colors';
import goToViewDevicePage from '../../../helper/functions/goToViewDevicePage';

const HeaderWrapper = styled('div')({
    flex: 1,
    height: '100%',
    paddingTop: '8px',
    paddingBottom: '8px',
});

const BinColumn = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '10%',
    paddingTop: '8px',
    paddingBottom: '8px',
});

const TableRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '8px',
    paddingRight: '8px',
    height: 80,
});

interface Props {
    row: any,
    index: number,
}

const StatusCommunicationSummaryTableRow = observer((props: Props) => {
    const FONT_SIZE = 12;

    const onClick = () => {
        if (props.row.id > -1) {
            goToViewDevicePage(props.row.id);
        }
    };

    return (
        <TableRow>
            <BinColumn>
                <a
                    onClick={() => onClick()}
                    style={{cursor: 'pointer'}}
                >
                    <Typography
                        fontWeight="bold"
                        fontSize={FONT_SIZE}
                    >
                        {props.row.header}
                    </Typography>
                </a>
            </BinColumn>
            {props.row.values.map((val: any, i: number) => (
                <HeaderWrapper
                    key={`${ props.index }_${ i }`}
                    style={{ backgroundColor: val.status === 'BAD' ? Colors.tableCellRed : Colors.tableCellGreen }}
                />
            ))}
        </TableRow>
    );
});

export default StatusCommunicationSummaryTableRow;