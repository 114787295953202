/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Site} from '../../../types/components/ApiTypes';
import SettingsPage from './SettingsPage';
import AssociationsPage from '../../dealer/myCustomers/associationsPage/AssociationsPage';
import {styled, Tab, Tabs} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';
import Constants from '../../../helper/Constants';
import {useState} from 'react';

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

interface Props {
    site: Site,
    showDevice: boolean,
}

const DeviceSettingsMainPage = (props: Props) => {
    const [ tab, setTab ] = useState(Constants.SETTINGS);

    const handleChange = (event: any, newValue: string) => {
        setTab(newValue);
    };

    const renderContent = () => {
        switch (tab) {
            case Constants.SETTINGS:
                return (
                    <SettingsPage
                        site={props.site}
                        showDevice={props.showDevice}
                    />
                    );
            case Constants.ASSOCIATIONS:
                return (<AssociationsPage site={props.site}/>);
            default:
                return (<div/>);
        }
    };

    return (
        <Root>
            <Tabs
                value={tab}
                onChange={handleChange}
            >
                <Tab
                    {...getTestID('settings_tab')}
                    label={Constants.SETTINGS}
                    value={Constants.SETTINGS}
                />
                <Tab
                    {...getTestID('associations_tab')}
                    label={Constants.ASSOCIATIONS}
                    value={Constants.ASSOCIATIONS}
                />
            </Tabs>
            {renderContent()}
        </Root>
    );
};

export default DeviceSettingsMainPage;
