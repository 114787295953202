/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Avatar, styled, Typography} from '@mui/material';
import getTestID from '../../helper/functions/getTestID';
import {observer} from 'mobx-react-lite';
import {useMemo} from 'react';
import BinsenseStore from '../../stores/BinsenseStore';
import WebStore from '../../stores/WebStore';
import Constants from '../../helper/Constants';

const Root = styled('div')(({theme}) => ({
    // @ts-ignore
    backgroundColor: theme.palette.primary.drawerUserBackground,
    borderRadius: '10px',
    margin: '8px',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '16px',
    paddingRight: '16px',
    marginBottom: '24px',
    fontSize: '12px',
}));

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
});

const DrawerUserInformation = observer(() => {
    const account = useMemo(() => {
        if (WebStore.selectedView === Constants.CUSTOMER) {
            return BinsenseStore.userData.account ? BinsenseStore.userData.account : null;
        }
        return BinsenseStore.dealerUserData.account ? BinsenseStore.dealerUserData.account : null;
    }, [ WebStore.selectedView, BinsenseStore.userData.account, BinsenseStore.dealerUserData.account ]);

    const name = useMemo(() => account?.firstName && account.lastName ? `${ account.lastName }, ${ account.firstName }`
        : 'anon',[ account ]);

    const initials = useMemo(() => account?.firstName && account.lastName
        ? `${ account.firstName.charAt(0) }${ account.lastName.charAt(0) }` : '', [ account ]);

    return (
        <Root>
            <Row>
                <Avatar>{initials}</Avatar>
                <Column>
                    <Typography
                        {...getTestID('admin_name')}
                        sx={{ fontWeight: 'bold' }}
                    >
                        {name}
                    </Typography>
                    <Typography>
                        Admin
                    </Typography>
                </Column>
            </Row>
        </Root>
    );
});

export default DrawerUserInformation;
