/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {action, makeObservable, observable} from 'mobx';
import Constants from '../helper/Constants';
import Colors from '../helper/Colors';
import { cloneDeep } from 'lodash';
import presetUserData from '../helper/temp/presetUserData';
import { UserData } from '../types/components/ApiTypes';

class WebStore {
    selectedDrawer = '';

    selectedDrawerAfterConfirmation = '';

    isLoseChangesConfirmationOpen = false;

    selectedView = '';

    currentPage = Constants.MAIN;

    pageQueue = [] as string[];

    activeStep = 0;

    storageMode = Constants.GRID;

    selectedSiteId = -1;

    pageIds = [ -1, -1, -1 ];

    pageData: any[] = [ null, null, null ];

    viewDeviceId = -1;

    createSiteNewUserId = -1;

    binSidePNG = '';

    binTopPNG = '';

    binSideSVG = '';

    binTopSVG = '';

    newUser = false;

    lightMode = true;

    editingMap = false;

    overDuePayment = false;

    myCustomersSearch = '';

    associationsChanged = false;

    utcOffset = (new Date()).getTimezoneOffset();

    selectedCustomer: UserData = cloneDeep(presetUserData);

    countClicksOnSameSensorId: number = 0;

    previousSensorId: string = '0';

    constructor() {
        makeObservable(this, {
            selectedDrawer: observable,
            setSelectedDrawer: action,
            selectedDrawerAfterConfirmation: observable,
            setSelectedDrawerAfterConfirmation: action,
            isLoseChangesConfirmationOpen: observable,
            setIsLoseChangesConfirmationOpen: action,
            newUser: observable,
            setNewUser: action,
            lightMode: observable,
            setLightMode: action,
            selectedView: observable,
            setSelectedView: action,
            currentPage: observable,
            setCurrentPage: action,
            pageQueue: observable,
            setPageQueue: action,
            goToNewPage: action,
            goToPreviousPage: action,
            activeStep: observable,
            setActiveStep: action,
            storageMode: observable,
            setStorageMode: action,
            selectedSiteId: observable,
            setSelectedSiteId: action,
            pageIds: observable,
            setPageIds: action,
            pageData: observable,
            setPageData: action,
            viewDeviceId: observable,
            setViewDeviceId: action,
            createSiteNewUserId: observable,
            setCreateSiteNewUserId: action,
            binSidePNG: observable,
            setBinSidePNG: action,
            binTopPNG: observable,
            setBinTopPNG: action,
            binSideSVG: observable,
            setBinSideSVG: action,
            binTopSVG: observable,
            setBinTopSVG: action,
            presetWebStore: action,
            editingMap: observable,
            setEditingMap: action,
            overDuePayment: observable,
            setOverDuePayment: action,
            myCustomersSearch: observable,
            setMyCustomersSearch: action,
            associationsChanged: observable,
            setAssociationsChanged: action,
            selectedCustomer: observable,
            setSelectedCustomer: action,
            countClicksOnSameSensorId: observable,
            setCountClicksOnSameSensorId: action,
            previousSensorId: observable,
            setPreviousSensorId: action,
        });
        this.initializeFromAsyncStorage();
    }

    setSelectedDrawer = (newDrawer: string, sync: boolean) => {
        this.selectedDrawer = newDrawer;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SELECTED_DRAWER, newDrawer);
        }
    };

    setSelectedDrawerAfterConfirmation = (newDrawer: string) => {
        this.selectedDrawerAfterConfirmation = newDrawer;
    };

    setIsLoseChangesConfirmationOpen = (value: boolean) => {
        this.isLoseChangesConfirmationOpen = value;
    }

    setSelectedView = (newView: string, sync: boolean) => {
        if (newView === Constants.CUSTOMER) {
            this.setSelectedDrawer(Constants.DASHBOARD, sync);
        } else if (newView === Constants.DEALER) {
            this.setSelectedDrawer(Constants.MY_CUSTOMERS, sync);
        }
        this.selectedView = newView;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SELECTED_VIEW, newView);
        }
    }

    setCurrentPage = (newPage: string, sync: boolean) => {
        this.currentPage = newPage;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_CURRENT_PAGE, newPage);
        }
    }

    setPageQueue = (newQueue: string[], sync: boolean) => {
        this.pageQueue = newQueue;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_PAGE_QUEUE, JSON.stringify(newQueue));
        }
    }

    goToPreviousPage = () => {
        if (this.pageQueue.length > 0) {
            const queue = JSON.parse(JSON.stringify(this.pageQueue));
            this.setCurrentPage(String(queue.pop()), true);
            this.setPageQueue(queue, true);
        }
    }

    goToNewPage = (newPage: string) => {
        const queue = JSON.parse(JSON.stringify(this.pageQueue));
        queue.push(this.currentPage);
        this.setPageQueue(queue, true);
        this.setCurrentPage(newPage, true);
    }

    setActiveStep = (newStep: number) => {
        this.activeStep = newStep;
    }

    setStorageMode = (storageMode: string, sync: boolean) => {
        this.storageMode = storageMode;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_STORAGE_MODE, storageMode);
        }
    }

    setSelectedSiteId = (selectedSiteId: number, sync: boolean) => {
        this.selectedSiteId = selectedSiteId;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SELECTED_SITE, selectedSiteId.toString());
        }
    }

    setCreateSiteNewUserId = (createSiteNewUserId: number) => {
        this.createSiteNewUserId = createSiteNewUserId;
    }

    setPageIds = (ids: number[], sync: boolean) => {
        this.pageIds = ids;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_PAGE_IDS, JSON.stringify(ids));
        }
    }

    setPageData = (data: any[], sync: boolean) => {
        this.pageData = data;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_PAGE_DATA, JSON.stringify(data));
        }
    }

    setViewDeviceId = (id: number, sync: boolean) => {
        this.viewDeviceId = id;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_VIEW_DEVICE_ID, id.toString());
        }
    }

    setBinSidePNG = (binSidePNG: string) => {
        this.binSidePNG = binSidePNG;
    }

    setBinTopPNG = (binTopPNG: string) => {
        this.binTopPNG = binTopPNG;
    }

    setBinSideSVG = (binSideSVG: string) => {
        this.binSideSVG = binSideSVG;
    }

    setBinTopSVG = (binTopSVG: string) => {
        this.binTopSVG = binTopSVG;
    }

    setNewUser = (value: boolean) => {
        this.newUser = value;
    }

    setEditingMap = (value: boolean) => {
        this.editingMap = value;
    }

    setOverDuePayment = (value: boolean) => {
        this.overDuePayment = value;
    }

    setMyCustomersSearch = (value: string, sync: boolean) => {
        this.myCustomersSearch = value;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_MY_CUSTOMERS_SEARCH, value);
        }
    }

    setLightMode = () => {
        this.lightMode = !this.lightMode;
        if (this.lightMode) {
            document.body.style.backgroundColor = Colors.background;
        } else {
            document.body.style.backgroundColor = Colors.backgroundDark;
        }
        this.syncWithAsyncStorage(Constants.STORE_KEY_LIGHT_MODE, this.lightMode.toString());
    }

    setInitialLightMode = (flag: boolean) => {
        this.lightMode = flag;
        if (this.lightMode) {
            document.body.style.backgroundColor = Colors.background;
        } else {
            document.body.style.backgroundColor = Colors.backgroundDark;
        }
    }

    setAssociationsChanged = (flag: boolean) => {
        this.associationsChanged = flag;
    }

    setSelectedCustomer = (customerData: UserData, sync: boolean) => {
        this.selectedCustomer = customerData;
        if (sync) {
            this.syncWithAsyncStorage(Constants.STORE_KEY_SELECTED_CUSTOMER, JSON.stringify(customerData));
        }
    };

    setCountClicksOnSameSensorId = (value: number) => {
        this.countClicksOnSameSensorId = value;
    }

    setPreviousSensorId = (value: string) => {
        this.previousSensorId = value;
    }

    presetWebStore = () => {
        this.setSelectedView('', true);
        this.setSelectedSiteId(-1, true);
        this.setCreateSiteNewUserId(-1);
        this.setPageIds([ -1, -1, -1 ], true);
        this.setPageData([ null, null, null ], true);
        this.setCurrentPage(Constants.MAIN, true);
        this.setPageQueue([], true);
        this.setStorageMode(Constants.GRID, true);
        this.setSelectedDrawer('', true);
        this.setOverDuePayment(false);
        this.setMyCustomersSearch('', true);
        this.setViewDeviceId(-1, true);
        this.setActiveStep(0);
        this.setEditingMap(false);
        this.setAssociationsChanged(false);
        this.setSelectedCustomer(cloneDeep(presetUserData), true);
    }

    syncWithAsyncStorage = (key: string, value: string ) => {
        global.localStorage.setItem(key, JSON.stringify(value));
    }

    initializeFromAsyncStorage = () => {
        const lightModeResult = global.localStorage.getItem(Constants.STORE_KEY_LIGHT_MODE);
        if (lightModeResult !== null) {
            this.setInitialLightMode(JSON.parse(lightModeResult) === 'true');
        }
        const selectedViewResult = global.localStorage.getItem(Constants.STORE_KEY_SELECTED_VIEW);
        if (selectedViewResult !== null) {
            this.setSelectedView(JSON.parse(selectedViewResult), false);
        }
        const selectedSiteResult = global.localStorage.getItem(Constants.STORE_KEY_SELECTED_SITE);
        if (selectedSiteResult !== null) {
            this.setSelectedSiteId(Number(JSON.parse(selectedSiteResult)), false);
        }
        const selectedDrawerResult = global.localStorage.getItem(Constants.STORE_KEY_SELECTED_DRAWER);
        if (selectedDrawerResult !== null) {
            this.setSelectedDrawer(JSON.parse(selectedDrawerResult), false);
        }
        const storageModeResult = global.localStorage.getItem(Constants.STORE_KEY_STORAGE_MODE);
        if (storageModeResult !== null) {
            this.setStorageMode(JSON.parse(storageModeResult), false);
        }
        const viewDeviceIdResult = global.localStorage.getItem(Constants.STORE_KEY_VIEW_DEVICE_ID);
        if (viewDeviceIdResult !== null) {
            this.setViewDeviceId(Number(JSON.parse(viewDeviceIdResult)), false);
        }
        const currentPageResult = global.localStorage.getItem(Constants.STORE_KEY_CURRENT_PAGE);
        if (currentPageResult !== null) {
            this.setCurrentPage(JSON.parse(currentPageResult), false);
        }
        const pageQueueResult = global.localStorage.getItem(Constants.STORE_KEY_PAGE_QUEUE);
        if (pageQueueResult !== null) {
            this.setPageQueue(JSON.parse(JSON.parse(pageQueueResult)), false);
        }
        const pageIdsResult = global.localStorage.getItem(Constants.STORE_KEY_PAGE_IDS);
        if (pageIdsResult !== null) {
            this.setPageIds(JSON.parse(JSON.parse(pageIdsResult)), false);
        }
        const pageDataResult = global.localStorage.getItem(Constants.STORE_KEY_PAGE_DATA);
        if (pageDataResult !== null) {
            this.setPageData(JSON.parse(JSON.parse(pageDataResult)), false);
        }
        const myCustomersSearchResult = global.localStorage.getItem(Constants.STORE_KEY_MY_CUSTOMERS_SEARCH);
        if (myCustomersSearchResult !== null) {
            this.setMyCustomersSearch(JSON.parse(myCustomersSearchResult), false);
        }
        const selectedCustomer = global.localStorage.getItem(Constants.STORE_KEY_SELECTED_CUSTOMER);
        if (selectedCustomer !== null) {
            this.setSelectedCustomer(JSON.parse(JSON.parse(selectedCustomer)), false);
        }
    }
}

export default new WebStore();
