/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default function isPasswordValid(password: string) {
    // eslint-disable-next-line no-control-regex,max-len
    const expression = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,50})/;
    const expression2 = /^\S*$/;

    if (!expression2.test(password)) {
        return 'Password must not contain any spaces';
    }

    if (!expression.test(password) || password.length < 8 || password.trim() === '') {
        return 'Password must contain at least eight characters, at least one number, uppercase and lowercase letter';
    }

    return '';
}
