/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import Grid from '@mui/material/Grid';
import {styled} from '@mui/material';

const Cell = styled('div')({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

interface Props {
    header?: boolean,
    xs: number,
    sm?: number
    md?: number,
    lg?: number,
    xl?: number,
    children: object,
    minWidth?: string,
    topMargin?: string,
}

const DisplayGridItem = (props: Props) => (
    <Grid
        item
        xs={props.xs}
        sm={props.sm}
        md={props.md}
        lg={props.lg}
        xl={props.xl}
        sx={props.header ? {paddingBottom: '16px'} : {
            marginTop: props.topMargin ? props.topMargin : '16px',
            paddingTop: 0,
            minWidth: props.minWidth,
        }}
    >
        <Cell>
            {props.children}
        </Cell>
    </Grid>
);

export default DisplayGridItem;
