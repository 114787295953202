/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper, styled, Typography} from '@mui/material';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import {observer} from 'mobx-react-lite';
import useWindowSize from '../../../../../helper/hooks/useWindowSize';
import {Dispatch, SetStateAction, useMemo, useState} from 'react';
import FanStatusModal from './FanStatusModal';
import SetFanPopup from './SetFanPopup';
import Constants from '../../../../../helper/Constants';
import {Bin, Building, Fan, GrainBag} from '../../../../../types/components/ApiTypes';
import getBinFanStatus from '../../../../../helper/functions/getBinFanStatus';
import getIndex from '../../../../../helper/functions/getIndex';
import ConfirmationModal from '../../../../common/modal/ConfirmationModal';
import BinsenseStore from '../../../../../stores/BinsenseStore';
import getSelectedSiteIndex from '../../../../../helper/functions/getSelectedSiteIndex';
import getTestID from '../../../../../helper/functions/getTestID';
import putBinSettings from '../../../../../controllers/apiCalls/put/putBinSettings';
import { useSnackbar } from 'notistack';
import DashboardSkeleton from '../../../../common/DashboardSkeleton';
import WebStore from '../../../../../stores/WebStore';

interface Props {
    fanIdField: {
        value: string | number,
        setValue: Dispatch<SetStateAction<string | number>>,
    }
    bin: Bin | GrainBag | Building,
}

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
});

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
});

const HEADER_FONT_SIZE = 14;
const CONTENT_FONT_SIZE = 20;

const FanStatusPaper = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ turnOnFanModal, setTurnOnFanModal ] = useState(false);
    const [ turnOffFanModal, setTurnOffFanModal ] = useState(false);

    const fanStatus = useMemo(() => {
        if (props.fanIdField.value === 'All') {
            return getBinFanStatus(props.bin.hardware.fans);
        }
        const index = getIndex(Number(props.fanIdField.value), props.bin.hardware.fans);
        return index > -1 ? getBinFanStatus([ props.bin.hardware.fans[index] ]) : Constants.NA;
    }, [ props.fanIdField.value, props.bin ]);

    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);

    const handleButtonClick = () => fanStatus === 'Off' ? setTurnOnFanModal(true) : setTurnOffFanModal(true);

    const turnOffFan = () => {
        const siteIndex = getSelectedSiteIndex();
        let binIndex;
        let clone;
        if (WebStore.selectedView === Constants.DEALER) {
            binIndex = getIndex(props.bin.id, BinsenseStore.siteStructures.bins);
            clone = JSON.parse(JSON.stringify(BinsenseStore.siteStructures));
        } else {
            binIndex = getIndex(props.bin.id, BinsenseStore.userData.sites[siteIndex].bins);
            clone = JSON.parse(JSON.stringify(BinsenseStore.userData));
        }
        let settings: {};
        if (props.fanIdField.value === 'All') {
            settings = {
                fanControls: props.bin.hardware.fans.map(fan => ({
                    fanId: fan.id,
                    request: 'TURN_OFF',
                    hours: Number(0),
                })),
            };
            if (WebStore.selectedView === Constants.DEALER) {
                clone.bins[binIndex].hardware.fans.forEach((fan: Fan) => fan.status.status = 'STOPPED');
            } else {
                clone.sites[siteIndex].bins[binIndex].hardware.fans.forEach((fan: Fan) => fan.status.status = 'STOPPED');
            }
        }
        else {
            settings = {
                fanControls: {
                    fanId: props.fanIdField.value,
                    request: 'TURN_OFF',
                    hours: Number(0),
                },
            };
            if (WebStore.selectedView === Constants.DEALER) {
                const fanIndex = getIndex(Number(props.fanIdField.value),
                    clone.bins[binIndex].hardware.fans);
                clone.bins[binIndex].hardware.fans[fanIndex].status.status = 'STOPPED';
            } else {
                const fanIndex = getIndex(Number(props.fanIdField.value),
                    clone.sites[siteIndex].bins[binIndex].hardware.fans);
                clone.sites[siteIndex].bins[binIndex].hardware.fans[fanIndex].status.status = 'STOPPED';
            }
        }
        putBinSettings(enqueueSnackbar, props.bin.id, settings);
        if (WebStore.selectedView === Constants.DEALER) {
            BinsenseStore.setSiteStructures(clone);
        } else {
            BinsenseStore.setUserData(clone);
        }
    };

    return !BinsenseStore.awaitingUserData ? (
        <Paper
            style={{
                height: '100%',
                width: '100%',
            }}
        >
            <Column>
                <Row style={{ marginBottom: '16px' }}>
                    <Typography fontSize={HEADER_FONT_SIZE}>
                        {Constants.FAN_STATUS}
                    </Typography>
                    <SetFanPopup
                        setFanId={props.fanIdField.setValue}
                        bin={props.bin}
                    />
                </Row>
                <Row>
                    <Typography
                        {...getTestID('view_device_fan_status')}
                        fontWeight="bold"
                        fontSize={windowWidth > Constants.WINDOW_WIDTHS[8] ? CONTENT_FONT_SIZE : CONTENT_FONT_SIZE - 2}
                        marginRight="16px"
                    >
                        {fanStatus}
                    </Typography>
                    <SubmitButton
                        id="change_fan_status_button"
                        text={fanStatus === 'Off' ? 'Turn On' : 'Turn Off'}
                        onClick={handleButtonClick}
                        disabled={fanStatus === 'No Fans' || (props.bin as Bin).automationSettings?.running}
                    />
                </Row>
            </Column>
            {turnOnFanModal && (
                <FanStatusModal
                    open
                    close={() => setTurnOnFanModal(false)}
                    bin={props.bin}
                    fanId={props.fanIdField.value}
                />
            )}
            {turnOffFanModal && (
                <ConfirmationModal
                    id="turn_off_fan_confirmation_modal"
                    open
                    close={() => setTurnOffFanModal(false)}
                    submit={turnOffFan}
                    title="Turn Off Fan"
                    message='Are you sure you want to turn off the selected fan?'
                />
            )}
        </Paper>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={160}/>
        </SkeletonRoot>
    );
});

export default FanStatusPaper;
