/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */
import isKeyInString from './isKeyInString';

const filterByName = (structs: {name: string}[], key: string) => {
    if (key !== '') {
        return structs.filter((struct: any) => isKeyInString(struct.name, key));
    }
    return structs;
};

export default filterByName;
