/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Typography} from '@mui/material';

const menuItem = {
    fontWeight: 'bold',
    marginRight: '8px',
    marginLeft: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '12px',
    paddingBottom: '12px',
};

interface Props {
    text: string,
    drawerOpen: boolean,
}

const DrawerText = (props: Props) => (
    <div>
        {props.drawerOpen && (
            <Typography sx={{...menuItem}}>
                {props.text}
            </Typography>
        )}
    </div>
);

export default DrawerText;
