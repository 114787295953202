import callPutAPI from '../../callPutAPI';

const putPasswordReset = (enqueueSnackbar: any, body: any, setSuccess: any) => {
    callPutAPI('password/reset',
        body,
        (response) => {
            if (response === 'OK') {
                setSuccess(true);
            }
        },
        enqueueSnackbar,
        'Password saved successfully.'
    );
};

export default putPasswordReset;