import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';
import WebStore from '../../../stores/WebStore';
import { comparisonOfStructuresInAscendingOrder } from '../../../helper/functions/functions';

const getSiteCommunicationData = (enqueueSnackbar: any, id: number) => {
    callAPI(`siteCommunicationSummaryTable/${ id }/${ WebStore.utcOffset }`,
        (response) => {
            const clone = JSON.parse(JSON.stringify(response));
            clone.cableCommunication.rows = comparisonOfStructuresInAscendingOrder(clone.cableCommunication.rows);
            BinsenseStore.setCommunicationTableData(clone);
        },
        enqueueSnackbar
    );
};

export default getSiteCommunicationData;