import callPostAPI from '../../callPostAPI';
import signIn from '../../../helper/functions/signIn';
import WebStore from '../../../stores/WebStore';

const postUser = (enqueueSnackbar: any, body: any, tab?: string) => {
    callPostAPI('user',
        body,
        (response) => {
            if (tab) {
                signIn(body.email, body.password, tab, enqueueSnackbar);
            }
            else {
                WebStore.setCreateSiteNewUserId(response);
            }
        },
        enqueueSnackbar,
        'User added successfully'
    );
};

export default postUser;