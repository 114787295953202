/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Box, Button, Step, StepLabel, Stepper, styled} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';
import BackButton from '../../common/buttons/BackButton';
import CancelButton from '../../common/buttons/CancelButton';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
});

interface Props {
    id: string,
    children: object,
    close: () => void,
    submit: () => void,
    steps: string[],
    activeStep: number,
    setActiveStep: (value: number) => void,
    cancel?: () => void,
    handleNext?: () => void,
    handleBack?: () => void,
}

const BaseWizard = (props: Props) => {
    const handleNext = () => {
        if (props.activeStep < props.steps.length - 1) {
            props.setActiveStep(props.activeStep + 1);
        }
        else {
            props.submit();
        }
    };

    const handleBack = () => props.setActiveStep(props.activeStep - 1);

    return (
        <Root>
            <Stepper
                activeStep={props.activeStep}
                style={{
                    paddingBottom: '16px',
                }}
            >
                {props.steps.map((label, i) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step
                            {...getTestID(`${ props.id }_step_${ i + 1 }`)}
                            key={label}
                            {...stepProps}
                        >
                            <StepLabel
                                {...labelProps}
                                {...getTestID(label)}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {props.children}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: '16px',
                }}
            >
                <BackButton
                    id={`${ props.id }_back_button`}
                    handleBack={props.handleBack ? props.handleBack : handleBack}
                    activeStep={props.activeStep}
                />
                <CancelButton
                    id={`${ props.id }_cancel_button`}
                    onClick={props.cancel ? props.cancel : props.close}
                />
                <Button
                    {...getTestID(`${ props.id }_next_button`)}
                    onClick={props.handleNext ? props.handleNext : handleNext}
                    variant="contained"
                >
                    {props.activeStep === props.steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
            </Box>
        </Root>
    );
};

export default BaseWizard;