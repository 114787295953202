/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import Constants from '../../../helper/Constants';
import {observer} from 'mobx-react-lite';
import {useEffect, useState} from 'react';
import {styled, Tab, Tabs} from '@mui/material';
import SiteReportPage from './siteReport/SiteReportPage';
import ReportsLogsPage from './logs/ReportsLogsPage';
import ReportsDifferencePage from './differences/ReportsDifferencePage';
import getTestID from '../../../helper/functions/getTestID';
import {Customer, Site} from '../../../types/components/ApiTypes';
import SiteReportHeaderPaper from '../../dealer/myCustomers/siteReportPage/SiteReportHeaderPaper';
import AutomationPage from './automation/AutomationPage';
import siteHasAutomationHub from '../../../helper/functions/siteHasAutomationHub';
import siteMonitoredOnlyBySolo from '../../../helper/functions/siteMonitoredOnlyBySolo';
import siteHasAllMasterCalledIn from '../../../helper/functions/siteHasAllMasterCalledIn';
import CommunicationSummaryPage from './CommunicationSummaryPage';
import WebStore from '../../../stores/WebStore';

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: '16px',
});

interface Props {
    site: Site,
    user?: Customer,
}

const ReportsMainPage = observer((props: Props) => {
    const [ tab, setTab ] = useState(Constants.SITE_REPORT);

    useEffect(() => {
        if (tab === Constants.AUTOMATION && !siteHasAutomationHub(props.site)) {
            setTab(Constants.SITE_REPORT);
        }
    }, [ props.site ]);

    const renderContentPanel = () => {
        switch (tab) {
            case Constants.SITE_REPORT:
                return (<SiteReportPage site={props.site}/>);
            case Constants.ACTIVITY:
                return (<ReportsLogsPage site={props.site}/>);
            case Constants.DIFFERENCE:
                return (<ReportsDifferencePage site={props.site}/>);
            case Constants.AUTOMATION:
                return (<AutomationPage site={props.site} />);
            case Constants.COMMUNICATION_SUMMARY:
                return (<CommunicationSummaryPage site={props.site}/>);
        }
    };

    const handleChange = (event: any, newValue: string) => {
        setTab(newValue);
    };

    const siteTransferable = (site: Site) => {
        const monitoredByOnlySolo = siteMonitoredOnlyBySolo(site);
        const hasAllMasterCalledIn = siteHasAllMasterCalledIn(site);
        return monitoredByOnlySolo || hasAllMasterCalledIn;
    };

    return (
        <div style={{ width: '100%' }}>
            {props.user && (
                <SiteReportHeaderPaper
                    user={props.user}
                    site={props.site}
                    disable={!siteTransferable(props.site)}
                />
            )}
            <Row>
                <Tabs
                    value={tab}
                    onChange={handleChange}
                >
                    <Tab
                        {...getTestID('site_report_tab')}
                        label={Constants.SITE_REPORT}
                        value={Constants.SITE_REPORT}
                    />
                    <Tab
                        {...getTestID('logs_tab')}
                        label={Constants.ACTIVITY}
                        value={Constants.ACTIVITY}
                    />
                    <Tab
                        {...getTestID('difference_tab')}
                        label={Constants.DIFFERENCE}
                        value={Constants.DIFFERENCE}
                    />
                    {siteHasAutomationHub(props.site) && (
                        <Tab
                            {...getTestID('automation_tab')}
                            label={Constants.AUTOMATION}
                            value={Constants.AUTOMATION}
                        />
                    )}
                    {WebStore.selectedView === Constants.DEALER && (
                        <Tab
                            {...getTestID('communication_summary_tab')}
                            label={Constants.COMMUNICATION_SUMMARY}
                            value={Constants.COMMUNICATION_SUMMARY}
                        />
                    )}
                </Tabs>
            </Row>
            {renderContentPanel()}
        </div>
    );
});

export default ReportsMainPage;
