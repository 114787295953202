import callDeleteAPI from '../../callDeleteAPI';
import updateData from '../../../helper/functions/updateData';
import WebStore from '../../../stores/WebStore';
import Constants from '../../../helper/Constants';
import getNotificationSettings from '../get/getNotificationSettings';

const deleteNotificationEmail = (enqueueSnackbar: any, id: number) => {
    callDeleteAPI(`notificationEmail/${ id }`,
        (response) => {
            if (response === 'Email deleted') {
                updateData(enqueueSnackbar);
                if (WebStore.selectedView === Constants.DEALER) {
                    getNotificationSettings(enqueueSnackbar, WebStore.selectedCustomer.id);
                }
            }
        },
        enqueueSnackbar,
        'Notification email deleted successfully'
    );
};

export default deleteNotificationEmail;