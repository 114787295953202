/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {useState} from 'react';
import BaseModal from '../../../common/modal/BaseModal';
import EnterSerialStep from './EnterSerialStep';
import {AutomationData, MarkerTypes} from '../../../../types/components/ApiTypes';
import {styled, Typography} from '@mui/material';
import getTestID from '../../../../helper/functions/getTestID';
import getSerialValidation from '../../../../helper/functions/getSerialValidation';
import { useSnackbar } from 'notistack';
import Constants from '../../../../helper/Constants';

const Container = styled('div')({
    width: '750px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
    onSubmit: (automationData: AutomationData) => void,
    title?: string,
}

const AddAutomationMasterHubModal = (props: Props) => {
    const [ serial, setSerial ] = useState([ '' ]);
    const [ error, setError ] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const isSerialValid = (s: any) => {
        if (s !== undefined) {
            return s.isAvailable;
        }
        return false;
    };

    const isValid = () => serial[0] !== '';

    const submit = () => {
        if (isValid()) {
            getSerialValidation(enqueueSnackbar, serial, MarkerTypes.automation, Constants.AUTOMATION_HUB).then(r => {
                const valid = !r.map(s => isSerialValid(s)).includes(false);
                if (valid) {
                    props.onSubmit({ serial: serial[0] });
                    props.close();
                } else {
                    setError(true);
                }
            });
        }
        else {
            setError(true);
        }
    };

    return (
        <BaseModal
            id='add_automation_master_hub_modal'
            open
            close={props.close}
            submit={submit}
            title={props.title || Constants.ADD_AUTOMATION_HUB}
        >
            <Container>
                <EnterSerialStep
                    serial={{
                        value: serial,
                        setValue: setSerial,
                    }}
                    automationMasterHub
                />
                {error && (
                    <Typography
                        {...getTestID('enter_serial_error')}
                        color='error'
                    >
                        Please enter a valid serial number
                    </Typography>
                )}
            </Container>
        </BaseModal>
    );
};

export default AddAutomationMasterHubModal;
