/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import AreaChart from '../../../../common/charts/AreaChart';
import { SiteGraphData } from '../../../../../types/components/ApiTypes';
import Colors from '../../../../../helper/Colors';
import { Dispatch, SetStateAction } from 'react';

interface Props {
    data: SiteGraphData[],
    id: string,
    legend_show: boolean,
    height: string | number,
    setTimeUnit: Dispatch<SetStateAction<string>>
    setStart: Dispatch<SetStateAction<moment.Moment>>
    setEnd: Dispatch<SetStateAction<moment.Moment>>
}

const ViewSiteRHChart = (props: Props) => {
    const series = [
        {
            name: 'Relative Humidity',
            type: 'line',
            data: props.data.map(dataPoint => [ dataPoint.dt, dataPoint.rh ]),
        },
    ];

    const yaxis: ApexYAxis[] = [
        {
            seriesName: 'Relative Humidity (%)',
            title: {
                text: 'Relative Humidity (%)',
            },
            opposite: false,
            labels: {
                  minWidth: 80,
                  maxWidth: 80,
            },
        },
    ];

    const gstroke : ApexStroke = {};
    gstroke.width = [ 3 ];
    gstroke.curve = 'smooth';
    gstroke.dashArray = [ 0 ];

    const popt : ApexPlotOptions = {};

    return (
        <AreaChart
            series={series}
            yaxis={yaxis}
            legendPosition="bottom"
            showLegend={props.legend_show}
            id={props.id}
            type="line"
            showXAxis={false}
            height={props.height}
            stroke={gstroke}
            colors={[ Colors.avgMoistureColor ]}
            plotOptions={popt}
            setTimeUnit={props.setTimeUnit}
            setStart={props.setStart}
            setEnd={props.setEnd}
            isSiteChart={true}
        />
    );
};

export default ViewSiteRHChart;
