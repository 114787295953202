/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import LoginStore from '../../../stores/LoginStore';
import {EnqueueSnackbar} from '../../../types/SnackbarTypes';
import handleError from '../../handleError';

const getAuthCode = (username: string,
                     password: string,
                     tab: string,
                     enqueueSnackbar: EnqueueSnackbar) => {
    // eslint-disable-next-line max-len
    const body = `username=${ encodeURIComponent(username) }&pwd=${ encodeURIComponent(password) }&redirect_uri=${ LoginStore.redirectUri }&client_secret=${ LoginStore.clientSecret }&client_id=${ LoginStore.loginConfig.clientId }`;

    return (
        fetch (
            LoginStore.loginConfig.authUrl,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Content-Length': body.length.toString(),
                },
                body,
            }
        )
            .then((response) => {
                handleError(response, enqueueSnackbar);
                return response.text();
            })
            .then((receivedServerData) => {
                if (receivedServerData !== '' && receivedServerData !== 'WrongPwd') {
                    const accountType = receivedServerData.split('&')[3].split('=')[1];
                    if (tab.toLowerCase() === accountType) {
                        LoginStore.setAuthUrl(receivedServerData);
                        return {
                            success: true,
                            message: 'OK',
                        };
                    }
                    return {
                        success: false,
                        message: '',
                    };
                }
                return {
                    success: false,
                    message: receivedServerData,
                };
            })
    );
};

export default getAuthCode;
