export default {
    account: {
        accountEmail: '',
        address: {
            address: '',
            city: '',
            country: '',
            postalCode: '',
            province: '',
        },
        companyName: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        userName: '',
    },
    balance: {
        amount: 0,
        dueDay: 0,
        subscribedAutomation: 0,
        subscribedMasters: 0,
        subscribedSolos: 0,
        currentPlanAmount: 0,
        renewalDate: 0,
    },
    bushelChange: 0,
    capacityChange: 0,
    generated: 0,
    id: -1,
    masterUnits: [],
    lockedOut: false,
    runningFansChange: 0,
    settings: {
        allowDealerAccess: true,
        dealerId: -1,
        notificationEmails: [],
        notificationPhoneNumbers: [],
        preferences: {
            areaUnits: '',
            distanceUnits: '',
            language: '',
            temperatureUnits: '',
            timeZone: '',
            volumeUnits: '',
            showEmptyStructures: true,
        },
        preferredSiteId: -1,
    },
    sites: [],
    totalBushels: 0,
    totalCapacity: 0,
    totalFansRunning: 0,
};
