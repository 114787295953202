/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import Constants from '../../../helper/Constants';
import {MenuItem, styled} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';
import {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import BinsenseStore from '../../../stores/BinsenseStore';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import {observer} from 'mobx-react-lite';
import TextFieldInput from '../../common/input/TextFieldInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import {Search, Close} from '@mui/icons-material';
import PopupMenu from '../../common/PopupMenu';

interface Props {
    id: string,
    user: {
        value: string | null,
        setValue: Dispatch<SetStateAction<string | null>>,
    },
    userInput: {
        value: string,
        setValue: (newValue: string) => void,
    },
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
});

const SearchUserFilter = observer((props: Props) => {
    const [ filteredList, setFilteredList ] = useState<any[]>([]);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);

    const customers = useMemo(() => BinsenseStore.dealerUserData.customers, [ BinsenseStore.dealerUserData ]);

    useEffect(() => {
        if (props.userInput.value === '') {
            props.user.setValue(null);
        }
    }, [ props.userInput ]);

    const filter = () => {
        const search = props.userInput.value.toLowerCase();
        const newList = customers.filter(c => {
            if (c.account?.accountEmail) {
                const isStringInUser =
                    (c.account.accountEmail && c.account.accountEmail.toLowerCase().includes(search)) ||
                    (c.account.userName && c.account.userName.toLowerCase().includes(search)) ||
                    (c.account.firstName && c.account.firstName.toLowerCase().includes(search)) ||
                    (c.account.lastName && c.account.lastName.toLowerCase().includes(search));
                if (isStringInUser) {
                    return true;
                } else {
                    for (let i = 0; i < c.sites.length; i++) {
                        if (c.sites[i].name && c.sites[i].name.toLowerCase().includes(search)) {
                            return true;
                        }
                    }
                }
            }
            return false;
        });
        setFilteredList(newList.map(c => ({
            id: c.id,
            title: `${ c.account.lastName }, ${ c.account.firstName } - ${ c.account.accountEmail }`,
            value: c.account.accountEmail,
        })));
    };

    useEffect(() => {
        if (props.user.value !== null) {
            const c = customers.filter(customer => {
                if (customer.account?.accountEmail) {
                    return props.user.value === customer.account.accountEmail;
                }
            });
            if (c.length > 0) {
                props.userInput.setValue(c[0].account.accountEmail);
            }
        }
    }, []);

    const handleClick = (event: any) => {
        if (props.user.value === null) {
            filter();
            setAnchorEl(event.currentTarget);
        }
        else {
            props.userInput.setValue('');
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCustomerClick = (customer: string) => {
        props.userInput.setValue(customer);
        props.user.setValue(customer);
        handleClose();
    };

    useEffect(() => {
        if (props.userInput.value !== '' &&
            customers.map(c => c.account?.accountEmail && c.account.accountEmail === props.userInput.value)
                .includes(true)) {
            handleCustomerClick(props.userInput.value);
        }
    }, [ customers ]);

    const onEnterPress = (e: any) => {
        if (e.key === 'Enter' && props.userInput.value !== '') {
            handleClick(e);
        }
    };

    return (
        <Row onKeyDown={onEnterPress}>
            {!BinsenseStore.awaitingUserData ? (
                <TextFieldInput
                    id={`${ props.id }_filter`}
                    label={Constants.SEARCH}
                    field={props.userInput}
                    textFieldProps={{
                        InputProps: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        {...getTestID(props.id)}
                                        onClick={handleClick}
                                    >
                                        {props.user.value === null ? <Search/> : <Close/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        },
                    }}
                />
            ) : (
                <DashboardSkeleton
                    height={64}
                    style={{
                        marginBottom: '8px',
                    }}
                />
            )}
            <PopupMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
            >
                {filteredList.length > 0 ? filteredList.map(customer => (
                    <MenuItem
                        {...getTestID(`${ customer.id }_button`)}
                        key={`customer_${ customer.id }`}
                        onClick={() => handleCustomerClick(customer.value)}
                    >
                        {customer.title}
                    </MenuItem>
                )) : <div>No search results</div>}
            </PopupMenu>
        </Row>
    );
});

export default SearchUserFilter;
