/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import Constants from '../../../helper/Constants';
import ContentPanel from '../../common/ContentPanel';
import ViewDevicePage from '../storage/viewDevicePage/ViewDevicePage';
import WebStore from '../../../stores/WebStore';
import StorageMainPage from '../storage/StorageMainPage';
import {observer} from 'mobx-react-lite';
import {useMemo} from 'react';
import getSelectedSiteIndex from '../../../helper/functions/getSelectedSiteIndex';
import BinsenseStore from '../../../stores/BinsenseStore';
import getSite from '../../../helper/functions/getSite';

const StorageDrawer = observer(() => {
    const site = useMemo(() => getSite(getSelectedSiteIndex()), [ WebStore.selectedSiteId, BinsenseStore.userData ]);

    const renderContentPanel = () => {
        switch (WebStore.currentPage) {
            case Constants.MAIN:
                return (<StorageMainPage site={site}/>);
            case Constants.VIEW_DEVICE:
                return (<ViewDevicePage site={site}/>);
            default:
                return (<div/>);
        }
    };

    return (
        <ContentPanel>
            {renderContentPanel()}
        </ContentPanel>
    );
});

export default StorageDrawer;
