/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Fragment} from 'react';
import {Chip, styled, Typography} from '@mui/material';
import {MarkerData} from '../../../../../types/components/ApiTypes';
import getTestID from '../../../../../helper/functions/getTestID';
import TrendIcon from '../../../TrendIcon';
import Constants from '../../../../../helper/Constants';
import BinImageSVG from '../../../../customer/storage/grid/BinImageSVG';

const AdvancedRoot = styled('div')({
    width: '468px',
});

const TitleRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '16px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    alignSelf: 'stretch',
    width: '50%',
});

const CenterColumn = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-between',
    alignSelf: 'stretch',
    width: '50%',
    paddingLeft: '24px',
});

interface Props {
    marker: MarkerData,
}

const showStatus = (props: Props) => {
    if (props.marker.tooltip &&
        props.marker.tooltip.displayData?.status !== null) {
        return (
            <Chip
            {...getTestID('map_popup_status')}
            label={props.marker.tooltip.displayData?.status}
            color={props.marker.tooltip.displayData?.status === 'Online' ? 'success' : 'error'}
            />
        );
    }
};

const getTrendIcon = (trend: string) => (
    <TrendIcon value={trend}/>
);

const DetailedBinTooltip = (props: Props) => {
    if (props.marker.tooltip) {
        return (
            <Fragment>
                <AdvancedRoot>
                    <TitleRow>
                        <Typography
                            {...getTestID('map_popup_name')}
                            fontSize="20px"
                            fontWeight="bold"
                        >
                            {props.marker.tooltip.name}
                        </Typography>
                        {showStatus(props)}
                    </TitleRow>
                    <Row>
                        <Column>
                            <Row>
                                <Typography fontSize="14px">Commodity: </Typography>
                                <Typography
                                    {...getTestID('map_popup_serial')}
                                    fontSize="14px"
                                >
                                    {props.marker.tooltip.displayData?.contents}
                                </Typography>
                            </Row>
                            <Row>
                                <Typography fontSize="14px">Max Temp: </Typography>
                                <Typography
                                    {...getTestID('map_popup_max_temp')}
                                    fontSize="14px"
                                    color="success.main"
                                >
                                    {props.marker.tooltip.displayData?.maxTemp}
                                </Typography>
                            </Row>
                            <Row>
                                <Typography fontSize="14px">Temp Trend: </Typography>
                                {props.marker.tooltip.displayData?.tempTrend
                                    ? getTrendIcon(props.marker.tooltip.displayData.tempTrend) : Constants.NA}
                            </Row>
                            <Row>
                                <Typography fontSize="14px">Max Moisture: </Typography>
                                <Typography
                                    {...getTestID('map_popup_max_moisture')}
                                    fontSize="14px"
                                    color="success.main"
                                >
                                    {props.marker.tooltip.displayData?.maxMoisture}
                                </Typography>
                            </Row>
                            <Row>
                                <Typography fontSize="14px">Moisture Trend: </Typography>
                                {props.marker.tooltip.displayData?.moistureTrend
                                    ? getTrendIcon(props.marker.tooltip.displayData.moistureTrend) : Constants.NA}
                            </Row>
                            <Row>
                                <Typography fontSize="14px">Level: </Typography>
                                <Typography
                                    {...getTestID('map_popup_level')}
                                    fontSize="14px"
                                >
                                    {props.marker.tooltip.displayData?.level}
                                </Typography>
                            </Row>
                            <Row>
                                <Typography fontSize="14px">Fan(s): </Typography>
                                <Typography
                                    {...getTestID('map_popup_fans')}
                                    fontSize="14px"
                                >
                                    {props.marker.tooltip.displayData?.fans}
                                </Typography>
                            </Row>
                            <Row>
                                <Typography fontSize="14px">{props.marker.tooltip.displayData?.type}: </Typography>
                                <Typography
                                    {...getTestID('map_popup_serial')}
                                    fontSize="14px"
                                >
                                    {props.marker.tooltip.displayData?.serial}
                                </Typography>
                            </Row>
                        </Column>
                        <CenterColumn>
                            {props.marker.tooltip.structure &&
                                <BinImageSVG
                                    id={ `bin_${ props.marker.tooltip.displayData?.serial }` }
                                    structure={ props.marker.tooltip.structure }
                                    displayInTooltip={true}
                                />
                            }
                        </CenterColumn>
                    </Row>
                </AdvancedRoot>
            </Fragment>
        );
    }
    return null;
};

export default DetailedBinTooltip;
