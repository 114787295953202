/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useState} from 'react';
import {Grid, styled} from '@mui/material';
import Constants from '../../../helper/Constants';
import TitlePaper from '../../common/TitlePaper';
import SubmitButton from '../../common/buttons/SubmitButton';
import ToggleButtonInput from '../../common/input/ToggleButtonInput';
import BinsenseStore from '../../../stores/BinsenseStore';
import GridSelectFieldInput from '../../common/input/GridSelectFieldInput';
import putUserSettings from '../../../controllers/apiCalls/put/putUserSettings';
import {useSnackbar} from 'notistack';
import {UserPreferences} from '../../../types/components/ApiTypes';
import StyledSwitch from '../../common/input/StyledSwitch';
import WebStore from '../../../stores/WebStore';

interface Props {
    preferences: UserPreferences,
    dealer?: boolean,
    userId?: number,
    hideShowEmptyStructureOption?: boolean,
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '10px',
});

const SystemPreferencesPaper = (props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ timezone, setTimezone ] = useState(props.preferences?.timeZone
        ? props.preferences.timeZone : '');
    const [ temperature, setTemperature ] = useState(props.preferences?.temperatureUnits
        ? props.preferences.temperatureUnits : '');
    const [ showEmptyStructures, setShowEmptyStructures ] = useState(props.preferences.showEmptyStructures);

    useEffect(() => {
        setTimezone(props.preferences?.timeZone ? props.preferences.timeZone : '');
        setTemperature(props.preferences?.temperatureUnits ? props.preferences?.temperatureUnits : '');
        setShowEmptyStructures(props.preferences.showEmptyStructures);
    }, [ props.preferences ]);

    useEffect(() => {
        if (WebStore.selectedView === Constants.CUSTOMER) {
            BinsenseStore.userData.settings.preferences.showEmptyStructures = showEmptyStructures;
        } else {
            BinsenseStore.dealerUserData.settings.preferences.showEmptyStructures = showEmptyStructures;
        }
    }, [ showEmptyStructures ]);

    const isValid = () => timezone !== '' || temperature !== '';

    const submit = () => {
        if (isValid()) {
            const settings = {
                preferences: {
                    temperatureUnits: temperature,
                    timeZone: timezone,
                    showEmptyStructures: showEmptyStructures ? 'true' : 'false',
                },
            };
            if (props.dealer) {
                if (props.userId && props.userId > 0) {
                    // dealer edit customer's preference
                    putUserSettings(enqueueSnackbar, props.userId, settings);
                } else {
                    // dealer edit dealer's preference
                    putUserSettings(enqueueSnackbar, BinsenseStore.dealerUserData.id, settings);
                }
            } else {
                // customer edit customer's preference
                putUserSettings(enqueueSnackbar, BinsenseStore.userData.id, settings);
            }
        }
    };

    return (
        <TitlePaper title={Constants.PREFERENCES}>
            <Grid
                container
                spacing={2}
            >
                <GridSelectFieldInput
                    id="preferred_timezone_select_input"
                    label={Constants.PREFERRED_TIMEZONE}
                    field={{
                        value: timezone,
                        setValue: setTimezone,
                        error: '',
                        setError: () => {},
                    }}
                    options={[
                        {
                            id: `${ timezone.toLowerCase().replace(' ', '_') }_option`,
                            title: timezone,
                        },
                        ...BinsenseStore.dataModel.timeZones.map(zone => ({
                            id: zone.toLowerCase().replace(' ', '_'),
                            title: zone,
                        })),
                    ]}
                    fullWidth
                />
            </Grid>
            <Row>
                <ToggleButtonInput
                    title="Temperature"
                    value={temperature}
                    setValue={setTemperature}
                    options={[
                        {
                            id: 'fahrenheit_option',
                            value: 'Fahrenheit',
                            title: 'Fahrenheit',
                        },
                        {
                            id: 'celsius_option',
                            value: 'Celsius',
                            title: 'Celsius',
                        },
                    ]}
                />
            </Row>
            { !props.hideShowEmptyStructureOption &&
                <Row>
                    <StyledSwitch
                        id='includeEmpty'
                        description='Show Empty Structures'
                        value={showEmptyStructures}
                        setValue={setShowEmptyStructures}
                    />
                </Row>
            }
            <SubmitButton
                id="preferences_submit"
                text={Constants.SAVE_CHANGES}
                onClick={submit}
            />
        </TitlePaper>
    );
};

export default SystemPreferencesPaper;
