import callAPI from '../../callAPI';

const getPortalForm = (enqueueSnackbar: any, setLoading: any, setHtml: any, userId: any) => {
    callAPI(userId ? `payments/portalForm/${ userId }` : 'payments/portalForm',
            (response) => {
                if (response != null && response.toString().length > 0) {
                    window.history.pushState({}, '', '/'); // this allows back arrow to redirect to binsense from stripe
                    setLoading(false);
                    setHtml(response.toString());
                }
            },
            enqueueSnackbar
        );
};

export default getPortalForm;