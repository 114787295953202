/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Skeleton} from '@mui/material';
import {CSSProperties} from 'react';

interface Props {
    height?: number,
    width?: string,
    style?: CSSProperties,
}

const DashboardSkeleton = (props: Props) => (
    <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{
            width: props.width ? props.width : '100%',
            maxWidth: 'none',
            height: props.height ? `${ props.height }px` : '100%',
            borderRadius: '8px',
        }}
        style={props.style}
    />
);

export default DashboardSkeleton;
