/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import Constants from '../../../../helper/Constants';
import DisplayGridHeaderRow from '../../../common/displayTable/DisplayGridHeaderRow';
import {observer} from 'mobx-react-lite';
import {useEffect, useMemo, useState} from 'react';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DisplayGridSortableTableHeader from '../../../common/displayTable/DisplayGridSortableTableHeader';
import {Site} from '../../../../types/components/ApiTypes';
import getAutomationDevicesData from '../../../../helper/functions/getAutomationDevicesData';
import AutomationDevicesTableRow from './AutomationDevicesTableRow';
import PaginationTable from '../../../common/displayTable/PaginationTable';

interface Props {
    site: Site,
}

const XS = 9.0 / 4.0;

const AutomationDevicesTable = observer((props: Props) => {
    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const rows = useMemo(() => BinsenseStore.automationDevicesData, [ BinsenseStore.automationDevicesData ]);

    useEffect(() => BinsenseStore.setAutomationDevicesData(getAutomationDevicesData(props.site)), [ props.site ]);

    const renderGridItem = (label: string, key: string, id: string, xs: number) => (
        <DisplayGridSortableTableHeader
            id={`automation_devices_table_${ id }`}
            label={label}
            xs={xs}
            data={rows}
            setData={BinsenseStore.setAutomationDevicesData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderRow = (row: any) => (
        <AutomationDevicesTableRow
            row={row}
            key={`${ row.id }_automation_devices_row`}
        />
    );

    return (
        <div style={{ paddingBottom: '8px' }}>
            <PaginationTable
                rows={rows}
                renderRow={renderRow}
            >
                <DisplayGridHeaderRow>
                    {renderGridItem(Constants.STRUCTURE, 'structure', 'structure', 3)}
                    {renderGridItem(Constants.REMOTE_UNIT, 'remoteUnit', 'remote_unit', XS)}
                    {renderGridItem(Constants.CABLES, 'cables', 'cables', XS)}
                    {renderGridItem(Constants.FAN_CONTROLLER, 'fanController', 'fan_controller', XS)}
                    {renderGridItem(Constants.PLENUM_SENSOR, 'plenumSensor', 'plenum_sensor', XS)}
                </DisplayGridHeaderRow>
            </PaginationTable>
        </div>
    );
});

export default AutomationDevicesTable;
