/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {ValidateFieldProps} from '../../types/components/ApiTypes';
import isStringNotEmpty from '../validator/isStringNotEmpty';
import isNumeric from '../validator/isNumeric';
import isEmailValid from '../validator/isEmailValid';

export default function isPersonalDetailsFormValid(firstName: ValidateFieldProps,
                                                   lastName: ValidateFieldProps,
                                                   phoneNumber: ValidateFieldProps,
                                                   email: ValidateFieldProps) {
    const newFirstNameError = isStringNotEmpty(firstName.value, 'first name');
    firstName.setError(newFirstNameError);
    const newLastNameError = isStringNotEmpty(lastName.value, 'last name');
    lastName.setError(newLastNameError);
    const newPhoneNumberError = isNumeric(phoneNumber.value, 10, '10-digit phone number', 10);
    phoneNumber.setError(newPhoneNumberError);
    const newEmailError = isEmailValid(email.value);
    email.setError(newEmailError);

    return (newFirstNameError === '' && newLastNameError === '' &&
        newPhoneNumberError === '' && newEmailError === '');
}
