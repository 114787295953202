export default {
    account: {
        'userName': '',
        'firstName': '',
        'lastName': '',
        'accountEmail': '',
        'phoneNumber': '',
        'companyName': '',
        'address': {
            'address': '',
            'city': '',
            'province': '',
            'country': '',
            'postalCode': '',
        },
    },
    customers: [],
    dealerGroups: [],
    dealerUsers: [],
    generated: 0,
    id: 0,
    dealerId: 0,
    settings: {
        allowDealerAccess: true,
        dealerId: -1,
        notificationEmails: [],
        notificationPhoneNumbers: [],
        preferences: {
            areaUnits: '',
            distanceUnits: '',
            language: '',
            temperatureUnits: '',
            timeZone: '',
            volumeUnits: '',
            showEmptyStructures: true,
        },
        preferredSiteId: -1,
    },
};
