/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Bin, Building, GrainBag} from '../../types/components/ApiTypes';
import Constants from '../Constants';

const getBinHeatThresholdColor = (struct: Bin | GrainBag | Building) => {
    const cables = struct.hardware?.cables ? struct.hardware.cables : [];
    let atLeastOneSensorRed = false;
    let atLeastOneSensorYellow = false;
    let allSensorsExposed = true;

    const highThreshold = struct.settings.highTemperatureThreshold;
    const lowThreshold = struct.settings.lowTemperatureThreshold;

    cables.forEach(cable => {
        cable.sensors.forEach(sensor => {
            if (!sensor.disabled && !sensor.sensorMuted && !sensor.exposed) {
                allSensorsExposed = false;
                // if temp is within 4 degrees of thresholds - CAUTION
                // eslint-disable-next-line
                if ((highThreshold != null && sensor.temperature > highThreshold - Constants.SENSOR_CAUTION_THRESHOLD && sensor.temperature < highThreshold) ||
                // eslint-disable-next-line
                    (lowThreshold != null && sensor.temperature < lowThreshold + Constants.SENSOR_CAUTION_THRESHOLD && sensor.temperature > lowThreshold)
                ) {
                    atLeastOneSensorYellow = true;
                }

                // if temp exceeds thresholds - WARNING
                if ((highThreshold != null && highThreshold && sensor.temperature >= highThreshold) ||
                    (lowThreshold != null && lowThreshold && sensor.temperature <= lowThreshold)) {
                    atLeastOneSensorRed = true;
                }
            }
        });
    });
    // eslint-disable-next-line
    return atLeastOneSensorRed ? 'red' : (atLeastOneSensorYellow ? 'yellow' : (!allSensorsExposed ? 'green' : 'black'));
};

export default getBinHeatThresholdColor;
