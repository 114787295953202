/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import {Command} from '../../../../types/components/ApiTypes';
import getTimeSince from '../../../../helper/functions/getTimeSince';
import useWindowSize from '../../../../helper/hooks/useWindowSize';
import {useMemo} from 'react';
import Constants from '../../../../helper/Constants';

interface Props {
    row: Command,
}

const ServerCommandHistoryTableRow = (props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const renderGridLabel = (id: string, label: string, xs: number, lengthLimit?: number) => (
        <DisplayGridLabel
            id={`${ props.row.serial }_command_history_${ id }`}
            label={label}
            xs={xs}
            lengthLimit={lengthLimit}
        />
    );

    return (
        <DisplayGridContent
            topMargin='0px'
        >
            {renderGridLabel('serial', props.row.serial, 1.5, largeScreen ? 9 : 6)}
            {renderGridLabel('status', props.row.status, 1.5)}
            {renderGridLabel('details', props.row.commandText, 3)}
            {renderGridLabel('queue_time', getTimeSince(props.row.queueTime), 1.5)}
            {renderGridLabel('sent_time', getTimeSince(props.row.sentTime), 1.5)}
            {renderGridLabel('reply', props.row.replyText, 3)}
        </DisplayGridContent>
    );
};

export default ServerCommandHistoryTableRow;
