/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Paper, styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import Constants from '../../../../helper/Constants';
import StorageGridAutomationHubHeader from './StorageGridAutomationHubHeader';
import getTestID from '../../../../helper/functions/getTestID';
import AutomationHub from '../../../../images/svg/AutomationGrid.svg';
import { AutomationHubs } from '../../../../types/components/ApiTypes';

const ImageDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: '40px',
    paddingBottom: '40px',
    alignItems: 'center',
});

interface Props {
    hub: AutomationHubs,
    maxWidth?: boolean,
    maxHeight?: boolean,
    children: object | object [],
}

const AutomationImagePanel = observer((props: Props) => (
    <Paper
        style={{
            width: props.maxWidth ? '100%' : undefined,
            height: props.maxHeight ? '100%' : undefined,
        }}
    >
        <StorageGridAutomationHubHeader hub={props.hub} />
        <ImageDiv>
            <img
                {...getTestID('automation_hub_grid_image')}
                src={AutomationHub}
                alt={Constants.AUTOMATION_HUB}
                width="65%"
            />
        </ImageDiv>
        {props.children}
    </Paper>
));

export default AutomationImagePanel;
