/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {MarkerData, SiteTooltipData} from '../../../../types/components/ApiTypes';
import {Place} from '@mui/icons-material';
import {Tooltip} from '@mui/material';
import getTestID from '../../../../helper/functions/getTestID';
import {useMemo} from 'react';
import getMapTooltipStyling from '../../../../helper/functions/getMapTooltipStyling';
import WebStore from '../../../../stores/WebStore';
import {observer} from 'mobx-react-lite';
import SiteMarkerTooltip from './tooltips/SiteMarkerTooltip';

interface Props {
    marker: MarkerData,
}

const SiteMarker = observer((props: Props) => {
    const tooltip = props.marker.siteTooltip ? props.marker.siteTooltip : {} as SiteTooltipData;

    const tooltipStyle = useMemo(getMapTooltipStyling,[ WebStore.lightMode ]);

    return (
        <Tooltip
            {...getTestID(`${ props.marker.id }_site_tooltip`)}
            title={(<SiteMarkerTooltip siteTooltip={tooltip}/>)}
            componentsProps={{
                tooltip: {
                    style: tooltipStyle,
                },
            }}
            followCursor
        >
            <Place
                color={props.marker.secondaryColor != undefined
                    ? props.marker.secondaryColor ? 'secondary' : 'primary' : 'primary'}
                fontSize="large"
                style={{ cursor: 'pointer' }}
            />
        </Tooltip>
    );
});

export default SiteMarker;
