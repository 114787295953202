import callPostAPI from '../../callPostAPI';
import updateData from '../../../helper/functions/updateData';
import WebStore from '../../../stores/WebStore';
import errorSnackbarOptions from '../../../helper/errorSnackbarOptions';

const postSite = (enqueueSnackbar: any, body: any) => {
    callPostAPI('site',
        body,
        (response) => {
            console.log(response);
            if (response === 'invalidRukkusSerial') {
                enqueueSnackbar('Invalid Solo Serial', errorSnackbarOptions);
            } else {
                updateData(enqueueSnackbar);
                WebStore.setSelectedSiteId(response, true);
            }
        },
        enqueueSnackbar,
        'Site added successfully'
    );
};

export default postSite;