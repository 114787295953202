/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Article, Delete, Edit, Add} from '@mui/icons-material';
import Constants from '../../../../helper/Constants';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import DisplayGridActions from '../../../common/displayTable/DisplayGridActions';
import WebStore from '../../../../stores/WebStore';
import BinSetupModal from '../../createSite/binSetupModal/BinSetupModal';
import {useMemo, useState} from 'react';
import getIndex from '../../../../helper/functions/getIndex';
import replaceSpaces from '../../../../helper/functions/replaceSpaces';
import getGrainBinData from '../../../../helper/functions/getGrainBinData';
import {BuildingData, GrainBagData, GrainBinData, Site, StructIdSerials} from '../../../../types/components/ApiTypes';
import putBin from '../../../../controllers/apiCalls/put/putBin';
import {useSnackbar} from 'notistack';
import DisplayGridChip from '../../../common/displayTable/DisplayGridChip';
import {observer} from 'mobx-react-lite';
import getNewBin from '../../../../helper/functions/getNewBin';
import useWindowSize from '../../../../helper/hooks/useWindowSize';
import getHardwareFieldsFromStructureData from '../../../../helper/functions/getHardwareFieldsFromStructureData';
import deleteAssociation from '../../../../controllers/apiCalls/delete/deleteAssociation';
import DeleteAssociationModal from '../../../common/modal/DeleteAssociationModal';
import DeleteStructureModal from '../../../common/modal/DeleteStructureModal';
import putGrainBag from '../../../../controllers/apiCalls/put/putGrainBag';
import getNewBag from '../../../../helper/functions/getNewBag';
import getGrainBagData from '../../../../helper/functions/getGrainBagData';
import BagSetupModal from '../../createSite/newSiteWizard/BagSetupModal';
import BuildingSetupModal from '../../createSite/newSiteWizard/BuildingSetupModal';
import putBuilding from '../../../../controllers/apiCalls/put/putBuilding';
import getBuildingData from '../../../../helper/functions/getBuildingData';
import getNewBuilding from '../../../../helper/functions/getNewBuilding';
import RemoteSerialGridItem from '../../../common/RemoteSerialGridItem';

interface Props {
    row: any,
    site: Site,
    masterSerials: string[],
    automationMasterSerials: string[],
    siteSerials: StructIdSerials[],
}

const AssociationsTableRow = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const disableAutomation = useMemo(() => props.site.automationHubs && props.site.automationHubs.length === 0,
        [ props.site ]);

    const [ deleteOpen, setDeleteOpen ] = useState(false);
    const [ editBinOpen, setEditBinOpen ] = useState(false);
    const [ editBagOpen, setEditBagOpen ] = useState(false);
    const [ editBuildingOpen, setEditBuildingOpen ] = useState(false);

    const setEditOpen = (value: boolean) => {
        if (props.row.type === Constants.BIN) {
            setEditBinOpen(value);
        } else if (props.row.type === Constants.BAG) {
            setEditBagOpen(value);
        } else if (props.row.type === Constants.BUILDING) {
            setEditBuildingOpen(value);
        }
    };

    const grainBinData = useMemo(() => {
        const index = getIndex(props.row.structId, props.site.bins);
        const bin = index !== -1 ? props.site.bins[index] : getNewBin();
        return getGrainBinData(bin);
    }, [ props.row, props.site ]);
    const grainBagData = useMemo(() => {
        const index = getIndex(props.row.structId, props.site.grainBags);
        const bag = index !== -1 ? props.site.grainBags[index] : getNewBag();
        return getGrainBagData(bag);
    }, [ props.row, props.site ]);
    const buildingData = useMemo(() => {
        const index = getIndex(props.row.structId, props.site.buildings);
        const building = index !== -1 ? props.site.buildings[index] : getNewBuilding();
        return getBuildingData(building);
    }, [ props.row, props.site ]);

    const onEditBinSubmit = (binData: GrainBinData) => {
        const binBody = {
            name: binData.name,
            binType: binData.type.toString().toLowerCase(),
            peakHeight: binData.peakHeight,
            eaveHeight: binData.eaveHeight,
            capacity: binData.bushelCapacity,
            diameter: binData.diameter,
            settings: {
                hardwareType: binData.deviceType,
            },
            hardware: getHardwareFieldsFromStructureData(binData),
        };
        putBin(enqueueSnackbar, props.row.structId, binBody);
    };

    const onEditBagSubmit = (data: GrainBagData) => {
        const bagBody = {
            id: props.row.structId,
            name: data.name,
            rotation: data.rotation,
            length: data.length,
            width: data.width,
            capacity: data.quantity,
            settings: {
                hardwareType: data.device,
            },
            hardware: getHardwareFieldsFromStructureData(data),
        };
        putGrainBag(enqueueSnackbar, props.row.structId, bagBody);
    };

    const onEditBuildingSubmit = (data: BuildingData) => {
        const buildingBody = {
            id: props.row.structId,
            name: data.name,
            rotation: data.rotation,
            length: data.length,
            width: data.width,
            height: data.height,
            settings: {
                hardwareType: data.device,
            },
            hardware: getHardwareFieldsFromStructureData(data),
        };
        putBuilding(enqueueSnackbar, props.row.structId, buildingBody);
    };

    const renderGridLabel = (id: string, label: string, xs: number, lengthLimit?: number) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={xs}
            lengthLimit={lengthLimit}
        />
    );

    const onDelete = () => {
        deleteAssociation(enqueueSnackbar, props.row.structId, props.row.serialNumber, props.row.structure);
    };

    const onIconClick = (newPage: string, id: number) => {
        if (WebStore.selectedView === Constants.DEALER) {
            WebStore.setPageIds([ WebStore.pageIds[0], WebStore.pageIds[1], id ], true);
            WebStore.setPageData([ WebStore.pageData[0], WebStore.pageData[1], BinsenseStore.associationsData ], true);
        }
        else {
            WebStore.setPageIds([ id, -1, -1 ], true);
            WebStore.setPageData([ BinsenseStore.associationsData, null, null ], true);
        }
        WebStore.goToNewPage(newPage);
    };

    return (
        <DisplayGridContent key={`${ props.row.id }_user`}>
            {renderGridLabel(`association_${ props.row.associationId }_structure`,
                props.row.structure, largeScreen ? 2.5 : 2, largeScreen ? 18 : 10)}
            {props.row.device === Constants.REMOTE ? (
                <RemoteSerialGridItem
                    device={props.row}
                    xs={largeScreen ? 1.5 : 1.25}
                    pageLabel='association'
                    testId={props.row.associationId}
                />
            ) : renderGridLabel(`association_${ props.row.associationId }_serial`,
                props.row.serialNumber, largeScreen ? 1.5 : 1.25)}
            <DisplayGridChip
                id={`association_${ props.row.associationId }_status`}
                status={props.row.status}
                xs={largeScreen ? 1.25 : 1.5}
            />
            {renderGridLabel(`association_${ props.row.associationId }_power_source`,
                props.row.powerSource, largeScreen ? 1.5 : 1.75)}
            {renderGridLabel(`association_${ props.row.associationId }_fan_control`,
                props.row.fanControl, largeScreen ? 1.5 : 1.25)}
            {renderGridLabel(`association_${ props.row.associationId }_device`,
                                            props.row.structure.toLowerCase() === Constants.AUTOMATION_HUB.toLowerCase()
                                            ? props.row.structure : props.row.device, 1.5)}
            {props.row.structure !== Constants.AUTOMATION_HUB &&
                <DisplayGridActions
                    buttons={[
                        {
                            id: `associations_${ props.row.associationId }_advanced_button`,
                            onClick: () => onIconClick(Constants.ADVANCED, props.row.id),
                            icon: <Add/>,
                            hoverText: Constants.ADVANCED,
                        },
                        {
                            id: `associations_${ props.row.associationId }_logs_button`,
                            onClick: () => onIconClick(Constants.ACTIVITY, props.row.id),
                            icon: <Article/>,
                            hoverText: Constants.ACTIVITY,
                        },
                        {
                            id: `associations_${ props.row.associationId }_edit_button`,
                            onClick: () => setEditOpen(true),
                            icon: <Edit/>,
                            hoverText: Constants.EDIT,
                        },
                        {
                            id: `associations_${ props.row.associationId }_delete_button`,
                            onClick: () => setDeleteOpen(true),
                            icon: <Delete/>,
                            hoverText: Constants.END_ASSOCIATION,
                        },
                    ]}
                    xs={largeScreen ? 2.25 : 2.75}
                    key={`${ props.row.associationId }_associations_actions_cell`}
                    id={`associations_${ replaceSpaces(props.row.structure) }_actions`}
                />
            }
            {props.row.structure === Constants.AUTOMATION_HUB &&
                <DisplayGridActions
                    buttons={[
                        {
                            id: `associations_${ replaceSpaces(props.row.structure) }_delete_button`,
                            onClick: () => setDeleteOpen(true),
                            icon: <Delete/>,
                            hoverText: Constants.DELETE,
                        },
                    ]}
                    xs={largeScreen ? 2.25 : 2.75}
                    key={`${ props.row.id }_associations_actions_cell`}
                    id={`associations_${ replaceSpaces(props.row.structure) }_actions`}
                />
            }
            {deleteOpen && props.row.structure !== Constants.AUTOMATION_HUB ? (
                <DeleteAssociationModal
                    id={`${ props.row.structId }_delete_confirmation_modal`}
                    open
                    close={() => setDeleteOpen(false)}
                    submit={onDelete}
                    structName={props.row.structure}
                />
            ) :  deleteOpen && props.row.structure === Constants.AUTOMATION_HUB ? (
                <DeleteStructureModal
                    id={`${ props.row.structId }_delete_confirmation_modal`}
                    open
                    close={() => setDeleteOpen(false)}
                    submit={onDelete}
                    structName={props.row.structure}
                />
            ) : null }
            {editBinOpen && props.row.structure !== Constants.AUTOMATION_HUB && (
                <BinSetupModal
                    open
                    close={() => setEditOpen(false)}
                    onSubmit={onEditBinSubmit}
                    grainBinData={grainBinData}
                    masterSerials={props.masterSerials}
                    automationMasterSerials={props.automationMasterSerials}
                    structureId={props.row.id}
                    disableAutomation={disableAutomation}
                    siteSerials={props.siteSerials}
                />
            )}
            {editBagOpen && props.row.structure !== Constants.AUTOMATION_HUB && (
                <BagSetupModal
                    open
                    close={() => setEditOpen(false)}
                    onSubmit={onEditBagSubmit}
                    grainBagData={grainBagData}
                    masterSerials={props.masterSerials}
                    automationMasterSerials={props.automationMasterSerials}
                    structureId={props.row.id}
                    disableAutomation={disableAutomation}
                    siteSerials={props.siteSerials}
                />
            )}
            {editBuildingOpen && props.row.structure !== Constants.AUTOMATION_HUB && (
                <BuildingSetupModal
                    open
                    close={() => setEditOpen(false)}
                    onSubmit={onEditBuildingSubmit}
                    buildingData={buildingData}
                    masterSerials={props.masterSerials}
                    automationMasterSerials={props.automationMasterSerials}
                    structureId={props.row.id}
                    disableAutomation={disableAutomation}
                    siteSerials={props.siteSerials}
                />
            )}
        </DisplayGridContent>
    );
});

export default AssociationsTableRow;
