/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {DataGridPro, GridCellParams, GridColumns, GridDensity, GridRowsProp} from '@mui/x-data-grid-pro';

interface Props {
    rows: GridRowsProp,
    columns: GridColumns,
    getRowId?: any,
    rowHeight?: number
    density?: GridDensity,
    getCellClassName?: (params: GridCellParams<any, any, any>) => string,
    headerHeight?: number,
}

const StyledDataGridPro = (props: Props) => (
    <DataGridPro
        {...props}
        sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
                textOverflow: 'clip',
                whiteSpace: 'normal',
                lineHeight: 1,
            },
            '& .MuiDataGrid-cellContent': {
                textOverflow: 'clip',
                whiteSpace: 'normal',
                lineHeight: 1,
            },
        }}
        density={props.density ? props.density : 'compact'}
    />
);

export default StyledDataGridPro;
