/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import Button from '@mui/material/Button';
import {observer} from 'mobx-react-lite';
import getTestID from '../../../helper/functions/getTestID';
import BinsenseStore from '../../../stores/BinsenseStore';
import { ButtonColors } from '../../../types/components/ApiTypes';

const Root = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});

interface Props {
    id: string,
    onClick: () => void,
    text?: string,
    disabled?: boolean,
    color?: ButtonColors,
    variant?: 'contained',
}

const CancelButton = observer((props: Props) => (
    <Root>
        <Button
            {...getTestID(`${ props.id }_cancel_button`)}
            sx={{marginRight: '16px'}}
            onClick={props.onClick}
            disabled={props.disabled || BinsenseStore.awaitingApi}
            variant={props.variant}
            color = {props.color == null ? 'secondary' : props.color}
        >
            {props.text ? props.text : 'Cancel'}
        </Button>
    </Root>
));

export default CancelButton;
