export default {
    'customerUsersData': [
        {
            id: 0,
            email: 'operations.intragrain.com',
            role: 'Admin',
            status: 'Online',
        },
        {
            id: 1,
            email: 'a@test.calian.com',
            role: 'Admin',
            status: 'Offline',
        },
    ],
};
