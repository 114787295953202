/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper, styled} from '@mui/material';
import DonutChart from '../../common/charts/DonutChart';
import {useMemo} from 'react';
import BinsenseStore from '../../../stores/BinsenseStore';
import {observer} from 'mobx-react-lite';
import getIndex from '../../../helper/functions/getIndex';
import WebStore from '../../../stores/WebStore';
import DashboardSkeleton from '../../common/DashboardSkeleton';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const GrainSummaryPaper = observer(() => {
    const siteIndex = useMemo(() => getIndex(WebStore.selectedSiteId, BinsenseStore.inventoryTableData),
        [ BinsenseStore.inventoryTableData, WebStore.selectedSiteId ]);
    const site = useMemo(() => BinsenseStore.inventoryTableData[siteIndex],
        [ BinsenseStore.inventoryTableData, siteIndex ]);

    const grainLabels = useMemo(() => {
        const grains: string[] = [];
        if (siteIndex >= 0) {
            site.structures.forEach(structure => {
                if (!grains.includes(structure.commodity) && structure.bushels !== 0) {
                    grains.push(structure.commodity);
                }
            });
        }
        return grains;
    }, [ site ]);

    const series = useMemo(() => {
        const grainBushels: number[] = [];
        if (siteIndex >= -1) {
            grainLabels.forEach(label => {
                let bushels = 0;
                site.structures.forEach(structure => {
                    if (label === structure.commodity) {
                        bushels += structure.bushels;
                    }
                });
                grainBushels.push(Math.round(bushels));
            });
        }
        return grainBushels;
    }, [ grainLabels, site ]);

    return !BinsenseStore.awaitingUserData ? (
        <Paper
            sx={{
                height: '100%',
                minHeight: '248px',
                marginBottom: '0px',
            }}
        >
            <div>
                <DonutChart
                    title='Grain Summary'
                    series={series}
                    labels={grainLabels}
                />
            </div>
        </Paper>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={600}/>
        </SkeletonRoot>
    );
});

export default GrainSummaryPaper;
