import BinsenseStore from '../../stores/BinsenseStore';
import WebStore from '../../stores/WebStore';
import { AutomationHubs, MarkerData, Site, SiteStructures } from '../../types/components/ApiTypes';

function getCountForDealerView(selectedSiteId?: number, skipCurrentSite?: boolean): number {
    let count: number = 0;

    BinsenseStore.userStructures.forEach((userStructure: SiteStructures) => {
        if (userStructure.id == selectedSiteId && skipCurrentSite) {
            return;
        }

        const hubs: AutomationHubs[] = userStructure.automationHubs;
        count += hubs.length;
    });

    return count;
}

function getCountForCustomerView(selectedSiteId?: number, skipCurrentSite?: boolean): number {
    const sites: Site[] = BinsenseStore.userData.sites;
    let count: number = 0;
    // iterate through user sites
    for (let i = 0; i < sites.length; i++) {
        if ((skipCurrentSite && sites[i].id === selectedSiteId) || !sites[i].live) {
            continue;
        }

        const hubs: AutomationHubs[] = sites[i].automationHubs;
        count += hubs.length;
    }

    return count;
}

export function getAutomationCount(skipCurrentSite?: boolean, user?: string, selectedSite?: number, newSite?: boolean) {
    const selectedSiteId: number = !newSite ? selectedSite ?? WebStore.selectedSiteId : -1;

    if (user) {
        return getCountForDealerView(selectedSiteId, skipCurrentSite);
    } else {
        return getCountForCustomerView(selectedSiteId, skipCurrentSite);
    }
};

export function getAutomationCountByMarkerData(markerData: MarkerData[], user?: string, selectedSite?: number, newSite?: boolean) {
    let count: number = 0;

    markerData.forEach((data: MarkerData) => {
        if (data.display?.automationHubData) {
            count++;
        }
    });

    // get the number of hubs on other sites
    count += getAutomationCount(true, user, selectedSite, newSite);

    return count;
}