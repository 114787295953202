/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import KeyValueTypography from '../../../common/typography/KeyValueTypography';
import Constants from '../../../../helper/Constants';
import DeviceTypePanel from './DeviceTypePanel';
import {InputFieldProps} from '../../../../types/components/ApiTypes';
import getTestID from '../../../../helper/functions/getTestID';

interface Props {
    deviceType: InputFieldProps,
    currentDeviceType: string,
    disableRemote?: boolean,
}

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '8px',
    width: '100%',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '32px',
    width: '100%',
});

const SelectDeviceTypeStep = observer((props: Props) => {
    const renderBinTypeOption = (label: string) => (
        <DeviceTypePanel
            label={label}
            selectedDevice={props.deviceType.value}
            // @ts-ignore
            setSelectedDevice={props.deviceType.setValue}
            // disable master selection if we are editing a remote structure
            // disable remote selection if we are editing a master structure
            disabled={
                (label.toLowerCase() === Constants.MASTER.toLowerCase() &&
                props.currentDeviceType.toLowerCase() === Constants.REMOTE.toLowerCase()) ||
                (label.toLowerCase() === Constants.REMOTE.toLowerCase() &&
                props.currentDeviceType.toLowerCase() === Constants.MASTER.toLowerCase())
            }
        />
    );

    return (
        <Root>
            <KeyValueTypography
                label="STEP 3:"
                value={Constants.SELECT_DEVICE_TYPE}
            />
            <Row>
                {renderBinTypeOption(Constants.MASTER)}
                {!props.disableRemote && renderBinTypeOption(Constants.REMOTE)}
            </Row>

            {props.deviceType.error !== '' && (
                <Typography
                    {...getTestID('select_device_type_error')}
                    color='error'
                >
                    {props.deviceType.error}
                </Typography>
            )}
        </Root>
    );
});

export default SelectDeviceTypeStep;