/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {BuildingData, GrainBagData, GrainBinData, GrainPileData} from '../../types/components/ApiTypes';
import Constants from '../Constants';

const getSoloUnitsFromStructureData = (data: GrainBinData | GrainBagData | GrainPileData | BuildingData) => {
    if (data.device === Constants.SOLO.toLowerCase()) {
        return data.sn.map(sn => ({ serial: sn }));
    }
    return [];
};

export default getSoloUnitsFromStructureData;
