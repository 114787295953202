/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Button, styled} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';
import BinsenseStore from '../../../stores/BinsenseStore';
import {observer} from 'mobx-react-lite';

const Root = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
});

interface Props {
    id: string,
    text: string,
    onClick: () => void;
    disabled?: boolean;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
}

const SubmitButton = observer((props: Props) => (
    <Root>
        <Button
            {...getTestID(props.id)}
            color={props.color == null ? 'primary' : props.color}
            variant="contained"
            onClick={props.onClick}
            disabled={props.disabled || BinsenseStore.awaitingApi || BinsenseStore.awaitingUserData}
        >
            {props.text}
        </Button>
    </Root>
));

export default SubmitButton;
