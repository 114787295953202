/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Bin, Building, GrainBag, Pile, Site} from '../../types/components/ApiTypes';

const getAutomationDevices = (structure: Bin | GrainBag | Building | Pile) => ({
    id: structure.id,
    structure: structure.name,
    remoteUnit: structure.hardware.remoteUnits.length > 0,
    cables: structure.hardware.cables.length > 0,
    fanController: structure.hardware.fans.length > 0,
    plenumSensor: structure.status.plenumTemp != null || structure.status.plenumRh != null,
    sortOrder: structure.sortOrder,
});

const getAutomationDevicesData = (site: Site) => {
    let arr: any[] = [];
    if (site.automationHubs != null && site.automationHubs.length > 0) {
        arr = [
            ...site.bins.map(bin => getAutomationDevices(bin)),
            ...site.grainBags.map(grainBag => getAutomationDevices(grainBag)),
            ...site.buildings.map(building => getAutomationDevices(building)),
            // ...site.piles.map(pile => getAutomationDevices(pile)),
        ];
    }

    let sortIndexAvailable = false;
    arr.forEach(obj => {
        if (obj.sortOrder > 0) {
            sortIndexAvailable = true;
        }
    });

    if (sortIndexAvailable) {
        return arr.sort((a, b) => a.sortOrder - b.sortOrder);
    } else {
        return arr;
    }
};

export default getAutomationDevicesData;
