import callPostAPI from '../../callPostAPI';
import updateData from '../../../helper/functions/updateData';

const postGrainBags = (enqueueSnackbar: any, siteId: number, body: any[]) => {
    callPostAPI(`structure/grainbags/${ siteId }`,
        body,
        (response) => {
            console.log(response);
            updateData(enqueueSnackbar);
        },
        enqueueSnackbar,
        'Grain Bags added successfully'
    );
};

export default postGrainBags;