/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import MyCustomersMap from '../myCustomers/MyCustomersMap';
import MyCustomersTable from '../myCustomers/MyCustomersTable';
import SearchUserFilter from './SearchUserFilter';
import {useMemo, useState} from 'react';
import BinsenseStore from '../../../stores/BinsenseStore';
import WebStore from '../../../stores/WebStore';
import {observer} from 'mobx-react-lite';

const MyCustomersMainPage = observer(() => {
    const [ user, setUser ] = useState<string | null>(null);

    const filteredCustomers = useMemo(() => {
        const customers = BinsenseStore.dealerUserData.customers;
        if (user !== null) {
            return customers.filter(c => c.account?.accountEmail ? c.account.accountEmail === user : false);
        }
        return customers;
        //return customers.length <= 10 ? customers : [];
    }, [ BinsenseStore.dealerUserData.customers, user ]);

    return (
        <div>
            <div style={{ width: '350px' }}>
                <SearchUserFilter
                    id="my_customers_search"
                    user={{
                        value: user,
                        setValue: setUser,
                    }}
                    userInput={{
                        value: WebStore.myCustomersSearch,
                        setValue: (newValue: string) => WebStore.setMyCustomersSearch(newValue, true),
                    }}
                />
            </div>
            <MyCustomersMap
                customers={filteredCustomers}
            />
            <MyCustomersTable
                customers={user ? filteredCustomers : BinsenseStore.dealerUserData.customers}
            />
        </div>
    );
});

export default MyCustomersMainPage;
