import callPostAPI from '../../callPostAPI';
import getServerCommands from '../get/getServerCommands';

const postServerCommand = (enqueueSnackbar: any, serial: string, body: any, siteId?: number) => {
    callPostAPI(`servercommands/${ serial }`,
        body,
        (response) => {
            console.log(response);
            if (siteId) {
                getServerCommands(enqueueSnackbar, siteId, 10, 0 * 10);
            }
        },
        enqueueSnackbar,
        'Server command added successfully'
    );
};

export default postServerCommand;