/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Grid} from '@mui/material';
import ContentPanel from '../../common/ContentPanel';
import WelcomeTile from '../dashboard/WelcomeTile';
import GrainSummaryPaper from '../dashboard/GrainSummaryPaper';
import WebStore from '../../../stores/WebStore';
import Carousel from '../../common/carousel/Carousel';
import SystemStatusPaper from '../dashboard/SystemStatusPaper';
import DashboardStatsPaper from '../dashboard/DashboardStatsPaper';
import {useMemo} from 'react';
import BinsenseStore from '../../../stores/BinsenseStore';
import getSelectedSiteIndex from '../../../helper/functions/getSelectedSiteIndex';
import DashboardWeatherPaper from '../dashboard/DashboardWeatherPaper';
import {observer} from 'mobx-react-lite';
import siteHasAutomationHub from '../../../helper/functions/siteHasAutomationHub';

const Dashboard = observer(() => {
    const data = useMemo(() => BinsenseStore.userData, [ BinsenseStore.userData ]);
    const siteIndex = useMemo(getSelectedSiteIndex, [ WebStore.selectedSiteId, data ]);

    const runningFans = useMemo(() => data.totalFansRunning, [ data, siteIndex ]);
    const fansDifference = useMemo(() => data.runningFansChange > 0 ? `+${ data.runningFansChange }`
        : String(data.runningFansChange), [ data ]);
    const site = useMemo(() => data.sites[siteIndex] ? data.sites[siteIndex] : null, [ data, siteIndex ]);

    return (
        <ContentPanel>
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                >
                    <WelcomeTile/>
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <SystemStatusPaper/>
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <DashboardStatsPaper
                        label='Number of Fans Running'
                        difference={fansDifference}
                        value={`${ runningFans } ${ runningFans === 1 ? 'fan' : 'fans' }`}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <Carousel/>
                </Grid>
                {site !== null && siteHasAutomationHub(site) && (
                    <Grid
                        item
                        xs={6}
                    >
                        <DashboardWeatherPaper/>
                    </Grid>
                )}
                <Grid
                    item
                    xs={6}
                >
                    <GrainSummaryPaper/>
                </Grid>
            </Grid>
        </ContentPanel>
    );
});

export default Dashboard;
