/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Bin, Building, GrainBag} from '../../types/components/ApiTypes';

const getFanAssociationsData = (structure: Bin | GrainBag | Building) => structure.hardware.fans.map(fan => ({
    id: fan.id,
    fanId: fan.id,
    fanLocalId: fan.localId,
    selected: false,
}));

export default getFanAssociationsData;
