/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import GridSelectFieldInput from '../input/GridSelectFieldInput';
import GridTextFieldInput from '../input/GridTextFieldInput';
import {Grid} from '@mui/material';
import Constants from '../../../helper/Constants';
import {InputFieldProps, SelectOptions} from '../../../types/components/ApiTypes';
import getCountryList from '../../../helper/functions/getCountryList';
import getProvinceList from '../../../helper/functions/getProvinceList';
import getDealerListForNewCustomer from '../../../controllers/apiCalls/get/getDealerListForNewCustomer';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';

interface Props {
    isCustomer: boolean,
    firstNameField: InputFieldProps,
    lastNameField: InputFieldProps,
    emailField: InputFieldProps,
    phoneNumberField: InputFieldProps,
    passwordField: InputFieldProps,
    passwordConfirmationField: InputFieldProps,
    countryField: InputFieldProps,
    provinceField: InputFieldProps,
    cityField: InputFieldProps,
    addressField: InputFieldProps,
    postalCodeField: InputFieldProps,
    dealerField: InputFieldProps,
}

const NewUserForm = (props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ dealerOptions, setDealerOptions ] = useState<SelectOptions[]>([]);

    useEffect(() => {
        if (props.isCustomer) {
            getDealerListForNewCustomer(enqueueSnackbar, setDealerOptions);
        }
    }, []);

    const updateCountry = (country: string) => {
        if (props.provinceField.setValue && props.countryField.setValue) {
            props.provinceField.setValue('');
            props.countryField.setValue(country);
        }
    };

    return (
        <Grid
            container
            spacing={2}
            style={{
                paddingTop: '16px',
            }}
        >
            <GridTextFieldInput
                id="new_user_first_name_input"
                label={Constants.FIRST_NAME}
                field={props.firstNameField}
                textFieldProps={{
                    inputProps: {
                        maxLength: 100,
                    },
                }}
            />
            <GridTextFieldInput
                id="new_user_last_name_input"
                label={Constants.LAST_NAME}
                field={props.lastNameField}
                textFieldProps={{
                    inputProps: {
                        maxLength: 100,
                    },
                }}
            />
            <GridTextFieldInput
                id="new_user_email_input"
                label={Constants.EMAIL}
                field={props.emailField}
                textFieldProps={{
                    inputProps: {
                        maxLength: 60,
                    },
                }}
            />
            <GridTextFieldInput
                id="new_user_phone_number_input"
                label={Constants.MOBILE_NUMBER}
                field={props.phoneNumberField}
                textFieldProps={{
                    inputProps: {
                        maxLength: 50,
                    },
                }}
            />
            {props.isCustomer && (
                <GridTextFieldInput
                    id="new_user_password_input"
                    label={Constants.PASSWORD}
                    field={props.passwordField}
                    type="password"
                    textFieldProps={{
                        inputProps: {
                            maxLength: 100,
                        },
                    }}
                />
            )}
            {props.isCustomer && (
                <GridTextFieldInput
                    id="new_user_password_confirmation_input"
                    label={Constants.PASSWORD_CONFIRMATION}
                    field={props.passwordConfirmationField}
                    type="password"
                    textFieldProps={{
                        inputProps: {
                            maxLength: 100,
                        },
                    }}
                />
            )}
            <GridSelectFieldInput
                id="new_user_country_input"
                label={Constants.COUNTRY}
                field={{
                    ...props.countryField,
                    setValue: updateCountry,
                }}
                options={getCountryList()}
            />
            <GridSelectFieldInput
                id="new_user_province_input"
                label={Constants.PROVINCE_OR_STATE}
                field={props.provinceField}
                options={getProvinceList(props.countryField.value)}
            />
            <GridTextFieldInput
                id="new_user_city_input"
                label={Constants.TOWN_OR_CITY}
                field={props.cityField}
                textFieldProps={{
                    inputProps: {
                        maxLength: 100,
                    },
                }}
            />
            <GridTextFieldInput
                id="new_user_address_input"
                label={Constants.ADDRESS}
                field={props.addressField}
                textFieldProps={{
                    inputProps: {
                        maxLength: 100,
                    },
                }}
            />
            <GridTextFieldInput
                id="new_user_postal_code_input"
                label={Constants.POSTAL_CODE_OR_ZIP_CODE}
                field={props.postalCodeField}
                textFieldProps={{
                    inputProps: {
                        maxLength: 100,
                    },
                }}
            />
            {props.isCustomer && (
                <GridSelectFieldInput
                    id="new_user_dealer_input"
                    label={Constants.DEALER}
                    field={props.dealerField}
                    options={dealerOptions}
                />
            )}
        </Grid>
    );
};

export default NewUserForm;
