import BinsenseStore from '../../../stores/BinsenseStore';
import callAPI from '../../callAPI';

const getDealerNotifications = (enqueueSnackbar: any, id: number) => {
    callAPI(`getDealerNotifications/${ id }`,
        (response) => {
            BinsenseStore.setDealerNotifications(response);
        },
        enqueueSnackbar
    );
};

export default getDealerNotifications;
