/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Cable} from '../../types/components/ApiTypes';

const getBinViewAvg = (rowIndex: number, cables: Cable[], temp: boolean = false) => {
    let total = 0;
    let count = 0;
    const key = temp ? 'temperature' : 'moistureContent';
    cables.forEach(cable => {
        if (cable.sensors.length > rowIndex && cable.sensors.slice().reverse()[rowIndex][key]) {
            total += cable.sensors.slice().reverse()[rowIndex][key];
            count++;
        }
    });
    return (count != 0 ? total / count : 0).toFixed(1);
};

export default getBinViewAvg;
