import callPostAPI from '../../callPostAPI';
import updateData from '../../../helper/functions/updateData';

const postDealerUser = (enqueueSnackbar: any, body: any) => {
    callPostAPI('dealerUser',
        body,
        (response) => {
            console.log(response);
            updateData(enqueueSnackbar);
        },
        enqueueSnackbar,
        'Dealer user added successfully'
    );
};

export default postDealerUser;