/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import MyCustomersTableRow from './MyCustomersTableRow';
import DisplayGridSortableTableHeader from '../../common/displayTable/DisplayGridSortableTableHeader';
import Constants from '../../../helper/Constants';
import {useEffect, useMemo, useState} from 'react';
import BinsenseStore from '../../../stores/BinsenseStore';
import {observer} from 'mobx-react-lite';
import AccordionTableHeader from '../../common/displayTable/AccordionTableHeader';
import {Customer} from '../../../types/components/ApiTypes';
import getMyCustomersTableData from '../../../helper/functions/getMyCustomersTableData';
import PaginationAccordionTable from '../../common/displayTable/PaginationAccordionTable';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import useWindowSize from '../../../helper/hooks/useWindowSize';

interface Props {
    customers: Customer[],
}

const MyCustomersTable = observer((props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const rows = useMemo(() => BinsenseStore.customerSiteData, [ BinsenseStore.customerSiteData ]);

    useEffect(() => BinsenseStore.setCustomerSiteData(getMyCustomersTableData(props.customers)), [ props.customers ]);

    const getHeader = (label: string, key: string, xs: number) => (
        <DisplayGridSortableTableHeader
            id={`${ key }_header`}
            label={label}
            xs={xs}
            data={rows}
            setData={BinsenseStore.setCustomerSiteData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderRow = (row: any) => (
        <MyCustomersTableRow
            data={row}
            key={`${ row.id }_row`}
        />
    );

    return !BinsenseStore.awaitingUserData ? (
        <PaginationAccordionTable
            rows={rows}
            renderRow={renderRow}
            style={{ marginTop: '32px' }}
        >
            <AccordionTableHeader>
                {getHeader(Constants.USER, 'user', largeScreen ? 3.5 : 3)}
                {getHeader(Constants.NAME, 'name', 2.5)}
                {getHeader(Constants.CITY_TOWN, 'city', 3)}
                <DisplayGridLabel
                    id='action_header'
                    label="Actions"
                    xs={largeScreen ? 3 : 3.5}
                    header
                />
            </AccordionTableHeader>
        </PaginationAccordionTable>
    ) : (
        <DashboardSkeleton
            height={600}
            style={{
                marginTop: '24px',
            }}
        />
    );
});

export default MyCustomersTable;
