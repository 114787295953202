/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import DealerReportsTable from './DealerReportsTable';
import Constants from '../../../helper/Constants';
import {useEffect, useMemo, useState} from 'react';
import BinsenseStore from '../../../stores/BinsenseStore';
import ReportsTableHeader from '../../customer/reports/ReportsTableHeader';
import getLostDeviceReport from '../../../controllers/apiCalls/get/getLostDeviceReport';
import {useSnackbar} from 'notistack';
import getHighTempertureReport from '../../../controllers/apiCalls/get/getHighTempertureReport';

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const DealerReportsPage = observer(() => {
     const {enqueueSnackbar} = useSnackbar();

    const [ reportType, setReportType ] = useState('Lost Devices');
    const [ reportTypeError, setReportTypeError ] = useState('');

    const rows = useMemo(() => reportType === 'Lost Devices' ? BinsenseStore.lostDevicesData
            : BinsenseStore.highTemperatureData,
        [ reportType, BinsenseStore.lostDevicesData, BinsenseStore.dealerReportsData ]);
    const fileName = useMemo(() => `${ reportType.replace(/ /g, '_') }_table`, [ reportType ]);

     useEffect(() => {
         getLostDeviceReport(enqueueSnackbar);
         getHighTempertureReport(enqueueSnackbar);
     }, []);

    const columns = [
        {
            field: 'user',
            headerName: Constants.USER,
        },
        {
            field: 'name',
            headerName: Constants.NAME,
        },
        {
            field: 'structure',
            headerName: Constants.STRUCTURE,
        },
        {
            field: 'device',
            headerName: Constants.DEVICE,
        },
        {
            field: reportType === 'Lost Devices' ? 'lastCallIn' : 'maxTemp',
            headerName: reportType === 'Lost Devices' ? Constants.LAST_CALL_IN : Constants.MAX_TEMP,
        },
    ];

    return (
        <Root>
            <ReportsTableHeader
                selectField={{
                    value: reportType,
                    setValue: setReportType,
                    error: reportTypeError,
                    setError: setReportTypeError,
                }}
                options={[
                    {
                        id: 'lost_devices_option',
                        title: 'Lost Devices',
                    },
                    {
                        id: 'high_temperature_option',
                        title: 'High Temperature',
                    },
                ]}
                rows={rows}
                columns={columns}
                noExport={reportType === 'Communication Summary'}
                fileName={fileName}
            />
            <DealerReportsTable
                rows={rows}
                reportType={reportType}
            />
        </Root>
    );
});

export default DealerReportsPage;
