import BinsenseStore from '../../../stores/BinsenseStore';
import callAPI from '../../callAPI';

const getNotificationSettings = (enqueueSnackbar: any, userId: number) => {
    // call notification settings GET endpoint, pass user id
    callAPI(`notificationSetting/${ userId }`,
        (response) => {
            // update user settings
            BinsenseStore.setDealerCustomerUserSettings(response);
        },
        enqueueSnackbar
    );
};

export default getNotificationSettings;