/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import { observer } from 'mobx-react-lite';
import ContentPanel from '../../common/ContentPanel';
import TableButton from '../../common/buttons/TableButton';
import { useState } from 'react';
import DealerNotificationTable from './DealerNotificationTable';
import AddDealerNotificationModal from './AddDealerNotificationModal';
import TitlePaper from '../../common/TitlePaper';

const DealerNotifications = observer(() => {
    const [ addDealerNotification, setAddDealerNotification ] = useState(false);
    return (
        <ContentPanel>
            <TitlePaper
                title='Notifications'
                rightTitle={(
                    <TableButton
                        id='add_contact_button'
                        label='Add Contact'
                        onClick={() => setAddDealerNotification(true)}
                        noIcon
                    />
                )}
            >
                <DealerNotificationTable/>
                {addDealerNotification && (
                    <AddDealerNotificationModal
                        open
                        close={() => setAddDealerNotification(false)}
                    />
                )}
            </TitlePaper>
        </ContentPanel>
    );
});

export default DealerNotifications;
