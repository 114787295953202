/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */
import {Fan} from '../../types/components/ApiTypes';
import Constants from '../Constants';

const getBinFanStatus = (fans: Fan[]) => {

    if (fans.length === 0) {
        return 'No Fans';
    }

    const statusList: String[] = [];

    fans.forEach(fan => {
        switch (fan.status.status) {
            case 'RUNNING':
                statusList.push(Constants.FAN_DISPLAY_STATUS_RUNNING);
                break;
            case 'MANUAL_ON':
                statusList.push(Constants.FAN_DISPLAY_STATUS_RUNNING_MANUAL);
                break;
            default:
                statusList.push(Constants.FAN_DISPLAY_STATUS_OFF);
                break;
        }
    });

    return (statusList.includes(Constants.FAN_DISPLAY_STATUS_RUNNING)
                ? Constants.FAN_DISPLAY_STATUS_RUNNING
                : statusList.includes(Constants.FAN_DISPLAY_STATUS_RUNNING_MANUAL)
                ? Constants.FAN_DISPLAY_STATUS_RUNNING_MANUAL
                : Constants.FAN_DISPLAY_STATUS_OFF);
};

export default getBinFanStatus;
