/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import Constants from '../../../helper/Constants';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../common/displayTable/DisplayGridContent';
import DisplayGridActions from '../../common/displayTable/DisplayGridActions';
import {Delete, Edit} from '@mui/icons-material';
import getTimeSince from '../../../helper/functions/getTimeSince';

interface Props {
    row: any,
    onEditClick: (id: number, note: string, structureId: number) => void,
    onDeleteClick: (id: number, note: string) => void,
}

const NotesTableRow = observer((props: Props) => {
    const renderGridLabel = (id: string, label: string, xs: number, lengthLimit?: number) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={xs}
            lengthLimit={lengthLimit}
        />
    );

    return (
        <DisplayGridContent key={`${ props.row.id }_note`}>
            {renderGridLabel(`${ props.row.id }_date`, getTimeSince(props.row.date), 2)}
            {renderGridLabel(`${ props.row.id }_structure`, props.row.structure, 3, 16)}
            {renderGridLabel(`${ props.row.id }_note`, props.row.note, 5)}
            <DisplayGridActions
                buttons={[
                    {
                        id: `${ props.row.id }_note_edit_button`,
                        onClick: () => props.onEditClick(props.row.id, props.row.note, props.row.structureId),
                        icon: <Edit/>,
                        hoverText: Constants.EDIT,
                    },
                    {
                        id: `${ props.row.id }_note_delete_button`,
                        onClick: () => props.onDeleteClick(props.row.id, props.row.note),
                        icon: <Delete/>,
                        hoverText: Constants.DELETE,
                    },
                ]}
                xs={2}
                key={`${ props.row.id }_user_actions_cell`}
                id={props.row.id}
            />
        </DisplayGridContent>
    );
});

export default NotesTableRow;
