/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {SiteStructures} from '../../types/components/ApiTypes';
import WebStore from '../../stores/WebStore';
import Constants from '../Constants';
import BinsenseStore from '../../stores/BinsenseStore';
import getIndex from './getIndex';

const updateSite = (siteStructures: SiteStructures) => {
    if (WebStore.selectedView == Constants.CUSTOMER) {
        const clone = JSON.parse(JSON.stringify(BinsenseStore.userData));
        const index = getIndex(siteStructures.id, clone.sites);
        if (index !== -1) {
            clone.sites[index] = {
                ...clone.sites[index],
                ...siteStructures,
            };
            BinsenseStore.setUserData(clone);
        }
    }
    if (WebStore.selectedView === Constants.DEALER && BinsenseStore.siteStructures.id === siteStructures.id) {
        BinsenseStore.setSiteStructures(siteStructures);
    }
};

export default updateSite;
