/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import {Brightness4, Brightness7, BugReport, TipsAndUpdates} from '@mui/icons-material';
import {observer} from 'mobx-react-lite';
import WebStore from '../../stores/WebStore';
import UserButton from './UserButton';
import HoverTextButton from '../common/buttons/HoverTextButton';
import TooltipStore from '../../stores/TooltipStore';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

interface Props {
    disableButtons?: boolean,
}

const BannerButtons = observer((props: Props) => (
    <Root>
        <HoverTextButton
            id="tooltip_button"
            icon={<TipsAndUpdates/>}
            hoverText={TooltipStore.beginTooltips ? 'Turn Off Tool Tips' : 'Turn On Tool Tips'}
            onClick={() =>{
                TooltipStore.setBeginTooltips(!TooltipStore.beginTooltips, true);
                TooltipStore.setAllMessages(true);
            }}
        />
        <HoverTextButton
            id="light_dark_mode_button"
            icon={WebStore.lightMode ? <Brightness4/> : <Brightness7/>}
            hoverText="Switch Viewing Mode"
            onClick={WebStore.setLightMode}
        />
        <HoverTextButton
            id="report_bug_button"
            icon={<BugReport/>}
            hoverText="Report Bug/Feedback"
            onClick={() => window.open('https://www.binsense.com/submit-feature-request-or-report-a-bug/', '_blank')}
        />
        {/*<NotificationsButton*/}
        {/*    disableButtons={Boolean(props.disableButtons)}*/}
        {/*/>*/}
        <UserButton
            disableButtons={props.disableButtons}
        />
    </Root>
));

export default BannerButtons;
