/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import BinsenseStore from '../../stores/BinsenseStore';
import WebStore from '../../stores/WebStore';
import Constants from '../Constants';
import getSelectedSiteIndex from './getSelectedSiteIndex';

const getAllStructNames = (markerStructNames?: string[], siteId?: number) => {
    const structNames: string[] = [];
    const siteStructNames = WebStore.selectedView === Constants.CUSTOMER
            ? (siteId !== null && siteId === getSelectedSiteIndex()
            ? BinsenseStore.userData.sites[getSelectedSiteIndex()]?.structNames : [])
            : BinsenseStore.siteStructures.structNames;

    siteStructNames?.forEach(name => {
        structNames?.push(name);
    });

    markerStructNames?.forEach(name => {
        if (!structNames.includes(name)) {
            structNames.push(name);
        }
    });

    return structNames;
};

export default getAllStructNames;
