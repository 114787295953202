/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import React from 'react';
import {styled, Paper, Skeleton} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {useSnackbar} from 'notistack';
import getPortalForm from '../../../controllers/apiCalls/get/getPortalForm';
import { Customer } from '../../../types/components/ApiTypes';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

interface Props {
    user?: Customer | null | undefined,
}

const ManageBillingPanel = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ loading, setLoading ] = React.useState(true);
    const [ html, setHtml ] = React.useState('');

    React.useEffect(() => {
        getPortalForm(enqueueSnackbar, setLoading, setHtml, props.user?.id);
    }, []);

    if (loading) {
        return (
            <Skeleton
                variant='rectangular'
                animation='wave'
                sx={{
                    width:'100%',
                    maxWidth: 'none',
                    height: '250px',
                    borderRadius: '12px',
                }}
            />
        );
    }

    return (
        <Paper>
            <Root>
                <div
                    className="content"
                    dangerouslySetInnerHTML={{__html: html}}
                />
            </Root>
        </Paper>
    );
});

export default ManageBillingPanel;
