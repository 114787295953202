/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import {useEffect, useMemo, useState} from 'react';
import DisplayGridHeaderRow from '../../common/displayTable/DisplayGridHeaderRow';
import BinsenseStore from '../../../stores/BinsenseStore';
import DisplayGridSortableTableHeader from '../../common/displayTable/DisplayGridSortableTableHeader';
import Constants from '../../../helper/Constants';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import DealerReportsTableRow from './DealerReportsTableRow';
import PaginationTable from '../../common/displayTable/PaginationTable';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import {styled} from '@mui/material';
import useWindowSize from '../../../helper/hooks/useWindowSize';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

interface Props {
    rows: any[],
    reportType: string,
}

const DealerReportsTable = observer((props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const [ tableData, setTableData ] = useState(props.rows);

    useEffect(()=>{
        setTableData(props.rows.map(x => Object.assign({}, x)));
    }, [ props.rows ]);

    const renderHeader = (id: string, label: string, key: string, xs: number) => (
        <DisplayGridSortableTableHeader
            id={id}
            label={label}
            xs={xs}
            data={tableData}
            setData={setTableData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderRow = (row: any) => (
        <DealerReportsTableRow
            row={row}
            reportType={props.reportType}
            key={`${ row.id }_row`}
        />
    );

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <div style={{ width: '100%' }}>
            <PaginationTable
                rows={tableData}
                renderRow={renderRow}
            >
                <DisplayGridHeaderRow sx={{marginTop: '0px'}}>
                    {renderHeader('user', Constants.USER, 'user', largeScreen ? 2.5 : 2.25)}
                    {renderHeader('name', Constants.NAME, 'name', largeScreen ? 2.5 : 2)}
                    {renderHeader('structure', Constants.STRUCTURE, 'structure', 2)}
                    {renderHeader('device', Constants.DEVICE, 'device', 2.5)}
                    {props.reportType === 'Lost Devices'
                        ? renderHeader('last_call_in', Constants.LAST_CALL_IN, 'lastCallIn', largeScreen ? 1.5 : 2)
                        : renderHeader('max_temp', Constants.MAX_TEMP, 'maxTemp', largeScreen ? 1.5 : 2)}
                    <DisplayGridLabel
                        id='actions'
                        label={Constants.ACTIONS}
                        xs={largeScreen ? 1 : 1.25}
                        header
                    />
                </DisplayGridHeaderRow>
            </PaginationTable>
        </div>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={600}/>
        </SkeletonRoot>
    );
});

export default DealerReportsTable;
