export default {
    'alertsData': [
        {
            id: 0,
            customer: 'Fish, Alex',
            site: 'Moose Jaw, SK',
            message: 'Temperature has risen by 8C in the past 6 hours',
            bin: 'Bin 3',
            date: new Date(),
            status: 'Urgent',
            grainType: 'Canola',
            read: true,
        },
        {
            id: 1,
            customer: 'Jackson, Halle',
            site: 'Saskatoon, SK',
            message: 'Temperature has reached threshold',
            bin: 'Bin 4',
            date: new Date(),
            status: 'Not Urgent',
            grainType: 'Wheat',
            read: false,
        },
        {
            id: 2,
            customer: 'Fahlman, Landon',
            site: 'Regina, SK',
            message: 'Moisture has risen by 5% in the past 12 hours',
            bin: 'Bin 6',
            date: new Date(),
            status: 'Moderate',
            grainType: 'Peas',
            read: true,
        },
    ],
};
