/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

const getDistancePerPixel = (latitude: number, zoom: number) => {
    // https://wiki.openstreetmap.org/wiki/Zoom_levels function obtained from here
    const equatorialCircumferenceM = 6378137.000;
    const mapboxTileSizePx = 512;
    const feetPerMeter = 3.281;
    const C = 2 * Math.PI * equatorialCircumferenceM;
    const squareTile = C * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoom);
    const mPerPx = squareTile / mapboxTileSizePx;
    return {
        mPerPx,
        ftPerPx: mPerPx * feetPerMeter,
    };
};

export default getDistancePerPixel;
