/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Dispatch, SetStateAction} from 'react';
import DisplayMap from '../../common/map/DisplayMap';
import {DistancePerPixel, MarkerData} from '../../../types/components/ApiTypes';
import cloneDeep from 'lodash/cloneDeep';
import getIndex from '../../../helper/functions/getIndex';
import {observer} from 'mobx-react-lite';

interface Props {
    viewState: {
        longitude: number,
        latitude: number,
        zoom: number,
    },
    setViewState: Dispatch<SetStateAction<{ longitude: number, latitude: number, zoom: number }>>
    markerData: MarkerData[]
    setMarkerData: Dispatch<SetStateAction<MarkerData[]>>
    showSearch?: boolean,
    toggleOnBottom?: boolean,
    distancePerPixel?: DistancePerPixel,
    features?: any,
    onFeaturesDelete?: any,
    onFeaturesUpdate?: any,
    height?: number,
    onMarkerClick?: (id: number) => void,
    siteId?: number,
    onMarkerUpdate?: () => void,
    deletedStructureArray?: any[],
    setDeletedStructureArray?: Dispatch<SetStateAction<number[]>>,
}

const CreateSiteMap = observer((props: Props) => {
    const onMarkerDragEnd = (lngLat: { lng: number, lat: number }, index: number) => {
        props.setMarkerData(prevState => {
            const newState = cloneDeep(prevState);
            newState[index] = {
                ...newState[index],
                longitude: lngLat.lng,
                latitude: lngLat.lat,
            };
            return newState;
        });
        if (props.onMarkerUpdate) {
            props.onMarkerUpdate();
        }
    };

    const onMarkerEdit = (newMarker: MarkerData, index: number) => {
        props.setMarkerData(prevState => {
            const newState = cloneDeep(prevState);
            newState[index] = newMarker;
            return newState;
        });
        if (props.onMarkerUpdate) {
            props.onMarkerUpdate();
        }
    };

    const onMarkerDelete = (id: number) => {
        const clone = cloneDeep(props.markerData);
        const index = getIndex(id, clone);
        clone.splice(index, 1);
        props.setMarkerData(clone);
        if (props.onMarkerUpdate) {
            props.onMarkerUpdate();
        }
    };

    const onMove = (e: any) => {
        props.setViewState(e.viewState);
    };

    return (
        <DisplayMap
            height={props.height ? props.height : 600}
            initialView={props.viewState}
            onMove={onMove}
            markers={props.markerData}
            onMarkerDragEnd={onMarkerDragEnd}
            onMarkerEdit={onMarkerEdit}
            onMarkerDelete={onMarkerDelete}
            showSearch={props.showSearch}
            toggleOnBottom={props.toggleOnBottom}
            distancePerPixel={props.distancePerPixel}
            features={props.features}
            onFeaturesDelete={props.onFeaturesDelete}
            onFeaturesUpdate={props.onFeaturesUpdate}
            onMarkerClick={props.onMarkerClick}
            deletedStructureArray={props.deletedStructureArray}
            setDeletedStructureArray={props.setDeletedStructureArray}
        />
    );
});

export default CreateSiteMap;
