import DisplayGridItem from './DisplayGridItem';
import getTestID from '../../../helper/functions/getTestID';
import {Checkbox, Typography} from '@mui/material';

interface Props {
    xs: number,
    id: string,
    value: boolean,
    onChange: () => void,
    header?: boolean,
}

const DisplayGridCheckBox = (props: Props) => (
    <DisplayGridItem
        xs={props.xs}
        header={props.header}
    >
        {props.header && (
            <Typography fontWeight="bold">
                {props.value ? 'Deselect All' : 'Select All'}
            </Typography>
        )}
        <Checkbox
            {...getTestID(props.id)}
            checked={props.value}
            onClick={props.onChange}
        />
    </DisplayGridItem>
);

export default DisplayGridCheckBox;