/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import NewUserForm from '../common/personalDetails/NewUserForm';
import BillingAddressForm from '../common/personalDetails/BillingAddressForm';
import StyledSwitch from '../common/input/StyledSwitch';
import {InputFieldProps} from '../../types/components/ApiTypes';

interface Props {
    isCustomer: boolean,
    firstName: InputFieldProps,
    lastName: InputFieldProps,
    email: InputFieldProps,
    phoneNumber: InputFieldProps,
    password: InputFieldProps,
    passwordConfirmation: InputFieldProps,
    country: InputFieldProps,
    province: InputFieldProps,
    city: InputFieldProps,
    address: InputFieldProps,
    postalCode: InputFieldProps,
    dealer: InputFieldProps,
    useForBillingAddress: boolean,
    setUseForBillingAddress: (value: boolean) => void,
    billingPostalCode: InputFieldProps,
    billingAddress: InputFieldProps,
    billingCity: InputFieldProps,
    billingProvince: InputFieldProps,
    billingCountry: InputFieldProps,
}

const RegisterNewUserForm = (props: Props) => (
    <div style={{ width: '100%' }}>
        <NewUserForm
            isCustomer={props.isCustomer}
            firstNameField={props.firstName}
            lastNameField={props.lastName}
            emailField={props.email}
            phoneNumberField={props.phoneNumber}
            passwordField={props.password}
            passwordConfirmationField={props.passwordConfirmation}
            countryField={props.country}
            provinceField={props.province}
            cityField={props.city}
            addressField={props.address}
            postalCodeField={props.postalCode}
            dealerField={props.dealer}
        />
        <StyledSwitch
            id="use_for_billing_address_switch"
            description="Use above as billing address"
            value={props.useForBillingAddress}
            setValue={props.setUseForBillingAddress}
        />
        {!props.useForBillingAddress && (
            <BillingAddressForm
                postalCode={props.billingPostalCode}
                address={props.billingAddress}
                town={props.billingCity}
                province={props.billingProvince}
                country={props.billingCountry}
            />
        )}
    </div>
);

export default RegisterNewUserForm;
