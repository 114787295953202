import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';

const getBinLogs = (enqueueSnackbar: any, id: number, startDate: number, endDate: number) => {
    callAPI(`binLogs/${ id }/${ startDate }/${ endDate }`,
        (response) => {
            BinsenseStore.setBinLogsData(response.map((row: any, index: number) => ({
                ...row,
                id: index,
            })));
        },
        enqueueSnackbar
    );
};

export default getBinLogs;