/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import WebStore from '../../stores/WebStore';
import LoginStore from '../../stores/LoginStore';
import getAuthCode from '../../controllers/apiCalls/get/getAuthCode';
import getAPIToken from '../../controllers/apiCalls/get/getAPIToken';

const signIn = async (username: string, password: string, tab: string, enqueueSnackbar: any) => {
    const authSuccessful = await getAuthCode(username, password, tab, enqueueSnackbar);
    if (authSuccessful.success) {
        const tokenSuccessful = await getAPIToken(enqueueSnackbar);
        if (tokenSuccessful.success) {
            WebStore.setSelectedView(tab, true);
            LoginStore.setLoggedIn(true);
            if (LoginStore.wrongUsernameOrPassword) {
                LoginStore.setWrongUsernameOrPassword(false);
            }
            if (LoginStore.wrongPassword) {
                LoginStore.setWrongPassword(false);
            }
        }
    }
    else {
        if (authSuccessful.message === '') {
            LoginStore.setWrongUsernameOrPassword(true);
        }
        if (authSuccessful.message === 'WrongPwd') {
            LoginStore.setWrongPassword(true);
        }
    }
};

export default signIn;
