/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import DisplayGridContent from '../../common/displayTable/DisplayGridContent';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import {useMemo} from 'react';
import getTempUnit from '../../../helper/functions/getTempUnit';
import BinsenseStore from '../../../stores/BinsenseStore';
import AccordionTableRowDetails from '../../common/displayTable/AccordionTableRowDetails';
import { displayTemp } from '../../../helper/functions/functions';
import Constants from '../../../helper/Constants';

interface Props {
    data: any,
}

const InventoryTableRow = (props: Props) => {
    const tempUnit = useMemo(getTempUnit, [ BinsenseStore.userData.settings.preferences ]);

    const getTempLabel = (tempLabel: string) => tempLabel === 'NaN' ? Constants.NA : `${ tempLabel } ${ tempUnit }`;
    const getMoistLabel = (moistLabel: string) => moistLabel === Constants.NA ? moistLabel : `${ moistLabel }%`;

    const getLabel = (id: string, label: string | number, xs: number, lengthLimit?: number) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={xs}
            lengthLimit={lengthLimit}
        />
    );

    const getBushelsPercent = (percentFull : number | null) => percentFull ? `${ percentFull }%` : '0%';

    return (
        <AccordionTableRowDetails>{props.data.structures.map((structure: any) => (
            <DisplayGridContent key={`${ props.data.id }_${ structure.id }_data`}>
                {getLabel(`${ props.data.site }_${ structure.site }_site_cell`, structure.site, 2, 10)}
                {getLabel(`${ props.data.site }_${ structure.site }_commodity_cell`,
                    structure.commodity, 2, 10)}
                {getLabel(`${ props.data.site }_${ structure.site }_bushels_cell`,
                    `${ getBushelsPercent(structure.percentFull) } (${ Math.round(structure.bushels).toLocaleString() } bu)`, 8.0 / 5.0)}
                {getLabel(`${ props.data.site }_${ structure.site }_min_temp_cell`,
                    getTempLabel(displayTemp(tempUnit === 'F', structure.minTemp)), 8.0 / 5.0)}
                {getLabel(`${ props.data.site }_${ structure.site }_max_temp_cell`,
                    getTempLabel(displayTemp(tempUnit === 'F', structure.maxTemp)), 8.0 / 5.0)}
                {getLabel(`${ props.data.site }_${ structure.site }_min_moisture_cell`,
                    getMoistLabel(structure.minMoisture), 8.0 / 5.0)}
                {getLabel(`${ props.data.site }_${ structure.site }_max_moisture_cell`,
                    getMoistLabel(structure.maxMoisture), 8.0 / 5.0)}
            </DisplayGridContent>
        ))}
        </AccordionTableRowDetails>
    );
};

export default InventoryTableRow;
