/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import Constants from '../../../../helper/Constants';
import TextFieldInput from '../../../common/input/TextFieldInput';
import {Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import {cloneDeep} from 'lodash';
import RemoveTextFieldInput from '../../../common/input/RemoveTextFieldInput';
import {InputFieldProps} from '../../../../types/components/ApiTypes';
import SelectFieldInput from '../../../common/input/SelectFieldInput';

const Root = styled('div')({
    width: '100%',
});

interface Props {
    deviceType?: string,
    serial: {
        value: string[],
        setValue: Dispatch<SetStateAction<string[]>>
    },
    associatedMaster?: InputFieldProps,
    automationMasterHub?: boolean,
    automationRemote?: boolean,
    masterSerials?: string[],
}

const EnterSerialInputFields = observer((props: Props) => {
    const [ serialListContainsMaster ] =
        useState<boolean>(!!props.associatedMaster?.value && props.serial.value.includes(props.associatedMaster?.value));

    useEffect(() => {
        if (props.associatedMaster && props.masterSerials && props.masterSerials.length === 1) {
            // @ts-ignore
            props.associatedMaster.setValue(props.masterSerials[0]);
        }
    }, [ props.masterSerials ]);

    const editSerial = (value: string, index: number) => {
        const clone = cloneDeep(props.serial.value);
        clone[props.deviceType?.toLowerCase() === Constants.REMOTE.toLowerCase() &&
            serialListContainsMaster ? index + 1 : index] = value.trim();
        props.serial.setValue(clone);
    };

    const removeSerialField = (index: number) => {
        const clone = cloneDeep(props.serial.value);
        // need to add 1 to index of remote units since the array of serials includes the master
        // but the array of remote units does not
        clone.splice(props.deviceType?.toLowerCase() === Constants.REMOTE.toLowerCase() &&
            serialListContainsMaster ? index + 1 : index, 1);
        props.serial.setValue(clone);
    };

    const getTextFieldLabel = (index: number) => {
        if (props.deviceType || props.automationRemote) {
            return index === 0 && !props.automationRemote
                // @ts-ignore
                // eslint-disable-next-line
                ? (props.deviceType?.toLowerCase() === Constants.SOLO.toLowerCase ? Constants.SOLO : props.deviceType?.charAt(0).toUpperCase() + props.deviceType?.slice(1))
                : Constants.REMOTE;
        }
        return Constants.DEVICE;
    };

    const serialSet = new Set(props.masterSerials);
    const serialList = props.deviceType?.toLowerCase() === Constants.REMOTE.toLowerCase()
        ? props.serial.value.filter((item) => !serialSet.has(item))
        : props.serial.value;

    const renderInputFields = useMemo(() =>  (serialList.length > 1) ? (
        serialList.map((sn, index) => serialSet.has(sn) ? (
            <TextFieldInput
                id="serial_input"
                key={`${ index }_serial_input`}
                label={`${ getTextFieldLabel(0) } ${ Constants.SERIAL_NUMBER }`}
                field={{
                    value: props.serial.value[0],
                    setValue: (value) => editSerial(value, 0),
                }}
            />
        ) : (
            <RemoveTextFieldInput
                id={`${ index }_serial_input`}
                key={`${ index }_serial_input`}
                label={`${ getTextFieldLabel(index) } ${ Constants.SERIAL_NUMBER }`}
                field={{
                    value: sn,
                    setValue: (value) => editSerial(value, index),
                }}
                onSubmit={() => removeSerialField(index)}
            />
        ))) : (
            getTextFieldLabel(0) === 'Master' ? (
                <TextFieldInput
                    id="serial_input"
                    label={`${ getTextFieldLabel(0) } ${ Constants.SERIAL_NUMBER }`}
                    field={{
                        value: props.serial.value[0],
                        setValue: (value) => editSerial(value, 0),
                    }}
                />
            ) : (
                <TextFieldInput
                    id="serial_input"
                    label={`${ getTextFieldLabel(0) } ${ Constants.SERIAL_NUMBER }`}
                    field={{
                        value: serialList[0],
                        setValue: (value) => editSerial(value, 0),
                    }}
                />
            )
    ), [ props.serial.value ]);

    return (
        <Root style={{ paddingTop: props.automationMasterHub ? '0px' : '32px' }}>
            {(props.deviceType === Constants.REMOTE.toLowerCase() || props.automationRemote) &&
            props.associatedMaster && props.masterSerials && (
                <SelectFieldInput
                    id="associated_master_input"
                    label={Constants.ASSOCIATED_MASTER}
                    field={props.associatedMaster}
                    options={props.masterSerials.map(serial => ({
                        id: `${ serial }_option`,
                        title: serial,
                    }))}
                />
            )}
            {renderInputFields}
        </Root>
    );
});

export default EnterSerialInputFields;
