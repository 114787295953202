/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useState} from 'react';
import {styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import BaseModal from '../../../common/modal/BaseModal';
import TextFieldInput from '../../../common/input/TextFieldInput';
import Constants from '../../../../helper/Constants';
import isStringNotEmpty from '../../../../helper/validator/isStringNotEmpty';
import { ServiceRecord } from '../../../../types/components/ApiTypes';
import putServiceRecord from '../../../../controllers/apiCalls/put/putServiceRecord';
import { useSnackbar } from 'notistack';

const Container = styled('div')({
    width: '500px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
    editingId?: number,
    note?: string,
    userId: number,
}

const NewRecordModal = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ record, setRecord ] = useState(props.editingId && props.note !== undefined ? props.note : '');
    const [ recordError, setRecordError ] = useState('');

    const isValid = () => {
        const newRecordError = isStringNotEmpty(record, 'note.');
        setRecordError(newRecordError);

        return newRecordError === '';
    };

    const submit = () => {
        if (isValid()) {
            const newRecord: ServiceRecord = {
                id: props.editingId ? props.editingId : null,
                notes: record,
                serviceDate: new Date(),
                userId: props.userId,
            };

            if (props.editingId) {
                putServiceRecord(enqueueSnackbar, newRecord, 'Record updated successfully');
            } else {
                putServiceRecord(enqueueSnackbar, newRecord, 'Record added successfully');
            }
            props.close();
        }
    };

    return (
        <BaseModal
            id="new_record"
            open={props.open}
            close={props.close}
            submit={submit}
            title={Constants.NEW_RECORD}
            disableSubmitOnAwait
        >
            <Container>
                <TextFieldInput
                    id="new_record_input"
                    label={Constants.NOTE}
                    field={{
                        value: record,
                        setValue: setRecord,
                        error: recordError,
                        setError: setRecordError,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 2000,
                        },
                        multiline: true,
                        rows: 5,
                    }}
                />
                <Typography align="right">{record.length}/2000</Typography>
            </Container>
        </BaseModal>
    );
});

export default NewRecordModal;
