/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {TrendingDown, TrendingFlat, TrendingUp} from '@mui/icons-material';

interface Props {
    value: string,
    size?: 'small' | 'inherit' | 'medium' | 'large' | undefined,
}

const TrendIcon = (props: Props) => {
    switch (props.value) {
        case 'RISING':
            return (
                <TrendingUp
                    color='success'
                    fontSize={props.size ? props.size : 'medium'}
                />
            );
        case 'FALLING':
            return (
                <TrendingDown
                    color='error'
                    fontSize={props.size ? props.size : 'medium'}
                />
            );
        case 'STEADY':
            return (
                <TrendingFlat
                    color='primary'
                    fontSize={props.size ? props.size : 'medium'}
                />
            );
        default:
            return (<div/>);
    }
};

export default TrendIcon;
