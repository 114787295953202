/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import {InputFieldProps} from '../../../../../../types/components/ApiTypes';
import Constants from '../../../../../../helper/Constants';
import {Chip, Divider, Grid, styled, Typography} from '@mui/material';
import GridTextFieldInput from '../../../../../common/input/GridTextFieldInput';
import getTempUnit from '../../../../../../helper/functions/getTempUnit';
import getTestID from '../../../../../../helper/functions/getTestID';
import AerationAdvancedSettings from './AerationAdvancedSettings';
import TooltipStore from '../../../../../../stores/TooltipStore';
import TooltipMessages from '../../../../../../helper/TooltipMessages';
import InfoTooltip from '../../../../../common/InfoTooltip';
import {useState} from 'react';
import AutomationAdvancedSettings from './AutomationAdvancedSettings';

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '12px',
    paddingBottom: '8px',
});

interface Props {
    maxGrainTemp: InputFieldProps,
    targetTemperature: InputFieldProps,
    minTemperature: InputFieldProps,
    emcLow: InputFieldProps,
    emcHigh: InputFieldProps,
    plenumRise: InputFieldProps,
    fanStatus: String | null,
    disabled?: boolean,
}

const AerationForm = observer((props: Props) => {
    const [ advancedOpen, setAdvancedOpen ] = useState(false);

    return (
        <div style={{ width: '100%' }}>
            <Grid
                container
                spacing={2}
            >
                <GridTextFieldInput
                    id="max_grain_temp_input"
                    label={`Current Max Grain Temperature (${ getTempUnit() })`}
                    field={props.maxGrainTemp}
                    textFieldProps={{
                        disabled: true,
                    }}
                    fullWidth
                />
                <GridTextFieldInput
                    id="max_grain_temp_input"
                    label={`Target Temperature (${ getTempUnit() })`}
                    field={props.targetTemperature}
                    textFieldProps={{
                        inputProps: {
                            type: 'number',
                            max: props.maxGrainTemp.value,
                            min: props.minTemperature.value,
                            disabled: props.disabled,
                        },
                        InputProps: {
                            endAdornment: (
                                <InfoTooltip
                                    id="target_temp_tooltip_button"
                                    open={TooltipStore.beginTooltips && !TooltipStore.showDefaultMessages &&
                                    TooltipStore.showTargetTempMessages}
                                    message={TooltipMessages.TEMP_CONTROL}
                                    onClick={() => TooltipStore.setShowTargetTempMessages(false, true)}
                                />
                            ),
                            disabled: props.disabled,
                        },
                        disabled: props.disabled,
                    }}
                    fullWidth
                />
            </Grid>
            <Row>
                <Typography style={{ marginRight: '16px' }}>
                    {Constants.FAN_STATUS}:
                </Typography>
                <Chip
                    {...getTestID('aeration_fan_status')}
                    label={props.fanStatus == null ? '' : props.fanStatus}
                    color={props.fanStatus?.toLowerCase() == Constants.FAN_STATUS_RUNNING.toLowerCase() ||
                            // eslint-disable-next-line
                            props.fanStatus?.toLowerCase() == Constants.FAN_DISPLAY_STATUS_RUNNING_MANUAL.toLowerCase() ? 'success'
                        : (props.fanStatus?.toLowerCase() == Constants.FAN_STATUS_OFF.toLowerCase() ? 'secondary'
                        : 'error')}
                    size="small"
                />
            </Row>
            <Divider
                style={{
                    marginBottom: '8px',
                    marginTop: '8px',
                }}
            />
            <AutomationAdvancedSettings
                id="aeration"
                advancedOpen={advancedOpen}
                setAdvancedOpen={setAdvancedOpen}
                tooltipOpen={TooltipStore.beginTooltips && !TooltipStore.showTargetTempMessages &&
                TooltipStore.showAdvancedMessages}
            >
                <AerationAdvancedSettings
                    targetTemperature={props.targetTemperature}
                    minTemperature={props.minTemperature}
                    emcLow={props.emcLow}
                    emcHigh={props.emcHigh}
                    plenumRise={props.plenumRise}
                    disabled={props.disabled}
                />
            </AutomationAdvancedSettings>
        </div>
    );
});

export default AerationForm;
