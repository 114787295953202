// @ts-nocheck

// code taken from: https://github.com/visgl/react-map-gl/blob/7.0-release/examples/geocoder/src/geocoder-control.tsx

import {useState} from 'react';
import {useControl, MarkerProps, ControlPosition} from 'react-map-gl';
import MapboxGeocoder, {GeocoderOptions} from '@mapbox/mapbox-gl-geocoder';

type GeocoderControlProps = Omit<GeocoderOptions, 'accessToken' | 'mapboxgl' | 'marker'> & {
    mapboxAccessToken: string;
    marker?: boolean | Omit<MarkerProps, 'longitude' | 'latitude'>;

    position: ControlPosition;

    onLoading?: (e: object) => void;
    onResults?: (e: object) => void;
    onResult?: (e: object) => void;
    onError?: (e: object) => void;
};

/* eslint-disable complexity,max-statements */
export default function GeocoderControl(props: GeocoderControlProps) {
    const [ marker ] = useState(null);

    const isValidLat = (value: number) => value >= -90 && value <= 90;
    const isValidLong = (value: number) => value >= -180 && value <= 180;

    const localGeocoder = (query: string) => {
        const options = [];
        const coordinates = query.split(',');
        if (coordinates.length === 2 && !isNaN(Number(coordinates[0])) && !isNaN(Number(coordinates[1]))) {
            if (isValidLat(Number(coordinates[1])) && isValidLong(Number(coordinates[0]))) {
                options.push({
                    center: [ Number(coordinates[0]), Number(coordinates[1]) ],
                    type: 'Feature',
                    place_name: `Lat: ${ coordinates[1] }, Long: ${ coordinates[0] }`,
                    properties: {
                        title: `Lat: ${ coordinates[1] }, Long: ${ coordinates[0] }`,
                    },
                    geometry: {
                        coordinates: [ Number(coordinates[0]), Number(coordinates[1]) ],
                        type: 'Point',
                    },
                });
            }
            if (isValidLat(Number(coordinates[0])) && isValidLong(Number(coordinates[1]))) {
                options.push({
                    center: [ Number(coordinates[1]), Number(coordinates[0]) ],
                    type: 'Feature',
                    place_name: `Lat: ${ coordinates[0] }, Long: ${ coordinates[1] }`,
                    properties: {
                        title: `Lat: ${ coordinates[0] }, Long: ${ coordinates[1] }`,
                    },
                    geometry: {
                        coordinates: [ Number(coordinates[1]), Number(coordinates[0]) ],
                        type: 'Point',
                    },
                });
            }
        }
        return options;
    };

    const geocoder = useControl<MapboxGeocoder>(
        () => {
            const ctrl = new MapboxGeocoder({
                ...props,
                marker: false,
                accessToken: props.mapboxAccessToken,
                localGeocoder,
            });
            ctrl.on('loading', props.onLoading);
            ctrl.on('results', props.onResults);
            ctrl.on('result', evt => {
                props.onResult(evt);

                // const {result} = evt;
                // const location =
                //     result &&
                //     (result.center || (result.geometry?.type === 'Point' && result.geometry.coordinates));
                // if (location && props.marker) {
                //     setMarker(
                //         <Marker
                //             {...props.marker}
                //             longitude={location[0]}
                //             latitude={location[1]}
                //         />
                //     );
                // } else {
                //     setMarker(null);
                // }
            });
            ctrl.on('error', props.onError);
            return ctrl;
        },
        {
            position: props.position,
        }
    );

    // @ts-ignore (TS2339) private member
    if (geocoder._map) {
        if (geocoder.getProximity() !== props.proximity && props.proximity !== undefined) {
            geocoder.setProximity(props.proximity);
        }
        if (geocoder.getRenderFunction() !== props.render && props.render !== undefined) {
            geocoder.setRenderFunction(props.render);
        }
        if (geocoder.getLanguage() !== props.language && props.language !== undefined) {
            geocoder.setLanguage(props.language);
        }
        if (geocoder.getZoom() !== props.zoom && props.zoom !== undefined) {
            geocoder.setZoom(props.zoom);
        }
        if (geocoder.getFlyTo() !== props.flyTo && props.flyTo !== undefined) {
            geocoder.setFlyTo(props.flyTo);
        }
        if (geocoder.getPlaceholder() !== props.placeholder && props.placeholder !== undefined) {
            geocoder.setPlaceholder(props.placeholder);
        }
        if (geocoder.getCountries() !== props.countries && props.countries !== undefined) {
            geocoder.setCountries(props.countries);
        }
        if (geocoder.getTypes() !== props.types && props.types !== undefined) {
            geocoder.setTypes(props.types);
        }
        if (geocoder.getMinLength() !== props.minLength && props.minLength !== undefined) {
            geocoder.setMinLength(props.minLength);
        }
        if (geocoder.getLimit() !== props.limit && props.limit !== undefined) {
            geocoder.setLimit(props.limit);
        }
        if (geocoder.getFilter() !== props.filter && props.filter !== undefined) {
            geocoder.setFilter(props.filter);
        }
        if (geocoder.getOrigin() !== props.origin && props.origin !== undefined) {
            geocoder.setOrigin(props.origin);
        }
        // Types missing from @types/mapbox__mapbox-gl-geocoder
        // if (geocoder.getAutocomplete() !== props.autocomplete && props.autocomplete !== undefined) {
        //   geocoder.setAutocomplete(props.autocomplete);
        // }
        // if (geocoder.getFuzzyMatch() !== props.fuzzyMatch && props.fuzzyMatch !== undefined) {
        //   geocoder.setFuzzyMatch(props.fuzzyMatch);
        // }
        // if (geocoder.getRouting() !== props.routing && props.routing !== undefined) {
        //   geocoder.setRouting(props.routing);
        // }
        // if (geocoder.getWorldview() !== props.worldview && props.worldview !== undefined) {
        //   geocoder.setWorldview(props.worldview);
        // }
    }
    return marker;
}

const noop = () => {
};

GeocoderControl.defaultProps = {
    marker: true,
    onLoading: noop,
    onResults: noop,
    onResult: noop,
    onError: noop,
};
