/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';

interface Props {
    id?: string,
    label: string,
    value: string | number,
    fontSize?: number,
    noColor?: boolean,
    flipColors?: boolean,
    column?: boolean,
    isItalic?: boolean,
}

const KeyValueTypography = (props: Props) => {
    const Root = styled('div')({
        display: 'flex',
        flexDirection: props.column ? 'column' : 'row',
    });

    return (
        <Root>
            <Typography
                color={props.noColor || props.flipColors ? undefined : 'primary'}
                fontSize={props.fontSize}
                fontWeight={props.flipColors ? undefined : 'bold'}
                sx={{paddingRight: '8px'}}
            >
                {props.label}
            </Typography>
            <Typography
                {...getTestID(props.id)}
                color={props.flipColors ? 'primary' : undefined}
                fontSize={props.fontSize}
                fontWeight={props.flipColors ? 'bold' : undefined}
                fontStyle={props.isItalic ? 'italic' : undefined}
            >
                {props.value}
            </Typography>
        </Root>
    );
};

export default KeyValueTypography;
