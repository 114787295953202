/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {useEffect, useMemo, useState} from 'react';
import BinsenseStore from '../../../../stores/BinsenseStore';
import Constants from '../../../../helper/Constants';
import WebStore from '../../../../stores/WebStore';
import ReportsLogsTable from './ReportsLogsTable';
import ReportsTableHeader from '../ReportsTableHeader';
import moment from 'moment';
import getBinLogs from '../../../../controllers/apiCalls/get/getBinLogs';
import {useSnackbar} from 'notistack';
import {BinLog, Site} from '../../../../types/components/ApiTypes';
import replaceSpaces from '../../../../helper/functions/replaceSpaces';

interface Props {
    site: Site
    noTimePicker?: boolean,
    structureId?: number,
}

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const ReportsLogsPage = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const binOptions = useMemo(() => props.site.bins.map(b => ({
        title: b.name,
        id: `${ replaceSpaces(b.name) }_dropdown`,
        value: String(b.id),
    })), [ props.site ]);

    const getInitialBin = () => {
        if (props.structureId) {
            return props.structureId.toString();
        }
        return props.noTimePicker ? String(WebStore.viewDeviceId) : '';
    };

    const [ start, setStart ] = useState<any>(moment(new Date().getTime() - Constants.MILLISECONDS_IN_A_WEEK * 2));
    const [ end, setEnd ] = useState<any>(moment(new Date().getTime()));
    const [ bin, setBin ] = useState(getInitialBin());
    const [ binError, setBinError ] = useState('');

    useEffect(() => {
        if (props.site.id !== -1) {
            getBinLogs(enqueueSnackbar, props.site.id, new Date(start).getTime(), new Date(end).getTime());
        }
    }, [ props.site, start, end ]);

    useEffect(() => {
        if (binOptions.length > 0) {
            setBin(props.structureId ? getInitialBin() : binOptions[0].value);
        }
    }, [ binOptions ]);

    useEffect(() => {
        if (props.noTimePicker) {
            setBin(String(WebStore.viewDeviceId));
        }
    }, [ WebStore.viewDeviceId ]);

    const filterData = (data: BinLog[]) => data.filter(log => log.type === 'EVENT');

    const rows = useMemo(() => {
        const data = BinsenseStore.binLogsData.filter(log => log.structId === Number(bin) || log.structId === null);
        return filterData(data);
    }, [ BinsenseStore.binLogsData, bin ]);

    const columns = [
        {
            field: 'logDate',
            headerName: Constants.RECEIVED_DATE,
        },
        {
            field: 'type',
            headerName: Constants.TITLE,
        },
        {
            field: 'log',
            headerName: Constants.DESCRIPTION,
        },
        {
            field: 'accepted',
            headerName: Constants.STATUS,
        },
    ];

    return (
        <Root>
            {!props.noTimePicker && (
                <ReportsTableHeader
                    selectField={{
                        value: bin,
                        setValue: setBin,
                        error: binError,
                        setError: setBinError,
                    }}
                    options={binOptions}
                    timeField={{
                        start,
                        setStart,
                        end,
                        setEnd,
                    }}
                    rows={rows}
                    columns={columns}
                />
            )}
            <div style={{ width: '100%' }}>
                <ReportsLogsTable
                    data={rows}
                />
            </div>
        </Root>
    );
});

export default ReportsLogsPage;
