/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Customer} from '../../types/components/ApiTypes';
import Constants from '../Constants';

const getMyCustomersTableData = (customers: Customer[]) => customers.map(customer => ({
    id: customer.id,
    name: customer.account ? `${ customer.account.lastName }, ${ customer.account.firstName }` : Constants.NA,
    user: customer.account?.accountEmail ? customer.account.accountEmail : Constants.NA,
    city: customer.account ? `${ customer.account.address.city }, ${ customer.account.address.province }` : Constants.NA,
    dealer: 'Lastname, Firstname',
    sites: customer.sites.map(site => ({
        id: site.id,
        name: site.name,
        siteStatus: 'Online',
    })),
    retailerHasAccess: customer.retailerHasAccess,
}));

export default getMyCustomersTableData;
