import BinsenseStore from '../../../stores/BinsenseStore';
import callAPI from '../../callAPI';

const getSiteConditions = (enqueueSnackbar: any, id: number) => {
    callAPI(`site/conditions/${ id }`,
        (response) => {
            BinsenseStore.setSiteConditions(response);
        },
        enqueueSnackbar
    );
};

export default getSiteConditions;