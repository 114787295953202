/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {IconButton, styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {useMemo, useState} from 'react';
import Constants from '../../../../helper/Constants';
import SelectFieldInput from '../../../common/input/SelectFieldInput';
import {Delete} from '@mui/icons-material';
import WebStore from '../../../../stores/WebStore';
import {Bin, Building, GrainBag, Site} from '../../../../types/components/ApiTypes';
import getTestID from '../../../../helper/functions/getTestID';
import deleteStructure from '../../../../controllers/apiCalls/delete/deleteStructure';
import {useSnackbar} from 'notistack';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import DeleteStructureModal from '../../../common/modal/DeleteStructureModal';
import { comparisonOfStructuresInAscendingOrder } from '../../../../helper/functions/functions';

interface Props {
    site: Site,
    bin: Bin | GrainBag | Building,
    tab: string,
}

const Root = styled('div')({
    width: '22.5%',
    display: 'flex',
    flexDirection: 'row',
});

const ViewDeviceBinSelect = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const binOptions = useMemo(() => props.site.bins.map(b => (
            {
                title: b.name,
                id: `${ b.name }_dropdown`,
                value: String(b.id),
                sortOrder: b.sortOrder,
            }
        )), [ props.site ]);
    const grainOption = useMemo(() => props.site.grainBags.map(b => (
            {
                title: b.name,
                id: `${ b.name }_dropdown`,
                value: String(b.id),
                sortOrder: b.sortOrder,
            }
        )), [ props.site ]);
    const buildingOption = useMemo(() => props.site.buildings.map(b => (
        {
            title: b.name,
            id: `${ b.name }_dropdown`,
            value: String(b.id),
            sortOrder: b.sortOrder,
        }
    )), [ props.site ]);
    const combinedArray =
        useMemo(() => [ ...binOptions , ...grainOption , ...buildingOption ], [ binOptions, grainOption, buildingOption ]);

    const [ binError, setBinError ] = useState('');
    const [ deleteConfirmationModalOpen, setDeleteConfirmationModalOpen ] = useState(false);

    return (
        <Root>
            {!BinsenseStore.awaitingUserData ? (
                <SelectFieldInput
                    id="bin_select_field"
                    label= "Structure"
                    field={{
                        value: props.bin.id.toString(),
                        setValue: (id: string) => WebStore.setViewDeviceId(Number(id), true),
                        error: binError,
                        setError: setBinError,
                    }}
                    options={comparisonOfStructuresInAscendingOrder(combinedArray)}
                    textFieldProps={{
                        disabled: props.tab === Constants.SETTINGS,
                    }}
                />
            ) : (
                <DashboardSkeleton height={55}/>
            )}
            <IconButton
                {...getTestID('delete_bin_button')}
                style={{
                    marginLeft: '4px',
                    marginBottom: '8px',
                }}
                onClick={() => setDeleteConfirmationModalOpen(true)}
                disabled={BinsenseStore.awaitingUserData || BinsenseStore.awaitingApi}
            >
                <Delete/>
            </IconButton>
            {deleteConfirmationModalOpen && (
                <DeleteStructureModal
                    id={`${ props.bin.id }_delete_confirmation`}
                    open
                    close={() => setDeleteConfirmationModalOpen(false)}
                    submit={() => deleteStructure(enqueueSnackbar, props.bin.id)}
                    structName={props.bin.name}
                />
            )}
        </Root>
    );
});

export default ViewDeviceBinSelect;
