/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper, styled, Typography} from '@mui/material';
import CircularProgressWithLabel from '../../../../dealer/myCustomers/siteReportPage/CircularProgressWithLabel';
import {observer} from 'mobx-react-lite';
import useWindowSize from '../../../../../helper/hooks/useWindowSize';
import {useEffect, useMemo, useState} from 'react';
import Constants from '../../../../../helper/Constants';
import {Bin, Building, Grain, GrainBag} from '../../../../../types/components/ApiTypes';
import getTestID from '../../../../../helper/functions/getTestID';
import TooltipStore from '../../../../../stores/TooltipStore';
import TooltipMessages from '../../../../../helper/TooltipMessages';
import BinsenseStore from '../../../../../stores/BinsenseStore';
import DashboardSkeleton from '../../../../common/DashboardSkeleton';
import InfoTooltip from '../../../../common/InfoTooltip';
import GridSelectFieldInput from '../../../../common/input/GridSelectFieldInput';
import { useSnackbar } from 'notistack';
import putBinSettings from '../../../../../controllers/apiCalls/put/putBinSettings';

interface Props {
    bin: Bin | GrainBag | Building,
}

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
});

const HEADER_FONT_SIZE = 14;
const CONTENT_FONT_SIZE = 20;

const DeviceInventoryPaper = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const volume = props.bin.status.volume ? Math.round(props.bin.status.volume).toLocaleString() : '0';
    const capacity = props.bin.capacity ? Math.round(props.bin.capacity).toLocaleString() : '0';
    const [ productType, setProductType ] = useState('');
    useEffect(() => setProductType(props.bin.settings.productId.toString()), [ props.bin.settings.productId ]);

    // move [Empty] from the last item to the first item of the product option list
    let productList = useMemo(() => BinsenseStore.dataModel.grains, [ BinsenseStore.dataModel.grains ]);
    if ( productType !== '' && productList && productList.length > 0 ) {
        productList = [ productList[productList.length - 1], ...productList.slice(0, -1) ];
    }

    const productOptions = useMemo(() => productList && productList.map((grain: Grain) => ({
        title: grain.name,
        value: grain.id.toString(),
        id: `${ grain.id }_option`,
    })), [ productList ]);

    const updateProduct = (newValue: any) => {
        setProductType(newValue);
        const settings = {
            productId: Number(newValue),
            lowTemperatureThreshold: props.bin.settings.lowTemperatureThreshold,
        };
        putBinSettings(enqueueSnackbar, props.bin.id, settings, 'Commodity updated successfully');
    };

    return !BinsenseStore.awaitingUserData ? (
        <Paper
            style={{
                height: '100%',
                width: '100%',
            }}
        >
            <Row
                style={{
                    justifyContent: 'space-between',
                    height: '100%',
                }}
            >
                <Column style={{ width: '75%' }}>
                    <Row style={{ paddingBottom: '16px' }}>
                        <Typography
                            fontSize={HEADER_FONT_SIZE}
                        >
                            Inventory
                        </Typography>
                        <InfoTooltip
                            id="inventory_tooltip_button"
                            open={TooltipStore.beginTooltips && TooltipStore.showInventoryMessages}
                            message={TooltipMessages.INVENTORY}
                            onClick={() => TooltipStore.setShowInventoryMessages(false, true)}
                        />
                    </Row>
                    <GridSelectFieldInput
                        id="edit_commodity_input"
                        label={Constants.COMMODITY}
                        field={{
                            value: productType,
                            setValue: updateProduct,
                        }}
                        options={productOptions}
                        fullWidth
                    />
                    <Typography
                        {...getTestID('view_device_inventory_bushels')}
                        fontWeight="bold"
                        fontSize={windowWidth > Constants.WINDOW_WIDTHS[2]
                            ? CONTENT_FONT_SIZE : CONTENT_FONT_SIZE - 4}
                    >
                        {`${ volume } bu / ${ capacity }`}
                    </Typography>
                </Column>
                <CircularProgressWithLabel
                    id='view_device_inventory_percent_full'
                    value={props.bin.status?.percentFull ? props.bin.status.percentFull : 0}
                    size={80}
                />
            </Row>
        </Paper>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={160}/>
        </SkeletonRoot>
    );
});

export default DeviceInventoryPaper;
