/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

// @ts-ignore
import uuid from 'react-uuid';
import {Button} from '@mui/material';
import DisplayGridItem from '../../../common/displayTable/DisplayGridItem';
import {observer} from 'mobx-react-lite';
import {Bin, GrainBag} from '../../../../types/components/ApiTypes';
import StorageGridItemBody from './StorageGridItemBody';
import Constants from '../../../../helper/Constants';
import {useMemo} from 'react';
import useWindowSize from '../../../../helper/hooks/useWindowSize';
import BinImagePanel from './BinImagePanel';
import goToViewDevicePage from '../../../../helper/functions/goToViewDevicePage';

interface Props {
    bin: Bin | GrainBag,
}

const StorageGridItem = observer((props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);

    return (
        <DisplayGridItem
            xs={windowWidth > Constants.WINDOW_WIDTHS[2] ? 4 : 6}
            key={uuid()}
            minWidth="375px"
        >
            <BinImagePanel
                bin={props.bin}
                maxWidth
                displayAmbientTemp={props.bin.settings.hardwareType.toLowerCase() === Constants.LIVE.toLowerCase() ||
                    props.bin.settings.hardwareType.toLowerCase() === Constants.SOLO.toLowerCase()}
            >
                <StorageGridItemBody
                    bin={props.bin}
                />
                <Button
                    sx={{
                        width: '100%',
                        marginTop: '8px',
                    }}
                    variant="contained"
                    onClick={() => goToViewDevicePage(props.bin.id)}
                >
                    View Details
                </Button>
            </BinImagePanel>
        </DisplayGridItem>
    );
});

export default StorageGridItem;
