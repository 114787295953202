/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const convertToCamelCase = (str: string): string => str.replace(/(?:^.|[A-Z]|\b.)/g,
    (letter, index) => index === 0
        ? letter.toLowerCase()
        : letter.toUpperCase()).replace(/\s+/g, '');

const getTestID = (elementName?: string) => ({id: convertToCamelCase(`tag_${ elementName }`)});

export default getTestID;
