/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import Constants from '../../../../../helper/Constants';
import ToggleButtonInput from '../../../../common/input/ToggleButtonInput';
import {Dispatch, SetStateAction} from 'react';
import {observer} from 'mobx-react-lite';
import DateRangePicker from '../../../../common/DateRangePicker';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
});

interface Props {
    timeUnit: string,
    setTimeUnit: Dispatch<SetStateAction<string>>,
    start: any,
    setStart: Dispatch<SetStateAction<any>>,
    end: any,
    setEnd: Dispatch<SetStateAction<any>>,
}

const SiteChartTimeRangePicker = observer((props: Props) => (
    <Root>
        <ToggleButtonInput
            title="Time Unit"
            value={props.timeUnit}
            setValue={props.setTimeUnit}
            options={[
                {
                    id: 'one_day_option',
                    title: Constants.ONE_DAY,
                },
                {
                    id: 'one_week_option',
                    title: Constants.ONE_WEEK,
                },
                {
                    id: 'one_month_option',
                    title: Constants.ONE_MONTH,
                },
                {
                    id: 'custom_option',
                    title: Constants.CUSTOM,
                },
            ]}
            size="small"
        />
        {props.timeUnit === Constants.CUSTOM && (
            <div
                style={{
                    width: '100%',
                    marginTop: '16px',
                }}
            >
                <Typography
                    color="primary"
                    marginBottom='8px'
                >
                    Set Date Range
                </Typography>
                <DateRangePicker
                    start={props.start}
                    setStart={props.setStart}
                    end={props.end}
                    setEnd={props.setEnd}
                    small
                />
            </div>
        )}
    </Root>
));

export default SiteChartTimeRangePicker;
