/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import DisplayMap from '../../common/map/DisplayMap';
import WebStore from '../../../stores/WebStore';
import Constants from '../../../helper/Constants';
import BinsenseStore from '../../../stores/BinsenseStore';
import {Customer} from '../../../types/components/ApiTypes';
import {useEffect, useMemo, useRef, useState} from 'react';
import getIndex from '../../../helper/functions/getIndex';
import {observer} from 'mobx-react-lite';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import getMyCustomersMarkerDataForBounds from '../../../helper/functions/getMyCustomersMarkerDataForBounds';
import { LngLat, LngLatBounds, LngLatLike } from 'mapbox-gl';

interface Props {
    customers: Customer[]
}

const markerUpdateDelayMS = 350;

const MyCustomersMap = observer((props: Props) => {

    // use a ref so we can keep resetting the same timer
    var updateTimer = useRef(null) as unknown as React.MutableRefObject<NodeJS.Timeout>;;

    // template objects so we can manage map bounds state
    const lngLatTemplate:LngLat = {
        lng: 0,
        lat: 0,
        wrap (): LngLat {
            throw new Error('Function not implemented.');
        },
        toArray (): number[] {
            return [ this.lng, this.lat ];
        },
        distanceTo (lngLat: LngLat): number {
            throw new Error('Function not implemented.');
        },
        toBounds (radius: number): LngLatBounds {
            throw new Error('Function not implemented.');
        },
    };

    const boundTemplate:LngLatBounds = {
        sw: [ 0, 0 ],
        ne: [ 0, 0 ],
        _sw: new LngLat(0, 0),
        _ne: new LngLat(0, 0),

        setNorthEast(ne: LngLatLike): LngLatBounds {
            return this;
        },
        setSouthWest(sw: LngLatLike): LngLatBounds {
            return this;
        },
        contains(lnglat: LngLatLike): boolean {
            return false;
        },
        extend(obj: LngLatBounds | [number, number] | LngLat |
        { lng: number; lat: number; } | { lon: number; lat: number; } |
        [LngLatLike, LngLatLike] | [number, number, number, number]): LngLatBounds {
            return this;
        },
        getCenter(): LngLat {
            return lngLatTemplate;
        },
        getSouthWest(): LngLat {
            return lngLatTemplate;
        },
        getNorthEast(): LngLat {
            return lngLatTemplate;
        },
        getNorthWest(): LngLat {
            return lngLatTemplate;
        },
        getSouthEast(): LngLat {
            return lngLatTemplate;
        },
        getWest(): number {
            return this.sw[0];
        },
        getSouth(): number {
            return this.sw[1];
        },
        getEast(): number {
            return this.ne[0];
        },
        getNorth(): number {
            return this.ne[1];
        },
        toArray(): number[][] {
            return [];
        },
        isEmpty(): boolean {
            return true;
        },
    };

    const [ bounds, setBounds ] = useState(boundTemplate);

    const [ viewState, setViewState ] = useState({
        longitude: -104.4,
        latitude: 55.8,
        zoom: 3,
    });

    const [ reloadMarkers, setReloadMarkers ] = useState(false);

    const markerData = useMemo(() => getMyCustomersMarkerDataForBounds(props.customers,bounds),[ reloadMarkers ]);

    const updateMarkerData = useEffect(() => {
        clearTimeout(updateTimer.current);
        updateTimer.current = setTimeout(() => {
            //setReloadMarkers(!reloadMarkers);
            setReloadMarkers(true);
        }, markerUpdateDelayMS);
        setReloadMarkers(false);
    },
    [ props.customers, bounds, WebStore.currentPage ]);

    const onMarkerClick = (siteId: number) => {
        if (siteId <= 0) {
            return; // this is a marker for multiple sites
        }
        const markerIndex = getIndex(siteId, markerData);
        // @ts-ignore
        const userId = markerData[markerIndex].siteTooltip.customer.id;
        WebStore.setPageIds([ userId, siteId, -1 ], true);
        const userIndex = getIndex(userId, BinsenseStore.customerSiteData);
        WebStore.setPageData([ BinsenseStore.customerSiteData,
            BinsenseStore.customerSiteData[userIndex].sites, null ], true);
        WebStore.setStorageMode(Constants.MAP, true);
        WebStore.goToNewPage(Constants.STORAGE);
    };

    const onMove = (e: any) => {
        setViewState(e.viewState);
    };

    return !BinsenseStore.awaitingUserData ? (
        <DisplayMap
            height={400}
            initialView={viewState}
            markers={markerData}
            toggleOnBottom
            onMarkerClick={onMarkerClick}
            onMove={onMove}
            onSetBounds={setBounds}
            isMyCustomersMap
        />
    ) : (
        <DashboardSkeleton height={400}/>
    );
});

export default MyCustomersMap;
