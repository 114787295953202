/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Box, CircularProgress, CircularProgressProps, Typography} from '@mui/material';
import getTestID from '../../../../helper/functions/getTestID';

interface Props {
    id?: string,
    value: number,
    size: number,
}

const CircularProgressWithLabel = (props: CircularProgressProps & Props) => (
    <Box
        sx={{
            position: 'relative',
            display: 'inline-flex',
        }}
    >
        <CircularProgress
            variant="determinate"
            color="primary"
            {...props}
            value={props.value ? props.value : 100}
        />
        <Box
            sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                {...getTestID(props.id)}
                variant="caption"
                component="div"
                color="text.secondary"
                fontWeight='bold'
            >
                {`${ props.value ? Math.round(props.value) : 0 }%`}
            </Typography>
        </Box>
    </Box>
);

export default CircularProgressWithLabel;