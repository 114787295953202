/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import BaseModal from '../../common/modal/BaseModal';
import TextFieldInput from '../../common/input/TextFieldInput';
import Constants from '../../../helper/Constants';
import SelectFieldInput from '../../common/input/SelectFieldInput';
import {useState} from 'react';

const Container = styled('div')({
    width: '500px',
    padding: '16px',
});

interface Props {
    open: boolean,
    close: () => void,
    editingId?: number,
    note?: string,
}

const NewUserModal = (props: Props) => {
    const [ email, setEmail ] = useState('');
    const [ emailError, setEmailError ] = useState('');
    const [ role, setRole ] = useState('');
    const [ roleError, setRoleError ] = useState('');

    const isValid = () => true;

    const submit = () => {
        if (isValid()) {
            console.log('submit add');
            props.close();
        }
    };

    return (
        <BaseModal
            id="new_user"
            open={props.open}
            close={props.close}
            submit={submit}
            title="New User"
            disableSubmitOnAwait
        >
            <Container>
                <TextFieldInput
                    id="email_input"
                    label={Constants.EMAIL}
                    field={{
                        value: email,
                        setValue: setEmail,
                        error: emailError,
                        setError: setEmailError,
                    }}
                />
                <SelectFieldInput
                    id="role_input"
                    label={Constants.ROLE}
                    field={{
                        value: role,
                        setValue: setRole,
                        error: roleError,
                        setError: setRoleError,
                    }}
                    options={[ {
                        title: 'Admin',
                        id: 'admin_option',
                    }, {
                        title: 'Manager',
                        id: 'manager_option',
                    } ]}
                />
            </Container>
        </BaseModal>
    );
};

export default NewUserModal;
