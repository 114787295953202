/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Tooltip} from '@mui/material';
import getTestID from '../../../../helper/functions/getTestID';

interface Props {
    id: string,
    title: string,
    src: string,
    onClick: (obj: object) => void;
    size: number,
}

const ToolbarButton = (props: Props) => (
    <Tooltip title={props.title}>
        <img
            {...getTestID(props.id)}
            style={{
                cursor: 'pointer',
                marginLeft: '8px',
                marginRight: '8px',
            }}
            src={props.src}
            alt={props.title}
            width={props.size}
            height={props.size}
            onClick={props.onClick}
        />
    </Tooltip>
);

export default ToolbarButton;
