/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import LoginStore from '../../stores/LoginStore';
import BinsenseStore from '../../stores/BinsenseStore';
import WebStore from '../../stores/WebStore';
import TooltipStore from '../../stores/TooltipStore';

const logout = () => {
    LoginStore.setEmail('', true);
    LoginStore.setToken('', true);
    LoginStore.setUdd(-1, true);
    BinsenseStore.presetBinsenseStore();
    WebStore.presetWebStore();
    TooltipStore.presetTooltipStore();
    LoginStore.setLoggedIn(false);
    LoginStore.setLoadingData(true);
};

export default logout;
