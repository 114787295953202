/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import WebStore from '../../../stores/WebStore';
import Colors from '../../../helper/Colors';
import {SxProps, Theme} from '@mui/material';

interface Props {
    children: object | object[],
    sx?: SxProps<Theme> | undefined,
}

const DisplayGridHeaderRow = observer((props: Props) => {
    const getBackgroundColor = () => {
        if (WebStore.lightMode) {
            return Colors.drawerUserBackgroundLight;
        }
        return '#424242';
    };

    return (
        <Grid
            container
            spacing={2}
            sx={{
                ...props.sx,
                backgroundColor: getBackgroundColor(),
                borderRadius: '12px',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
                marginLeft: '0px',
                width: 'auto',
                position: 'sticky',
                top: '0',
                zIndex: '10',
            }}
        >
            {props.children}
        </Grid>
    );
});

export default DisplayGridHeaderRow;
