/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {AutomationHubs, Bin, Building, GrainBag, MasterUnit, RemoteUnit, Site} from '../../types/components/ApiTypes';
import BinsenseStore from '../../stores/BinsenseStore';
import { comparisonOfStructuresInAscendingOrder } from './functions';

const getCommand = (device: string) => {
    const index = BinsenseStore.dataModel.serverCommandsGroupMap.findIndex(commandGroup =>
        commandGroup.groupName === device.toUpperCase());
    if (index > -1) {
        return BinsenseStore.dataModel.serverCommandsGroupMap[index].commands[0].id.toString();
    }
    return '';
};

const getCommands = (bin: Bin | Building | GrainBag | AutomationHubs,
        units: MasterUnit[] | RemoteUnit[],
        device: string, commands: any[]) =>
    units.map(unit => commands.push({
        id: unit.serial.concat(bin.id.toString()),
        bin: bin.name,
        device,
        serialNumber: unit.serial,
        numberOfCables: bin.hardware.cables.length,
        command: getCommand(device),
        sortOrder: bin.sortOrder,
}));

const getBinCommands = (bin: Bin | Building | GrainBag | AutomationHubs, commands: any[]) => {
    getCommands(bin, bin.hardware.masterUnits, 'Master', commands);
    getCommands(bin, bin.hardware.remoteUnits, 'Remote', commands);
};

const getCommandsData = (site: Site) => {
    const commands: any[] = [];
    site.bins.forEach(bin => getBinCommands(bin, commands));
    site.buildings.forEach(bin => getBinCommands(bin, commands));
    site.grainBags.forEach(bin => getBinCommands(bin, commands));
    site.automationHubs.forEach(bin => getBinCommands(bin, commands));
    return comparisonOfStructuresInAscendingOrder(commands);
};

export default getCommandsData;
