import callPostAPI from '../../callPostAPI';
import updateData from '../../../helper/functions/updateData';

const postAutomationHubs = (enqueueSnackbar: any, siteId: number, body: any[]) => {
    callPostAPI(`structure/automationHubs/${ siteId }`,
        body,
        (response) => {
            console.log(response);
            updateData(enqueueSnackbar);
        },
        enqueueSnackbar,
        'Automation hubs added successfully'
    );
};

export default postAutomationHubs;