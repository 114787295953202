/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import { Dispatch, SetStateAction, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import Constants from '../../../helper/Constants';
import moment from 'moment';
import getTempUnit from '../../../helper/functions/getTempUnit';
import BinsenseStore from '../../../stores/BinsenseStore';
import { Skeleton, styled } from '@mui/material';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import ViewSiteTempChart from '../storage/viewDevicePage/display/ViewSiteTempChart';
import ViewSiteRHChart from '../storage/viewDevicePage/display/ViewSiteRHChart';
import SiteChartTimeRangePicker from '../storage/viewDevicePage/display/SiteChartTimeRangePicker';

interface Props {
    timeUnit: string,
    setTimeUnit: Dispatch<SetStateAction<string>>,
    start: number,
    setStart: Dispatch<SetStateAction<moment.Moment>>,
    end: number,
    setEnd: Dispatch<SetStateAction<moment.Moment>>,
}

const SkeletonRoot = styled('div')({
    width: '100%',
    padding: '20px',
    paddingTop: '10px',
});

const AmbientCondtionChart = observer((props:Props) => {
    const [ reset, setReset ] = useState(true);
    let startTime = new Date(props.start).getTime();
    let endTime = new Date(props.end).getTime();
    const data = useMemo(() => BinsenseStore.siteGraphData, [ BinsenseStore.siteGraphData ]);
    const tempUnit = useMemo(getTempUnit,
        [ BinsenseStore.userData.settings.preferences, BinsenseStore.awaitingUserData ]);

    useEffect(() => {
        switch (props.timeUnit) {
            case Constants.ONE_DAY:
                endTime = new Date().getTime();
                startTime = endTime - Constants.MILLISECONDS_IN_A_DAY;
                props.setEnd(moment(endTime));
                props.setStart(moment(startTime));
                break;
            case Constants.ONE_WEEK:
                endTime = new Date().getTime();
                startTime = endTime - Constants.MILLISECONDS_IN_A_WEEK;
                props.setEnd(moment(endTime));
                props.setStart(moment(startTime));
                break;
            case Constants.ONE_MONTH:
                endTime = new Date().getTime();
                startTime = endTime - Constants.MILLISECONDS_IN_A_MONTH;
                props.setEnd(moment(endTime));
                props.setStart(moment(startTime));
                break;
        }
    },[ props.timeUnit ]);

    useEffect(() => {
        setReset(true);
    }, [ data ]);

    const chart = useMemo(() => {
        if (reset) {
            setTimeout(() => { setReset(false); }, 0);
        }
        return !reset ? (
            <div id="wrapper">
                <div id="chartTemp">
                    <ViewSiteTempChart
                        id="tempchart"
                        data={data}
                        legend_show={true}
                        tempUnit={tempUnit}
                        timeUnit={props.timeUnit}
                        setTimeUnit={props.setTimeUnit}
                        setStart={props.setStart}
                        setEnd={props.setEnd}
                    />
                </div>
                <div id="chartRh">
                    <ViewSiteRHChart
                        data={data}
                        id="rhchart"
                        legend_show={true}
                        height="100%"
                        setTimeUnit={props.setTimeUnit}
                        setStart={props.setStart}
                        setEnd={props.setEnd}
                    />
                </div>
            </div>
        ) : (
            <Skeleton
                variant="rectangular"
                width="100%"
                height={100}
            />
        );
    }, [ reset, data ]);

    return (!BinsenseStore.awaitingUserData) ? (
        <div
            style={{
                width: '100%',
                height: '100%',
                paddingRight: '40px',
            }}
        >
            {chart}
            <SiteChartTimeRangePicker
                timeUnit={props.timeUnit}
                setTimeUnit={props.setTimeUnit}
                start={props.start}
                setStart={props.setStart}
                end={props.end}
                setEnd={props.setEnd}
            />
        </div>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={400}/>
        </SkeletonRoot>
    );
});

export default AmbientCondtionChart;
