/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import ContentPanel from '../../common/ContentPanel';
import {observer} from 'mobx-react-lite';
import {useMemo} from 'react';
import WebStore from '../../../stores/WebStore';
import getSelectedSiteIndex from '../../../helper/functions/getSelectedSiteIndex';
import BinsenseStore from '../../../stores/BinsenseStore';
import ReportsMainPage from '../reports/ReportsMainPage';
import getNewSite from '../../../helper/functions/getNewSite';

const Reports = observer(() => {
    const site = useMemo(() => {
        const index = getSelectedSiteIndex();
        return index !== -1 ? BinsenseStore.userData.sites[index] : getNewSite();
    }, [ WebStore.selectedSiteId, BinsenseStore.userData ]);

    return (
        <ContentPanel>
            <ReportsMainPage site={site}/>
        </ContentPanel>
    );
});

export default Reports;
