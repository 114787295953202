/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {BinNote} from '../../types/components/ApiTypes';
import Constants from '../Constants';
import getIndex from './getIndex';
import getSelectedSiteIndex from './getSelectedSiteIndex';
import getSite from './getSite';

const getNoteStructure = (id: number) => {
    const siteIndex = getSelectedSiteIndex();
    const site = getSite(siteIndex);
    let index = getIndex(id, site.bins);
    if (index === -1) {
        index = getIndex(id, site.buildings);
        if (index === -1) {
            index = getIndex(id, site.grainBags);
            // if (index !== -1) {
            //     index = getIndex(id, site.piles);
            //     return index !== -1 ? site.piles[index].name : 'N/A';
            // }
            return index !== -1 ? site.grainBags[index].name : Constants.NA;
        }
        return site.buildings[index].name;
    }
    return site.bins[index].name;
};

const getNotesTableData = (data: BinNote[]) => data.map(note => ({
        date: note.dateAdded,
        id: note.id,
        note: note.note,
        structure: getNoteStructure(note.structureId),
        structureId: note.structureId,
    }));

export default getNotesTableData;
