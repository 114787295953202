/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import {useEffect, useMemo, useState} from 'react';
import Constants from '../../../../helper/Constants';
import DisplayGridHeaderRow from '../../../common/displayTable/DisplayGridHeaderRow';
import BinsenseStore from '../../../../stores/BinsenseStore';
import {styled} from '@mui/material';
import {Typography} from '@mui/material';
import {useSnackbar} from 'notistack';
import {Site} from '../../../../types/components/ApiTypes';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import DisplayGridPaper from '../../../common/displayTable/DisplayGridPaper';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import Pagination from '../../../common/pagination/Pagination';
import getAlertHistory from '../../../../controllers/apiCalls/get/getAlertHistory';
import AlertHistoryTableRow from './AlertHistoryTableRow';
import SelectFieldInput from '../../../common/input/SelectFieldInput';

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const SkeletonRoot = styled('div')({
    width: '100%',
    padding: '20px',
    paddingTop: '0px',
});

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '20px',
});

interface Props {
    site: Site,
}

const AlertHistoryTable = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(30);
    const [ type, setType ] = useState('all');

    const rows = useMemo(() => BinsenseStore.alertHistoryData, [ BinsenseStore.alertHistoryData ]);

    useEffect(() => {
        if (props.site.id > -1) {
            console.log(type);
            getAlertHistory(enqueueSnackbar, props.site.id, rowsPerPage, page * rowsPerPage, type);
        }
    }, [ props.site, page, rowsPerPage, type ]);

    useEffect(() => {
        setPage(0);
    }, [ type ]);

    const count = useMemo(() => BinsenseStore.alertHistoryCount, [ BinsenseStore.alertHistoryCount ]);

    const renderHeader = (id: string, label: string, xs: number, dropDown?: object) => (
        <DisplayGridLabel
            id={`${ id }_alert_history_header`}
            label={label}
            xs={xs}
            header
            dropDown={dropDown}
        />
    );

    return (
        <Root>
            <Row>
                <Typography
                    color="primary"
                    width='250px'
                >
                    Alert History
                </Typography>
            </Row>
            {!BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
                <div>
                    <DisplayGridPaper>
                        <DisplayGridHeaderRow sx={{marginTop: '0px'}}>
                            {renderHeader('alertTime', Constants.ALERT_TIME, 3)}
                            {renderHeader('message', Constants.MESSAGE, 4)}
                            {renderHeader('type', Constants.TYPE, 1, (
                                <SelectFieldInput
                                    id="select_type_input"
                                    label={Constants.SELECT_TYPE}
                                    field={{
                                        value: type,
                                        setValue: setType,
                                    }}
                                    options={[ {
                                        title: Constants.ALL,
                                        value: 'all',
                                        id: 'all',
                                    }, {
                                        title: Constants.WEEKLY_RISE,
                                        value: 'roi',
                                        id: 'roi',
                                    }, {
                                        title: Constants.HIGH_THRESHOLD,
                                        value: 'threshold_high',
                                        id: 'threshold_high',
                                    }, {
                                        title: Constants.LOW_THRESHOLD,
                                        value: 'threshold_low',
                                        id: 'threshold_low',
                                    }, {
                                        title: Constants.SYSTEM_THRESHOLD,
                                        value: 'threshold_sys',
                                        id: 'threshold_sys',
                                    }, {
                                        title: Constants.VOLTAGE,
                                        value: 'voltage',
                                        id: 'voltage',
                                    } ]}
                                />
                            ))}
                            {renderHeader('reply', Constants.REPLY, 2)}
                            {renderHeader('replyTime', Constants.REPLY_TIME, 2)}
                        </DisplayGridHeaderRow>
                        {rows.map(row => (
                            <AlertHistoryTableRow
                                row={row}
                                key={`${ row.id }_alert_history_row`}
                                refreshAlertHistory={() =>
                                    getAlertHistory(enqueueSnackbar, props.site.id, rowsPerPage, page * rowsPerPage, type)}
                            />
                        ))}
                    </DisplayGridPaper>
                    <Pagination
                        count={count}
                        pageField={{
                            value: page,
                            setValue: setPage,
                        }}
                        rowsPerPageField={{
                            value: rowsPerPage,
                            setValue: setRowsPerPage,
                        }}
                    />
                </div>
            ) : (
                <SkeletonRoot>
                    <DashboardSkeleton height={65 * rowsPerPage}/>
                </SkeletonRoot>
            )}
        </Root>
    );
});

export default AlertHistoryTable;
