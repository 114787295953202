/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import ErrorMessages from '../helper/ErrorMessages';
import {EnqueueSnackbar} from '../types/SnackbarTypes';
import BinsenseStore from '../stores/BinsenseStore';
import errorSnackbarOptions from '../helper/errorSnackbarOptions';
import logout from '../helper/functions/logout';

const showError = (errorMessage: String,
                   response: Response,
                   enqueueSnackbar: EnqueueSnackbar) => {
    BinsenseStore.setAwaitingApi(false);
    response.json().then((data:any) =>{
        if (data.status && data.status === 'fail') {
            if (data.displayMessage && data.message) {
                enqueueSnackbar(data.message, errorSnackbarOptions);
            } else {
                enqueueSnackbar(`${ response.status }:${  errorMessage }`, errorSnackbarOptions);
            }
        } else {
            enqueueSnackbar(`${ response.status }:${  errorMessage }`, errorSnackbarOptions);
        }
    } );
};

const handleError = (oldResponse: Response,
                     enqueueSnackbar: EnqueueSnackbar,
                     on401Fail?: () => void) => {
    const response = oldResponse.clone();
    if (!response.ok) {
        switch (response.status) {
            case 401:
                logout();
                enqueueSnackbar(`${ response.status }:${  ErrorMessages.ERROR_401 }`, errorSnackbarOptions);
                if (on401Fail) {
                    on401Fail();
                }
                break;
            case 500:
                showError(ErrorMessages.ERROR_500, response, enqueueSnackbar);
                break;
            case 403:
                showError(ErrorMessages.ERROR_403, response, enqueueSnackbar);
                break;
            case 400:
                BinsenseStore.setAwaitingApi(false);
                if (response.statusText.toLowerCase().includes('token is invalid or expired')) {
                    enqueueSnackbar(`${ ErrorMessages.TOKEN_EXPIRED }`, errorSnackbarOptions);
                } else if (response.statusText.toLowerCase().includes('this user does not exist')) {
                    enqueueSnackbar(`${ ErrorMessages.UNIDENTIFIED_ACCOUNT }`, errorSnackbarOptions);
                } else if (response.statusText.toLowerCase().includes('incorrect password')) {
                    enqueueSnackbar('You have entered an incorrect old password. Please try again.', errorSnackbarOptions);
                } else if (response.statusText) {
                    enqueueSnackbar(`${ response.status }: ${  response.statusText }`, errorSnackbarOptions);
                } else {
                    enqueueSnackbar(`${ response.status }: ${ ErrorMessages.UNEXPECTED  }`, errorSnackbarOptions);
                }
                break;
            default:
                BinsenseStore.setAwaitingApi(false);
                enqueueSnackbar(`${ response.status }:${  ErrorMessages.UNEXPECTED }`, errorSnackbarOptions);

                throw new Error(JSON.stringify({
                    status: 'failed',
                    statusCode: response.status,
                    message: response.statusText,
                }));
        }
    }
};

export default handleError;
