/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import Constants from '../../../../helper/Constants';
import {observer} from 'mobx-react-lite';
import {useMemo, useState} from 'react';
import {styled, Tab, Tabs} from '@mui/material';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import BinsenseStore from '../../../../stores/BinsenseStore';
import FanAssociationsTable from './FanAssociationsTable';
import CableAssociationsTable from './CableAssociationsTable';
import TableButton from '../../../common/buttons/TableButton';
import deleteAssociationCable from '../../../../controllers/apiCalls/delete/deleteAssociationCable';
import deleteAssociationFan from '../../../../controllers/apiCalls/delete/deleteAssociationFan';
import {useSnackbar} from 'notistack';
// import AutomationAccessoriesTable from './AutomationAccessoriesTable';
import {Bin, Building, GrainBag} from '../../../../types/components/ApiTypes';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
});

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: '16px',
});

interface Props {
    structure: Bin | GrainBag | Building,
}

const AdvancedAssociationsPage = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ tab, setTab ] = useState(Constants.CURRENT_CABLE_ASSOCIATIONS);
    const [ endAssociationsConfirmationOpen, setEndAssociationsConfirmationOpen ] = useState(false);

    const currentData = useMemo(() => {
        if (tab === Constants.CURRENT_CABLE_ASSOCIATIONS) {
            return BinsenseStore.cableAssociationsData;
        }
        if (tab === Constants.CURRENT_FAN_ASSOCIATIONS) {
            return BinsenseStore.fanAssociationsData;
        }
        return BinsenseStore.automationAccessoriesData;
    }, [ tab, BinsenseStore.cableAssociationsData, BinsenseStore.fanAssociationsData,
        BinsenseStore.automationAccessoriesData ]);
    // @ts-ignore
    const disableButton = useMemo(() => currentData.filter((row: any) => row.selected).length === 0, [ currentData ]);

    const renderContent = () => {
        switch (tab) {
            case Constants.CURRENT_CABLE_ASSOCIATIONS:
                return (
                    <CableAssociationsTable structure={props.structure}/>
                );
            case Constants.CURRENT_FAN_ASSOCIATIONS:
                return (
                    <FanAssociationsTable structure={props.structure}/>
                );
            // case Constants.AUTOMATION_ACCESSORIES:
            //     return (
            //         <AutomationAccessoriesTable structure={props.structure}/>
            //     );
            default:
                return (<div/>);
        }
    };

    const handleChange = (event: any, newValue: string) => {
        setTab(newValue);
    };

    const onEndSelectedAssociations = () => {
        currentData.forEach(assoc => {
            if (assoc.selected) {
                if (tab === Constants.CURRENT_CABLE_ASSOCIATIONS) {
                    deleteAssociationCable(enqueueSnackbar, Number(assoc.id));
                }
                else if (tab === Constants.CURRENT_FAN_ASSOCIATIONS) {
                    deleteAssociationFan(enqueueSnackbar, Number(assoc.id));
                }
                else {
                    console.log('delete');
                }
            }
        });
    };

    const getButtonLabel = () => {
        if (tab === Constants.CURRENT_CABLE_ASSOCIATIONS) {
            return 'Cable';
        }
        if (tab === Constants.CURRENT_FAN_ASSOCIATIONS) {
            return 'Fan';
        }
        return Constants.AUTOMATION;
    };

    return (
        <Root>
            <Row>
                <Tabs
                    value={tab}
                    onChange={handleChange}
                >
                    <Tab
                        label={Constants.CURRENT_CABLE_ASSOCIATIONS}
                        value={Constants.CURRENT_CABLE_ASSOCIATIONS}
                    />
                    <Tab
                        label={Constants.CURRENT_FAN_ASSOCIATIONS}
                        value={Constants.CURRENT_FAN_ASSOCIATIONS}
                    />
                    {/* <Tab
                        label={Constants.AUTOMATION_ACCESSORIES}
                        value={Constants.AUTOMATION_ACCESSORIES}
                    /> */}
                </Tabs>
            </Row>
            <TableButton
                id="end_selected_associations_button"
                label={`End Selected ${ getButtonLabel() } Associations`}
                onClick={() => setEndAssociationsConfirmationOpen(true)}
                disabled={disableButton}
                noIcon
            />
            {renderContent()}
            {endAssociationsConfirmationOpen && (
                <ConfirmationModal
                    id="end_selected_associations_confirmation_modal"
                    open
                    close={() => setEndAssociationsConfirmationOpen(false)}
                    submit={onEndSelectedAssociations}
                    title="End Selected Cable Associations"
                    message="Are you sure you want to delete this cable association?"
                />
            )}
        </Root>
    );
});

export default AdvancedAssociationsPage;
