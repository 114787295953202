/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */
import { useState } from 'react';
import {Grid} from '@mui/material';
import DisplayGridItem from '../../../../common/displayTable/DisplayGridItem';
import DeviceBinViewPaper from './DeviceBinViewPaper';
import DeviceChartPaper from './DeviceChartPaper';
import {observer} from 'mobx-react-lite';
import DeviceInventoryPaper from './DeviceInventoryPaper';
import DeviceMaxTempHumidityPaper from './DeviceMaxTempHumidityPaper';
import FanStatusPaper from './FanStatusPaper';
import {Bin, Building, GrainBag} from '../../../../../types/components/ApiTypes';
import {Dispatch, SetStateAction} from 'react';
import DeviceAmbientConditionStationPaper from './DeviceAmbientConditionStationPaper';
import DevicePlenumConditionsPaper from './DevicePlenumConditionsPaper';
import Constants from '../../../../../helper/Constants';

interface Props {
    fanIdField: {
        value: string | number,
        setValue: Dispatch<SetStateAction<string | number>>,
    }
    bin: Bin | GrainBag | Building,
    hasHub?: boolean,
    siteId: number,
}

const ViewDeviceDisplayPage = observer((props: Props) => {
    const [ binChartVisible, setBinChartVisible ] = useState(false);

    const handleToggle = () => {
        setBinChartVisible((currentStatus) => !currentStatus);
    };

    const renderGridItem = (paper: object, xs: number) => (
        <DisplayGridItem
            xs={xs}
        >
            {paper}
        </DisplayGridItem>
    );

    window.resetMouseClickedForSensor();

    return (
        <Grid
            container
            spacing={0}
            alignItems="stretch"
        >
            {renderGridItem(<DeviceInventoryPaper bin={props.bin}/>, 4)}
            {renderGridItem(<DeviceMaxTempHumidityPaper bin={props.bin}/>, 4)}
            {renderGridItem((
                <FanStatusPaper
                    fanIdField={props.fanIdField}
                    bin={props.bin}
                />
            ), 4)}
            {props.hasHub && renderGridItem(
                <DeviceAmbientConditionStationPaper
                    siteId={props.siteId}
                    bin={props.bin}
                />, 6)}
            {props.hasHub && renderGridItem(<DevicePlenumConditionsPaper bin={props.bin}/>, 6)}
            <DisplayGridItem xs={5}>
                <DeviceBinViewPaper
                    handleToggle={handleToggle}
                    bin={props.bin}
                    displayAmbientTemp={props.bin.settings.hardwareType.toLowerCase() === Constants.LIVE.toLowerCase() ||
                        props.bin.settings.hardwareType.toLowerCase() === Constants.SOLO.toLowerCase()}
                />
            </DisplayGridItem>
            <DisplayGridItem xs={7}>
                <DeviceChartPaper binChartVisible={binChartVisible} />
            </DisplayGridItem>
        </Grid>
    );
});

export default ViewDeviceDisplayPage;
