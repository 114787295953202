import BinsenseStore from '../../stores/BinsenseStore';
import WebStore from '../../stores/WebStore';
import { Bin, Building, GrainBag, MarkerData, MasterUnit, Site, SiteStructures } from '../../types/components/ApiTypes';
import Constants from '../Constants';

function getCountForDealerView(selectedSiteId?: number, skipCurrentSite?: boolean): number {
    let count: number = 0;
    BinsenseStore.userStructures.forEach((userStructure: SiteStructures) => {
        if (userStructure.id == selectedSiteId && skipCurrentSite) {
            return;
        }

        const bins: Bin[] = userStructure.bins;
        for (let j = 0; j < bins.length; j++) {
            const masterUnits: MasterUnit[] = bins[j].hardware.masterUnits;
            for (let k = 0; k < masterUnits.length; k++) {
                if (!masterUnits[k].automation) {
                    count++;
                }
            }
        }

        // grain bags
        const grainBags: GrainBag[] = userStructure.grainBags;
        for (let j = 0; j < grainBags.length; j++) {
            const masterUnits: MasterUnit[] = grainBags[j].hardware.masterUnits;
            for (let k = 0; k < masterUnits.length; k++) {
                if (!masterUnits[k].automation) {
                    count++;
                }
            }
        }

        // and buildings
        const buildings: Building[] = userStructure.buildings;
        for (let j = 0; j < buildings.length; j++) {
            const masterUnits: MasterUnit[] = buildings[j].hardware.masterUnits;
            for (let k = 0; k < masterUnits.length; k++) {
                if (!masterUnits[k].automation) {
                    count++;
                }
            }
        }
    });

    return count;
}

function getCountForCustomerView(selectedSiteId?: number, skipCurrentSite?: boolean) {
    const sites: Site[] = BinsenseStore.userData.sites;
    let count: number = 0;
    // iterate through user sites
    for (let i = 0; i < sites.length; i++) {
        // skip site if specified & if not live
        if ((skipCurrentSite && sites[i].id === selectedSiteId) || !sites[i].live) {
            continue;
        }

        // check for masters on bins
        const bins: Bin[] = sites[i].bins;
        for (let j = 0; j < bins.length; j++) {
            const masterUnits: MasterUnit[] = bins[j].hardware.masterUnits;
            for (let k = 0; k < masterUnits.length; k++) {
                if (!masterUnits[k].automation) {
                    count++;
                }
            }
        }

        // grain bags
        const grainBags: GrainBag[] = sites[i].grainBags;
        for (let j = 0; j < grainBags.length; j++) {
            const masterUnits: MasterUnit[] = grainBags[j].hardware.masterUnits;
            for (let k = 0; k < masterUnits.length; k++) {
                if (!masterUnits[k].automation) {
                    count++;
                }
            }
        }

        // and buildings
        const buildings: Building[] = sites[i].buildings;
        for (let j = 0; j < buildings.length; j++) {
            const masterUnits: MasterUnit[] = buildings[j].hardware.masterUnits;
            for (let k = 0; k < masterUnits.length; k++) {
                if (!masterUnits[k].automation) {
                    count++;
                }
            }
        }
    }

    return count;
}

/**
 * getLiveCount
 * @param skipCurrentSite Should we count the devices on the currently selected site?
 * @param user User we are getting counts for. Only passed on the dealer side.
 * @param selectedSite The currently selected site. Only passed on the dealer side.
 * @param newSite Is this site new?
 * @returns Live count (number)
 */
export const getLiveCount = (skipCurrentSite?: boolean, user?: String, selectedSite?: number, newSite?: boolean) => {
    const selectedSiteId: number = !newSite ? selectedSite ?? WebStore.selectedSiteId : -1; // -1 to skip no sites

    // if user object is present, this is being called by a dealer
    // get user sites
    if (user) {
        return getCountForDealerView(selectedSiteId, skipCurrentSite);
    } else {
        return getCountForCustomerView(selectedSiteId, skipCurrentSite);
    }
};

/**
 * getLiveCountByMarkerData
 * @param markerData Markers on current site
 * @param user User we are getting counts for. Only passed on the dealer side.
 * @param selectedSite The currently selected site. Only passed on the dealer side.
 * @param newSite Is this site new?
 * @returns Live count including newly added devices
 */
export function getLiveCountByMarkerData(markerData: MarkerData[], user?: string, selectedSite?: number, newSite?: boolean) {
    let count: number = 0;

    // get the new counts for the current site
    markerData.forEach((data: MarkerData) => {
        if (data?.display?.buildingData) {
            // check for master
            if (data.display.buildingData.deviceType.toLowerCase() == Constants.MASTER.toLowerCase()) {
                count++;
            }
        }
        else if (data?.display?.grainBagData) {
            // check for master
            if (data.display.grainBagData.deviceType.toLowerCase() == Constants.MASTER.toLowerCase()) {
                count++;
            }
        }
        else if (data?.display?.grainBinData) {
            // check for master
            if (data.display.grainBinData.deviceType.toLowerCase() == Constants.MASTER.toLowerCase()) {
                count++;
            }
        }
        else if (data?.display?.grainPileData) {
            // check for master
            if (data.display.grainPileData.deviceType.toLowerCase() == Constants.MASTER.toLowerCase()) {
                count++;
            }
        }
    });

    count += getLiveCount(true, user, selectedSite, newSite);

    return count;
}