/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const getDateString = (date: Date, includeTime?: boolean) => {
    let dateStr = `${ date.getMonth() + 1 }/${ date.getDate() }/${ date.getFullYear() }`;
    if (includeTime) {
        let hours = date.getHours();
        const isAm = hours < 12;
        if (hours === 0) {
            hours = 12;
        }
        else if (hours > 12) {
            hours = hours - 12;
        }
        dateStr = `${ dateStr } ${ hours }:${ date.getMinutes() < 10 ? `0${ date.getMinutes() }` : date.getMinutes() } ${
            isAm ? 'AM' : 'PM' }`;
    }
    return dateStr;
};

export default getDateString;
