/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Menu} from '@mui/material';

interface Props {
    anchorEl: any,
    open: boolean,
    handleClose: () => void,
    children: any,
}

function PopupMenu(props: Props) {
    return (
        <Menu
            anchorEl={props.anchorEl}
            open={props.open}
            onClose={props.handleClose}
            onClick={props.handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    maxHeight: '400px',
                    overflow: 'auto',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                },
            }}
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top',
            }}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
            }}
        >
            {props.children}
        </Menu>
    );
}

export default PopupMenu;
