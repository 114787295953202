import { Bin, Building, GrainBag } from '../../types/components/ApiTypes';

const binHasAutomationRemote = (bin: Bin | GrainBag | Building) => {
    for (let i = 0; i < bin.hardware.remoteUnits.length; i++) {
        if (bin.hardware.remoteUnits[i].automation != null &&
            bin.hardware.remoteUnits[i].automation == true) {
                return true;
            }
    }

    return false;
};

export default binHasAutomationRemote;