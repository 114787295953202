/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Typography, styled} from '@mui/material';
import BaseModal from '../modal/BaseModal';
import UIStateStore from '../../../stores/UIStateStore';

const PopupContainer = styled('div')({
    width: '400px',
    padding: '16px',
});

const SatelliteViewPopup = () => (
    <BaseModal
        id='satellite_view_popup'
        open={UIStateStore.showSatelliteViewMessage}
        close={() => UIStateStore.setShowSatelliteViewMessage(false)}
        title='Satellite View'
        cancelButtonText='Got It!'
        variant = 'contained'
        buttonColor='primary'
    >
        <PopupContainer>
            <Typography>
                {`We have added Satellite View to Bin-Sense! Just a note that you may need to adjust
                your bins position over the new map. To do this, simply click the edit button >
                drag structures to new placement > click save button.`}
            </Typography>
        </PopupContainer>
    </BaseModal>
);

export default SatelliteViewPopup;