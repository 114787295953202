/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import downloadFile from './downloadFile';
import generateCSVFileContents from './generateCSVFileContents';
import {TableCSVColumn} from '../../types/components/ApiTypes';

export default function exportTableToCSV(columns: TableCSVColumn[], rows: any[], fileName: string) {
    const blob = new Blob([ generateCSVFileContents(columns, rows) ], {type: `${ 'text/csv;charset=utf-8' };`});
    downloadFile(blob, `${ fileName }.csv`);
}
