/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Button, styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import KeyValueTypography from '../../../common/typography/KeyValueTypography';
import Constants from '../../../../helper/Constants';
import {Dispatch, SetStateAction} from 'react';
import {cloneDeep} from 'lodash';
import getTestID from '../../../../helper/functions/getTestID';
import {Add} from '@mui/icons-material';
import {InputFieldProps} from '../../../../types/components/ApiTypes';
import EnterSerialInputFields from './EnterSerialInputFields';
import TooltipStore from '../../../../stores/TooltipStore';
import TooltipMessages from '../../../../helper/TooltipMessages';
import InfoTooltip from '../../../common/InfoTooltip';

interface Props {
    serial: {
        value: string[],
        setValue: Dispatch<SetStateAction<string[]>>
    },
    serialError?: boolean,
    deviceType?: string,
    associatedMaster?: InputFieldProps
    automationMasterHub?: boolean,
    automationRemote?: boolean,
    masterSerials?: string[],
}

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '8px',
    width: '100%',
});

const EnterSerialStep = observer((props: Props) => {
    const addSerialField = () => {
        const clone = cloneDeep(props.serial.value);
        clone.push('');
        props.serial.setValue(clone);
    };

    const getSerialLabel = () => {
        if (props.automationRemote) {
            return 'Enter Automation Remote Unit Serial';
        }
        if (props.deviceType) {
            return `Enter ${ (props.deviceType?.toLowerCase() === Constants.SOLO.toLowerCase()
                    ? Constants.SOLO
                    : props.deviceType?.charAt(0).toUpperCase() + props.deviceType?.slice(1)) } Serial`;
        }
        return Constants.ENTER_SERIAL;
    };

    return (
        <Root>
            {!props.automationMasterHub && (
                <KeyValueTypography
                    label="STEP 4:"
                    value={getSerialLabel()}
                />
            )}
            <EnterSerialInputFields
                deviceType={props.deviceType}
                serial={props.serial}
                associatedMaster={props.associatedMaster}
                automationMasterHub={props.automationMasterHub}
                automationRemote={props.automationRemote}
                masterSerials={props.masterSerials}
            />
            {(!props.automationMasterHub && (props.deviceType === Constants.MASTER.toLowerCase() ||
                props.deviceType === Constants.REMOTE.toLowerCase() ||
                props.deviceType?.toLowerCase() === Constants.PLUS.toLowerCase())) && (
                <Row>
                    <Button
                        {...getTestID('add_serial_button')}
                        startIcon={<Add/>}
                        onClick={addSerialField}
                        disabled={props.serial.value.length >= 5}
                    >
                        Add Additional {props.deviceType || props.automationRemote
                        ? Constants.REMOTE : Constants.DEVICE} to Structure
                    </Button>
                    <InfoTooltip
                        id="add_device_tooltip_button"
                        open={TooltipStore.beginTooltips && TooltipStore.showAddDeviceMessages}
                        message={TooltipMessages.DEVICES}
                        onClick={() => TooltipStore.setShowAddDeviceMessages(false, true)}
                    />
                </Row>
            )}
            {props.serialError && (
                <Typography
                    {...getTestID('serial_error')}
                    color='error'
                >
                    One or more of the given serials is unavailable or invalid
                </Typography>
            )}
        </Root>
    );
});

export default EnterSerialStep;
