/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import WebStore from '../../stores/WebStore';
import Colors from '../Colors';

const getMapTooltipStyling = () => ({
    maxWidth: '500px',
    backgroundColor: WebStore.lightMode ? 'white' : '#121212',
    color: WebStore.lightMode ? 'black' : 'white',
    boxShadow: WebStore.lightMode ? Colors.boxShadowLight : Colors.boxShadowDark,
    backgroundImage: WebStore.lightMode ? ''
        : 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
    padding: '16px',
});

export default getMapTooltipStyling;
