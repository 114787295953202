/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import TextField from '@mui/material/TextField';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import getTestID from '../../../helper/functions/getTestID';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import {RemoveCircle} from '@mui/icons-material';

interface Props {
    id: string,
    label: string,
    field: InputFieldProps,
    textFieldProps?: object,
    onBlur?: (value: string) => void;
    onSubmit: () => void;
}

const RemoveTextFieldInput = (props: Props) => (
    <TextField
        {...getTestID(props.id)}
        fullWidth
        label={props.label}
        value={props.field.value}
        onChange={(event) => props.field.setValue &&
            props.field.setValue(event.target.value)}
        error={props.field.error !== '' && props.field.error !== undefined}
        helperText={props.field.error}
        {...props.textFieldProps}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton
                        {...getTestID(`${ props.id  }_remove`)}
                        onClick={props.onSubmit}
                    >
                        <RemoveCircle/>
                    </IconButton>
                </InputAdornment>
            ),
        }}
    />
)
;

export default RemoveTextFieldInput;
