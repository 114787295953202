/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Bin, Building, GrainBag, Pile} from '../../types/components/ApiTypes';
import Constants from '../Constants';

const getPowerSource = (bin: Bin | GrainBag | Building | Pile) => {
        if (bin.hardware.masterUnits.length > 0) {
                return bin.hardware.masterUnits[0].status.acPower ? Constants.PLUGGED_IN : Constants.BATTERY_POWERED;
        }
        if (bin.hardware.remoteUnits.length > 0) {
                return bin.hardware.remoteUnits[0].status.acPower ? Constants.PLUGGED_IN : Constants.BATTERY_POWERED;
        }
        return Constants.BATTERY_POWERED;
};

export default getPowerSource;
