/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Divider, styled, Typography} from '@mui/material';
import ToolbarButton from './ToolbarButton';
import {observer} from 'mobx-react-lite';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
});

interface Props {
    title: string,
    size: number,
    buttons: {
        id: string,
        title: string,
        src: string,
        onClick: (obj: object) => void,
    }[],
    isFirst?: boolean,
}

const ToolbarCategory = observer((props: Props) => (
    <Row>
        {props.isFirst && (
            <Divider
                orientation="vertical"
                flexItem
            />
        )}
        <Typography style={{ marginLeft: '16px' }}>{props.title}</Typography>
        <Root>
            {props.buttons.map(button => (
                <ToolbarButton
                    id={button.id}
                    key={button.title}
                    title={button.title}
                    src={button.src}
                    onClick={button.onClick}
                    size={props.size}
                />
            ))}
        </Root>
        <Divider
            orientation="vertical"
            flexItem
        />
    </Row>
));

export default ToolbarCategory;
