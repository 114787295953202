/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo, useState} from 'react';
import Constants from '../../../../helper/Constants';
import BinDetailsForm from './BinDetailsForm';
import {BinType, DistanceUnit, GrainBinData, StructIdSerials} from '../../../../types/components/ApiTypes';
import StructureSetupModal from './StructureSetupModal';
import getAllStructNames from '../../../../helper/functions/getAllStructNames';
// import WebStore from '../../../../stores/WebStore';

interface Props {
    open: boolean,
    close: () => void,
    onSubmit: (grainBinData: GrainBinData) => void,
    createBinType?: BinType,
    grainBinData?: GrainBinData,
    masterSerials: string[],
    automationMasterSerials: string[],
    structureId?: number,
    disableAutomation: boolean,
    markerStructNames?: string[],
    markerSerials?: StructIdSerials[],
    siteSerials?: StructIdSerials[],
    siteId?: number,
}

const BinSetupModal = (props: Props) => {
    const [ name, setName ] = useState(props.grainBinData?.name ? props.grainBinData.name : '');
    const [ nameError, setNameError ] = useState('');
    const [ structure, setStructure ] = useState(props.grainBinData?.type ? props.grainBinData.type
        : (props.createBinType ? props.createBinType : BinType.flat));
    const [ structureError, setStructureError ] = useState('');
    const [ peakHeight, setPeakHeight ] = useState(props.grainBinData?.peakHeight
        ? props.grainBinData.peakHeight.toString() : '');
    const [ peakHeightError, setPeakHeightError ] = useState('');
    const [ eaveHeight, setEaveHeight ] = useState(props.grainBinData?.eaveHeight
        ? props.grainBinData.eaveHeight.toString() : '');
    const [ eaveHeightError, setEaveHeightError ] = useState('');
    const [ diameter, setDiameter ] = useState(props.grainBinData?.diameter
        ? props.grainBinData.diameter.toString() : '');
    const [ diameterError, setDiameterError ] = useState('');
    const [ powerSource, setPowerSource ] = useState(props.grainBinData?.powerSource
        ? props.grainBinData.powerSource : '');
    const [ powerSourceError, setPowerSourceError ] = useState('');
    const [ heightError, setHeightError ] = useState('');
    const [ bushelCapacity, setBushelCapacity ] = useState(props.grainBinData?.bushelCapacity
        ? props.grainBinData.bushelCapacity.toString() : '');
    const [ bushelCapacityError, setBushelCapacityError ] = useState('');
    const [ useDimensions, setUseDimensions ] = useState(props.grainBinData?.peakHeight ? true : false);
    const [ tab, setTab ] = useState(props.grainBinData?.peakHeight ? Constants.DIMENSIONS : Constants.BUSHEL_CAPACITY);

    const resetTabValues = (newValue: string) => {
        if (newValue === Constants.DIMENSIONS) {
            setPeakHeight(props.grainBinData?.peakHeight ? props.grainBinData.peakHeight.toString() : '');
            setEaveHeight(props.grainBinData?.eaveHeight ? props.grainBinData.eaveHeight.toString() : '');

            setBushelCapacity('');
        }
        else {
            setBushelCapacity(props.grainBinData?.bushelCapacity ? props.grainBinData.bushelCapacity.toString() : '');

            setPeakHeight('');
            setEaveHeight('');
        }
    };

    const handleTabChange = (event: any, newValue: string) => {
        setUseDimensions(newValue === Constants.DIMENSIONS);
        setTab(newValue);
        resetTabValues(newValue);
    };

    const structNames = getAllStructNames(props.markerStructNames, props.siteId);

    const nameOnPageLoad = useMemo(() => props.grainBinData?.name ? props.grainBinData.name : '',
        [ props.grainBinData ]);

    const resetErrors = () => {
        if (name.trim() !== '') {
            setNameError('');
        }
        if (useDimensions) {
            if (peakHeight !== '') {
                setPeakHeightError('');
            }
            if (eaveHeight !== '') {
                setEaveHeightError('');
            }
        }
        else {
            if (bushelCapacity !== '') {
                setBushelCapacityError('');
            }
        }

        if (diameter !== '') {
            setDiameterError('');
        }
        if (powerSource !== '') {
            setPowerSourceError('');
        }
        if (heightError !== '') {
            setHeightError('');
        }
    };

    const submit = (device: string, deviceType: string, sn: string[], associatedMaster: string) => {
        props.onSubmit({
            name,
            type: structure,
            peakHeight: useDimensions ? Number(peakHeight) : null,
            eaveHeight: useDimensions ? Number(eaveHeight) : null,
            bushelCapacity: useDimensions ? null : Number(bushelCapacity),
            device,
            deviceType: deviceType.toLowerCase(),
            sn,
            associatedMaster,
            diameter: Number(diameter),
            unit: DistanceUnit.feet,
            powerSource,
        });
    };

    const isBinDetailsValid = () => (name.trim() !== '') && diameter !== '' && powerSource !== ''  &&
        !((nameOnPageLoad === '' || nameOnPageLoad.trim().toLowerCase() !== name.trim().toLowerCase()) &&
            structNames && structNames.some(item => item.trim().toLowerCase() === name.trim().toLowerCase())) &&
        ((useDimensions && peakHeight !== '' && eaveHeight !== '' && Number(peakHeight) >= Number(eaveHeight)) ||
        (!useDimensions && bushelCapacity !== ''));

    const setBinErrors = () => {
        resetErrors();
        if (name.trim() === '') {
            setNameError('Please enter a name for this bin.');
        }
        if ((nameOnPageLoad === '' || nameOnPageLoad.trim().toLowerCase() !== name.trim().toLowerCase()) &&
            structNames && structNames.some(item => item.trim().toLowerCase() === name.trim().toLowerCase())) {
            setNameError('This name already exists');
        }
        if (useDimensions) {
            if (peakHeight === '') {
                setPeakHeightError('Please enter a peak height for this bin.');
            }
            if (eaveHeight === '') {
                setEaveHeightError('Please enter an eave height for this bin.');
            }
            if (Number(peakHeight) < Number(eaveHeight)) {
                setHeightError('The eave height must be less than the peak height.');
            }
        } else {
            if (bushelCapacity === '') {
                setBushelCapacityError('Please enter a bushel capacity for this bin.');
            }
        }
        if (diameter === '') {
            setDiameterError('Please enter a diameter for this bin.');
        }
        if (powerSource === '') {
            setPowerSourceError('Please enter a power source for this bin.');
        }
    };

    return (
        <StructureSetupModal
            id='bin_setup'
            modalTitle={Constants.BIN_SETUP}
            firstStepTitle={Constants.BIN_DETAILS}
            isFirstStepValid={isBinDetailsValid}
            setFirstStepErrors={setBinErrors}
            resetFirstStepErrors={resetErrors}
            onSubmit={submit}
            close={props.close}
            data={props.grainBinData}
            masterSerials={props.masterSerials}
            automationMasterSerials={props.automationMasterSerials}
            structureId={props.structureId}
            disableAutomation={props.disableAutomation}
            markerSerials={props.markerSerials}
            siteSerials={props.siteSerials}
        >
            <BinDetailsForm
                useDimensions={useDimensions}
                tab={tab}
                onTabChange={handleTabChange}
                name={{
                    value: name,
                    setValue: setName,
                    error: nameError,
                    setError: setNameError,
                }}
                structure={{
                    value: structure,
                    setValue: setStructure,
                    error: structureError,
                    setError: setStructureError,
                }}
                peakHeight={{
                    value: peakHeight,
                    setValue: setPeakHeight,
                    error: peakHeightError,
                    setError: setPeakHeightError,
                }}
                eaveHeight={{
                    value: eaveHeight,
                    setValue: setEaveHeight,
                    error: eaveHeightError,
                    setError: setEaveHeightError,
                }}
                bushelCapacity={{
                    value: bushelCapacity,
                    setValue: setBushelCapacity,
                    error: bushelCapacityError,
                    setError: setBushelCapacityError,
                }}
                diameter={{
                    value: diameter,
                    setValue: setDiameter,
                    error: diameterError,
                    setError: setDiameterError,
                }}
                powerSource={{
                    value: powerSource,
                    setValue: setPowerSource,
                    error: powerSourceError,
                    setError: setPowerSourceError,
                }}
                heightError={heightError}
            />
        </StructureSetupModal>
    );
};

export default BinSetupModal;
