/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';

const StyledTypography = styled(Typography)(({theme}) => ({
    // @ts-ignore
    color: theme.palette.primary.titleText,
    fontSize: '20px',
}));

interface Props {
    title: string,
}

function TitleTypography(props: Props) {
    return (
        <StyledTypography>
            {props.title}
        </StyledTypography>
    );
}

export default TitleTypography;
