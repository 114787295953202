import { Site } from '../../../../types/components/ApiTypes';
import { observer } from 'mobx-react-lite';
import AlertHistoryTable from './AlertHistoryTable';
import {styled} from '@mui/material';

interface Props {
    site: Site
}

const Root = styled('div')({ width: '100%' });

const DealerAlertHistory = observer((props: Props) => (
    <Root>
        <AlertHistoryTable site={props.site} />
    </Root>
));

export default DealerAlertHistory;