import callPutAPI from '../../callPutAPI';
import updateData from '../../../helper/functions/updateData';

const putStructureSettings = (enqueueSnackbar: any, siteId: number, body: any) => {
    callPutAPI(`structureSettings/${ siteId }`,
        body,
        (response) => {
            if (response === 'OK') {
                updateData(enqueueSnackbar);
            }
        },
        enqueueSnackbar,
        'Settings updated successfully'
    );
};

export default putStructureSettings;