/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import Constants from '../../../../helper/Constants';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import DisplayGridItem from '../../../common/displayTable/DisplayGridItem';
import SelectFieldInput from '../../../common/input/SelectFieldInput';
import DisplayGridActions from '../../../common/displayTable/DisplayGridActions';
import {Send} from '@mui/icons-material';
import replaceSpaces from '../../../../helper/functions/replaceSpaces';
import BinsenseStore from '../../../../stores/BinsenseStore';
import {useMemo} from 'react';
import postServerCommand from '../../../../controllers/apiCalls/post/postServerCommand';
import {useSnackbar} from 'notistack';
import getIndex from '../../../../helper/functions/getIndex';
import useWindowSize from '../../../../helper/hooks/useWindowSize';

interface Props {
    row: any,
    siteId: number
}

const CommandsTable = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const commandsOptions = useMemo(() => {
        const commandGroups = BinsenseStore.dataModel.serverCommandsGroupMap;
        const index = commandGroups.findIndex(commandGroup =>
            commandGroup.groupName === props.row.device.toUpperCase());
        if (index > -1) {
            return commandGroups[index].commands.map(command => ({
                id: `${ command.id }_command`,
                title: command.commandDesc,
                value: command.id.toString(),
            }));
        }
        return [];
    }, [ props.row ]);

    const renderGridLabel = (id: string, label: string, xs: number, lengthLimit?: number) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={xs}
            lengthLimit={lengthLimit}
        />
    );

    const idBody = replaceSpaces(props.row.bin);

    const setCommand = (newValue: string) => {
        const clone = JSON.parse(JSON.stringify(BinsenseStore.commandsData));
        const index = getIndex(props.row.id, clone);
        if (index > -1) {
            clone[index].command = newValue;
        }
        BinsenseStore.setCommandsData(clone);
    };

    const sendCommand = () => {
        const body = {
            commandId: Number(props.row.command),
            deviceType: props.row.device,
            arguments: [],
            scheduledTime: new Date().getTime(),
        };
        postServerCommand(enqueueSnackbar, props.row.serialNumber, body, props.siteId);
    };

    return (
        <DisplayGridContent
            key={`${ props.row.id }_user`}
            topMargin='0px'
        >
            {renderGridLabel(`commands_${ idBody }_bin`, props.row.bin, largeScreen ? 2.75 : 2, largeScreen ? 20 : 10)}
            {renderGridLabel(`commands_${ idBody }_device`, props.row.device, largeScreen ? 1.5 : 2, 8)}
            {renderGridLabel(`commands_${ idBody }_serial`, props.row.serialNumber, largeScreen ? 1.75 : 2, 10)}
            {renderGridLabel(`commands_${ idBody }_number_of_cables`, props.row.numberOfCables, 1.5)}
            <DisplayGridItem xs={3}>
                <SelectFieldInput
                    id={`commands_${ idBody }_command`}
                    label={Constants.COMMAND}
                    field={{
                        value: props.row.command,
                        setValue: setCommand,
                    }}
                    options={commandsOptions}
                />
            </DisplayGridItem>
            <DisplayGridActions
                buttons={[
                    {
                        id: `commands_${ idBody }_send_button`,
                        onClick: sendCommand,
                        icon: <Send/>,
                        hoverText: Constants.SEND,
                        disabled: props.row.command === '',
                    },
                ]}
                xs={1.5}
                id={`${ idBody }_command_actions`}
            />
        </DisplayGridContent>
    );
});

export default CommandsTable;
