/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Button, styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import KeyValueTypography from '../../../common/typography/KeyValueTypography';
import Constants from '../../../../helper/Constants';
import DeviceTypePanel from './DeviceTypePanel';
import getTestID from '../../../../helper/functions/getTestID';
import {InputFieldProps} from '../../../../types/components/ApiTypes';
import TooltipStore from '../../../../stores/TooltipStore';
import TooltipMessages from '../../../../helper/TooltipMessages';
import InfoTooltip from '../../../common/InfoTooltip';

interface Props {
    selectedDevice: InputFieldProps,
    //disableAutomation is to prevent adding automation remotes if there is no hub yet
    disableAutomation: boolean,
    originalDevice: string
    structureId?: number
}

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '8px',
    width: '100%',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const OptionRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

const SelectDeviceStep = observer((props: Props) => {
    const newStruct = props.structureId === undefined || props.structureId < 1;
    const renderBinTypeOption = (label: string, disabled: boolean = false) => (
        <DeviceTypePanel
            label={label}
            selectedDevice={props.selectedDevice.value}
            //@ts-ignore
            setSelectedDevice={props.selectedDevice.setValue}
            disabled={disabled}
        />
    );

    return (
        <Root>
            <KeyValueTypography
                label="STEP 2:"
                value={Constants.SELECT_DEVICE}
            />
            <OptionRow
                style={{ paddingTop: '32px' }}
            >
                {renderBinTypeOption(Constants.DIRECT,
                    (!newStruct && props.originalDevice !== 'direct' && props.originalDevice !== ''))}
                {renderBinTypeOption(Constants.SOLO,
                    (!newStruct && props.originalDevice !== 'solo' && props.originalDevice !== ''))}
            </OptionRow>
            <OptionRow>
                {renderBinTypeOption(Constants.LIVE,
                    (!newStruct && props.originalDevice !== 'live' && props.originalDevice !== ''))}
                {renderBinTypeOption(Constants.PLUS, (props.disableAutomation ||
                    (props.selectedDevice.value.toLowerCase() === Constants.LIVE.toLowerCase()) ||
                    (props.selectedDevice.value.toLowerCase() === Constants.SOLO.toLowerCase())) &&
                    (!newStruct && props.originalDevice !== 'plus' && props.originalDevice !== ''))}
            </OptionRow>
            <Row>
                <Button
                    {...getTestID('not_monitored_button')}
                    //@ts-ignore
                    onClick={() => props.selectedDevice.setValue(Constants.NOT_MONITORED)}
                    disabled={!newStruct && props.originalDevice !== ''}
                >
                    {Constants.NOT_MONITORED}
                </Button>
                <InfoTooltip
                    id="not_monitored_tooltip_button"
                    open={TooltipStore.beginTooltips && TooltipStore.showNotMonitoredMessages}
                    message={TooltipMessages.NOT_MONITORED}
                    onClick={() => TooltipStore.setShowNotMonitoredMessages(false, true)}
                />
            </Row>
            {props.selectedDevice.error !== '' && (
                <Typography
                    {...getTestID('select_device_error')}
                    color='error'
                >
                    {props.selectedDevice.error}
                </Typography>
            )}
        </Root>
    );
});

export default SelectDeviceStep;