/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Typography} from '@mui/material';
import Colors from '../../../../../helper/Colors';

interface Props {
    value: string,
    isHigh?: boolean,
    sensorColor?: string,
}

const SensorTableCell = (props: Props) => (
    <Typography color={props.sensorColor ? props.sensorColor : props.isHigh ? Colors.tableLabelRed : Colors.tableLabelGreen}>
        {props.value}
    </Typography>
);

export default SensorTableCell;
