import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';

const getBinNotes = (enqueueSnackbar: any, id: number) => {
    callAPI(`binNotes/${ id }`,
        (response) => {
            BinsenseStore.setBinNotesData(response);
        },
        enqueueSnackbar
    );
};

export default getBinNotes;