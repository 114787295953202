/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import DisplayGridPaper from './DisplayGridPaper';
import {observer} from 'mobx-react-lite';
import {useEffect, useMemo, useState} from 'react';
import getPaginationData from '../../../helper/functions/getPaginationData';
import Pagination from '../pagination/Pagination';
import getRowsPerPage from '../../../helper/functions/getRowsPerPage';

interface Props {
    rows: any[],
    renderRow: (row: any) => object,
    children: object,
    pageRowCount?: number
}

const PaginationTable = observer((props: Props) => {
    const defaultRowCount = props.pageRowCount == null ? 30 : props.pageRowCount;
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(props.rows.length >= defaultRowCount
        ? defaultRowCount : props.rows.length);

    useEffect(() => {
        setRowsPerPage(getRowsPerPage(rowsPerPage, props.rows.length));
        setPage(0);
    }, [ props.rows ]);

    const displayedRows = useMemo(() => getPaginationData(props.rows, page, rowsPerPage),
        [ props.rows, page, rowsPerPage ]);

    return (
        <div style={{ paddingBottom: '8px' }}>
            <DisplayGridPaper>
                {props.children}
                {displayedRows.map(row => props.renderRow(row))}
            </DisplayGridPaper>
            <Pagination
                count={props.rows.length}
                pageField={{
                    value: page,
                    setValue: setPage,
                }}
                rowsPerPageField={{
                    value: rowsPerPage,
                    setValue: setRowsPerPage,
                }}
            />
        </div>
    );
});

export default PaginationTable;
