export default {
    'countries': [
        {
            id: '14_option',
            title: 'Australia',
            value: '14',
        },
        {
            id: '40_option',
            title: 'Canada',
            value: '40',
        },
        {
            id: '144_option',
            title: 'Mexico',
            value: '144',
        },
        {
            id: '236_option',
            title: 'United States',
            value: '236',
        },
    ],
};
