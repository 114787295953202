/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {DistanceUnit, GrainBag} from '../../types/components/ApiTypes';
import getDeviceType from './getDeviceType';
import getDevice from './getDevice';
import getAssociatedMaster from './getAssociatedMaster';
import getSerialNumbers from './getSerialNumbers';
import getPowerSource from './getPowerSource';
import Constants from '../Constants';

const getGrainBagData = (bag: GrainBag) => ({
        associatedMaster: getAssociatedMaster(bag.settings?.hardwareType ? bag.settings.hardwareType : '',
            bag.hardware.remoteUnits),
        device: getDevice(bag),
        deviceType: getDeviceType(bag),
        length: bag.length,
        name: bag.name ? bag.name : Constants.NA,
        powerSource: getPowerSource(bag),
        quantity: bag.capacity ? bag.capacity : 0,
        rotation: bag.rotation ? bag.rotation : 0,
        sn: getSerialNumbers(bag),
        unit: DistanceUnit.feet,
        width: bag.width,
});

export default getGrainBagData;
