/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {MarkerData} from '../../types/components/ApiTypes';
import Constants from '../Constants';
import getUnitFields from './getUnitFields';

const getPutAutomationBodyFromMarker = (marker: MarkerData) => {
    const data = marker.display?.automationHubData;
    return data ? {
        id: marker.id,
        name: Constants.AUTOMATION_HUB,
        rotation: 0,
        latitude: marker.latitude,
        longitude: marker.longitude,
        hardware: {
            masterUnits: [ getUnitFields(data.serial, Constants.PLUGGED_IN, true) ],
        },
    } : {};
};

export default getPutAutomationBodyFromMarker;
