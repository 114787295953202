/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import handleError from './handleError';
import loginStore from '../stores/LoginStore';
import BinsenseStore from '../stores/BinsenseStore';
import urlStore from '../stores/urlStore';
import getBaseURL from './getBaseURL';
import {EnqueueSnackbar} from '../types/SnackbarTypes';
import showStatusFailMessage from './showStatusFailMessage';

const callAPI = (uri: string,
                 onSuccess: (response: any) => void,
                 enqueueSnackbar: EnqueueSnackbar,
                 userData?: boolean,
                 skipStore?: boolean,
                 on401Fail?: () => void,
                 onFail?: () => void) => {
    if (!skipStore) {
        if (userData) {
            BinsenseStore.setAwaitingUserData(true);
        } else {
            BinsenseStore.setAwaitingApi(true);
        }
    }

    fetch(
        `${ getBaseURL() }${ urlStore.baseDirectory }${ uri }`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-API-TOKEN': loginStore.token,
            },
        }
    )
        .then((response) => {
            handleError(response, enqueueSnackbar, on401Fail);
            return response.json();
        })
        .then((receivedServerData) => {
            if (!skipStore) {
                setTimeout(() => {
                    if (userData) {
                        BinsenseStore.setAwaitingUserData(false);
                    } else {
                        BinsenseStore.setAwaitingApi(false);
                    }
                }, 100);
            }

            if (receivedServerData.status && receivedServerData.status === 'fail') {
                showStatusFailMessage(receivedServerData, enqueueSnackbar);
                if (onFail) {
                    onFail();
                }
            } else {
                if (receivedServerData.displayMessage && receivedServerData.message &&
                    receivedServerData.messageTitle) {
                    enqueueSnackbar(receivedServerData.message, {variant: 'success'});
                }
                onSuccess(receivedServerData);
            }
        })
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error.message);
            if (!skipStore) {
                if (!userData) {
                    BinsenseStore.setAwaitingApi(false);
                }
            }
        });
};

export default callAPI;
