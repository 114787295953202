/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import ContentPanel from '../../common/ContentPanel';
import MyUsersTable from '../myUsers/MyUsersTable';
import {useState} from 'react';
import AddUserModal from '../myUsers/AddUserModal';
import Constants from '../../../helper/Constants';
import TableButton from '../../common/buttons/TableButton';

const DealerMyUsers = () => {
    const [ addUserModalOpen, setAddUserModalOpen ] = useState(false);

    return (
        <ContentPanel>
            <TableButton
                id='add_user_button'
                label={Constants.ADD_USER}
                onClick={() => setAddUserModalOpen(true)}
            />
            <MyUsersTable/>
            {addUserModalOpen && (
                <AddUserModal
                    open
                    close={() => setAddUserModalOpen(false)}
                />
            )}
        </ContentPanel>
    );
};

export default DealerMyUsers;
