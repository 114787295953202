/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import BaseWizard from '../../common/wizard/BaseWizard';
import {observer} from 'mobx-react-lite';
import Constants from '../../../helper/Constants';
import {useEffect, useMemo, useState} from 'react';
import SelectSitePaper from './SelectSitePaper';
import WebStore from '../../../stores/WebStore';
import BinsenseStore from '../../../stores/BinsenseStore';
import getIndex from '../../../helper/functions/getIndex';
import {MarkerData, SiteLocationData} from '../../../types/components/ApiTypes';
import AddDevicesPaper from '../createSite/newSiteWizard/AddDevicesPaper';
import getMarkerData from '../../../helper/functions/getMarkerData';
import getSiteStructures from '../../../controllers/apiCalls/get/getSiteStructures';
import {useSnackbar} from 'notistack';
import saveMarkerData from '../../../helper/functions/saveMarkerData';
import checkAddToSubscription from '../../../helper/functions/checkAddToSubscription';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import { getBalanceByUsername } from '../../../controllers/apiCalls/get/getBalance';
import getUserStructures from '../../../controllers/apiCalls/get/getUserStructures';

const steps = [
    Constants.SELECT_SITE,
    Constants.ADD_STRUCTURES,
];

const DealerAddNewWizard = observer(() => {
    const {enqueueSnackbar} = useSnackbar();

    const [ user, setUser ] = useState<string | null>(null);
    const [ userError, setUserError ] = useState('');
    const [ site, setSite ] = useState('');
    const [ siteError, setSiteError ] = useState('');
    const [ addToSubscriptionMessage, setAddToSubscriptionMessage ] = useState('');
    const [ showAddToSubscriptionModel, setShowAddToSubscriptionModel ] = useState(false);

    const selectedSite = useMemo(() => {
        if (user !== null && site !== '') {
            getSiteStructures(enqueueSnackbar, Number(site));
            const customers = BinsenseStore.dealerUserData.customers;
            const userIndex = customers.findIndex(customer =>
                (customer.account.accountEmail === user || customer.account.userName === user));
            const siteIndex = getIndex(Number(site), customers[userIndex].sites);
            if (user) {
                getUserStructures(undefined, user);
                getBalanceByUsername(undefined, user);
            }
            return customers[userIndex].sites[siteIndex];
        }
        return undefined;
    }, [ site, BinsenseStore.dealerUserData, user ]);

    const selectedSiteWithStructures = useMemo(() => {
        if (selectedSite) {
            return {
                ...selectedSite,
                ...BinsenseStore.siteStructures,
            };
        }
        return undefined;
    }, [ selectedSite, BinsenseStore.siteStructures ]);

    const siteLocationData = useMemo(() => {
        if (selectedSite) {
            return {
                id: selectedSite.id,
                name: selectedSite.name,
                longitude: selectedSite.longitude,
                latitude: selectedSite.latitude,
                zoom: 17,
            };
        }
        return {} as SiteLocationData;
    }, [ selectedSite ]);

    const [ markerData, setMarkerData ] = useState<MarkerData[]>([]);

    const onMarkerUpdate = () => {
        if (!WebStore.editingMap) {
            WebStore.setEditingMap(true);
        }
    };

    useEffect(() => {
        if (selectedSiteWithStructures) {
            setMarkerData(getMarkerData(selectedSiteWithStructures));
        }
    }, [ selectedSiteWithStructures ]);

    const save = () => {
        if (selectedSiteWithStructures) {
            saveMarkerData(enqueueSnackbar, selectedSiteWithStructures, markerData);
        }
        WebStore.setEditingMap(false);
    };

    const submit = () => {
        let message: string = '';
        if (selectedSite?.live && user) {
            message = checkAddToSubscription(markerData, user, selectedSite.id);
        }

        if (message.length > 0) {
            setAddToSubscriptionMessage(message);
            setShowAddToSubscriptionModel(true);
        } else {
            save();
        }
    };

    const cancel = () => {
        WebStore.setSelectedDrawer(Constants.MY_CUSTOMERS, true);
    };

    const handleNext = () => {
        if (WebStore.activeStep < steps.length - 1 && site !== '') {
            WebStore.setActiveStep(WebStore.activeStep + 1);
            if (siteError !== '') {
                setSiteError('');
            }
            if (userError !== '') {
                setUserError('');
            }
        }
        else if (site === '') {
            setSiteError('Please select a site');
            if (user === null) {
                setUserError('Please select a user');
            }
        }
        else {
            submit();
        }
    };

    const renderPaper = () => {
        switch (steps[WebStore.activeStep]) {
            case Constants.SELECT_SITE:
                return (
                    <SelectSitePaper
                        user={{
                            value: user,
                            setValue: setUser,
                            error: userError,
                            setError: setUserError,
                        }}
                        site={{
                            value: site,
                            setValue: setSite,
                            error: siteError,
                            setError: setSiteError,
                        }}
                    />
                );
            case Constants.ADD_STRUCTURES:
                return (
                    <AddDevicesPaper
                        site={siteLocationData}
                        markerData={markerData}
                        setMarkerData={setMarkerData}
                        onMarkerUpdate={onMarkerUpdate}
                    />
                );
            default:
                return <div/>;
        }
    };

    return (
        <BaseWizard
            id='dealer_add_new_wizard'
            close={cancel}
            submit={submit}
            cancel={cancel}
            steps={steps}
            handleNext={handleNext}
            activeStep={WebStore.activeStep}
            setActiveStep={WebStore.setActiveStep}
        >
            {renderPaper()}
            {showAddToSubscriptionModel && (
                <ConfirmationModal
                    id='add_to_subscription_confirmation'
                    open
                    close={() => setShowAddToSubscriptionModel(false)}
                    submit={save}
                    title={Constants.INCREASE_SUBSCRIPTION}
                    message={addToSubscriptionMessage}
                />
            )}
        </BaseWizard>
    );
});

export default DealerAddNewWizard;
