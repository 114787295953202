/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import { InputFieldProps } from '../../../../../../types/components/ApiTypes';
import { Grid } from '@mui/material';
import getTempUnit from '../../../../../../helper/functions/getTempUnit';
import TooltipStore from '../../../../../../stores/TooltipStore';
import TooltipMessages from '../../../../../../helper/TooltipMessages';
import { observer } from 'mobx-react-lite';
import GridTextFieldInput from '../../../../../common/input/GridTextFieldInput';
import InfoTooltip from '../../../../../common/InfoTooltip';

interface Props {
    targetTemperature: InputFieldProps,
    minTemperature: InputFieldProps,
    emcLow: InputFieldProps,
    emcHigh: InputFieldProps,
    plenumRise: InputFieldProps,
    disabled?: boolean,
}

const AerationAdvancedSettings = observer((props: Props) => (
    <Grid
        container
        spacing={2}
    >
        <GridTextFieldInput
            id="min_allowed_temp_input"
            label={`Minimum Allowed Temperature (${ getTempUnit() })`}
            field={props.minTemperature}
            textFieldProps={{
                inputProps: {
                    type: 'number',
                    max: '30',
                    min: '-20',
                    disabled: props.disabled,
                },
                InputProps: {
                    endAdornment: (
                        <InfoTooltip
                            id="min_temp_tooltip_button"
                            open={TooltipStore.beginTooltips && !TooltipStore.showTargetTempMessages &&
                                !TooltipStore.showAdvancedMessages && TooltipStore.showMinTempMessages}
                            message={TooltipMessages.MIN_TEMP}
                            onClick={() => TooltipStore.setShowMinTempMessages(false, true)}
                        />
                    ),
                    disabled: props.disabled,
                },
                disabled: props.disabled,

            }}
            fullWidth
        />
        <GridTextFieldInput
            id="emc_low_threshold_input"
            label="Minimum EMC (%)"
            field={props.emcLow}
            textFieldProps={{
                inputProps: {
                    type: 'number',
                    min: '0',
                    max: props.emcHigh.value,
                    disabled: props.disabled,
                },
                InputProps: {
                    endAdornment: (
                        <InfoTooltip
                            id="emc_moisture_tooltip_button"
                            open={TooltipStore.beginTooltips && !TooltipStore.showMinTempMessages &&
                                TooltipStore.showEMCMoistureMessages}
                            message={TooltipMessages.EMC_MOISTURE}
                            onClick={() => TooltipStore.setShowEMCMoistureMessages(false, true)}
                        />
                    ),
                    disabled: props.disabled,

                },
                disabled: props.disabled,
            }}
        />
        <GridTextFieldInput
            id="emc_range_high_threshold_input"
            label="Maximum EMC (%)"
            field={props.emcHigh}
            textFieldProps={{
                inputProps: {
                    type: 'number',
                    min: props.emcLow.value,
                    max: '100',
                    disabled: props.disabled,
                },
                InputProps: {
                    endAdornment: (
                        <InfoTooltip
                            id="emc_range_tooltip_button"
                            message={TooltipMessages.EMC_RANGE}
                            onClick={() => TooltipStore.setShowEMCRangeMessages(false, true)}
                        />
                    ),
                    disabled: props.disabled,
                },
                disabled: props.disabled,
            }}
        />
        <GridTextFieldInput
            id="plenum_rise_input"
            label={`Plenum Rise (${ getTempUnit() })`}
            field={props.plenumRise}
            textFieldProps={{
                inputProps: {
                    type: 'number',
                    min: '0',
                    max: '10',
                    disabled: props.disabled,
                },
                InputProps: {
                    endAdornment: (
                        <InfoTooltip
                            id="plenum_rise_tooltip_button"
                            open={TooltipStore.beginTooltips && !TooltipStore.showEMCMoistureMessages &&
                                TooltipStore.showPlenumRiseMessages}
                            message={TooltipMessages.PLENUM_RISE}
                            onClick={() => TooltipStore.setShowPlenumRiseMessages(false, true)}
                        />
                    ),
                    disabled: props.disabled,
                },
                disabled: props.disabled,
            }}
            fullWidth
        />
    </Grid>
));

export default AerationAdvancedSettings;
