/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import {useEffect, useMemo, useState} from 'react';
import BinsenseStore from '../../../../stores/BinsenseStore';
import Constants from '../../../../helper/Constants';
import ReportsTableHeader from '../ReportsTableHeader';
import ReportsDifferenceTable from './ReportsDifferenceTable';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import getReportsDifferenceData from '../../../../controllers/apiCalls/get/getReportsDifferenceData';
import {Site} from '../../../../types/components/ApiTypes';

interface Props {
    site: Site,
}

const ReportsDifferencePage = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const binOptions = useMemo(() => props.site.bins.map(b => (
        {
            title: b.name,
            id: `${ b.id }_option`,
            value: String(b.id),
        }
    )), [ props.site ]);

    const [ bin, setBin ] = useState(binOptions.length > 0 ? binOptions[0].value : '');
    const [ binError, setBinError ] = useState('');
    const [ start, setStart ] = useState<any>(moment(new Date().getTime() - Constants.MILLISECONDS_IN_A_WEEK * 2));
    const [ end, setEnd ] = useState<any>(moment(new Date().getTime()));

    useEffect(() => {
        if (bin !== '') {
            getReportsDifferenceData(enqueueSnackbar, Number(bin), new Date(start).getTime(),
                new Date(end).getTime() + Constants.MILLISECONDS_IN_A_DAY);
        }
    }, [ props.site, start, end, bin ]);

    const rows = useMemo(() => BinsenseStore.differencesData, [ BinsenseStore.differencesData ]);
    const columns = [
        {
            field: 'level',
            headerName: Constants.ROW,
        },
        {
            field: 'startTemp',
            headerName: Constants.START_TEMP,
        },
        {
            field: 'endTemp',
            headerName: Constants.END_TEMP,
        },
        {
            field: 'tempChange',
            headerName: Constants.TEMP_CHANGE,
        },
        {
            field: 'startMoisture',
            headerName: Constants.START_MOIST,
        },
        {
            field: 'endMoisture',
            headerName: Constants.END_MOIST,
        },
        {
            field: 'moistureChange',
            headerName: Constants.MOIST_CHANGE,
        },
    ];

    return (
        <div style={{ width: '100%' }}>
            <ReportsTableHeader
                selectField={{
                    value: bin,
                    setValue: setBin,
                    error: binError,
                    setError: setBinError,
                }}
                options={binOptions}
                timeField={{
                    start,
                    setStart,
                    end,
                    setEnd,
                }}
                rows={rows}
                columns={columns}
                fileName="difference_report"
            />
            <ReportsDifferenceTable
                data={rows}
            />
        </div>
    );
});

export default ReportsDifferencePage;
