/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import Colors from '../../../helper/Colors';

interface Props {
    src: string,
}

const CarouselImageDisplay = (props: Props) => (
    <img
        src={props.src}
        alt="carousel image"
        style={{
            width: '100%',
            height: '100%',
            borderRadius: Colors.paperBorderRadius,
        }}
    />
);

export default CarouselImageDisplay;
