/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import addLeading from './addLeading';
import getMonth from './getMonth';

function getYear(date: Date) {
    return `${ date.getFullYear() }`;
}

export default function formatDate(date: Date,
                                   includeYear = false,
                                   includeTime = true,
                                   useUTC: boolean = false) {
    const hours = useUTC ? date.getUTCHours() : date.getHours();
    const h = hours % 12 || 12;
    const ampm = (hours < 12 || hours === 24) ? 'AM' : 'PM';

    return `${ getMonth(useUTC ? date.getUTCMonth() : date.getMonth()) } ${ useUTC
        ? date.getUTCDate() : date.getDate() }, ${
        includeYear ? getYear(date) : '' } ${
        includeYear && includeTime ? ' at ' : '' }${
        includeTime ? `${ h }:${ addLeading(useUTC
            ? date.getUTCMinutes() : date.getMinutes(), 2) }${ ampm }` : '' }`;
}
