/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default function downloadFile(blob: Blob, fileName: any) {
    const element = document.createElement('a');
    if (element.download !== undefined) {
        element.href = URL.createObjectURL(blob);
        element.setAttribute('download', fileName);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
}
