import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';
import getTempUnit from '../../../helper/functions/getTempUnit';

const getHighTemperatureReport = (enqueueSnackbar: any) => {
    callAPI('highTemperatureReport',
        (response) => {
            const data = response.map((device: any) => ({
                id: device.deviceSerial,
                user: device.userName,
                name: device.name,
                structure: device.structureName,
                device: `${ device.deviceType === 'AUTOMATION_HUB' ? 'Automation Hub' : device.deviceType.charAt(0) +
                    device.deviceType.slice(1).toLowerCase() } - ${ device.deviceSerialHex }`,
                maxTemp: `${ device.maxTemp } ${ getTempUnit() }`,
                siteId: device.siteId,
                structureId: device.structureId,
            }));
            BinsenseStore.setHighTemperatureData(data);
        },
        enqueueSnackbar
    );
};

export default getHighTemperatureReport;