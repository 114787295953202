import callDeleteAPI from '../../callDeleteAPI';
import BinsenseStore from '../../../stores/BinsenseStore';
import getDealerNotifications from '../get/getDealerNotifications';
import errorSnackbarOptions from '../../../helper/errorSnackbarOptions';

const deleteDealerNotifications = (enqueueSnackbar: any, id: number) => {
    callDeleteAPI(`deleteDealerNotification/${ id }`,
        (response) => {
            if (response === 'true') {
                getDealerNotifications(enqueueSnackbar, BinsenseStore.dealerUserData.dealerId);
            } else {
                enqueueSnackbar('Notification delete failed', errorSnackbarOptions);
            }
        },
        enqueueSnackbar,
        'Notification deleted successfully'
    );
};

export default deleteDealerNotifications;