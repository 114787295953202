/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';

interface Props {
    tab: string,
}

const Root =  styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const LoginPaperText = (props: Props) => (
    <Root>
        <Typography
            sx={{
                color: 'primary',
                fontSize: '24px',
                fontWeight: 'bold',
                paddingBottom: '10px',
            }}
        >
            {props.tab} Login
        </Typography>
        <Typography
            sx={{
                color: 'darkgrey',
            }}
        >
            Enter your username and password below
        </Typography>
    </Root>
);

export default LoginPaperText;