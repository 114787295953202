/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

export default {
    ADVANCED: 'Proceed with caution when changing advanced settings from their default values.',
    AMBIENT: 'Temperature and relative humidity readings are measured every five minutes by the ambient sensor ' +
        'located on the site. The EMC (Equilibrium Moisture Content) shows the moisture of the ambient air depending on ' +
        'the type of grain in the bin.',
    AUTOMATION_HUB: 'New sites can be setup by adding a new bin (then adding a new Bin-Sense Live Master unit or a ' +
        'new Bin-Sense Solo unit) OR adding a new Bin-Sense Plus Automation Hub.',
    COMMUNICATION_SUMMARY: 'This report only displays devices for a Bin-Sense Live system. Plus, Direct, and Solo ' +
        'devices are not included.',
    DEFAULT: 'Default temperature settings are recommended parameters from the grain commission.',
    DEVICES: 'Add additional devices to this structure. This is used for large Bin-Sense Live structures that need ' +
        'multiple devices to read a large number of cables.',
    DIFFERENCES: 'This table will show the change in grain temperature and moisture between two dates.',
    EMC_RANGE: 'Set the allowed EMC range. When the ambient EMC is in the allowed range, fans will run until the ' +
        'target grain moisture is reached.',
    EMC_MOISTURE: 'These settings prevent aerating the bin with excessively hot or cold air. If the ambient air ' +
        'temperature is outside the allowed range, fans will not run.',
    INVENTORY: 'Inventory values are an approximate value based off the number of covered and uncovered sensors.',
    MANUAL: 'Manually turn fans on for up to 96 hours at a time.',
    MASTER: 'When adding multiple Master units to one site, each Master unit must be set to a unique channel. Remote ' +
        'units must be set to the same channel as the Master unit they are associated to. See guide for changing ' +
        'device channel settings.',
    MIN_TEMP: 'The lowest air temperature where fans are still allowed to run. If the plenum air temperature is ' +
        'lower than this setting, fans will not run.',
    NOT_MONITORED: 'Use this option to add structures and landmarks to the site that are not monitored with Bin-Sense.',
    NOTIFICATION_ASSOCIATION_ALERT: 'Association alerts are sent when you press and hold the scan button on a Master unit. ' +
        'They contain summary information about the status of a Master unit that is helpful when setting up or troubleshooting ' +
        'Bin-Sense Live systems.',
    NOTIFICATION_SYSTEM_TEMP: 'System Temperature Thresholds will send you an alert when the temperature of a covered sensor in a ' +
        'customer bin exceeds 45 °C (113 °F).',
    NOTIFICATION_VOLTAGE_TEMP: 'Voltage alerts are sent when the battery voltage of a customer Master unit is below 6 V.',
    PLENUM_CONDITIONS: 'Plenum conditions are measured every five minutes by a sensor installed in the bin plenum ' +
        'space.',
    PLENUM_RISE: 'Plenum rise is the increase of ambient air temperature when aeration fans push air into the plenum ' +
        'space (the space under the bin floor). Because fans compress the air, the temperature rises a few degrees ' +
        'above the ambient air temperature.',
    POWER: 'Use the \'Power Source\' field to choose whether this device is powered by battery and solar, or by an ' +
        'external AC power source.',
    SITE_LOCATION: 'Note: you can enter Latitude and Longitude to find site location ex. 40.740121, -73.990593',
    TARGET_MOISTURE: 'Set the target grain moisture for the grain to be conditioned to. Fans will run when the ' +
        'ambient EMC is in the allowed range until the target moisture is reached.',
    TEMP_CONTROL: 'Set the target grain temperature for the grain to be cooled to. Fans will run when the ambient ' +
        'temperature is lower than the grain temperature until the target is reached.',
    WEATHER: 'This weather data is provided by the ambient sensor located on the site.',
};
