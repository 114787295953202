/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Button, styled, Typography} from '@mui/material';
import {useState} from 'react';
import { useSnackbar } from 'notistack';
import isPasswordValid from '../../helper/validator/isPasswordValid';
import logout from '../../helper/functions/logout';
import PasswordField from '../common/input/PasswordField';
import Constants from '../../helper/Constants';
import BaseModal from '../common/modal/BaseModal';
import getTestID from '../../helper/functions/getTestID';
import putForgotPasswordReset from '../../controllers/apiCalls/put/putForgotPasswordReset';

const Container = styled('div')({
    width: '500px',
    padding: '16px',
});

const ButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: '20px',
});

const PopupContainer = styled('div')({
    width: '350px',
    padding: '16px',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingTop: '16px',
});

const ForgotPasswordResetPaper = () => {
    const {enqueueSnackbar} = useSnackbar();
    const url = new URL(window.location.href);
    const [ newPassword, setNewPassword ] = useState('');
    const [ newPasswordError, setNewPasswordError ] = useState('');
    const [ newPasswordConfirm, setNewPasswordConfirm ] = useState('');
    const [ newPasswordConfirmError, setNewPasswordConfirmError ] = useState('');
    const [ success, setSuccess ] = useState(false);
    const message = 'Password saved successfully.';

    const isValid = () => {
        let valid = true;
        const passwordError = isPasswordValid(newPassword);
        if (passwordError !== '') {
            setNewPasswordError(passwordError);
            setNewPasswordConfirmError('');
            valid = false;
        } else if (newPassword !== newPasswordConfirm) {
            setNewPasswordConfirmError('Password fields do not match');
            setNewPasswordError('');
            valid = false;
        }
        return valid;
    };

    const resetURL = () => {
        url.searchParams.delete('un');
        url.searchParams.delete('token');
        url.searchParams.delete('passwordReset');
        window.location.assign(url);
        logout();
    };

    const submit = () => {
        const body = {
            username: url.searchParams.get('un'),
            token: url.searchParams.get('token'),
            newPassword,
            newPasswordConfirm,
        };

        if (isValid()) {
            setNewPasswordError('');
            setNewPasswordConfirmError('');

            putForgotPasswordReset(enqueueSnackbar, body, message, setSuccess);
        }
    };

    const onCancel = () => {
        resetURL();
    };

    return (
        <Container>
            <Typography
                sx={{
                    fontSize: '24px',
                    paddingBottom: '32px',
                }}
            >
                Password Reset
            </Typography>

            <Typography
                sx={{
                    paddingBottom: '16px',
                }}
            >
                The system received a password reset request from you. Please enter your new password.
            </Typography>

            <PasswordField
                id="new_user_password_input"
                label={Constants.PASSWORD}
                field={{
                    value: newPassword,
                    setValue: setNewPassword,
                    error: newPasswordError,
                    setError: setNewPasswordError,
                }}
            />
            <PasswordField
                id="new_user_password_confirmation_input"
                label={Constants.PASSWORD_CONFIRMATION}
                field={{
                    value: newPasswordConfirm,
                    setValue: setNewPasswordConfirm,
                    error: newPasswordConfirmError,
                    setError: setNewPasswordConfirmError,
                }}
            />

            <Row>
                <ButtonContainer>
                    <Button
                        {...getTestID('cancel_new_user_password_button')}
                        color='secondary'
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </ButtonContainer>
                <ButtonContainer>
                    <Button
                        {...getTestID('submit_new_user_password_button')}
                        color="primary"
                        variant="contained"
                        onClick={submit}
                        disabled={newPassword === '' || newPasswordConfirm === ''}
                    >
                        Submit
                    </Button>
                </ButtonContainer>
            </Row>
            {success &&
                <BaseModal
                    id="password_reset_modal"
                    open={success}
                    close={resetURL}
                    title="Password Reset"
                    cancelButtonText="Continue to Login"
                    variant = 'contained'
                    buttonColor='primary'
                >
                    <PopupContainer>
                        <Typography>
                            {'Password saved successfully.'}
                        </Typography>
                    </PopupContainer>
                </BaseModal>
            }
        </Container>
    );
};

export default ForgotPasswordResetPaper;
