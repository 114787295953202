/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Button, Divider, MenuItem} from '@mui/material';
import {KeyboardArrowDown} from '@mui/icons-material';
import {observer} from 'mobx-react-lite';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import getTestID from '../../../../../helper/functions/getTestID';
import WebStore from '../../../../../stores/WebStore';
import Colors from '../../../../../helper/Colors';
import PopupMenu from '../../../../common/PopupMenu';
import {Bin, Building, GrainBag} from '../../../../../types/components/ApiTypes';

interface Props {
    setFanId: Dispatch<SetStateAction<string | number>>,
    bin: Bin | GrainBag | Building,
}

const SetFanPopup = observer((props: Props) => {
    const [ selectedFan, setSelectedFan ] = useState<string | number>('All');
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => setSelectedFan('All'), [ props.bin ]);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeviceClick = (label: number | string, id: number | string) => {
        setSelectedFan(label);
        props.setFanId(id);
        handleClose();
    };

    return (
        <div>
            <Button
                {...getTestID('set_fan_button')}
                onClick={handleClick}
                endIcon={<KeyboardArrowDown/>}
                sx={{ color: WebStore.lightMode ? Colors.text : Colors.textLight }}
                disabled={props.bin.hardware.fans.length === 0}
            >
                {selectedFan}
            </Button>
            <PopupMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
            >
                <MenuItem
                    {...getTestID('all_dropdown')}
                    key="all_option"
                    onClick={() => handleDeviceClick('All', 'All')}
                >
                    All
                </MenuItem>
                <Divider/>
                {props.bin.hardware.fans.map((fan, index) => (
                    <MenuItem
                        {...getTestID(`site_${ fan.serial }_dropdown`)}
                        key={`fan_${ fan.id }`}
                        onClick={() => handleDeviceClick(`Fan ${ index + 1 } (ID ${ fan.localId })`, fan.id)}
                    >
                        {`Fan ${ index + 1 } (ID ${ fan.localId })`}
                    </MenuItem>
                ))}
            </PopupMenu>
        </div>
    );
});

export default SetFanPopup;
