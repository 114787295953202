import callDeleteAPI from '../../callDeleteAPI';
import updateData from '../../../helper/functions/updateData';
import Constants from '../../../helper/Constants';
import WebStore from '../../../stores/WebStore';
import getNotificationSettings from '../get/getNotificationSettings';

const deleteNotificationPhone = (enqueueSnackbar: any, id: number) => {
    callDeleteAPI(`notificationPhone/${ id }`,
        (response) => {
            if (response === 'Phone number deleted') {
                updateData(enqueueSnackbar);
                if (WebStore.selectedView === Constants.DEALER) {
                    getNotificationSettings(enqueueSnackbar, WebStore.selectedCustomer.id);
                }
            }
        },
        enqueueSnackbar,
        'Notification phone number deleted successfully'
    );
};

export default deleteNotificationPhone;