import callDeleteAPI from '../../callDeleteAPI';
import getBinNotes from '../get/getBinNotes';

const deleteBinNote = (enqueueSnackbar: any, id: number, siteId: number) => {
    callDeleteAPI(`binNotes/${ id }`,
        (response) => {
        if (response === 'Note deleted')
            getBinNotes(enqueueSnackbar, siteId);
        },
        enqueueSnackbar,
        'Note deleted successfully'
    );
};

export default deleteBinNote;