/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Button} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';
import Colors from '../../../helper/Colors';
import WebStore from '../../../stores/WebStore';
import SortableTableHeaderIcon from './SortableTableHeaderIcon';
import {Dispatch, SetStateAction} from 'react';
import tableHeaderClicked from '../../../helper/functions/tableHeaderClicked';
import {observer} from 'mobx-react-lite';

interface Props {
    id: string,
    label: string | number,
    data: any[],
    setData: (prevState: any[]) => void,
    sortColumn: string,
    setSortColumn: Dispatch<SetStateAction<string>>,
    sortAscending: boolean,
    setSortAscending: Dispatch<SetStateAction<boolean>>,
    dataKey: string,
    fontSize?: number,
}

const DisplayGridSortableTableHeader = observer((props: Props) => (
    <Button
        {...getTestID(props.id)}
        startIcon={(
            <SortableTableHeaderIcon
                label={String(props.label)}
                sortColumn={props.sortColumn}
                sortAscending={props.sortAscending}
            />
        )}
        onClick={() => tableHeaderClicked(String(props.label), props.data, props.setData, props.sortColumn,
            props.setSortColumn, props.sortAscending, props.setSortAscending, props.dataKey)}
        sx={{
            fontWeight: 'bold',
            fontSize: props.fontSize ? props.fontSize : 16,
            color: WebStore.lightMode ? Colors.textTitle : Colors.textTitleLight,
        }}
    >
        {props.label}
    </Button>
));

export default DisplayGridSortableTableHeader;
