/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import ContentPanel from '../../common/ContentPanel';
import UsersTable from '../users/UsersTable';
import Constants from '../../../helper/Constants';
import {useState} from 'react';
import NewUserModal from '../users/NewUserModal';
import TableButton from '../../common/buttons/TableButton';

const Users = () => {
    const [ newUserModalOpen, setNewUserModalOpen ] = useState(false);

    return (
        <ContentPanel>
            <TableButton
                id='add_user_button'
                label={Constants.NEW_USER}
                onClick={() => setNewUserModalOpen(true)}
            />
            <UsersTable/>
            {newUserModalOpen && (
                <NewUserModal
                    open
                    close={() => setNewUserModalOpen(false)}
                />
            )}
        </ContentPanel>
    );
};

export default Users;
