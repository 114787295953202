/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import WebStore from '../../../stores/WebStore';
import ContentPanel from '../../common/ContentPanel';
import {observer} from 'mobx-react-lite';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import CreateSiteWizard from '../createSite/CreateSiteWizard';
import changeDrawerAfterConfirmation from '../../../helper/functions/changeDrawerAfterConfirmation';

interface Props {
    isCustomer?: boolean
}

const CreateSite = observer((props: Props) => {
    const confirmationSubmit = () => {
        WebStore.setActiveStep(0);
        changeDrawerAfterConfirmation();
    };

    return (
        <ContentPanel>

            <CreateSiteWizard
                customer={props.isCustomer}
            />

            {WebStore.isLoseChangesConfirmationOpen && (
                <ConfirmationModal
                    id="exit_add_bin_wizard_confirmation"
                    open
                    close={() => WebStore.setIsLoseChangesConfirmationOpen(false)}
                    title="Warning"
                    submit={confirmationSubmit}
                    message="Are you sure you want to exit the Create Site wizard? All unsaved changes will be lost."
                />
            )}
        </ContentPanel>
    );
});

export default CreateSite;
