/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {observer} from 'mobx-react-lite';
import BinSenseLogo from '../../images/bin-sense-logo.png';
import BinSenseLogoWhite from '../../images/Bin-Sense-logo-WHITE.png';
import BinSenseIcon from '../../images/binsense_icon.png';
import BinSenseIconWhite from '../../images/binsense_icon_white.png';
import WebStore from '../../stores/WebStore';

interface Props {
    style: object,
    justIcon?: boolean
}

const BinsenseLogo = observer((props: Props) => props.justIcon
    ? (
        <img
            style={props.style}
            src={WebStore.lightMode ? BinSenseIcon : BinSenseIconWhite}
            alt="BinSense Logo"
        />
    )
    : (
        <img
            style={props.style}
            src={WebStore.lightMode ? BinSenseLogo : BinSenseLogoWhite}
            alt="BinSense Logo"
        />
    )
);

export default BinsenseLogo;
