/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {IconButton, Tooltip} from '@mui/material';
import {InfoOutlined} from '@mui/icons-material';
import TooltipWithButton from './TooltipWithButton';
import {useState} from 'react';
import getTestID from '../../helper/functions/getTestID';

interface Props {
    id: string
    open?: boolean,
    message: string,
    onClick: () => void,
}

const InfoTooltip = (props: Props) => {
    const [ tooltipOpen, setTooltipOpen ] = useState(false);

    return (
        <Tooltip
            open={props.open || tooltipOpen}
            arrow
            title={(
                <TooltipWithButton
                    id={props.id}
                    message={props.message}
                    onClick={() => props.open ? props.onClick() : setTooltipOpen(!tooltipOpen)}
                />
            )}
        >
            <div>
                <IconButton
                    {...getTestID(props.id)}
                    onClick={() => props.open ? props.onClick() : setTooltipOpen(!tooltipOpen)}
                >
                    <InfoOutlined/>
                </IconButton>
            </div>
        </Tooltip>
    );
};

export default InfoTooltip;
