/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import AutomationDevicesTable from '../automation/AutomationDevicesTable';
import { Site } from '../../../../types/components/ApiTypes';
import AutomationHubPaper from '../automation/AutomationHubPaper';

interface Props {
    site: Site
}

const AutomationPage = observer((props: Props) => {
    const renderGridItem = (item: object, fullWidth: boolean = false) => (
        <Grid
            item
            xs={fullWidth ? 12 : 6}
        >
            {item}
        </Grid>
    );

    return (
        <Grid
            container
            spacing={2}
        >
            {renderGridItem(<AutomationDevicesTable site={props.site} />, true)}
            {renderGridItem(<AutomationHubPaper site={props.site} />)}
        </Grid>
    );
});

export default AutomationPage;
