/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import AssociationsTable from './AssociationsTable';
import {styled} from '@mui/material';
import {Site} from '../../../../types/components/ApiTypes';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
});

interface Props {
    site: Site,
}

const AssociationsPage = (props: Props) => (
    <Root>
        <AssociationsTable site={props.site}/>
    </Root>
);

export default AssociationsPage;
