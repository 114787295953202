import Constants from '../../../helper/Constants';
import errorSnackbarOptions from '../../../helper/errorSnackbarOptions';
import BinsenseStore from '../../../stores/BinsenseStore';
import callPostAPI from '../../callPostAPI';
import getDealerNotifications from '../get/getDealerNotifications';

const postDealerNotification = (enqueueSnackbar: any, body: any, message: string) => {
    callPostAPI('setDealerNotification',
        body,
        (response) => {
            if (response === 'true') {
                getDealerNotifications(enqueueSnackbar, BinsenseStore.dealerUserData.dealerId);
            } else if (response === Constants.DEALER_NOTIFICATION_DUPLICATE) {
                enqueueSnackbar(Constants.DEALER_NOTIFICATION_DUPLICATE_MESSAGE, errorSnackbarOptions);
            }
        },
        enqueueSnackbar,
        message
    );
};

export default postDealerNotification;
