import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';

const getWeatherData = (enqueueSnackbar: any, id: number) => {
    callAPI(`weather/${ id }`,
        (response) => {
            BinsenseStore.setWeatherData(response);
        },
        enqueueSnackbar
    );
};

export default getWeatherData;