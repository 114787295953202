/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {SyntheticEvent, useMemo, useState} from 'react';
import WebStore from '../../stores/WebStore';
import { observer } from 'mobx-react-lite';

interface Props {
    id: string,
    summary: any,
    details: any,
    noBackgroundColor?: boolean,
    hideDetailsDivider?: boolean,
    expanded?: boolean,
    disabled?: boolean,
}

const AccordionComponent = observer((props: Props) => {
    const [ expanded, setExpanded ] = useState(props.expanded || false);

    const handleChange = (event: SyntheticEvent, newExpanded: boolean) => {
        if (!props.disabled) {
            setExpanded(newExpanded);
        }
    };

    const borderColour = useMemo(() => WebStore.lightMode ? '0.2px solid rgba(200, 200, 200, 0.6)' : '0.2px solid rgba(120, 120, 120, 0.5)',
    [ WebStore.lightMode ]);

    const AccordionSummary = styled((accordionProps: AccordionSummaryProps) => (
        <MuiAccordionSummary
            expandIcon={!props.disabled || expanded
                ? <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
                : <div style={{ width: 14.39 }}/>}
            {...accordionProps}
        />
    ))(({ theme }) => ({
        backgroundColor: props.noBackgroundColor ? undefined : (
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)'
        ),
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: props.hideDetailsDivider ? undefined : '1px solid rgba(0, 0, 0, .125)',
    }));

    return (
        <div>
            <Accordion
                disableGutters
                square
                expanded={expanded}
                onChange={handleChange}
                sx={{
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginBottom: '0px',
                    padding: '0px',
                    borderBottom: borderColour,
                    borderRadius: '0px',
                    boxShadow: 'none',
                }}
                id={`${ props.id }_accordion`}
                defaultExpanded={true}
            >
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                >
                    {props.summary}
                </AccordionSummary>
                <AccordionDetails>
                    {props.details}
                </AccordionDetails>
            </Accordion>
        </div>
    );
});

export default AccordionComponent;
