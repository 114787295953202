/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import {AutomationDevicesData} from '../../../../types/components/ApiTypes';
import DisplayGridItem from '../../../common/displayTable/DisplayGridItem';
import {Check, Close} from '@mui/icons-material';
import getTestID from '../../../../helper/functions/getTestID';

interface Props {
    row: AutomationDevicesData,
}

const XS = 9.0 / 4.0;

const AutomationDevicesTableRow = (props: Props) => {
    const renderGridCheck = (id: string, key: string) => (
        <DisplayGridItem xs={XS}>
            {props.row[key] ? (
                <Check
                    {...getTestID(id)}
                    color="success"
                />
            ) : (
                <Close
                    {...getTestID(id)}
                    color="error"
                />
            )}
        </DisplayGridItem>
    );

    return (
        <DisplayGridContent>
            <DisplayGridLabel
                id={`${ props.row.id }_structure`}
                label={props.row.structure}
                xs={3}
                lengthLimit={15}
            />
            {renderGridCheck('remote_unit', 'remoteUnit')}
            {renderGridCheck('cables', 'cables')}
            {renderGridCheck('fan_controller', 'fanController')}
            {renderGridCheck('plenum_sensor', 'plenumSensor')}
        </DisplayGridContent>
    );
};

export default AutomationDevicesTableRow;
