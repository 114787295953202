/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import Constants from '../../../../../../helper/Constants';
import {styled, Typography} from '@mui/material';
import TooltipStore from '../../../../../../stores/TooltipStore';
import TooltipMessages from '../../../../../../helper/TooltipMessages';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import InfoTooltip from '../../../../../common/InfoTooltip';
import {Dispatch, SetStateAction} from 'react';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

interface Props {
    id: string,
    advancedOpen: boolean,
    setAdvancedOpen: Dispatch<SetStateAction<boolean>>,
    tooltipOpen: boolean,
    children: object,
}

const AutomationAdvancedSettings = (props: Props) => (
    <div style={{ width: '100%' }}>
        <Row>
            <Row
                style={{
                    paddingRight: '16px',
                    cursor: 'pointer',
                }}
                onClick={() => props.setAdvancedOpen(!props.advancedOpen)}
            >
                {props.advancedOpen ? <ExpandLess/> : <ExpandMore/>}
                <Typography
                    fontWeight="bold"
                    style={{ paddingLeft: '8px' }}
                >
                    {Constants.ADVANCED}
                </Typography>
            </Row>
            <InfoTooltip
                id={`${ props.id }_advanced_tooltip_button`}
                open={props.tooltipOpen}
                message={TooltipMessages.ADVANCED}
                onClick={() => TooltipStore.setShowAdvancedMessages(false, true)}
            />
        </Row>
        {props.advancedOpen && (
            <div style={{ paddingTop: '16px' }}>
                {props.children}
            </div>
        )}
    </div>
);

export default AutomationAdvancedSettings;
