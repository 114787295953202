/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import CommandsTable from './CommandsTable';
import ServerCommandHistoryTable from './ServerCommandHistoryTable';
import {Site} from '../../../../types/components/ApiTypes';

const Root = styled('div')({ width: '100%' });

interface Props {
    site: Site,
}

const MyCustomerCommandsPage = observer((props: Props) => (
    <Root>
        <CommandsTable site={props.site}/>
        <ServerCommandHistoryTable site={props.site}/>
    </Root>
));

export default MyCustomerCommandsPage;
