import BinsenseStore from '../../stores/BinsenseStore';
import WebStore from '../../stores/WebStore';
import { Bin, Building, GrainBag, MarkerData, Site, SiteStructures } from '../../types/components/ApiTypes';
import Constants from '../Constants';

function getCountForDealerView(selectedSiteId?: number, skipCurrentSite?: boolean) {
    let count: number = 0;

    BinsenseStore.userStructures.forEach((userStructure: SiteStructures) => {
        if (userStructure.id == selectedSiteId && skipCurrentSite) {
            return;
        }

        // check for solos on bins
        const bins: Bin[] = userStructure.bins;
        for (let j = 0; j < bins.length; j++) {
            count += bins[j].hardware.soloUnits.length;
        }

        // grain bags
        const grainBags: GrainBag[] = userStructure.grainBags;
        for (let j = 0; j < grainBags.length; j++) {
            count += grainBags[j].hardware.soloUnits.length;
        }

        // and buildings
        const buildings: Building[] = userStructure.buildings;
        for (let j = 0; j < buildings.length; j++) {
            count += buildings[j].hardware.soloUnits.length;
        }
    });

    return count;
}

function getCountForCustomerView(selectedSiteId?: number, skipCurrentSite?: boolean) {
    const sites: Site[] = BinsenseStore.userData.sites;
    let count: number = 0;

    // iterate through user sites
    for (let i = 0; i < sites.length; i++) {
        if ((skipCurrentSite && sites[i].id === selectedSiteId) || !sites[i].live) {
            continue;
        }

        // check for solos on bins
        const bins: Bin[] = sites[i].bins;
        for (let j = 0; j < bins.length; j++) {
            count += bins[j].hardware.soloUnits.length;
        }

        // grain bags
        const grainBags: GrainBag[] = sites[i].grainBags;
        for (let j = 0; j < grainBags.length; j++) {
            count += grainBags[j].hardware.soloUnits.length;
        }

        // and buildings
        const buildings: Building[] = sites[i].buildings;
        for (let j = 0; j < buildings.length; j++) {
            count += buildings[j].hardware.soloUnits.length;
        }
    }

    return count;
}

export function getSoloCount(skipCurrentSite?: boolean, user?: string, selectedSite?: number, newSite?: boolean) {
    const selectedSiteId: number = !newSite ? selectedSite ?? WebStore.selectedSiteId : -1;

    if (user) {
        return getCountForDealerView(selectedSiteId, skipCurrentSite);
    } else {
        return getCountForCustomerView(selectedSiteId, skipCurrentSite);
    }
};

export function getSoloCountByMarkerData(markerData?: MarkerData[], user?: string, selectedSite?: number, newSite?: boolean) {
    let count: number = 0;

    markerData?.forEach((data: MarkerData) => {
        if (data?.display?.buildingData) {
            // check for solo
            if (data.display.buildingData.device.toLowerCase() == Constants.SOLO.toLowerCase()) {
                count++;
            }
        }
        if (data?.display?.grainBagData) {
            // check for solo
            if (data.display.grainBagData.device.toLowerCase() == Constants.SOLO.toLowerCase()) {
                count++;
            }
        }
        if (data?.display?.grainBinData) {
            // check for solo
            if (data.display.grainBinData.device.toLowerCase() == Constants.SOLO.toLowerCase()) {
                count++;
            }
        }
        if (data?.display?.grainPileData) {
            // check for solo
            if (data.display.grainPileData.device.toLowerCase() == Constants.SOLO.toLowerCase()) {
                count++;
            }
        }
    });

    // add that to the counts from every other site
    count += getSoloCount(true, user, selectedSite, newSite);

    return count;
}