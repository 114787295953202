/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Typography} from '@mui/material';
import getTestID from '../../helper/functions/getTestID';
import DisplayGridItem from './displayTable/DisplayGridItem';
import RemoteTooltip from './RemoteTooltip';

interface Props {
    device: any,
    xs?: number,
    fontSize?: number,
    pageLabel?: string,
    testId?: string,
}

const RemoteSerialGridItem = (props: Props) => (
    <DisplayGridItem
        xs={props.xs ?? 1.2}
    >
        <RemoteTooltip
            device={props.device}
            fontSize={props.fontSize}
        >
            <Typography
                {...getTestID(`${ props.pageLabel ? props.pageLabel.concat('_') : '' }
                    ${ props.testId ? props.testId.concat('_') : '' }serial`)}
                fontSize={props.fontSize}
                fontWeight="bold"
            >
                {/* props.device may be based on different objects, which may store serial number in 'serial' or 'serialNumber' property */}
                {props.device.serial ?? props.device.serialNumber}
            </Typography>
        </RemoteTooltip>
    </DisplayGridItem>
);

export default RemoteSerialGridItem;
