/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import ContentPanel from '../../common/ContentPanel';
import {observer} from 'mobx-react-lite';
import DealerAddNewWizard from '../addNew/DealerAddNewWizard';
import WebStore from '../../../stores/WebStore';
import ConfirmationModal from '../../common/modal/ConfirmationModal';
import changeDrawerAfterConfirmation from '../../../helper/functions/changeDrawerAfterConfirmation';

const DealerAddNew = observer(() => {
    const confirmationSubmit = () => {
        WebStore.setActiveStep(0);
        WebStore.setEditingMap(false);
        changeDrawerAfterConfirmation();
    };

    return (
        <ContentPanel>
            <DealerAddNewWizard/>

            {WebStore.isLoseChangesConfirmationOpen && (
                <ConfirmationModal
                    id="exit_add_new_confirmation"
                    open
                    close={() => WebStore.setIsLoseChangesConfirmationOpen(false)}
                    title="Warning"
                    submit={confirmationSubmit}
                    message="Are you sure you want to exit? All unsaved changes will be lost."
                />
            )}
        </ContentPanel>
    );
});

export default DealerAddNew;
