/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Modal, Paper, styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import SubmitButton from '../buttons/SubmitButton';
import getTestID from '../../../helper/functions/getTestID';
import BinsenseStore from '../../../stores/BinsenseStore';
import CancelButton from '../buttons/CancelButton';
import AlwaysEnabledSubmitButton from '../buttons/AlwaysEnabledSubmitButton';
import { ButtonColors } from '../../../types/components/ApiTypes';

const Title = styled('div')({
    padding: '16px',
    borderRadiusTopRight: '5px',
    borderRadiusTopLeft: '5px',
    paddingBottom: '0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const ButtonRow = styled('div')({
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
});

interface Props {
    id: string,
    open: boolean,
    close: () => void,
    title: string,
    titleObject?: object
    children: object,
    submit?: () => void,
    submitButtonText?: string,
    cancelButtonText?: string,
    onCancel?: () => void,
    disableSubmitOnAwait?: boolean,
    hideSubmit?: boolean,
    hideButtons?: boolean,
    disableCancel?: boolean,
    alwaysEnabledSubmit?: boolean,
    buttonColor?: ButtonColors,
    variant?: 'contained',
    disableSubmit?: boolean,
}

const BaseModal = observer((props: Props) => (
    <Modal
        open={props.open}
        onClose={props.close}
    >
        <Paper
            sx={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                outline: 'none',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                padding: 0,
                margin: 0,
                overflow: 'auto',
                maxHeight: '80%',
            }}
        >
            <Title>
                <Typography
                    {...getTestID('popup_title')}
                    color="inherit"
                >
                    {props.title}
                </Typography>
                {props.titleObject}
            </Title>
            {props.children}
            {!props.hideButtons && (
                <ButtonRow>
                    <CancelButton
                        id={props.id}
                        onClick={props.onCancel ? props.onCancel : props.close}
                        text={props.cancelButtonText}
                        disabled={Boolean(props.disableCancel)}
                        variant={props.variant}
                        color={props.buttonColor}
                    />
                    {!props.hideSubmit && props.submit && !props.alwaysEnabledSubmit && (
                        <SubmitButton
                            id={`${ props.id }_submit_button`}
                            text={props.submitButtonText ? props.submitButtonText : 'Submit'}
                            onClick={props.submit}
                            disabled={(props.disableSubmitOnAwait && BinsenseStore.awaitingApi) || props.disableSubmit}
                            color={props.buttonColor}
                        />
                    )}
                    {!props.hideSubmit && props.submit && props.alwaysEnabledSubmit && (
                        <AlwaysEnabledSubmitButton
                            id={`${ props.id }_submit_button`}
                            text={props.submitButtonText ? props.submitButtonText : 'Submit'}
                            onClick={props.submit}
                        />
                    )}
                </ButtonRow>
            )}
        </Paper>
    </Modal>
));

export default BaseModal;
