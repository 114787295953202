/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useMemo, useState} from 'react';
import Constants from '../../../../helper/Constants';
import DisplayGridPaper from '../../../common/displayTable/DisplayGridPaper';
import DisplayGridHeaderRow from '../../../common/displayTable/DisplayGridHeaderRow';
import DisplayGridSortableTableHeader from '../../../common/displayTable/DisplayGridSortableTableHeader';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import ConfirmationModal from '../../../common/modal/ConfirmationModal';
import {observer} from 'mobx-react-lite';
import DisplayGridCheckBox from '../../../common/displayTable/DisplayGridCheckBox';
import CableAssociationsTableRow from './CableAssociationsTableRow';
import deleteAssociationCable from '../../../../controllers/apiCalls/delete/deleteAssociationCable';
import {useSnackbar} from 'notistack';
import {Bin, Building, GrainBag} from '../../../../types/components/ApiTypes';
import getCableAssociationsData from '../../../../helper/functions/getCableAssociationsData';
import {styled} from '@mui/material';
import DashboardSkeleton from '../../../common/DashboardSkeleton';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

interface Props {
    structure: Bin | GrainBag | Building,
}

const CableAssociationsTable = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const [ selectAll, setSelectAll ] = useState(false);
    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);
    const [ deleteId, setDeleteId ] = useState<number | null>(null);

    const rows = useMemo(() => BinsenseStore.cableAssociationsData, [ BinsenseStore.cableAssociationsData ]);

    useEffect(() => BinsenseStore.setCableAssociationsData(getCableAssociationsData(props.structure)),
        [ props.structure ]);

    const renderSortableHeader = (id: string, label: string, key: string, xs: number) => (
        <DisplayGridSortableTableHeader
            id={`${ id }_header`}
            label={label}
            xs={xs}
            data={rows}
            setData={BinsenseStore.setCableAssociationsData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderGridLabel = (id: string, label: string, xs: number, header: boolean = false) => (
        <DisplayGridLabel
            id={id}
            label={label}
            xs={xs}
            header={header}
        />
    );

    const onDelete = () => {
        deleteAssociationCable(enqueueSnackbar, Number(deleteId));
    };

    const selectAllClicked = () => {
        const clone = JSON.parse(JSON.stringify(rows));
        clone.map((row: any) => {
            row.selected = !selectAll;
            return row;
        });
        BinsenseStore.setCableAssociationsData(clone);
        setSelectAll(!selectAll);
    };

    const changeSelected = (id: number) => {
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].smartId === id) {
                const clone = JSON.parse(JSON.stringify(rows));
                clone[i].selected = !rows[i].selected;
                BinsenseStore.setCableAssociationsData(clone);
                break;
            }
        }
    };

    return !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
        <DisplayGridPaper>
            <DisplayGridHeaderRow>
                <DisplayGridCheckBox
                    xs={2}
                    id='select_header'
                    value={selectAll}
                    onChange={selectAllClicked}
                    header
                />
                {renderSortableHeader('cable_smart_id', Constants.CABLE_SMART_ID, 'smartId', 2)}
                {renderSortableHeader('serial', Constants.SERIAL, 'serial', 2.5)}
                {renderSortableHeader('type', Constants.TYPE, 'type', 2.5)}
                {renderGridLabel('actions_header', Constants.ACTIONS, 3, true)}
            </DisplayGridHeaderRow>
            {rows.map((row: any) => (
                <CableAssociationsTableRow
                    row={row}
                    setDeleteId={setDeleteId}
                    changeSelected={changeSelected}
                    key={`${ row.smartId }_cable_associations_table_row`}
                />
            ))}
            {deleteId !== null && (
                <ConfirmationModal
                    id={`${ deleteId }_delete_confirmation_modal`}
                    open
                    close={() => setDeleteId(null)}
                    submit={onDelete}
                    title="End Cable Association"
                    message="Are you sure you want to end this cable association?"
                />
            )}
        </DisplayGridPaper>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={600}/>
        </SkeletonRoot>
    );
});

export default CableAssociationsTable;
