/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Bin, Building, GrainBag, Pile} from '../../types/components/ApiTypes';
import Constants from '../Constants';

const getStructStatus = (struct: Bin | GrainBag | Building | Pile) => {
    const masters = struct.hardware?.masterUnits ? struct.hardware.masterUnits : [];
    const remotes = struct.hardware?.remoteUnits ? struct.hardware.remoteUnits : [];
    const solos = struct.hardware?.soloUnits ? struct.hardware.soloUnits : [];

    if (struct.settings.hardwareType.toLowerCase() === Constants.DIRECT.toLowerCase()) {
        return null;
    }

    for (let i = 0; i < masters.length; i++) {
        if (masters[i].status.reportingStatus === Constants.OFFLINE) {
            return Constants.OFFLINE;
        }
    }

    for (let i = 0; i < remotes.length; i++) {
        if (remotes[i].status.reportingStatus === Constants.OFFLINE) {
            return Constants.OFFLINE;
        }
    }

    for (let i = 0; i < struct.hardware.soloUnits.length; i++) {
        if (struct.hardware.soloUnits[i].status.reportingStatus === Constants.OFFLINE) {
            return Constants.OFFLINE;
        }
    }

    if ( masters.length == 0 && remotes.length == 0 && solos.length == 0 ) {
        return Constants.OFFLINE;
    }
    return Constants.ONLINE;
};

export default getStructStatus;
