/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Paper, styled, Typography} from '@mui/material';
import getTestID from '../../../../helper/functions/getTestID';
import {Wifi} from '@mui/icons-material';
import {observer} from 'mobx-react-lite';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DashboardSkeleton from '../../../common/DashboardSkeleton';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
});

const TitleRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    paddingTop: '11px',
});

interface Props {
    id: string,
    title: string,
    reported: number,
    total: number,
}

const ItemsReportedPaper = observer((props: Props) => !BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
    <Paper
        elevation={6}
    >
        <Root>
            <TitleRow>
                <Typography
                    {...getTestID(`${ props.id }_reported_title`)}
                >
                    {props.title}
                </Typography>
                <Wifi/>
            </TitleRow>
            <Row>
                <Typography
                    {...getTestID(`reported_${ props.id }`)}
                    fontSize={110}
                    fontWeight="bold"
                    marginRight='16px'
                    color='primary'
                >
                    {props.reported}
                </Typography>
                <Typography
                    {...getTestID(`total_${ props.id }`)}
                    fontSize={40}
                    fontWeight="bold"
                    marginTop='50px'
                >
                    / {props.total}
                </Typography>
            </Row>
        </Root>
    </Paper>
) : (
    <SkeletonRoot>
        <DashboardSkeleton height={180}/>
    </SkeletonRoot>
));

export default ItemsReportedPaper;
