/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {MarkerData} from '../../../../types/components/ApiTypes';
import {Avatar, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';

interface Props {
    marker: MarkerData,
}

const CountMarker = observer((props: Props) => {
    const count = props.marker.siteCount;
    return (
        <Avatar sx={{
            width: 32,
            height: 32,
        }}
        >
            <Typography fontSize={12}>{count}</Typography>
        </Avatar>
    );
});

export default CountMarker;
