/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import ContentPanel from '../../common/ContentPanel';
import NotesPage from '../notes/NotesPage';

const Notes = () => (
    <ContentPanel>
        <NotesPage/>
    </ContentPanel>
);

export default Notes;
