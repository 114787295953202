/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import getDateString from './getDateString';
import Constants from '../Constants';

const getTimeSinceString = (value: number, unit: string) => `${ value } ${ value === 1 ? unit : `${ unit }s` } ago`;

const getTimeSince = (date: number) => {
    if (date > 0) {
        const current = new Date().valueOf();
        const timeSince = current - date;
        if (timeSince < Constants.MILLISECONDS_IN_A_MINUTE) {
            return 'just now';
        }
        if (timeSince < Constants.MILLISECONDS_IN_AN_HOUR * 3) {
            const minutes = Math.floor(timeSince / Constants.MILLISECONDS_IN_A_MINUTE);
            return getTimeSinceString(minutes, 'minute');
        }
        if (timeSince < Constants.MILLISECONDS_IN_A_DAY) {
            const hours = Math.floor(timeSince / Constants.MILLISECONDS_IN_AN_HOUR);
            return getTimeSinceString(hours, 'hour');
        }
        if (timeSince < Constants.MILLISECONDS_IN_A_DAY * 30) {
            const days = Math.floor(timeSince / Constants.MILLISECONDS_IN_A_DAY);
            return getTimeSinceString(days, 'day');
        }
        return getDateString(new Date(date));
    }
    return Constants.NA;
};

export default getTimeSince;
