/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Bin, Building, GrainBag, Pile} from '../../types/components/ApiTypes';
import Constants from '../Constants';

const getDevice = (struct: Bin | GrainBag | Building | Pile) => {
        if ( struct.hardware?.remoteUnits.length > 0 &&
                struct.hardware.remoteUnits[0].automation) {
                return Constants.PLUS.toLocaleLowerCase();
        }

        if (struct.settings?.hardwareType) {
                return struct.settings.hardwareType.toLowerCase() === Constants.LIVE.toLowerCase() ||
                        struct.settings.hardwareType.toLowerCase() === Constants.DIRECT.toLowerCase() ||
                        struct.settings.hardwareType.toLowerCase() === Constants.SOLO.toLowerCase()
                        ? struct.settings.hardwareType.toLowerCase() : '';
        }
        return '';
};

export default getDevice;
