/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import DisplayGridContent from '../../common/displayTable/DisplayGridContent';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import DisplayGridActions from '../../common/displayTable/DisplayGridActions';
import {
    Announcement,
    Print,
    QueryStats,
    QuickreplyOutlined,
    Settings,
    Visibility
} from '@mui/icons-material';
import Constants from '../../../helper/Constants';
import WebStore from '../../../stores/WebStore';
import BinsenseStore from '../../../stores/BinsenseStore';
import replaceSpaces from '../../../helper/functions/replaceSpaces';
import {observer} from 'mobx-react-lite';
import getSitePrintMap from '../../../controllers/apiCalls/get/getSitePrintMap';
import {useSnackbar} from 'notistack';
import useWindowSize from '../../../helper/hooks/useWindowSize';
import {useMemo} from 'react';
import { ActionButton } from '../../../types/components/ApiTypes';

interface Props {
    data: any,
    site: any,
}

const MyCustomersTableRowDetails = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const onPrintClick = () => {
        getSitePrintMap(enqueueSnackbar, props.site.id, props.data.id);
    };

    const renderGridLabel = (name: string, label: string, xs: number, lengthLimit?: number) => (
        <DisplayGridLabel
            id={`${ name }_cell`}
            label={label}
            xs={xs}
            lengthLimit={lengthLimit}
        />
    );

    const onIconClick = (page: string, id: number) => {
        WebStore.setPageIds([ props.data.id, id, -1 ], true);
        WebStore.goToNewPage(page);
        WebStore.setPageData([ BinsenseStore.customerSiteData, props.data.sites, null ], true);
    };

    const idStarter = `${ replaceSpaces(props.data.user) }_${ replaceSpaces(props.site.name) }_data`;

    const getButtons = () => {
        const buttons: ActionButton[] = [];
        buttons.push(
            {
                id: `${ idStarter }_commands_button`,
                onClick: () => onIconClick(Constants.COMMANDS, props.site.id),
                icon: <QuickreplyOutlined/>,
                hoverText: Constants.COMMANDS,
            }
        );
        buttons.push(
            {
                id: `${ idStarter }_customer_device_settings_button`,
                onClick: () => onIconClick(Constants.DEVICE_SETTINGS, props.site.id),
                icon: <Settings/>,
                hoverText: Constants.DEVICE_SETTINGS,
            }
        );
        buttons.push(
            {
                id: `${ idStarter }_customer_site_report_button`,
                onClick: () => onIconClick(Constants.SITE_REPORT, props.site.id),
                icon: <QueryStats/>,
                hoverText: Constants.SITE_REPORT,
            }
        );
        if ( props.data.retailerHasAccess ) {
            buttons.push(
                {
                    id: `${ idStarter }_customer_storage_button`,
                    onClick: () => {
                        WebStore.setStorageMode(Constants.LIST, true);
                        onIconClick(Constants.STORAGE, props.site.id);
                    },
                    icon: <Visibility/>,
                    hoverText: Constants.STORAGE,
                }
            );
        }
        buttons.push(
            {
                id: `${ idStarter }_customer_alert_history_button`,
                onClick: () => onIconClick(Constants.ALERT_HISTORY, props.site.id),
                icon: <Announcement/>,
                hoverText: Constants.ALERTS,
            }
        );
        buttons.push(
            {
                id: `${ idStarter }_print_button`,
                onClick: () => onPrintClick(),
                icon: <Print/>,
                hoverText: 'Print Site Map',
            }
        );
        return buttons;
    };

    return (
        <DisplayGridContent key={`${ idStarter }_data`}>
            {renderGridLabel(`${ idStarter }_user`, '--', largeScreen ? 3.5 : 3)}
            {renderGridLabel(`${ idStarter }_site_name`, props.site.name, 2.5, largeScreen ? 20 : 12)}
            {renderGridLabel(`${ idStarter }_city`, '--', 3)}
            <DisplayGridActions
                buttons={getButtons()}
                xs={largeScreen ? 3 : 3.5}
                key={`${ props.data.id }_${ props.site.id }_actions_cell`}
                id={`${ idStarter }`}
            />
        </DisplayGridContent>
    );
});

export default MyCustomersTableRowDetails;
