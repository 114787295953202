import callDeleteAPI from '../../callDeleteAPI';
import getServiceRecords from '../get/getServiceRecords';

const deleteServiceRecord = (enqueueSnackbar: any, id: number) => {
    callDeleteAPI(`serviceRecord/${ id }`,
        (response) => {
            if (Number(response) > 0)
                getServiceRecords(enqueueSnackbar, Number(response));
        },
        enqueueSnackbar,
        'Record deleted successfully'
    );
};

export default deleteServiceRecord;