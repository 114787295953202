/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

interface Props {
    src: string,
}

const Carousel = (props: Props) => (
    <iframe
        width="100%"
        height="100%"
        src={props.src}
        frameBorder={0}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    />
);

export default Carousel;
