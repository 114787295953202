/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import TitlePaper from '../../common/TitlePaper';
import Constants from '../../../helper/Constants';
import {useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import BillingAddressForm from '../../common/personalDetails/BillingAddressForm';
import BinsenseStore from '../../../stores/BinsenseStore';
import StyledSwitch from '../../common/input/StyledSwitch';
import SubmitButton from '../../common/buttons/SubmitButton';
import getCountryId from '../../../helper/functions/getCountryId';
import getProvinceId from '../../../helper/functions/getProvinceId';
import putUserSettings from '../../../controllers/apiCalls/put/putUserSettings';
import { useSnackbar } from 'notistack';
import isBillingAddressFormValid from '../../../helper/complexValidators/isBillingAddressFormValid';
import WebStore from '../../../stores/WebStore';

const BillingAddressPaper = observer(() => {
    const {enqueueSnackbar} = useSnackbar();
    const userData = useMemo(() => WebStore.selectedView.toLowerCase() === Constants.DEALER.toLowerCase()
            ? WebStore.selectedCustomer : BinsenseStore.userData, [ BinsenseStore.userData, WebStore.selectedCustomer ]);
    const billingAddress = userData.account?.billingAddress ? userData.account.billingAddress : {
        address: '',
        city: '',
        country: '',
        postalCode: '',
        province: '',
    };

    const [ sameAsHomeAddress, setSameAsHomeAddress ] = useState(billingAddress.country === '');
    const initialSameAsHomeAddress = billingAddress.country === '' ? true : false;
    const [ postalCode, setPostalCode ] = useState(billingAddress.postalCode);
    const [ postalCodeError, setPostalCodeError ] = useState('');
    const [ address, setAddress ] = useState(billingAddress.address);
    const [ addressError, setAddressError ] = useState('');
    const [ city, setCity ] = useState(billingAddress.city );
    const [ cityError, setCityError ] = useState('');
    const [ country, setCountry ] = useState(getCountryId(billingAddress.country).toString());
    const [ countryError, setCountryError ] = useState('');
    const [ email, setEmail ] = useState(userData.account ? userData.account.accountEmail : '');
    const [ emailError, setEmailError ] = useState('');
    const [ province, setProvince ] = useState(
        getProvinceId(billingAddress.province, billingAddress.country).toString());
    const [ provinceError, setProvinceError ] = useState('');

    useEffect(() => {
        if (userData.account?.billingAddress?.postalCode) {
            setPostalCode(userData.account.billingAddress.postalCode);
        }
        if (userData.account?.billingAddress?.address) {
            setAddress(userData.account.billingAddress.address);
        }
        if (userData.account?.billingAddress?.city) {
            setCity(userData.account.billingAddress.city);
        }
        if (userData.account?.billingAddress?.country) {
            setCountry(getCountryId(userData.account.billingAddress.country).toString());
            if (userData.account?.billingAddress.province) {
                setProvince(getProvinceId(userData.account.billingAddress.province,
                    userData.account.billingAddress.country).toString());
            }
        }

        if (sameAsHomeAddress) {
            setPostalCode('');
            setAddress('');
            setCity('');
            setCountry('-1');
            setProvince('-1');
        }
    }, [ userData.account, sameAsHomeAddress ]);

    const isValid = () => isBillingAddressFormValid(
        {
            value: postalCode,
            setError: setPostalCodeError,
        },
        {
            value: address,
            setError: setAddressError,
        },
        {
            value: city,
            setError: setCityError,
        }, {
            value: country,
            setError: setCountryError,
        }, {
            value: province,
            setError: setProvinceError,
        }
    );

    const submit = () => {
        if ((!initialSameAsHomeAddress && sameAsHomeAddress) || isValid()) {
            const settings = {
                customer: {
                    account: {
                        billingAddress: {
                            address,
                            city,
                            province,
                            country,
                            postalCode,
                        },
                    },
                },
            };
            putUserSettings(enqueueSnackbar, userData.id, settings);
        }
    };

    return (
        <TitlePaper title={Constants.BILLING_ADDRESS}>
            <StyledSwitch
                id="same_as_home_address_switch"
                description="Same As Home Address"
                value={sameAsHomeAddress}
                setValue={setSameAsHomeAddress}
            />
            <BillingAddressForm
                id="billing_information"
                postalCode={{
                    value: postalCode,
                    setValue: setPostalCode,
                    error: postalCodeError,
                    setError: setPostalCodeError,
                }}
                address={{
                    value: address,
                    setValue: setAddress,
                    error: addressError,
                    setError: setAddressError,
                }}
                town={{
                    value: city,
                    setValue: setCity,
                    error: cityError,
                    setError: setCityError,
                }}
                country={{
                    value: country,
                    setValue: setCountry,
                    error: countryError,
                    setError: setCountryError,
                }}
                province={{
                    value: province,
                    setValue: setProvince,
                    error: provinceError,
                    setError: setProvinceError,
                }}
                email={{
                    value: email,
                    setValue: setEmail,
                    error: emailError,
                    setError: setEmailError,
                }}
                disabled={sameAsHomeAddress}
            />
            <SubmitButton
                id="billing_address_submit"
                text={Constants.SAVE_CHANGES}
                onClick={submit}
            />
        </TitlePaper>
    );
});

export default BillingAddressPaper;
