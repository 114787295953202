/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Component, ErrorInfo, ReactNode} from 'react';
import {Button, styled, Typography} from '@mui/material';
import WelcomeImage from '../../images/welcome.png';
import Constants from '../../helper/Constants';

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
});

interface Props {
    children: ReactNode;
}

class ErrorBoundary extends Component {
    constructor(props: Props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    getValue = (key: string) => {
        const value = global.localStorage.getItem(key);
        if (value === null) {
            return '';
        }
        return JSON.parse(value);
    }

    setValue = (key: string, value: string ) => {
        global.localStorage.setItem(key, JSON.stringify(value));
    };

    homeClicked = () => {
        const selectedView = this.getValue(Constants.STORE_KEY_SELECTED_VIEW);

        if (selectedView === Constants.CUSTOMER) {
            this.setValue(Constants.STORE_KEY_SELECTED_DRAWER, Constants.DASHBOARD);
        } else if (selectedView === Constants.DEALER) {
            this.setValue(Constants.STORE_KEY_SELECTED_DRAWER, Constants.MY_CUSTOMERS);
        }
        window.location.reload();
    };

    render() {
        // @ts-ignore
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Column>
                    <Typography
                        sx={{
                            fontSize: '2em',
                            fontWeight: 'bold',
                            marginBottom: '8px',
                        }}
                    >
                        Internal Error
                    </Typography>
                    <Typography sx={{marginBottom: '24px'}}>
                        Whoops! There was an error - please try again later.
                    </Typography>

                    <img src={WelcomeImage}/>
                    <Button
                        variant="contained"
                        sx={{marginTop: '24px'}}
                        onClick={this.homeClicked}
                    >
                        Home
                    </Button>
                </Column>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
