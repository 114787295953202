/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import BinsenseStore from '../../stores/BinsenseStore';

const getCountryId = (abbreviation: string) => {
    const index = BinsenseStore.dataModel.countries.findIndex(country => country.abbreviation === abbreviation);
    return index !== -1 ? BinsenseStore.dataModel.countries[index].id : -1;
};

export default getCountryId;
