/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo} from 'react';
import {Divider, Grid} from '@mui/material';
import Constants from '../../../helper/Constants';
import TitleTypography from '../typography/TitleTypography';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import GridTextFieldInput from '../input/GridTextFieldInput';
import GridSelectFieldInput from '../input/GridSelectFieldInput';
import getCountryList from '../../../helper/functions/getCountryList';
import getProvinceList from '../../../helper/functions/getProvinceList';

interface Props {
    hasTitle?: boolean,
    hideDivider?: boolean,
    id?: string,
    postalCode: InputFieldProps,
    address: InputFieldProps,
    town: InputFieldProps,
    province: InputFieldProps,
    country: InputFieldProps,
    company?: InputFieldProps,
    email?: InputFieldProps,
    disabled?: boolean,
}

function BillingAddressForm(props: Props) {
    const updateCountry = (country: string) => {
        if (props.province.setValue && props.country.setValue) {
            props.province.setValue('');
            props.country.setValue(country);
        }
    };

    const prefixId = useMemo(() => {
        if (props.id) {
            return `${ props.id }_`;
        }
        return '';

    }, [ props.id ]);

    return (
        <div style={{ width: '100%' }}>
            {!props.hideDivider && (
                <Divider
                    sx={{
                        marginTop: '8px',
                        marginBottom: '16px',
                    }}
                />
            )}

            {props.hasTitle && (
                <TitleTypography title={Constants.BILLING_ADDRESS}/>
            )}
            <Grid
                container
                spacing={2}
            >
                <GridTextFieldInput
                    id={`${ prefixId }address_input`}
                    label={Constants.ADDRESS}
                    field={props.address}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 150,
                        },
                        disabled: props.disabled,
                    }}
                />
                <GridTextFieldInput
                    id={`${ prefixId }town_input`}
                    label={Constants.TOWN_OR_CITY}
                    field={props.town}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 25,
                        },
                        disabled: props.disabled,
                    }}
                />
                <GridSelectFieldInput
                    id={`${ prefixId }country_input`}
                    label={Constants.COUNTRY}
                    field={{
                        ...props.country,
                        setValue: updateCountry,
                    }}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 25,
                        },
                        disabled: props.disabled,
                    }}
                    options={getCountryList()}
                />
                <GridSelectFieldInput
                    id={`${ prefixId }province_input`}
                    label={Constants.PROVINCE_OR_STATE}
                    field={props.province}
                    options={getProvinceList(props.country.value)}
                    textFieldProps={{ disabled: props.disabled }}
                />
                <GridTextFieldInput
                    id={`${ prefixId }postal_code_input`}
                    label={Constants.POSTAL_CODE_OR_ZIP_CODE}
                    field={props.postalCode}
                    textFieldProps={{
                        inputProps: {
                            maxLength: 25,
                        },
                        disabled: props.disabled,
                    }}
                />
                {props.email && (
                    <GridTextFieldInput
                        id={`${ prefixId }email_input`}
                        label={Constants.EMAIL}
                        field={props.email}
                        textFieldProps={{ disabled: true }}
                    />
                )}
                {props.company && (
                    <GridTextFieldInput
                        id={`${ prefixId }company_input`}
                        label="Company Name (Optional)"
                        field={props.company}
                        textFieldProps={{ disabled: props.disabled }}
                    />
                )}
            </Grid>
        </div>
    );
}

export default BillingAddressForm;
