/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import Constants from '../../../../helper/Constants';
import {styled, Tooltip} from '@mui/material';
import TextFieldInput from '../../../common/input/TextFieldInput';
import SelectFieldInput from '../../../common/input/SelectFieldInput';
import {DistanceUnit, GrainPileShape, InputFieldProps} from '../../../../types/components/ApiTypes';
import TooltipStore from '../../../../stores/TooltipStore';
import TooltipWithButton from '../../../common/TooltipWithButton';
import TooltipMessages from '../../../../helper/TooltipMessages';
import KeyValueTypography from '../../../common/typography/KeyValueTypography';
import InfoTooltip from '../../../common/InfoTooltip';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '16px',
});

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '8px',
    width: '100%',
});

interface Props {
    name: InputFieldProps,
    shape: InputFieldProps,
    diameter: InputFieldProps,
    quantity: InputFieldProps,
    powerSource: InputFieldProps,
}

const PileDetailsForm = (props: Props) => (
    <Root>
        <Row>
            <KeyValueTypography
                label="STEP 1:"
                value={Constants.PILE_DETAILS}
            />
            <InfoTooltip
                id="power_source_tooltip_button"
                open={TooltipStore.beginTooltips && TooltipStore.showPowerSourceMessages}
                message={TooltipMessages.POWER}
                onClick={() => TooltipStore.setShowPowerSourceMessages(false, true)}
            />
        </Row>
        <TextFieldInput
            id="pile_name_input"
            label={Constants.NAME}
            field={props.name}
        />
        <SelectFieldInput
            id="pile_shape_input"
            label={Constants.SHAPE}
            field={props.shape}
            options={[
                {
                    id: 'shape_circle_option',
                    title: GrainPileShape.circle,
                },
                {
                    id: 'shape_square_option',
                    title: GrainPileShape.square,
                },
            ]}
        />
        <TextFieldInput
            id="pile_diameter_input"
            label={`${ props.shape.value === GrainPileShape.circle
                ? Constants.DIAMETER : Constants.WIDTH } (${ DistanceUnit.feet })`}
            field={props.diameter}
            textFieldProps={{
                inputProps: {
                    type: 'number',
                    min: '0',
                },
            }}
        />
        <TextFieldInput
            id="pile_quantity_input"
            label={Constants.QUANTITY}
            field={props.quantity}
            textFieldProps={{
                inputProps: {
                    type: 'number',
                    min: '0',
                },
            }}
        />
        <Tooltip
            open={TooltipStore.beginTooltips && TooltipStore.showPowerSourceMessages}
            arrow
            title={(
                <TooltipWithButton
                    id="power_source_tooltip_button"
                    message={TooltipMessages.POWER}
                    onClick={() => TooltipStore.setShowPowerSourceMessages(false, true)}
                />
            )}
        >
            <div style={{ width: '100%' }}>
                <SelectFieldInput
                    id="bin_details_power_source_input"
                    label={Constants.POWER_SOURCE}
                    field={props.powerSource}
                    options={[
                        {
                            id: 'battery_powered',
                            title: Constants.BATTERY_POWERED,
                        },
                        {
                            id: 'plugged_in',
                            title: Constants.PLUGGED_IN,
                        },
                    ]}
                />
            </div>
        </Tooltip>
    </Root>
);

export default PileDetailsForm;
