/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled, Typography} from '@mui/material';
import {LocationOn} from '@mui/icons-material';

const Column = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

const LocationMarker = () => (
    <Column>
        <LocationOn
            color="primary"
            sx={{
                width: '1.5em',
                height: '1.5em',
            }}
        />
        <Typography>Place Marker</Typography>
    </Column>

);

export default LocationMarker;
