/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Paper, styled, Typography} from '@mui/material';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import getTestID from '../../../../helper/functions/getTestID';
import {useMemo} from 'react';
import BinsenseStore from '../../../../stores/BinsenseStore';
import DashboardSkeleton from '../../../common/DashboardSkeleton';
import {observer} from 'mobx-react-lite';

const SkeletonRoot = styled('div')({
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
});

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '16px',
});

interface Props {
    totals: number[],
}

const SiteReportingProgressPaper = observer((props: Props) => {
    const progress = useMemo(() => {
        const value = (props.totals[0] / props.totals[1]) * 100;
        return Number(value.toFixed(0));
    }, [ props.totals ]);

    return !BinsenseStore.awaitingApi && !BinsenseStore.awaitingUserData ? (
        <Paper elevation={6}>
            <Root>
                <Typography
                    {...getTestID('devices_reported_title')}
                >
                    Site Reporting
                </Typography>
                <Row>
                    <CircularProgressWithLabel
                        id='site_setup_completion_progress'
                        value={progress}
                        size={160}
                    />
                </Row>
            </Root>
        </Paper>
    ) : (
        <SkeletonRoot>
            <DashboardSkeleton height={180}/>
        </SkeletonRoot>
    );
});

export default SiteReportingProgressPaper;