import callAPI from '../../callAPI';
import BinsenseStore from '../../../stores/BinsenseStore';
import WebStore from '../../../stores/WebStore';
import getBinNotes from './getBinNotes';
import getInventory from '../../../helper/functions/getInventory';

const getUserModel = (enqueueSnackbar: any) => {
    callAPI('userModel',
        (response) => {
            BinsenseStore.setUserData(response);
            WebStore.setOverDuePayment(response.lockedOut);
            if (response.sites.length > 0 && WebStore.selectedSiteId === -1) {
                WebStore.setSelectedSiteId(response.sites[0].id, true);
                getBinNotes(enqueueSnackbar, response.sites[0].id);
            }
            else if (response.sites.length === 0) {
                WebStore.setNewUser(true);
            }
            else {
                getBinNotes(enqueueSnackbar, WebStore.selectedSiteId);
            }
            BinsenseStore.setInventoryTableData(getInventory(response));
        },
        enqueueSnackbar,
        true
    );
};

export default getUserModel;