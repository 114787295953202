/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Grid} from '@mui/material';
import EditCustomerAccountPaper from '../myUsers/EditCustomerAccountPaper';
import {useState} from 'react';
import EditCustomerInformationPaper from './editCustomerInformationPage/EditCustomerInformationPaper';
import {Customer} from '../../../types/components/ApiTypes';
import putUserSettings from '../../../controllers/apiCalls/put/putUserSettings';
import {useSnackbar} from 'notistack';
import ManageSitePaper from '../../customer/preferences/ManageSitePaper';
import SystemPreferencesPaper from '../../customer/preferences/SystemPreferencesPaper';

interface Props {
    user: Customer,
}

const EditAccountPage = (props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const account = props.user.account ? props.user.account : {
        accountEmail: '',
        address: {
            address: '',
            city: '',
            country: '',
            postalCode: '',
            province: '',
        },
        companyName: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
    };
    const getPreferences = () => ({
        areaUnits: '',
        distanceUnits: '',
        language: '',
        temperatureUnits: props.user.temperatureUnits ?? 'Celsius',
        timeZone: props.user.timeZone ?? '',
        volumeUnits: '',
        showEmptyStructures: false,
    });

    const [ firstName, setFirstName ] = useState(account.firstName);
    const [ firstNameError, setFirstNameError ] = useState('');
    const [ lastName, setLastName ] = useState(account.lastName);
    const [ lastNameError, setLastNameError ] = useState('');
    const [ address, setAddress ] = useState(account.address.address);
    const [ addressError, setAddressError ] = useState('');
    const [ postalCode, setPostalCode ] = useState(account.address.postalCode);
    const [ postalCodeError, setPostalCodeError ] = useState('');
    const [ city, setCity ] = useState(account.address.city);
    const [ cityError, setCityError ] = useState('');
    const [ province, setProvince ] = useState(account.address.province);
    const [ provinceError, setProvinceError ] = useState('');
    const [ country, setCountry ] = useState(account.address.country);
    const [ countryError, setCountryError ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState(account.phoneNumber);
    const [ phoneNumberError, setPhoneNumberError ] = useState('');
    const [ email, setEmail ] = useState(account.accountEmail);
    const [ emailError, setEmailError ] = useState('');

    const isValid = () => firstName !== '' && lastName !== '' && address !== '' && postalCode !== '' && city !== '' &&
        province !== '' && country !== '' && phoneNumber !== '' && email !== '';

    const submit = () => {
        if (isValid()) {
            const settings = {
                customer: {
                    account: {
                        firstName,
                        lastName,
                        accountEmail: email,
                        phoneNumber,
                        address: {
                            address,
                            city,
                            province,
                            country,
                            postalCode,
                        },
                    },
                },
            };
            putUserSettings(enqueueSnackbar, props.user.id, settings);
        }
    };

    return (
        <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={6}
            >
                <EditCustomerAccountPaper user={props.user}/>
            </Grid>
            <Grid
                item
                xs={6}
            >
                <ManageSitePaper
                    sites={props.user.sites}
                    userId={props.user.id}
                />
            </Grid>
            <Grid
                item
                xs={12}
            >
                <EditCustomerInformationPaper
                    firstName={{
                        value: firstName,
                        setValue: setFirstName,
                        error: firstNameError,
                        setError: setFirstNameError,
                    }}
                    lastName={{
                        value: lastName,
                        setValue: setLastName,
                        error: lastNameError,
                        setError: setLastNameError,
                    }}
                    address={{
                        value: address,
                        setValue: setAddress,
                        error: addressError,
                        setError: setAddressError,
                    }}
                    postalCode={{
                        value: postalCode,
                        setValue: setPostalCode,
                        error: postalCodeError,
                        setError: setPostalCodeError,
                    }}
                    city={{
                        value: city,
                        setValue: setCity,
                        error: cityError,
                        setError: setCityError,
                    }}
                    province={{
                        value: province,
                        setValue: setProvince,
                        error: provinceError,
                        setError: setProvinceError,
                    }}
                    country={{
                        value: country,
                        setValue: setCountry,
                        error: countryError,
                        setError: setCountryError,
                    }}
                    phoneNumber={{
                        value: phoneNumber,
                        setValue: setPhoneNumber,
                        error: phoneNumberError,
                        setError: setPhoneNumberError,
                    }}
                    email={{
                        value: email,
                        setValue: setEmail,
                        error: emailError,
                        setError: setEmailError,
                    }}
                    submit={submit}
                />
            </Grid>
            <Grid
                item
                xs={6}
            >
                <SystemPreferencesPaper
                    preferences={getPreferences()}
                    dealer
                    userId={props.user.id}
                    hideShowEmptyStructureOption
                />
            </Grid>
        </Grid>
    );
};

export default EditAccountPage;
