/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo, useState} from 'react';
import {styled} from '@mui/material';
import BaseModal from '../../common/modal/BaseModal';
import BinsenseStore from '../../../stores/BinsenseStore';
import getIndex from '../../../helper/functions/getIndex';
import putUserSettings from '../../../controllers/apiCalls/put/putUserSettings';
import {useSnackbar} from 'notistack';
import TextFieldInput from '../../common/input/TextFieldInput';
import Constants from '../../../helper/Constants';
import isNumeric from '../../../helper/validator/isNumeric';
import WebStore from '../../../stores/WebStore';

const Container = styled('div')({
    width: '500px',
    padding: '16px',
});

interface Props {
    id: string,
    open: boolean,
    close: () => void,
    editId: number,
}

function EditPhoneModal(props: Props) {
    const {enqueueSnackbar} = useSnackbar();

    const data = useMemo(() => WebStore.selectedView === Constants.CUSTOMER
        ? BinsenseStore.userData.settings.notificationPhoneNumbers
        : BinsenseStore.dealerCustomerUserSettings.notificationPhoneNumbers,
        [ BinsenseStore.userData, BinsenseStore.dealerCustomerUserSettings ]);
    const editIndex = useMemo(() => props.editId ? getIndex(props.editId, data) : -1, [ data, props.editId ]);

    const [ phoneNumber, setPhoneNumber ] = useState(data[editIndex].phoneNumber);
    const [ phoneNumberError, setPhoneNumberError ] = useState('');
    const initData = data[editIndex].phoneNumber;

    const isValid = () => {
        let error = isNumeric(phoneNumber, 10, '10-digit phone number', 10);
        if (error === '' && initData !== phoneNumber && data && data.some(item => item.phoneNumber === phoneNumber)) {
            error = 'The phone number you are trying to add already exists on your account';
        }
        if (error !== '') {
            setPhoneNumberError(error);
        }
        return error === '';
    };

    const submit = () => {
        if (isValid()) {
            const settings = {
                notificationPhoneNumbers: {
                    id: props.editId,
                    phoneNumber,
                },
            };
            putUserSettings(enqueueSnackbar, WebStore.selectedView === Constants.CUSTOMER
                ? BinsenseStore.userData.id
                : WebStore.selectedCustomer.id, settings);
            props.close();
        }
    };

    return (
        <BaseModal
            id={props.id}
            open={props.open}
            close={props.close}
            submit={submit}
            title="Edit Phone Number"
        >
            <Container>
                <TextFieldInput
                    id="edit_contact_phone"
                    label={Constants.MOBILE_NUMBER}
                    field={{
                        value: phoneNumber,
                        setValue: setPhoneNumber,
                        error: phoneNumberError,
                        setError: setPhoneNumberError,
                    }}
                />
            </Container>
        </BaseModal>
    );
}

export default EditPhoneModal;
