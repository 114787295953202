/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import Constants from '../../../helper/Constants';
import ContentPanel from '../../common/ContentPanel';
import WebStore from '../../../stores/WebStore';
import {observer} from 'mobx-react-lite';
import ViewDevicePage from '../storage/viewDevicePage/ViewDevicePage';
import DeviceSettingsMainPage from '../deviceSettings/DeviceSettingsMainPage';
import {useMemo} from 'react';
import getSelectedSiteIndex from '../../../helper/functions/getSelectedSiteIndex';
import BinsenseStore from '../../../stores/BinsenseStore';
import getSite from '../../../helper/functions/getSite';
import AdvancedAssociationsPage from '../../dealer/myCustomers/associationsPage/AdvancedAssociationsPage';
import getIndex from '../../../helper/functions/getIndex';
import getNewBin from '../../../helper/functions/getNewBin';
import ReportsLogsPage from '../reports/logs/ReportsLogsPage';

const DeviceSettings = observer(() => {
    const site = useMemo(() => getSite(getSelectedSiteIndex()),
        [ BinsenseStore.userData.sites, WebStore.selectedSiteId ]);

    const structure = useMemo(() => {
        if (WebStore.pageIds[0] !== -1) {
            const structId = Number(String(WebStore.pageIds[0]).split('_')[0]);
            const index = getIndex(structId, site.bins);
            return index > -1 ? site.bins[index] : getNewBin();
        }
        return getNewBin();
    }, [ site, WebStore.pageIds[0] ]);

    const renderContentPanel = () => {
        switch (WebStore.currentPage) {
            case Constants.MAIN:
                return (
                    <DeviceSettingsMainPage
                        site={site}
                        showDevice={true}
                    />
                );
            case Constants.VIEW_DEVICE:
                return <ViewDevicePage site={site}/>;
            case Constants.ADVANCED:
                return <AdvancedAssociationsPage structure={structure}/>;
            case Constants.ACTIVITY:
                return (
                    <ReportsLogsPage
                        site={site}
                        structureId={structure.id}
                        noTimePicker
                    />
                );
            default:
                return <div/>;
        }
    };

    return (
        <ContentPanel>
            {renderContentPanel()}
        </ContentPanel>
    );
});

export default DeviceSettings;
