import callPostAPI from '../../callPostAPI';
import updateData from '../../../helper/functions/updateData';
import { Bin, GrainBag, Building } from '../../../types/components/ApiTypes';

const postUpdateStructureSensors = (enqueueSnackbar: any, body: any, structure?: Bin | GrainBag | Building,
                                    setShowAutoLevelModal?: (value: boolean) => void) => {
    callPostAPI('updateStructureSensors',
        body,
        (response) => {
            if (response) {
                if (structure && setShowAutoLevelModal &&
                    (structure.hardware.masterUnits.length > 0 || structure.hardware.remoteUnits.length > 0) &&
                    structure.settings.autoExposeCoverSensors) {

                    setShowAutoLevelModal(true);
                } else {
                    updateData(enqueueSnackbar);
                }
            }
        },
        enqueueSnackbar,
        'Sensors updated successfully'
    );
};

export default postUpdateStructureSensors;