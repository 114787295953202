/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper, styled} from '@mui/material';
import KeyValueTypography from '../../../common/typography/KeyValueTypography';
import Constants from '../../../../helper/Constants';
import RegisterNewUserForm from '../../../login/RegisterNewUserForm';
import {InputFieldProps} from '../../../../types/components/ApiTypes';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
});

interface Props {
    isCustomer: boolean,
    firstName: InputFieldProps,
    lastName: InputFieldProps,
    email: InputFieldProps,
    phoneNumber: InputFieldProps,
    password: InputFieldProps,
    passwordConfirmation: InputFieldProps,
    country: InputFieldProps,
    province: InputFieldProps,
    city: InputFieldProps,
    address: InputFieldProps,
    postalCode: InputFieldProps,
    dealer?: InputFieldProps,
    useForBillingAddress: boolean,
    setUseForBillingAddress: (value: boolean) => void,
    billingPostalCode: InputFieldProps,
    billingAddress: InputFieldProps,
    billingCity: InputFieldProps,
    billingProvince: InputFieldProps,
    billingCountry: InputFieldProps,
}

const CreateUserPaper = (props: Props) => (
    <Paper style={{ width: '95%' }}>
        <Root>
            <KeyValueTypography
                label="STEP 2:"
                value={Constants.CREATE_NEW_USER}
            />
            <RegisterNewUserForm
                isCustomer={props.isCustomer}
                firstName={props.firstName}
                lastName={props.lastName}
                email={props.email}
                phoneNumber={props.phoneNumber}
                password={props.password}
                passwordConfirmation={props.passwordConfirmation}
                country={props.country}
                province={props.province}
                city={props.city}
                address={props.address}
                postalCode={props.postalCode}
                useForBillingAddress={props.useForBillingAddress}
                setUseForBillingAddress={props.setUseForBillingAddress}
                billingPostalCode={props.billingPostalCode}
                billingAddress={props.billingAddress}
                billingCity={props.billingCity}
                billingProvince={props.billingProvince}
                billingCountry={props.billingCountry}
                dealer={props.dealer ? props.dealer! : {value: ''}}
            />
        </Root>
    </Paper>
);

export default CreateUserPaper;
