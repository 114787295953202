/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {FiberManualRecord} from '@mui/icons-material';
import CarouselIconButton from './CarouselIconButton';

interface Props {
    active: boolean,
    onClick: () => void,
}

const CarouselIndicator = (props: Props) => (
    <CarouselIconButton
        active={props.active}
        onClick={props.onClick}
    >
        <FiberManualRecord sx={{fontSize: '15px'}}/>
    </CarouselIconButton>
);

export default CarouselIndicator;
