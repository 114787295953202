/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import DisplayGridHeaderRow from '../../common/displayTable/DisplayGridHeaderRow';

interface Props {
    children: object | object[],
}

const AccordionTableHeader = (props: Props) => (
    <DisplayGridHeaderRow
        sx={{
            paddingLeft: '36.39px',
            paddingRight: '16px',
        }}
    >
        {props.children}
    </DisplayGridHeaderRow>
);

export default AccordionTableHeader;
