import callPostAPI from '../../callPostAPI';
import updateData from '../../../helper/functions/updateData';

const postBuildings = (enqueueSnackbar: any, siteId: number, body: any[]) => {
    callPostAPI(`structure/buildings/${ siteId }`,
        body,
        (response) => {
            console.log(response);
            updateData(enqueueSnackbar);
        },
        enqueueSnackbar,
        'Buildings added successfully'
    );
};

export default postBuildings;