/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

export default function addLeading(str: number, expectedLength: number) {
    let newStr = str.toString();

    while (newStr.length < expectedLength) {
        newStr = `0${ newStr }`;
    }
    return newStr;
}
