/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

const getPaginationData = (data: any[], page: number, rowsPerPage: number) => {
    const startIndex = page * rowsPerPage;
    return data.slice(startIndex, startIndex + rowsPerPage);
};

export default getPaginationData;
