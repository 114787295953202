/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Button, Grid, styled, Typography} from '@mui/material';
import CustomerNotificationsTable from '../../customer/preferences/CustomerNotificationsTable';
import {useState} from 'react';
import Constants from '../../../helper/Constants';
import getTestID from '../../../helper/functions/getTestID';
import {Add} from '@mui/icons-material';
import {observer} from 'mobx-react-lite';
import AddContactDetailModal from './AddContactDetailModal';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '12px',
});

const CustomerNotificationsPanel = observer(() => {
    const [ addDetailModalOpen, setAddDetailModalOpen ] = useState(false);

    return (
        <Grid
            item
            xs={12}
        >
            <Row>
                <Typography color="primary">
                    Phone/Email Notifications
                </Typography>
                <Button
                    {...getTestID('new_contact_detail_button')}
                    startIcon={<Add/>}
                    onClick={() => setAddDetailModalOpen(true)}
                    variant="contained"
                >
                    {Constants.CONTACT_DETAIL}
                </Button>
            </Row>
            <CustomerNotificationsTable/>
            {addDetailModalOpen && (
                <AddContactDetailModal
                    id="add_contact_detail_modal"
                    open
                    close={() => setAddDetailModalOpen(false)}
                />
            )}
        </Grid>
    );
});

export default CustomerNotificationsPanel;
