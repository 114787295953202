/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {
    AutomationHubs,
    Bin,
    Building,
    GrainBag,
    MarkerData,
    MarkerTypes,
    Pile
} from '../../types/components/ApiTypes';
import Constants from '../Constants';
import getIndex from './getIndex';
import getPutAutomationBodyFromMarker from './getPutAutomationBodyFromMarker';
import getPutBinBodyFromMarker from './getPutBinBodyFromMarker';
import getPutGrainBagBodyFromMarker from './getPutGrainBagBodyFromMarker';
import getPutBuildingBodyFromMarker from './getPutBuildingBodyFromMarker';
import getPutPileBodyFromMarker from './getPutPileBodyFromMarker';

const setBin = (bin: Bin, marker: MarkerData) => {
    bin.id = marker.id;
    bin.latitude = marker.latitude;
    bin.longitude = marker.longitude;
    if (bin.latLongOffset) {
        bin.latLongOffset = false;
    }
    if (marker.display?.grainBinData) {
        bin.name = marker.display.grainBinData.name;
        bin.binType = marker.display.grainBinData.type.toString().toLowerCase();
        bin.diameter = marker.display.grainBinData.diameter;
        bin.peakHeight = marker.display.grainBinData.peakHeight;
        bin.eaveHeight = marker.display.grainBinData.eaveHeight;
        bin.capacity = marker.display.grainBinData.bushelCapacity ? marker.display.grainBinData.bushelCapacity : 0;
        bin.settings.hardwareType = marker.display.grainBinData.device;
    }
};

const setBag = (bag: GrainBag, marker: MarkerData) => {
    bag.id = marker.id;
    bag.latitude = marker.latitude;
    bag.longitude = marker.longitude;
    if (bag.latLongOffset) {
        bag.latLongOffset = false;
    }
    if (marker.display?.grainBagData) {
        bag.name = marker.display.grainBagData.name;
        bag.length = marker.display.grainBagData.length;
        bag.rotation = marker.display.grainBagData.rotation;
        bag.settings.hardwareType = marker.display.grainBagData.device;
    }
};

const setBuilding = (building: Building, marker: MarkerData) => {
    building.id = marker.id;
    building.latitude = marker.latitude;
    building.longitude = marker.longitude;
    if (building.latLongOffset) {
        building.latLongOffset = false;
    }
    if (marker.display?.buildingData) {
        building.name = marker.display.buildingData.name;
        building.length = marker.display.buildingData.length;
        building.width = marker.display.buildingData.width;
        building.height = marker.display.buildingData.height;
        building.rotation = marker.display.buildingData.rotation;
        building.settings.hardwareType = marker.display.buildingData.device;
    }
};

const setPile = (pile: Pile, marker: MarkerData) => {
    pile.id = marker.id;
    pile.latitude = marker.latitude;
    pile.longitude = marker.longitude;
    if (pile.latLongOffset) {
        pile.latLongOffset = false;
    }
    if (marker.display?.grainPileData) {
        pile.name = marker.display.grainPileData.name;
        pile.shape = marker.display.grainPileData.shape.toString();
        pile.width = marker.display.grainPileData.diameter;
        pile.diameter = marker.display.grainPileData.diameter;
        pile.rotation = 0;
        pile.quantity = marker.display.grainPileData.quantity;
        pile.settings.hardwareType = marker.display.grainPileData.device;
    }
};

const setAutomationHub = (hub: AutomationHubs, marker: MarkerData) => {
    hub.id = marker.id;
    hub.latitude = marker.latitude;
    hub.longitude = marker.longitude;
    if (hub.latLongOffset) {
        hub.latLongOffset = false;
    }
    if (marker.display?.automationHubData) {
        hub.name = Constants.AUTOMATION_HUB;
    }
};

const saveStructure = (site: any, struct: MarkerData, key: string) => {
    const structList = site[key];
    const index = getIndex(struct.id, structList);
    if (index >= 0) {
        if (key === 'bins') {
            setBin(structList[index], struct);
        }
        else if (key === 'grainBags') {
            setBag(structList[index], struct);
        }
        else if (key === 'buildings') {
            setBuilding(structList[index], struct);
        }
        else if (key === 'piles') {
            setPile(structList[index], struct);
        }
        else if (key == 'automationHubs') {
            setAutomationHub(structList[index], struct);
        }
    }
};

const deleteStructure = (site: any, id: number, key: string) => {
    const structClone = site[key];
    const index = getIndex(id, structClone);
    structClone.splice(index, 1);
    site[key] = structClone;
};

const saveStructures = (site: any, key: string, markerData: MarkerData[]) => {
    site[key].forEach((struct: Bin | GrainBag | Building | Pile | AutomationHubs) => {
        const index = getIndex(struct.id, markerData);
        if (index !== -1) {
            saveStructure(site, markerData[index], key);
        }
        else {
            deleteStructure(site, struct.id, key);
        }
    });
};

const addBin = (site: any, marker: MarkerData) => {
    const newBin = getPutBinBodyFromMarker(marker);
    site.bins.push(newBin);
};

const addBag = (site: any, marker: MarkerData) => {
    const newBag = getPutGrainBagBodyFromMarker(marker);
    site.grainBags.push(newBag);
};

const addBuilding = (site: any, marker: MarkerData) => {
    const newBuilding = getPutBuildingBodyFromMarker(marker);
    site.buildings.push(newBuilding);
};

const addPile = (site: any, marker: MarkerData) => {
    const newPile = getPutPileBodyFromMarker(marker);
    site.piles.push(newPile);
};

const addAutomationHub = (site: any, marker: MarkerData) => {
    const newHub = getPutAutomationBodyFromMarker(marker);
    site.automationHubs.push(newHub);
};

const addStructure = (site: any, marker: MarkerData, key: string) => {
    if (key === 'bins') {
        addBin(site, marker);
    }
    else if (key === 'grainBags') {
        addBag(site, marker);
    }
    else if (key === 'buildings') {
        addBuilding(site, marker);
    }
    else if (key === 'piles') {
        addPile(site, marker);
    }
    else if (key == 'automationHubs') {
        addAutomationHub(site, marker);
    }
};

const addStructures = (site: any, markerData: MarkerData[]) => {
    markerData.forEach(marker => {
        if (marker.display) {
            let key = `${ marker.display.type }s`;
            if (marker.display.type == MarkerTypes.automation) {
                key = 'automationHubs';
            }
            if (site[key]) {
                const addStruct = !site[key].map((struct: { id: number; }) => struct.id).includes(marker.id);
                if (addStruct) {
                    addStructure(site, marker, key);
                }
            }
        }
    });
};

const saveMarkerDataToSite = (site: any, markers: MarkerData[]) => {
    saveStructures(site, 'bins', markers);
    saveStructures(site, 'grainBags', markers);
    saveStructures(site, 'buildings', markers);
    saveStructures(site, 'piles', markers);
    saveStructures(site, 'automationHubs', markers);
    addStructures(site, markers);
};

export default saveMarkerDataToSite;
