/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Grid} from '@mui/material';
import ContentPanel from '../../common/ContentPanel';
import GrainTypeInventoryPaper from '../inventory/GrainTypeInventoryPaper';
import SiteInventoryPaper from '../inventory/SiteInventoryPaper';
import InventoryTable from '../inventory/InventoryTable';
import {observer} from 'mobx-react-lite';

const Inventory = observer(() => (
    <ContentPanel>
        <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={6}
            >
                <GrainTypeInventoryPaper/>
            </Grid>
            <Grid
                item
                xs={6}
            >
                <SiteInventoryPaper/>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <InventoryTable/>
            </Grid>
        </Grid>
    </ContentPanel>
));

export default Inventory;
