/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import {Bin, Building, GrainBag, Pile} from '../../types/components/ApiTypes';
import Constants from '../Constants';

const getHardwareType = (struct: Bin | GrainBag | Building | Pile) => {
    const hardwareType = struct.settings?.hardwareType ? struct.settings.hardwareType : Constants.UNMONITORED;
    if (hardwareType === Constants.UNMONITORED.toLowerCase()) {
        return Constants.UNMONITORED;
    }
    if (hardwareType === Constants.SOLO.toLowerCase()) {
        return Constants.SOLO;
    }
    if (hardwareType === Constants.DIRECT.toLowerCase()) {
        return Constants.DIRECT;
    }
    return hardwareType.charAt(0).toUpperCase() + hardwareType.slice(1);
};

export default getHardwareType;
