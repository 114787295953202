/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Grid} from '@mui/material';
import Constants from '../../../helper/Constants';
import {InputFieldProps} from '../../../types/components/ApiTypes';
import GridTextFieldInput from '../input/GridTextFieldInput';

interface Props {
    currentOwingBalance: InputFieldProps,
    accountRenewalDate: InputFieldProps,
    totalLiveDevices: InputFieldProps,
    totalSoloDevices: InputFieldProps,
    totalAutomationDevices: InputFieldProps,
    dueDate: InputFieldProps,
    currentPlan: InputFieldProps,
    disableAll?: boolean,
}

function BillingInformationForm(props: Props) {
    return (
        <Grid
            container
            spacing={2}
        >
            <GridTextFieldInput
                id="current_owing_balance_input"
                label={Constants.CURRENT_OWING_BALANCE}
                field={props.currentOwingBalance}
                textFieldProps={{ disabled: true }}
                fullWidth
            />
            <GridTextFieldInput
                id="account_renewal_date_input"
                label={Constants.ACCOUNT_RENEWAL_DATE}
                field={props.accountRenewalDate}
                textFieldProps={{ disabled: true }}
            />
            <GridTextFieldInput
                id="account_due_date_input"
                label={Constants.BALANCE_DUE_DATE}
                field={props.dueDate}
                textFieldProps={{ disabled: true }}
            />
            <GridTextFieldInput
                id="total_live_devices_input"
                label={Constants.TOTAL_LIVE_DEVICES}
                field={props.totalLiveDevices}
                textFieldProps={{ disabled: true }}
            />
            <GridTextFieldInput
                id="total_solo_devices_input"
                label={Constants.TOTAL_SOLO_DEVICES}
                field={props.totalSoloDevices}
                textFieldProps={{ disabled: true }}
            />
            <GridTextFieldInput
                id="total_automation_devices_input"
                label={Constants.TOTAL_AUTOMATION_DEVICES}
                field={props.totalAutomationDevices}
                textFieldProps={{ disabled: true }}
            />
            <GridTextFieldInput
                id="current_plan_input"
                label={Constants.CURRENT_PLAN}
                field={props.currentPlan}
                textFieldProps={{ disabled: true }}
            />
        </Grid>
    );
}

export default BillingInformationForm;
