/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useMemo, useState} from 'react';
import DisplayGridPaper from '../../common/displayTable/DisplayGridPaper';
import DisplayGridHeaderRow from '../../common/displayTable/DisplayGridHeaderRow';
import DisplayGridSortableTableHeader from '../../common/displayTable/DisplayGridSortableTableHeader';
import Constants from '../../../helper/Constants';
import BinsenseStore from '../../../stores/BinsenseStore';
import DisplayGridLabel from '../../common/displayTable/DisplayGridLabel';
import UsersTableRow from './UsersTableRow';

const UsersTable = () => {
    const [ sortColumn, setSortColumn ] = useState('');
    const [ sortAscending, setSortAscending ] = useState(true);

    const rows = useMemo(() => BinsenseStore.customerUsersData, [ BinsenseStore.customerUsersData ]);

    const renderSortableHeader = (id: string, label: string, key: string) => (
        <DisplayGridSortableTableHeader
            id={id}
            label={label}
            xs={3}
            data={rows}
            setData={BinsenseStore.setCustomerUsersData}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortAscending={sortAscending}
            setSortAscending={setSortAscending}
            dataKey={key}
        />
    );

    const renderGridLabel = (id: string, label: string, header: boolean = false) => (
        <DisplayGridLabel
            id={`${ id }_header`}
            label={label}
            xs={3}
            header={header}
        />
    );

    return (
        <DisplayGridPaper>
            <DisplayGridHeaderRow>
                {renderSortableHeader('member_email', Constants.MEMBER_EMAIL, 'email')}
                {renderSortableHeader('role', Constants.ROLE, 'role')}
                {renderSortableHeader('status', Constants.STATUS, 'status')}
                {renderGridLabel('actions', Constants.ACTIONS, true)}
            </DisplayGridHeaderRow>
            {rows.map(row => (
                <UsersTableRow
                    row={row}
                />
            ))}
        </DisplayGridPaper>
    );
};

export default UsersTable;
