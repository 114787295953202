/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import ItemsReportedPaper from './ItemsReportedPaper';
import Constants from '../../../../helper/Constants';

interface Props {
    reported: number,
    total: number,
}

const DevicesReportedPaper = (props: Props) => (
    <ItemsReportedPaper
        id='devices'
        title={Constants.DEVICES_REPORTED}
        reported={props.reported}
        total={props.total}
    />
);

export default DevicesReportedPaper;
