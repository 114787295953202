/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Site, Bin, Building, GrainBag, Pile} from '../../types/components/ApiTypes';
import getBinFanStatus from './getBinFanStatus';
import { comparisonOfStructuresInAscendingOrder } from './functions';
import getGrainType from './getGrainType';
import Constants from '../Constants';

const getSpecificStorage = (site: Site, key: string, structType: string) => site[key]
.map((bin: Bin | Building | GrainBag | Pile) => ({
    id: bin.id,
    name: bin.name ? bin.name : Constants.NA,
    fanStatus: getBinFanStatus(bin.hardware.fans ? bin.hardware.fans : []),
    fillLevel: bin.status?.percentFull || bin.status?.percentFull === 0 ? bin.status.percentFull : Constants.NA,
    lastReported: bin.status?.lastReading ? bin.status.lastReading : Constants.NA,
    maxTemp: bin.status?.maxTemperature ? bin.status.maxTemperature : Constants.NA,
    avgTemp: bin.status?.avgTemperature ? bin.status.avgTemperature : Constants.NA,
    avgMoisture: bin.status?.avgMoisture ? bin.status.avgMoisture : Constants.NA,
    sortOrder: bin.sortOrder,
    grainType: getGrainType(bin.settings.productId),
    type: structType,
}));

const getStorageListBins = (site: Site) => {
    const bins = getSpecificStorage(site, 'bins', Constants.BIN);
    const buildings = getSpecificStorage(site, 'buildings', Constants.BUILDING);
    const grainBags = getSpecificStorage(site, 'grainBags', Constants.BAG);
    const array = [ ...bins, ...buildings, ...grainBags ];

    const clonedData = JSON.parse(JSON.stringify(array));
    return comparisonOfStructuresInAscendingOrder(clonedData);
};

export default getStorageListBins;
