/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import BinsenseStore from '../../stores/BinsenseStore';

const getProvinceId = (provinceAbr: string, countryAbr: string) => {
    const countryIndex = BinsenseStore.dataModel.countries.findIndex(country => country.abbreviation === countryAbr);
    if (countryIndex !== -1) {
        const regions = BinsenseStore.dataModel.countries[countryIndex].regions;
        const provIndex = regions.findIndex(region => region.abbreviation === provinceAbr);
        return provIndex !== -1 ? regions[provIndex].id : -1;
    }
    return -1;
};

export default getProvinceId;
