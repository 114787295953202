/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react-lite';
import WebStore from '../../stores/WebStore';
import Colors from '../../helper/Colors';
import Crumb from './Crumb';

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

interface Props {
    disableButtons?: boolean,
}

const Breadcrumbs = observer((props: Props) => (
    <Row style={{ paddingTop: WebStore.pageQueue.length > 0 ? '0px' : '8px' }}>
        {WebStore.pageQueue.map(page => (
            <Row key={`${ page }_breadcrumb`}>
                <Crumb
                    page={page}
                    disabled={props.disableButtons}
                />
                <Typography
                        fontSize={20}
                        color={WebStore.lightMode ? Colors.modalTitleLightMode : Colors.modalTitleDarkMode}
                >
                    •
                </Typography>
            </Row>
            ))}
        <Crumb page={WebStore.currentPage}/>
    </Row>
    ));

export default Breadcrumbs;
