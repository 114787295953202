/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

const getNewSite = () => ({
    bins: [],
    buildings: [],
    grainBags: [],
    piles: [],
    automationHubs: [],
    id: -1,
    latitude: 0,
    longitude: 0,
    name: '',
    ambientTemp: undefined,
    humidity: undefined,
    tempTrend: undefined,
    humidityTrend: undefined,
    automationRemotesCount: 0,
    dataGeneratedTime: new Date(),
    live: false,
});

export default getNewSite;
