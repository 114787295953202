/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

const tableHeaderClicked = (label: string, data: any, setData: any, sortColumn: string, setSortColumn: any,
                       sortAscending: boolean, setSortAscending: any, key: string) => {
    let sortAsc = true;
    if (label === sortColumn) {
        sortAsc = !sortAscending;
        setSortAscending(!sortAscending);
    }
    else {
        setSortColumn(label);
        setSortAscending(true);
    }
    let clonedData = JSON.parse(JSON.stringify(data));
    const collator = new Intl.Collator('en',
    {numeric: true,
        sensitivity: 'base' });
    clonedData = clonedData.sort((obj1: any, obj2: any) => {
        if (obj1[key] === obj2[key]) {
            return 0;
        }
        const sortOrderModifier = sortAsc === true ? 1 : -1;
        const comparison = collator.compare(obj1[key], obj2[key]);
        return comparison * sortOrderModifier;
    });
    setData(clonedData);
};

export default tableHeaderClicked;