import callPutAPI from '../../callPutAPI';
import updateData from '../../../helper/functions/updateData';
import WebStore from '../../../stores/WebStore';
import Constants from '../../../helper/Constants';
import getSiteStructures from '../get/getSiteStructures';

const putSite = (enqueueSnackbar: any, body: any) => {
    callPutAPI('site',
        body,
        (response) => {
            console.log('response:', response);
            updateData(enqueueSnackbar);
            if (WebStore.selectedView === Constants.DEALER) {
                getSiteStructures(enqueueSnackbar, body.id);
            }
        },
        enqueueSnackbar,
        'Site updated successfully'
    );
};

export default putSite;