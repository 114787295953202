/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {ValidateFieldProps} from '../../types/components/ApiTypes';
import isStringNotEmpty from '../validator/isStringNotEmpty';
import isPostalCode from '../validator/isPostalCode';

export default function isBillingAddressFormValid(postalCode: ValidateFieldProps,
                                                  address: ValidateFieldProps,
                                                  town: ValidateFieldProps,
                                                  country: ValidateFieldProps,
                                                  province: ValidateFieldProps) {
    const newPostalCodeError = isPostalCode(postalCode.value, 5, 'postal code');
    postalCode.setError(newPostalCodeError);
    const newAddressError = isStringNotEmpty(address.value, 'address');
    address.setError(newAddressError);
    const newTownError = isStringNotEmpty(town.value, 'town or city');
    town.setError(newTownError);
    const newCountryError = country.value === '' ? 'Please select a country' : '';
    country.setError(newCountryError);
    const newProvinceError = province.value === '' ? 'Please select a state/province' : '';
    province.setError(newProvinceError);

    return (newPostalCodeError === '' && newAddressError === '' && newTownError === '' &&
        newCountryError === '' &&  newProvinceError === '');
}
