/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Paper} from '@mui/material';
import {Dispatch, SetStateAction, useMemo} from 'react';
import {SiteLocationData} from '../../../../types/components/ApiTypes';
import useWindowSize from '../../../../helper/hooks/useWindowSize';
import {observer} from 'mobx-react-lite';
import SetSiteLocationForm from '../../../common/SetSiteLocationForm';

interface Props {
    site: SiteLocationData | null,
    setSite: Dispatch<SetStateAction<SiteLocationData | null>>,
    siteLocationInvalid: boolean,
    existingSiteNoLocation?: boolean,
    userId?: number,
}

const SetSiteLocationPaper = observer((props: Props) => {
    const windowSize = useWindowSize();
    const mapHeight = useMemo(() => windowSize[1] - 382,
        [ windowSize ]);

    return (
        <Paper sx={{width: '95%'}}>
            <SetSiteLocationForm
                site={props.site}
                setSite={props.setSite}
                siteLocationInvalid={props.siteLocationInvalid}
                mapHeight={mapHeight}
                userId={props.userId}
            />
        </Paper>
    );
});

export default SetSiteLocationPaper;
