/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {Chip, styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {Bin, Building, GrainBag} from '../../../../types/components/ApiTypes';
import StorageGridItemHeaderRight from './StorageGridItemHeaderRight';
import getStructStatus from '../../../../helper/functions/getStructStatus';
import {useMemo} from 'react';
import TypographyWithLengthLimit from '../../../common/typography/TypographyWithLengthLimit';
import Constants from '../../../../helper/Constants';

const Root = styled('div')({
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

interface Props {
    bin: Bin | GrainBag | Building,
}

const showStatus = (props: Props, status: any) => {
    if (props.bin.settings?.hardwareType &&
            (props.bin.settings.hardwareType?.toLowerCase() === Constants.LIVE.toLowerCase() ||
            props.bin.settings.hardwareType?.toLowerCase() === Constants.SOLO.toLowerCase())) {
        return (
            <Chip
            label={status}
            color={status === 'Online' ? 'success' : 'error'}
            size="small"
            style={{ marginLeft: '8px' }}
            />
        );
    }
};

const StorageGridItemHeader = observer((props: Props) => {
    const status = useMemo(() => getStructStatus(props.bin), [ props.bin ]);
    return (
        <Root>
            <Row>
                <TypographyWithLengthLimit
                    id={`${ props.bin.id }_storage_item_header`}
                    lengthLimit={12}
                    fontWeight='bold'
                >
                    {props.bin.name}
                </TypographyWithLengthLimit>
                {showStatus(props, status)}
            </Row>
            <StorageGridItemHeaderRight bin={props.bin}/>
        </Root>
    );
});

export default StorageGridItemHeader;
