/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Paper, SxProps, Theme} from '@mui/material';
import useWindowSize from '../../../helper/hooks/useWindowSize';
import { useMemo } from 'react';

interface Props {
    children: object,
    sx?: SxProps<Theme>,
}

const DisplayGridPaper = (props: Props) => {
    const windowSize = useWindowSize();
    const maxHeight = useMemo(() => (windowSize[1] - 325), [ windowSize ]);
    return (
        <Paper
            sx={{
                padding: 0,
                paddingBottom: '16px',
                marginTop: '16px',
                ...props.sx,
                maxHeight: { maxHeight },
                overflow: 'auto',
            }}
        >
            {props.children}
        </Paper>
    );
};

export default DisplayGridPaper;
